import React, { useState, useEffect, useContext } from "react"
import "./TripDocuments.scss"
import Dropzone from "react-dropzone"
import { v4 as uuidv4 } from "uuid"
import { AuthUserContext } from "../../../components/session"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import { useGlobalStateStore } from "../../../State/global-state/global-state"
import { ToastProvider, useToasts } from "react-toast-notifications"
import { keys, findKey } from "lodash"
import { navigate } from "gatsby"
import is from "is_js"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import {
  ConfirmationModal,
  ConfirmationModalWithInputField,
} from "../../../components/ConfirmationModal/ConfirmationModal"
import {
  dottedFileName,
  dottedText,
  getFileExtension,
} from "../../../utility/general"
import TripMatesProfiles from "../Traveler/TripMatesProfiles"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"
const TripDocuments = ({ canEdit = false }) => {
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [showProgress, setShowProgress] = useState(false)
  const [toggleSelectedItemsMenu, setToggleSelectedItemsMenu] = useState(true)
  const [
    toggleSelectedItemsMenuDropdown,
    setToggleSelectedItemsMenuDropdown,
  ] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [deleteFile, setDeleteFile] = useState(false)
  const { addToast } = useToasts()
  const [progressValue, setProgressValue] = useState(0)
  const [fileInfo, setFileInfo] = useState([])
  const [activeViewType, setActiveViewType] = useState("tile")
  const [currentFileQty, setCurrentFileQty] = useState(0)
  //const [uploadSuccessful, setUploadSuccessful] = useState(false)
  const [fileToDelete, setFileToDelete] = useState("")
  const [toggleCheckInternational, setToggleCheckInternational] = useState(
    false
  )
  const [toggleMenu, setToggleMenu] = useState(9999)
  const [fileBusy, setFileBusy] = useState(9999)
  const [maxFileLimitError, setMaxFileLimitError] = useState(false)
  const MAX_FILE_LIMIT = 5
  const screenWidth = useWindowWidth()
  useEffect(() => {
    try {
      if (state.currentJobInfo.tripDocumentsSyncRequired) {
        // clearSelection() //Make sure the wishlist selections are cleared before pushing in state to db
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${tripState.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({
              tripDocuments: state.currentJobInfo.tripDocuments,
            })
            .then(() => {
              console.log("document update successful")
            })
            .catch(e => {
              setIsBusy(false)
              console.log(e)
            })
        }
        dispatch({ type: "tripDocumentsSyncRequired", data: false })
      }
    } catch (error) {
      console.log(error)
    }
  }, [state.currentJobInfo.tripDocumentsSyncRequired])

  useEffect(() => {
    console.log({ screenWidth })
    if (screenWidth < 840) setActiveViewType("list")
  }, [])

  useEffect(() => {
    try {
      if (currentFileQty !== state.currentJobInfo.tripDocuments.length)
        setCurrentFileQty(state.currentJobInfo.tripDocuments.length)
    } catch (error) {}
  }, [state.currentJobInfo.tripDocuments])

  const getTripOwnerUid = () => {
    try {
      let roles = state.currentJobInfo.roles
      let tripOwner = findKey(roles, { role: "owner" })
      console.log({ tripOwner })
      return tripOwner
    } catch (error) {
      return ""
    }
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setErrorMsg("")
    setShowError(false)
    console.log({ acceptedFiles })
    console.log({ rejectedFiles })
    if (acceptedFiles.length > 0) {
      if (acceptedFiles.length + currentFileQty > MAX_FILE_LIMIT) {
        addToast(`Maximum of ${MAX_FILE_LIMIT} documents allowed.`, {
          appearance: "error",
          autoDismiss: true,
        })
        return
      }
      acceptedFiles.map(item => onSave(item))
    }
    if (rejectedFiles.length > 0) {
      addToast(
        `File ${rejectedFiles[0].file.name} is too large. Max allowed file size is 10Mb`,
        {
          appearance: "error",
          autoDismiss: true,
        }
      )
      setErrorMsg(` File ${rejectedFiles[0].file.name} is too large, sorry!`)
      setShowError(true)
    }
  }

  useEffect(() => {
    console.log({ currentFileQty })
  }, [currentFileQty])

  let onSave = async fileObject => {
    const regex = /\.[0-9a-z]+$/gm
    const str = fileObject.name
    let m
    var ext = ""
    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        console.log(`Found match: ${match}`)
        ext = match
      })
    }
    let tripOwnerUid = getTripOwnerUid()
    if (tripOwnerUid === "") return
    var fileName = `${fileObject.name}` // + ext
    var fileURL = `${process.env.TRIP_DOCS_STORAGE_BUCKET}/trip-documents/${state.tripDetails.tripUid}/${authContext.uid}/${fileName}`
    console.log({ fileName })
    var storageRef = dbContext.storage().refFromURL(fileURL)
    var uploadTask = storageRef.put(fileObject)

    uploadTask.on(
      dbContext.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log("Upload is " + progress + "% done")
        setShowProgress(true)
        setProgressValue(Number(progress))
        switch (snapshot.state) {
          case dbContext.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused")
            break
          case dbContext.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running")
            break
        }
      },
      function(error) {
        console.log(error)
        setShowProgress(false)
        setProgressValue(0)
        addToast("Error uploading the file, please try again in sometime", {
          appearance: "error",
          autoDismiss: true,
        })
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break
          case "storage/canceled":
            // User canceled the upload
            break
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break
        }
      },
      async function() {
        // Upload completed successfully, now we can get the download URL
        let roles = state.currentJobInfo.roles
        let customMetadata = {
          tripUid: `${state.tripDetails.tripUid}`,
          jobUid: `${state.currentJobInfo.jobUid}`,
          owner: `${authContext.uid}`,
        }
        if (roles) {
          let collaboratorIds = keys(roles)
          console.log({ collaboratorIds })

          collaboratorIds.map((item, index) => {
            let i = roles[item].role
            Object.assign(customMetadata, { [item]: `${i}` })
          })
          console.log({ customMetadata })
          var metadata = {
            customMetadata: customMetadata,
          }

          let updatedMeta = await uploadTask.snapshot.ref.updateMetadata(
            metadata
          )
          console.log({ updatedMeta })
        }

        dispatch({
          type: "addNewTripDocument",
          data: {
            fileURL: fileURL,
            uploadedDateTime: new Date(),
            uploader: authContext.uid,
            fileName: fileName,
            fileNameAlias: fileName,
            docStarred: false,
          },
        })
        setShowProgress(false)
        // uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        //   console.log({ downloadURL })
        //   dispatch({
        //     type: "addNewTripDocument",
        //     data: {
        //       fileURL: fileURL,
        //       uploadedDateTime: new Date(),
        //       uploader: authContext.uid,
        //       fileName: fileName,
        //     },
        //   })
        //   //   setIsBusy(true)
        //   //   setShowProgress(false)
        //   //   setProgressValue(0)
        //   //   updateFiles()
        // })
      }
    )
  }
  const updateFiles = () => {
    setFileInfo([])
    let tripOwnerUid = getTripOwnerUid()
    if (tripOwnerUid === "") return
    var storageRef = dbContext
      .storage()
      .refFromURL(
        `${process.env.TRIP_DOCS_STORAGE_BUCKET}/trip-documents/${state.tripDetails.tripUid}/${tripOwnerUid}`
      )

    // Find all the prefixes and items.
    storageRef
      .listAll()
      .then(function(res) {
        var a = []
        if (res) {
          if (res.items.length >= MAX_FILE_LIMIT) {
            setMaxFileLimitError(true)
          } else {
            setMaxFileLimitError(false)
          }
        }
        console.log(res.items)
        res.items.forEach(function(itemRef) {
          // All the items under listRef.
          console.log({ itemRef })

          itemRef.getDownloadURL().then(function(url) {
            setFileInfo(fileInfo => [
              ...fileInfo,
              { name: itemRef.name, url: url },
            ])
          })
        })
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!
      })
  }

  const fileUploader = () => {
    try {
      return (
        <>
          <Dropzone
            onDrop={onDrop}
            accept="application/png, image/jpeg,image/jpg,image/png,image/gif, application/pdf, application/doc, application/docx, application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
            minSize={0}
            maxSize={5242880 * 2} //10MB
            // disabled="true"
            style={{ height: "100px", cursor: "pointer !important" }}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragReject,
              acceptedFiles,
              rejectedFiles,
            }) => {
              return (
                <div className="drop-zone-small" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {!isDragActive && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {/* <i className="far fa-images fa-4x"></i> */}
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          textAlign: "center",
                          verticalAlign: "middle",
                          padding: "5px",
                        }}
                      >
                        {is.mobile()
                          ? "Touch here to upload travel documents from your phone."
                          : "Click here or drag your travel documents from your computer to upload."}
                      </p>
                    </div>
                  )}
                  {isDragActive && !isDragReject && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        Drop the file anywhere inside this box.
                      </p>
                    </div>
                  )}
                  {isDragReject && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                      >
                        File type not accepted, sorry!
                      </p>
                    </div>
                  )}
                  {/* {isFileTooLarge && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          File is too large, sorry!
                        </p>
                      </div>
                    )} */}
                </div>
              )
            }}
          </Dropzone>
          <div style={{ marginTop: "5px" }}>
            {showProgress && (
              <progress
                className="progress is-warning is-small"
                value={progressValue}
                max="100"
              >
                {progressValue}
              </progress>
            )}
          </div>
        </>
      )
    } catch (error) {}
  }

  const downloadFile = (fileURL, fileName) => {
    try {
      if (fileURL) {
        var gsReference = dbContext.storage().refFromURL(fileURL)
        gsReference
          .getDownloadURL()
          .then(url => {
            // Insert url into an <img> tag to "download"
            // var img = document.getElementById("myimg")
            // img.setAttribute("src", url)
            console.log(url)
            var a = document.createElement("a")

            a.href = url
            a.download = fileName
            a.target = "_blank"
            document.body.appendChild(a)
            a.click()
            setTimeout(function() {
              document.body.removeChild(a)
              window.URL.revokeObjectURL(url)
            }, 0)
          })
          .catch(error => {
            console.log(error)
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/object-not-found":
                // File doesn't exist
                break
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                addToast("Insufficient permission to access file", {
                  appearance: "error",
                  autoDismiss: true,
                })
                break
              case "storage/canceled":
                // User canceled the upload
                break

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect the server response
                break
            }
          })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleSelectedItemsMenuDropdownBlur = () => {
    console.log("blur")
    if (toggleMenu !== 9999) setToggleMenu(9999)
  }
  const showMenu = (index, verticalEllipse) => {
    try {
      return (
        <>
          {canEdit ? (
            <div
              class={`${
                toggleMenu === index
                  ? "dropdown is-right is-active"
                  : "dropdown is-right"
              }`}
              tabindex="0"
              style={{
                background: "#fafafa",
                background: "transparent",
                backgroundColor: "transparent",
                outline: "none !important",
              }}
              onBlur={handleSelectedItemsMenuDropdownBlur}
            >
              <div className="dropdown-trigger">
                <a
                  className="is-text"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={e => {
                    toggleMenu === 9999
                      ? setToggleMenu(index)
                      : setToggleMenu(9999)
                    e.stopPropagation()
                  }}
                  onBlur={() => {
                    setToggleMenu(9999)
                  }}
                  style={{
                    background: "transparent",
                    backgroundColor: "transparent",
                    outline: "none !important",
                  }}
                >
                  {/* <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          letterSpacing: "0.5px",
                          textTransform: "uppercase",
                          textDecoration: "underline",
                          color: "#4a4a4a",
                          textDecorationColor: "#fec600",
                        }}
                      >
                        {toggleMenu ? "Close" : "Menu"}
                      </span> */}
                  {toggleMenu === index ? (
                    <span
                      className="icon is-small"
                      style={{ color: "#fec600" }}
                    >
                      <i className="fas fa-times"></i>
                    </span>
                  ) : (
                    <span
                      className="icon is-small"
                      style={{ color: "#fec600" }}
                    >
                      {verticalEllipse ? (
                        <i className="fas fa-ellipsis-v-alt"></i>
                      ) : (
                        <i className="fas fa-ellipsis-h-alt"></i>
                      )}
                    </span>
                  )}
                </a>
              </div>
              <div
                className="dropdown-menu"
                id="dropdown-menu"
                role="menu"
                onBlur={() => {
                  setToggleMenu(9999)
                }}
                style={{
                  background: "#fafafa",
                  backgroundColor: "#fafafa",
                  outline: "none !important",
                  border: "1px solid #eaeaea",
                  borderRadius: "4px",
                  fontSize: "14px",
                }}
              >
                <div
                  className="dropdown-content"
                  id="dropdown-menu-item"
                  style={{ backgroundColor: "#fafafa" }}
                >
                  <a
                    className="is-small dropdown-item"
                    id={index}
                    onClick={e => {
                      ConfirmationModal({
                        onConfirm: index => {
                          removeFile(index)
                          setToggleMenu(9999)
                        },
                        sendBackValue: index,
                        title: "Confirmation",
                        text: `Are you sure you want to delete the file?`,
                      })
                      e.stopPropagation()
                    }}
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <i className="far fa-trash-alt delete-icon-color"> </i>
                    <p style={{ marginLeft: "7px" }}> Delete</p>
                  </a>
                </div>
                <div
                  className="dropdown-content"
                  id="dropdown-menu-item"
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                >
                  <a
                    className="is-small dropdown-item"
                    id={index}
                    onClick={e => {
                      setToggleMenu(false)
                      //   // onMoveActivity(e.currentTarget.id)
                      //   onMoveActivity(value.dayId, value.id, itemNo)
                      let fileExt = getFileExtension(
                        state.currentJobInfo.tripDocuments[index].fileNameAlias
                      )
                      if (fileExt !== "--") {
                        let extensionTrimmedFileName = state.currentJobInfo.tripDocuments[
                          index
                        ].fileNameAlias.substring(
                          0,
                          state.currentJobInfo.tripDocuments[index]
                            .fileNameAlias.length - fileExt.length
                        )

                        ConfirmationModalWithInputField({
                          onConfirm: (fileExt, newFileName) => {
                            console.log({ fileExt, newFileName })
                            dispatch({
                              type: "updateTripDocumentFileNameAlias",
                              data: newFileName + fileExt,
                              index: index,
                            })
                            setToggleMenu(9999)
                          },
                          sendBackValue: fileExt,
                          confirmationText: "Save",
                          inputFieldValue: extensionTrimmedFileName,

                          // title: "Edit",
                          text: `Edit file name`,
                        })
                      }
                    }}
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <i className="far fa-pen"> </i>
                    <p style={{ marginLeft: "7px" }}> Rename</p>
                  </a>
                </div>

                <div
                  className="dropdown-content"
                  id="dropdown-menu-item"
                  style={{ backgroundColor: "#fafafa", display: "none" }}
                >
                  <a
                    className="is-small dropdown-item"
                    id={index}
                    onClick={e => {
                      setToggleMenu(false)
                      //   // onMoveActivity(e.currentTarget.id)
                      //   onMoveActivity(value.dayId, value.id, itemNo)

                      e.stopPropagation()
                    }}
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <i className="far fa-file-export"> </i>
                    <p style={{ marginLeft: "7px" }}> Export</p>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            // <a
            //   className="is-small"
            //   id={`Day:${value.dayId}-Item:${value.id}`}
            //   onClick={onRemove}
            // >
            //   <i className="far fa-trash-alt delete-icon-color"></i>
            // </a>
            ""
          )}
        </>
      )
    } catch (error) {}
  }

  const displayTripMates = () => {
    try {
      if (state.currentJobInfo && state.currentJobInfo.roles) {
        let tList = Object.keys(state.currentJobInfo.roles)
        if (tList.length < 2)
          return (
            <a
              style={{
                fontSize: "12px",
                color: "rgba(74,74,74,0.8)",
                fontWeight: "500",
              }}
              onClick={() => {
                navigate(
                  `/app/byottripdetails/${state.tripDetails.tripUid}?tab=settings`
                )
              }}
            >
              <span className="icon is-small" style={{ marginRight: "5px" }}>
                {" "}
                <i className="far fa-user-plus"></i>
              </span>
              <span
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                }}
              >
                Invite trip mate
              </span>
            </a>
          )
        return tList.map((item, index) => {
          if (authContext.uid === item) return ""
          else
            return (
              <div key={item} style={{ marginRight: "8px" }}>
                <TripMatesProfiles uid={item} />
              </div>
            )
        })
      }
    } catch (error) {}
  }

  const showTripMates = () => {
    return (
      <>
        <div style={{ display: "flex" }}>{displayTripMates()}</div>
      </>
    )
  }

  const removeFile = fileIndex => {
    try {
      let fileToDelete = state.currentJobInfo.tripDocuments[fileIndex]
      if (fileToDelete) {
        var deleteTask = dbContext.storage().refFromURL(fileToDelete.fileURL)
        setFileBusy(fileIndex)
        deleteTask
          .delete()
          .then(function() {
            console.log("file deleted")
            setFileBusy(9999)
            dispatch({
              type: "deleteTripDocument",
              data: fileIndex,
            })
          })
          .catch(function(error) {
            setFileBusy(9999)
            addToast("Error deleting the file, please try again in sometime", {
              appearance: "error",
              autoDismiss: true,
            })
          })
      }
    } catch (error) {}
  }
  const pickRightIcon = (fileName, iconSize) => {
    try {
      const regex = /\.[0-9a-z]+$/gm

      const str = fileName
      let m
      var ext = ""
      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          // console.log(`Found match: ${match}`)
          ext = match
        })
      }
      // console.log({ ext })
      if (iconSize === "small") {
        if ([".jpg", ".jpeg", ".bmp", ".png"].includes(ext))
          return <i class="fal fa-file-image fa-2x"></i>
        if ([".doc", ".docx"].includes(ext))
          return <i class="fal fa-file-word fa-2x"></i>
        if ([".xsl", ".xlsx"].includes(ext))
          return <i class="fal fa-file-excel fa-2x"></i>
        if ([".pdf"].includes(ext)) return <i class="fal fa-file-pdf fa-2x"></i>
        return <i class="fal fa-file-alt fa-2x"></i>
      } else {
        if ([".jpg", ".jpeg", ".bmp", ".png"].includes(ext))
          return <i class="fal fa-file-image fa-2x"></i>
        if ([".doc", ".docx"].includes(ext))
          return <i class="fal fa-file-word fa-2x"></i>
        if ([".xsl", ".xlsx"].includes(ext))
          return <i class="fal fa-file-excel fa-2x"></i>
        if ([".pdf"].includes(ext)) return <i class="fal fa-file-pdf fa-2x"></i>
        return <i class="fal fa-file-alt fa-2x"></i>
      }
    } catch (error) {}
  }
  const showFilesTiled = () => {
    try {
      return (
        <div style={{ marginTop: "25px" }}>
          {state.currentJobInfo.tripDocuments &&
          state.currentJobInfo.tripDocuments.length !== 0 ? (
            <>
              {/* <p
                style={{
                  marginBottom: "10px",
                  // maxWidth: "200px",
                }}
              >
                Your files:
              </p> */}

              <div
                style={{
                  display: "flex",
                  //   flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {state.currentJobInfo.tripDocuments.map((item, index) => (
                  <div
                    className="box"
                    style={{
                      marginRight: "20px",
                      width: "200px",
                      height: "200px",
                      border: "1px solid #ebebeb",
                      position: "relative",
                    }}
                    key={item.fileName}
                  >
                    {fileBusy === index ? (
                      <div>
                        <div
                          className="spinner"
                          style={{ zIndex: "20", margin: "37% auto" }}
                        >
                          <div className="rect1"></div>
                          <div className="rect2"></div>
                          <div className="rect3"></div>
                          <div className="rect4"></div>
                          <div className="rect5"></div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="control" style={{ padding: "0px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                              color: "#fec600",
                            }}
                          >
                            {canEdit ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  dispatch({
                                    type: "toggleDocumentStar",
                                    data: index,
                                  })
                                }}
                              >
                                {item.docStarred ? (
                                  <i className="fas fa-star"></i>
                                ) : (
                                  <i className="fal fa-star"></i>
                                )}
                              </div>
                            ) : (
                              <div style={{ minHeight: "24px" }}></div>
                            )}
                            <div style={{ cursor: "pointer" }}>
                              {showMenu(index)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                padding: "10px",
                                color: "#fec600",
                                fontWeight: "300",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                downloadFile(item.fileURL, item.fileNameAlias)
                              }}
                            >
                              {pickRightIcon(item.fileName)}
                            </div>
                            <div>
                              <p
                                style={{
                                  fontSize: "12px",
                                  //   marginTop: "5px",
                                  fontWeight: "400",
                                  wordBreak: "break-all",
                                  textDecoration: "underline",
                                  textDecorationColor: "#fec600",
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  downloadFile(item.fileURL, item.fileNameAlias)
                                }}
                              >
                                {" "}
                                {dottedFileName(item.fileNameAlias, 35)}
                              </p>
                            </div>
                            <div
                              style={{
                                marginTop: "10px",
                                alignSelf: "self-start",
                                display: "none",
                              }}
                            >
                              <div className="tags are-small">
                                <div className="tag is-warning">test</div>
                              </div>
                            </div>
                          </div>

                          {/* <div
                        className="tags has-addons are-medium"
                        style={{ display: "none" }}
                      >
                        <a
                          className="tag is-warning"
                          onClick={() =>
                            downloadFile(item.fileURL, item.fileName)
                          }
                        >
                          {item.fileName}
                        </a>
                        {canEdit && (
                          <a
                            id={item.fileName}
                            className="tag"
                            onClick={removeFile}
                          >
                            <i className="fal fa-trash-alt"></i>
                          </a>
                        )}
                      </div> */}
                        </div>

                        <div
                          style={{
                            borderTop: "0.5px solid #ebebeb",
                            // width: "100%",
                            // backgroundColor: "wheat",
                            margin: "10px auto",
                            position: "absolute",
                            bottom: "10px",
                            left: "20px",
                            right: "20px",
                            height: "40px",
                            display: "none",
                          }}
                        >
                          <div
                            className="trip-mates-container"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "column",
                              maxWidth: "200px",
                              marginRight: "0px",
                              marginLeft: "auto",
                              // marginBottom: "15px",
                            }}
                          >
                            {/* {showTripMates()} */}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              {/* <p style={{ fontSize: "18px", fontWeight: "600" }}>
                Your uploaded file(s):
              </p> */}
              <p
                style={{
                  marginTop: "30px",
                  fontSize: "12px",
                  textAlign: "center",
                  color: "lightgrey",
                }}
              >
                - No files uploaded yet -
              </p>
            </>
          )}
        </div>
      )
    } catch (err) {
      console.log(err)
    }
  }
  const showFilesRows = () => {
    try {
      return (
        <div style={{ marginTop: "25px" }}>
          {state.currentJobInfo.tripDocuments &&
          state.currentJobInfo.tripDocuments.length !== 0 ? (
            <>
              <div
                style={
                  {
                    // display: "flex",
                    // //   flexDirection: "column",
                    // justifyContent: "flex-start",
                    // alignItems: "flex-start",
                    // flexWrap: "wrap",
                  }
                }
              >
                {state.currentJobInfo.tripDocuments.map((item, index) => (
                  <div
                    className="box"
                    style={{
                      marginRight: "20px",
                      width: "100%",
                      // height: "75px",
                      border: "1px solid #ebebeb",
                      position: "relative",
                    }}
                    key={item.fileName}
                  >
                    {fileBusy === index ? (
                      <div>
                        <div
                          className="spinner"
                          style={{ zIndex: "20", margin: "0px auto" }}
                        >
                          <div className="rect1"></div>
                          <div className="rect2"></div>
                          <div className="rect3"></div>
                          <div className="rect4"></div>
                          <div className="rect5"></div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              // padding: "10px",
                              color: "#fec600",
                              fontWeight: "300",
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                            onClick={() => {
                              downloadFile(item.fileURL, item.fileNameAlias)
                            }}
                          >
                            {pickRightIcon(item.fileName, "small")}
                          </div>
                          <div>
                            <p
                              className="trip-documents-name"
                              // style={{
                              //   fontSize: "14px",
                              //   //   marginTop: "5px",
                              //   fontWeight: "400",
                              //   wordBreak: "break-all",
                              //   textDecoration: "underline",
                              //   textDecorationColor: "#fec600",
                              //   cursor: "pointer",
                              //   textAlign: "center",
                              // }}
                              onClick={() => {
                                downloadFile(item.fileURL, item.fileNameAlias)
                              }}
                            >
                              {" "}
                              {dottedFileName(item.fileNameAlias, 35)}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                              color: "#fec600",
                              position: "absolute",
                              right: "15px",
                            }}
                          >
                            {canEdit && (
                              <div
                                style={{
                                  cursor: "pointer",
                                  marginRight: "20px",
                                }}
                                onClick={() => {
                                  dispatch({
                                    type: "toggleDocumentStar",
                                    data: index,
                                  })
                                }}
                              >
                                {item.docStarred ? (
                                  <i className="fas fa-star"></i>
                                ) : (
                                  <i className="fal fa-star"></i>
                                )}
                              </div>
                            )}
                            <div style={{ cursor: "pointer" }}>
                              {showMenu(index, true)}
                            </div>
                          </div>
                        </div>
                        <div className="control" style={{ padding: "0px" }}>
                          <div
                            style={{
                              display: "none",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                              color: "#fec600",
                            }}
                          >
                            {canEdit && (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  dispatch({
                                    type: "toggleDocumentStar",
                                    data: index,
                                  })
                                }}
                              >
                                {item.docStarred ? (
                                  <i className="fas fa-star"></i>
                                ) : (
                                  <i className="fal fa-star"></i>
                                )}
                              </div>
                            )}
                            <div style={{ cursor: "pointer" }}>
                              {showMenu(index)}
                            </div>
                          </div>

                          {/* <div
                        className="tags has-addons are-medium"
                        style={{ display: "none" }}
                      >
                        <a
                          className="tag is-warning"
                          onClick={() =>
                            downloadFile(item.fileURL, item.fileName)
                          }
                        >
                          {item.fileName}
                        </a>
                        {canEdit && (
                          <a
                            id={item.fileName}
                            className="tag"
                            onClick={removeFile}
                          >
                            <i className="fal fa-trash-alt"></i>
                          </a>
                        )}
                      </div> */}
                        </div>

                        <div
                          style={{
                            borderTop: "0.5px solid #ebebeb",
                            // width: "100%",
                            // backgroundColor: "wheat",
                            margin: "10px auto",
                            position: "absolute",
                            bottom: "10px",
                            left: "20px",
                            right: "20px",
                            height: "40px",
                            display: "none",
                          }}
                        >
                          <div
                            className="trip-mates-container"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "column",
                              maxWidth: "200px",
                              marginRight: "0px",
                              marginLeft: "auto",
                              // marginBottom: "15px",
                            }}
                          >
                            {/* {showTripMates()} */}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              {/* <p style={{ fontSize: "18px", fontWeight: "600" }}>
                Your uploaded file(s):
              </p> */}
              <p
                style={{
                  marginTop: "30px",
                  fontSize: "12px",
                  textAlign: "center",
                  color: "lightgrey",
                }}
              >
                - No files uploaded yet -
              </p>
            </>
          )}
        </div>
      )
    } catch (err) {}
  }
  return (
    <div>
      <div className="columns">
        <div className="column is-1-desktop is-0-tablet is-hidden-mobile"></div>
        <div className="column is-10-desktop is-12-tablet is-12-mobile">
          <p
            className="tab-header"
            // style={{
            //   fontSize: "34px",
            //   fontWeight: "800",
            //   color: "rgba(74,74,74,0.8)",
            //   margin: "20px auto",
            //   lineHeight: "1.4",
            //   textAlign: "center",
            // }}
          >
            Trip Documents<span style={{ color: "#fec600" }}>.</span>
          </p>
          <img id="myimg"></img>
          <p className="tab-tagline">
            Organize all your trip documents in once place for easy access. Tip:
            Tag your documents for quick access.
          </p>
          {canEdit && fileUploader()}
          <p className="" style={{ fontSize: "12px" }}>
            Note: All files uploaded here can be viewed by your trip mates.{" "}
            <span
              onClick={() => {
                navigate(
                  `/app/byottripdetails/${state.tripDetails.tripUid}?tab=settings`
                )
              }}
              style={{
                textDecoration: "underline",
                textDecorationColor: "#fec600",
                cursor: "pointer",
              }}
            >
              Invite trip mates
            </span>
          </p>
          <div
            style={{
              display: "flex",
              marginTop: "40px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={
                {
                  // marginBottom: "10px",
                  // maxWidth: "200px",
                }
              }
            >
              Your files:
            </p>
            <div>
              <i
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  color: activeViewType === "tile" ? "#fec600" : "lightgrey",
                }}
                class="fas fa-th-large"
                onClick={() => {
                  setActiveViewType("tile")
                }}
              ></i>
              <i
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  color: activeViewType === "list" ? "#fec600" : "lightgrey",
                }}
                class="fas fa-th-list"
                onClick={() => {
                  setActiveViewType("list")
                }}
              ></i>
            </div>
          </div>
          {activeViewType === "tile" ? showFilesTiled() : showFilesRows()}
        </div>
        <div className="column is-1-desktop is-0-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}

export default TripDocuments
