import React, { useState, useEffect } from "react"
import "./scoreitem.scss"

const ScoreItem = ({ question, featureScored }) => {
  const [score, setScore] = useState(-1)

  const scored = params => {
    try {
      let s = params.currentTarget.id
      setScore(parseInt(s))
    } catch (err) {}
  }
  useEffect(() => {
    if (featureScored) featureScored(score)
  }, [score])

  return (
    <div className="score-container">
      <div className="score-question-container">
        <p>{question}</p>
      </div>
      <div
        className="score-button-container"
        style={{ display: "flex", justifyContent: "start" }}
      >
        <div>
          {/* <span className="button-help-text">not valuable</span> */}
          <button
            id="0"
            className={`${
              score === 0
                ? "button is-warning score-item-button"
                : "button score-item-button"
            }`}
            onClick={scored}
          >
            0
          </button>
        </div>
        <button
          id="1"
          className={`${
            score === 1
              ? "button is-warning score-item-button"
              : "button score-item-button"
          }`}
          onClick={scored}
        >
          1
        </button>
        <button
          id="2"
          className={`${
            score === 2
              ? "button is-warning score-item-button"
              : "button score-item-button"
          }`}
          onClick={scored}
        >
          2
        </button>
        <button
          id="3"
          className={`${
            score === 3
              ? "button is-warning score-item-button"
              : "button score-item-button"
          }`}
          onClick={scored}
        >
          3
        </button>
        <button
          id="4"
          className={`${
            score === 4
              ? "button is-warning score-item-button"
              : "button score-item-button"
          }`}
          onClick={scored}
        >
          4
        </button>
        <div>
          {/* <span className="button-help-text">very valuable</span> */}
          <button
            id="5"
            className={`${
              score === 5
                ? "button is-warning score-item-button"
                : "button score-item-button"
            }`}
            onClick={scored}
          >
            5
          </button>
        </div>
      </div>
    </div>
  )
}

export default ScoreItem
