import React, { useState, useEffect, useContext } from "react"
import PaginateSimple from "../../components/PaginateSimple/PaginateSimple"
import TripLogisticsEntryPage from "../../components/TripCreationComponents"
import TripPreferences from "../../components/TripCreationComponents/TripPreferences"
import { v4 as uuidv4 } from "uuid"
import "./NewTripWizard.scss"
import AuthUserContext from "../../components/session/context"
import TravellerDetailsTable from "../../components/TripCreationComponents/TravellerDetailsTable"
import { TripSummary } from "../../components/TripCreationComponents/TripSummary"
import { FirebaseContext } from "../../components/firebase"
import { dbGet } from "../../utility/dbCRUD"
import { navigate } from "gatsby"
import {
  TripStateProvider,
  useTripStateStore,
} from "../../State/trip-state/trip-state-store"
import PickADesigner from "../../components/TripCreationComponents/PickADesigner"
import Confirm from "../../components/TripCreationComponents/Confirm"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import { Link } from "gatsby"
import * as ROUTES from "./../../constants/routes"
import { analytics } from "../../components/firebase/firebase"
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal"

const NewTripWizardBase = ({ tripUid }) => {
  const [componentInPage, setComponentInPage] = useState("")
  const [loading, setLoading] = useState(true)
  //   This container component will contain all the state for all the paginates screens so that the
  //   user does not lose the date when navigating between pages.
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [state, dispatch] = useTripStateStore()
  const [currentPage, setCurrentPage] = useState(0)
  const totalPages = 5
  useEffect(() => {
    dispatch({ type: "setWizardProgress", data: "0" })
    setComponentInPage(
      <TripLogisticsEntryPage onNext={onNext} onPrevious={onPrevious} />
    )
    if (tripUid) {
      if (dbContext)
        dbContext
          .firestore()
          .collection("trips")
          .doc(`${authContext.uid}`)
          .collection("active-trips")
          .doc(`${tripUid}`)
          .get()
          .then(doc => {
            if (doc) {
              var tripData = doc.data()
              // dispatch({
              //   type: "syncTripDetailsAndTripState",
              //   data: tripData,
              // })
              // Update the trip state with current selected trip details.
              dispatch({
                type: "updateTripState",
                data: tripData,
              })
              setLoading(false)
            }
          })
    } else {
      let tUid = uuidv4()
      dispatch({ type: "updateTripUid", data: tUid })

      if (authContext && dbContext) {
        analytics.logEvent("create_trip", {
          userId: `${authContext.uid}`,
          tripId: tUid,
        })
        dbGet(dbContext, "users", authContext.uid)
          .then(doc => {
            if (doc.exists) {
              dispatch({
                type: "updatePrimaryTravelerDetails",
                data: doc.data(),
                uid: authContext.uid,
              })
              setLoading(false)
            }
          })
          .catch(error => {
            console.log("Error getting document:", error)
          })
      }
    }

    console.log({ authContext })
    return () => {}
  }, [dbContext])

  useEffect(() => {
    return () => {}
  }, [componentInPage])

  useEffect(() => {
    if (currentPage < 5 && currentPage >= 0) {
      pageSelected(currentPage)
    }
    if (currentPage === -1) {
      window.history.back()
    }
    return () => {}
  }, [currentPage])

  const handleSubmit = () => {
    console.log("state pushed to cloud")
    //  dbUpdateNewTrip(dbContext, `${state.tripUid}`, state.uid, state)

    dispatch({
      type: "updateTripCreatedDateTime",
      data: dbContext.firestore.FieldValue.serverTimestamp(),
    })

    dbContext
      .firestore()
      .collection("trips")
      .doc(`${state.uid}`)
      .collection("active-trips")
      .doc(`${state.tripUid}`)
      .set(state)
      .then(function() {
        console.log("Document successfully updated!")
      })
      .catch(error => {
        console.log("Error getting document:", error)
      })
  }

  const onNext = params => {
    setCurrentPage(currentPage + 1)
  }

  const gotoPage = page => {
    setCurrentPage(page)
  }

  const onPrevious = params => {
    setCurrentPage(currentPage - 1)
  }
  const pageSelected = pageNo => {
    switch (pageNo) {
      case "0":
      case 0:
        setComponentInPage(
          <TripLogisticsEntryPage onNext={onNext} onPrevious={onPrevious} />
        )
        dispatch({ type: "setWizardProgress", data: "0" })
        break
      case "1":
      case 1:
        dispatch({ type: "setWizardProgress", data: "25" })
        setComponentInPage(
          <TripPreferences onNext={onNext} onPrevious={onPrevious} />
        )
        break
      case "2":
      case 2:
        dispatch({ type: "setWizardProgress", data: "50" })
        setComponentInPage(
          <TravellerDetailsTable onNext={onNext} onPrevious={onPrevious} />
        )
        break
      case "3":
      case 3:
        dispatch({ type: "setWizardProgress", data: "75" })
        setComponentInPage(
          <TripSummary
            onNext={onNext}
            onPrevious={onPrevious}
            gotoPage={gotoPage}
          />
        )
        break
      case 4:
      case "4":
        dispatch({ type: "setWizardProgress", data: "95" })
        setComponentInPage(
          <PickADesigner onNext={onNext} onPrevious={onPrevious} />
        )
        break
      // case 5:
      // case "5":
      //   setComponentInPage(<Confirm />)
      //   break
      default:
        setComponentInPage(<TripSummary />)
        break
    }
  }

  return (
    <div className="columns is-vcentered">
      <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
      <div
        className="column is-10-desktop is-10-tablet is-12-mobile"
        style={{ marginBottom: "50px" }}
      >
        {loading ? (
          <ShowLoading />
        ) : (
          <>
            {currentPage != 0 ? ( // Do not show in logistics screen as it already has "Exit button"
              <a
                style={{ color: "#4a4a4a" }}
                onClick={() => {
                  ConfirmationModal({
                    onConfirm: () => {
                      navigate(ROUTES.DASHBOARD)
                    },
                    title: "Confirmation",
                    text: `Are you sure? Note: Your changes will be saved. You can continue from where you left.`,
                  })
                }}
              >
                <i
                  className="fas fa-chevron-left"
                  style={{ marginRight: "5px", marginBottom: "10px" }}
                ></i>{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    textDecorationColor: "#fec600",
                  }}
                >
                  Dashboard
                </span>
              </a>
            ) : (
              ""
            )}
            {componentInPage}
          </>
        )}
      </div>
      <div className="column is-1-desktop is-1-tablet is-hidden-mobile">
        {/* <PaginateSimple
          totalPages={totalPages}
          pageSelectedCallback={pageSelected}
          onSubmit={handleSubmit}
        /> */}
        {/* <hr className="hr-branded"></hr> */}
        {/* <TripLogisticsEntryPage /> */}

        {/* <hr className="hr-branded"></hr> */}
      </div>
    </div>
  )
}

const NewTripWizard = ({ tripUid }) => {
  return (
    <TripStateProvider>
      <NewTripWizardBase tripUid={tripUid} />
    </TripStateProvider>
  )
}

export default NewTripWizard
