import React, { useCallback, useContext, useEffect, useMemo } from "react"
import {
  useTripStateStore,
  TripStateProvider,
} from "../../../State/trip-state/trip-state-store"
import swal from "sweetalert"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { FirebaseContext } from "../../../components/firebase"
import is from "is_js"
import {
  useDashboardStateStore,
  DashboardStateProvider,
} from "../../../State/dashboard-state/dashboard-state"
import GoToTop from "../../../components/GoToTop/GoToTop"
import EditableProfileImageField from "../../../components/EditableFormComponents/EditableProfileImageField"
import { useState } from "react"

import { navigate } from "gatsby"
import TripDetailsComponent from "../TripDetailsTab/TripDetailsComponent"
import ItineraryComponent from "../ItineraryTab/ItineraryComponent"
import ChecklistComponent from "../ChecklistTab/ChecklistComponent"
import MessageActivityContainer from "../MessagesTab/MessageActivityContainer"
import ShowError from "../../../components/Error/ShowError"
import TripDesignerCardComponent from "../ItineraryTab/TripDesignerCardComponent"
import ReservationsComponent from "../ReservationTab/ReservationsComponent"
import ShowLoading from "../../../components/ShowLoading/ShowLoading"
import AuthUserContext from "../../../components/session/context"
import PickADesigner from "../../../components/TripCreationComponents/PickADesigner"
import { TripDashboardCombinedProvider } from "../../../State/TripDashboardCombinedProvider"
import ItineraryViewer from "../ItineraryTab/ItineraryViewer"
import ReservationViewer from "../ReservationTab/ReservationViewer"
import ChecklistViewer from "../ChecklistTab/ChecklistViewer"
import TripRecommendationsContainer from "../TripRecommendations/TripRecommendationsContainer"
import { useGlobalStateStore } from "../../../State/global-state/global-state"
import ItineraryCreator from "../ItineraryTab/ItineraryCreator"
import BYOTItineraryCreator from "../ItineraryTab/BYOTItineraryCreator"
import { ToastProvider } from "react-toast-notifications"
import BYOTItineraryComponent from "../ItineraryTab/BYOTItineraryComponent"
import BYOTReservationsComponent from "../ReservationTab/BYOTReservationsComponent"
import queryString from "query-string"
import { functions, analytics } from "../../../components/firebase/firebase"
import CollaboratorDetails from "./CollaboratorDetails"
import TripSettings from "../SettingsTab/TripSettings"
import FlightDetails from "../FlightDetailsTab/FlightDetails"
import AccommodationDetails from "../AccommodationDetailsTab/AccommodationDetails"
import RentalCarsDetails from "../RentalCarsTab/RentalCarsDetails"
import TripNotes from "../NotesTab/TripNotes"
import ChecklistCreator from "../ChecklistTab/ChecklistCreator"
import TripMatesProfiles from "./TripMatesProfiles"
import ReactTooltip from "react-tooltip"
import BYOTMessageActivityComponent from "../MessagesTab/BYOTMessageActivityComponent"
import { motion, useCycle } from "framer-motion"
import TaskList from "../TaskListTab/TaskList"
import Wishlist from "../WishlistTab/Wishlist"
import TripDocuments from "../TripDocumentsTab/TripDocuments"

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"
import QuickNPS from "../../../components/QuickNPS/QuickNPS"
import TripPlannerReferral from "../../../components/TripPlannerReferral/TripPlannerReferral"
const BYOTDetailsForTravelerBase = ({ tripUid, location }) => {
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripDispatch] = useTripStateStore()
  const [globalState, globalStateDispatch] = useGlobalStateStore()
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [loading, setLoading] = useState(true)
  const [tripDesignComplete, setTripDesignComplete] = useState(false)
  const [viewerOnly, setViewerOnly] = useState(true)
  const [isOwner, setIsOwner] = useState(false)
  const [defaultDayId, setDefaultDayId] = useState(0)
  const [toggleMenu, setToggleMenu] = useState(false)
  const [isOpen, toggleOpen] = useCycle(false, true)
  const [editTripName, setEditTripName] = useState(false)
  const [tempTripName, setTempTripName] = useState("")
  const screenWidth = useWindowWidth()
  const [byotSampleTravelPlan, setByotSampleTravelPlan] = useState(false)
  const [showNPS, setShowNPS] = useState(false)
  useEffect(() => {
    console.log("%cDashboard-State", "color: #972426; font-size: 18px", {
      state,
    })
  }, [state])
  useEffect(() => {
    try {
      setTimeout(() => {
        console.log(globalState.npsComplete)
        if (!globalState.npsComplete) {
          setShowNPS(true)
        } else {
          console.log("NPS already completed")
          setShowNPS(false)
        }
      }, 10 * 60 * 1000)
    } catch (err) {
      console.log(err)
    }
  }, [globalState])

  useEffect(() => {
    console.log("%Location", "color: #972426; font-size: 18px", {
      location,
    })

    try {
      if (location) {
        if (location.search) {
          const parameters = queryString.parse(location.search)
          console.log({ parameters })
          if (parameters.tab) {
            dispatch({ type: "setActiveTab", data: parameters.tab })
          } else {
            dispatch({ type: "setActiveTab", data: "itinerary" })
          }
          if (parameters.day) {
            setDefaultDayId(parseInt(parameters.day))
            dispatch({ type: "setActiveDay", data: parameters.day })
          }
        } else {
          if (dispatch) dispatch({ type: "setActiveTab", data: "itinerary" })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [location])
  useEffect(() => {
    console.log("%Trip-State", "color: #972426; font-size: 18px", {
      state,
    })
  }, [tripState])
  useEffect(() => {
    console.log("%Global-State", "color: #FEC6FF; font-size: 18px", {
      globalState,
    })
  }, [globalState])
  useEffect(() => {
    try {
      analytics.logEvent("screen_view", {
        firebase_screen: "byot-trip-details",
        firebase_screen_class: "byot",
      })
    } catch (error) {}
  }, [])
  // useEffect(() => {
  //   let myInterval = setInterval(() => {
  //     console.log("5 minutes up")
  //   }, 5 * 60 * 1000)

  //   return () => {
  //     if (myInterval) clearInterval(myInterval)
  //   }
  // }, [])

  useEffect(() => {
    var unsubscribe = ""

    if (tripUid === "d142061e-4ea2-4886-881f-556f63b9c730") {
      if (!byotSampleTravelPlan) setByotSampleTravelPlan(true)
      console.log("sample travel plan")
      if (dbContext)
        if (tripUid) {
          dbContext
            .firestore()
            .collection("byotSampleTravelPlan")
            .doc(`travelPlan`)
            .get()
            .then(doc => {
              if (doc) {
                dispatch({
                  type: "updateCurrentJobInfo",
                  data: doc.data(),
                })

                setViewerOnly(true)
                // if (tripData.roles) {
                //   if (
                //     ["owner", "editor"].includes(
                //       tripData.roles[`${authContext.uid}`].role
                //     )
                //   )
                //     setViewerOnly(false)
                //   if (tripData.roles[`${authContext.uid}`].role === "owner")
                //     setIsOwner(true)
                // }

                dbContext
                  .firestore()
                  .collection("byotSampleTravelPlan")
                  .doc(`tripDetails`)
                  .get()
                  .then(doc => {
                    if (doc) {
                      var tripData = doc.data()
                      dispatch({
                        type: "syncTripDetailsAndTripState",
                        data: tripData,
                      })
                      // Update the trip state with current selected trip details.
                      tripDispatch({
                        type: "updateTripState",
                        data: tripData,
                      })
                      setLoading(false)
                    }
                  })

                // dispatch({
                //   type: "updateCurrentJobInfo",
                //   data: tripData,
                // })

                // dbContext
                //   .firestore()
                //   .collection("trips")
                //   .doc(`${authContext.uid}`)
                //   .get()
                //   .then(doc => {
                //     if (doc) {
                //       console.log(doc.data())
                //       dispatch({
                //         type: "updateTripManifest",
                //         data: doc.data(),
                //       })

                //       unsubscribe = dbContext
                //         .firestore()
                //         .collection("jobs")
                //         .doc(`${authContext.uid}`)
                //         .collection("active-jobs")
                //         .doc(`${tripData.designerDetails.jobId}`)
                //         .onSnapshot(doc => {
                //           if (doc) {
                //             console.log(doc.data())

                //             setLoading(false)
                //             //dispatch({ type: "setActiveTrips", data: doc.data() })
                //             setLoading(false)
                //           }
                //         })
                //     }
                //   })

                console.log({ tripDesignComplete })
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!")
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error)
            })
        }
      return
    } else {
      if (byotSampleTravelPlan) setByotSampleTravelPlan(false)
      if (dbContext) {
        if (tripUid) {
          dbContext
            .firestore()
            .collection("trips")
            .doc(`${authContext.uid}`)
            .collection("active-trips")
            .doc(`${tripUid}`)
            .get()
            .then(doc => {
              if (doc) {
                var tripData = doc.data()

                if (tripData.tripOwner) {
                  // this is a shared trip. The trip details need to fetched from a owners active-trips location
                  console.log("shared trip")
                  console.log(tripData.tripOwner)

                  dbContext
                    .firestore()
                    .collection("trips")
                    .doc(`${tripData.tripOwner}`)
                    .collection("active-trips")
                    .doc(`${tripUid}`)
                    .get()
                    .then(doc => {
                      console.log("shared trip 2")
                      var tData = doc.data()
                      if (tData.roles) {
                        if (
                          ["owner", "editor"].includes(
                            tData.roles[`${authContext.uid}`].role
                          )
                        )
                          setViewerOnly(false)
                      }
                      dispatch({
                        type: "syncTripDetailsAndTripState",
                        data: tData,
                      })
                      // Update the trip state with current selected trip details.
                      tripDispatch({
                        type: "updateTripState",
                        data: {
                          ...tData,
                          sharedTrip: true,
                          tripOwner: tripData.tripOwner,
                        },
                      })
                      // dispatch({
                      //   type: "updateCurrentJobInfo",
                      //   data: tripData,
                      // })
                      dbContext
                        .firestore()
                        .collection("trips")
                        .doc(`${authContext.uid}`)
                        .get()
                        .then(doc => {
                          if (doc) {
                            console.log(doc.data())
                            dispatch({
                              type: "updateTripManifest",
                              data: doc.data(),
                            })
                            unsubscribe = dbContext
                              .firestore()
                              .collection("jobs")
                              .doc(`${tripData.tripOwner}`)
                              .collection("active-jobs")
                              .doc(`${tData.designerDetails.jobId}`)
                              .onSnapshot(doc => {
                                console.log("shared jobs")
                                if (doc) {
                                  // console.log(doc.data())
                                  dispatch({
                                    type: "updateCurrentJobInfo",
                                    data: doc.data(),
                                  })
                                  setLoading(false)
                                  //dispatch({ type: "setActiveTrips", data: doc.data() })
                                  setLoading(false)
                                }
                              })
                          }
                        })
                    })
                } //Normal trip
                else {
                  if (tripData.roles) {
                    if (
                      ["owner", "editor"].includes(
                        tripData.roles[`${authContext.uid}`].role
                      )
                    )
                      setViewerOnly(false)
                    if (tripData.roles[`${authContext.uid}`].role === "owner")
                      setIsOwner(true)
                  }

                  dispatch({
                    type: "syncTripDetailsAndTripState",
                    data: tripData,
                  })
                  // Update the trip state with current selected trip details.
                  tripDispatch({
                    type: "updateTripState",
                    data: tripData,
                  })
                  // dispatch({
                  //   type: "updateCurrentJobInfo",
                  //   data: tripData,
                  // })

                  dbContext
                    .firestore()
                    .collection("trips")
                    .doc(`${authContext.uid}`)
                    .get()
                    .then(doc => {
                      if (doc) {
                        console.log(doc.data())
                        dispatch({
                          type: "updateTripManifest",
                          data: doc.data(),
                        })

                        unsubscribe = dbContext
                          .firestore()
                          .collection("jobs")
                          .doc(`${authContext.uid}`)
                          .collection("active-jobs")
                          .doc(`${tripData.designerDetails.jobId}`)
                          .onSnapshot(doc => {
                            if (doc) {
                              console.log(doc.data())
                              dispatch({
                                type: "updateCurrentJobInfo",
                                data: doc.data(),
                              })

                              setLoading(false)
                              //dispatch({ type: "setActiveTrips", data: doc.data() })
                              setLoading(false)
                            }
                          })
                      }
                    })
                }

                console.log({ tripDesignComplete })
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!")
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error)
            })
        }
      }
      // console.log("sample travel plan")
    }

    // if (
    //   state.tripDetails.designerDetails !== undefined &&
    //   (state.tripDetails.designerDetails.status === "active" ||
    //     state.tripDetails.designerDetails.status === "declined")
    // ) {
    //   if (state.tripDetails.designerDetails.designerUid !== undefined) {
    //     if (dbContext) {
    //       console.log(
    //         "fetching data from cloud for: ",
    //         state.tripDetails.designerDetails.designerUid
    //       )
    //       dbContext
    //         .firestore()
    //         .collection("users")
    //         .doc(`${state.tripDetails.designerDetails.designerUid}`)
    //         .collection("publicProfile")
    //         .doc(`${state.tripDetails.designerDetails.designerUid}`)
    //         .get()
    //         .then(function(doc) {
    //           if (doc.exists) {
    //             console.log("Document data:", doc.data())
    //             dispatch({
    //               type: "updateDesignerPublicProfile",
    //               data: doc.data(),
    //             })
    //           } else {
    //             // doc.data() will be undefined in this case
    //             console.log("No such document!")
    //           }
    //         })
    //         .catch(function(error) {
    //           console.log("Error getting document:", error)
    //         })
    //     }
    //   } else {
    //     //Show a designer not assigned yet image
    //   }
    //}
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [dbContext])

  const tabItemsList = byotSampleTravelPlan
    ? [
        // { name: "Wishlist", id: "wishlist", icon: "fa-heart" },
        { name: "Itinerary", id: "itinerary", icon: "fa-map-marked-alt" },
        { name: "Notes", id: "notes", icon: "fa-clipboard" },
        { name: "Documents", id: "docs", icon: "fa-file-alt" },
        { name: "Flights", id: "flights", icon: "fa-plane" },
        { name: "Accommodation", id: "lodging", icon: "fa-bed" },
        { name: "Rental Cars", id: "cars", icon: "fa-car" },
        { name: "Reservations", id: "bookings", icon: "fa-ticket" },
        { name: "TaskList", id: "tasklist", icon: "fa-tasks" },

        // { name: "Other Info", id: "recommendations" },
        // { name: "Checklist", id: "checklist", icon: "fa-tasks" },
        // { name: "Chat", id: "messages", icon: "fa-comment-dots" },
        // { name: "Settings", id: "settings", icon: "fa-cog" },

        // { name: "Trip Details", id: "TripDetails" },
      ]
    : [
        // { name: "Wishlist", id: "wishlist", icon: "fa-heart" },
        { name: "Itinerary", id: "itinerary", icon: "fa-map-marked-alt" },
        { name: "Notes", id: "notes", icon: "fa-clipboard" },
        { name: "Documents", id: "docs", icon: "fa-file-alt" },
        { name: "Flights", id: "flights", icon: "fa-plane" },
        { name: "Accommodation", id: "lodging", icon: "fa-bed" },
        { name: "Rental Cars", id: "cars", icon: "fa-car" },
        { name: "Reservations", id: "bookings", icon: "fa-ticket" },
        { name: "Tasks", id: "tasklist", icon: "fa-tasks" },

        // { name: "Other Info", id: "recommendations" },
        // { name: "Checklist", id: "checklist", icon: "fa-tasks" },
        { name: "Messages", id: "messages", icon: "fa-comment-dots" },
        { name: "Settings", id: "settings", icon: "fa-cog" },

        // { name: "Trip Details", id: "TripDetails" },
      ]

  const renderTabItems = () => {
    return (
      <>
        {/* <ReactTooltip /> */}
        {/* <p
          style={{
            fontWeight: "600",
            // borderBottom: "2px solid #fec600",
            width: "fit-content",
            letterSpacing: "0.5px",
            // marginBottom: "5px",
            fontSize: "14px",
            textTransform: "uppercase",
            color: "rgba(74,74,74,0.8)",
            // backgroundColor: "rgba(74,74,74)",
            padding: "9px",
          }}
        >
          {state.tripDetails.tripName === ""
            ? state.tripDetails.tripPrimaryDestination.place
            : state.tripDetails.tripName}{" "}
          <span
            style={{
              fontWeight: "500",
              // borderBottom: "2px solid #fec600",
              width: "fit-content",
              letterSpacing: "0.5px",
              // marginBottom: "5px",
              fontSize: "12px",
              textTransform: "uppercase",
              color: "rgba(74,74,74,0.8)",
              // backgroundColor: "rgba(74,74,74,0.8)",
              padding: "9px",
            }}
          >
            {getDates(state.tripDetails)}
          </span>
        </p> */}
        {tabItemsList.map(item => (
          <li
            key={item.id}
            // data-tip={`${item.name}`}
            className={`${state.activeTab === item.id ? "is-active" : ""}`}
          >
            <a
              id={item.id}
              style={{
                letterSpacing: "0.5px",
                fontSize: "16px",
                fontWeight: "600",
                // textTransform: "uppercase",
              }}
              onClick={e => {
                // dispatch({ type: "setActiveTab", data: `${e.currentTarget.id}` })
                navigate(
                  `/app/byottripdetails/${state.tripDetails.tripUid}?tab=${e.currentTarget.id}`
                )
              }}
            >
              <span className="icon">
                {" "}
                <i
                  className={`far ${item.icon} fa-lg `}
                  style={{ color: "rgba(74,74,74,0.8)" }}
                ></i>
              </span>
              {/* {item.name} */}
            </a>
          </li>
        ))}
      </>
    )
  }
  const menuULVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }
  const menuVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }
  const renderTabItemsMobile = () => {
    return (
      <>
        {/* <ReactTooltip /> */}
        {/* <p
          style={{
            fontWeight: "600",
            // borderBottom: "2px solid #fec600",
            width: "fit-content",
            letterSpacing: "0.5px",
            // marginBottom: "5px",
            fontSize: "14px",
            textTransform: "uppercase",
            color: "rgba(74,74,74,0.8)",
            // backgroundColor: "rgba(74,74,74)",
            padding: "9px",
          }}
        >
          {state.tripDetails.tripName === ""
            ? state.tripDetails.tripPrimaryDestination.place
            : state.tripDetails.tripName}{" "}
          <span
            style={{
              fontWeight: "500",
              // borderBottom: "2px solid #fec600",
              width: "fit-content",
              letterSpacing: "0.5px",
              // marginBottom: "5px",
              fontSize: "12px",
              textTransform: "uppercase",
              color: "rgba(74,74,74,0.8)",
              // backgroundColor: "rgba(74,74,74,0.8)",
              padding: "9px",
            }}
          >
            {getDates(state.tripDetails)}
          </span>
        </p> */}
        {/* <div
          style={{
            fontSize: "14px",
            marginTop: "20px",
            marginLeft: "20px",
            // width: "50px",
            // height: "50px",
            // borderRadius: "50%",
            // background: "#fec600",
            // position: "absolute",
            // zIndex: "999999999",
            // color: "rgba(74,74,74,0.8)",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
          }}
          onClick={() => {
            setToggleMenu(false)
          }}
        >
          <i
            className="fas fa-chevron-double-left"
            style={{ marginRight: "5px", color: "#fec600" }}
          ></i>
          <span style={{ textTransform: "uppercase" }}>Close</span>
        </div> */}
        {tabItemsList.map(item => (
          <motion.li
            variants={menuVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            key={item.id}
            style={{ textAlign: "center", margin: "10px" }}
            // data-tip={`${item.name}`}
            className={`${state.activeTab === item.id ? "is-active" : ""}`}
          >
            <a
              id={item.id}
              style={{
                letterSpacing: "0.5px",
                fontSize: "20px",
                fontWeight: "800",
                // color: "rgba(74,74,74,0.8)",
                color: "#fec600",
                // textTransform: "uppercase",
              }}
              onClick={e => {
                try {
                  // document.body.setAttribute("style", "position:static")
                } catch (err) {
                  console.log(err)
                }
                // dispatch({ type: "setActiveTab", data: `${e.currentTarget.id}` })
                toggleOpen()
                navigate(
                  `/app/byottripdetails/${state.tripDetails.tripUid}?tab=${e.currentTarget.id}`
                )
              }}
            >
              {/* <span className="icon">
                {" "}
                <i
                  className={`far ${item.icon} fa-lg `}
                  style={{ color: "rgba(74,74,74,0.8)" }}
                ></i>
              </span> */}
              {item.name}
            </a>
          </motion.li>
        ))}
        <motion.li
          variants={menuVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          key="67676767"
          style={{ textAlign: "center", margin: "10px" }}
          // data-tip={`${item.name}`}
          // className={`${state.activeTab === item.id ? "is-active" : ""}`}
        >
          <a
            id={"67676767"}
            style={{
              letterSpacing: "0.5px",
              fontSize: "20px",
              fontWeight: "800",
              // color: "rgba(74,74,74,0.8)",
              color: "#fec600",
              // textTransform: "uppercase",
            }}
            onClick={e => {
              // dispatch({ type: "setActiveTab", data: `${e.currentTarget.id}` })
              toggleOpen()
              navigate(`/app/dashboard`)
            }}
          >
            {/* <span className="icon">
                {" "}
                <i
                  className={`far ${item.icon} fa-lg `}
                  style={{ color: "rgba(74,74,74,0.8)" }}
                ></i>
              </span> */}
            Exit to dashboard
          </a>
        </motion.li>
        <motion.li
          variants={menuVariants}
          // whileHover={{ scale: 1.1 }}
          // whileTap={{ scale: 0.95 }}
          key="2232323"
          style={{ textAlign: "center", margin: "30px" }}
        >
          <div
            className="trip-mates-container"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "200px",
              marginRight: "0px",
              marginLeft: "auto",
              marginBottom: "15px",
            }}
          >
            {showTripMates()}
          </div>
        </motion.li>
      </>
    )
  }
  const renderTabItemsMobileSimple = () => {
    return (
      <>
        <>
          {/* <ReactTooltip /> */}

          {tabItemsList.map(item => (
            // <li
            //   key={item.id}
            //   // data-tip={`${item.name}`}
            //   className={`${state.activeTab === item.id ? "is-active" : ""}`}
            // >
            <>
              <a
                id={item.id}
                style={{
                  letterSpacing: "0.5px",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "10px",
                  // textTransform: "uppercase",
                }}
                data-tip={`${item.name}`}
                data-for="trip-menu"
                onClick={e => {
                  // dispatch({ type: "setActiveTab", data: `${e.currentTarget.id}` })
                  navigate(
                    `/app/byottripdetails/${state.tripDetails.tripUid}?tab=${e.currentTarget.id}`
                  )
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {state.activeTab === item.id && (
                    <span
                      style={{
                        width: "8px",
                        height: "8px",
                        background: "#fec600",
                        borderRadius: "50%",
                        marginBottom: "5px",
                        display: "none",
                      }}
                    ></span>
                  )}
                  <span className="icon">
                    {" "}
                    <i
                      className={`${
                        state.activeTab === item.id ? "fas" : "far"
                      } ${item.icon} fa-lg `}
                      style={{
                        color:
                          state.activeTab === item.id
                            ? "#fec600"
                            : "rgba(74,74,74,0.8)",
                      }}
                    ></i>
                  </span>
                </div>
                {/* {item.name} */}
              </a>
            </>
            // </li>
          ))}
        </>
      </>
    )
  }

  const renderNameField = () => {
    try {
      const fName = state.tripDetails.designerDetails.designerProfile.firstName
      const lName = state.tripDetails.designerDetails.designerProfile.lastName
      return <>{fName + " " + lName}</>
    } catch (error) {
      return <>"--"</>
    }
  }

  const displayTripMates = () => {
    try {
      if (state.currentJobInfo && state.currentJobInfo.roles) {
        let tList = Object.keys(state.currentJobInfo.roles)
        if (tList.length < 2)
          return (
            <a
              style={{
                fontSize: "12px",
                color: "rgba(74,74,74,0.8)",
                fontWeight: "500",
              }}
              onClick={() => {
                navigate(`/app/byottripdetails/${tripUid}?tab=settings`)
              }}
            >
              <span className="icon is-small" style={{ marginRight: "5px" }}>
                {" "}
                <i className="far fa-user-plus"></i>
              </span>
              <span
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                }}
              >
                Invite
              </span>
            </a>
          )
        return tList.map((item, index) => {
          if (authContext.uid === item) return ""
          else
            return (
              <div key={item} style={{ marginRight: "8px" }}>
                <TripMatesProfiles uid={item} />
              </div>
            )
        })
      }
    } catch (error) {}
  }
  const displayTripMatesDesktop = () => {
    try {
      if (state.currentJobInfo && state.currentJobInfo.roles) {
        let tList = Object.keys(state.currentJobInfo.roles)
        if (tList.length < 2)
          return (
            <span
              onClick={() => {
                navigate(`/app/byottripdetails/${tripUid}?tab=settings`)
              }}
              className="icon"
              style={{ marginTop: "5px" }}
            >
              {" "}
              <i className="far fa-user-plus fa-lg "></i>
            </span>
          )
        return tList.map((item, index) => {
          if (authContext.uid === item) return ""
          else
            return (
              <div key={item} style={{ marginRight: "8px" }}>
                <TripMatesProfiles uid={item} />
              </div>
            )
        })
      }
    } catch (error) {}
  }

  const showTripMates = () => {
    return (
      <>
        <div
          style={{
            fontWeight: "300",
            // textTransform: "uppercase",
            // letterSpacing: "0.5px",
            fontSize: "11px",
            marginBottom: "4px",
            lineHeight: "1.3",
          }}
        >
          Trip {screenWidth > 1024 ? <br></br> : ""} Mates
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: screenWidth > 1024 ? "column" : "row",
            cursor: "pointer",
          }}
        >
          {screenWidth > 1024 ? displayTripMatesDesktop() : displayTripMates()}
        </div>
      </>
    )
  }

  const renderSelectedComponent = () => {
    switch (state.activeTab) {
      case "TripDetails":
      case "tripDetails":
        return
        if (state.tripDetails.designerDetails.status === "declined") {
          return (
            <>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "20px",
                }}
              >
                {/* Uh oh! <strong>{renderNameField()}</strong> wont be able to work
                on your travel plan, sorry! */}
              </p>
              {/* <p
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginTop: "20px",
                }}
              >
                Please pick a new travel designer
              </p> */}
              <PickADesigner reselectDesigner={true} />{" "}
            </>
          )
        } else {
          return (
            <>
              <TripDesignerCardComponent />
              <hr className="hr-branded"></hr>
              <TripDetailsComponent />
            </>
          )
        }
        break
      case "Itinerary":
      case "itinerary":
        // return <ItineraryComponent />
        return (
          <>
            {" "}
            {viewerOnly ? (
              <BYOTItineraryComponent
                defaultDayId={defaultDayId}
                canEdit={false}
                location={location}
              />
            ) : (
              <BYOTItineraryComponent
                defaultDayId={defaultDayId}
                canEdit={true}
                location={location}
              />
            )}
            {/* <TripDetailsComponent />   <TripDesignerCardComponent /> */}
          </>
        )
        break
      case "Bookings":
      case "bookings":
        return (
          <>
            {" "}
            {viewerOnly ? (
              <BYOTReservationsComponent canEdit={false} />
            ) : (
              <BYOTReservationsComponent canEdit={true} />
            )}
          </>
        )
        break
      case "flights":
        return (
          <>
            {" "}
            {viewerOnly ? (
              <FlightDetails canEdit={false} />
            ) : (
              <FlightDetails canEdit={true} />
            )}
          </>
        )
        break
      case "wishlist":
        return (
          <>
            {" "}
            {viewerOnly ? (
              <Wishlist canEdit={false} />
            ) : (
              <Wishlist canEdit={true} />
            )}
          </>
        )
        break
      case "notes":
        return (
          <>
            {" "}
            {viewerOnly ? (
              <TripNotes canEdit={false} />
            ) : (
              <TripNotes canEdit={true} />
            )}
          </>
        )
        break
      case "lodging":
        return (
          <>
            {" "}
            {viewerOnly ? (
              <AccommodationDetails canEdit={false} />
            ) : (
              <AccommodationDetails canEdit={true} />
            )}
          </>
        )
        break
      case "cars":
        return (
          <>
            {" "}
            {viewerOnly ? (
              <RentalCarsDetails canEdit={false} />
            ) : (
              <RentalCarsDetails canEdit={true} />
            )}
          </>
        )
        break
      case "tasklist":
        return (
          <>
            {" "}
            {viewerOnly ? (
              <TaskList canEdit={false} />
            ) : (
              <TaskList canEdit={true} />
            )}
          </>
        )
        break
      case "docs":
        return (
          <>
            {" "}
            {viewerOnly ? (
              <TripDocuments canEdit={false} />
            ) : (
              <TripDocuments canEdit={true} />
            )}
          </>
        )
        break
      case "Checklist":
      case "checklist":
        // return <ChecklistComponent />
        return <> {viewerOnly ? <ChecklistViewer /> : <ChecklistCreator />}</>
        return
        break
      case "Messages":
      case "messages":
        return (
          <>
            {" "}
            {viewerOnly ? (
              <BYOTMessageActivityComponent
                roomId={state.currentJobInfo.roomId}
                canEdit={false}
              />
            ) : (
              <BYOTMessageActivityComponent
                roomId={state.currentJobInfo.roomId}
                canEdit={true}
              />
            )}
          </>
        )
        break
      case "Settings":
      case "settings":
        return <TripSettings isOwner={isOwner} />
        break
      case "Recommendations":
      case "recommendations":
        return <TripRecommendationsContainer canEdit={false} />
        break
      default:
        return (
          <div style={{ padding: "20px" }}>
            <ShowError />
          </div>
        )
        break
    }
  }
  const getDates = data => {
    try {
      if (data) {
        if (
          data.tripLogistics === undefined ||
          data.tripLogistics.travelDates === undefined ||
          data.tripLogistics.travelDates.start === "" ||
          data.tripLogistics.travelDates.end === ""
        )
          return "--"
        return `${data.tripLogistics.travelDates.start
          .toDate()
          .toLocaleDateString()} - ${data.tripLogistics.travelDates.end
          .toDate()
          .toLocaleDateString()}`
      }
    } catch (e) {
      // console.log({ e })
      return `${new Date(
        data.tripLogistics.travelDates.start
      ).toLocaleDateString()} - ${new Date(
        data.tripLogistics.travelDates.end
      ).toLocaleDateString()}`
    }
  }
  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 0px 0px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: "circle(0px at 0px 0px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  }
  const displayCurrentTab = () => {
    try {
      return (
        <>
          {" "}
          <p
            style={{
              margin: "auto 5px",
              color: "#fec600",
              fontWeight: "300",
              fontSize: "30px",
            }}
          >
            |
          </p>
          <p
            style={{
              margin: "auto 0px",
              color: "rgba(74,74,74,0.7)",
              fontWeight: "300",
              fontSize: "24px",
            }}
          >
            {tabItemsList.map(item => {
              if (item.id === state.activeTab) return item.name
            })}
          </p>
        </>
      )
    } catch (error) {
      return ""
    }
  }
  const onTripPlannerReferralClose = () => {
    console.log("onTripPlannerReferralClose")
    setShowNPS(false)
  }
  return (
    <>
      {/* <GoToTop scrollStepInPx="80" delayInMs="30" /> */}

      {loading ? (
        <ShowLoading />
      ) : (
        <>
          {showNPS && (
            <TripPlannerReferral onClose={onTripPlannerReferralClose} />
          )}
          <ReactTooltip
            backgroundColor="#fafafa"
            textColor="#4a4a4ad9"
            borderColor="#fec600"
            delayShow="500"
            place="right"
            id="trip-menu"
            effect="solid"
          />
          <div className="desktop-menu">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // alignItems: "flex-end",
                paddingBottom: "10px",
                marginRight: "15px",
                // background: "#fec600",
                position: "absolute",
                height: "100%",
                padding: "10px",
                // borderRadius: "4px 4px 0px 0px",
                // paddingTop: "40px",
                // borderRight: "2px solid #fec600",
                // borderBottom: "1px solid #fec600",
                // maxWidth: "512px",
              }}
            >
              <div
                // className="tabs is-centered"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  // paddingBottom: "10px",
                  // borderBottom: "1px solid #fec600",
                  // minWidth: "512px",
                  // flexWrap: "wrap",
                }}
              >
                {renderTabItemsMobileSimple()}
                <a
                  onClick={() => {
                    // onClose()
                    // window.history.back()
                    navigate("/app/dashboard")
                  }}
                  // className="is-text"
                  style={{
                    letterSpacing: "0.5px",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "10px",
                    // textTransform: "uppercase",
                  }}
                  // className="mobile-menu"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    data-tip="Exit to Dashboard"
                    data-for="trip-menu"
                  >
                    <span className="icon">
                      <i
                        style={{ color: "rgba(74,74,74,0.8)" }}
                        className="far fa-sign-out fa-lg fa-flip-horizontal"
                      ></i>
                    </span>
                  </div>

                  {/* <span
                  style={{
                    textDecoration: "underline",
                    textDecorationColor: "#fec600",
                  }}
                >
                  Dashboard
                </span> */}
                </a>
                <div
                  className="trip-mates-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "200px",
                    marginRight: "0px",
                    marginLeft: screenWidth > 1024 ? "none" : "auto",
                    // marginBottom: "15px",
                    marginTop: screenWidth > 1024 ? "60px" : "0px",
                    width: "100%",
                    textAlign: screenWidth > 1024 ? "center" : "left",
                  }}
                >
                  {showTripMates()}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: screenWidth > 1024 ? "95px" : "0px" }}>
            <div
              className="byot-header-destination desktop-menu"
              style={{
                // fontWeight: "800",
                // // borderBottom: "2px solid #fec600",
                // // width: "fit-content",
                // letterSpacing: "0.5px",
                // // marginBottom: "5px",
                // fontSize: "32px",
                // // textTransform: "uppercase",
                // color: "rgba(74,74,74,0.8)",
                // // backgroundColor: "rgba(74,74,74)",
                // padding: "9px",
                textAlign: "left",
                padding: "0px",
                marginBottom: "20px",
              }}
            >
              {editTripName ? (
                <>
                  <input
                    className="trip-name-edit"
                    type="text"
                    tabIndex="1"
                    autoFocus
                    value={tempTripName}
                    onChange={e => {
                      setTempTripName(e.currentTarget.value)
                    }}
                    onBlur={() => {
                      setEditTripName(false)
                    }}
                  ></input>
                </>
              ) : (
                <span style={{ position: "relative", display: "flex" }}>
                  {state.tripDetails.tripName === ""
                    ? state.tripDetails.tripPrimaryDestination.place
                    : state.tripDetails.tripName}{" "}
                  <div
                    className="edit-icon"
                    onClick={() => {
                      setTempTripName(state.tripDetails.tripName)
                      setEditTripName(true)
                    }}
                  >
                    <i className="far fa-pen"></i>
                  </div>
                  {displayCurrentTab()}
                </span>
              )}

              <div
                style={{
                  display: "none",
                  fontWeight: "500",
                  // borderBottom: "2px solid #fec600",
                  // width: "fit-content",
                  letterSpacing: "0.5px",
                  // marginBottom: "5px",
                  fontSize: "10px",
                  textTransform: "uppercase",
                  color: "rgba(74,74,74,0.8)",
                  // backgroundColor: "rgba(74,74,74,0.8)",
                  // padding: "9px",
                  textAlign: "left",
                }}
              >
                {getDates(state.tripDetails)}
              </div>
            </div>
            <div className="byot-details-container">
              <div className="columns desktop-only-width-100">
                {/* <div className="column is-1-desktop is-1-tablet "></div> */}
                <div className="column is-12-desktop is-12-tablet ">
                  {/* {!toggleMenu && (

            )} */}
                  <a
                    onClick={() => {
                      // onClose()
                      // window.history.back()
                      navigate("/app/dashboard")
                    }}
                    // className="is-text"
                    style={{
                      color: "#4a4a4a",
                      border: "none",
                      marginTop: "10px",
                    }}
                    className="show-mobile-only"
                  >
                    <span className="icon">
                      <i className="fal fa-times fa-lg"></i>
                    </span>
                    {/* <span
                  style={{
                    textDecoration: "underline",
                    textDecorationColor: "#fec600",
                  }}
                >
                  Dashboard
                </span> */}
                  </a>
                  <div
                    className="byot-header-destination show-mobile-only"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // fontWeight: "800",
                      // // borderBottom: "2px solid #fec600",
                      // // width: "fit-content",
                      // letterSpacing: "0.5px",
                      // // marginBottom: "5px",
                      // fontSize: "32px",
                      // // textTransform: "uppercase",
                      // color: "rgba(74,74,74,0.8)",
                      // // backgroundColor: "rgba(74,74,74)",
                      // padding: "9px",
                    }}
                  >
                    {editTripName ? (
                      <>
                        <input
                          className="trip-name-edit"
                          type="text"
                          tabIndex="1"
                          autoFocus
                          value={tempTripName}
                          onChange={e => {
                            setTempTripName(e.currentTarget.value)
                          }}
                          onBlur={() => {
                            setEditTripName(false)
                          }}
                        ></input>
                      </>
                    ) : (
                      <span style={{ position: "relative" }}>
                        {state.tripDetails.tripName === ""
                          ? state.tripDetails.tripPrimaryDestination.place
                          : state.tripDetails.tripName}{" "}
                        <div
                          className="edit-icon"
                          onClick={() => {
                            setTempTripName(state.tripDetails.tripName)
                            setEditTripName(true)
                          }}
                        >
                          <i className="far fa-pen"></i>
                        </div>
                      </span>
                    )}

                    <div
                      style={{
                        fontWeight: "500",
                        // borderBottom: "2px solid #fec600",
                        // width: "fit-content",
                        letterSpacing: "0.5px",
                        // marginBottom: "5px",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        color: "rgba(74,74,74,0.8)",
                        // backgroundColor: "rgba(74,74,74,0.8)",
                        // padding: "9px",
                        textAlign: "center",
                      }}
                    >
                      {getDates(state.tripDetails)}
                    </div>
                  </div>
                  <div className="mobile-menu" style={{ marginTop: "5px" }}>
                    <div
                      className="trip-mates-container"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        maxWidth: "200px",
                        marginRight: "0px",
                        marginLeft: "auto",
                        marginBottom: "15px",
                      }}
                    >
                      {showTripMates()}
                    </div>
                    <div
                      // className="mobile-menu"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        // borderRight: "1px solid #ebebeb",
                        flexWrap: "wrap",
                      }}
                    >
                      {renderTabItemsMobileSimple()}
                    </div>
                  </div>

                  {/*
            <motion.nav

              initial={false}
              animate={isOpen ? "open" : "closed"}
              style={{ display: "none" }}
              // variants={sidebar}
              className="mobile-menu"
            >
              {isOpen ? (
                <div
                  style={{
                    fontSize: "14px",
                    letterSpacing: "0.5px",
                  }}
                  onClick={() => {
                    try {
                      // document.body.setAttribute("style", "position:static")
                    } catch (err) {
                      console.log(err)
                    }
                    toggleOpen()
                  }}
                >
                  <i
                    className="fas fa-chevron-double-left"
                    style={{ marginRight: "5px", color: "#fec600" }}
                  ></i>
                  <span style={{ textTransform: "uppercase" }}>Close</span>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "14px",
                    letterSpacing: "0.5px",
                  }}
                  onClick={() => {
                    try {
                      // document.body.setAttribute("style", "position:fixed")
                      // document.body.setAttribute("style", "width:100%")
                    } catch (err) {
                      console.log(err)
                    }
                    toggleOpen()
                  }}
                >
                  <i
                    className="fas fa-chevron-double-right"
                    style={{ marginRight: "5px", color: "#fec600" }}
                  ></i>
                  <span
                    style={{ textTransform: "uppercase", fontWeight: "500" }}
                  >
                    Trip Menu
                  </span>
                </div>
              )}
              <motion.div className="trip-menu" variants={sidebar}>
                <motion.ul
                  style={{ flexDirection: "" }}
                  // variants={menuULVariants}
                >
                  {renderTabItemsMobile()}
                </motion.ul>
              </motion.div>

              <div className="trip-menu" style={{ display: "none" }}>
                <a
                  onClick={() => {
                    // onClose()
                    // window.history.back()
                    navigate("/app/dashboard")
                  }}
                  // className="is-text"
                  style={{ color: "#4a4a4a" }}
                >
                  <span className="icon">
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <span
                    style={{
                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                    }}
                  >
                    Dashboard
                  </span>
                </a>
                <div
                  className="trip-mates-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    maxWidth: "200px",
                    marginRight: "0px",
                    marginLeft: "auto",
                    marginBottom: "15px",
                  }}
                >
                  {showTripMates()}
                </div>
                <div
                  className="tabs"
                  style={{
                    flexDirection: "column",
                    // borderRight: "1px solid #ebebeb",
                  }}
                >
                  <ul style={{ flexDirection: "" }}>{renderTabItems()}</ul>
                </div>
              </div>
            </motion.nav> */}

                  {renderSelectedComponent()}
                  <div
                    style={{
                      marginTop: "26px",
                      marginBottom: "0px",
                      display: "none",
                    }}
                    align="center"
                    className="is-centered destination-header"
                  >
                    <div
                      style={{
                        // display: "flex",
                        alignItems: "flex-end",
                        // textTransform: "uppercase",
                        justifyContent: "center",
                        fontSize: "18px",
                      }}
                    >
                      {/* <p
                  className="place"
                  style={{
                    fontWeight: "600",
                    // borderBottom: "2px solid #fec600",
                    width: "fit-content",
                    letterSpacing: "0.5px",
                    marginBottom: "5px",
                    fontSize: "20px",
                  }}
                >
                  Your{" "}
                  {state.tripDetails.tripName === ""
                    ? state.tripDetails.tripPrimaryDestination.place
                    : state.tripDetails.tripName}{" "}
                  trip
                </p> */}
                      {/* <span
                  style={{
                    color: "#fec600",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  /
                </span> */}
                      {/* <p
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    letterSpacing: "0.5px",
                  }}
                >

                  {getDates(state.tripDetails)}
                </p> */}
                    </div>
                  </div>

                  {/* <div style={{ display: "flex" }}>
              <div style={{ flexGrow: "2" }}>{renderSelectedComponent()}</div>
            </div> */}
                </div>
                {/* <div className="column is-1-desktop is-1-tablet "></div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const BYOTDetailsForTraveler = ({ tripUid, location }) => {
  return (
    <TripStateProvider>
      <DashboardStateProvider>
        <ToastProvider>
          <BYOTDetailsForTravelerBase tripUid={tripUid} location={location} />
        </ToastProvider>
      </DashboardStateProvider>
    </TripStateProvider>
    // <TripDashboardCombinedProvider>
    //   <BYOTDetailsForTravelerBase tripUid={tripUid} />
    // </TripDashboardCombinedProvider>
  )
}
export default BYOTDetailsForTraveler
