import React from "react"
import "./FeedbackComponent.scss"
import { useState, useContext, useEffect } from "react"
import { useGlobalStateStore } from "../../State/global-state/global-state"
import { FirebaseContext } from "../firebase"
import AuthUserContext from "../session/context"
import loadable from "@loadable/component"
// import html2canvas from "html2canvas"
import Dropzone from "react-dropzone"
import { v4 as uuidv4 } from "uuid"
import success from "./../../images/success.png"
import error from "./../../images/error.png"
// const html2canvas = loadable(() => import("html2canvas"))
function FeedbackComponent({ onClose }) {
  const [feedbackType, setFeedbackType] = useState("bug")
  const [message, setMessage] = useState("")
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [imageUrl, setImageUrl] = useState("")
  const [showScreenshot, setShowScreenshot] = useState(false)
  const [progressValue, setProgressValue] = useState(0)
  const [fileInfo, setFileInfo] = useState([])
  const [showProgress, setShowProgress] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [fileToDelete, setFileToDelete] = useState("")
  const [deleteFile, setDeleteFile] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [imageStorageURL, setImageStorageURL] = useState("")
  const [feedbackReadyForDB, setFeedbackReadyForDB] = useState(false)
  const [maxFileLimitError, setMaxFileLimitError] = useState(false)
  const [feedbackId, setFeedbackId] = useState(uuidv4())
  const [showSuccess, setShowSuccess] = useState(false)

  const MAX_FILE_LIMIT = 1

  const clearState = () => {
    setFeedbackReadyForDB(false)
    setImageStorageURL("")
    setImageUrl("")
    setFeedbackType("bug")
    setMessage("")
    setShowScreenshot(false)
  }

  useEffect(() => {
    try {
      if (feedbackReadyForDB) {
        var feedback = {
          type: feedbackType,
          message: message,
          uid: authContext ? authContext.uid : "anonymous",
          feedbackId: feedbackId,
          firstName: globalState.currentUserData
            ? globalState.currentUserData.firstName
            : "",
          lastName: globalState.currentUserData
            ? globalState.currentUserData.lastName
            : "",
          userName: globalState.currentUserData
            ? globalState.currentUserData.userName
            : "",
          userProfileImage: globalState.currentUserData
            ? globalState.currentUserData.userProfileImage
            : "",
          session: globalState.currentSessionRole,
          time: new Date(),
          imageURL: imageStorageURL,
        }
        if (dbContext) {
          dbContext
            .firestore()
            .collection("feedback")
            .doc(`${feedbackId}`)
            .set(feedback)
            .then(() => {
              console.log("feedback updates successfully!")
              clearState()
              setShowSuccess(true)
              setIsBusy(false)
            })
            .catch(err => {
              console.log(err)
              setShowError(true)
              setIsBusy(false)
            })
        }
      }
    } catch (err) {
      console.log(err)
      setShowError(true)
      setIsBusy(false)
    }
  }, [feedbackReadyForDB])

  useEffect(() => {
    console.log({ feedbackId })
  }, [])
  const sendFeedback = () => {}

  const removeFile = e => {
    setFileToDelete(e.currentTarget.id)
    setDeleteFile(true)
  }
  const submitFeedback = () => {
    setIsBusy(true)
    if (imageUrl === "") {
      // no image to upload
      setFeedbackReadyForDB(true)
    } else {
      onSave(imageUrl)
    }
  }

  const getSnapshot = params => {
    try {
      const domElement = document.getElementById("gatsby-focus-wrapper")
      //html2canvas Removed this to save package space
      // html2canvas(domElement, {
      //   onclone: document => {
      //     document.getElementById("snapshot").style.visibility = "hidden"
      //     document.getElementById("showSnapshot").style.visibility = "hidden"
      //   },
      // }).then(canvas => {
      //   const imgData = canvas.toDataURL("image/png")

      //   setImageUrl(imgData)
      //   setShowScreenshot(true)
      // Open the image in a new window
      //  window.open(imgData, "_blank");
      // console.log(imgData)
      // const pdf = new jsPdf();
      // pdf.addImage(imgData, "JPEG", 10, 10);
      // pdf.save(`${new Date().toISOString()}.pdf`);
      // })
    } catch (err) {
      console.log(err)
    }
  }

  // const updateFiles = () => {
  //   setFileInfo([])
  //   var storageRef = dbContext.storage().ref()
  //   var listRef = storageRef.child(
  //     `user-data/${authContext.uid}/designerApplication/`
  //   )

  //   // Find all the prefixes and items.
  //   listRef
  //     .listAll()
  //     .then(function(res) {
  //       var a = []
  //       if (res) {
  //         if (res.items.length >= MAX_FILE_LIMIT) {
  //           setMaxFileLimitError(true)
  //         } else {
  //           setMaxFileLimitError(false)
  //         }
  //       }
  //       res.items.forEach(function(itemRef) {
  //         // All the items under listRef.
  //         console.log({ itemRef })

  //         itemRef.getDownloadURL().then(function(url) {
  //           setFileInfo(fileInfo => [
  //             ...fileInfo,
  //             { name: itemRef.name, url: url },
  //           ])
  //         })
  //       })
  //     })
  //     .catch(function(error) {
  //       // Uh-oh, an error occurred!
  //     })
  // }
  const onDrop = (acceptedFiles, rejectedFiles) => {
    setErrorMsg("")
    setShowError(false)
    console.log({ acceptedFiles })
    console.log({ rejectedFiles })
    if (acceptedFiles.length > 0)
      acceptedFiles.map(item => {
        var reader = new FileReader()
        reader.readAsDataURL(item)
        reader.onloadend = function() {
          setImageUrl(reader.result)
          setShowScreenshot(true)
        }
      })
    if (rejectedFiles.length > 0) {
      setErrorMsg(` File ${rejectedFiles[0].file.name} is too large!`)
      setShowError(true)
    }
  }

  let onSave = newUserProfileImageURL => {
    try {
      var file = newUserProfileImageURL
      const regexp = /\/(([\s\S]*?));/gm
      var result = regexp.exec(file)
      console.log({ result })
      if (
        (result !== null && result[1] === "png") ||
        result[1] === "jpg" ||
        result[1] === "jpeg"
      ) {
        var storageRef = dbContext.storage().ref()
        var uploadTask = storageRef
          .child(`feedback-data/${feedbackId}/feedbackImage.` + result[1])
          .putString(file, "data_url")

        uploadTask.on(
          dbContext.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log("Upload is " + progress + "% done")
            switch (snapshot.state) {
              case dbContext.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused")
                break
              case dbContext.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running")
                break
            }
          },
          function(error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break
              case "storage/canceled":
                // User canceled the upload
                break
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break
            }
          },
          function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                console.log("File available at", downloadURL)
                setImageStorageURL(downloadURL)
                setFeedbackReadyForDB(true)
                //setdbStorageURL(downloadURL)
              })
          }
        )
      } else {
        console.log()
      }
    } catch (err) {
      setShowError(true)
    }
    //Push the new user profile image back to remote db
    //    authUser.updateProfile({photo})
  }

  const showSuccessOrError = () => {
    if (showSuccess)
      return (
        <>
          <img src={success} alt="success" style={{ width: "295px" }} />
          <p style={{ fontSize: "16px", textAlign: "center" }}>
            Feedback received
          </p>
          <p style={{ fontSize: "18px", textAlign: "center" }}>Thank you!</p>
        </>
      )

    if (showError)
      return (
        <>
          <p style={{ fontSize: "20px", textAlign: "center" }}>ERROR</p>
          <img src={error} alt="error" style={{ width: "295px" }} />
          <p style={{ fontSize: "18px", textAlign: "center" }}>
            Please try again in sometime or send an email to{" "}
            <a href="mailto: support@voyayge.com">support@voyayge.com</a> Thank
            you!
          </p>
        </>
      )
  }
  return (
    <div id="snapshot" className="feedback-card fade-in">
      <header className="header">
        <div className="header-text">
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500px",
              color: "#4a4a4a",
              marginRight: "10px",
            }}
          >
            <i
              className="far fa-comment-alt-smile"
              style={{
                color: "#4a4a4a",
                marginRight: "7px",
                paddingTop: "5px",
              }}
            ></i>
            Send Feedback
          </p>
          <a style={{ float: "right", fontSize: "12px" }} onClick={onClose}>
            {/* close */}
            <i className="far fa-times fa-lg"></i>
          </a>
        </div>
      </header>
      <body className="body">
        {showSuccess || showError ? (
          showSuccessOrError()
        ) : (
          <>
            <div className="field">
              <label className="" style={{ fontSize: "14px" }}>
                Feedback Type
              </label>
              <div className="control">
                <div className="buttons has-addons is-centered">
                  <button
                    className={`${
                      feedbackType === "bug" ? "button is-warning" : "button"
                    }`}
                    style={{ fontSize: "13px", width: "99px" }}
                    onClick={() => {
                      setFeedbackType("bug")
                    }}
                  >
                    Bug
                  </button>
                  <button
                    className={`${
                      feedbackType === "improvement"
                        ? "button is-warning"
                        : "button"
                    }`}
                    style={{ fontSize: "13px", width: "99px" }}
                    onClick={() => {
                      setFeedbackType("improvement")
                    }}
                  >
                    Improvement
                  </button>
                  <button
                    className={`${
                      feedbackType === "feature"
                        ? "button is-warning"
                        : "button"
                    }`}
                    style={{ fontSize: "13px", width: "99px" }}
                    onClick={() => {
                      setFeedbackType("feature")
                    }}
                  >
                    Feature
                  </button>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="" style={{ fontSize: "14px" }}>
                Message
              </label>
              <div className="control">
                <textarea
                  value={message}
                  onChange={e => {
                    setMessage(e.currentTarget.value)
                  }}
                  className="textarea"
                ></textarea>
              </div>
            </div>
            {!showScreenshot && (
              <div className="field">
                <button
                  className="button is-warning is-light"
                  style={{ fontSize: "14px", width: "100%" }}
                  onClick={() => getSnapshot()}
                >
                  Attach Screenshot
                </button>
              </div>
            )}
            {showScreenshot && (
              <div className="field">
                <label className="" style={{ fontSize: "14px" }}>
                  Screenshot
                </label>
                <div
                  className="control"
                  style={{
                    textAlign: "center",
                    maxHeight: "125px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a
                    style={{
                      float: "right",
                      fontSize: "14px",
                      textAlign: "end",
                    }}
                    onClick={() => {
                      setShowScreenshot(false)
                      setImageUrl("")
                    }}
                  >
                    remove
                  </a>
                  <img
                    src={imageUrl}
                    style={{ maxHeight: "125px", objectFit: "scale" }}
                  />
                </div>
              </div>
            )}
            {!showScreenshot && <div className="text-separator">or</div>}
            {!showScreenshot && (
              // <div className="field">
              //   <input
              //     className="is-warning is-light"
              //     style={{ fontSize: "14px", width: "100%" }}
              //     onClick={() => {}}
              //     type="file"
              //   ></input>
              // </div>
              <Dropzone
                onDrop={onDrop}
                accept="image/png, image/jpeg, application/pdf"
                minSize={0}
                maxSize={5242880} //5MB
                //disabled={true}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                  acceptedFiles,
                  rejectedFiles,
                }) => {
                  return (
                    <div className="drop-zone-small" {...getRootProps()}>
                      <input {...getInputProps()} />
                      {!isDragActive && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          {/* <i className="far fa-images fa-4x"></i> */}
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Click or drag a file to upload.
                          </p>
                        </div>
                      )}
                      {isDragActive && !isDragReject && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: "500",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Drop the file anywhere inside this box.
                          </p>
                        </div>
                      )}
                      {isDragReject && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: "500",
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            File type not accepted, sorry!
                          </p>
                        </div>
                      )}
                      {/* {isFileTooLarge && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          File is too large, sorry!
                        </p>
                      </div>
                    )} */}
                    </div>
                  )
                }}
              </Dropzone>
            )}
            {/* {showProgress && (
          <progress
            className="progress is-warning is-small"
            value={progressValue}
            max="100"
          >
            {progressValue}
          </progress>
        )} */}

            {/* {fileInfo.length !== 0 ? (
          <p
            style={{
              marginBottom: "10px",
              // maxWidth: "200px",
            }}
          >
            Your files:
          </p>
        ) : (
          ""
        )} */}
            {/* {fileInfo.map(item => (
          <div className="control" style={{ marginBottom: "10px" }}>
            <div className="tags has-addons are-medium">
              <a className="tag is-warning" href={item.url}>
                {item.name}
              </a>
              <a id={item.name} className="tag" onClick={removeFile}>
                <i className="fal fa-trash-alt"></i>
              </a>
            </div>
          </div>
        ))} */}

            <div className="field" style={{ marginTop: "20px" }}>
              <button
                className={`${
                  isBusy ? "button is-warning is-loading" : "button is-warning"
                }`}
                style={{ fontSize: "14px", width: "100%" }}
                onClick={submitFeedback}
              >
                Send feedback
              </button>
            </div>
          </>
        )}
      </body>
    </div>
  )
}

export default FeedbackComponent
