import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"
import ChecklistCreator from "../ChecklistTab/ChecklistCreator"
// import reservations from "./../../../images/reservations/reservations.svg"
import ReservationCreator from "./ReservationCreator"

const BYOTReservationsComponent = ({ canEdit = false }) => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()

  useEffect(() => {
    if (state && state.currentJobInfo) {
      if (state.currentJobInfo.reservationsStatus !== "in-progress") {
        beginWork()
      }
    }
  }, [state])

  const beginWork = e => {
    //Update the required state and db values
    try {
      //get all the date between the trip start and end dates.
      if (dbContext) {
        dbContext
          .firestore()
          .collection("jobs")
          .doc(`${authContext.uid}`)
          .collection("active-jobs")
          .doc(`${state.currentJobInfo.jobUid}`)
          .update({
            reservationsStatus: "in-progress",
            tripReservations: [],
          })
          .then(() => {
            console.log("document update successful")
            //update local state now
            dispatch({ type: "reservationsInProgress", data: true })
          })
          .catch(e => console.log(e))
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/* If no work as commenced yet on itineraries*/}
      {state.currentJobInfo.reservationsStatus === "in-progress" ? (
        <ReservationCreator canEdit={canEdit} />
      ) : (
        <div
          style={{
            margin: "30px auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <a
              style={{
                textAlign: "center",
                fontWeight: "600",
                textDecorationColor: "#fec600",
                color: "rgba(74, 74, 74, 0.8)",
                textDecoration: "none",
              }}
              // className="button is-warning"
              onClick={beginWork}
            >
              <i className="far fa-plus" style={{ marginRight: "5px" }}></i> Add
              your reservations
            </a>
          </div>

          <div>
            {/* <img
              src={reservations}
              style={{ maxWidth: "500px", marginTop: "80px" }}
            ></img> */}
          </div>
        </div>
      )}
    </>
  )
}

export default BYOTReservationsComponent
