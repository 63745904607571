import React, { useState, useEffect, useContext, useCallback } from "react"
import Dropzone from "react-dropzone"
import swal from "sweetalert"
import "./DesignerSignUp.scss"
import {
  ProfileStateProvider,
  useProfileStateStore,
} from "../../State/profile-state/profile-state"
import { ToastProvider, useToasts } from "react-toast-notifications"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "./../../components/firebase"
import { navigate } from "gatsby"
import { responsiveColumnWrap } from "../../utility/general"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import empty from "./../../images/empty.svg"
import { v4 as uuidv4 } from "uuid"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import useRestrictCharacterLength from "../../hooks/useRestrictCharacterLength"
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal"
//const BasicRows = () => <Gallery photos={photos} />

const UploadPhotosBase = () => {
  const userAuthContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [state, dispatch] = useProfileStateStore()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()

  const maxSize = 1048576 //1Mb
  const MAX_FILE_LIMIT = 10 // Max 10 files. - fow now at least.
  const [maxFileLimitError, setMaxFileLimitError] = useState(false)

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [images, setImages] = useState([])
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [showProgress, setShowProgress] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [deleteImage, setDeleteImage] = useState(false)
  const [progressValue, setProgressValue] = useState(0)
  const [fileToDelete, setFileToDelete] = useState("")
  const [showCaptionModal, setShowCaptionModal] = useState(false)
  const [captionText, setCaptionText] = useState("")
  const [captionIndex, setCaptionIndex] = useState(-1)
  const BasicRows = () => <Gallery photos={images} />
  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(100)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  useEffect(() => {
    console.log({ images })
  }, [images])

  useEffect(() => {
    // if (state.profilePhotosLocal !== undefined) {
    //   var testObj = state.profilePhotosLocal
    //   setImages([...images, testObj])
    // }
    //  setImages(state.tempPublicProfile.profilePhotos)
    if (state.dbUpdateRequired) {
      updateDB()
      dispatch({ type: "setDBUpdateRequired", data: false })
    }
  }, [state])

  const checkMaxFiles = () => {
    var storageRef = dbContext.storage().ref()
    var listRef = storageRef.child(
      `user-data/${userAuthContext.uid}/profilePictures/`
    )

    // Find all the prefixes and items.
    listRef
      .listAll()
      .then(function(res) {
        if (res) {
          console.log(res.items.length)
          if (res.items.length >= MAX_FILE_LIMIT * 2) {
            //every image has a 200x200 thumbnail generated hence checking for twice the max count.
            setMaxFileLimitError(true)
          } else {
            setMaxFileLimitError(false)
          }
        }
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!
      })
  }

  useEffect(() => {
    //  document.title = "My Profile | voyay!ge"

    if (dbContext) {
      dbContext
        .firestore()
        .collection("users")
        .doc(`${userAuthContext.uid}`)
        .collection("publicProfile")
        .doc(`${userAuthContext.uid}`)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            checkMaxFiles()
            console.log(doc.data())
            dispatch({ type: "updateInitialState", data: doc.data() })
            setLoading(false)
          } else {
            console.log("No such document!")
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error)
        })
    }

    return () => {}
  }, [dbContext])

  const updateDB = params => {
    try {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("users")
          .doc(`${userAuthContext.uid}`)
          .collection("publicProfile")
          .doc(`${userAuthContext.uid}`)
          .update(state.publicProfile)
          .then(function(doc) {
            console.log("Public profile updated")
          })
          .catch(function(error) {
            console.log("Error getting document:", error)
          })
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (deleteImage) {
      swal({
        title: "Are you sure you want to delete this photo?",
        // text:
        //   "You will lose all the information you have entered so far!",
        // icon: "warning",
        buttons: true,
        dangerMode: false,
        buttons: ["No, cancel it!", "Yes, I am sure!"],
      }).then(willSubmit => {
        if (willSubmit) {
        } else {
          setDeleteImage(false)
          setFileToDelete("")
        }
      })
    }
  }, [deleteImage])

  const removeImage = e => {
    setFileToDelete(e.currentTarget.id)
    setDeleteImage(true)
  }
  const onConfirm = e => {
    // removeImage(e)
    let fIndex = e
    console.log({ e })
    var storageRef = dbContext.storage().ref()
    var deleteTask = storageRef.child(
      `user-data/${userAuthContext.uid}/profilePictures/${fIndex}`
    )
    deleteTask
      .delete()
      .then(function() {
        checkMaxFiles()
        console.log("file deleted")
        dispatch({ type: "deleteTempProfilePhoto", data: fIndex })
        setDeleteImage(false)
      })
      .catch(function(error) {
        setDeleteImage(false)
        addToast("Error deleting the image, please try again in sometime", {
          appearance: "error",
          autoDismiss: true,
        })
      })

    const regex = /\.[0-9a-z]+$/gm
    const str = fIndex
    let m
    var ext = ""
    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        console.log(`Found match: ${match}`)
        ext = match
      })
    }
    var thumb_to_delete = fIndex.replace(ext, `_200x200${ext}`)
    console.log({ fIndex })
    console.log({ thumb_to_delete })
    var deleteTask = storageRef.child(
      `user-data/${userAuthContext.uid}/profilePictures/${thumb_to_delete}`
    )
    deleteTask
      .delete()
      .then(function() {
        checkMaxFiles()
        console.log("thumbnail deleted")
        setDeleteImage(false)
      })
      .catch(function(error) {
        setDeleteImage(false)
        addToast("Error deleting the image, please try again in sometime", {
          appearance: "error",
          autoDismiss: true,
        })
      })
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setErrorMsg("")
    setShowError(false)
    console.log({ acceptedFiles })
    console.log({ rejectedFiles })
    if (acceptedFiles.length > MAX_FILE_LIMIT) {
      setErrorMsg(`You can only upload a maximum of ${MAX_FILE_LIMIT} files.`)
      setShowError(true)
      return
    }
    if (acceptedFiles.length > 0) acceptedFiles.map(item => onSave(item))
    if (rejectedFiles.length > 0) {
      setErrorMsg(` File ${rejectedFiles[0].file.name} is too large!`)
      setShowError(true)
    }
  }

  let onSave = fileObject => {
    var storageRef = dbContext.storage().ref()
    const regex = /\.[0-9a-z]+$/gm
    const str = fileObject.name
    let m
    var ext = ""
    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        console.log(`Found match: ${match}`)
        ext = match
      })
    }
    var id = uuidv4()
    var fileName = `${id}` + ext
    console.log({ fileName })
    var thumbnailFileName = `${id}` + "_200x200" + ext
    console.log({ thumbnailFileName })
    var uploadTask = storageRef
      .child(`user-data/${userAuthContext.uid}/profilePictures/${fileName}`)
      .put(fileObject)

    uploadTask.on(
      dbContext.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log("Upload is " + progress + "% done")
        setShowProgress(true)
        setProgressValue(Number(progress))
        switch (snapshot.state) {
          case dbContext.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused")
            break
          case dbContext.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running")
            break
        }
      },
      function(error) {
        setShowProgress(false)
        setProgressValue(0)
        addToast("Error uploading the file, please try again in sometime", {
          appearance: "error",
          autoDismiss: true,
        })
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break
          case "storage/canceled":
            // User canceled the upload
            break
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break
        }
      },
      function() {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          // var thumbnailRef = storageRef.child(
          //   `user-data/${userAuthContext.uid}/profilePictures/${thumbnailFileName}`
          // )
          // dbContext
          //   .storage()
          //   .ref()
          //   .child(
          //     `user-data/${userAuthContext.uid}/profilePictures/${thumbnailFileName}`
          //   )
          //   .getDownloadURL()
          //   .then(url => {
          //console.log(url)
          console.log(downloadURL)
          var img = new Image()
          img.onload = function() {
            var imageObj = {
              src: downloadURL,
              thumb_src: downloadURL.replace(fileName, thumbnailFileName),
              name: fileName,
              width: this.width,
              height: this.height,
            }
            setIsBusy(true)
            setShowProgress(false)
            setProgressValue(0)
            console.log({ imageObj })
            dispatch({ type: "addNewProfileImage", data: imageObj })
            setTimeout(() => {
              setIsBusy(false)
              checkMaxFiles()
            }, 5000)
            //setPhotos(imageObj)

            // console.log()
          }

          img.src = downloadURL
          //})

          //setdbStorageURL(downloadURL)
        })
      }
    )

    //Push the new user profile image back to remote db
    //    authUser.updateProfile({photo})
  }

  const showImageThumbnails = params => {
    try {
      if (state.publicProfile.profilePhotos.length > 0) {
        // return (
        //   <Carousel
        //     currentIndex={0}
        //     views={state.publicProfile.profilePhotos.map(x => ({
        //       ...x,
        //       // srcset: x.srcSet,
        //       caption: x.title,
        //       //source: { thumbnail: x.thumb_src, regular: x.src },
        //       source: x.thumb_src,
        //     }))}
        //   />
        // )

        return state.publicProfile.profilePhotos.map((item, index) => {
          return (
            <div key={item.name} id={index} className="image-item">
              <img
                id={index}
                src={item.thumb_src}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={e => {
                  // openLightbox(index)
                  setCurrentImage(Number(e.currentTarget.id))
                  setViewerIsOpen(true)
                }}
              />
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <p
                  className=""
                  style={{
                    // width: "100%",
                    color: "#4a4a4a",
                    textAlign: "left",
                    fontSize: "14px",
                    // textTransform: "uppercase",
                    letterSpacing: "0.5px",
                    textDecoration: "underline",
                    cursor: "pointer",
                    textDecorationColor: "#fec600",
                    marginRight: "20px",
                  }}
                  onClick={() => {
                    setCaptionText(item.caption ?? "")
                    setCaptionIndex(index)
                    setShowCaptionModal(true)
                  }}
                >
                  Edit caption
                </p>
                <a
                  style={{ textDecoration: "none", color: "#4a4a4a" }}
                  id={item.name}
                  onClick={e => {
                    ConfirmationModal({
                      onConfirm,
                      title: "Confirmation",
                      text: `Permanently delete the image?`,
                      sendBackValue: e.currentTarget.id,
                    })
                  }}
                >
                  {/* <i className="far fa-trash-alt"></i> */}
                  <i className="fal fa-trash-alt"></i>
                </a>
              </div>
              <div className="image-hover-menu" style={{ display: "none" }}>
                <div className="image-show">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    id={index}
                    onClick={e => {
                      // openLightbox(index)
                      setCurrentImage(Number(e.currentTarget.id))
                      setViewerIsOpen(true)
                    }}
                  >
                    {/* <i className="fal fa-eye"></i> */}
                    View
                  </a>
                </div>
                <div className="image-delete">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    id={item.name}
                    onClick={removeImage}
                  >
                    {/* <i className="far fa-trash-alt"></i> */}
                    <i className="fal fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </div>
          )
        })
      } else {
        return (
          <div className="profile-photo-container">
            <p>- No Photos Uploaded -</p>
            <img src={empty} alt="" />
          </div>
        )
      }
    } catch (err) {
      console.log(err)
      return (
        <div className="profile-photo-container">
          <p>- No Photos Uploaded -</p>
          <img src={empty} alt="" />
        </div>
      )
    }
  }

  return (
    <>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={state.publicProfile.profilePhotos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {x.caption}
                  </p>
                ),
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <div className={`modal ${showCaptionModal ? `is-active` : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          {/* <header className="modal-card-head">
                <p className="modal-card-title">Modal title</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => {
                    setShowContactMessageBox(false)
                  }}
                ></button>
              </header> */}
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Caption:</label>
              <div className="control is-expanded">
                <textarea
                  onChange={e => {
                    // if (check(e.currentTarget.value.length)) return
                    console.log(e.currentTarget.value)
                    if (e.currentTarget.value) {
                      setCaptionText(e.currentTarget.value)
                    } else {
                      setCaptionText("")
                    }
                  }}
                  value={captionText}
                  className="textarea"
                  placeholder="Type your caption here..."
                  maxlength={maxCharactersAllowed}
                ></textarea>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    className={`help is-pulled-right ${
                      reachedMaxLength ? "is-danger" : ""
                    }`}
                  >
                    Characters: {captionText ? captionText.length : "0"}/
                    {maxCharactersAllowed}
                  </p>
                </div>
                {/* {errors.accommodation ? (
                    <p className="help is-danger">
                      Please pick you accommodation preference
                    </p>
                  ) : (
                    <p className="help">pick all that apply</p>
                  )} */}
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <div className="" style={{ marginLeft: "auto" }}>
              <button
                className="button"
                onClick={() => {
                  setCaptionText("")
                  setShowCaptionModal(false)
                }}
              >
                Cancel
              </button>
              <button
                className="button is-warning"
                onClick={() => {
                  setShowCaptionModal(false)
                  dispatch({
                    type: "updateProfileImageCaption",
                    data: captionText,
                    index: captionIndex,
                  })
                  // setBusySendingMessage(true)
                  // sendMessage()
                }}
              >
                Save
              </button>
            </div>
          </footer>
        </div>
      </div>

      {responsiveColumnWrap(
        <div className="columns is-mobile">
          <div className="column is-2-desktop is-3-tablet is-6-mobile">
            <button
              style={{ width: "100%", marginTop: "25px" }}
              className="button is-warning"
              onClick={() => {
                // navigate("/app/designersignup")
                window.history.back()
              }}
            >
              Go back
            </button>
          </div>
          <div className="column is-10-desktop"></div>
        </div>
      )}
      {responsiveColumnWrap(
        <p
          style={{
            fontSize: "24px",
            fontWeight: "600",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          Profile Photos
        </p>
      )}

      {responsiveColumnWrap(
        <p
          style={{
            fontSize: "16px",
            fontWeight: "400",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          Please upload your photos to showcase your travel experiences!
        </p>
      )}
      {responsiveColumnWrap(
        <p
          style={{
            fontSize: "14px",
            fontWeight: "400",
            textAlign: "center",
            verticalAlign: "middle",
          }}
        >
          Note: Maximum file size supported is 5MB
        </p>
      )}

      {responsiveColumnWrap(
        <>
          {maxFileLimitError ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                - File Upload Disabled -
              </p>

              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                Reached max file upload limit of {MAX_FILE_LIMIT} files. Please
                delete files to upload new ones.
              </p>
            </div>
          ) : (
            <Dropzone
              onDrop={onDrop}
              accept="image/png, image/jpeg"
              minSize={0}
              maxSize={5242880} //5MB
              //disabled={true}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                acceptedFiles,
                rejectedFiles,
              }) => {
                return (
                  <div className="drop-zone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {!isDragActive && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        {/* <i className="far fa-images fa-4x"></i> */}
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          {" "}
                          Click here or drag images to upload!
                        </p>
                      </div>
                    )}
                    {isDragActive && !isDragReject && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Drop the file anywhere inside this box.
                        </p>
                      </div>
                    )}
                    {isDragReject && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          File type not accepted, sorry!
                        </p>
                      </div>
                    )}
                    {/* {isFileTooLarge && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "500",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      File is too large, sorry!
                    </p>
                  </div>
                )} */}
                  </div>
                )
              }}
            </Dropzone>
          )}
        </>
      )}
      {responsiveColumnWrap(
        showProgress && (
          <progress
            className="progress is-warning is-large"
            value={progressValue}
            max="100"
          >
            {progressValue}
          </progress>
        )
      )}
      {responsiveColumnWrap(
        showError && (
          <>
            <div className="has-text-danger" style={{ textAlign: "center" }}>
              <i className="fas fa-exclamation-triangle fa-2x"></i> {errorMsg}
            </div>
            <hr className="hr-branded"></hr>
          </>
        )
      )
      // <button
      //   className="button is-warning"
      //   onClick={params => {
      //     setImages([
      //       ...images,
      //       {
      //         src: "https://i.picsum.photos/id/564/1200/600.jpg",
      //         width: 1200,
      //         height: 600,
      //       },
      //     ])
      //   }}
      // >
      //   Add Image
      // </button>
      }
      {responsiveColumnWrap(
        <div className="image-show-container">
          {isBusy ? (
            <ShowLoading timeOutValue={20000} />
          ) : (
            showImageThumbnails()
          )}
        </div>
      )}

      {responsiveColumnWrap()
      // //Todo - Seem to have an issue with row directions. Need to be fixed later.
      // state.publicProfile.length === 0 ? (
      //   ""
      // ) : (
      //   <Gallery
      //     photos={state.publicProfile}
      //     direction={"column"}
      //     columns="3"
      //     onClick={openLightbox}
      //   />
      // )
      }
    </>
  )
}

const UploadPhotos = () => {
  return (
    <ToastProvider>
      <ProfileStateProvider>
        <UploadPhotosBase />
      </ProfileStateProvider>
    </ToastProvider>
  )
}
export default UploadPhotos
