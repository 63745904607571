import React, { useState, useEffect, useContext } from "react"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"
import {
  DashboardStateProvider,
  useDashboardStateStore,
} from "../../State/dashboard-state/dashboard-state"
import { Link } from "gatsby"
import {
  GlobalStateProvider,
  useGlobalStateStore,
} from "../../State/global-state/global-state"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import "./MessagesContainer.scss"
import MessageCard from "./MessageCard"
import nomessage from "./../../images/nomessages.png"

const MessagesContainerBase = () => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [loading, setLoading] = useState(true)
  const [state, dispatch] = useDashboardStateStore()
  const pageSize = 5
  const [showExpandedView, setShowExpandedView] = useState(false)
  const [lastVisible, setLastVisible] = useState(0)
  const [busy, setBusy] = useState(false)
  React.useEffect(() => {
    document.title = "Messages | voyayge"
  }, [])

  useEffect(() => {
    dispatch({ type: "initMessages" })
  }, [])
  useEffect(() => {
    console.log({ state })
  }, [state])

  const getMessages = () => {
    try {
      setBusy(true)
      const getMessageSnapshot = async () => {
        var messageBucketIDs = []
        var msgCollection = []
        let querySnapshot = ""
        if (lastVisible) {
          querySnapshot = await dbContext
            .firestore()
            .collection("chatRooms")
            .orderBy("modifiedOn", "desc")
            .where("owners", "array-contains", `${authContext.uid}`)
            .startAfter(lastVisible)
            .limit(pageSize)
            .get()
        } else {
          querySnapshot = await dbContext
            .firestore()
            .collection("chatRooms")
            .orderBy("modifiedOn", "desc")
            .where("owners", "array-contains", `${authContext.uid}`)
            .limit(pageSize)
            .get()
        }

        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
        messageBucketIDs = querySnapshot.docs.map(snap => snap.id)

        var messageBucketData = querySnapshot.docs.map(snap => snap.data())

        if (messageBucketIDs.length > 0) {
          //there are messages
          const messagesReads = messageBucketIDs.map((item, index) => {
            dbContext
              .firestore()
              .collection("chatRooms")
              .doc(`${item}`)
              .collection("messages")
              .orderBy("sentAt", "desc")
              .limit(1)
              .get()
              .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, " => ", doc.data())
                  dispatch({
                    type: "updateMessages",
                    data: doc.data(),
                    meta: messageBucketData.find(
                      item => item.roomId === doc.data().roomId
                    ),
                  })
                  //msgCollection.push(doc.data())
                })
                setLoading(false)
                setBusy(false)
              })
          })
          const messages = Promise.all(messagesReads)
        } else {
          setLoading(false)
          setBusy(false)
        }

        // if (messageBucketIDs.length > 0) {
        //   //there are messages
        //   console.log("inside loop #$%#%@#%@#$%#")
        //   messageBucketIDs.map((item, index) => {
        //     dbContext
        //       .firestore()
        //       .collection("users")
        //       .doc(`${authContext.uid}`)
        //       .collection("my-messages")
        //       .doc(`${item}`)
        //       .collection("messages")
        //       .limit(1)
        //       .get()
        //       .then(doc => console.log(doc.id, " => ", doc.data()))
        //   })
        // }
        // .onSnapshot(function(snapshot) {
        //   snapshot.docChanges().forEach(function(change) {
        //     if (change.type === "added") {
        //       console.log("New Message: ", change.doc.data())
        //       dispatch({ type: "updateChatMsg", data: change.doc.data() })
        //     }
        //   })
        // })
      }

      if (dbContext) getMessageSnapshot()
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getMessages()
    return () => {}
  }, [dbContext])

  const loadNext = params => {
    getMessages()
  }
  const onExpand = roomId => {
    //console.log({ roomId })
  }
  const populateMessages = () => {
    if (state.messagesViewSnapshot != undefined) {
      if (state.messagesViewSnapshot.length === 0) {
        return (
          <div className="columns is-vcentered is-multiline is-desktop is-tablet">
            <div className="column is-1"></div>
            <div className="column is-5">
              <p style={{ textAlign: "center", fontSize: "18px" }}>- Empty -</p>
            </div>
            <div className="column is-5">
              <img style={{ maxWidth: "250px" }} src={nomessage} alt="" />
            </div>
            <div className="column is-1"></div>
          </div>
        )
      } else
        return state.messagesViewSnapshot.map((item, index) => {
          return <MessageCard key={index} data={item} onExpand={onExpand} />
        })
    }
  }

  return (
    <div>
      {/* <hr className="hr-branded"></hr>
      <h3 align="center" className="subtitle is-3 align-center">
        Messages
      </h3> */}
      {loading ? (
        <ShowLoading />
      ) : (
        <div className="columns is-multiline">
          <div className="column is-2-desktop is-1-tablet"></div>
          <div className="column is-8-desktop is-10-tablet">
            {" "}
            <p
              style={{
                fontSize: "24px",
                fontWeight: "700",
                borderBottom: "2px solid #fec600",
              }}
            >
              Messages
            </p>
          </div>

          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>

          <div className="column is-2-desktop is-1-tablet  is-hidden-mobile"></div>
          <div className="column is-8-desktop is-10-tablet">
            {populateMessages()}
            <button
              disabled={lastVisible ? false : true}
              className={`button is-warning ${busy ? `is-loading` : ``}`}
              onClick={() => {
                loadNext()
              }}
              style={{ width: "100%" }}
            >
              Load more
            </button>
          </div>

          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
        </div>

        // <div className="messages-list"></div>
      )}
    </div>
  )
}

const MessagesContainer = () => {
  return (
    <DashboardStateProvider>
      <MessagesContainerBase />
    </DashboardStateProvider>
  )
}
export default MessagesContainer
