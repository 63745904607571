import is from "is_js"
import React, { useContext, useState } from "react"
import errorImg from "../../images/error.png"
import newsletter from "../../images/newsletter.svg"
import success from "../../images/success.png"
import { analytics } from "../firebase/firebase"
import { FirebaseContext } from "./../../components/firebase"
const NewsletterSubscription = ({ rId }) => {
  const dbContext = useContext(FirebaseContext)
  const [
    newsletterSubscriptionState,
    setNewsletterSubscriptionState,
  ] = useState("default")
  const [newsletterEmailError, setNewsletterEmailError] = useState(false)
  const [newsletterEmail, setNewsletterEmail] = useState("")
  const [newsletterEmailLoading, setNewsletterEmailLoading] = useState(false)

  const showRightContentForNewsletterSubscription = params => {
    switch (newsletterSubscriptionState) {
      case "default":
        return (
          <>
            <div
              style={{
                maxWidth: "175px",
                margin: "0 auto",
                marginBottom: "15px",
              }}
              className="newsletter-header-image"
            >
              <img src={newsletter} alt="" style={{ width: "100%" }} />
            </div>
            <p className="newsletter-header-primary">
              Subscribe to our bi-weekly travel newsletter
            </p>
            <p className="newsletter-header-secondary">
              Join the growing list of travelers who get a carefully curated
              list of articles covering time and money-saving travel tips,
              travel destinations info, and other travel related articles direct
              to their inbox.
            </p>
            <div style={{ maxWidth: "450px", margin: "0 auto" }}>
              <div className="field">
                <div className="control is-expanded">
                  <input
                    style={{ height: "45px" }}
                    className="input "
                    type="text"
                    placeholder="Enter your email"
                    onChange={e => {
                      if (e.currentTarget.value) {
                        setNewsletterEmail(e.currentTarget.value)
                      }
                    }}
                    onFocus={() => {
                      setNewsletterEmailError(false)
                    }}
                  ></input>
                  {newsletterEmailError && (
                    <p className="help is-danger">
                      Please enter a valid email address
                    </p>
                  )}
                </div>
              </div>
              <div className="field">
                <div className="control is-expanded">
                  <button
                    style={{
                      // backgroundColor: "#4a4a4a",
                      // color: "white",
                      width: "100%",
                      fontSize: "18px",
                    }}
                    className={`${
                      newsletterEmailLoading
                        ? "button is-warning is-loading"
                        : "button is-warning"
                    }`}
                    onClick={e => {
                      try {
                        if (is.email(newsletterEmail.trim())) {
                          setNewsletterEmailError(false)
                          setNewsletterEmailLoading(true)

                          if (dbContext) {
                            const increment = dbContext.firestore.FieldValue.increment(
                              1
                            )

                            const statsRef = dbContext
                              .firestore()
                              .collection("newsletter-subscribers")
                              .doc("--count--")
                            const emailRef = dbContext
                              .firestore()
                              .collection("newsletter-subscribers")
                              .doc(`${newsletterEmail.trim()}`)

                            const batch = dbContext.firestore().batch()
                            batch.set(emailRef, {
                              email: `${newsletterEmail.trim()}`,
                              referredId: rId,
                              date: new Date(),
                            })
                            batch.set(
                              statsRef,
                              { counter: increment },
                              { merge: true }
                            )
                            batch
                              .commit()
                              .then(() => {
                                setNewsletterSubscriptionState("success")
                                setNewsletterEmailLoading(false)
                                setNewsletterEmail("")
                                console.log("success")

                                analytics.logEvent("newsletter_subscribed")
                              })
                              .catch(error => {
                                setNewsletterSubscriptionState("error")
                                setNewsletterEmailLoading(false)
                                setNewsletterEmail("")
                                console.log(error)
                              })

                            // db.collection("notification-list")
                            //   .doc(`${email}`)
                            //   .set({ email: email, registeredDate: new Date() })
                            //   .then(() => {
                            //     setState("success")
                            //     console.log("success")
                            //   })
                            //   .catch(error => {
                            //     setState("error")
                            //     console.log(error)
                            //   })
                          } else {
                            console.log("No dbContext")
                          }
                        } else {
                          setNewsletterEmailError(true)
                        }
                      } catch (err) {
                        console.log(err)
                        setNewsletterSubscriptionState("error")
                        setNewsletterEmailLoading(false)
                        setNewsletterEmail("")
                      }
                    }}
                  >
                    Subscribe me
                  </button>
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "7px",
                      textAlign: "center",
                    }}
                  >
                    <strong>Absolutely no spam!</strong> You can always
                    unsubscribe if you are not satisfied.
                  </p>
                </div>
              </div>
            </div>
          </>
        )
        break
      case "inProgress":
        break
      case "error":
        return (
          <div className="fade-in">
            <div style={{ maxWidth: "150px", margin: "0 auto" }}>
              <img src={errorImg} alt="error" style={{ width: "100%" }} />
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              There was a problem adding you to the subscription list, try again
              in sometime. If the issue persists, please reach out to{" "}
              <a href="mailto: support@voyayge.com">support@voyayge.com</a>
            </p>
          </div>
        )
        break
      case "success":
        return (
          <div className="fade-in">
            <div style={{ maxWidth: "300px", margin: "0 auto" }}>
              <img
                src={success}
                alt="congratulations"
                style={{ width: "100%" }}
              />
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
              }}
            >
              You are on the list.
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                // textTransform: "uppercase",
                letterSpacing: "0.5px",
              }}
            >
              We have sent you a confirmation email. If it is not there, please
              check your spam or junk folder.
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "24px",
                marginTop: "7px",
                fontWeight: "500",
              }}
            >
              Thank you!
            </p>
          </div>
        )
        break

      default:
        break
    }
  }

  return <div>{showRightContentForNewsletterSubscription()}</div>
}

export default NewsletterSubscription
