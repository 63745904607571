import React, { useState, useEffect, useMemo } from "react"
import {
  useDashboardStateStore,
  DashboardStateProvider,
} from "../../State/dashboard-state/dashboard-state"
import {
  titleCase,
  getMinutes,
  getHours,
  addZeroBefore,
  dottedText,
  googleMapsURI,
  parseDayAndItemFromString,
  googleDirectionsURI,
  onEdit,
} from "../../utility/general"

function ActivityViewer({
  value,
  onRemove,
  onEditActivity,
  activeTab,
  type,
  canEdit,
}) {
  const [state, dispatch] = useDashboardStateStore()
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const [launchEditForm, setLaunchEditForm] = useState(false)
  //var a = value.activity.activityType
  useEffect(() => {
    console.log({ showMoreInfo })
  }, [showMoreInfo])
  useEffect(() => {
    console.log({ value })
    console.log(`Day:${value.dayId}-Item:${value.id}`)
  }, [])

  const onClose = () => {
    setShowMoreInfo(!showMoreInfo)
  }

  const customNavigateTo = url => {
    window.open(url, "_blank")
  }

  function returnFormattedTime() {
    try {
      return (
        <>
          {" "}
          <div className="top-timeline">
            {value.activity.activityStartTime === undefined
              ? 0
              : `${addZeroBefore(
                  getHours(value.activity.activityStartTime)
                )}:${addZeroBefore(
                  getMinutes(value.activity.activityStartTime)
                )}`}
          </div>
          <div className="bottom-timeline">
            {value.activity.activityEndTime === undefined
              ? 0
              : `${addZeroBefore(
                  getHours(value.activity.activityEndTime)
                )}:${addZeroBefore(
                  getMinutes(value.activity.activityEndTime)
                )}`}
          </div>
        </>
      )
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const showCompact = (value, onClose, onRemove) => {
    console.log({ value })
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // textAlign: "center",
          margin: "10px",
          fontSize: "18px",
          fontWeight: "400",
        }}
      >
        <p
          style={{
            fontSize: "18px",
            fontWeight: "400",
            borderBottom: "2px solid #fec600",
            marginBottom: "30px",
            textAlign: "center",
          }}
        >
          Compact View
        </p>
        <div className="activity-card-type2" style={{ margin: "10px" }}>
          {/* <div className="vertical-line"></div> */}
          <div className="activity-card-data">
            {/* {returnFormattedTime()} */}
            <div className="activity-card-close">
              {canEdit ? (
                <a
                  className="is-small"
                  id={`${value.activity.activityUid}`}
                  onClick={e => {
                    onRemove(e.currentTarget.id)
                  }}
                >
                  <i className="far fa-trash-alt delete-icon-color"></i>
                </a>
              ) : (
                ""
              )}
            </div>

            {/* <div className="activity-card-warnings">
              <a className="is-small">
                <i className="fas fa-exclamation-triangle warning-icon-color"></i>
              </a>
            </div> */}
            <div className="activity-card-name">
              {dottedText(value.activity.activityName, 20)}
            </div>
            {/* <div className="activity-card-type" style={{ fontSize: "14px" }}>
              <i
                className="fas fa-map-marker-alt"
                style={{ marginRight: "5px" }}
              ></i>
              <a
                // onClick={e => {
                //   e.preventDefault()
                //   var a =
                //   console.log("clicked")
                //   customNavigateTo(a)
                // }}
                href={googleMapsURI(value.activity.activityAddress)}
                target="_blank"
              >
                {value.activity.activityAddress}
              </a>
            </div>   */}
            <div
              className="activity-card-type"
              style={{
                marginTop: "-2px",
                fontSize: "10px",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
              }}
            >
              {titleCase(value.activity.activityType)}
            </div>
            {formatDuration() !== "" ? (
              <div
                className="activity-card-type"
                style={{ marginTop: "11px", fontSize: "14px" }}
              >
                <i className="far fa-clock"></i>
                {formatDuration()}
              </div>
            ) : (
              ""
            )}
            <div className="activity-card-details">
              <a
                style={{ paddingRight: "0px", marginRight: "5px" }}
                className="hover-link"
                onClick={() => {
                  onClose()
                }}
              >
                Info
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const onCancel = () => {
    setLaunchEditForm(false)
  }
  const prepareAndLaunchEditForm = () => {
    onEdit()
  }

  const formatDuration = () => {
    try {
      var hours = value.activity.activityDurationHours
      var minutes = value.activity.activityDurationMinutes

      var hrs = hours === "1" ? "Hr" : "Hrs"
      if (hours === undefined && minutes === undefined) return ""
      if (
        hours === undefined ||
        hours === "" ||
        hours === " " ||
        hours === "0"
      ) {
        return ` ${minutes} Min`
      } else {
        if (
          minutes === undefined ||
          minutes === "" ||
          minutes === " " ||
          minutes === "0"
        )
          return ` ${hours} ${hrs}`
        else {
          return ` ${hours} ${hrs} & ${minutes} Min`
        }
      }
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const showTags = params => {
    // return ""
    if (state.activityScratchPad.activityTags) {
      return (
        <div className="tags" style={{ padding: "10px" }}>
          {state.activityScratchPad.activityTags
            ? state.activityScratchPad.activityTags.map(item => {
                if (item)
                  return (
                    <span key={item} className="tag is-warning">
                      {item}
                    </span>
                  )
              })
            : "--"}
        </div>
      )
    }
  }

  const getFormattedAddressForIframe = () => {
    try {
      const gpsRegX = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
      var result = gpsRegX.exec(value.activity.activityAddress)
      console.log({ result })
      let a =
        result !== null
          ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
              value.activity.activityAddress
            )}`
          : `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
              value.activity.activityName
            )}+${encodeURI(value.activity.activityAddress)}`

      console.log({ a })
      return a
    } catch (err) {
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
        value.activity.activityName
      )}+${encodeURI(value.activity.activityAddress)}`
    }
  }
  const showExpanded = (value, onClose, onEditActivity) => {
    return (
      <>
        {launchEditForm ? (
          prepareAndLaunchEditForm()
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              //  textAlign: "center",
              margin: "10px",
              fontSize: "18px",
              fontWeight: "400",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                borderBottom: "2px solid #fec600",
                marginBottom: "30px",
                textAlign: "center",
              }}
            >
              Expanded View
            </p>
            <div
              className="activity-card-type2-expanded"
              style={{ maxWidth: "340px" }}
            >
              <div className="activity-card-data">
                <div
                  className="activity-card-name"
                  style={{ marginTop: "20px", fontSize: "26px" }}
                >
                  {value.activity.activityName}
                </div>

                {/* <div className="activity-card-type" style={{ marginTop: "8px" }}>
          <i className="fas fa-map-marker-alt" style={{ marginRight: "3px" }}></i>5
          Rue Lucien Sampaix, 75010 Paris, France
        </div> */}

                <div
                  className="activity-card-type"
                  style={{ marginTop: "8px", fontSize: "14px" }}
                >
                  <i
                    className="fas fa-map-marker-alt"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <a
                    // onClick={e => {
                    //   e.preventDefault()
                    //   var a =
                    //   console.log("clicked")
                    //   customNavigateTo(a)
                    // }}
                    href={googleMapsURI(value.activity.activityAddress)}
                    target="_blank"
                  >
                    {value.activity.activityAddress}
                  </a>
                </div>
                {value.activity.activityPhone !== undefined ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i
                      className="fas fa-phone-alt"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a href={`tel:${value.activity.activityPhone}`}>
                      value.activity.activityPhone
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {value.activity.activityType === "restaurant" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i
                      className="fas fa-external-link-alt"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a href={value.activity.activityMenuURL} target="_blank">
                      Menu
                    </a>
                  </div>
                ) : (
                  " "
                )}

                {value.activity.activityType === "sightseeing" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    Entry Fee (per person in USD):{" "}
                    {value.activity.activityEntryFee}
                  </div>
                ) : (
                  " "
                )}

                {formatDuration() !== "" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i className="far fa-clock"></i>
                    {formatDuration()}
                  </div>
                ) : (
                  ""
                )}

                {showTags()}

                {/* {value.activity.activityType === "restaurant" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i className="fas fa-dollar-sign"></i>
                    <i className="fas fa-dollar-sign"></i>
                    <i className="fas fa-dollar-sign"></i>
                  </div>
                ) : (
                  " "
                )} */}

                <hr className="hr-branded"></hr>
                <iframe
                  title="Paris"
                  src={getFormattedAddressForIframe()}
                  width="100%"
                  height="200px"
                  samesite="strict|lax|none"
                  allowFullScreen
                ></iframe>
                <hr className="hr-branded"></hr>
                {/* <div className="activity-card-type" style={{ marginTop: "5px" }}>
          <i className="fas fa-phone" style={{ marginRight: "3px" }}></i>+33 1 82 28
          00 80
        </div> */}

                <div
                  className="activity-card-type"
                  style={{ marginTop: "16px", fontSize: "16px" }}
                >
                  <strong>Description: </strong>
                  <p>{value.activity.activityDescription}</p>
                </div>

                <div
                  className="activity-card-type"
                  style={{ marginTop: "16px", fontSize: "16px" }}
                >
                  <strong>Personal info from your designer:</strong>
                  <p>{value.activity.personalPoints}</p>
                </div>
                <hr className="hr-branded"></hr>
                <div
                  className="activity-card-type"
                  style={{ marginTop: "16px", fontSize: "16px" }}
                >
                  <strong>
                    <p>Additional Info:</p>
                  </strong>
                </div>

                <div
                  className="activity-card-type"
                  style={{ marginTop: "4px", fontSize: "16px" }}
                >
                  <i
                    className="fas fa-external-link-alt"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <a href={value.activity.activityURL1} target="_blank">
                    {dottedText(value.activity.activityURL1, 30)}
                  </a>
                </div>
                <div
                  className="activity-card-type"
                  style={{ marginTop: "4px", fontSize: "16px" }}
                >
                  <i
                    className="fas fa-external-link-alt"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <a href={value.activity.activityURL2} target="_blank">
                    {dottedText(value.activity.activityURL2, 30)}
                  </a>
                </div>
                {value.activity.activityURL3 !== undefined && (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "4px", fontSize: "16px" }}
                  >
                    <i
                      className="fas fa-external-link-alt"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a href={value.activity.activityURL3} target="_blank">
                      {dottedText(value.activity.activityURL3, 30)}
                    </a>
                  </div>
                )}

                <hr className="hr-branded"></hr>
                <div className="field is-grouped is-grouped-centered">
                  <p className="control">
                    {canEdit ? (
                      <button
                        className="button is-light"
                        style={{
                          marginTop: "0px",
                          marginBottom: "20px",
                          marginRight: "10px",
                          width: "100px",
                        }}
                        onClick={() => {
                          //setLaunchEditForm(true)
                          dispatch({
                            type: "prepareActivityScratchPad",
                            data: value.activity,
                          })
                          onEditActivity()
                        }}
                      >
                        Edit
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      className="button is-warning"
                      style={{
                        marginTop: "0px",
                        marginBottom: "20px",
                        marginLeft: "10px",
                        width: "100px",
                      }}
                      onClick={() => {
                        onClose()
                      }}
                    >
                      Close
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}{" "}
      </>
    )
  }

  const LaunchGoogleDirections = e => {
    try {
      var results = parseDayAndItemFromString(e.currentTarget.id)
      if (results !== []) {
        var dayId = results[1]
        var itemId = results[2]
        var activitiesLen =
          state.currentJobInfo.designerWorkspace.itinerary[dayId].activities
            .length
        var selectedItemIndex = state.currentJobInfo.designerWorkspace.itinerary[
          dayId
        ].activities.findIndex(item => item.id === itemId)
        if (
          //filter out element not found, first element and last element cases
          selectedItemIndex !== -1 ||
          selectedItemIndex !== 0 ||
          selectedItemIndex !== activitiesLen - 1
        ) {
          console.log({ selectedItemIndex })
          var previousActivity =
            state.currentJobInfo.designerWorkspace.itinerary[dayId].activities[
              selectedItemIndex - 1
            ].activity
          var nextActivity =
            state.currentJobInfo.designerWorkspace.itinerary[dayId].activities[
              selectedItemIndex + 1
            ].activity

          console.log(
            previousActivity.activityName + previousActivity.activityAddress
          )
          console.log(nextActivity.activityName + nextActivity.activityAddress)
          var directionUrl = googleDirectionsURI(
            previousActivity.activityName,
            previousActivity.activityAddress,
            nextActivity.activityName,
            nextActivity.activityAddress,
            value.activity.commuteMode
          )
          if (directionUrl !== "") return directionUrl
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const displayRightIcons = value => {
    switch (value.activity.commuteMode) {
      case "walk":
      case "walking":
        return (
          <i
            className="fas fa-walking fa-lg"
            style={{
              marginRight: "33px",
              color: "rgba(51, 51, 51, 0.8)",
              float: "left",
              marginTop: "5px",
              marginLeft: "18px",
            }}
          ></i>
        )
        break
      case "car":
      case "driving":
        return (
          <i
            className="fas fa-car fa-lg"
            style={{
              marginRight: "33px",
              color: "rgba(51, 51, 51, 0.8)",
              float: "left",
              marginTop: "5px",
              marginLeft: "15px",
            }}
          ></i>
        )
        break
      case "train":
      case "transit":
        return (
          <i
            className="fas fa-subway fa-lg"
            style={{
              marginRight: "33px",
              color: "rgba(51, 51, 51, 0.8)",
              float: "left",
              marginTop: "5px",
              marginLeft: "20px",
            }}
          ></i>
        )
        break

      case "bicycling":
        return (
          <i
            className="fas fa-biking fa-lg"
            style={{
              marginRight: "33px",
              color: "rgba(51, 51, 51, 0.8)",
              float: "left",
              marginTop: "5px",
              marginLeft: "10px",
            }}
          ></i>
        )
        break

      default:
        break
    }
  }

  const checkCardType = value => {
    if (value.activity.activityType === "commute") {
      if (
        value.activity.commuteMode === undefined ||
        value.activity.commuteMode === ""
      ) {
        return (
          <div className="activity-card-type-commute">
            <div className="top-connector"></div>
            <div className="remove-add-commute-button">
              {canEdit ? (
                <a
                  className="is-small"
                  id={`Day:${value.dayId}-Item:${value.id}`}
                  onClick={onRemove}
                >
                  <i
                    className="far fa-trash-alt"
                    style={{
                      color: "rgba(51, 51, 51, 0.8)",
                    }}
                  ></i>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="bottom-connector"></div>
          </div>
        )
      } else {
        return (
          <div className="activity-card-type-commute-active">
            <div className="vertical-line"></div>
            {/* <div className="top-connector"></div> */}
            <div className="commute-card-data">
              {formatDuration() !== "" ? (
                <div
                  className="activity-card-type"
                  style={{ marginTop: "5px" }}
                >
                  {displayRightIcons(value)}
                  <div
                    className="commute-time"
                    style={{
                      marginLeft: "70px",
                      marginRight: "10px",
                      marginTop: "8px",
                      // float: "",
                    }}
                  >
                    <i
                      className="far fa-clock"
                      style={{ marginRight: "0px" }}
                    ></i>
                    {formatDuration()}
                  </div>
                  <div
                    className="commute-card-close"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "8px",
                      color: "rgba(51, 51, 51, 0.8)",
                    }}
                  >
                    {canEdit ? (
                      <a
                        className="is-small"
                        id={`Day:${value.dayId}-Item:${value.id}`}
                        onClick={onRemove}
                      >
                        <i
                          className="far fa-trash-alt"
                          style={{
                            color: "rgba(51, 51, 51, 0.8)",
                          }}
                        ></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="commute-card-close"
                    style={{
                      position: "absolute",
                      right: "35px",
                      top: "8px",
                      color: "rgba(51, 51, 51, 0.8)",
                    }}
                  >
                    {canEdit ? (
                      <a
                        className="is-small"
                        id={`Day:${value.dayId}-Item:${value.id}`}
                        onClick={() => {
                          dispatch({
                            type: "prepareActivityScratchPad",
                            data: value.activity,
                          })
                          // onAddCommute()
                        }}
                      >
                        <i
                          className="fas fa-edit delete-icon-color"
                          style={{
                            color: "rgba(51, 51, 51, 0.8)",
                          }}
                        ></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  {canEdit ? (
                    <div
                      className="commute-card-close"
                      style={{
                        position: "absolute",
                        right: "65px",
                        top: "10px",
                        color: "rgba(51, 51, 51, 0.8)",
                      }}
                    >
                      <a
                        className="is-small"
                        id={`Day:${value.dayId}-Item:${value.id}`}
                        onClick={e => {
                          customNavigateTo(LaunchGoogleDirections(e))
                        }}
                      >
                        <i
                          className="fal fa-directions delete-icon-color fa-lg"
                          style={{
                            color: "rgba(51, 51, 51, 0.8)",
                          }}
                        ></i>
                      </a>
                    </div>
                  ) : (
                    <div
                      className="commute-card-close"
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "10px",
                        color: "rgba(51, 51, 51, 0.8)",
                      }}
                    >
                      <a
                        className="is-small"
                        id={`Day:${value.dayId}-Item:${value.id}`}
                        onClick={e => {
                          customNavigateTo(LaunchGoogleDirections(e))
                        }}
                      >
                        <i
                          className="fal fa-directions delete-icon-color fa-lg"
                          style={{
                            color: "rgba(51, 51, 51, 0.8)",
                          }}
                        ></i>
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            {/* <div className="bottom-connector"></div> */}
          </div>
        )
      }
    }
  }

  return (
    <div>
      {type === "commute" ? (
        checkCardType(value)
      ) : (
        <div className="cards-viewer">
          {/* {showMoreInfo
            ? showExpanded(value, onClose, onEditActivity)
            : showCompact(value, onClose, onRemove)} */}
          {showCompact(value, onClose, onRemove)}
          {showExpanded(value, onClose, onEditActivity)}
        </div>
      )}
    </div>
  )
}
export default ActivityViewer
