import React, { useState, useEffect, useContext } from "react"
import swal from "sweetalert"
import AuthUserContext from "../../components/session/context"
import { ToastProvider, useToasts } from "react-toast-notifications"
import { FirebaseContext } from "../../components/firebase"
import {
  DashboardStateProvider,
  useDashboardStateStore,
} from "../../State/dashboard-state/dashboard-state"
import "./DesignerSignUp.scss"
import AddGeneralInfo from "./AddGeneralInfo"
import { Link, navigate } from "gatsby"
import * as ROUTES from "./../../constants/routes"
import { useGlobalStateStore } from "../../State/global-state/global-state"
import Pending from "../../components/Pending/Pending"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import { v4 as uuidv4 } from "uuid"
import Dropzone from "react-dropzone"
import welcome from "../../images/welcome.png"
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal"
function DesignerSignUpContainerBase() {
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [state, dispatch] = useDashboardStateStore()
  const [toggleCheckDomestic, setToggleCheckDomestic] = useState(false)
  const { addToast } = useToasts()
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [showProgress, setShowProgress] = useState(false)

  const [isBusy, setIsBusy] = useState(false)
  const [deleteFile, setDeleteFile] = useState(false)

  const [progressValue, setProgressValue] = useState(0)
  const [fileInfo, setFileInfo] = useState([])
  const [showThankYou, setShowThankYou] = useState(false)
  //const [uploadSuccessful, setUploadSuccessful] = useState(false)
  const [fileToDelete, setFileToDelete] = useState("")
  const [toggleCheckInternational, setToggleCheckInternational] = useState(
    false
  )
  const [maxFileLimitError, setMaxFileLimitError] = useState(false)
  const MAX_FILE_LIMIT = 7
  const rowItem = (column1 = "", column2 = "", column3 = "", column4 = "") => {
    return (
      <div className="columns is-mobile is-vcentered">
        <div className="column is-3-desktop is-2-tablet is-1-mobile">
          {column1}
        </div>
        <div className="column is-5-desktop is-7-tablet is-8-mobile">
          {column2}
        </div>
        <div className="column is-1-desktop  is-1-tablet is-1-mobile">
          {column3}
        </div>
        <div className="column is-3-desktop is-2-tablet is-1-mobile">
          {column4}
        </div>
      </div>
    )
  }
  const rowItem3 = (column1 = "", column2 = "", column3 = "") => {
    return (
      <div className="columns is-mobile is-vcentered">
        <div className="column is-3-desktop is-2-tablet is-1-mobile">
          {column1}
        </div>
        <div className="column is-6-desktop is-8-tablet is-10-mobile">
          {column2}
        </div>
        <div className="column is-3-desktop is-2-tablet is-1-mobile">
          {column3}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (deleteFile) {
      swal({
        title: "Are you sure you want to delete this file?",
        buttons: true,
        dangerMode: false,
        buttons: ["No, cancel it!", "Yes, I am sure!"],
      }).then(willSubmit => {
        if (willSubmit) {
          var storageRef = dbContext.storage().ref()
          console.log(fileToDelete)
          var deleteTask = storageRef.child(
            `user-data/${authContext.uid}/designerApplication/${fileToDelete}`
          )
          deleteTask
            .delete()
            .then(function() {
              console.log("file deleted")
              updateFiles()
              setDeleteFile(false)
            })
            .catch(function(error) {
              setDeleteFile(false)
              addToast(
                "Error deleting the file, please try again in sometime",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              )
            })
        } else {
          setDeleteFile(false)
          setFileToDelete("")
        }
      })
    }
  }, [deleteFile])

  const updateFiles = () => {
    setFileInfo([])
    var storageRef = dbContext.storage().ref()
    var listRef = storageRef.child(
      `user-data/${authContext.uid}/designerApplication/`
    )

    // Find all the prefixes and items.
    listRef
      .listAll()
      .then(function(res) {
        var a = []
        if (res) {
          if (res.items.length >= MAX_FILE_LIMIT) {
            setMaxFileLimitError(true)
          } else {
            setMaxFileLimitError(false)
          }
        }
        res.items.forEach(function(itemRef) {
          // All the items under listRef.
          console.log({ itemRef })
          if (itemRef.name) {
            // This check is to make sure we are not listing the thumbnails generated for images files in the storage bucket.
            if (itemRef.name.includes("200x200")) return
          }
          itemRef.getDownloadURL().then(function(url) {
            setFileInfo(fileInfo => [
              ...fileInfo,
              { name: itemRef.name, url: url },
            ])
          })
        })
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!
      })
  }

  useEffect(() => {
    try {
      updateFiles()
    } catch (err) {
      console.log({ err })
    }
  }, [dbContext])

  useEffect(() => {
    console.log({ globalState })
  }, [globalState])

  const addJobToDesignerApprovalQueue = params => {
    if (dbContext) {
      const publicProfileRef = dbContext
        .firestore()
        .collection("users")
        .doc(`${authContext.uid}`)
        .collection("publicProfile")
        .doc(`${authContext.uid}`)

      const designerApprovalsRef = dbContext
        .firestore()
        .collection("designer-approvals")
        .doc(`${authContext.uid}`)

      const batch = dbContext.firestore().batch()

      batch.set(
        publicProfileRef,
        {
          designerApplication: {
            status: "submitted",
            dateSubmitted: new Date(),
          },
        },
        { merge: true }
      )

      batch.set(designerApprovalsRef, { jobSubmitted: true })

      batch
        .commit()
        .then(() => {
          console.log("Document successfully written!")
          globalDispatch({
            type: "updateDesignerApprovalStatus",
            data: "pending",
          })
          setShowThankYou(true)
          // navigate(ROUTES.THANKYOU, {
          //   replace: true,
          //   state: {
          //     message:
          //       "Your request has been submitted. We'll review it and get back to you in 24-48 hours.",
          //   },
          // })
        })
        .catch(error => {
          console.error("Error writing document: ", error)
          addToast(
            "Sorry!, error submitting application, please try again later",
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
        })

      //   dbContext
      //     .firestore()
      //     .collection("users")
      //     .doc(`${authContext.uid}`)
      //     .collection("publicProfile")
      //     .doc(`${authContext.uid}`)
      //     .update({
      //       designerApplication: {
      //         status: "submitted",
      //         dateSubmitted: new Date(),
      //       },
      //     })
      //     .then(() => {
      //       dbContext
      //         .firestore()
      //         .collection("designer-approvals")
      //         .doc(`${authContext.uid}`)
      //         .set({ jobSubmitted: true })
      //         .then(function() {
      //           console.log("Document successfully written!")
      //           globalDispatch({
      //             type: "updateDesignerApprovalStatus",
      //             data: "pending",
      //           })
      //           navigate(ROUTES.THANKYOU, {
      //             replace: true,
      //             state: {
      //               message:
      //                 "Your request has been submitted. We'll review it and get back to you in 24-48 hours.",
      //             },
      //           })
      //         })
      //     })
      //     .catch(function(error) {
      //       console.error("Error writing document: ", error)
      //       addToast(
      //         "Sorry!, error submitting application, please try again later",
      //         {
      //           appearance: "error",
      //           autoDismiss: true,
      //         }
      //       )
      //     })
    }
  }

  const downloadPDF = async () => {
    try {
      var gsReference = dbContext
        .storage()
        .refFromURL(
          "gs://voyayge-dev-9adfe.appspot.com/designer-application-assets/Sample Travel Plan.pdf"
        )

      gsReference
        .getDownloadURL()
        .then(function(url) {
          // This can be downloaded directly:
          console.log(url)
          var a = document.createElement("a")

          a.href = url
          a.download = "travel.pdf"
          document.body.appendChild(a)
          a.click()
          setTimeout(function() {
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          }, 0)
        })
        .catch(function(error) {
          // Handle any errors
          addToast(
            "Sorry!, error downloading the file. Please try again in sometime. If the issue persists please reach out to support@voyayge.com",
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
        })
    } catch (err) {
      console.log(err)
      addToast(
        "Sorry!, error downloading the file. Please try again in sometime. If the issue persists please reach out to support@voyayge.com",
        {
          appearance: "error",
          autoDismiss: true,
        }
      )
    }
  }

  const removeFile = e => {
    setFileToDelete(e.currentTarget.id)
    setDeleteFile(true)
  }

  const onConfirm = () => {
    addJobToDesignerApprovalQueue()
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setErrorMsg("")
    setShowError(false)
    console.log({ acceptedFiles })
    console.log({ rejectedFiles })
    if (acceptedFiles.length > 0) acceptedFiles.map(item => onSave(item))
    if (rejectedFiles.length > 0) {
      setErrorMsg(` File ${rejectedFiles[0].file.name} is too large, sorry!`)
      setShowError(true)
    }
  }

  let onSave = fileObject => {
    var storageRef = dbContext.storage().ref()
    const regex = /\.[0-9a-z]+$/gm
    const str = fileObject.name
    let m
    var ext = ""
    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        console.log(`Found match: ${match}`)
        ext = match
      })
    }

    var fileName = `${fileObject.name}` // + ext
    console.log({ fileName })
    var uploadTask = storageRef
      .child(`user-data/${authContext.uid}/designerApplication/${fileName}`)
      .put(fileObject)

    uploadTask.on(
      dbContext.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log("Upload is " + progress + "% done")
        setShowProgress(true)
        setProgressValue(Number(progress))
        switch (snapshot.state) {
          case dbContext.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused")
            break
          case dbContext.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running")
            break
        }
      },
      function(error) {
        console.log(error)
        setShowProgress(false)
        setProgressValue(0)
        addToast("Error uploading the file, please try again in sometime", {
          appearance: "error",
          autoDismiss: true,
        })
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break
          case "storage/canceled":
            // User canceled the upload
            break
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break
        }
      },
      function() {
        // Upload completed successfully, now we can get the download URL

        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          setIsBusy(true)
          setShowProgress(false)
          setProgressValue(0)
          var listRef = storageRef.child(
            `user-data/${authContext.uid}/designerApplication/`
          )
          updateFiles()
        })
      }
    )
  }

  const wereFilesUploaded = () => {
    try {
      if (fileInfo === []) {
        return false
      }
      if (fileInfo.length > 0) return true
    } catch (err) {
      return false
    }
  }
  const showAdditionalNote = () => {
    try {
      if (globalState.adminData.additionalNote) {
        return (
          <div>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "50px",
                fontSize: "14px",
              }}
            >
              <i
                style={{ marginRight: "8px", color: "#fec600" }}
                class="fas fa-comment-alt-exclamation fa-lg"
              ></i>
              <span style={{ fontWeight: "600", marginRight: "3px" }}>
                Note from application processing team:
              </span>{" "}
              {globalState.adminData.additionalNote}
            </p>{" "}
            <hr className="hr-branded"></hr>
          </div>
        )
      }
    } catch (error) {
      return ""
    }
  }

  const showMainPage = params => {
    return (
      <div style={{ width: "100%", marginBottom: "60px" }}>
        {rowItem3(
          "",
          <p
            style={{
              fontSize: "24px",
              fontWeight: "600",
              textAlign: "center",
              borderBottom: "2px solid #fec600",
              marginTop: "25px",
              textTransform: "uppercase",
              letterSpacing: "0.5px",
            }}
          >
            Designer Onboarding
          </p>,
          "",
          ""
        )}
        {rowItem3(
          "",
          <>
            <div
              style={{
                maxHeight: "200px",
                textAlign: "center",
                margin: "25px auto",
              }}
            >
              <img
                src={welcome}
                alt=""
                style={{ maxHeight: "200px", margin: "0 auto" }}
              />
            </div>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              WELCOME!
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              Complete the following steps to complete your onboarding.
            </p>
          </>,
          "",
          ""
        )}
        {rowItem3("", <hr className="hr-branded"></hr>, "", "")}
        {rowItem3("", <> {showAdditionalNote()}</>, "", "")}
        {/* {rowItem3(
          "",
          <p style={{ fontSize: "18px", fontWeight: "600", textAlign: "" }}>
            Step 1 - Pick your services
          </p>,
          "",
          ""
        )}
        {rowItem3(
          "",
          <p style={{ fontSize: "16px" }}>
            US Domestic itineraries – You would need to prepare a custom travel
            plan for a US domestic vacation.
          </p>,
          <a
            className="no-decoration"
            onClick={e => setToggleCheckDomestic(!toggleCheckDomestic)}
          >
            <i
              className={`${
                toggleCheckDomestic
                  ? "far fa-check-square fa-2x"
                  : "far fa-square fa-2x"
              }`}
              style={{ color: "#ffc600" }}
            ></i>
          </a>,
          ""
        )}
        {rowItem3(
          "",
          <p style={{ fontSize: "16px" }}>
            International itineraries – You would need to prepare a custom
            travel plan for an international vacation.
          </p>,
          <a
            className="no-decoration"
            onClick={e =>
              setToggleCheckInternational(!toggleCheckInternational)
            }
          >
            <i
              className={`${
                toggleCheckInternational
                  ? "far fa-check-square fa-2x"
                  : "far fa-square fa-2x"
              }`}
              style={{ color: "#ffc600" }}
            ></i>
          </a>,
          ""
        )}
        {rowItem3("", <hr className="hr-branded"></hr>, "", "")} */}
        {rowItem3(
          "",
          <>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "",
                color: "",
              }}
            >
              Step 1 <br></br>Lets get your profile ready
            </p>
            <p
              style={{ fontSize: "14px", fontWeight: "400", marginTop: "5px" }}
            >
              This is what travelers look at when they want to select a travel
              designer.
            </p>
          </>,
          "",
          ""
        )}
        {rowItem3(
          "",
          <Link
            to="/app/designersignup/generalinfo"
            style={{ fontSize: "14px" }}
            className="link"
          >
            Update your General Info
          </Link>,
          "",
          ""
        )}
        {rowItem3(
          "",
          <Link
            to="/app/designersignup/additionalinfo"
            style={{ fontSize: "14px" }}
            className="link"
          >
            Tell the traveler about yourself
          </Link>,
          "",
          ""
        )}
        {rowItem3(
          "",
          <Link
            to={ROUTES.EDIT_PHOTOS}
            style={{ fontSize: "14px" }}
            className="link"
          >
            Upload your travel photos
          </Link>,
          "",
          ""
        )}
        {rowItem3("", <hr className="hr-branded"></hr>, "", "")}
        {rowItem3(
          "",
          <>
            <p style={{ fontSize: "16px", fontWeight: "600", textAlign: "" }}>
              Step 2<br></br>Complete a sample travel plan
            </p>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            >
              Using the provided traveler requirements please create amd submit
              a sample travel itinerary. <br></br> <strong>Note:</strong> You
              can pick a destination city of your choice.
            </p>
            <Link
              to={ROUTES.SAMPLE_TRAVEL_PLAN_FORM}
              style={{ fontSize: "14px" }}
              className="link"
            >
              Sample travel plan
            </Link>

            {/* <p
              style={{ fontSize: "14px", fontWeight: "400", marginTop: "5px" }}
            >
              Using the sample traveler requirements outlined in the document
              create a personalized travel plan and upload it.
            </p> */}
          </>,
          "",
          ""
        )}
        {false &&
          rowItem3(
            "",
            <div
              style={{
                maxWidth: "400px",
                margin: "0 auto",
                cursor: "pointer",
              }}
              onClick={e => {
                downloadPDF()
              }}
            >
              <div
                style={{
                  maxWidth: "36px",
                  margin: "0 auto",
                }}
              >
                <i
                  className="fal fa-file-pdf fa-3x"
                  style={{
                    width: "100%",
                  }}
                ></i>
              </div>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "600",
                  marginTop: "15px",
                  marginBottom: "15px",
                  padding: "10px",
                }}
              >
                Click to download sample plan traveler requirements
              </p>
            </div>,
            "",
            ""
          )}
        {false &&
          rowItem3(
            "",
            <>
              {maxFileLimitError ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    - File Upload Disabled -
                  </p>

                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    Reached max file upload limit of {MAX_FILE_LIMIT} files.
                    Please delete files to upload new ones.
                  </p>
                </div>
              ) : (
                <Dropzone
                  onDrop={onDrop}
                  //accept="application/png, image/jpeg, application/pdf, application/doc, application/docx"
                  minSize={0}
                  maxSize={5242880 * 2} //10MB
                  // disabled="true"
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                    acceptedFiles,
                    rejectedFiles,
                  }) => {
                    return (
                      <div className="drop-zone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!isDragActive && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            {/* <i className="far fa-images fa-4x"></i> */}
                            <p
                              style={{
                                fontSize: "15px",
                                fontWeight: "400",
                                textAlign: "center",
                                verticalAlign: "middle",
                                padding: "5px",
                              }}
                            >
                              Click here or drag completed travel plan from your
                              computer to upload.
                            </p>
                          </div>
                        )}
                        {isDragActive && !isDragReject && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Drop the file anywhere inside this box.
                            </p>
                          </div>
                        )}
                        {isDragReject && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              File type not accepted, sorry!
                            </p>
                          </div>
                        )}
                        {/* {isFileTooLarge && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          File is too large, sorry!
                        </p>
                      </div>
                    )} */}
                      </div>
                    )
                  }}
                </Dropzone>
              )}
            </>,
            "",
            ""
          )}
        {false &&
          rowItem3(
            "",
            showProgress && (
              <progress
                className="progress is-warning is-small"
                value={progressValue}
                max="100"
              >
                {progressValue}
              </progress>
            ),
            "",
            ""
          )}
        {false &&
          rowItem3(
            "",
            fileInfo.length !== 0 ? (
              <>
                <p
                  style={{
                    marginBottom: "10px",
                    // maxWidth: "200px",
                  }}
                >
                  Your files:
                </p>
                {fileInfo.map(item => (
                  <div className="control" style={{ marginBottom: "10px" }}>
                    <div className="tags has-addons are-medium">
                      <a className="tag is-warning" href={item.url}>
                        {item.name}
                      </a>
                      <a id={item.name} className="tag" onClick={removeFile}>
                        <i className="fal fa-trash-alt"></i>
                      </a>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <p style={{ fontSize: "18px", fontWeight: "600" }}>
                  Your uploaded file(s):
                </p>
                <p style={{ marginTop: "10px" }}>- No files uploaded yet -</p>
              </>
            ),
            ""
          )}

        {rowItem3(
          "",
          <div
            style={{
              margin: "40px auto",
              maxWidth: "200px",
            }}
            onClick={params => {
              // if (wereFilesUploaded()) {
              //   swal({
              //     title:
              //       "Are you sure you want to submit your profile for review?",
              //     buttons: true,
              //     dangerMode: false,
              //     buttons: ["No, cancel it!", "Yes, I am sure!"],
              //   }).then(willSubmit => {
              //     if (willSubmit) {
              //       addJobToDesignerApprovalQueue()
              //     }
              //   })
              //   return
              // } else {
              //   swal({
              //     text: "Please upload your completed sample travel plan.",
              //     // buttons: true,
              //     dangerMode: false,
              //     // buttons: ["No, cancel it!", "Yes, I am sure!"],
              //   })
              //   return
              // }
              ConfirmationModal({
                onConfirm,
                title: "Confirmation",
                text: `Have you completed updating your profile & the sample travel plan?`,
                confirmationText: "Yes, Submit",
                cancelText: "No, Cancel",
              })
            }}
          >
            <button
              className="button is-warning"
              style={{
                width: "100%",
              }}
            >
              {" "}
              Lets go, I'm done
            </button>
          </div>,
          ""
        )}
        {rowItem3("", <hr className="hr-branded"></hr>, "", "")}
      </div>
    )
  }

  const checkCurrentApplicationStatus = () => {
    if (
      globalState.currentSessionRole === "" ||
      globalState.adminData === undefined
    ) {
      //show loading
      return <ShowLoading />
    } else {
      if (globalState.adminData.designerApprovalStatus === "pending") {
        // Show pending
        return (
          <Pending
            heading="We are on it!"
            message="Reviewing your application, we'll get back to you in 24-48 hours."
          />
        )
      } else {
        if (
          globalState.adminData.designerApprovalStatus === "none" ||
          globalState.adminData.designerApprovalStatus === "sentBack"
        ) {
          // show main page
          return showMainPage()
        }
        if (globalState.adminData.designerApprovalStatus === "approved") {
          // show dashboard page
          navigate(ROUTES.DASHBOARD)
        }
      }
    }
  }

  return (
    <>
      {checkCurrentApplicationStatus()}
      {/* {showMainPage()} */}
      {/* <AddGeneralInfo /> */}
    </>
  )
}

const DesignerSignUpContainer = () => {
  return (
    <ToastProvider>
      <DashboardStateProvider>
        <DesignerSignUpContainerBase />
      </DashboardStateProvider>
    </ToastProvider>
  )
}
export default DesignerSignUpContainer
