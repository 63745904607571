import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import { find } from "lodash"
import AuthUserContext from "../../components/session/context"

function MessageCard({ data, onExpand }) {
  const [toggleStar, setToggleStar] = useState(false)
  const authContext = useContext(AuthUserContext)
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }

  useEffect(() => {
    console.log({ data })
  }, [])

  const canRender = () => {
    console.log({ data })
    console.log({ authContext })
    if (data !== undefined && authContext !== undefined) {
      return true
    } else {
      return false
    }
  }

  const getProfileUrl = () => {
    try {
      if (data.recipientInfo) {
        return data.recipientInfo.uid === authContext.uid
          ? data.senderInfo.profileUrl
          : data.recipientInfo.profileUrl
      }
      if (data.creatorInfo) {
        // Support got new message format.
        return data.creatorInfo.uid === authContext.uid
          ? find(data.usersInfo, item => item.uid !== authContext.uid)
              .profileUrl
          : data.creatorInfo.profileUrl
      }
    } catch (error) {
      return ""
    }
  }
  const getUserName = () => {
    try {
      if (data.recipientInfo) {
        return data.recipientInfo.uid === authContext.uid
          ? data.senderInfo.name
          : data.recipientInfo.name
      }
      if (data.creatorInfo) {
        // Support got new message format.
        return data.creatorInfo.uid === authContext.uid
          ? find(data.usersInfo, item => item.uid !== authContext.uid).name
          : data.creatorInfo.name
      }
    } catch (error) {
      return ""
    }
  }
  const getUid = () => {
    try {
      if (data.recipientInfo) {
        return data.recipientInfo.uid === authContext.uid
          ? data.senderInfo.uid
          : data.recipientInfo.uid
      }
      if (data.creatorInfo) {
        // Support got new message format.
        return data.creatorInfo.uid === authContext.uid
          ? find(data.usersInfo, item => item.uid !== authContext.uid).uid
          : data.creatorInfo.uid
      }
    } catch (error) {
      return ""
    }
  }
  return (
    <>
      {canRender() ? (
        <>
          <div
            className=""
            style={{
              marginBottom: "15px",
              borderBottom: "1px solid #fec600",
              padding: "10px",
            }}
          >
            <article className="media">
              <div
                className="media-left"
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={params => {
                  window.open(`/app/userprofiles/${getUid()}`, "_blank")
                }}
              >
                <figure className="image is-32x32">
                  <img
                    // className="is-rounded"
                    style={{ borderRadius: "4px" }}
                    src={getProfileUrl()}
                    alt="message source profile image"
                  ></img>
                </figure>
              </div>
              <div className="media-content">
                <div className="content" style={{ marginBottom: "10px" }}>
                  <p style={{ marginBottom: "7px" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        letterSpacing: "0.5px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={params => {
                        window.open(`/app/userprofiles/${getUid()}`, "_blank")
                      }}
                    >
                      {getUserName()}
                    </span>{" "}
                    <span style={{ fontSize: "10px", letterSpacing: "0.5px" }}>
                      {new Intl.DateTimeFormat("en-US", options).format(
                        data.sentAt.toDate()
                      )}
                    </span>{" "}
                  </p>
                  <span style={{ marginTop: "15px" }}>{data.message}</span>
                </div>
                <div className="media-right" style={{ marginLeft: "0px" }}>
                  <a
                    className="is-small"
                    aria-label="reply"
                    // style={{ color: "rgba(78,78,78,0.8" }}
                  >
                    <Link
                      to={`/app/messageactivity/${data.roomId}/${getUid()}`}
                    >
                      <div
                        onClick={e => {
                          onExpand(data.roomId)
                        }}
                        style={{
                          cursor: "pointer",
                          color: "rgba(78,78,78,1)",
                          fontSize: "10px",
                          textDecoration: "underline",
                          textDecorationColor: "#fec600",
                          letterSpacing: "0.5px",
                          textTransform: "uppercase",
                          fontWeight: "500",
                        }}
                      >
                        View Thread{" "}
                        <i className="fas fa-chevron-double-right"></i>
                      </div>
                    </Link>
                  </a>
                </div>
              </div>
            </article>
          </div>
          {/* <hr className="hr-branded"></hr> */}
          {/* <div className="message-container">
            <div className="message-avatar">
              <Link
                to={`/app/userprofiles/${
                  data.recipientInfo.uid === authContext.uid
                    ? data.senderInfo.uid
                    : data.recipientInfo.uid
                }`}
              >
                <img
                  className="is-rounded-avatar-messages"
                  src={
                    data.recipientInfo.uid === authContext.uid
                      ? data.senderInfo.profileUrl
                      : data.recipientInfo.profileUrl
                  }
                  alt="message source profile image"
                />
              </Link>
            </div>
            <div className="message-date-name">
              <div className="message-name">
                {data.recipientInfo.uid === authContext.uid
                  ? data.senderInfo.name
                  : data.recipientInfo.name}
              </div>
              <div className="message-date">
                {new Intl.DateTimeFormat("en-US", options).format(
                  data.sentAt.toDate()
                )}
              </div>
            </div>
            <div className="message-content">
              {data.message}
              <Link
                to={`/app/messageactivity/${data.roomId}/${
                  data.recipientInfo.uid === authContext.uid
                    ? data.senderInfo.uid
                    : data.recipientInfo.uid
                }`}
              >
                <div
                  onClick={e => {
                    onExpand(data.roomId)
                  }}
                  style={{ cursor: "pointer" }}
                >
                  show more...
                </div>
              </Link>
            </div>
            <div className="message-icon">
              <div className="margin-10-bottom">
                <a
                  className="no-decoration"
                  onClick={e => setToggleStar(!toggleStar)}
                >
                  <i
                    className={`${
                      toggleStar ? "fas fa-star red-star" : "far fa-star"
                    }`}
                  ></i>
                </a>
              </div>
              <a className="no-decoration">
                <i className="far fa-trash-alt"></i>
              </a>
            </div>
          </div> */}
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default MessageCard
