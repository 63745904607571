import { navigate } from "gatsby"
import is from "is_js"
import { find } from "lodash"
import { LazyLoadImage } from "react-lazy-load-image-component"
import React, { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { FirebaseContext } from "../../../components/firebase"
import { functions, analytics } from "../../../components/firebase/firebase"
import { NotificationModal } from "../../../components/NotificationModal/NotificationModal"
import AuthUserContext from "../../../components/session/context"
import TripCardClean from "../../../components/TripCards/TripCardClean"
import * as ROUTES from "../../../constants/routes"
import confetti from "../../../images/confetti.svg"
import hello from "../../../images/hello.png"
import trip from "../../../images/trip.png"
import designer from "../../../images/designer.png"
import workinghard from "../../../images/workingHard.png"
import paris from "../../../images/paris.jpg"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import { useGlobalStateStore } from "../../../State/global-state/global-state"
// import rocket from "./../../../images/rocket.svg"
import traveldoodle from "./../../../images/starttripdoodle.svg"
import newImage from "./../../../images/new.png"
import "./../Dashboard.scss"
import DetailsForTraveler from "./../Traveler/DetailsForTraveler"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import * as FullStory from "@fullstory/browser"
import ShowSwitchToDesktop from "../../../components/ShowSwitchToDesktop/ShowSwitchToDesktop"
export default function TravelerDashboard() {
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [state, dispatch] = useDashboardStateStore()
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [toggleTripDetails, setToggleTripDetails] = useState(false)
  const [loading, setLoading] = useState(true)
  const [foundSampleTravelPlan, setFoundSampleTravelPlan] = useState(true)
  const [firstSession, setFirstSession] = useState(false)
  const [showSwitchToDesktop, setShowSwitchToDesktop] = useState(false)
  // const [byotSampleTravel, setbyotSampleTravel] = useState(second)
  const [hasAccessToBYOT, setHasAccessToBYOT] = useState(false)
  const [
    busyDownloadingSampleTravelPlan,
    setBusyDownloadingSampleTravelPlan,
  ] = useState(false)
  React.useEffect(() => {
    document.title = "Dashboard | voyayge"
  }, [])

  React.useEffect(() => {
    try {
      var fSession = JSON.parse(localStorage.getItem("firstSession"))
      console.log({ fSession })
      if (fSession)
        fSession.value === "true"
          ? setFirstSession(false) // Disabling this for now. 03/29/22
          : setFirstSession(false)
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    try {
      var role = JSON.parse(localStorage.getItem("sessionType"))
      console.log(authContext.uid)
      if (
        authContext.uid === "RuViXtBoAHZG6X6aGdnLMBQB5JD2" ||
        authContext.uid === "3s54ouMA9KQEGGun2ojB5NxIICt2" ||
        authContext.uid === "ee5pQQLlzZO8zYdxLJmhwXOFmZ82"
      ) {
        console.log("User is VJ")
        return
      }

      if (
        process.env.NODE_ENV !== "development" &&
        role.sessionType !== "designer"
      ) {
        if (typeof window !== "undefined") {
          FullStory.init({ orgId: "XXWGJ", debug: false })
        }
      } else {
        console.log(role.sessionType)
        console.log("skipping fullstory init")
      }
    } catch (err) {}
  }, [authContext])

  const fetchBYOTAccess = params => {
    try {
      setLoading(true)
      if (dbContext === null) {
        setLoading(true)
      }
      console.log("requesting byot access")
      if (dbContext) {
        dbContext
          .firestore()
          .collection("byot-access")
          .doc(`${authContext.email}`)
          .get()
          .then(doc => {
            console.log(authContext.uid)
            console.log(doc.data())
            setLoading(false)
            if (doc.exists) {
              console.log("BYOT found")
              setHasAccessToBYOT(true)
              fetchBYOTSampleManifest()
              setLoading(false)
            } else {
              console.log("No BYOT found")
              setHasAccessToBYOT(false)
            }
          })
          .catch(e => {
            console.log(e)
            setHasAccessToBYOT(false)
          })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchBYOTSampleManifest = params => {
    try {
      if (dbContext === null) {
        setLoading(true)
      }
      //fetch user trip data.
      //Todo: This has to be refactored to account for multiple trips and pagination.
      //Todo: Need to fix a bug - If any edits are made although they are updated in
      //the DB the changes don't show up in the dashboard cards.
      let activeTrips = []
      // if (dbContext) {
      //   dbContext
      //     .firestore()
      //     .collection("trips")
      //     .doc(`${authContext.uid}`)
      //     .collection("active-trips")
      //     .onSnapshot(querySnapshot =>
      //       querySnapshot.forEach(doc => {
      //         if (doc) {
      //           //activeTrips.push(doc.data())
      //           dispatch({ type: "setActiveTrips", data: doc.data() })
      //           setLoading(false)
      //         }
      //       })
      //     )
      if (dbContext) {
        dbContext
          .firestore()
          .collection("byotSampleTravelPlan")
          .doc("travelManifest")
          .get()
          .then(doc => {
            if (doc.exists) {
              console.log(doc.data().length)
              if (doc.data() === undefined) {
                //No trips created yet
                console.log("byot travel manifest not found")
              } else {
                console.log(doc.data())
                dispatch({ type: "appendToTripManifest", data: doc.data() })
                console.log("byot travel manifest appended")
              }
            }
          })
          .catch(e => console.log(e))
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchTravelManifest = params => {
    try {
      if (dbContext === null) {
        setLoading(true)
      }
      //fetch user trip data.
      //Todo: This has to be refactored to account for multiple trips and pagination.
      //Todo: Need to fix a bug - If any edits are made although they are updated in
      //the DB the changes don't show up in the dashboard cards.
      let activeTrips = []
      // if (dbContext) {
      //   dbContext
      //     .firestore()
      //     .collection("trips")
      //     .doc(`${authContext.uid}`)
      //     .collection("active-trips")
      //     .onSnapshot(querySnapshot =>
      //       querySnapshot.forEach(doc => {
      //         if (doc) {
      //           //activeTrips.push(doc.data())
      //           dispatch({ type: "setActiveTrips", data: doc.data() })
      //           setLoading(false)
      //         }
      //       })
      //     )
      if (dbContext) {
        dbContext
          .firestore()
          .collection("trips")
          .doc(`${authContext.uid}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              console.log(doc.data().length)
              if (doc.data().travelManifest === undefined) {
                //No trips created yet
                console.log("hello")
                dbContext
                  .firestore()
                  .collection("trips")
                  .doc(`${authContext.uid}`)
                  .set({ travelManifest: [] })
                  .then(() => {
                    console.log("Travel Manifest created successfully!")
                  })
              }
              let manifestLen = doc.data().travelManifest.length
              if (manifestLen > 0) {
                let found = find(doc.data().travelManifest, o => {
                  return o.type === "sample-for-traveler"
                })
                if (found) setFoundSampleTravelPlan(true)
                else setFoundSampleTravelPlan(false)
              } else {
                setFoundSampleTravelPlan(false)
              }
              //console.log("test-@#$@#@44")
              dispatch({ type: "updateTripManifest", data: doc.data() })

              //dispatch({ type: "setActiveTrips", data: doc.data() })
              setLoading(false)
            } else {
              console.log("No manifest found")
              setFoundSampleTravelPlan(false)
              dbContext
                .firestore()
                .collection("trips")
                .doc(`${authContext.uid}`)
                .set({ travelManifest: [] })
                .then(() => {
                  console.log("Travel Manifest created successfully!")
                })
            }
          })
          .catch(e => console.log(e))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchTravelManifest()
  }, [dbContext])

  useEffect(() => {
    fetchBYOTSampleManifest()
  }, [dbContext])

  useEffect(() => {
    console.log("%cDashboard-State", "color: #972426; font-size: 18px", {
      state,
    })
  }, [state])

  useEffect(() => {
    console.log("%cGlobal-State", "color: #972426; font-size: 18px", {
      globalState,
    })
  }, [globalState])

  const getSampleTravelPlan = () => {
    try {
      setBusyDownloadingSampleTravelPlan(true)
      var getSampleTravelPlan = functions.httpsCallable("getSampleTravelPlan")
      getSampleTravelPlan()
        .then(result => {
          // Read result of the Cloud Function.
          setBusyDownloadingSampleTravelPlan(false)
          console.log({ result })
          if (result.data.success) {
            //fetch latest manifest
            fetchTravelManifest()
          } else {
            //show error try again
          }
          // ...
        })
        .catch(err => {
          console.log(err)
          setBusyDownloadingSampleTravelPlan(false)
        })
    } catch (err) {
      console.log(err)
      setBusyDownloadingSampleTravelPlan(false)
    }
  }

  const getDates = data => {
    try {
      return `${data.travelDates.start
        .toDate()
        .toLocaleDateString()} - ${data.travelDates.end
        .toDate()
        .toLocaleDateString()}`
    } catch (e) {
      // console.log({ e })
      return `${new Date(
        data.travelDates.start
      ).toLocaleDateString()} - ${new Date(
        data.travelDates.end
      ).toLocaleDateString()}`
    }
  }
  const showTripDetails = e => {
    // dispatch({ type: "setActiveTripDetailsUid", data: e.currentTarget.id })
    //setToggleTripDetails(true)
    console.log(e.currentTarget)
    navigate(`${ROUTES.TRAVELER_DETAILS}/${e.currentTarget.id}`, {
      state: { 118: state.travelManifest },
    })
  }
  const showBYOTTripDetails = e => {
    // dispatch({ type: "setActiveTripDetailsUid", data: e.currentTarget.id })
    //setToggleTripDetails(true)
    console.log(e.currentTarget)
    navigate(`${ROUTES.BYOT_TRAVELER_DETAILS}/${e.currentTarget.id}`, {
      state: { 118: state.travelManifest },
    })
  }
  const showNewTripWizard = e => {
    // dispatch({ type: "setActiveTripDetailsUid", data: e.currentTarget.id })
    //setToggleTripDetails(true)
    navigate(`${ROUTES.NEWTRIP}/${e.currentTarget.id}`, {
      state: { 118: state.travelManifest },
    })
  }
  const close = () => {
    setToggleTripDetails(false)
  }
  // const updateCards = type => {
  //   if (state.travelManifest && state.travelManifest.length > 0) {
  //     return state.travelManifest.map((m, index) => (
  //       <React.Fragment key={m.tripUid}>
  //         <TripCardNew
  //           key={m.tripUid}
  //           tripId={m.tripUid}
  //           tripName={m.tripName}
  //           tripDates={getDates(m)}
  //           invokeTripDetails={showTripDetails}
  //         />
  //       </React.Fragment>
  //     ))
  //   }
  //   // return range(1, 14).map(m => (
  //   //   // <div className="column is-one-fifth">
  //   //   <TripCardNew tripName={m.tripName} tripDates={getDates(m)} />
  //   //   // </div>
  //   // ))
  // }

  const compareTravelStartDate = (a, b) => {
    if (a.travelDates.start > b.travelDates.start) return 1
    if (a.travelDates.start < b.travelDates.start) return -1
    return 0
  }
  const compareCreatedDate = (a, b) => {
    if (a.createdAt > b.createdAt) return -1
    if (a.createdAt < b.createdAt) return 1
    return 0
  }

  const onDeleteTrip = async tripId => {
    try {
      console.log(tripId)
      var tripToDelete = state.travelManifest.filter(
        item => item.tripUid === tripId
      )
      console.log(tripToDelete[0])
      if (tripToDelete[0].members && tripToDelete[0].members.length > 1) {
        //Trip cannot be deleted at this time.
        console.log(
          `Trip had active trip mates. Please remove collaborators before deleting the trip.`
        )
        toast.error(
          ({ closeToast }) => (
            <div>
              <p
                style={{
                  color: "white",
                }}
              >
                Error deleting trip. Trip has active trip mates. Please remove
                collaborators from{" "}
                <span
                  onClick={() => {
                    navigate(`/app/byottripdetails/${tripId}?tab=settings`)
                  }}
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    textDecorationColor: "white",
                    fontWeight: "600",
                  }}
                >
                  here
                </span>{" "}
                and try again.
              </p>
            </div>
          ),
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
        // await NotificationModal({
        //   title: "Error",
        //   text: `Trip had active trip mates. Please remove collaborators before deleting the trip.`,
        //   onClose: () => {},
        // })
      } else {
        const tripManifestRef = dbContext
          .firestore()
          .collection("trips")
          .doc(`${authContext.uid}`)
        const tripRef = dbContext
          .firestore()
          .collection("trips")
          .doc(`${authContext.uid}`)
          .collection("active-trips")
          .doc(`${tripId}`)
        const newTripPoolRef = dbContext
          .firestore()
          .collection("newTripsPool")
          .doc(`${tripId}`)

        const batch = dbContext.firestore().batch()

        batch.set(
          tripManifestRef,
          {
            travelManifest: dbContext.firestore.FieldValue.arrayRemove(
              ...tripToDelete,
              {
                merge: true,
              }
            ),
          },
          {
            merge: true,
          }
        )

        batch.delete(tripRef)

        batch.delete(newTripPoolRef)

        batch
          .commit()
          .then(() => {
            //Notify user
            dispatch({ type: "removeManifestEntry", data: tripId })

            NotificationModal({
              title: "",
              text: "Trip deleted successfully!",
              onClose: close,
            })
          })
          .catch(error => {
            //console.log(error)
            console.log("Error deleting job! :", error)
            toast.error(
              "Error deleting trip, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
              {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            )
          })
      }
    } catch (err) {
      toast.error(
        "Error deleting trip, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      )
    }
    // if(dbContext)
    // dbContext
    //             .firestore()
    //             .collection("trips")
    //             .doc(`${authContext.uid}`)
    //             .
  }
  const isOwner = mItem => {
    try {
      if (mItem) {
        // console.log({ mItem })
        var membersList = mItem.members.filter(
          item => item.UID === authContext.uid
        )
        // console.log({ membersList })
        if (membersList[0].owner) {
          return true
        } else return false
      } else return false
    } catch (error) {
      return false
    }
  }

  const getInProgressTripCards = () => {
    if (state.travelManifest && state.travelManifest.length > 0) {
      var tripsInProgress = state.travelManifest.filter(
        item =>
          item.status === "work-in-progress" ||
          item.status === "byot-work-in-progress" ||
          item.status === "design-in-progress" ||
          item.status === "review-pending" ||
          item.status === "review-complete" ||
          item.status === "payment-pending" ||
          item.status === "changes-requested" ||
          item.status === "waiting-for-acceptance" ||
          item.status === "declined-job"
      )
      tripsInProgress.sort(compareCreatedDate)
      // console.log({ tripsInProgress })
      if (tripsInProgress) {
        return tripsInProgress.map((m, index) => {
          return (
            <React.Fragment key={m.tripUid}>
              <TripCardClean
                key={m.tripUid}
                tripId={m.tripUid}
                tripName={m.tripName}
                tripStatus={m.status}
                tripDates={getDates(m)}
                invokeTripDetails={
                  m.status === "byot-work-in-progress"
                    ? showBYOTTripDetails
                    : showTripDetails
                }
                destination={m.tripPrimaryDestination}
                invokeContinue={showNewTripWizard}
                activeOn={m.activeOn}
                status={m.status}
                onDelete={onDeleteTrip}
                byotSample={
                  m.type === "byot-sample-for-traveler" ? true : false
                }
                statusUpdatedOn={m.statusUpdatedOn}
                byot={m.byot}
                isOwner={isOwner(m)}
              />
            </React.Fragment>
          )
        })
      }
    } else return ""
    // <div style={{ maxWidth: "100px", margin: "0 auto" }}>
    //   <img
    //     style={{ width: "100%", opacity: "0.5" }}
    //     src={emptyBox}
    //     alt=""
    //   />
    //   <p style={{ textAlign: "center", fontSize: "16px" }}>{`- Empty -`}</p>
    // </div>
  }
  const getUpcomingTripCards = () => {
    if (state.travelManifest && state.travelManifest.length > 0) {
      var tripsDesignComplete = state.travelManifest.filter(
        item => item.status === "design-complete"
      )
      tripsDesignComplete.sort(compareTravelStartDate)
      // console.log({ tripsDesignComplete })
      if (tripsDesignComplete) {
        return tripsDesignComplete.map((m, index) => (
          <React.Fragment key={m.tripUid}>
            <TripCardClean
              key={m.tripUid}
              tripId={m.tripUid}
              tripName={m.tripName}
              tripStatus={m.status}
              tripDates={getDates(m)}
              invokeTripDetails={showTripDetails}
              destination={m.tripPrimaryDestination}
              status={m.status}
              sample={m.type === "sample-for-traveler" ? true : false}
            />
          </React.Fragment>
        ))
      }
    } else return ""
    // <div style={{ maxWidth: "100px", margin: "0 auto" }}>
    //   <img
    //     style={{ width: "100%", opacity: "0.5" }}
    //     src={emptyBox}
    //     alt=""
    //   />
    //   <p style={{ textAlign: "center", fontSize: "16px" }}>{`- Empty -`}</p>
    // </div>
  }

  const getPastTripCards = () => {
    if (state.travelManifest && state.travelManifest.length > 0) {
      var tripsInProgress = state.travelManifest.filter(
        item => item.status === "travel-complete"
      )
      if (tripsInProgress) {
        return tripsInProgress.map((m, index) => (
          <React.Fragment key={m.tripUid}>
            {/* <TripCardNew
              key={m.tripUid}
              tripId={m.tripUid}
              tripName={m.tripName}
              tripStatus={m.status}
              tripDates={getDates(m)}
              invokeTripDetails={showTripDetails}
            /> */}
            <TripCardClean
              key={m.tripUid}
              tripId={m.tripUid}
              tripName={m.tripName}
              tripStatus={m.status}
              tripDates={getDates(m)}
              invokeTripDetails={showTripDetails}
              destination={m.tripPrimaryDestination}
            />
          </React.Fragment>
        ))
      }
    } else return ""
    // <div style={{ maxWidth: "100px", margin: "0 auto" }}>
    //   <img
    //     style={{ width: "100%", opacity: "0.5" }}
    //     src={emptyBox}
    //     alt=""
    //   />
    //   <p style={{ textAlign: "center", fontSize: "16px" }}>{`- Empty -`}</p>
    // </div>
    // return range(1, 14).map(m => (
    //   // <div className="column is-one-fifth">
    //   <TripCardNew tripName={m.tripName} tripDates={getDates(m)} />
    //   // </div>
    // ))
  }
  const methodDoesNotExist = () => {
    throw new Error("first error")
  }
  function getFaviconEl() {
    if (typeof window !== "undefined") {
      return document.getElementById("favicon")
    }
  }
  const handleGoogle = () => {
    const favicon = getFaviconEl() // Accessing favicon element
    console.log({ favicon })
    if (favicon)
      favicon.setAttribute("href", "https://www.google.com/favicon.ico")
  }
  const customNavigateTo = url => {
    window.open(url, "_blank")
  }
  return (
    <div>
      {/* <button
        className="button"
        onClick={() => {
          // window.dataLayer.push({ event: "TRAVEL_PLAN_PURCHASED" })
          setShowSwitchToDesktop(true)
        }}
      >
        test
      </button> */}
      {showSwitchToDesktop ? (
        <div className="columns is-centered ">
          <div className="column is-8 ">
            {/* <DetailsForTraveler onClose={close} /> */}
            <ShowSwitchToDesktop
              onContinue={() => {
                setShowSwitchToDesktop(false)
              }}
            />
          </div>
        </div>
      ) : (
        <>
          {/* <ShowFeedback /> */}
          {/* {null.map(val => val)} */}

          {/* <p>{`${process.env.GATSBY_WELCOME_MESSAGE}`}</p> */}
          {/* <button
            className="button"
            // onClick={async () => {
            //   const result = await fetchFromAPI('POST',"api/test")
            //   console.log(JSON.stringify(result))
            // }}
            onClick={async () => {
              const favicon = getFaviconEl(); // Accessing favicon element
              console.log({ favicon });
              if (favicon)
              favicon.setAttribute("href", `${notification}`);

           }}
          >
            test
          </button> */}
          {/* {firstSession && ( */}
          {firstSession && is.not.mobile() && (
            <div className="welcome-screen-wrapper">
              <div className="welcome-screen-blurred"></div>
              <div
                className="welcome-screen fade-in"
                style={{ zIndex: "9999" }}
              >
                <div
                  className="welcome-close"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setFirstSession(false)
                    localStorage.setItem(
                      "firstSession",
                      JSON.stringify({ value: "false" })
                    )
                  }}
                >
                  <i className="fas fa-times-circle fa-2x"></i>
                </div>
                <div className="welcome-image">
                  <img src={hello} alt="" />
                </div>
                <div className="welcome-content">
                  <div className="confetti-image">
                    <img src={confetti} alt="" />
                  </div>
                  <p className="welcome-headline">Welcome!</p>
                  <p className="welcome-message">
                    We are <span style={{}}>thrilled</span> to have you here.
                  </p>

                  <div className="welcome-new-trip">
                    <p className="welcome-text">
                      Ready to start a new travel adventure?
                    </p>
                    <div>
                      <button
                        className="button is-warning"
                        style={{ height: "35px" }}
                        onClick={() => {
                          setFirstSession(false)
                          localStorage.setItem(
                            "firstSession",
                            JSON.stringify({ value: "false" })
                          )
                          navigate(ROUTES.NEWTRIP)
                        }}
                      >
                        Start a new trip
                      </button>
                    </div>
                  </div>
                </div>
                <hr
                  className="hr-branded"
                  style={{
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                />
                <div className="welcome-footer">
                  <p
                    style={{
                      fontSize: "10px",
                      fontWeight: "400",
                      textAlign: "center",
                      marginBottom: "7px",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Follow us
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                    }}
                  >
                    <p className="">
                      <i
                        className="fab fa-lg fa-instagram"
                        style={{
                          color: "#fec600",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          customNavigateTo(
                            "https://www.instagram.com/govoyayge/"
                          )
                        }}
                      ></i>
                    </p>
                    <p className="">
                      <i
                        className="fab fa-lg fa-linkedin"
                        style={{
                          color: "#fec600",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          customNavigateTo(
                            "https://www.linkedin.com/company/voyay-ge-inc"
                          )
                        }}
                      ></i>
                    </p>
                    <p
                      className=""
                      style={{ color: "#fec600", padding: "0px" }}
                    >
                      <i
                        className="fab fa-lg fa-twitter-square"
                        style={{
                          color: "#fec600",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          customNavigateTo("https://twitter.com/voyayge")
                        }}
                      ></i>
                    </p>

                    {/* <p className="">
                          <i
                            className="fab fa-lg fa-facebook-square"
                            style={{ color: "#fec600", padding: "10px" }}
                          ></i>
                        </p> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="columns">
            <div className="column is-1-desktop is-1-tablet"></div>
            <div className="column is-10-desktop is-10-tablet ">
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  borderBottom: "2px solid #fec600",
                }}
              >
                Your trips
              </p>
              <div className="columns" style={{ marginTop: "40px" }}>
                <div className="column is-hidden-desktop is-hidden-tablet is-12-mobile ">
                  {hasAccessToBYOT && (
                    <div
                      style={{
                        margin: "20px auto",
                        display: "flex",
                        // justifyContent: "space-around",
                        alignItems: "center",
                        flexDirection: "column",
                        // flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          color: "#4a4a4a",
                          lineHeight: ".2",
                          cursor: "pointer",
                          marginBottom: "40px",
                          width: "232px",
                          background: "rgba(254, 198,0,0.05)",

                          border: "0.5px solid #fec600",
                          // padding: "10px",
                          // borderRadius: "4px",
                        }}
                        className="box"
                        onClick={() => {
                          analytics.logEvent("byot-start-a-trip")
                          navigate(ROUTES.PLANYOURTRIP)
                        }}
                      >
                        <span
                          style={{
                            // textTransform: "uppercase",
                            lineHeight: ".7",
                            fontSize: "12px",
                            // letterSpacing: "0.5px",
                          }}
                        >
                          {/* Build Your own */}
                          Start a
                        </span>
                        <h2
                          className="section-header"
                          style={{
                            // marginRight: "100px",
                            textDecoration: "underline",
                            marginBottom: "0px",
                            fontSize: "26px",
                            position: "relative",
                          }}
                        >
                          <img
                            src={newImage}
                            alt=""
                            style={{
                              position: "absolute",
                              right: "10px",
                              width: "30px",
                              top: "-20px",
                              display: "none",
                            }}
                          />
                          <span
                            style={{
                              color: "#4a4a4a",
                              lineHeight: "1",
                              opacity: "0.9",
                            }}
                          >
                            Travel Plan
                          </span>

                          {/* <span
                    style={{
                      color: "#4a4a4a",
                      lineHeight: "1",
                    }}
                  >
                    Trip
                  </span> */}
                          <span
                            style={{
                              color: "#fec600",
                            }}
                          >
                            .
                          </span>
                        </h2>
                        <p
                          style={{
                            fontSize: "11px",
                            fontWeight: "300",
                            width: "195px",
                            lineHeight: "1.4",
                            marginTop: "-12px",
                            display: "none",
                          }}
                        >
                          Get started and build your travel plan for free.
                        </p>
                      </div>
                      <p
                        style={{
                          marginRight: "50px",
                          marginLeft: "50px",
                          color: "lightgrey",
                          display: "none",
                        }}
                        className=""
                      >
                        {/* - or - */}|
                      </p>
                      <div
                        style={{
                          color: "#4a4a4a",
                          lineHeight: ".2",
                          cursor: "pointer",
                          maxWidth: "232px",
                          background: "rgba(254, 198,0,0.05)",
                          border: "0.5px solid #fec600",
                        }}
                        className="box"
                        onClick={() => {
                          analytics.logEvent("hire-a-trip-designer")
                          navigate(ROUTES.NEWTRIP)
                        }}
                      >
                        <span
                          style={{
                            // textTransform: "uppercase",
                            lineHeight: ".7",
                            fontSize: "12px",
                            letterSpacing: "0.5px",
                          }}
                        >
                          Hire a
                        </span>
                        <h2
                          className="section-header"
                          style={{
                            marginRight: "0px",
                            textDecoration: "underline",
                            fontSize: "26px",
                            opacity: "0.9",
                            lineHeight: "1.3",
                            marginBottom: "0px",
                          }}
                        >
                          <span
                            style={{
                              color: "#4a4a4a",
                              lineHeight: "1",
                            }}
                          >
                            Trip Designer
                          </span>
                          {/* <span
                  style={{
                    color: "#4a4a4a",
                    lineHeight: "1",
                  }}
                >
                  Designer
                </span> */}
                          <span
                            style={{
                              color: "#fec600",
                            }}
                          >
                            .
                          </span>
                        </h2>
                        <p
                          style={{
                            fontSize: "11px",
                            fontWeight: "300",
                            width: "195px",
                            lineHeight: "1.4",
                            marginTop: "-12px",
                            maxWidth: "220px",
                            display: "none",
                          }}
                        >
                          For $25/day have a trip designer craft your custom
                          travel plan.
                        </p>
                      </div>
                      <div
                        style={{
                          display: "none",
                          // justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                          margin: "25px",
                        }}
                        onClick={() => {
                          analytics.logEvent("hire-a-trip-designer")
                          navigate(ROUTES.NEWTRIP)
                        }}
                      >
                        <div style={{ maxWidth: "290px" }}>
                          <LazyLoadImage
                            src={workinghard}
                            alt="trip"
                            effect="blur"
                          />
                        </div>
                        <a
                          // className="button is-warning is-light"
                          style={{
                            // width: "195px",
                            textAlign: "center",
                            // height: "50px",
                            // margin: "40px",
                            color: "rgba(74,74,74,0.8)",
                            // border: "1px solid #fec600",
                            fontSize: "18px",
                            fontWeight: "600",
                            // textTransform: "uppercase",
                            // letterSpacing: "0.5px",
                            marginTop: "15px",

                            textDecoration: "underline",
                            WebkitTextDecorationColor: "#fec600",
                            textDecorationColor: "#fec600",
                          }}
                        >
                          Hire a trip designer
                        </a>
                      </div>
                    </div>
                  )}
                  {!hasAccessToBYOT && (
                    <div
                      style={{
                        marginTop: "40px auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        className="trip-card-new-clean"
                        onClick={() => {
                          navigate(ROUTES.NEWTRIP)
                        }}
                        style={{
                          border: "1px solid #fec600",
                          //backgroundColor: "#ffffff",
                          // height: "120px",
                          width: "180px",
                          //marginRight: "15px",
                          padding: "10px",
                          // display: "flex",
                          // flexDirection: "column",
                          // justifyContent: "center",
                          marginTop: "40px",
                          alignItems: "center",
                        }}
                      >
                        <img src={traveldoodle}></img>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            textAlign: "center",
                            letterSpacing: "0.5px",
                            textTransform: "uppercase",
                            marginTop: "7px",
                          }}
                        >
                          Start a new trip
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="column is-1-desktop is-0-tablet is-hidden-mobile "></div>
                <div className="column is-10-desktop is-12-tablet is-hidden-mobile ">
                  {!hasAccessToBYOT && (
                    <div
                      style={{
                        marginTop: "40px auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        className="trip-card-new-clean"
                        onClick={() => {
                          navigate(ROUTES.NEWTRIP)
                        }}
                        style={{
                          border: "1px solid #fec600",
                          //backgroundColor: "#ffffff",
                          // height: "120px",
                          width: "180px",
                          //marginRight: "15px",
                          padding: "10px",
                          // display: "flex",
                          // flexDirection: "column",
                          // justifyContent: "center",
                          marginTop: "40px",
                          alignItems: "center",
                        }}
                      >
                        <img src={traveldoodle}></img>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            textAlign: "center",
                            letterSpacing: "0.5px",
                            textTransform: "uppercase",
                            marginTop: "7px",
                          }}
                        >
                          Start a new trip
                        </p>
                      </div>
                    </div>
                  )}
                  {hasAccessToBYOT && (
                    <div
                      style={{
                        margin: "50px auto",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          color: "#4a4a4a",
                          lineHeight: ".2",
                          cursor: "pointer",
                          // border: "0.5px solid #fec600",
                          // padding: "10px",
                          // borderRadius: "4px",
                        }}
                        onClick={() => {
                          analytics.logEvent("byot-start-a-trip")
                          navigate(ROUTES.PLANYOURTRIP)
                        }}
                      >
                        <span
                          style={{
                            // textTransform: "uppercase",
                            lineHeight: ".5",
                            fontSize: "12px",
                            letterSpacing: "0.5px",
                          }}
                        >
                          {/* Build Your own */}
                          Start a
                        </span>
                        <h2
                          className="section-header"
                          style={{
                            // marginRight: "100px",
                            textDecoration: "underline",
                            fontSize: "40px",
                            position: "relative",
                          }}
                        >
                          <img
                            src={newImage}
                            alt=""
                            style={{
                              position: "absolute",
                              right: "0px",
                              width: "30px",
                              top: "-25px",
                            }}
                          />
                          <span
                            style={{
                              color: "#4a4a4a",
                              lineHeight: "1",
                              opacity: "0.9",
                            }}
                          >
                            Travel Plan
                          </span>

                          {/* <span
                      style={{
                        color: "#4a4a4a",
                        lineHeight: "1",
                      }}
                    >
                      Trip
                    </span> */}
                          <span
                            style={{
                              color: "#fec600",
                            }}
                          >
                            .
                          </span>
                        </h2>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "300",
                            width: "248px",
                            lineHeight: "1.4",
                            marginTop: "-12px",
                          }}
                        >
                          Get started and build your travel plan for free.
                        </p>
                      </div>
                      <p
                        style={{
                          marginRight: "50px",
                          marginLeft: "50px",
                          color: "lightgrey",
                          display: "none",
                        }}
                        className=""
                      >
                        {/* - or - */}|
                      </p>
                      <div
                        style={{
                          color: "#4a4a4a",
                          lineHeight: ".2",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          analytics.logEvent("hire-a-trip-designer")
                          navigate(ROUTES.NEWTRIP)
                        }}
                      >
                        <span
                          style={{
                            // textTransform: "uppercase",
                            lineHeight: ".5",
                            fontSize: "12px",
                            letterSpacing: "0.5px",
                          }}
                        >
                          Hire a
                        </span>
                        <h2
                          className="section-header"
                          style={{
                            marginRight: "0px",
                            textDecoration: "underline",
                            fontSize: "40px",
                            opacity: "0.9",
                          }}
                        >
                          <span
                            style={{
                              color: "#4a4a4a",
                              lineHeight: "1",
                            }}
                          >
                            Trip Designer
                          </span>
                          {/* <span
                    style={{
                      color: "#4a4a4a",
                      lineHeight: "1",
                    }}
                  >
                    Designer
                  </span> */}
                          <span
                            style={{
                              color: "#fec600",
                            }}
                          >
                            .
                          </span>
                        </h2>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "300",
                            width: "291px",
                            lineHeight: "1.4",
                            marginTop: "-12px",
                          }}
                        >
                          For $25/day have a trip designer build your custom
                          travel plan.
                        </p>
                      </div>
                      <div
                        style={{
                          display: "none",
                          // justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                          margin: "25px",
                        }}
                        onClick={() => {
                          analytics.logEvent("hire-a-trip-designer")
                          navigate(ROUTES.NEWTRIP)
                        }}
                      >
                        <div style={{ maxWidth: "290px" }}>
                          <LazyLoadImage
                            src={workinghard}
                            alt="trip"
                            effect="blur"
                          />
                        </div>
                        <a
                          // className="button is-warning is-light"
                          style={{
                            // width: "195px",
                            textAlign: "center",
                            // height: "50px",
                            // margin: "40px",
                            color: "rgba(74,74,74,0.8)",
                            // border: "1px solid #fec600",
                            fontSize: "18px",
                            fontWeight: "600",
                            // textTransform: "uppercase",
                            // letterSpacing: "0.5px",
                            marginTop: "15px",

                            textDecoration: "underline",
                            WebkitTextDecorationColor: "#fec600",
                            textDecorationColor: "#fec600",
                          }}
                        >
                          Hire a trip designer
                        </a>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "none",
                    }}
                  >
                    <div
                      className="trip-card-new-clean"
                      onClick={() => {
                        analytics.logEvent("hire-a-trip-designer")
                        navigate(ROUTES.NEWTRIP)
                      }}
                      style={{
                        border: "1px solid #fec600",
                        //backgroundColor: "#ffffff",
                        // height: "120px",
                        width: "180px",
                        //marginRight: "15px",
                        padding: "10px",
                        // display: "flex",
                        // flexDirection: "column",
                        // justifyContent: "center",
                        marginTop: "40px",
                        alignItems: "center",
                      }}
                    >
                      <img src={traveldoodle}></img>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          textAlign: "center",
                          letterSpacing: "0.5px",
                          // textTransform: "uppercase",
                          marginTop: "7px",
                        }}
                      >
                        Hire a trip designer
                      </p>
                    </div>
                    <div
                      className="trip-card-new-clean"
                      onClick={() => {
                        navigate(ROUTES.PLANYOURTRIP)
                      }}
                      style={{
                        border: "1px solid #fec600",
                        //backgroundColor: "#ffffff",
                        // height: "120px",
                        width: "180px",
                        //marginRight: "15px",
                        padding: "10px",
                        // display: "flex",
                        // flexDirection: "column",
                        // justifyContent: "center",
                        marginTop: "40px",
                        alignItems: "center",
                      }}
                    >
                      <img src={traveldoodle}></img>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          textAlign: "center",
                          letterSpacing: "0.5px",
                          // textTransform: "uppercase",
                          marginTop: "7px",
                        }}
                      >
                        Start planning a trip
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column is-1-desktop is-0-tablet is-hidden-mobile "></div>
              </div>

              <div className="columns">
                <div className="column is-12-desktop is-12-tablet ">
                  {/* <hr className="hr-branded"></hr> */}
                  {/* <div className="trip-container"> */}
                  {/* <NewTripCard
                  onCreateNewTrip={() => {
                    navigate(ROUTES.NEWTRIP)
                  }}
                /> */}
                  {/* </div> */}

                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "35px",
                      marginBottom: "15px",
                    }}
                  >
                    In works:
                  </p>

                  <div className="trip-container">
                    {getInProgressTripCards()}
                  </div>
                  <hr className="hr-branded"></hr>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Upcoming:
                  </p>
                  {!foundSampleTravelPlan && (
                    <div
                      className="trip-card-clean fade-in"
                      style={{ maxWidth: "270px" }}
                      onClick={getSampleTravelPlan}
                    >
                      {busyDownloadingSampleTravelPlan && (
                        <div
                          className="spinner center"
                          style={{ zIndex: "20" }}
                        >
                          <div className="rect1"></div>
                          <div className="rect2"></div>
                          <div className="rect3"></div>
                          <div className="rect4"></div>
                          <div className="rect5"></div>
                        </div>
                      )}
                      <div style={{ width: "100%" }}>
                        <img
                          style={{ padding: "5px", opacity: "0.15" }}
                          src={paris}
                          alt=""
                        />
                        <p
                          style={{
                            textAlign: "center",
                            // marginTop: "-12px",
                            textTransform: "uppercase",
                            fontSize: "16px",
                            fontWeight: "600",
                            letterSpacing: "0.5px",
                            padding: "5px",
                          }}
                          className="center"
                        >
                          View a sample travel plan
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="trip-container testBackground">
                    {getUpcomingTripCards()}
                  </div>
                  <hr className="hr-branded"></hr>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Past:
                  </p>
                  <div className="trip-container testBackground">
                    {getPastTripCards()}
                  </div>
                  <hr className="hr-branded"></hr>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
