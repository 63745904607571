import React, { useState, useEffect, useContext } from "react"
import thankq from "./../../images/thankq.png"
import errorImg from "./../../images/error.png"
import { functions } from "../firebase/firebase"
import { v4 as uuidv4 } from "uuid"
import Dropzone from "react-dropzone"
import { FirebaseContext } from "../firebase"
import { AuthUserContext } from "../session"
const SupportRequestForm = () => {
  const [supportTextLen, setSupportTextLen] = useState(0)
  const [supportText, setSupportText] = useState("")
  const [submissionState, setSubmissionState] = useState("none")
  const [busy, setBusy] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const authContext = useContext(AuthUserContext)
  const [progressValue, setProgressValue] = useState(0)
  const [showProgress, setShowProgress] = useState(false)
  const [currentFileQty, setCurrentFileQty] = useState(0)
  const MAX_FILE_LIMIT = 5

  //   let onSave = async fileObject => {
  //     const regex = /\.[0-9a-z]+$/gm
  //     const str = fileObject.name
  //     let m
  //     var ext = ""
  //     while ((m = regex.exec(str)) !== null) {
  //       // This is necessary to avoid infinite loops with zero-width matches
  //       if (m.index === regex.lastIndex) {
  //         regex.lastIndex++
  //       }

  //       // The result can be accessed through the `m`-variable.
  //       m.forEach((match, groupIndex) => {
  //         console.log(`Found match: ${match}`)
  //         ext = match
  //       })
  //     }
  //     let tripOwnerUid = getTripOwnerUid()
  //     if (tripOwnerUid === "") return
  //     var fileName = `${fileObject.name}` // + ext
  //     var fileURL = `${process.env.TRIP_DOCS_STORAGE_BUCKET}/trip-documents/${state.tripDetails.tripUid}/${authContext.uid}/${fileName}`
  //     console.log({ fileName })
  //     var storageRef = dbContext.storage().refFromURL(fileURL)
  //     var uploadTask = storageRef.put(fileObject)

  //     uploadTask.on(
  //       dbContext.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
  //       function(snapshot) {
  //         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //         var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //         console.log("Upload is " + progress + "% done")
  //         setShowProgress(true)
  //         setProgressValue(Number(progress))
  //         switch (snapshot.state) {
  //           case dbContext.storage.TaskState.PAUSED: // or 'paused'
  //             console.log("Upload is paused")
  //             break
  //           case dbContext.storage.TaskState.RUNNING: // or 'running'
  //             console.log("Upload is running")
  //             break
  //         }
  //       },
  //       function(error) {
  //         console.log(error)
  //         setShowProgress(false)
  //         setProgressValue(0)
  //         addToast("Error uploading the file, please try again in sometime", {
  //           appearance: "error",
  //           autoDismiss: true,
  //         })
  //         // A full list of error codes is available at
  //         // https://firebase.google.com/docs/storage/web/handle-errors
  //         switch (error.code) {
  //           case "storage/unauthorized":
  //             // User doesn't have permission to access the object
  //             break
  //           case "storage/canceled":
  //             // User canceled the upload
  //             break
  //           case "storage/unknown":
  //             // Unknown error occurred, inspect error.serverResponse
  //             break
  //         }
  //       },
  //       async function() {
  //         // Upload completed successfully, now we can get the download URL
  //         setShowProgress(false)
  //       }
  //     )
  //   }

  //   const onDrop = (acceptedFiles, rejectedFiles) => {
  //     setErrorMsg("")
  //     setShowError(false)
  //     console.log({ acceptedFiles })
  //     console.log({ rejectedFiles })
  //     if (acceptedFiles.length > 0) {
  //       if (acceptedFiles.length + currentFileQty > MAX_FILE_LIMIT) {
  //         addToast(`Maximum of ${MAX_FILE_LIMIT} documents allowed.`, {
  //           appearance: "error",
  //           autoDismiss: true,
  //         })
  //         return
  //       }
  //       acceptedFiles.map(item => onSave(item))
  //     }
  //     if (rejectedFiles.length > 0) {
  //       addToast(
  //         `File ${rejectedFiles[0].file.name} is too large. Max allowed file size is 10Mb`,
  //         {
  //           appearance: "error",
  //           autoDismiss: true,
  //         }
  //       )
  //       setErrorMsg(` File ${rejectedFiles[0].file.name} is too large, sorry!`)
  //       setShowError(true)
  //     }
  //   }

  //   const fileUploader = () => {
  //     try {
  //       return (
  //         <>
  //           <Dropzone
  //             onDrop={onDrop}
  //             accept="application/png, image/jpeg,image/jpg,image/png,image/gif, application/pdf, application/doc, application/docx, application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
  //             minSize={0}
  //             maxSize={5242880 * 2} //10MB
  //             // disabled="true"
  //             style={{ height: "100px", cursor: "pointer !important" }}
  //           >
  //             {({
  //               getRootProps,
  //               getInputProps,
  //               isDragActive,
  //               isDragReject,
  //               acceptedFiles,
  //               rejectedFiles,
  //             }) => {
  //               return (
  //                 <div className="drop-zone-small" {...getRootProps()}>
  //                   <input {...getInputProps()} />
  //                   {!isDragActive && (
  //                     <div
  //                       style={{
  //                         display: "flex",
  //                         justifyContent: "center",
  //                         alignItems: "center",
  //                         height: "100%",
  //                       }}
  //                     >
  //                       {/* <i className="far fa-images fa-4x"></i> */}
  //                       <p
  //                         style={{
  //                           fontSize: "14px",
  //                           fontWeight: "400",
  //                           textAlign: "center",
  //                           verticalAlign: "middle",
  //                           padding: "5px",
  //                         }}
  //                       >
  //                         {is.mobile()
  //                           ? "Touch here to upload travel documents from your phone."
  //                           : "Click here or drag your travel documents from your computer to upload."}
  //                       </p>
  //                     </div>
  //                   )}
  //                   {isDragActive && !isDragReject && (
  //                     <div
  //                       style={{
  //                         display: "flex",
  //                         justifyContent: "center",
  //                         alignItems: "center",
  //                         height: "100%",
  //                       }}
  //                     >
  //                       <p
  //                         style={{
  //                           fontSize: "14px",
  //                           fontWeight: "500",
  //                           textAlign: "center",
  //                           verticalAlign: "middle",
  //                         }}
  //                       >
  //                         Drop the file anywhere inside this box.
  //                       </p>
  //                     </div>
  //                   )}
  //                   {isDragReject && (
  //                     <div
  //                       style={{
  //                         display: "flex",
  //                         justifyContent: "center",
  //                         alignItems: "center",
  //                         height: "100%",
  //                       }}
  //                     >
  //                       <p
  //                         style={{
  //                           fontSize: "14px",
  //                           fontWeight: "500",
  //                           textAlign: "center",
  //                           verticalAlign: "middle",
  //                         }}
  //                       >
  //                         File type not accepted, sorry!
  //                       </p>
  //                     </div>
  //                   )}
  //                   {/* {isFileTooLarge && (
  //                       <div
  //                         style={{
  //                           display: "flex",
  //                           justifyContent: "center",
  //                           alignItems: "center",
  //                           height: "100%",
  //                         }}
  //                       >
  //                         <p
  //                           style={{
  //                             fontSize: "20px",
  //                             fontWeight: "500",
  //                             textAlign: "center",
  //                             verticalAlign: "middle",
  //                           }}
  //                         >
  //                           File is too large, sorry!
  //                         </p>
  //                       </div>
  //                     )} */}
  //                 </div>
  //               )
  //             }}
  //           </Dropzone>
  //           <div style={{ marginTop: "5px" }}>
  //             {showProgress && (
  //               <progress
  //                 className="progress is-warning is-small"
  //                 value={progressValue}
  //                 max="100"
  //               >
  //                 {progressValue}
  //               </progress>
  //             )}
  //           </div>
  //         </>
  //       )
  //     } catch (error) {}
  //   }

  const createSupportRequest = () => {
    try {
      setBusy(true)
      let data = {
        feedback: supportText,
        id: uuidv4(),
        user: authContext.uid,
        email: authContext.email,
      }
      var saveFeedback = functions.httpsCallable("saveSupportRequest")
      saveFeedback(data)
        .then(function(result) {
          // Read result of the Cloud Function.
          setBusy(false)
          console.log({ result })
          if (result.data.success) {
            window.scrollTo(0, 0)
            setSubmissionState("success")
          } else {
            window.scrollTo(0, 0)
            setSubmissionState("error")
          }
          // ...
        })
        .catch(err => {
          console.log(err)
          setBusy(false)
          window.scrollTo(0, 0)
          setSubmissionState("error")
        })
    } catch (err) {
      console.log(err)
      setBusy(false)
      window.scrollTo(0, 0)
      setSubmissionState("error")
    }
  }

  const showFormState = () => {
    // window.scrollTo(0, 0)
    switch (submissionState) {
      case "none":
        return (
          <>
            <div>
              <div style={{ display: "" }}>
                <p className="" style={{ fontSize: "28px", fontWeight: "800" }}>
                  Support Request<span style={{ color: "#fec600" }}>.</span>{" "}
                </p>{" "}
              </div>
              {/* <p className="feedback-preamble">
                    We want to be sure you are delighted with your experience with
                    <strong
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {" "}
                      vo<span style={{ color: "#fec600" }}>yay!</span>ge
                    </strong>
                    . To help provide you with the best experience possible, we'd
                    like your help with this very brief survey:{" "}
                  </p> */}
            </div>

            <div
              style={{ marginTop: "15px", marginBottom: "35px" }}
              className="fade-in"
            >
              <div className="field">
                <label className="label">How can we help?</label>
                <div className="control is-expanded">
                  <textarea
                    onChange={e => {
                      if (e.currentTarget.value.length <= 500)
                        setSupportText(e.currentTarget.value)
                    }}
                    value={supportText}
                    className="textarea"
                    placeholder=""
                    rows={8}
                  ></textarea>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="help"></p>
                    <p
                      className={`help is-pulled-right ${
                        supportTextLen >= 500 ? "is-danger" : ""
                      }`}
                    >
                      Characters: {supportTextLen}/500
                    </p>
                  </div>
                  {/* <div className="field">
                    <p>Screenshots:</p>
                    {fileUploader()}
                  </div> */}
                  {/* {errors.accommodation ? (
                       <p className="help is-danger">
                         Please pick you accommodation preference
                       </p>
                     ) : (
                       <p className="help">pick all that apply</p>
                     )} */}
                </div>
              </div>
            </div>
            <div
              className="field  is-pulled-right"
              style={{ marginTop: "-5px" }}
            >
              <button
                style={{ fontSize: "14px", minWidth: "150px" }}
                className={`${
                  busy
                    ? "button is-warning is-pulled-right is-loading"
                    : "button is-warning is-pulled-right"
                }`}
                onClick={e => {
                  createSupportRequest()
                  //   setScreenLocationState("thanks")
                  e.preventDefault()
                }}
              >
                Submit
              </button>
            </div>
          </>
        )

      case "error":
        return (
          <>
            <div style={{ margin: "20px auto", maxWidth: "300px" }}>
              <img
                src={errorImg}
                alt="error!"
                style={{
                  padding: "10px",
                  // maxHeight: "300px",
                  width: "100%",
                }}
              />
            </div>
            <p
              style={{
                fontSize: "16px",
                margin: "15px auto",
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              Uh oh!, there was an error submitting your support request.{" "}
              <br></br> {/* <button className="button">Retry</button> */}
              <small>
                You can also directly email us the issue to{" "}
                <a href="mailto: support@voyayge.com">support@voyayge.com</a>
              </small>
            </p>
          </>
        )
      case "success":
        return (
          <>
            <div style={{ maxWidth: "400px", margin: "0px auto" }}>
              <img src={thankq} alt="thank you" />
            </div>
            <p
              style={{
                fontSize: "16px",
                margin: "10px auto",
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              We received your support request. We'll get back to you shortly.
              Expect an email from support@voyayge.com
            </p>
          </>
        )

      default:
        break
    }
  }

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div className="columns" style={{ paddingTop: "25px" }}>
          <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
          <div className="column is-6-desktop is-8-tablet is-12-mobile">
            <div style={{ marginTop: "40px" }}>{showFormState()}</div>
          </div>
          <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
        </div>
      </div>
    </div>
  )
}

export default SupportRequestForm
