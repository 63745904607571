import React, { useMemo, useState } from "react"
import "./TripCardClean.scss"
import {
  CircularProgressbar,
  buildStyles,
  RadialSeparators,
} from "react-circular-progressbar"
import {
  daysRemaining,
  calculateProgress,
  dottedText,
} from "../../utility/general"
import "react-circular-progressbar/dist/styles.css"
import bonvoyayge from "../../images/bon-voyayge.png"
import ReactTooltip from "react-tooltip"
import { analytics } from "../firebase/firebase"
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal"

const TripCardClean = React.memo(
  ({
    tripId,
    tripName,
    tripDates,
    invokeTripDetails,
    tripStatus,
    invokeContinue,
    destination,
    notify,
    activeOn,
    sample = false,
    byotSample = false,
    status,
    onDelete,
    statusUpdatedOn,
    byot = false,
    isOwner = false,
  }) => {
    // console.log({ statusUpdatedOn })
    const percentage = 66
    const maxDesignDays = 7 // This needs to be made configurable later on.
    const maxReDesignDays = 3
    const maxReviewDays = 5

    // console.log({ isOwner })
    const displayMessage = params => {
      switch (params) {
        case "review":
          return "Review in progress"
        case "review-complete":
          return "Finalize travel plan"
        case "request-changes":
          return "Changes required"
        case "changes-requested":
          return "Requested changes in works"
        case "declined-job":
          return "Pick a travel designer"
        case "active":
          return "Design in-progress"
        case "pending":
          return "Pending"
        case "review-pending":
          return "Travel plan review pending"
        case "payment-pending":
          return "Payment due"
        case "complete":
          return "Task completed"
        case "design-in-progress":
          return "Design in progress"
        case "work-in-progress":
          return "Not submitted"
        case "design-complete":
          return "Design complete"
        case "waiting-for-acceptance":
          return "Waiting for designer to accept"
          break

        default:
          return params
          break
      }
    }

    const onConfirm = () => {
      try {
        onDelete(tripId)
      } catch (err) {
        console.log(err)
      }
    }

    return (
      <div
        className=""
        style={{
          position: "relative",
          marginRight: "25px",
          marginBottom: "25px",
          width: "270px",
          height: "115px",
        }}
      >
        <ReactTooltip />
        {status === "work-in-progress" ? (
          <div
            className="trip-delete"
            style={{
              // fontSize: "14px",
              marginTop: "5px",
              zIndex: "99",
              // textAlign: "right",
              position: "absolute",
              right: "10px",
              bottom: "7px",
              cursor: "pointer",
            }}
            onClick={() => {
              ConfirmationModal({
                onConfirm,
                title: "Delete Confirmation",
                text: `Would you like to permanently delete this trip?`,
              })
            }}
          >
            <i className="far fa-trash-alt" data-tip={`Delete Trip!`}></i>
          </div>
        ) : (
          ""
        )}
        {byot && isOwner ? (
          <div
            className="trip-delete"
            style={{
              // fontSize: "14px",
              marginTop: "5px",
              zIndex: "99",
              // textAlign: "right",
              position: "absolute",
              right: "10px",
              bottom: "7px",
              cursor: "pointer",
            }}
            onClick={() => {
              ConfirmationModal({
                onConfirm,
                title: "Delete Confirmation",
                text: `Would you like to permanently delete this trip?`,
              })
            }}
          >
            <i className="far fa-trash-alt" data-tip={`Delete Trip!`}></i>
          </div>
        ) : (
          ""
        )}
        <div
          className="trip-card-clean fade-in"
          // style={{
          //   backgroundImage: `url(
          //   https://lp-cms-production.imgix.net/2021-03/shutterstock_304631102.jpg?auto=format&fit=crop&sharp=10&vib=20&ixlib=react-8.6.4&w=850
          // )`,
          //   backgroundPositionY: "center",
          //   backgroundSize: "cover",
          // }}
          id={tripId}
          onClick={e => {
            tripStatus === "work-in-progress"
              ? invokeContinue(e)
              : invokeTripDetails(e)

            if (sample) {
              analytics.logEvent("sample_plan_viewed", {})
            }
          }}
        >
          {/* <div className="custom-trip-card-image">
          <img
            src={`https://picsum.photos/id/${Math.round(
              Math.random() * 1000
            )}/201/150`}
            // src="https://images.unsplash.com/photo-1493976040374-85c8e12f0c0e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjEyMTc5NX0"
            alt=""
          />
        </div> */}
          {sample && (
            <div
              className="showSample"
              style={{
                textTransform: "uppercase",
                fontWeight: "400",
                letterSpacing: "0.5px",
              }}
            >
              Your Sample Travel Plan
            </div>
          )}
          {byotSample && (
            <div
              className="showSample"
              style={{
                textTransform: "uppercase",
                fontWeight: "400",
                letterSpacing: "0.5px",
              }}
            >
              Sample
            </div>
          )}
          <div className="trip-card-clean-data">
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <p className="place" style={{ letterSpacing: "0.5px" }}>
                {/* {destination.place} */}
                {dottedText(
                  tripName === "" ? destination.place : tripName,
                  27
                )}{" "}
              </p>
              {/* <span style={{ color: "#fec600", fontWeight: "700" }}>-</span>
            <p className="country">{destination.country}</p> */}
            </div>

            {/* <p className="country" style={{ letterSpacing: "0.5px" }}>
            {destination.country}
          </p> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                letterSpacing: "0.5px",
              }}
            >
              <p className="date">{tripDates}</p>
              <div
                // className="notification-icon"
                style={{ width: 25, height: 25 }}
              >
                {/* {activeOn && (
                <CircularProgressbar
                  value={calculateProgress()}
                  strokeWidth={15}
                  // styles={buildStyles({
                  //   strokeLinecap: "butt",
                  // })}
                  styles={buildStyles({
                    // strokeLinecap: "butt",
                    pathColor: "#fec600",
                    trailColor: "#f0f0f0",
                  })}
                />
              )} */}
              </div>

              {notify && (
                <div
                  className="notification-icon"
                  style={{ width: 20, height: 20, position: "absolute" }}
                >
                  <CircularProgressbar value={66} text={`${66}%`} />
                  <i
                    className="far fa-bell-on"
                    style={{
                      color: "#6a6a6a",
                      position: "absolute",
                      // left: "-3px",
                      bottom: "2px",
                    }}
                  ></i>
                </div>
              )}
            </div>
            {/* <p className="trip-card-clean-title">
            {tripName !== undefined
              ? tripName.length > 15
                ? tripName.substring(0, 14) + " ..."
                : tripName
              : ""}
          </p> */}
            {/* <p className="date">{tripDates}</p> */}
            {/* {!activeOn && (
            <div
              style={{
                height: "32px",
                overflow: "auto",
                borderRadius: "3px",
                // opacity: "0.9",
                // rotate: "45deg",
              }}
            >
              <img src={triangles} alt="" />
            </div>
          )} */}
            {status === "design-in-progress"
              ? activeOn && (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <p
                      className=""
                      style={{
                        fontSize: "11px",
                        textAlign: "right",
                        position: "absolute",
                        right: "5px",
                        bottom: "9px",
                      }}
                    >
                      {maxDesignDays -
                        (daysRemaining(activeOn, maxDesignDays) < 0
                          ? 0
                          : daysRemaining(activeOn, maxDesignDays))}{" "}
                      / {maxDesignDays} Days
                      {/* {100 - calculateProgress()} % */}
                    </p>
                    <progress
                      className="progress is-thin is-warning"
                      value={100 - calculateProgress(activeOn, maxDesignDays)}
                      style={{
                        marginTop: "7px",
                        borderRadius: "0px",
                        width: "98%",
                        marginRight: "5px",
                      }}
                      max="100"
                    >
                      15%
                    </progress>
                  </div>
                )
              : ""
            // <div
            //   style={{
            //     position: "relative",
            //   }}
            // >
            //   <p
            //     className=""
            //     style={{
            //       fontSize: "11px",
            //       textAlign: "right",
            //       position: "absolute",
            //       right: "5px",
            //       bottom: "9px",
            //     }}
            //   >
            //     Not Active
            //   </p>
            //   <progress
            //     className="progress-thin is-thin flip"
            //     value={100}
            //     style={{
            //       marginTop: "7px",
            //       borderRadius: "0px",
            //       width: "98%",
            //       marginRight: "5px",
            //     }}
            //     max="100"
            //   >
            //     15%
            //   </progress>
            // </div>
            }
            {status === "changes-requested" && statusUpdatedOn ? (
              <div
                style={{
                  position: "relative",
                }}
              >
                <p
                  className=""
                  style={{
                    fontSize: "11px",
                    textAlign: "right",
                    position: "absolute",
                    right: "5px",
                    bottom: "9px",
                  }}
                >
                  {maxReDesignDays -
                    (daysRemaining(statusUpdatedOn, maxReDesignDays) < 0
                      ? 0
                      : daysRemaining(statusUpdatedOn, maxReDesignDays))}{" "}
                  / {maxReDesignDays} Days
                  {/* {100 - calculateProgress()} % */}
                </p>
                <progress
                  className="progress is-thin is-warning"
                  value={
                    100 - calculateProgress(statusUpdatedOn, maxReDesignDays)
                  }
                  style={{
                    marginTop: "7px",
                    borderRadius: "0px",
                    width: "98%",
                    marginRight: "5px",
                  }}
                  max="100"
                >
                  15%
                </progress>
              </div>
            ) : (
              ""
            )}
            {status === "review-pending" && statusUpdatedOn ? (
              <div
                style={{
                  position: "relative",
                }}
              >
                <p
                  className=""
                  style={{
                    fontSize: "11px",
                    textAlign: "right",
                    position: "absolute",
                    right: "5px",
                    bottom: "9px",
                  }}
                >
                  {/* {maxDesignDays - (daysRemaining() < 0 ? 0 : daysRemaining())} /{" "}
            {maxDesignDays} Days */}
                  {daysRemaining(statusUpdatedOn, maxReviewDays) < 0
                    ? "Late"
                    : daysRemaining(statusUpdatedOn, maxReviewDays) === 0
                    ? `Due today`
                    : `Due in ${daysRemaining(
                        statusUpdatedOn,
                        maxReviewDays
                      )} days`}
                  {/* {100 - calculateProgress()} % */}
                </p>
                <progress
                  className="progress is-warning is-thin"
                  value={
                    100 - calculateProgress(statusUpdatedOn, maxReviewDays)
                  }
                  // value={20}
                  style={{
                    marginTop: "15px",
                    borderRadius: "0px",
                    width: "98%",
                    border: "0.5px solid #fec600 !important",
                  }}
                  max="100"
                >
                  15%
                </progress>
              </div>
            ) : (
              ""
            )}
            <p
              className=""
              style={{
                fontSize: "12px",
                marginTop: "5px",
                // textAlign: "right",
                position: "absolute",
                // right: "7px",
                bottom: "7px",
              }}
            >
              {sample || byot ? "" : `Status: ${displayMessage(status)}`}
            </p>
            {/* {status === "work-in-progress" ? (
              <p
                className="link"
                style={{
                  fontSize: "14px",
                  marginTop: "5px",
                  zIndex: "99",
                  // textAlign: "right",
                  position: "absolute",
                  right: "10px",
                  bottom: "7px",
                }}
                onClick={() => {
                  ConfirmationModal({
                    onConfirm,
                    title: "Delete Confirmation",
                    text: `Are you sure? This trip will be permanently deleted!`,
                  })
                }}
              >
                Delete Trip
              </p>
            ) : (
              ""
            )} */}
            {/* {status === "design-complete" ? (
            <div
              style={{
                width: "85px",
                position: "absolute",
                right: "-2px",
                bottom: "-4px",
              }}
            >
              <img src={starttripdoodle} alt="" />
              <img src={starttripdoodle} alt="" />
            </div>
          ) : (
            ""
          )} */}
          </div>

          {/* <div className="details">
          <a
            className="hover-link"
            id={tripId}
            onClick={e => {
              tripStatus === "work-in-progress"
                ? invokeContinue(e)
                : invokeTripDetails(e)
            }}
          >
            {tripStatus === "work-in-progress" ? "Continue" : "Trip details"}
          </a>
        </div> */}
        </div>
      </div>
    )
  }
)

export default TripCardClean
