import React, { useEffect } from "react"
import { useAsync } from "react-async"
import blogLogo from "../../images/voyayge-blog.png"
import "./BlogPostCard.scss"
import { LazyLoadImage } from "react-lazy-load-image-component"
const fetchDataFromURL = async ({ value }) => {
  try {
    let data = { url: `${value}` }
    let html = await fetch(
      `${process.env.CLOUD_FUNCTIONS_ENDPOINT}/api/proxy`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
    let text = await html.text()
    var parser = new DOMParser()
    var doc = parser.parseFromString(text, "text/html")
    if (doc) return doc
    else return null
  } catch (err) {
    console.log(err)
  }
}

const BlogPostCard = ({ postUrl }) => {
  useEffect(() => {
    console.log(postUrl)
  }, [postUrl])
  const { data, error, isPending } = useAsync({
    promiseFn: fetchDataFromURL,
    value: postUrl,
  })

  try {
    if (isPending) return "Loading..."
    if (error) return `Blog post unavailable.`
    if (data) {
      let imageUrl = data
        .querySelector("meta[property='og:image']")
        .getAttribute("content")
      let title = data
        .querySelector("meta[property='og:title']")
        .getAttribute("content")
      console.log({ data })
      return (
        <div
          className="blog-post-card"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(postUrl, "_blank")
          }}
        >
          <div style={{ height: "35px", margin: "0px 0px", display: "flex" }}>
            <LazyLoadImage
              src={blogLogo}
              alt=""
              style={{ height: "100%", objectFit: "scale", display: "inline" }}
              effect="blur"
            />

            {/* <strong>{data.all["twitter:title"]}</strong> */}
          </div>
          <div style={{}} className="blog-post-card-image">
            <LazyLoadImage
              src={imageUrl}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              effect="blur"
            />

            {/* <strong>{data.all["twitter:title"]}</strong> */}
          </div>
          <p
            style={{
              margin: "10px auto",
              paddingLeft: "0px",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {title}
          </p>
        </div>
      )
    }
    return null
  } catch (err) {
    return ""
  }
}

export default BlogPostCard
