import React from 'react';

class TravelPreferencesContainer extends React.Component {
  render() {
    return (
      <div>
        TravelPreferencesContainer
      </div>
    );
  }
}

export default TravelPreferencesContainer;
