import React, { useContext, useEffect, useState } from "react"
import {
  useTripStateStore,
  TripStateProvider,
} from "../../../State/trip-state/trip-state-store"
import swal from "sweetalert"
import { ToastProvider, useToasts } from "react-toast-notifications"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { FirebaseContext } from "../../../components/firebase"
import {
  DashboardStateProvider,
  useDashboardStateStore,
} from "../../../State/dashboard-state/dashboard-state"
import TripDetailsComponent from "../TripDetailsTab/TripDetailsComponent"
import ItineraryComponent from "../ItineraryTab/ItineraryComponent"
import ChecklistComponent from "../ChecklistTab/ChecklistComponent"
import MessageActivityContainer from "../MessagesTab/MessageActivityContainer"
import ShowError from "../../../components/Error/ShowError"
import GoToTop from "../../../components/GoToTop/GoToTop"
import ReservationsComponent from "../ReservationTab/ReservationsComponent"
import JobStatusContainer from "../JobStatus/JobStatusContainer"
import TripRecommendationsContainer from "../TripRecommendations/TripRecommendationsContainer"
import ItineraryViewer from "../ItineraryTab/ItineraryViewer"
import ReservationViewer from "../ReservationTab/ReservationViewer"
import ChecklistViewer from "../ChecklistTab/ChecklistViewer"
import ShowMessageWindow from "../MessagesTab/ShowMessageWindow"
import * as ROUTES from "../../../constants/routes"
import { navigate } from "gatsby"
import { AuthUserContext } from "../../../components/session"
import ShowLoading from "../../../components/ShowLoading/ShowLoading"
import FlightRecommendations from "../Transportation/FlightRecommendations"
import useRestrictCharacterLength from "../../../hooks/useRestrictCharacterLength"
import { functions } from "../../../components/firebase/firebase"
import { toast } from "react-toastify"
const DetailsForDesignerBase = ({ tripUid, uid, jobUid }) => {
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const dbContext = useContext(FirebaseContext)
  const [isActive, setIsActive] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(true)
  const [showContactMessageBox, setShowContactMessageBox] = useState(false)
  const [messageText, setMessageText] = useState("")
  const [busySendingMessage, setBusySendingMessage] = useState(false)
  useEffect(() => {
    try {
      //Check the current state of the trip for which we are showing the trip details for.
      // // This section initializes the initializes the itineraries object.
      // var startDate = tripState.tripLogistics.travelDates.start.toDate()
      // var endDate = tripState.tripLogistics.travelDates.end.toDate()
      // //Check if itineraries exists, if not initialize the itinerary array
      // if (tripState.itinerary === undefined) {
      //   console.log("Initializing Itineraries!")
      //   tripStateDispatch({
      //     type: "initializeItineraries",
      //     startDate: startDate,
      //     endDate: endDate,
      //   })
      // } else {
      //   console.log("do nothing!")
      // }
    } catch (error) {
      console.log(error)
    }
  }, [tripState])
  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(300)

  useEffect(() => {
    //    dispatch({ type: "setActiveTab", data: "Status" })
    // Update the trip state with current selected trip details.
    // tripStateDispatch({ type: "updateTripState", data: state.tripDetails })
  }, [dbContext])
  useEffect(() => {
    console.log("%cDashboard-State", "color: #FEC600; font-size: 18px", {
      state,
    })
  }, [state])
  useEffect(() => {
    //dispatch({ type: "setActiveTab", data: "Status" })
    setLoading(true)
    console.log({ tripUid })
    console.log({ uid })
    console.log({ jobUid })
    if (tripUid && uid && jobUid) {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("trips")
          .doc(`${uid}`)
          .collection("active-trips")
          .doc(`${tripUid}`)
          .get()
          .then(doc => {
            if (doc.data()) {
              dispatch({
                type: "updateJobDetails",
                data: doc.data(),
              })
              // setToggleTripDetails(true)
              tripStateDispatch({
                type: "updateTripState",
                data: state.tripDetails,
              })

              dbContext
                .firestore()
                .collection("jobs")
                .doc(`${authContext.uid}`)
                .collection("active-jobs")
                .doc(`${jobUid}`)
                .get()
                .then(doc => {
                  if (doc) {
                    var selectedJob = doc.data()
                    if (selectedJob.status === "complete") setIsComplete(true)
                    else setIsComplete(false)
                    if (
                      selectedJob.status === "active" ||
                      selectedJob.status === "review" ||
                      selectedJob.status === "review-complete" ||
                      selectedJob.status === "request-changes"
                    ) {
                      setIsActive(true)
                      dispatch({ type: "setActiveTab", data: "Status" })
                    } else {
                      setIsActive(false)
                      dispatch({ type: "setActiveTab", data: "TripDetails" })
                    }

                    //Update local dashboard state currentJobInfo with the selected job info
                    dispatch({
                      type: "updateCurrentJobInfo",
                      data: selectedJob,
                    })
                    setLoading(false)
                  }
                })
            }
          })
          .catch(e => console.log(e))
      }
    }
  }, [dbContext])

  //Only show the trip details if it is in pending state and has not been accepted yet.
  const tabItemsList =
    isActive || isComplete
      ? [
          { name: "Job Status", id: "Status" },
          { name: "Trip Details", id: "TripDetails" },
          { name: "Itinerary Designer", id: "Itinerary" },
          // { name: "Logistics", id: "Logistics" },

          { name: "Other Info", id: "Recommendations" },
          // { name: "Reservations", id: "Reservations" },
          { name: "Traveler Checklist", id: "Checklist" },
          { name: "Messages", id: "Messages" },
        ]
      : [{ name: "Trip Details", id: "TripDetails" }]

  const renderTabItems = () => {
    return tabItemsList.map(item => (
      <li
        key={item.id}
        className={`${state.activeTab === item.id ? "is-active" : ""}`}
      >
        <a
          id={item.id}
          style={{
            letterSpacing: "0.5px",
            fontSize: "12px",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
          onClick={e => {
            dispatch({ type: "setActiveTab", data: `${e.currentTarget.id}` })
          }}
        >
          {item.name}
        </a>
      </li>
    ))
  }
  const jobAccepted = tripUid => {
    console.log(`${tripUid}`)
    // console.log(`${e.name}`)
    if (tripUid) {
      // find the jobId with a matching tripUid
      // const found = state.jobs.find(element => element.tripUid === tripUid)
      if (dbContext) {
        dbContext
          .firestore()
          .collection("jobs")
          .doc(`${authContext.uid}`)
          .collection("active-jobs")
          .doc(`${jobUid}`)
          .update({ status: "payment-pending", acceptedOn: new Date() })
          .then(function() {
            console.log("Job status successfully updated to active state!")
            dbContext
              .firestore()
              .collection("trips")
              .doc(`${uid}`)
              .collection("active-trips")
              .doc(`${tripUid}`)
              .update({
                designerDetails: {
                  status: "payment-pending",
                  designerUid: authContext.uid,
                  jobUid: jobUid,
                  acceptedOn: new Date(),
                  roomId: state.currentJobInfo.roomId,
                },
              })
              .then(function() {
                addToast(
                  'Great! Once the job is finalized you will see the job in your "Active" section under "Your Jobs"',
                  {
                    appearance: "info",
                    autoDismiss: false,
                  }
                )
                console.log(
                  "Trip details update updated to active state and designer info!"
                )
              })
          })
          .catch(error => {
            console.log("Error getting document:", error)
            addToast("Error accepting the job, please try again in sometime", {
              appearance: "error",
              autoDismiss: true,
            })
          })
      }
    }
  }

  const jobDeclined = tripUid => {
    console.log(`${tripUid}`)
    if (tripUid) {
      // find the jobId with a matching tripUid
      // const found = state.jobs.find(element => element.tripUid === tripUid)
      if (dbContext) {
        dbContext
          .firestore()
          .collection("trips")
          .doc(`${uid}`)
          .collection("active-trips")
          .doc(`${tripUid}`)
          .update({
            designerDetails: {
              status: "declined",
              designerUid: authContext.uid,
            },
          })
          .then(function() {
            console.log("Job status successfully updated to declined state!")
            //Now delete the job from designers queue!
            dbContext
              .firestore()
              .collection("jobs")
              .doc(`${authContext.uid}`)
              .collection("active-jobs")
              .doc(`${jobUid}`)
              .delete()
              .then(function() {
                console.log("Job successfully removed from the designers queue")
              })
          })
          .catch(error => {
            console.log("Error getting document:", error)
            addToast("Error declining the job, please try again in sometime", {
              appearance: "error",
              autoDismiss: true,
            })
          })
      }
    }
  }

  const onClose = () => {
    navigate(`${ROUTES.DASHBOARD}`)
  }

  const sendMessage = () => {
    try {
      var sendMessageToUser = functions.httpsCallable("sendMessageToUser")
      sendMessageToUser({
        message: messageText,
        recipientUid: state.currentJobInfo.travelerUid,
      })
        .then(function(result) {
          // Read result of the Cloud Function.
          setBusySendingMessage(false)
          console.log({ result })
          if (result.data.success) {
            setShowContactMessageBox(false)
            setMessageText("")
          } else {
            toast.error(
              "Error sending message, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
              {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            )
          }
          // ...
        })
        .catch(err => {
          setBusySendingMessage(false)
          console.log(err)
          toast.error(
            "Error sending message, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
            {
              position: "top-right",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          )
        })
    } catch (error) {
      console.log(error)
      toast.error(
        "Error sending message, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      )
    }
  }

  const renderAcceptCancel = () => {
    if (state.currentJobInfo.status === "pending") {
      return (
        <>
          <div
            className="field is-grouped is-grouped-right"
            style={{ marginBottom: "40px" }}
          >
            <p className="control">
              <a
                className="button is-warning is-light"
                style={{ border: "1px solid #fec600" }}
                onClick={() => {
                  // Check if user is signed in
                  const authUser = localStorage.getItem("authUser")
                  if (!authUser) {
                    // If we’re not logged in, redirect to the home page.
                    navigate(`/signup`)
                    return null
                  } else setShowContactMessageBox(true)
                }}
              >
                Message Traveler
              </a>
            </p>
          </div>

          <div
            className="field is-grouped is-grouped-right"
            style={{ marginBottom: "20px" }}
          >
            <p className="control">
              <a
                className="button is-light"
                onClick={() => {
                  onClose()
                }}
              >
                Cancel
              </a>
            </p>
            <p className="control">
              <a
                className="button is-danger is-light"
                onClick={() => {
                  swal({
                    title: "Confirmation",
                    text: "Are you sure you want to decline this job?",
                    //  icon: "question",
                    buttons: true,
                    //dangerMode: true,
                    buttons: ["No, cancel it!", "Yes, I decline the job!"],
                  }).then(willDelete => {
                    if (willDelete) {
                      jobDeclined(state.currentJobInfo.tripUid)
                      onClose()
                      swal("Job removed from your queue!", {
                        icon: "success",
                      })
                    }
                  })
                }}
              >
                Decline
              </a>
            </p>
            <p className="control">
              <a
                className="button is-warning"
                onClick={() => {
                  swal({
                    title: "Confirmation",
                    text: "Are you sure you want to accept this job?",
                    //  icon: "question",
                    buttons: true,
                    //dangerMode: true,
                    buttons: ["No, cancel it!", "Yes, I accept the job!"],
                  }).then(willDelete => {
                    if (willDelete) {
                      jobAccepted(state.currentJobInfo.tripUid)
                      onClose()
                      swal(
                        'Nice! Note: Once the job is finalized you will see the job in your "Active" section under "Your Jobs"',
                        {
                          icon: "success",
                        }
                      )
                    }
                  })
                }}
              >
                Accept
              </a>
            </p>
          </div>
        </>
      )
    }
  }

  const renderSelectedComponent = () => {
    switch (state.activeTab) {
      case "TripDetails":
        return (
          <>
            <TripDetailsComponent />
            {renderAcceptCancel()}
            <hr className="hr-branded"></hr>
          </>
        )

        break
      case "Itinerary":
        return state.currentJobInfo.itineraryStatus === "complete" ? (
          <ItineraryViewer />
        ) : (
          <ItineraryComponent />
        )
        break
      case "Reservations":
        return state.currentJobInfo.reservationsStatus === "complete" ? (
          <ReservationViewer />
        ) : (
          <ReservationsComponent />
        )
        break
      case "Checklist":
        return state.currentJobInfo.tripChecklistStatus === "complete" ? (
          <ChecklistViewer />
        ) : (
          <ChecklistComponent />
        )
        break
      case "Messages":
        return <MessageActivityContainer />
        break
      case "Status":
        return <JobStatusContainer />
        break
      case "Recommendations":
        return state.currentJobInfo.tripChecklistStatus === "complete" ? (
          <TripRecommendationsContainer canEdit={false} />
        ) : (
          <TripRecommendationsContainer canEdit={true} />
        )
        break
      // case "Logistics":
      //   return <FlightRecommendations canEdit={true} />
      //   break
      default:
        return <ShowError />
        break
    }
  }

  return (
    <>
      {loading ? (
        <ShowLoading />
      ) : (
        <>
          <div className={`modal ${showContactMessageBox ? `is-active` : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
              {/* <header className="modal-card-head">
                <p className="modal-card-title">Modal title</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => {
                    setShowContactMessageBox(false)
                  }}
                ></button>
              </header> */}
              <section className="modal-card-body">
                <div className="field">
                  <label className="label">Message:</label>
                  <div className="control is-expanded">
                    <textarea
                      onChange={e => {
                        // if (check(e.currentTarget.value.length)) return
                        console.log(e.currentTarget.value)
                        if (e.currentTarget.value) {
                          setMessageText(e.currentTarget.value)
                        } else {
                          setMessageText("")
                        }
                      }}
                      value={messageText}
                      className="textarea"
                      placeholder="Type your message here..."
                      maxlength={maxCharactersAllowed}
                    ></textarea>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        className={`help is-pulled-right ${
                          reachedMaxLength ? "is-danger" : ""
                        }`}
                      >
                        Characters: {messageText ? messageText.length : "0"}/
                        {maxCharactersAllowed}
                      </p>
                    </div>
                    {/* {errors.accommodation ? (
                    <p className="help is-danger">
                      Please pick you accommodation preference
                    </p>
                  ) : (
                    <p className="help">pick all that apply</p>
                  )} */}
                  </div>
                </div>
              </section>
              <footer className="modal-card-foot">
                <div className="" style={{ marginLeft: "auto" }}>
                  <button
                    className="button"
                    onClick={() => {
                      setShowContactMessageBox(false)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={`${
                      busySendingMessage
                        ? "button is-warning is-loading"
                        : "button is-warning"
                    }`}
                    onClick={() => {
                      setBusySendingMessage(true)
                      sendMessage()
                    }}
                  >
                    Send
                  </button>
                </div>
              </footer>
            </div>
          </div>
          <div className="columns is-centered ">
            <div className="column is-10">
              {/* <GoToTop scrollStepInPx="80" delayInMs="30" /> */}
              {isActive && <ShowMessageWindow />}
              {/* <a
                onClick={() => {
                  onClose()
                }}
                // className="is-text"
              >
                <span className="icon">
                  <i className="fas fa-chevron-left"></i>
                </span>
                <span>Back to dashboard</span>
              </a> */}

              {/* <h3
        style={{ marginTop: "26px" }}
        align="center"
        className="subtitle is-3 is-centered"
      >
        {state.tripDetails.tripName}
      </h3> */}

              <div
                style={{ marginTop: "26px", marginBottom: "26px" }}
                align="center"
                className="destination-header is-centered"
              >
                <div
                  style={{
                    //display: "flex",
                    alignItems: "flex-end",
                    // textTransform: "uppercase",
                    justifyContent: "center",
                  }}
                >
                  <p
                    className="place"
                    style={{
                      fontWeight: "800",
                      // borderBottom: "2px solid #fec600",
                      width: "fit-content",
                      color: "rgba(74, 74, 74, 0.8)",
                      letterSpacing: "0.5px",
                      fontSize: "32px",
                    }}
                  >
                    {state.tripDetails.tripPrimaryDestination.place}
                  </p>
                  {/* <span
            style={{
              color: "#fec600",
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            /
          </span> */}
                  <p style={{ fontWeight: "300", fontSize: "12px" }}>
                    {state.tripDetails.tripPrimaryDestination.country}
                  </p>
                </div>
              </div>
              <div className="tabs is-center is-fullwidth">
                <ul>{renderTabItems()}</ul>
              </div>

              {renderSelectedComponent()}
            </div>
          </div>
        </>
      )}
    </>
  )
}

const DetailsForDesigner = ({ tripUid, uid, jobUid }) => {
  return (
    <ToastProvider>
      {/* <DashboardStateProvider> */}
      <TripStateProvider>
        <DetailsForDesignerBase tripUid={tripUid} uid={uid} jobUid={jobUid} />
      </TripStateProvider>
      {/* </DashboardStateProvider> */}
    </ToastProvider>
  )
}
export default DetailsForDesigner
