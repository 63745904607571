import React, { useContext, useState, useRef, useEffect } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.bubble.css"
import { FirebaseContext } from "../../../components/firebase"
import { AuthUserContext } from "../../../components/session"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import "./TripNotes.scss"
import ReactHtmlParser from "react-html-parser"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"

const TripNotes = ({ canEdit = false }) => {
  const userAuthContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [isBusy, setIsBusy] = useState(false)
  const maxNotesHtmlLen = 1024
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [inEditMode, setInEditMode] = useState(false)
  const [reachedNotesMaxLen, setReachedNotesMaxLen] = useState(false)
  const [tripNotes, setTripNotes] = useState("")
  var notesRef = useRef(null)
  useEffect(() => {
    console.log({ tripNotes })
  }, [tripNotes])
  useEffect(() => {
    if (state) {
      if (state.currentJobInfo) {
        setTripNotes(
          state.currentJobInfo.tripNotes ? state.currentJobInfo.tripNotes : ""
        )
      }
    }
  }, [])
  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      //   ["link", "image"],
      ["link"],
    ],
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
  ]
  const handleNotesInHtml = async html => {
    try {
      if (notesRef) {
        // setShowSaveButton(true)
        const editor = notesRef.getEditor()
        const quill = notesRef.makeUnprivilegedEditor(editor)
        console.log(quill.getLength())
        if (quill.getLength() > maxNotesHtmlLen) {
          setReachedNotesMaxLen(true)
          editor.deleteText(maxNotesHtmlLen, quill.getLength())
        } else {
          dispatch({
            type: "updateTripNotes",
            data: html,
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const showViewer = () => {
    return (
      <div
        style={{
          //   border: "1px solid #fec600",
          //   minHeight: "400px",
          width: "100%",
        }}
      >
        {tripNotes ? (
          <>
            {" "}
            <label className="label">Trip Notes</label>
            <div className="ql-editor">{ReactHtmlParser(tripNotes)}</div>
          </>
        ) : (
          <p
            style={{
              // fontStyle: "italic",
              fontSize: "14px",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            - No notes added yet -
          </p>
        )}
      </div>
    )
  }

  const showEditor = () => {
    return (
      <div
        style={{
          //   border: "1px solid #fec600",
          //   minHeight: "400px",
          width: "100%",
        }}
      >
        <label className="label">Notes</label>
        {/* <ReactQuill
          value={state.currentJobInfo.tripNotes || ""}
          ref={e => (notesRef = e)}
          theme="bubble"
          onChange={handleNotesInHtml}
          onBlur={() => {
            console.log("blur")
          }}
          placeholder="Start typing your notes here..."
          modules={modules}
          formats={formats}
        >
          <div className="my-editing-area" />
        </ReactQuill> */}
        <ReactQuill
          theme="bubble"
          autoFocus
          value={tripNotes}
          onChange={val => {
            setTripNotes(val)
            setShowSaveButton(true)
          }}
          modules={modules}
          formats={formats}
          placeholder="Type or paste your notes here..."
        />
        {showSaveButton && (
          <div
            className="field"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "15px",
            }}
          >
            <div className="control">
              <button
                class={`button is-warning ${isBusy ? "is-loading" : ""}`}
                onClick={() => {
                  if (isBusy) return
                  setIsBusy(true)
                  if (dbContext) {
                    dbContext
                      .firestore()
                      .collection("jobs")
                      .doc(`${tripState.uid}`)
                      .collection("active-jobs")
                      .doc(`${state.currentJobInfo.jobUid}`)
                      .update({
                        tripNotes: tripNotes,
                      })
                      .then(() => {
                        console.log("document update successful")
                        dispatch({
                          type: "updateTripNotes",
                          data: tripNotes,
                        })
                        setIsBusy(false)
                      })
                      .catch(e => {
                        setIsBusy(false)
                        console.log(e)
                      })
                  }
                }}
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  textDecorationColor: "#fec600",
                  color: "rgba(74, 74, 74, 0.8)",
                  textDecoration: "none",
                  minWidth: "100px",
                  //   borderRadius: "10px",
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <div className="columns">
        <div className="column is-2-desktop is-0-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-12-tablet is-12-mobile">
          <p
            // style={{
            //   fontSize: "34px",
            //   fontWeight: "800",
            //   color: "rgba(74,74,74,0.8)",
            //   margin: "20px auto",
            //   lineHeight: "1.4",
            //   textAlign: "center",
            // }}
            className="tab-header"
          >
            Trip Notes<span style={{ color: "#fec600" }}>.</span>
          </p>
          <p className="tab-tagline">
            Jot down your destination research items such as weather, key
            phrases, transportation info, useful local services, tipping policy
            etc.
          </p>
          {canEdit ? showEditor() : showViewer()}
        </div>
        <div className="column is-2-desktop is-0-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}

export default TripNotes
