import React, { useState, useEffect, useContext } from "react"

import "./PersonalInfoContainer.scss"
import { FirebaseContext } from "../../../components/firebase"

import EditableAddressItem from "../../../components/EditableFormComponents/EditableAddressItem"
import EditableNameField from "../../../components/EditableFormComponents/EditableNameField"
import EditableDateField from "../../../components/EditableFormComponents/EditableDateField"
import EditableProfileImageField from "../../../components/EditableFormComponents/EditableProfileImageField"
import EditableSelectList from "../../../components/EditableFormComponents/EditableSelectList"
import AuthUserContext from "../../../components/session/context"
import { dbGet } from "../../../utility/dbCRUD"

import { ToastProvider, useToasts } from "react-toast-notifications"
import {
  ProfileStateProvider,
  useProfileStateStore,
} from "../../../State/profile-state/profile-state"

const PersonalInfoContainerBase = () => {
  const [currentAddress, setCurrentAddress] = useState()
  const [currentUserName, setCurrentUserName] = useState()
  const [currentUserDOB, setCurrentUserDOB] = useState()
  const [userPhotoURL, setUserPhotoURL] = useState("")
  const authUser = useContext(AuthUserContext)
  const [state, dispatch] = useProfileStateStore()

  const dbContext = useContext(FirebaseContext)
  function getSafe(value, defaultVal) {
    try {
      return value
    } catch (e) {
      return defaultVal
    }
  }

  useEffect(() => {
    console.log(currentAddress)
  }, [currentAddress])

  useEffect(() => {
    //User profile picture.
    // if (authUser) {
    //   setUserPhotoURL(authUser.providerData[0].photoURL)
    // }

    dbGet(dbContext, "users", authUser.uid)
      .then(doc => {
        if (doc.exists) {
          const _profileData = doc.data()
          const _address = getSafe(_profileData.homeAddress, null)
          const _fName = getSafe(doc.data().firstName, null)
          const _lName = getSafe(doc.data().lastName, null)
          const _dob = getSafe(doc.data().dob, "")
          const _userProfileImage = getSafe(doc.data().userProfileImage, "")
          console.log("_dob", _dob)
          setCurrentUserDOB(_dob)
          setCurrentUserName({ firstName: _fName, lastName: _lName })
          if (_address) {
            setCurrentAddress(_address)
          }
          setUserPhotoURL(_userProfileImage)
        }
      })
      .catch(error => {
        console.log("Error getting document:", error)
      })
  }, [])

  return (
    <div className="columns">
      <div className="column is-2-desktop is-1-tablet "></div>
      <div className="column is-8-desktop is-10-tablet ">
        {userPhotoURL ? (
          <div style={{ maxWidth: "200px", margin: "0px auto" }}>
            <EditableProfileImageField
              profileImageURL={userPhotoURL}
              doUpdateImage={imageUrl => {
                dispatch({
                  type: "updateProfileImageUrl",
                  data: imageUrl,
                  context: dbContext,
                  uid: `${authUser.uid}`,
                })
              }}
            />
          </div>
        ) : (
          ""
        )}
        <hr className="hr-branded"></hr>
        {/* <p className="brand-border"> */}
        {currentUserName ? (
          <EditableNameField nameObject={currentUserName} />
        ) : (
          ""
        )}
        <hr className="hr-branded"></hr>
        {currentAddress ? (
          <EditableAddressItem addressObject={currentAddress} />
        ) : (
          ""
        )}
        <hr className="hr-branded"></hr>

        <EditableDateField dateObject={currentUserDOB} />
        <hr className="hr-branded"></hr>

        {/* <EditableSelectList label={"Gender"} />
              <hr className="hr-branded"></hr>

              <EditableSelectList label={"Diet"} />
              </p> */}
      </div>

      <div className="column is-2-desktop is-1-tablet "></div>
    </div>
  )
}

const PersonalInfoContainer = () => {
  return (
    <ToastProvider>
      <ProfileStateProvider>
        <PersonalInfoContainerBase />
      </ProfileStateProvider>
    </ToastProvider>
  )
}

export default PersonalInfoContainer
