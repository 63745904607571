import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react"
import { useAsync } from "react-async"
import { FirebaseContext } from "../../../components/firebase"
import ReactTooltip from "react-tooltip"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
const fetchProfile = async ({ ctx }) => {
  try {
    let { dbContext, uid } = ctx
    console.log({ ctx })
    if (dbContext) {
      if (uid) {
        let response = await dbContext
          .firestore()
          .collection("users")
          .doc(`${uid}`)
          .collection("publicProfile")
          .doc(`${uid}`)
          .get()

        if (response) return { ...response.data(), uid: uid }
      } else {
        return Error
      }
    } else {
      return Error
    }
  } catch (err) {
    console.log(err)
  }
}

export const TripMatesProfiles = React.memo(({ uid }) => {
  const dbContext = useContext(FirebaseContext)
  const [state, dispatch] = useDashboardStateStore()
  const [presence, setPresence] = useState("offline")
  let ctx = { uid: uid, dbContext: dbContext }

  const { data, error } = useAsync({ promiseFn: fetchProfile, ctx: ctx })
  //   console.log(uid)
  useEffect(() => {
    activatePresence()
    return () => {
      deactivatePresence()
    }
  }, [dbContext])

  useEffect(() => {
    console.log({ presence })
  }, [presence])

  const activatePresence = () => {
    if (dbContext)
      return dbContext
        .database()
        .ref("/status/" + uid)
        .on("value", snapshot => {
          console.log(snapshot.val())
          if (snapshot.val()) {
            var a = snapshot.val().state || "offline"
            setPresence(a)
            console.log({ a })
          } else setPresence("")
        })
  }
  const deactivatePresence = () => {
    if (dbContext)
      return dbContext
        .database()
        .ref("/status/" + uid)
        .off()
  }

  if (error) return "Error"

  if (data) {
    // console.log({ data })
    dispatch({
      type: "updateCollaboratorData",
      data: data,
      uid: data.uid,
    })
    return (
      <div>
        <ReactTooltip effect="solid" />
        <div
          style={{
            width: "40px",
            height: "40px",
            border: "2px solid #eaeaea",
            borderRadius: "50%",
            padding: "2px",
            position: "relative",
          }}
          data-tip={`${
            data.username ? data.username : data.firstName + " " + data.lastName
          } - ${presence}`}
        >
          <img
            style={{ width: "100%", borderRadius: "50%" }}
            src={data.userProfileImage}
            alt="profile-image"
          />
          {presence === "online" ? (
            <span
              style={{
                width: "15px",
                height: "15px",
                position: "absolute",
                background: "#2bca40",
                right: "-1px",
                bottom: "-1px",
                borderRadius: "50%",
                border: "1px solid white",
              }}
            ></span>
          ) : (
            ""
          )}
          {presence === "away" ? (
            <span
              style={{
                width: "15px",
                height: "15px",
                position: "absolute",
                background: "#fbe1a8",
                right: "-1px",
                bottom: "-1px",
                borderRadius: "50%",
                border: "1px solid white",
              }}
            ></span>
          ) : (
            ""
          )}
          {presence === "offline" ? (
            <span
              style={{
                width: "15px",
                height: "15px",
                position: "absolute",
                background: "#eaeaea",
                right: "-1px",
                bottom: "-1px",
                borderRadius: "50%",
                border: "1px solid white",
              }}
            ></span>
          ) : (
            ""
          )}
        </div>
      </div>
    )
  } else {
    return <div>...</div>
  }
})
export default TripMatesProfiles
