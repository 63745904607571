import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slider,
  Slide,
} from "pure-react-carousel"

import React, { useState, useEffect, useRef, useContext } from "react"
import { useDashboardStateStore } from "../../State/dashboard-state/dashboard-state"
import algoliasearch from "algoliasearch/lite"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"
import { dottedText, titleCase } from "../../utility/general"
import { LazyLoadImage } from "react-lazy-load-image-component"
import noImageDefault from "../../images/no-image-background.png"
import ShowImage from "../ShowImage/ShowImage"
import ReactTooltip from "react-tooltip"
const searchClient = algoliasearch(
  `6ISV547TER`,
  `7f1f9b4df5ef999caa0d7c6ba0ba8251`
)
const TopAttractions = ({ addAction, showDetailAction }) => {
  const [recommendationHits, setRecommendationHits] = useState()
  const [state, dispatch] = useDashboardStateStore()
  const [showMore, setShowMore] = useState(false)
  const [attractionsCount, setAttractionsCount] = useState(15)
  const getResponsiveCount = sWidth => {
    if (sWidth < 400) return 1.3
    if (sWidth < 640) return 1.5
    if (sWidth < 1024) return 2.5
    if (sWidth < 1300) return 3.5
    if (sWidth < 1600) return 4.5
    if (sWidth > 1600) return 6
  }
  const getResponsiveStepCount = sWidth => {
    if (sWidth < 640) return 1
    if (sWidth < 1024) return 2
    if (sWidth < 1254) return 3
    if (sWidth < 1600) return 4
    if (sWidth > 1600) return 6
  }
  const clearSelection = () => {
    try {
      if (state.currentJobInfo.tripWishlist) {
        dispatch({
          type: "ResetWishlistSelections",
        })
      }
    } catch (error) {}
  }

  useEffect(() => {
    console.log({ attractionsCount })
  }, [attractionsCount])

  const screenWidth = useWindowWidth()
  useEffect(() => {
    async function setRecommendationHitsFromFacets() {
      try {
        if (state.tripDetails.tripDestinations) {
          // let tripDestination = state.tripDetails.tripDestinations.map(
          //   (item, index) => {
          //     return `activityCity:${item.place}`
          //   }
          // )

          const index = searchClient.initIndex("attractions")
          // state.tripDetails.tripDestinations
          let foundFacets = []
          const jobs = []
          let facetType = "city"
          state.tripDetails.tripDestinations.forEach(async element => {
            if (element.place === element.country) {
              // user selected a country
              facetType = "country"
              setShowMore(true)
            } else {
              if (
                element.place === element.state &&
                element.country === "United States" // This is an exception for US where user can just type Oregon as the destination.
              ) {
                facetType = "state"
                setAttractionsCount(30)
              } else {
                facetType = "city"
              }
            }
            let itemsHI = []
            //Clumsy hack. Need to find a solution to this. :(
            switch (element.place) {
              case "Scotland":
                itemsHI = [
                  "Glasgow",
                  "Edinburgh",
                  "Isle Of Skye",
                  "Fort Williams",
                  "Glenfinnan",
                  "Dornie",
                ]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        if (facetHits && facetHits[0]) {
                          console.log(facetHits[0].value)
                          console.log(facetHits)
                          if (facetHits) foundFacets.push(facetHits[0].value)
                        }
                      })
                    jobs.push(job)
                  }
                })
                break
              case "Buffalo":
                itemsHI = ["Buffalo", "Niagara Falls"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        if (facetHits && facetHits[0]) {
                          console.log(facetHits[0].value)
                          console.log(facetHits)
                          if (facetHits) foundFacets.push(facetHits[0].value)
                        }
                      })
                    jobs.push(job)
                  }
                })
                break
              case "England":
                itemsHI = [
                  "London",
                  "Manchester",
                  "Leeds",
                  "Liverpool",
                  "Blackpool",
                ]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        if (facetHits && facetHits[0]) {
                          console.log(facetHits[0].value)
                          console.log(facetHits)
                          if (facetHits) foundFacets.push(facetHits[0].value)
                        }
                      })
                    jobs.push(job)
                  }
                })
                break
              case "Island of Hawai‘i":
                itemsHI = ["hilo", "kailua-kona", "honokaa"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        if (facetHits && facetHits[0]) {
                          console.log(facetHits[0].value)
                          console.log(facetHits)
                          if (facetHits) foundFacets.push(facetHits[0].value)
                        }
                      })
                    jobs.push(job)
                  }
                })
                break
              case "Dubai City":
                itemsHI = ["Dubai"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        console.log(facetHits[0].value)
                        console.log(facetHits)
                        if (facetHits) foundFacets.push(facetHits[0].value)
                      })
                    jobs.push(job)
                  }
                })
                break
              case "Kailua-Kona":
                itemsHI = ["hilo", "kailua-kona", "honokaa"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        if (facetHits && facetHits[0]) {
                          console.log(facetHits[0].value)
                          console.log(facetHits)
                          if (facetHits) foundFacets.push(facetHits[0].value)
                        }
                      })
                    jobs.push(job)
                  }
                })
                break
              case "Santorini":
                itemsHI = ["Santorini", "Oia", "Mykonos"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        if (facetHits && facetHits[0]) {
                          console.log(facetHits[0].value)
                          console.log(facetHits)

                          if (facetHits) foundFacets.push(facetHits[0].value)
                        }
                      })
                    jobs.push(job)
                  }
                })
                break
              case "Rome":
                itemsHI = ["Rome", "Vatican City"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        console.log(facetHits[0].value)
                        console.log(facetHits)

                        if (facetHits) foundFacets.push(facetHits[0].value)
                      })
                    jobs.push(job)
                  }
                })
                break
              // case "Vatican City":
              //   itemsHI = ["Vatican City", "Rome"]
              //   itemsHI.map(item => {
              //     {
              //       const job = index
              //         .searchForFacetValues(facetType, `${item}`)
              //         .then(({ facetHits }) => {
              //           console.log(facetHits[0].value)
              //           console.log(facetHits)

              //           if (facetHits) foundFacets.push(facetHits[0].value)
              //         })
              //       jobs.push(job)
              //     }
              //   })
              //   break
              case "Honolulu":
                itemsHI = ["Oahu"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        if (facetHits && facetHits[0]) {
                          console.log(facetHits[0].value)
                          console.log(facetHits)
                          if (facetHits) foundFacets.push(facetHits[0].value)
                        }
                      })
                    jobs.push(job)
                  }
                })
                break
              case "Frankfurt am Main":
                itemsHI = ["Frankfurt", "Frankfurt am Main"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        console.log(facetHits[0].value)
                        console.log(facetHits)
                        if (facetHits) foundFacets.push(facetHits[0].value)
                      })
                    jobs.push(job)
                  }
                })
                break
              case "Lahaina":
                itemsHI = ["Maui"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        if (facetHits && facetHits[0]) {
                          console.log(facetHits[0].value)
                          console.log(facetHits)
                          if (facetHits) foundFacets.push(facetHits[0].value)
                        }
                      })
                    jobs.push(job)
                  }
                })
                break
              case "Kauai Island":
                itemsHI = ["Kauai"]
                itemsHI.map(item => {
                  {
                    const job = index
                      .searchForFacetValues(facetType, `${item}`)
                      .then(({ facetHits }) => {
                        if (facetHits && facetHits[0]) {
                          console.log(facetHits[0].value)
                          console.log(facetHits)
                          if (facetHits) foundFacets.push(facetHits[0].value)
                        }
                      })
                    jobs.push(job)
                  }
                })
                break
              // case "O‘ahu":
              //   itemsHI = ["Oahu", "Honolulu"]
              //   itemsHI.map(item => {
              //     {
              //       const job = index
              //         .searchForFacetValues(facetType, `${item}`)
              //         .then(({ facetHits }) => {
              //           console.log(facetHits[0].value)
              //           console.log(facetHits)
              //           if (facetHits) foundFacets.push(facetHits[0].value)
              //         })
              //       jobs.push(job)
              //     }
              //   })
              //   break

              default:
                console.log(element.place)
                const job = index
                  .searchForFacetValues(facetType, `${element.place}`)
                  .then(({ facetHits }) => {
                    console.log({ facetHits, foundFacets })

                    if (facetHits !== [] && facetHits.length > 0) {
                      if (facetHits.length > 1) {
                        facetHits.map(item => {
                          if (
                            item.value.toLowerCase() ===
                            element.place.toLowerCase()
                          ) {
                            foundFacets.push(item.value)
                          }
                        })
                      } else {
                        foundFacets.push(facetHits[0].value)
                      }
                    }
                  })
                jobs.push(job)
                break
            }
            // if (element.place === "Island of Hawai‘i") {
            //   let itemsHI = ["hilo", "kailua-kona", "honokaa"]
            //   itemsHI.map(item => {
            //     {
            //       const job = index
            //         .searchForFacetValues(facetType, `${item}`)
            //         .then(({ facetHits }) => {
            //           console.log(facetHits[0].value)
            //           console.log(facetHits)
            //           if (facetHits) foundFacets.push(facetHits[0].value)
            //         })
            //       jobs.push(job)
            //     }
            //   })
            // } else {
            //   const job = index
            //     .searchForFacetValues(facetType, `${element.place}`)
            //     .then(({ facetHits }) => {
            //       console.log(facetHits[0].value)
            //       console.log(facetHits)
            //       if (facetHits) foundFacets.push(facetHits[0].value)
            //     })
            //   jobs.push(job)
            // }
          })

          await Promise.all(jobs)
          console.log({ foundFacets })
          console.log(foundFacets.length)
          if (foundFacets.length > 1)
            setAttractionsCount(foundFacets.length * 15)
          else setAttractionsCount(15)
          if (foundFacets != [] && foundFacets.length !== 0) {
            let tripDestination = ""
            let faceValue = ""
            tripDestination = foundFacets.map((item, index) => {
              console.log({ item })
              faceValue =
                faceValue === ""
                  ? faceValue + ", " + `${facetType}:${item}`
                  : faceValue + ", " + `${facetType}:${item}`
              return `${facetType}:${item}`
            })
            console.log({ tripDestination })
            index
              .search("", {
                facetFilters: [tripDestination],
                // filters: 'activityCity:"New York"',
              })
              .then(({ hits }) => {
                setRecommendationHits([...hits])
              })
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    setRecommendationHitsFromFacets()
  }, [state.tripDetails.tripDestinations])

  const showTopAttractions = () => {
    try {
      if (recommendationHits) {
        {
          return (
            <div style={{ marginTop: "0px" }}>
              <ReactTooltip
                backgroundColor="#ebebeb"
                textColor="#4a4a4ad9"
                borderColor="#fec600"
                delayShow="500"
                place="bottom"
                id="top-attraction-menu"
                effect="solid"
              />
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "16px",
                  // textTransform: "uppercase",
                  color: "rgba(74,74,74,0.8)",
                  marginBottom: "10px",
                }}
              >
                Top attractions
              </p>
              <div className="wl-attractions-scroll">
                <div
                  className="pat-destinations-nearby-container"
                  // style={{ margin: "5px 40px", display: "" }}
                >
                  <CarouselProvider
                    naturalSlideWidth={208}
                    naturalSlideHeight={148}
                    totalSlides={attractionsCount}
                    isPlaying={false}
                    interval={10000}
                    infinite={false}
                    step={getResponsiveStepCount(screenWidth)}
                    visibleSlides={getResponsiveCount(screenWidth)}
                    //visibleSlides={5}
                    // currentSlide={1}
                    isIntrinsicHeight={true}
                    isIntrinsicWidth={true}
                    // naturalSlideWidth={275}
                    className="nearby-destination-carousel"
                  >
                    <Slider style={{}}>
                      {recommendationHits.map((item, index) => {
                        if (index > attractionsCount) return ""
                        else
                          return (
                            <Slide index={index} key={index}>
                              <div
                                className="wl-card-carousel-compact"
                                style={{
                                  // width: "unset",
                                  // width: "315px",
                                  // minHeight: "75px",
                                  // backgroundColor: "rgba(254,198,0,0.7)",
                                  marginBottom: "30px",
                                  cursor: "pointer",
                                }}
                                onClick={e => {
                                  const mouseUp = e.clientX
                                  if (
                                    mouseUp < window.checkForDrag + 5 &&
                                    mouseUp > window.checkForDrag - 5
                                  ) {
                                    if (showDetailAction) {
                                      showDetailAction({ item: item })
                                    }
                                  }
                                }}
                                onMouseDown={e => {
                                  window.checkForDrag = e.clientX
                                }}
                                // onMouseUp={e => {
                                //   const mouseUp = e.clientX
                                //   if (
                                //     mouseUp < window.checkForDrag + 5 &&
                                //     mouseUp > window.checkForDrag - 5
                                //   ) {
                                //     if (showDetailAction) {
                                //       showDetailAction({ item: item })
                                //     }
                                //   }
                                // }}
                              >
                                {" "}
                                <div>
                                  {/* <LazyLoadImage
                                src={testImage}
                                effect="blur"
                              ></LazyLoadImage> */}
                                  {item.image ? (
                                    <ShowImage
                                      imageObj={item.image}
                                      isFullWidth={false}
                                      isRounded={true}
                                    />
                                  ) : (
                                    <></>
                                    // showImage(item.image, false, true)
                                    // <figure class="image is-4x3">
                                    //   <LazyLoadImage
                                    //     src={noImageDefault}
                                    //     style={{
                                    //       opacity: "100%",
                                    //       height: "228px",
                                    //       marginTop: "1px",
                                    //       borderRadius: "0px 4px 4px 0px",
                                    //     }}
                                    //   />
                                    // </figure>
                                  )}

                                  <div
                                    // style={{ padding: "0px 10px" }}
                                    className="wl-card-carousel-compact-txt-container"
                                    style={{
                                      borderRadius: item.image
                                        ? " 0px 0px 4px 4px"
                                        : "4px",
                                    }}
                                  >
                                    <div style={{}}>
                                      <div className="accommodation-name">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginRight: "10px",
                                              fontSize: "12px",
                                              // color: "white",
                                            }}
                                          >
                                            {" "}
                                            {dottedText(
                                              titleCase(item.name),
                                              27
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        className="accommodation-address"
                                        style={{
                                          fontSize: "10px",
                                          marginTop: "-2px",
                                          fontWeight: "400",
                                          letterSpacing: "0.5px",
                                          cursor: "default",
                                          // color: "white",
                                        }}
                                      >
                                        <span
                                          // className="tag is-white is-small wl-type"
                                          style={{
                                            marginRight: "7px",
                                            fontSize: "10px",
                                            // color: "white",
                                          }}
                                        >
                                          {" "}
                                          {dottedText(
                                            titleCase(
                                              titleCase(item.city) +
                                                ", " +
                                                titleCase(item.country)
                                            ),
                                            28
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      style={
                                        {
                                          // width: "118px",
                                          // marginRight: "0px",
                                          // marginLeft: "auto",
                                        }
                                      }
                                    >
                                      <button
                                        onClick={e => {
                                          e.stopPropagation()
                                          clearSelection()
                                          if (addAction) addAction(item)
                                        }}
                                        style={{
                                          fontWeight: "400",
                                          color: "#4a4a4a",
                                          marginTop: "10px",
                                          marginBottom: "5px",
                                          // marginRight: "12px",
                                          // marginBottom: "12px",
                                          fontSize: "12px",
                                          // width: "55px",
                                          // height: "25px",
                                          width: "208px",
                                          zIndex: "999",
                                          // borderRadius: "10px",
                                          // textTransform: "uppercase",
                                        }}
                                        className="button is-warning is-small"
                                        // data-tip={`Add to plan`}
                                        // data-for="top-attraction-menu"
                                      >
                                        {/* <i class="far fa-check"></i> */}
                                        Add to itinerary
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="accommodation-address"
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "10px",
                                    cursor: "default",
                                    display: "flex",
                                    // justifyContent: "flex-end",
                                    width: "100%",
                                    marginLeft: "10px",
                                    display: "none",
                                  }}
                                >
                                  <button
                                    onClick={e => {
                                      clearSelection()
                                      if (addAction) addAction(item)
                                      e.stopPropagation()
                                    }}
                                    style={{
                                      fontWeight: "400",
                                      color: "#4a4a4a",
                                      marginRight: "12px",
                                      marginBottom: "12px",
                                      fontSize: "12px",
                                      width: "75px",
                                      height: "25px",
                                      // borderRadius: "10px",
                                      // textTransform: "uppercase",
                                    }}
                                    className="button is-warning is-small"
                                  >
                                    {/* <i class="far fa-plus"></i> */}
                                    Add
                                  </button>
                                  {/* <button
                                    onClick={() => {
                                      showDetailsModal({ item: item })
                                    }}
                                    style={{ color: "#4a4a4a" }}
                                    className="button is-text is-small"
                                  >
                                    View Details
                                  </button> */}
                                </div>
                              </div>
                            </Slide>
                          )
                      })}
                    </Slider>
                    <ButtonBack className="button wl-recommendations-back-button">
                      <i className="far fa-angle-left fa-lg"></i>
                    </ButtonBack>
                    <ButtonNext className="button wl-recommendations-next-button">
                      <i className="far fa-angle-right fa-lg"></i>
                    </ButtonNext>
                  </CarouselProvider>
                </div>
              </div>
            </div>
          )
        }
      }
    } catch (error) {
      return ""
    }
  }
  return <>{showTopAttractions()}</>
}

export default TopAttractions
