import React, { useContext, useState, useRef, useEffect } from "react"
import { AuthUserContext } from "../../../components/session"
import { fetchFromAPI } from "../../../utility/general"
// import { offers_list } from "./DuffelAPI"
import DatePicker from "react-date-picker"
import { cabinClass, journeyType } from "../../../constants/data/general"
import Select from "react-select"
import algoliaImg from "../../../images/search-by-algolia.png"
import {
  Autocomplete,
  connectAutoComplete,
  InstantSearch,
  Highlight,
  connectPoweredBy,
  SearchBox,
  connectSearchBox,
  connectHitsPerPage,
  connectHits,
  connectPagination,
  connectRefinementList,
  RefinementList,
} from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
const searchClient = algoliasearch(
  `${process.env.ALGOLIA_SEARCH_APP_ID}`,
  `${process.env.ALGOLIA_SEARCH_API_KEY}`
)
const FlightRecommendations = () => {
  var originRef = useRef(null)
  var originRefResults = useRef(null)
  var destinationRef = useRef(null)
  const authContext = useContext(AuthUserContext)
  const [forceRefresh, setForceRefresh] = useState(false)
  const [showHits, setShowHits] = useState(false)
  const [originAirport, setOriginAirport] = useState("")
  const [destinationAirport, setDestinationAirport] = useState("")
  const [originHitIndex, setOriginHitIndex] = useState(-1)
  const [journeyTypeSelection, setJourneyTypeSelection] = useState({
    value: "roundtrip",
    label: "Round-trip",
  })

  // const [searchState, setSearchState] = useState({
  //   query: "paris",
  //   page: 1,
  // })
  useEffect(() => console.log(originAirport), [originAirport])
  const fetchFlightData = async () => {
    try {
      const token = await authContext.getIdToken()
      console.log({ token })
      const result = await fetchFromAPI(
        "POST",
        "api/fetchflights",
        {
          body: {},
        },
        token
      )
      console.log({ result })
    } catch (err) {
      console.log({ err })
      return "error"
    }
  }

  const clearOriginSelection = hits => {
    try {
      hits.map((item, index) => {
        document.getElementById(`origin-hit-${index}`).className = ""
      })
    } catch (err) {}
  }
  const clearDestinationSelection = hits => {
    try {
      hits.map((item, index) => {
        document.getElementById(`destination-hit-${index}`).className = ""
      })
    } catch (err) {}
  }

  const hideOriginHits = hits => {
    try {
      hits.map((item, index) => {
        console.log("hide")
        document.getElementById(`origin-hit-${index}`).style.display = "none"
      })
    } catch (err) {}
  }
  const showOriginHits = hits => {
    try {
      hits.map((item, index) => {
        console.log("hide")
        document.getElementById(`origin-hit-${index}`).style.display = "auto"
      })
    } catch (err) {}
  }
  const hideDestinationHits = hits => {
    try {
      hits.map((item, index) => {
        console.log("hide")
        document.getElementById(`destination-hit-${index}`).style.display =
          "none"
      })
    } catch (err) {}
  }

  // useEffect(() => {
  //   console.log({ originHitIndex })
  // }, [originHitIndex])
  var cursorOrigin = -1
  const keyChangedOrigin = (e, hits) => {
    // console.log({ selectedItemIndex })
    console.log(e.keyCode)
    try {
      clearOriginSelection(hits)
      switch (e.keyCode) {
        case 27: // ESC
          // setShowHits(false)
          return
        case 38: // UP
          if (hits) {
            console.log(cursorOrigin)
            if (cursorOrigin >= 0) {
              // setOriginHitIndex(1)
              cursorOrigin = cursorOrigin - 1
            }
            if (cursorOrigin === -1) {
              console.log("zero")
              cursorOrigin = hits.length - 1
            }
            originRef.value = `${hits[cursorOrigin].city +
              " | " +
              hits[cursorOrigin].name +
              " | " +
              hits[cursorOrigin].iata_code}`
            document.getElementById(`origin-hit-${cursorOrigin}`).className +=
              "card-focussed"
          }
          break
        case 40: // DOWN
          if (hits) {
            console.log(cursorOrigin)
            if (cursorOrigin < hits.length) {
              // setOriginHitIndex(1)
              cursorOrigin = cursorOrigin + 1
            }
            if (cursorOrigin === hits.length) {
              console.log("equal")
              cursorOrigin = 0
            }
            // if (cursorOrigin === -1) {
            //   console.log("equal")
            //   cursorOrigin = 0
            // }
            originRef.value = `${hits[cursorOrigin].city +
              " | " +
              hits[cursorOrigin].name +
              " | " +
              hits[cursorOrigin].iata_code}`
            document.getElementById(`origin-hit-${cursorOrigin}`).className +=
              "card-focussed"
          }
          break
          //   if (selectedItemIndex < state.searchResults.length - 1) {
          //     var a = selectedItemIndex + 1
          //     if (selectedItemIndex != -1) clearFocus()
          //     setSelectedItemIndex(a)
          //   }
          break
        case 13: //ENTER
        case 9:
          if (hits) {
            if (cursorOrigin !== -1) {
              let a = `${hits[cursorOrigin].city +
                " | " +
                hits[cursorOrigin].name +
                " | " +
                hits[cursorOrigin].iata_code}`
              setOriginAirport(a)
              document.getElementById(`origin-hit-${cursorOrigin}`).className +=
                "card-focussed"
            }
          }
          break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }

  var cursorDestination = -1
  const keyChangedDestination = (e, hits) => {
    // console.log({ selectedItemIndex })
    console.log(e.keyCode)
    try {
      clearDestinationSelection(hits)
      switch (e.keyCode) {
        case 27: // ESC
          // setShowHits(false)
          return
        case 38: // UP
          if (hits) {
            console.log(cursorDestination)
            if (cursorDestination >= 0) {
              // setOriginHitIndex(1)
              cursorDestination = cursorDestination - 1
            }
            if (cursorDestination === -1) {
              console.log("zero")
              cursorDestination = hits.length - 1
            }
            destinationRef.value = `${hits[cursorDestination].city +
              " | " +
              hits[cursorDestination].name +
              " | " +
              hits[cursorDestination].iata_code}`
            document.getElementById(
              `destination-hit-${cursorDestination}`
            ).className += "card-focussed"
          }
          break
        case 40: // DOWN
          if (hits) {
            console.log(cursorDestination)
            if (cursorDestination < hits.length) {
              // setOriginHitIndex(1)
              cursorDestination = cursorDestination + 1
            }
            if (cursorDestination === hits.length) {
              console.log("equal")
              cursorDestination = 0
            }
            // if (cursorDestination === -1) {
            //   console.log("equal")
            //   cursorDestination = 0
            // }
            destinationRef.value = `${hits[cursorDestination].city +
              " | " +
              hits[cursorDestination].name +
              " | " +
              hits[cursorDestination].iata_code}`
            document.getElementById(
              `destination-hit-${cursorDestination}`
            ).className += "card-focussed"
          }
          break
          //   if (selectedItemIndex < state.searchResults.length - 1) {
          //     var a = selectedItemIndex + 1
          //     if (selectedItemIndex != -1) clearFocus()
          //     setSelectedItemIndex(a)
          //   }
          break
        // case 13: //ENTER
        //   if (selectedItemIndex != -1) {
        //     clearFocus()
        //     // Add to days activities list.
        //     dispatch({
        //       type: "addSearchedActivity",
        //       activeTab: activeTab,
        //       selectedItem: selectedItemIndex,
        //     })
        //     dispatch({ type: "updateSearchResults", data: [] })
        //     setSelectedItemIndex(-1)
        //     setSearchText("")
        //     //update cloud
        //     dispatch({ type: "syncSchedule", data: true })
        //     //setDesignerWorkSpaceModified(true)
        //   }
        //   break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }

  const PoweredBy = ({ url }) => (
    <a href={url} style={{ color: "#4a4a4a", fontSize: "14px" }}>
      <img src={algoliaImg} alt="algolia" style={{ maxHeight: "18px" }} />
    </a>
  )
  const CustomPoweredBy = connectPoweredBy(PoweredBy)

  const AutocompleteOrigin = React.memo(
    ({ currentRefinement, refine, hits }) => {
      console.log({ hits, refine, currentRefinement })
      //dispatch({ type: "updateSearchResults", data: hits })
      return (
        <div style={{ position: "relative" }}>
          <div style={{ position: "" }}>
            <label className="label">Origin</label>
            <input
              autocomplete="off"
              ref={e => (originRef = e)}
              // autoFocus
              style={{ width: "300px" }}
              id="search-bar"
              // type="search"
              className="input"
              value={
                currentRefinement.length === 0
                  ? originAirport
                  : currentRefinement
              }
              onChange={event => {
                refine(event.currentTarget.value)
                console.log(event.currentTarget.value.length)
                if (event.currentTarget.value.length === 0) {
                  hideOriginHits(hits)
                  cursorOrigin = -1
                  setOriginAirport(event.currentTarget.value)
                }
              }}
              onBlur={e => {
                // setOriginAirport(e.currentTarget.value)
                setTimeout(() => {
                  // setOriginAirport(e.currentTarget.value)
                  hideOriginHits(hits)
                }, 500)
              }}
              onKeyDown={e => keyChangedOrigin(e, hits)}
            />
          </div>
          <div style={{ position: "absolute", top: "100px", left: "10px" }}>
            <ul style={{ zIndex: "99" }}>
              {currentRefinement.length > 0 &&
                hits.map((hit, index) => {
                  // <li key={hit.objectID}>{hit.firstName}</li>
                  return (
                    <li
                      key={hit.objectID}
                      id={`origin-hit-${index}`}
                      // className="card result-cards"
                      // className={`${cursor === index ? "card-focussed" : ""}`}
                      ref={e => (originRefResults = e)}
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                        // width: "500px !important",
                      }}
                      onActive={() => {
                        // originRef.value = `${hit.city +
                        //   " | " +
                        //   hit.name +
                        //   " | " +
                        //   hit.iata_code}`
                        console.log("focussed")
                      }}
                      onClick={params => {
                        // console.log({ hit })
                        // console.log(originRef.value)
                        // showOriginHits(hits)
                        console.log(hit)
                        let a = `${hit.city +
                          " | " +
                          hit.country +
                          " | " +
                          hit.name +
                          " | " +
                          hit.iata_code}`
                        setOriginAirport(a)

                        // setOriginAirport(e.currentTarget.value)
                        hideOriginHits(hits)
                        // originRef.value =
                        // dispatch({
                        //   type: "addSearchedActivity",
                        //   activeTab: activeTab,
                        //   selectedItem: hit,
                        // })
                        // setShowHits(false)
                        // dispatch({ type: "syncSchedule", data: true })
                      }}
                    >
                      <div
                        // className="media-content"
                        style={{ fontSize: "14px" }}
                      >
                        {/* <div className="content"> */}
                        <p>
                          {hit.city}
                          {" | "}
                          {hit.country}
                          {" | "}
                          {hit.name}
                          {" | "}
                          {hit.iata_code}
                          {/* <strong>{hit.activityName}</strong>{" "}
                        <small style={{ fontWeight: "300" }}>
                          {hit.activityType}
                        </small>{" "}
                        <br></br> <small>{hit.activityAddress}</small> */}
                          {/* <br></br>
                        {dottedText(hit.activityDescription, 100)} */}
                        </p>
                        {/* </div> */}
                      </div>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      )
    }
  )
  const AutocompleteDestination = React.memo(
    ({ currentRefinement, refine, hits }) => {
      console.log({ hits, refine, currentRefinement })
      //dispatch({ type: "updateSearchResults", data: hits })
      return (
        <div style={{ position: "relative" }}>
          <div style={{ position: "" }}>
            <label className="label">Destination</label>
            <input
              autocomplete="off"
              ref={e => (destinationRef = e)}
              // autoFocus
              style={{ width: "300px" }}
              id="search-bar"
              // type="search"
              className="input"
              value={
                currentRefinement.length === 0
                  ? destinationAirport
                  : currentRefinement
              }
              onChange={event => {
                refine(event.currentTarget.value)
                console.log(event.currentTarget.value.length)
                if (event.currentTarget.value.length === 0) {
                  hideDestinationHits(hits)
                  cursorDestination = -1
                  // setOriginAirport(event.currentTarget.value)
                }
              }}
              onBlur={e => {
                // setOriginAirport(e.currentTarget.value)
                setTimeout(() => {
                  console.log("blur")
                  hideDestinationHits(hits)
                }, 500)
              }}
              onKeyDown={e => keyChangedDestination(e, hits)}
            />
          </div>
          <div style={{ position: "absolute", top: "100px", left: "10px" }}>
            <ul style={{ zIndex: "99" }}>
              {currentRefinement.length > 0 &&
                hits.map((hit, index) => {
                  // <li key={hit.objectID}>{hit.firstName}</li>
                  return (
                    <li
                      key={hit.objectID}
                      id={`destination-hit-${index}`}
                      // className="card result-cards"
                      // className={`${cursor === index ? "card-focussed" : ""}`}
                      ref={e => (originRefResults = e)}
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                        // width: "500px !important",
                      }}
                      onActive={() => {
                        // originRef.value = `${hit.city +
                        //   " | " +
                        //   hit.name +
                        //   " | " +
                        //   hit.iata_code}`
                        console.log("focussed")
                      }}
                      onClick={params => {
                        // console.log({ hit })
                        // console.log(originRef.value)
                        destinationRef.value = `${hit.city +
                          " | " +
                          hit.name +
                          " | " +
                          hit.iata_code}`
                        // dispatch({
                        //   type: "addSearchedActivity",
                        //   activeTab: activeTab,
                        //   selectedItem: hit,
                        // })
                        // setShowHits(false)
                        // dispatch({ type: "syncSchedule", data: true })
                      }}
                    >
                      <div
                        // className="media-content"
                        style={{ fontSize: "14px" }}
                      >
                        {/* <div className="content"> */}
                        <p>
                          {hit.city}
                          {" | "}
                          {hit.name}
                          {" | "}
                          {hit.iata_code}
                          {/* <strong>{hit.activityName}</strong>{" "}
                        <small style={{ fontWeight: "300" }}>
                          {hit.activityType}
                        </small>{" "}
                        <br></br> <small>{hit.activityAddress}</small> */}
                          {/* <br></br>
                        {dottedText(hit.activityDescription, 100)} */}
                        </p>
                        {/* </div> */}
                      </div>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      )
    }
  )

  const CustomAutocompleteOrigin = React.memo(
    connectAutoComplete(AutocompleteOrigin)
  )
  const CustomAutocompleteDestination = connectAutoComplete(
    AutocompleteDestination
  )

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}
    >
      <InstantSearch
        refresh={false}
        indexName={"Airports"}
        searchClient={searchClient}
      >
        {/* <CustomSearchBox /> */}
        {/* <CustomSearchBox /> */}
        <CustomAutocompleteOrigin />
      </InstantSearch>
      <InstantSearch
        // refresh={forceRefresh}
        indexName={"Airports"}
        searchClient={searchClient}
      >
        <CustomAutocompleteDestination />
      </InstantSearch>
      <div className="control is-expanded" style={{ minWidth: "200px" }}>
        <label className="label">Type</label>
        <Select
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
          // isMulti
          onFocus={() => {
            //  clearError("accommodation")
            // setTripTypeError(false)
          }}
          // defaultValue={state.tripLogistics.tripType}
          // className="basic-select"
          classNamePrefix="select"
          onChange={(value, actionMeta) => {
            console.log({ value })
            setJourneyTypeSelection(value)
            // dispatch({
            //   type: "updateTripType",
            //   data: value ? value : "",
            // })
          }}
          value={journeyTypeSelection}
          isSearchable="false"
          isClearable="false"
          options={journeyType}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#fef0bf",
              primary50: "#fee27f",
              primary: "#fed43f",

              //neutral0: "#424242",
            },
          })}
        />
      </div>
      {/* <div className="level-left">
          <div className="level-item">
            <input
              className="input is-warning"
              type="text"
              placeholder="Origin"
            ></input>
          </div>
          <div className="level-item">
            <button className="button is-light">
              <i className="far fa-exchange"></i>
            </button>
          </div>
          <div className="level-item">
            <input
              className="input is-warning"
              type="text"
              placeholder="Destination"
            ></input>
          </div>

          <div className="level-item">
            <DatePicker
              // minDate={moment().subtract(18, "years")}
              // maxDate={moment().subtract(18, "years")._d}
              // style={datePickerStyle}
              // value={getDateSafely(state.tempPrivateProfile.dob)}
              onChange={d => {
                console.log(d)
                // dispatch({
                //   type: "updateTempPrivateProfileBirthday",
                //   data: d,
                // })
              }}
            ></DatePicker>
          </div>
          <div className="level-item">
            <div className="control is-expanded" style={{ minWidth: "200px" }}>
              <Select
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                // isMulti
                onFocus={() => {
                  //  clearError("accommodation")
                  // setTripTypeError(false)
                }}
                // defaultValue={state.tripLogistics.tripType}
                // className="basic-select"
                classNamePrefix="select"
                onChange={(value, actionMeta) => {
                  console.log({ value })
                  // dispatch({
                  //   type: "updateTripType",
                  //   data: value ? value : "",
                  // })
                }}
                isSearchable="false"
                isClearable="false"
                options={journeyType}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#fef0bf",
                    primary50: "#fee27f",
                    primary: "#fed43f",

                    //neutral0: "#424242",
                  },
                })}
              />
            </div>
          </div>
          <div className="level-item">
            <div className="control is-expanded" style={{ minWidth: "200px" }}>
              <Select
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                // isMulti
                onFocus={() => {
                  //  clearError("accommodation")
                  // setTripTypeError(false)
                }}
                // defaultValue={state.tripLogistics.tripType}
                // className="basic-select"
                classNamePrefix="select"
                onChange={(value, actionMeta) => {
                  console.log({ value })
                  // dispatch({
                  //   type: "updateTripType",
                  //   data: value ? value : "",
                  // })
                }}
                isSearchable="false"
                isClearable="false"
                options={cabinClass}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#fef0bf",
                    primary50: "#fee27f",
                    primary: "#fed43f",

                    //neutral0: "#424242",
                  },
                })}
              />
            </div>
          </div>
        </div> */}
    </div>
  )
}

export default FlightRecommendations
