import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import AuthUserContext from "../../components/session/context"
import swal from "sweetalert"
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal"
function NotificationCard({ data, onStarred, onDelete }) {
  const [toggleStar, setToggleStar] = useState(false)
  const [proceedWithDelete, setProceedWithDelete] = useState(false)
  const authContext = useContext(AuthUserContext)
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }

  useEffect(() => {
    if (proceedWithDelete) {
      onDelete(data.messageId)
      setProceedWithDelete(false)
    }
  }, [proceedWithDelete])

  useEffect(() => {
    setToggleStar(data.starred)
  }, [data])

  const canRender = () => {
    // console.log({ data })
    // console.log({ authContext })
    if (data !== undefined && authContext !== undefined) {
      return true
    } else {
      return false
    }
  }
  // var travelerFirstName = "Vijay"
  // var designerFirstName = "Jake"
  // var tripUid = "a22dd2c9-a681-43b7-a2e9-66bc1047392f"
  // var messageStr = `Hi ${travelerFirstName}, unfortunately your travel designer ${designerFirstName} would not be able to work on your travel plan, sorry!. Please select a different travel designer from your trip details. smart-data:<Link to="/app/tripdetails/${tripUid}" style={{ display: "inline-" }}>click here </Link>`

  // var getMessage = str => {

  //   if(data.helperURL !== undefined)
  //   {
  //     return (<div>{data.message} </div> )
  //   }

  return (
    <>
      {canRender() ? (
        <div
          className="columns is-vcentered"
          style={{
            backgroundColor: "",
            borderRadius: "0px",
            padding: "5px",
            borderBottom: "1px solid #ffc600",
          }}
        >
          <div className="column is-2-desktop is-3-tablet">
            <p style={{ fontSize: "12px", letterSpacing: "0.5px" }}>
              {new Intl.DateTimeFormat("en-US", options).format(
                data.sentAt.toDate()
              )}
            </p>
          </div>
          <div className="column is-9-desktop is-8-tablet ">{data.message}</div>
          <div className="column is-2-desktop is-1-tablet">
            {/* <a
              className="no-decoration"
              onClick={e => {
                if (toggleStar) onStarred(data.messageId, false)
                else onStarred(data.messageId, true)
                setToggleStar(!toggleStar)
              }}
            >
              <i
                className={`${
                  toggleStar ? "fas fa-star red-star" : "far fa-star"
                }`}
                style={{
                  marginRight: "20px",
                  marginBottom: "10px",
                  fontSize: "16px",
                }}
              ></i>
            </a> */}
            <a
              className="no-decoration"
              onClick={e => {
                ConfirmationModal({
                  onConfirm: () => {
                    setProceedWithDelete(true)
                  },
                  title: "Confirm",
                  text: "Are you sure?",
                })
                // swal({
                //   title: "Are you sure?",
                //   icon: "warning",
                //   //buttons: true,
                //   dangerMode: false,
                //   buttons: ["No, cancel", "Yes, delete notification"],
                // }).then(willDelete => {
                //   if (willDelete)
                // })
              }}
            >
              <i
                className="far fa-trash-alt"
                style={{
                  marginRight: "20px",
                  marginTop: "10px",
                  fontSize: "16px",
                }}
              ></i>{" "}
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default NotificationCard
