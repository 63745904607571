import { relativeTimeRounding } from "moment"
import React, { useEffect, useState } from "react"
import { useAsync } from "react-async"
import { SingleDatePickerShape } from "react-dates"

// const getDistanceMatrix = (service, data) =>
//   new Promise((resolve, reject) => {
//     service.getDistanceMatrix(data, (response, status) => {
//       if (status === "OK") {
//         resolve(response)
//       } else {
//         reject(response)
//       }
//     })
//   })
// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

// const fetchDistance = async ({ addressObj }, { signal }) => {
//   console.log("asdfasdfasdfasfdsafasdfasdfasdf")
//   console.log(signal)
//   console.log(window.google)
//   await sleep(500)
//   let response
//   if (window.google) {
//     response = await getDistanceMatrix(
//       new window.google.maps.DistanceMatrixService(),
//       {
//         origins: [addressObj.src],
//         destinations: [addressObj.dst],
//         travelMode: window.google.maps.TravelMode.DRIVING,
//         unitSystem: window.google.maps.UnitSystem.IMPERIAL,
//         avoidHighways: false,
//         avoidTolls: false,
//       }
//     )
//   }

//   console.log({ response })
//   return response
// }

export const DisplayCommute = React.memo(
  ({ sourceAddress, destinationAddress }) => {
    const [currentSourceAddress, setCurrentSourceAddress] = useState("")
    const [currentDestinationAddress, setCurrentDestinationAddress] = useState(
      ""
    )
    const [distanceInMiles, setDistanceInMiles] = useState("")
    const [durationInText, setDurationInText] = useState("")
    useEffect(() => {
      // console.log({ sourceAddress, destinationAddress })
      if (sourceAddress !== currentSourceAddress) {
        setCurrentSourceAddress(sourceAddress)
      }
      if (destinationAddress !== currentDestinationAddress) {
        setCurrentDestinationAddress(destinationAddress)
      }
    }, [sourceAddress, destinationAddress])

    const getDistanceMatrix = (service, data) =>
      new Promise((resolve, reject) => {
        service.getDistanceMatrix(data, (response, status) => {
          if (status === "OK") {
            resolve(response)
          } else {
            reject(response)
          }
        })
      })

    useEffect(() => {
      console.log({ currentSourceAddress, currentDestinationAddress })
      const runDistance = async () => {
        if (window.google) {
          let response = await getDistanceMatrix(
            new window.google.maps.DistanceMatrixService(),
            {
              origins: [currentSourceAddress],
              destinations: [currentDestinationAddress],
              travelMode: window.google.maps.TravelMode.DRIVING,
              unitSystem: window.google.maps.UnitSystem.IMPERIAL,
              // avoidHighways: false,
              // avoidTolls: false,
            }
          )
          console.log({ response })
          if (response.rows[0].elements[0].status !== "ZERO_RESULTS") {
            let duration = response.rows[0].elements[0].duration.value
            let distance = response.rows[0].elements[0].distance.value
            let dText = ""
            if (duration < 60) dText = "less than a min"
            let hours = 0
            let minutes = 0
            if (duration > 60) {
              hours = Math.floor(duration / 3600)
              minutes = Math.round((duration % 3600) / 60)
              if (hours > 0) {
                if (hours === 1) dText = `${hours} hr & ${minutes} min`
                else dText = `${hours} hrs & ${minutes} min`
              } else {
                dText = `${minutes} min`
              }
            }
            setDurationInText(dText)
            let dMiles = distance === 0 ? 0 : (distance * 0.000621).toFixed(1)
            setDistanceInMiles(dMiles)
          }
          return response
        }
      }
      if (currentSourceAddress !== "" && currentDestinationAddress !== "") {
        setTimeout(() => {
          runDistance()
        }, 500)
      }
    }, [currentSourceAddress, currentDestinationAddress])

    return (
      <span style={{ fontSize: "12px" }}>
        <i class="far fa-car"></i> {`${distanceInMiles}`}{" "}
        <span style={{ marginRight: "5px" }}>mi{"  "}</span>
        <i class="far fa-clock"></i> {`${durationInText}`}
      </span>
    )

    //   console.log({ sourceAddress, destinationAddress })
    //   const { data, error, isPending, setData, counter } = useAsync({
    //     promiseFn: fetchDistance,
    //     addressObj: { src: sourceAddress, dst: destinationAddress },
    //   })

    //   console.log({ counter })
    //   if (isPending) return "..."
    //   if (error) {
    //     console.log({ error })
    //     return "."
    //   }
    //   if (data) {
    //     //   cancel()
    //     console.log({ data })
    //     if (data.rows[0].elements[0].status !== "ZERO_RESULTS") {
    //       if (
    //         data.destinationAddresses[0] !== destinationAddress &&
    //         data.originAddresses[0] != sourceAddress
    //       ) {
    //         //   setData({ src: sourceAddress, dst: destinationAddress })
    //         return
    //       }

    //       let duration = data.rows[0].elements[0].duration.value
    //       let distance = data.rows[0].elements[0].distance.value
    //       let durationInText = ""
    //       if (duration < 60) durationInText = "less than a min"
    //       let hours = 0
    //       let minutes = 0
    //       if (duration > 60) {
    //         hours = Math.floor(duration / 3600)
    //         minutes = Math.round((duration % 3600) / 60)
    //         if (hours > 0) {
    //           if (hours === 1) durationInText = `${hours} hr & ${minutes} min`
    //           else durationInText = `${hours} hrs & ${minutes} min`
    //         } else {
    //           durationInText = `${minutes} min`
    //         }
    //       }
    //       let distanceInMiles =
    //         distance === 0 ? 0 : (distance * 0.000621).toFixed(1)
    //       console.log(`${distanceInMiles} mi ${durationInText}`)
    //       return (
    //         <span>
    //           {" "}
    //           <i class="far fa-car"></i> {`${distanceInMiles}`}{" "}
    //           <span style={{ marginRight: "5px" }}>mi{"  "}</span>
    //           <i class="far fa-clock"></i> {`${durationInText}`}
    //         </span>
    //       )
    //     }
    //   } else return ""

    return ""
  }
)

export default DisplayCommute
