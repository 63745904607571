import React, { useState, useEffect } from "react"
import "./socialShareForm.scss"
import { analytics, functions } from "../firebase/firebase"
import useRestrictCharacterLength from "../../hooks/useRestrictCharacterLength"
import thankq from "./../../images/thankq.png"
import errorImg from "./../../images/error.png"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share"
import SEO from "../seo"
function SocialShareForm({ recommendationId }) {
  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(1000)
  const [recommendations, setRecommendations] = useState("")
  const [error, setError] = useState(false)
  const [textLength, setTextLength] = useState(0)
  const reactQuillRef = React.useRef()
  const [submissionState, setSubmissionState] = useState("none")
  const [busy, setBusy] = useState(false)
  const [URL, setURL] = useState("https://voyayge.com")
  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      // ["clean"],
    ],
  }

  useEffect(() => {
    console.log({ textLength })
  }, [textLength])

  useEffect(() => {
    document.title = "Share your 💛  for voyay!ge"
  }, [])

  console.log({ recommendationId })
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    //  "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
  ]

  const saveRecommendations = () => {
    try {
      let data = {
        recommendation: recommendations,
        id: recommendationId,
      }
      var saveRecommendations = functions.httpsCallable("saveRecommendations")
      saveRecommendations(data).then(function(result) {
        // Read result of the Cloud Function.
        setBusy(false)
        console.log({ result })
        if (result.data.success) {
          setSubmissionState("success")
        } else {
          setSubmissionState("error")
        }
        // ...
      })
    } catch (err) {
      console.log(err)
      setBusy(false)
      setSubmissionState("error")
    }
  }
  // const checkCharacterCount = event => {
  //   const editor = reactQuillRef.current.getEditor()
  //   const unprivilegedEditor = reactQuillRef.current.makeUnprivilegedEditor(
  //     editor
  //   )
  //   // const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor
  //   // console.log({ unprivilegedEditor })

  //   let len = unprivilegedEditor.getText().length
  //   setTextLength(len)
  //   if (len > 1000 && event.key !== "Backspace") event.preventDefault()
  //   //setError(true)
  // }

  const showRightScreen = () => {
    switch (submissionState) {
      case "none":
        return (
          <>
            <div style={{ maxWidth: "320px", margin: "0 auto" }}>
              <img style={{ width: "100%" }} src={thankq} alt="thank you" />
            </div>
            <p
              style={{
                fontSize: "20px",
                margin: "30px auto",
                textAlign: "center",
                fontWeight: "500",
                marginTop: "40px",
              }}
            >
              {/* Each share will a make difference for us! */}
              We really appreciate you spreading the{" "}
              <strong
                style={{
                  fontSize: "21px",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                <span style={{ color: "#fec600" }}>yay!</span>
              </strong>{" "}
              {/* <strong
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                vo<span style={{ color: "#fec600" }}>yay!</span>ge
              </strong>{" "} */}
            </p>
            <div className="social-share-container">
              <div className="social-share-icon">
                <EmailShareButton
                  url={URL}
                  subject="Check out Voyay!ge"
                  onClick={() => {
                    analytics.logEvent("social_share_email")
                  }}
                  body="I want to share the 💛 of travel with voyay!ge. It is a platform for custom-crafted travel experiences. Take the stress away from your vacation planning by getting a complete day-to-day travel plan that includes local attractions, dining suggestions, accommodation recommendations, local travel tips, custom-built based on your preferences."
                  separator=" Check it out → "
                >
                  <EmailIcon
                    borderRadius={4}
                    size={48}
                    iconFillColor="#4a4a4a"
                    bgStyle={{ fill: "#fec600" }}
                  />
                </EmailShareButton>
              </div>
              <div className="social-share-icon">
                <FacebookShareButton
                  quote="I want to share the love of travel with voyay!ge. Get your custom vacation plan crafted at voyay!ge. Check it out!"
                  hashtag="#govoyayge"
                  onClick={() => {
                    analytics.logEvent("social_share_facebook")
                  }}
                  url={URL}
                >
                  <FacebookIcon
                    borderRadius={4}
                    size={48}
                    iconFillColor="#4a4a4a"
                    bgStyle={{ fill: "#fec600" }}
                  />
                </FacebookShareButton>
              </div>
              <div className="social-share-icon">
                <TwitterShareButton
                  url={URL}
                  onClick={() => {
                    analytics.logEvent("social_share_twitter")
                  }}
                  // title="I 💛 voyay!ge. Get the yay! back to your vacation. Check it out!"
                  title="I want to share the 💛 of travel with @voyayge. Get your custom vacation plan crafted at voyay!ge. Check it out!"
                  via="voyayge"
                  hashtags={["govoyayge", "voyayge", "checkitout"]}
                >
                  <TwitterIcon
                    borderRadius={4}
                    size={48}
                    iconFillColor="#4a4a4a"
                    bgStyle={{ fill: "#fec600" }}
                  />
                </TwitterShareButton>
              </div>
              <div className="social-share-icon">
                <LinkedinShareButton
                  url={URL}
                  onClick={() => {
                    analytics.logEvent("social_share_linkedin")
                  }}
                  source="https://voyayge.com"
                  title="voyay!ge"
                  summary="Get a complete day-to-day travel plan that includes local attractions, dining suggestions, accommodation recommendations, local travel tips, custom-built based on your preferences."
                  description="Get a complete day-to-day travel plan that includes local attractions, dining suggestions, accommodation recommendations, local travel tips, custom-built based on your preferences."
                >
                  <LinkedinIcon
                    borderRadius={4}
                    size={48}
                    iconFillColor="#4a4a4a"
                    bgStyle={{ fill: "#fec600" }}
                  />
                </LinkedinShareButton>
              </div>
              <div className="social-share-icon">
                <WhatsappShareButton
                  onClick={() => {
                    analytics.logEvent("social_share_whatsapp")
                  }}
                  url={URL}
                  title="I want to share the 💛 of travel with voyay!ge. Get your custom vacation plan crafted at voyay!ge. Check it out!"
                  separator=" → "
                >
                  <WhatsappIcon
                    borderRadius={4}
                    size={48}
                    iconFillColor="#4a4a4a"
                    bgStyle={{ fill: "#fec600" }}
                  />
                </WhatsappShareButton>
              </div>
            </div>
          </>
        )
        break
      case "error":
        return (
          <>
            <div style={{ margin: "20px auto", maxWidth: "300px" }}>
              <img
                src={errorImg}
                alt="error!"
                style={{
                  padding: "10px",
                  // maxHeight: "300px",
                  width: "100%",
                }}
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                margin: "15px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Uh oh!, there was an error submitting your recommendations. Please
              try again in sometime. Sorry! <br></br>{" "}
              <small>
                If the issue persists please email to{" "}
                <a href="mailto: support@voyayge.com">support@voyayge.com</a>
              </small>
            </p>
          </>
        )
        break
      case "success":
        return (
          <>
            <img src={thankq} alt="thank you" />
            <p
              style={{
                fontSize: "22px",
                margin: "0px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              We received your recommendations!
            </p>
          </>
        )
        break

      default:
        break
    }
  }

  return (
    <>
      <SEO pageTitle="Share your love for travel" title="voyayge.com" />
      <div style={{ padding: "25px" }}>
        <div className="columns">
          <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
          <div className="column is-6-desktop is-10-tablet is-12-mobile">
            <div
              style={{
                marginBottom: "25px",
                maxWidth: "500px",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "45px",
              }}
            >
              {/* <img
              src={logo}
              style={{
                width: "100%",
              }}
              onClick={() => {
                navigate("/")
              }}
            ></img> */}
              {showRightScreen()}
            </div>
          </div>
          <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
        </div>
      </div>
    </>
  )
}

export default SocialShareForm
