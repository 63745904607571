import React, { useEffect, useContext, useState } from "react"
import queryString from "query-string"
import * as ROUTES from "./../../constants/routes"
import { navigate } from "gatsby"
import { fetchFromAPI } from "../../utility/general"
import AuthUserContext from "../../components/session/context"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import { FirebaseContext } from "../../components/firebase"
import { auth } from "../../components/firebase/firebase"
import success from "./../../images/success.png"
import error from "./../../images/error.png"

export default function DesignerOnboardingCallback({ location }) {
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [responseState, setResponseState] = useState("")
  const [loading, setLoading] = useState(true)
  const [counter, setCounter] = React.useState(10)

  useEffect(() => {
    if (counter > 0) {
      var timer = setTimeout(function() {
        setCounter(counter - 1)
      }, 1000)
    }
    // Navigate to dashboard
    if (counter === 0) {
      navigate(ROUTES.DASHBOARD)
    }
    return () => clearTimeout(timer)
  }, [counter])

  useEffect(() => {
    console.log({ location })
  }, [location])

  useEffect(() => {
    try {
      if (authContext) {
        async function completeValidation() {
          const parsed = queryString.parse(location.search)
          const user = auth.currentUser
          console.log({ user })
          const token = user && (await user.getIdToken())
          console.log({ token })
          const response = await fetchFromAPI(
            "POST",
            "api/completeUserOnboarding",
            {
              body: {
                authorizationCode: parsed.code,
              },
              token,
            },
            token
          )
          console.log({ parsed })
          console.log({ response })
          if (response.success === true) {
            setResponseState("success")
          } else {
            setResponseState("error")
          }
          setLoading(false)
          startTimer()
        }
        completeValidation()
      }
    } catch (err) {
      setResponseState("error")
      console.log(err)
    }
  }, [authContext])

  var timer
  const startTimer = () => {
    timer = setTimeout(function() {
      console.log(counter)
      setCounter(counter - 1)
    }, 1000)
  }

  const showRightMessage = () => {
    switch (responseState) {
      case "success":
        return (
          <div className="">
            <div style={{ maxWidth: "300px", margin: "0 auto" }}>
              <img
                src={success}
                alt="congratulations"
                style={{ width: "100%" }}
              />
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "26px",
              }}
            >
              Your payment account setup has completed successfully.
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                marginTop: "26px",
                fontWeight: "300",
              }}
            >
              You will now be automatically redirected to your dashboard in
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "50px",
                fontWeight: "700",
                marginTop: "26px",
              }}
            >
              {counter}s
            </p>
          </div>
        )
        break
      case "none":
        return <p></p>
        break
      case "error":
        return (
          <div className="">
            <div style={{ maxWidth: "300px", margin: "0 auto" }}>
              <img src={error} alt="error" style={{ width: "100%" }} />
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: "26px",
                fontWeight: "700",
              }}
            >
              Setup Error
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "300",
              }}
            >
              There was an issue setting up your payment account. Please try
              again later. If the issue persists please send an email to
              support: <strong>support@voyayge.com</strong>
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "50px",
                fontWeight: "700",
                marginTop: "26px",
              }}
            >
              {counter}
            </p>
          </div>
        )
        break

      default:
        break
    }
  }

  return (
    <div>
      <div className="columns is-multiline is-vcentered">
        <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
        <div className="column is-6-desktop is-8-tablet is-12-mobile">
          {loading ? (
            <ShowLoading />
          ) : (
            <>
              <div className="columns is-multiline is-vcentered">
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {showRightMessage()}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}
