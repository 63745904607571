import React, { useEffect } from "react"
import { designerSkills } from "../../constants/data/general"
import "./skill-badge.scss"
const SkillBadge = ({ item }) => {
  useEffect(() => {
    // console.log(item)
  }, [item])
  const getBadgeDetails = () => {
    let found = designerSkills.find(d => d.value === item.value)
    if (found != -1) {
      return (
        <>
          <i class={`${found.icon} fa-3x`}></i>
          <p>{found.label}</p>
        </>
      )
    } else return ""
  }

  return <div className="skill-badge">{getBadgeDetails()}</div>
}

export default SkillBadge
