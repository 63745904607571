import { dbUpdate } from "../../utility/dbCRUD"
import React, { useState, useEffect, useContext } from "react"
import "./EditableItems.scss"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"
import DatePicker from "react-date-picker"

const EditableDateField = dateObject => {
  const datePickerStyle = {
    fontSize: "55px",
    color: "red",
  }
  const [inEditMode, setEditMode] = useState({
    fullName: false,
    address: false,
  })
  const [userDOB, setUserDOB] = useState({ year: "", month: "", day: "" })
  const [userDOBTemp, setUserDOBTemp] = useState({
    year: "",
    month: "",
    day: "",
  })
  const [formattedDOB, setFormattedDOB] = useState("")

  const authUser = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)

  useEffect(() => {
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const _date = userDOB
    var year = "",
      month = "",
      day = ""
    if (_date instanceof Date) {
      console.log("Date Object")
      day = _date.getDate()
      year = _date.getFullYear()
      month = _date.getMonth() + 1
    }
    if (year + month + day === "") setFormattedDOB("(not set)")
    else setFormattedDOB(month + "/" + day + "/" + year)
  }, [userDOB])

  useEffect(() => {
    console.log({ dateObject })
    try {
      const date = dateObject.dateObject.toDate()
      console.log({ date })
      setUserDOB(date)
      setUserDOBTemp(date)
    } catch {
      setFormattedDOB("(not set)")
    }
  }, [dateObject])

  const handleSave = e => {
    switch (e.target.name) {
      case "fullNameSave":
      case "dob":
        setUserDOB(userDOBTemp)
        setUserDOBTemp(userDOBTemp)

        const blob = { dob: userDOBTemp }
        console.log({ blob })
        dbUpdate(dbContext, "users", authUser.uid, blob)
        setEditMode({ dob: false })
        break
      default:
        break
    }
  }
  return (
    <>
      {!inEditMode.dob ? (
        <>
          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">Date of birth</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">{formattedDOB}</p>
              </div>
              <div className="control">
                <a
                  // className="is-relative is-pulled-right"
                  onClick={() => setEditMode({ dob: true })}
                >
                  Edit
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="field">
            <div className="control is-vertical-center">
              <label className="label">Date of birth</label>
              <DatePicker
                style={datePickerStyle}
                value={
                  userDOBTemp.year === "" ? new Date() : new Date(userDOBTemp)
                }
                onChange={d => {
                  console.log(d)
                  if (d) {
                    setUserDOBTemp(d)
                  }
                }}
              ></DatePicker>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field is-grouped">
                <div className="control">
                  <button
                    className="button is-warning"
                    name="dob"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
                <div className="control">
                  <button
                    className="button is-text"
                    onClick={() => setEditMode({ dob: false })}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default EditableDateField
