import React from "react"
import { navigate } from "gatsby"
import Layout from "./Layout"
import SEO from "./seo"

const PrivateRoute = ({ component: Component, location, data, ...rest }) => {
  const authUser = localStorage.getItem("authUser")
  // const canonicalUrl = data.site.siteMetadata.siteURL + location.pathname
  console.log(location.pathname)
  if (!authUser && location.pathname !== `/app/login`) {
    // If we’re not logged in, redirect to the home page.
    localStorage.setItem(
      "redirectAfterSignIn",
      JSON.stringify({ value: true, url: `${location.pathname}` })
    )
    navigate(`/signup`)
    return null
  } else {
    localStorage.setItem(
      "redirectAfterSignIn",
      JSON.stringify({ value: false, url: `` })
    )
  }

  return (
    <>
      <div style={{ marginTop: "10px", marginBottom: "100px" }}>
        <Component location={location} {...rest} />
      </div>
    </>
  )
}

export default PrivateRoute
