import { navigate } from "gatsby"
import { countBy, sortBy } from "lodash"
import React, { useContext, useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { FirebaseContext } from "../../../components/firebase"
import AuthUserContext from "../../../components/session/context"
import ShowLoading from "../../../components/ShowLoading/ShowLoading"
import TripCardHorizontalClean from "../../../components/TripCards/TripCardHorizontalClean"
import * as ROUTES from "../../../constants/routes"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import { useGlobalStateStore } from "../../../State/global-state/global-state"
import "./../Dashboard.scss"
import DetailsForDesigner from "./DetailsForDesigner"
export default function DesignerDashboard() {
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [state, dispatch] = useDashboardStateStore()
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [toggleTripDetails, setToggleTripDetails] = useState(false)
  const [loading, setLoading] = useState(true)
  const { addToast } = useToasts()

  useEffect(() => {
    // console.log({ state })
  }, [state])

  useEffect(() => {
    try {
      if (dbContext === null) {
        setLoading(true)
      }
      //fetch user trip data.
      var unsubscribe = ""
      if (dbContext) {
        unsubscribe = dbContext
          .firestore()
          .collection("jobs")
          .doc(`${authContext.uid}`)
          .collection("active-jobs")
          .where("status", "in", [
            "pending",
            "active",
            "review",
            "review-complete",
            "complete",
            "request-changes",
            // "payment-pending",
          ])
          // .where("byot", "!=", true)
          .onSnapshot(querySnapshot => {
            querySnapshot.docChanges().forEach(change => {
              if (change.doc.data()) {
                let cloudData = change.doc.data()
                if (
                  cloudData.tripUid !== undefined &&
                  cloudData.travelerUid !== undefined &&
                  cloudData.jobUid !== undefined
                ) {
                  if (cloudData.byot) return
                  switch (change.type) {
                    case "added":
                      // console.log("New Item: ", cloudData)
                      dispatch({
                        type: "addNewJob",
                        data: cloudData,
                      })

                      break
                    case "modified":
                      console.log("Modified Item: ", cloudData)
                      dispatch({
                        type: "updateJob",
                        data: cloudData,
                      })

                      break
                    case "removed":
                      console.log("Removed Item: ", cloudData)
                      dispatch({
                        type: "deleteJob",
                        data: cloudData,
                      })
                      break
                    default:
                      break
                  }
                }
              }
            })
            setLoading(false)
          })
      }
    } catch (error) {
      console.log(error)
    }
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [dbContext])

  useEffect(() => {
    console.log("%cDashboard-State", "color: #972426; font-size: 18px", {
      state,
    })
  }, [])

  useEffect(() => {
    console.log("%cGlobal-State", "color: #972426; font-size: 18px", {
      globalState,
    })
  }, [globalState])

  const jobAccepted = tripUid => {
    console.log(`${tripUid}`)
    // console.log(`${e.name}`)
    if (tripUid) {
      // find the jobId with a matching tripUid
      const found = state.jobs.find(element => element.tripUid === tripUid)
      if (found) {
        if (dbContext) {
          var tripRef = dbContext
            .firestore()
            .collection("trips")
            .doc(`${found.travelerUid}`)
            .collection("active-trips")
            .doc(`${tripUid}`)

          var activeJobRef = dbContext
            .firestore()
            .collection("jobs")
            .doc(`${authContext.uid}`)
            .collection("active-jobs")
            .doc(`${found.jobUid}`)

          const batch = dbContext.firestore().batch()

          batch.set(
            activeJobRef,
            { status: "payment-pending", acceptedOn: new Date() },
            { merge: true }
          )
          batch.set(
            tripRef,
            {
              designerDetails: {
                status: "payment-pending",
                designerUid: authContext.uid,
                jobUid: found.jobUid,
                acceptedOn: new Date(),
                roomId: found.roomId,
              },
            },
            { merge: true }
          )

          batch
            .commit()
            .then(() => {
              addToast(
                'Great! Once the job is finalized you will see the job in your "Active" section under "Your Jobs"',
                {
                  appearance: "info",
                  autoDismiss: false,
                }
              )
              console.log(
                "Trip details update updated to active state and designer info!"
              )
            })
            .catch(error => {
              console.log("Error getting document:", error)
              addToast(
                "Error accepting the job, please try again in sometime",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              )
            })

          // dbContext
          //   .firestore()
          //   .collection("jobs")
          //   .doc(`${authContext.uid}`)
          //   .collection("active-jobs")
          //   .doc(`${found.jobUid}`)
          //   .update({ status: "payment-pending", acceptedOn: new Date() })
          //   .then(function() {
          //     console.log("Job status successfully updated to active state!")
          //     if (found) {
          //       dbContext
          //         .firestore()
          //         .collection("trips")
          //         .doc(`${found.travelerUid}`)
          //         .collection("active-trips")
          //         .doc(`${tripUid}`)
          //         .update({
          //           designerDetails: {
          //             status: "payment-pending",
          //             designerUid: authContext.uid,
          //             jobUid: found.jobUid,
          //             acceptedOn: new Date(),
          //             roomId: found.roomId,
          //           },
          //         })
          //         .then(function() {
          //           addToast(
          //             'Great! Once the job is finalized you will see the job in your "Active" section under "Your Jobs"',
          //             {
          //               appearance: "info",
          //               autoDismiss: false,
          //             }
          //           )
          //           console.log(
          //             "Trip details update updated to active state and designer info!"
          //           )
          //         })
          //     }
          //   })
          //   .catch(error => {
          //     console.log("Error getting document:", error)
          //     addToast(
          //       "Error accepting the job, please try again in sometime",
          //       {
          //         appearance: "error",
          //         autoDismiss: true,
          //       }
          //     )
          //   })
        }
      }
    }
  }
  const jobDeclined = tripUid => {
    console.log(`${tripUid}`)
    if (tripUid) {
      // find the jobId with a matching tripUid
      const found = state.jobs.find(element => element.tripUid === tripUid)
      if (found) {
        if (dbContext) {
          var tripRef = dbContext
            .firestore()
            .collection("trips")
            .doc(`${found.travelerUid}`)
            .collection("active-trips")
            .doc(`${tripUid}`)

          var activeJobRef = dbContext
            .firestore()
            .collection("jobs")
            .doc(`${authContext.uid}`)
            .collection("active-jobs")
            .doc(`${found.jobUid}`)

          const batch = dbContext.firestore().batch()

          batch.set(
            tripRef,
            {
              designerDetails: {
                status: "declined",
                designerUid: authContext.uid,
              },
            },
            { merge: true }
          )

          batch.delete(activeJobRef)

          batch
            .commit()
            .then(() => {
              console.log(
                "Travelers job status successfully updated to declined state and job successfully removed from the designers queue"
              )
            })
            .catch(error => {
              console.log("Error getting document:", error)
              addToast(
                "Error declining the job, please try again in sometime",
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              )
            })
          // dbContext
          //   .firestore()
          //   .collection("trips")
          //   .doc(`${found.travelerUid}`)
          //   .collection("active-trips")
          //   .doc(`${tripUid}`)
          //   .update({
          //     designerDetails: {
          //       status: "declined",
          //       designerUid: authContext.uid,
          //     },
          //   })
          // .then(function() {
          //   console.log("Job status successfully updated to declined state!")
          //   //Now delete the job from designers queue!
          //   dbContext
          //     .firestore()
          //     .collection("jobs")
          //     .doc(`${authContext.uid}`)
          //     .collection("active-jobs")
          //     .doc(`${found.jobUid}`)
          //     .delete()
          //     .then(function() {
          //       console.log(
          //         "Job successfully removed from the designers queue"
          //       )
          //     })
          // })
        }
      }
    }
  }

  const showTripDetails = (e, name, jobUid) => {
    console.log(`${e.currentTarget.id}`)
    console.log(`${name}`)
    console.log(`${jobUid}`)
    //
    navigate(
      `${ROUTES.JOB_DETAILS}/${e.currentTarget.id}/${name}/${jobUid}`,
      {}
    )
    //Fetch the trip state from cloud
    // if (e.currentTarget.id)
    //   if (dbContext) {
    //     dbContext
    //       .firestore()
    //       .collection("trips")
    //       .doc(`${name}`)
    //       .collection("active-trips")
    //       .doc(`${e.currentTarget.id}`)
    //       .get()
    //       .then(doc => {
    //         if (doc.data()) {
    //           dispatch({
    //             type: "updateJobDetails",
    //             data: doc.data(),
    //           })
    //           setToggleTripDetails(true)
    //         }
    //       })
    //       .catch(e => console.log(e))
    //   }
  }
  const close = () => {
    setToggleTripDetails(false)
  }

  const getDates = data => {
    try {
      return `${data.travelDates.start
        .toDate()
        .toLocaleDateString()} - ${data.travelDates.end
        .toDate()
        .toLocaleDateString()}`
    } catch (e) {
      // console.log({ e })
      return `${new Date(
        data.travelDates.start
      ).toLocaleDateString()} - ${new Date(
        data.travelDates.end
      ).toLocaleDateString()}`
    }
  }
  const updateCards = (cardsArray, type) => {
    if (cardsArray) {
      if (cardsArray.length === 0) {
        return <></>
        // if (type === "pending" || type === "active" || type === "complete") {
        //   return (
        //     <div className="columns is-vcentered">
        //       <div className="column is-4-desktop"></div>
        //       <div className="column is-4-desktop">
        //         <div style={{ maxWidth: "100px", margin: "0 auto" }}>
        //           <img style={{ width: "100%" }} src={nojobs} alt="" />
        //           <p style={{ textAlign: "center", fontSize: "16px" }}>
        //             {`- No ${type} jobs -`}
        //           </p>
        //         </div>
        //       </div>
        //       <div className="column is-4-desktop"></div>
        //     </div>
        //   )
        // }
      }
      {
        return cardsArray.map(m => {
          return m.status === type ? (
            <React.Fragment key={m.tripUid}>
              {/* <TripCardNew
              key={m.tripUid}
              tripId={m.tripUid}
              tripName={m.tripPrimaryDestination}
              tripDates={getDates(m)}
              invokeTripDetails={showTripDetails}
            /> */}
              <TripCardHorizontalClean
                key={m.tripUid}
                tripId={m.tripUid}
                travelerId={m.travelerUid}
                tripName={m.tripPrimaryDestination}
                jobUid={m.jobUid}
                tripDates={getDates(m)}
                invokeTripDetails={showTripDetails}
                invokeAcceptJob={jobAccepted}
                invokeDeclineJob={jobDeclined}
                showAcceptDecline={m.status === "pending" ? true : false}
                sample={m.type === "sample" ? true : false}
                activeOn={m.paymentCompletedOn}
                status={type}
              />
            </React.Fragment>
          ) : (
            ""
          )
        })
      }
    }
  }

  const showCards = m => {
    return (
      <React.Fragment key={m.tripUid}>
        <TripCardHorizontalClean
          key={m.tripUid}
          tripId={m.tripUid}
          travelerId={m.travelerUid}
          tripName={m.tripPrimaryDestination}
          jobUid={m.jobUid}
          tripDates={getDates(m)}
          invokeTripDetails={showTripDetails}
          invokeAcceptJob={jobAccepted}
          invokeDeclineJob={jobDeclined}
          showAcceptDecline={m.status === "pending" ? true : false}
          sample={m.type === "sample" ? true : false}
          activeOn={m.paymentCompletedOn}
          changesRequestedOn={m.changesRequestedOn}
          status={m.status}
        />
      </React.Fragment>
    )
  }

  const showActiveCards = cardsArray => {
    if (cardsArray) {
      if (cardsArray.length === 0) {
        return <></>
        // if (type === "pending" || type === "active" || type === "complete") {
        //   return (
        //     <div className="columns is-vcentered">
        //       <div className="column is-4-desktop"></div>
        //       <div className="column is-4-desktop">
        //         <div style={{ maxWidth: "100px", margin: "0 auto" }}>
        //           <img style={{ width: "100%" }} src={nojobs} alt="" />
        //           <p style={{ textAlign: "center", fontSize: "16px" }}>
        //             {`- No ${type} jobs -`}
        //           </p>
        //         </div>
        //       </div>
        //       <div className="column is-4-desktop"></div>
        //     </div>
        //   )
        // }
      } else {
        var sArray = sortBy(cardsArray, [
          function(o) {
            return o.acceptedOn
          },
        ]).reverse()
        return sArray.map(m => {
          return m.status === "active" ||
            m.status === "review" ||
            m.status === "review-complete" ||
            m.status === "request-changes"
            ? showCards(m)
            : ""
        })
      }
    }
  }
  const showPendingCards = cardsArray => {
    if (cardsArray) {
      if (cardsArray.length === 0) {
        return <></>
        // if (type === "pending" || type === "active" || type === "complete") {
        //   return (
        //     <div className="columns is-vcentered">
        //       <div className="column is-4-desktop"></div>
        //       <div className="column is-4-desktop">
        //         <div style={{ maxWidth: "100px", margin: "0 auto" }}>
        //           <img style={{ width: "100%" }} src={nojobs} alt="" />
        //           <p style={{ textAlign: "center", fontSize: "16px" }}>
        //             {`- No ${type} jobs -`}
        //           </p>
        //         </div>
        //       </div>
        //       <div className="column is-4-desktop"></div>
        //     </div>
        //   )
        // }
      } else {
        var sArray = sortBy(cardsArray, [
          function(o) {
            return o.createdOn
          },
        ]).reverse()
        return sArray.map(m => {
          return m.status === "pending" || m.status === "payment-pending"
            ? showCards(m)
            : ""
        })
      }
    }
  }
  const showCompletedCards = cardsArray => {
    if (cardsArray) {
      if (cardsArray.length === 0) {
        return <></>
        // if (type === "pending" || type === "active" || type === "complete") {
        //   return (
        //     <div className="columns is-vcentered">
        //       <div className="column is-4-desktop"></div>
        //       <div className="column is-4-desktop">
        //         <div style={{ maxWidth: "100px", margin: "0 auto" }}>
        //           <img style={{ width: "100%" }} src={nojobs} alt="" />
        //           <p style={{ textAlign: "center", fontSize: "16px" }}>
        //             {`- No ${type} jobs -`}
        //           </p>
        //         </div>
        //       </div>
        //       <div className="column is-4-desktop"></div>
        //     </div>
        //   )
        // }
      } else {
        return cardsArray.map(m => {
          return m.status === "complete" ? showCards(m) : ""
        })
      }
    }
  }

  const countActiveJobs = () => {
    var a = countBy(state.jobs, val => {
      return (
        val.status === "active" ||
        val.status === "review" ||
        val.status === "review-complete" ||
        val.status === "request-changes"
      )
    })
    return a.true ? a.true : 0
  }

  const countPendingJobs = () => {
    var a = countBy(state.jobs, val => {
      return val.status === "pending"
    })
    return a.true ? a.true : 0
  }

  const countCompletedJobs = () => {
    var a = countBy(state.jobs, val => {
      return val.status === "complete"
    })
    return a.true ? a.true : 0
  }

  return (
    <>
      {loading ? (
        <ShowLoading timeOutValue={15000} />
      ) : (
        <div>
          {/* <ShowFeedback /> */}
          {toggleTripDetails ? (
            <div className="columns is-centered ">
              <div className="column is-10">
                <DetailsForDesigner
                  onClose={close}
                  onAccept={jobAccepted}
                  onDecline={jobDeclined}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="columns is-multiline">
                <div className="column is-2-desktop is-1-tablet "></div>
                <div className="column is-8-desktop is-10-tablet ">
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: "700",
                      borderBottom: "2px solid #fec600",
                    }}
                  >
                    Your Jobs
                  </p>
                </div>
                {/* <hr className="hr-branded"></hr> */}
                <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
                <div className="column is-2-desktop is-1-tablet is-hidden-mobile "></div>
                <div className="column is-8-desktop is-10-tablet ">
                  <nav className="level" style={{}}>
                    <div className="level-item has-text-centered">
                      <div>
                        <p className="heading" style={{}}>
                          Active
                        </p>
                        <p className="title" style={{}}>
                          {countActiveJobs()}
                        </p>
                      </div>
                    </div>
                    <div className="level-item has-text-centered">
                      <div>
                        <p className="heading" style={{}}>
                          Pending Approval
                        </p>
                        <p className="title" style={{}}>
                          {countPendingJobs()}
                        </p>
                      </div>
                    </div>
                    <div className="level-item has-text-centered">
                      <div>
                        <p className="heading" style={{}}>
                          Completed
                        </p>
                        <p className="title" style={{}}>
                          {countCompletedJobs()}
                        </p>
                      </div>
                    </div>
                    {/* <div className="level-item has-text-centered">
                      <div>
                        <p className="heading">Declined</p>
                        <p className="title">789</p>
                      </div>
                    </div> */}
                  </nav>
                  <hr className="hr-branded"></hr>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Pending:
                  </p>
                  <div className="trip-container">
                    {showPendingCards(state.jobs)}
                    {/* {updateCards(state.jobs, "pending")} */}
                  </div>
                  <hr className="hr-branded"></hr>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Active:
                  </p>
                  <div className="trip-container">
                    {showActiveCards(state.jobs)}
                    {/* {updateCards(state.jobs, "active")}
                    {updateCards(state.jobs, "review")}
                    {updateCards(state.jobs, "review-complete")}
                    {updateCards(state.jobs, "request-changes")} */}
                  </div>
                  <hr className="hr-branded"></hr>
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Completed:
                  </p>
                  <div className="trip-container">
                    {showCompletedCards(state.jobs)}
                  </div>
                  <hr className="hr-branded"></hr>
                </div>
                <div className="column is-2-desktop is-1-tablet "></div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
