import React, { useContext, useEffect, useState } from "react"
import { FirebaseContext } from "../../../components/firebase"
import { analytics } from "../../../components/firebase/firebase"
import { AuthUserContext } from "../../../components/session"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import { ToastProvider, useToasts } from "react-toast-notifications"
import Async from "react-async"
import "./CollaboratorDetails.scss"
import { functions } from "../../../components/firebase/firebase"
import is from "is_js"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import { mapValues } from "lodash"
const CollaboratorDetails = () => {
  const MAXCOLLABORATORSCOUNT = 10

  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripDispatch] = useTripStateStore()
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [collaborators, setCollaborators] = useState([])
  const [maxTeamMatesReached, setMaxTeamMatesReached] = useState(false)
  const [invitee, setInvitee] = useState("")
  const [inviteeRole, setInviteeRole] = useState("viewer")
  const [busy, setBusy] = useState(false)
  const [role, setRole] = useState("viewer")
  const [invalidEmailAddress, setInvalidEmailAddress] = useState(false)
  const { addToast } = useToasts()
  const [collaborationInviteState, setCollaborationInviteState] = useState(
    "none"
  )
  const [currentCollaboratorsBusy, setCurrentCollaboratorsBusy] = useState({})
  useEffect(() => {
    console.log({ collaborators })
  }, [collaborators])

  useEffect(() => {
    console.log({ role })
  }, [role])
  useEffect(() => {
    console.log({ state })
  }, [state])
  useEffect(() => {
    console.log({ collaborationInviteState })
  }, [collaborationInviteState])

  const showCollaborators = () => {
    try {
      if (state.tripDetails.roles) {
        let allUIDs = Object.keys(state.tripDetails.roles)
        console.log({ allUIDs })
        if (allUIDs.length === 1) return "- None -"

        if (allUIDs.length < MAXCOLLABORATORSCOUNT + 1) {
          if (maxTeamMatesReached) setMaxTeamMatesReached(false)
        } else {
          if (!maxTeamMatesReached) setMaxTeamMatesReached(true)
        }

        return allUIDs.map((item, index) => {
          if (state.tripDetails.roles[item].role !== "owner")
            return (
              <>
                {currentCollaboratorsBusy[item] === "busy" ? (
                  <>
                    {" "}
                    <div
                      className="spinner "
                      style={{ zIndex: "20", margin: "auto auto" }}
                    >
                      <div className="rect1"></div>
                      <div className="rect2"></div>
                      <div className="rect3"></div>
                      <div className="rect4"></div>
                      <div className="rect5"></div>
                    </div>
                  </>
                ) : (
                  <div className="cd-container" key={item}>
                    {/* <div className="cd-profile-image">
                  <img src={item.profilePic} alt="" />
                </div> */}
                    <div className="control">
                      <div className="tags has-addons">
                        <span className="tag is-warning">
                          {" "}
                          {state.tripDetails.roles[item].userName}
                        </span>
                        <span className="tag is-warning">
                          {" "}
                          {state.tripDetails.roles[item].role}
                        </span>
                        <a
                          onClick={e => {
                            ConfirmationModal({
                              onConfirm: e => {
                                let a = {
                                  ...currentCollaboratorsBusy,
                                  [item]: "busy",
                                }
                                setCurrentCollaboratorsBusy(a)
                                removeCollaborator(e)
                              },
                              sendBackValue: e.currentTarget.id,
                              title: "Confirmation",
                              text: `Are you sure you want to stop sharing the trip with this user?`,
                            })
                            // removeCollaborator()
                            // setEditDayDestination(true)
                            // dispatch({
                            //   type: "updateDayDestination",
                            //   data: "",
                            //   activeTab: activeTab,
                            // })
                          }}
                          id={item}
                          className="tag is-delete"
                        ></a>
                      </div>
                    </div>
                    <div className="cd-user-name" style={{ display: "none" }}>
                      <div style={{ display: "flex" }}>
                        <p style={{ marginRight: "10px" }}>
                          {state.tripDetails.roles[item].userName}
                        </p>
                        <a
                          style={{
                            textTransform: "uppercase",
                            // fontSize: "12px",
                            lineHeight: "27px",
                            color: "#4a4a4a",
                            letterSpacing: "0.5px",
                            textDecoration: "underline",
                            textDecorationColor: "#fec600",
                          }}
                          id={item}
                          // className="button is-warning is-small"
                          onClick={e => {
                            ConfirmationModal({
                              onConfirm: e => {
                                let a = {
                                  ...currentCollaboratorsBusy,
                                  [item]: "busy",
                                }
                                setCurrentCollaboratorsBusy(a)
                                removeCollaborator(e)
                              },
                              sendBackValue: e.currentTarget.id,
                              title: "Confirmation",
                              text: `Are you sure you want to stop sharing the trip with this user?`,
                            })
                            // removeCollaborator()
                            // setEditDayDestination(true)
                            // dispatch({
                            //   type: "updateDayDestination",
                            //   data: "",
                            //   activeTab: activeTab,
                            // })
                          }}
                        >
                          <i className="fal fa-trash-alt"></i>
                          {/* Remove */}
                        </a>
                      </div>
                    </div>
                    <div
                      className="cd-container-action-items"
                      style={{ display: "none" }}
                    >
                      <div className="cd-role">
                        <p>{state.tripDetails.roles[item].role}</p>
                      </div>
                      {/* <div className="cd-role">
                        <a
                          style={{
                            // textTransform: "uppercase",
                            // fontSize: "12px",
                            lineHeight: "27px",
                            color: "#fec600",
                          }}
                          className=""
                          onClick={() => {
                            // setEditDayDestination(true)
                            // dispatch({
                            //   type: "updateDayDestination",
                            //   data: "",
                            //   activeTab: activeTab,
                            // })
                          }}
                        >
                          Edit Role
                        </a>
                      </div> */}

                      <div className="cd-role">
                        <a
                          style={{
                            textTransform: "uppercase",
                            // fontSize: "12px",
                            lineHeight: "27px",
                            color: "#4a4a4a",
                            letterSpacing: "0.5px",
                            textDecoration: "underline",
                            textDecorationColor: "#fec600",
                          }}
                          id={item}
                          // className="button is-warning is-small"
                          onClick={e => {
                            ConfirmationModal({
                              onConfirm: e => {
                                let a = {
                                  ...currentCollaboratorsBusy,
                                  [item]: "busy",
                                }
                                setCurrentCollaboratorsBusy(a)
                                removeCollaborator(e)
                              },
                              sendBackValue: e.currentTarget.id,
                              title: "Confirmation",
                              text: `Are you sure you want to stop sharing the trip with this user?`,
                            })

                            // removeCollaborator()
                            // setEditDayDestination(true)
                            // dispatch({
                            //   type: "updateDayDestination",
                            //   data: "",
                            //   activeTab: activeTab,
                            // })
                          }}
                        >
                          <i className="fal fa-trash-alt"></i>
                          {/* Remove */}
                        </a>
                      </div>
                    </div>
                    <div style={{ display: "none" }}>
                      <label className="label">Role:</label>
                      <div className="vacation-pacing">
                        <div style={{ marginBottom: "5px" }}>
                          {/* <label className="label">Role:</label> */}
                          <input
                            className="is-checkradio has-background-color is-warning"
                            id="radio_role_viewer"
                            type="radio"
                            name="radio_role_viewer"
                            // disabled={isAccommodationNotRequired()}
                            // checked="checked"
                            checked={
                              state.tripDetails.roles[item].role === "viewer"
                                ? "checked"
                                : ""
                            }
                            onClick={e => {
                              setRole("viewer")
                            }}
                          ></input>
                          <label
                            for="radio_role_viewer"
                            style={{ marginLeft: "8px" }}
                          >
                            Viewer
                          </label>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <input
                            className="is-checkradio has-background-color is-warning"
                            id="radio_role_editor"
                            type="radio"
                            name="radio_role_editor"
                            // disabled={isAccommodationNotRequired()}
                            // checked="checked"
                            checked={
                              state.tripDetails.roles[item].role === "editor"
                                ? "checked"
                                : ""
                            }
                            onClick={e => {
                              setRole("editor")
                            }}
                          ></input>
                          <label
                            for="radio_role_editor"
                            style={{ marginLeft: "8px" }}
                          >
                            Editor
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const removeCollaborator = removeUid => {
    console.log({ removeUid })
    if (removeUid) {
      let data = {
        tripUID: state.tripDetails.tripUid,
        removeUID: removeUid,
        jobUID: state.currentJobInfo.jobUid,
        chatRoomId: state.currentJobInfo.roomId,
      }
      var removeCollaborator = functions.httpsCallable("removeCollaborator")
      removeCollaborator(data)
        .then(result => {
          // Read result of the Cloud Function.

          setBusy(false)
          let a = {
            ...currentCollaboratorsBusy,
            [removeUid]: "",
          }
          setCurrentCollaboratorsBusy(a)
          console.log({ result })
          if (result.data.success != undefined) {
            let a = result.data.uid
            dispatch({
              type: "removeNewRoleEntry",
              data: a,
            })
            console.log("success")
            // setCollaborationInviteState("success")
          }
          if (result.data.error != undefined) {
            console.log("error")
            // setCollaborationInviteState("error")
          }
          // ...
        })
        .catch(err => {
          console.log(err)
          setBusy(false)

          setCollaborationInviteState("error")
        })
    }
  }
  const inviteeAlreadyOnTheList = () => {
    try {
      console.log("inviteeAlreadyOnTheList")

      let cRoles = state.currentJobInfo.roles
      let present = false
      let tList = Object.keys(state.currentJobInfo.roles)
      // let result = mapValues(cRoles, function(o) {
      //   return o.email === invitee.trim()
      // })
      console.log({ tList })
      tList.map((item, index) => {
        console.log(item.email)
        if (cRoles[item].email === invitee.trim()) {
          present = true
        }
      })
      return present
    } catch (error) {
      console.log(error)
    }
  }
  const sendCollaborationInvite = () => {
    if (is.email(invitee.trim())) {
      if (invitee.trim() === authContext.email) {
        addToast("Cannot add yourself as a trip mate.", {
          appearance: "error",
          autoDismiss: true,
        })
        setBusy(false)
        return
      }
      if (inviteeAlreadyOnTheList()) {
        addToast(
          "User is already on the list. If you would like to change the collaborator role, please remove the user and add with the appropriate role.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        )
        setBusy(false)
        return
      }
      let data = {
        tripUID: state.tripDetails.tripUid,
        jobUID: state.currentJobInfo.jobUid,
        iEmail: invitee,
        role: role,
        chatRoomId: state.currentJobInfo.roomId,
      }
      var addCollaborators = functions.httpsCallable("addCollaborators")
      addCollaborators(data)
        .then(async result => {
          // Read result of the Cloud Function.
          setBusy(false)
          console.log({ result })
          // await updateTripState()
          console.log({ state })

          if (result.data.success != undefined) {
            setInvitee("")
            console.log("success")

            if (result.data.success === "collaborator invite sent") {
              setCollaborationInviteState("success-upon-singup")
            } else {
              let a = result.data.roles
              if (a != [] && a != undefined)
                dispatch({
                  type: "addNewRoleEntry",
                  data: a,
                })
              setCollaborationInviteState("success")
            }
            analytics.logEvent("trip-mate-invite-sent")
          }
          if (result.data.error != undefined) {
            console.log("error")
            setCollaborationInviteState("error")
          }
          // ...
        })
        .catch(err => {
          console.log(err)
          setBusy(false)
          addToast(
            "Error adding trip mate, please try again in sometime. If the issue persists please reach out to support@voyayge.com",
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
          setCollaborationInviteState("error")
        })
    } else {
      setInvalidEmailAddress(true)
      setBusy(false)
    }
  }

  const showRightCollaborationInviteScreen = () => {
    switch (collaborationInviteState) {
      case "none":
        return (
          <>
            <p
              style={{
                // borderBottom: "solid 1px #fec600",
                marginBottom: "10px",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Add Trip Mates:
            </p>

            <div className="field" style={{ marginBottom: "15px" }}>
              <label className="label">Email:</label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  style={{ width: "100%", fontSize: "14px" }}
                  // placeholder="Text input"
                  autoFocus
                  onChange={e => {
                    setInvalidEmailAddress(false)
                    setInvitee(e.currentTarget.value)
                  }}
                ></input>
                {invalidEmailAddress ? (
                  <p className="help is-danger">Invalid email address</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <label className="label">Role:</label>
            <div className="vacation-pacing">
              <div style={{ marginBottom: "5px" }}>
                {/* <label className="label">Role:</label> */}
                <input
                  className="is-checkradio has-background-color is-warning"
                  id="radio_role_viewer"
                  type="radio"
                  name="radio_role_viewer"
                  // disabled={isAccommodationNotRequired()}
                  // checked="checked"
                  checked={role === "viewer" ? "checked" : ""}
                  onClick={e => {
                    setRole("viewer")
                  }}
                ></input>
                <label
                  for="radio_role_viewer"
                  style={{ marginLeft: "8px", fontSize: "14px" }}
                >
                  Viewer
                </label>
              </div>
              <div style={{ marginBottom: "5px" }}>
                <input
                  className="is-checkradio has-background-color is-warning"
                  id="radio_role_editor"
                  type="radio"
                  name="radio_role_editor"
                  // disabled={isAccommodationNotRequired()}
                  // checked="checked"
                  checked={role === "editor" ? "checked" : ""}
                  onClick={e => {
                    setRole("editor")
                  }}
                ></input>
                <label
                  for="radio_role_editor"
                  style={{ marginLeft: "8px", fontSize: "14px" }}
                >
                  Editor
                </label>
              </div>
            </div>

            {role === "viewer" ? (
              <p style={{ fontSize: "14px", marginBottom: "20px" }}>
                Note: Viewer can only view your trip
              </p>
            ) : (
              <p style={{ fontSize: "14px", marginBottom: "20px" }}>
                Note: Editor can view and modify your trip
              </p>
            )}
            <div className="control">
              <button
                onClick={() => {
                  if (maxTeamMatesReached) {
                    addToast("Reached maximum invite count", {
                      appearance: "error",
                      autoDismiss: true,
                    })
                    return
                  }
                  setBusy(true)
                  sendCollaborationInvite()
                }}
                style={{ width: "100%" }}
                className={`${
                  busy
                    ? "button is-small is-warning is-loading"
                    : "button is-small is-warning"
                } `}
              >
                Add
              </button>
            </div>
          </>
        )
      case "success":
        return (
          <>
            <p
              style={{
                // borderBottom: "solid 2px #fec600",
                marginBottom: "10px",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Add Trip Mates:
            </p>
            <p style={{ fontSize: "500", margin: "15px auto" }}>
              Trip mate successfully added!
            </p>
            <button
              className="button is-warning"
              style={{ width: "100%" }}
              onClick={() => {
                setCollaborationInviteState("none")
              }}
            >
              Send another invite
            </button>
          </>
        )
      case "success-upon-singup":
        return (
          <>
            <p
              style={{
                // borderBottom: "solid 2px #fec600",
                marginBottom: "10px",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Add Trip Mates:
            </p>
            <p style={{ fontSize: "500", margin: "15px auto" }}>
              We have sent an email inviting the user to Signup. The user does
              not have a voyay!ge account.<br></br>
              <br></br>Please add the user again, once they finish signing up.
            </p>
            <button
              className="button is-warning"
              style={{ width: "100%" }}
              onClick={() => {
                setCollaborationInviteState("none")
              }}
            >
              Send another invite
            </button>
          </>
        )
      case "error":
        return (
          <>
            <p
              style={{
                borderBottom: "solid 2px #fec600",
                marginBottom: "10px",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              Add collaborators to your trip
            </p>
            <p style={{ fontSize: "500", margin: "15px auto" }}>
              Error adding trip mate. Please try again in sometime.
            </p>
            <button
              className="button is-warning"
              style={{ width: "100%" }}
              onClick={() => {
                setCollaborationInviteState("none")
              }}
            >
              Try again
            </button>
          </>
        )

      default:
        break
    }
  }

  return (
    <div>
      <div style={{}}>
        <div style={{ marginBottom: "50px" }}>
          <p
            style={{
              marginBottom: "15px",
              fontWeight: "600",
              borderBottom: "solid 2px #fec600",
              fontSize: "18px",
            }}
          >
            Trip Mates
          </p>
          <span style={{ fontSize: "14px" }}>{showCollaborators()}</span>
        </div>
        {showRightCollaborationInviteScreen()}
      </div>
    </div>
  )
}

export default CollaboratorDetails
