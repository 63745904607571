import React, { useContext, useEffect, useState } from "react"
import ItineraryCreator from "./ItineraryCreator"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../../components/session/context"
import ItineraryViewer from "./ItineraryViewer"
import BYOTItineraryCreator from "./BYOTItineraryCreator"

function BYOTItineraryComponent({ defaultDayId, canEdit, location }) {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [state, dispatch] = useDashboardStateStore()

  const [enableTravelerView, setEnableTravelerView] = useState(false)

  useEffect(() => {
    if (state && state.currentJobInfo) {
      if (state.currentJobInfo.itineraryStatus !== "in-progress") {
        beginWork()
      }
    }
  }, [state])

  // Work has started on the itineraries. Update the flag in db and also initialize the itinerary workspace.
  const beginWork = e => {
    //Update the required state and db values
    try {
      var startDate = new Date(state.currentJobInfo.travelDates.start)
      var endDate = new Date(state.currentJobInfo.travelDates.end)
      console.log({ startDate })
      console.log({ endDate })
      //get all the date between the trip start and end dates.
      var a = []
      for (
        var d = new Date(startDate);
        d <= endDate;
        d.setDate(d.getDate() + 1)
      ) {
        a.push({
          date: new Date(d).toUTCString(),
          activities: [],
          dayScheduleStartTime: new Date(
            new Date(d).setUTCHours(8)
          ).toUTCString(), // This set the default time when all the scheduling begins for the day.
          daysStats: {
            totalActivitiesTime: 0,
            totalCommuteTime: 0,
            totalDiningTime: 0,
            totalSightseeingTime: 0,
          },
        })
      }

      if (dbContext) {
        dbContext
          .firestore()
          .collection("jobs")
          .doc(`${authContext.uid}`)
          .collection("active-jobs")
          .doc(`${state.currentJobInfo.jobUid}`)
          .update({
            itineraryStatus: "in-progress",
            designerWorkspace: { itinerary: a },
          })
          .then(() => {
            console.log("document update successful")
            //update local state now
            dispatch({ type: "itineraryInProgress" })
            dispatch({
              type: "initializeDesignerWorkspaceForItineraries",
              data: a,
            })

            //
          })
          .catch(e => console.log(e))
      }
    } catch (error) {
      console.log("beginWork: ", error)
    }
  }
  return (
    <div className="">
      {/* If no work as commenced yet on itineraries*/}
      {state.currentJobInfo.itineraryStatus === "in-progress" ? (
        <>
          <BYOTItineraryCreator
            defaultDayId={defaultDayId}
            canEdit={canEdit}
            location={location}
          />
        </>
      ) : (
        // <ItineraryCreator />

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <button className="button is-warning" onClick={beginWork}>
              Start working on your trip itinerary
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default BYOTItineraryComponent
