import React, { useState, useEffect } from "react"
import thankq from "./../../images/thankq.png"
import errorImg from "./../../images/error.png"
import { functions } from "../firebase/firebase"
import ShowLoading from "../ShowLoading/ShowLoading"
const NewsletterFeedback = ({ nId, score }) => {
  const [busy, setBusy] = useState(false)
  const [submissionState, setSubmissionState] = useState("none")
  useEffect(() => {
    pushFeedback()
  }, [])
  const pushFeedback = () => {
    try {
      setBusy(true)
      let data = {
        score: score,
        id: nId,
      }
      var saveFeedback = functions.httpsCallable("saveNewsletterFeedback")
      saveFeedback(data)
        .then(function(result) {
          // Read result of the Cloud Function.
          setBusy(false)
          console.log({ result })
          if (result.data.success) {
            window.scrollTo(0, 0)
            setSubmissionState("success")
          } else {
            window.scrollTo(0, 0)
            setSubmissionState("error")
          }
          // ...
        })
        .catch(err => {
          console.log(err)
          setBusy(false)
          window.scrollTo(0, 0)
          setSubmissionState("error")
        })
    } catch (err) {
      console.log(err)
      setBusy(false)
      window.scrollTo(0, 0)
      setSubmissionState("error")
    }
  }

  const showFormState = () => {
    // window.scrollTo(0, 0)
    switch (submissionState) {
      case "none":
        return (
          <>
            <ShowLoading />
          </>
        )

      case "error":
        return (
          <>
            <div style={{ margin: "20px auto", maxWidth: "300px" }}>
              <img
                src={errorImg}
                alt="error!"
                style={{
                  padding: "10px",
                  // maxHeight: "300px",
                  width: "100%",
                }}
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                margin: "15px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Uh oh!, there was an error submitting your feedback. Please try
              again in sometime. Sorry! <br></br>{" "}
              {/* <button className="button">Retry</button> */}
              <small>
                If the issue persists please email to{" "}
                <a href="mailto: support@voyayge.com">support@voyayge.com</a>
              </small>
            </p>
          </>
        )
      case "success":
        return (
          <>
            <div
              style={{
                maxWidth: "400px",
                margin: "0px auto",
                marginTop: "100px",
              }}
            >
              <img src={thankq} alt="thank you" />
            </div>
            <p
              style={{
                fontSize: "22px",
                margin: "20px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              We received your feedback!
            </p>
          </>
        )

      default:
        break
    }
  }

  return <div>{showFormState()}</div>
}

export default NewsletterFeedback
