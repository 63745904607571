import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const ShowImage = ({ imageObj, isFullWidth, isRounded }) => {
  const app_name = "travelPlanner"
  const display = () => {
    try {
      if (imageObj && imageObj.urls) {
        return (
          <figure class="image is-4x3">
            <div style={{ position: "relative" }}>
              <LazyLoadImage
                src={imageObj.urls.regular}
                effect="blur"
                style={{
                  // height: fullWidth ? "auto" : "154px",
                  height: isFullWidth ? "auto" : "228px",
                  width: isFullWidth ? "" : "228px",
                  maxHeight: isFullWidth ? "300px" : "228px",
                  objectFit: "cover",
                  borderRadius: isRounded
                    ? "4px 4px 0px 0px"
                    : "0px 0px 0px 0px",
                }}
                wrapperClassName="lazy-load-image-wrapper"
              ></LazyLoadImage>
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "15px",
                  fontSize: "8px",
                  color: "#4a4a4a",
                  backgroundColor: "rgba(256,256,256,0.8)",
                  padding: "2px 7px",
                  borderRadius: "2px",
                }}
              >
                <span>Photo by </span>
                <a
                  // href={`${data.response.user.links.html}?utm_source=${app_name}&utm_medium=referral`}
                  style={{ color: "#4a4a4a", textDecoration: "underline" }}
                  onClick={e => {
                    window.open(
                      `${imageObj.user.profileURL}?utm_source=${app_name}&utm_medium=referral`,
                      "_blank"
                    )
                    e.stopPropagation()
                  }}
                >
                  {imageObj.user.fName}
                </a>
              </div>
            </div>
          </figure>
        )
      } else {
        return (
          <figure class="image is-4x3">
            {/* <AsyncFetchImage
                  imageId={image.imageId}
                  rounded={rounded}
                  fullWidth={fullWidth}
                /> */}
          </figure>
        )
      }
    } catch (error) {}
  }
  return <>{display()}</>
}

export default ShowImage
