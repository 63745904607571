import React, { useState, useEffect, useContext } from "react"
import love from "./../../images/love-comment.jpg"
import thankq from "./../../images/thankq.png"
import errorImg from "./../../images/error.png"
import { functions } from "../firebase/firebase"
import { v4 as uuidv4 } from "uuid"
import { FirebaseContext } from "../firebase"
import { AuthUserContext } from "../session"
const GeneralFeedBackForm = () => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [whatWorkedText, setWhatWorkedText] = useState("")
  const [whatWorkedTextLen, setWhatWorkedTextLen] = useState(0)
  const [feedbackTextLen, setFeedbackTextLen] = useState(0)
  const [feedbackText, setFeedbackText] = useState("")
  const [npsScore, setNpsScore] = useState(0)
  const [screenLocationState, setScreenLocationState] = useState("score")
  const [submissionState, setSubmissionState] = useState("none")
  const [busy, setBusy] = useState(false)
  const [itineraryScore, setItineraryScore] = useState(-1)
  const [accommodationScore, setAccommodationScore] = useState(-1)
  const [reservationsScore, setReservationsScore] = useState(-1)
  const [destinationInfoScore, setDestinationInfoScore] = useState(-1)
  const [checklistScore, setChecklistScore] = useState(-1)
  const [offlinePlanScore, setOfflinePlanScore] = useState(-1)

  useEffect(() => {
    setFeedbackTextLen(feedbackText.length)
  }, [feedbackText])
  const pushFeedback = () => {
    try {
      setBusy(true)
      let data = {
        feedback: feedbackText,
        id: uuidv4(),
        user: authContext.uid,
        email: authContext.email,
      }
      var saveFeedback = functions.httpsCallable("saveGeneralFeedback")
      saveFeedback(data)
        .then(function(result) {
          // Read result of the Cloud Function.
          setBusy(false)
          console.log({ result })
          if (result.data.success) {
            window.scrollTo(0, 0)
            setSubmissionState("success")
          } else {
            window.scrollTo(0, 0)
            setSubmissionState("error")
          }
          // ...
        })
        .catch(err => {
          console.log(err)
          setBusy(false)
          window.scrollTo(0, 0)
          setSubmissionState("error")
        })
    } catch (err) {
      console.log(err)
      setBusy(false)
      window.scrollTo(0, 0)
      setSubmissionState("error")
    }
  }

  const showFormState = () => {
    // window.scrollTo(0, 0)
    switch (submissionState) {
      case "none":
        return (
          <>
            <div>
              <div style={{ display: "" }}>
                <p className="" style={{ fontSize: "28px", fontWeight: "800" }}>
                  Send Feedback<span style={{ color: "#fec600" }}>.</span>{" "}
                </p>{" "}
              </div>
              {/* <p className="feedback-preamble">
                We want to be sure you are delighted with your experience with
                <strong
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  {" "}
                  vo<span style={{ color: "#fec600" }}>yay!</span>ge
                </strong>
                . To help provide you with the best experience possible, we'd
                like your help with this very brief survey:{" "}
              </p> */}
            </div>

            <div
              style={{ marginTop: "15px", marginBottom: "35px" }}
              className="fade-in"
            >
              <div className="field">
                <label className="label">How could we improve?</label>
                <div className="control is-expanded">
                  <textarea
                    onChange={e => {
                      if (e.currentTarget.value.length <= 500)
                        setFeedbackText(e.currentTarget.value)
                    }}
                    value={feedbackText}
                    className="textarea"
                    placeholder=""
                    rows={8}
                  ></textarea>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="help"></p>
                    <p
                      className={`help is-pulled-right ${
                        feedbackTextLen >= 500 ? "is-danger" : ""
                      }`}
                    >
                      Characters: {feedbackTextLen}/500
                    </p>
                  </div>
                  {/* {errors.accommodation ? (
                   <p className="help is-danger">
                     Please pick you accommodation preference
                   </p>
                 ) : (
                   <p className="help">pick all that apply</p>
                 )} */}
                </div>
              </div>
            </div>
            <div
              className="field  is-pulled-right"
              style={{ marginTop: "-5px" }}
            >
              <button
                style={{ fontSize: "14px", minWidth: "150px" }}
                className={`${
                  busy
                    ? "button is-warning is-pulled-right is-loading"
                    : "button is-warning is-pulled-right"
                }`}
                onClick={e => {
                  pushFeedback()
                  //   setScreenLocationState("thanks")
                  e.preventDefault()
                }}
              >
                Submit
              </button>
            </div>
          </>
        )

      case "error":
        return (
          <>
            <div style={{ margin: "20px auto", maxWidth: "300px" }}>
              <img
                src={errorImg}
                alt="error!"
                style={{
                  padding: "10px",
                  // maxHeight: "300px",
                  width: "100%",
                }}
              />
            </div>
            <p
              style={{
                fontSize: "16px",
                margin: "15px auto",
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              Uh oh!, there was an error submitting your feedback. Please try
              again in sometime. Sorry! <br></br>{" "}
              {/* <button className="button">Retry</button> */}
              <small>
                If the issue persists please email to{" "}
                <a href="mailto: support@voyayge.com">support@voyayge.com</a>
              </small>
            </p>
          </>
        )
      case "success":
        return (
          <>
            <div style={{ maxWidth: "400px", margin: "0px auto" }}>
              <img src={thankq} alt="thank you" />
            </div>
            <p
              style={{
                fontSize: "16px",
                margin: "10px auto",
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              We received your feedback!
            </p>
          </>
        )

      default:
        break
    }
  }

  return (
    <div>
      {" "}
      <div className="columns" style={{ paddingTop: "25px" }}>
        <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
        <div className="column is-6-desktop is-8-tablet is-12-mobile">
          <div style={{ marginTop: "40px" }}>{showFormState()}</div>
        </div>
        <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}

export default GeneralFeedBackForm
