import React, { useState, useEffect } from "react"
// import ReactQuill from "react-quill"
import logo from "../../images/logo.png"
import { navigate } from "gatsby"
import "./reco.scss"
import { functions } from "../firebase/firebase"
import useRestrictCharacterLength from "../../hooks/useRestrictCharacterLength"
import thankq from "./../../images/thankq.png"
import errorImg from "./../../images/error.png"
import loadable from "@loadable/component"
const ReactQuill = loadable(() => import("react-quill"))
function RecommendationsForm({ recommendationId }) {
  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(1000)
  const [recommendations, setRecommendations] = useState("")
  const [error, setError] = useState(false)
  const [textLength, setTextLength] = useState(0)
  const reactQuillRef = React.useRef()
  const [submissionState, setSubmissionState] = useState("none")
  const [busy, setBusy] = useState(false)
  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      // ["clean"],
    ],
  }

  useEffect(() => {
    console.log({ textLength })
  }, [textLength])

  useEffect(() => {
    document.title = "Your recommendations | voyayge"
  }, [])

  console.log({ recommendationId })
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    //  "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
  ]

  const saveRecommendations = () => {
    try {
      let data = {
        recommendation: recommendations,
        id: recommendationId,
      }
      var saveRecommendations = functions.httpsCallable("saveRecommendations")
      saveRecommendations(data).then(function(result) {
        // Read result of the Cloud Function.
        setBusy(false)
        console.log({ result })
        if (result.data.success) {
          setSubmissionState("success")
        } else {
          setSubmissionState("error")
        }
        // ...
      })
    } catch (err) {
      console.log(err)
      setBusy(false)
      setSubmissionState("error")
    }
  }
  const checkCharacterCount = event => {
    const editor = reactQuillRef.current.getEditor()
    const unprivilegedEditor = reactQuillRef.current.makeUnprivilegedEditor(
      editor
    )
    // const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor
    // console.log({ unprivilegedEditor })

    let len = unprivilegedEditor.getText().length
    setTextLength(len)
    if (len > 1000 && event.key !== "Backspace") event.preventDefault()
    //setError(true)
  }

  const showRightScreen = () => {
    switch (submissionState) {
      case "none":
        return (
          <>
            {" "}
            <p
              style={{
                fontSize: "20px",
                margin: "15px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              YOUR RECOMMENDATIONS
            </p>
            <div className="field">
              {/* <label className="label">Recommendations:</label> */}
              <div className="reco-quill" style={{ minHeight: "400px" }}>
                <ReactQuill
                  theme="snow"
                  onKeyDown={checkCharacterCount}
                  value={recommendations}
                  ref={reactQuillRef}
                  onChange={e => {
                    console.log(e)
                    //   if (check(e.length)) return
                    setError(false)
                    setRecommendations(e)
                  }}
                  placeholder="Please paste your recommendations here..."
                  rows="15"
                  modules={modules}
                  formats={formats}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {error && (
                  <p className="help is-danger">
                    Please enter your recommendations before submitting
                  </p>
                )}

                <p
                  className={`help is-pulled-right ${
                    reachedMaxLength ? "is-danger" : ""
                  }`}
                >
                  Characters: {recommendations ? textLength : "0"}/
                  {maxCharactersAllowed}
                </p>
              </div>
              <button
                className={`${
                  busy
                    ? "button is-warning is-pulled-right is-loading"
                    : "button is-warning is-pulled-right"
                }`}
                style={{ marginTop: "25px" }}
                onClick={() => {
                  if (recommendations.length > 15) {
                    setBusy(true)
                    saveRecommendations()
                    setError(false)
                  } else setError(true)
                }}
              >
                Submit recommendations
              </button>
            </div>
          </>
        )
        break
      case "error":
        return (
          <>
            <div style={{ margin: "20px auto", maxWidth: "300px" }}>
              <img
                src={errorImg}
                alt="error!"
                style={{
                  padding: "10px",
                  // maxHeight: "300px",
                  width: "100%",
                }}
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                margin: "15px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Uh oh!, there was an error submitting your recommendations. Please
              try again in sometime. Sorry! <br></br>{" "}
              <small>
                If the issue persists please email to{" "}
                <a href="mailto: support@voyayge.com">support@voyayge.com</a>
              </small>
            </p>
          </>
        )
        break
      case "success":
        return (
          <>
            <img src={thankq} alt="thank you" />
            <p
              style={{
                fontSize: "22px",
                margin: "0px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              We received your recommendations!
            </p>
          </>
        )
        break

      default:
        break
    }
  }

  return (
    <div style={{ padding: "25px" }}>
      <div className="columns">
        <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-6-desktop is-10-tablet is-12-mobile">
          <div
            style={{
              marginBottom: "45px",
              maxWidth: "500px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <img
              src={logo}
              style={{
                width: "100%",
              }}
              onClick={() => {
                navigate("/")
              }}
            ></img>
            {showRightScreen()}
          </div>
        </div>
        <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}

export default RecommendationsForm
