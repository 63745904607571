import React, { useState, useEffect, useRef, useContext } from "react"
import algoliasearch from "algoliasearch/lite"
import { createAutocomplete } from "@algolia/autocomplete-core"
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia"
const AttractionsSearchBar = ({ onItemSelected, onAddNewItem }) => {
  const [showAddNewActivityEntry, setShowAddNewActivityEntry] = useState(false)
  const [showAddOwnItem, setShowAddOwnItem] = useState(false)
  const [
    autocompleteStateAttraction,
    setAutocompleteStateAttraction,
  ] = useState("")
  const searchClient = algoliasearch(
    `6ISV547TER`,
    `7f1f9b4df5ef999caa0d7c6ba0ba8251`
  )
  const hideItem = id => {
    try {
      let a = document.getElementById(id)
      if (a) {
        a.style.display = "none"
      }
    } catch (error) {}
  }

  const showItem = id => {
    try {
      let a = document.getElementById(id)
      if (a) {
        a.style.display = "block"
      }
    } catch (error) {}
  }
  useEffect(() => {
    // console.log({ autocompleteStateAttraction })
    try {
      if (
        autocompleteStateAttraction.collections &&
        autocompleteStateAttraction.collections[0].items.length === 0 &&
        autocompleteStateAttraction.query !== "" &&
        autocompleteStateAttraction.status === "idle"
      ) {
        setShowAddOwnItem(true)
      } else {
        if (showAddOwnItem === true) setShowAddOwnItem(false)
      }
    } catch (error) {}
  }, [autocompleteStateAttraction])

  useEffect(() => {
    if (!autocompleteStateAttraction.isOpen) {
      hideItem("attraction-panel")
    }
  }, [showAddNewActivityEntry])

  const clearSearchBar = () => {
    try {
      const searchBarInput = document.getElementById("idAttractions")
      console.log({ searchBarInput })
      if (searchBarInput) searchBarInput.value = ""
    } catch (error) {}
  }

  const autoCompleteAttractions = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state
          setAutocompleteStateAttraction(state)
        },
        id: "autoCompleteAttractions",
        navigator: {
          navigate({ itemUrl }) {
            console.log({ itemUrl })
          },
          navigateNewTab({ itemUrl }) {
            console.log({ itemUrl })
          },
        },
        // shouldPanelOpen() {
        //   console.log("shouldPanelOpen called")
        // },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: "attractions",
              getItemInputValue({ item }) {
                return item.query
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "attractions",
                      query,
                      params: {
                        hitsPerPage: 7,
                        highlightPreTag: "<mark>",
                        highlightPostTag: "</mark>",
                      },
                    },
                  ],
                })
              },
              templates: {
                // ...
                noResults() {
                  console.log("no-results")
                  return <p>No Results</p>
                },
              },
              getItemUrl({ item }) {
                return item.url
              },
              onSelect({ item }) {
                // console.log({ item })
                // setSelectedArrivalAirport(item)
              },
            },
          ]
        },
      }),
    []
  )

  const showAttractionsSearchBar = () => {
    try {
      return (
        <div className="wl-search-bar-container" style={{}}>
          <div className="wl-search-bar" style={{}}>
            {/* <input className="input" type="text" placeholder="Airport"></input> */}
            <div
              className="aa-Autocomplete"
              {...autoCompleteAttractions.getRootProps({})}
            >
              <input
                className="aa-Input input"
                tabindex="0"
                {...autoCompleteAttractions.getInputProps({})}
                id="idAttractions"
                placeholder="Search & add attractions to itinerary"
                style={{ fontSize: "14px" }}
                onBlur={e => {
                  // let a = e.currentTarget.value
                  // if (
                  //   a === "" &&
                  //   currentFlightInfo.arriveAirport != "" &&
                  //   currentFlightInfo.arriveCity != ""
                  // ) {
                  //   setAirportValues(currentFlightInfo)
                  // }
                  // console.log(e.currentTarget.value)
                  setTimeout(() => {
                    setShowAddOwnItem(false)
                  }, 200)
                }}
                // onFocus={e => {
                //   let a = e.currentTarget.value
                //   if (
                //     a === "" &&
                //     currentFlightInfo.arriveAirport != "" &&
                //     currentFlightInfo.arriveCity != ""
                //   ) {
                //     setAirportValues(currentFlightInfo)
                //   }
                //   console.log(e.currentTarget.value)
                // }}
              />
              <div
                className="aa-Panel"
                id="attraction-panel"
                {...autoCompleteAttractions.getPanelProps({})}
                //   placeholder="Airport"
              >
                {autocompleteStateAttraction.isOpen &&
                  autocompleteStateAttraction.collections.map(
                    (collection, index, array) => {
                      const { source, items } = collection
                      if (autocompleteStateAttraction.isOpen) {
                        showItem("attraction-panel")
                      } else {
                        hideItem("attraction-panel")
                      }
                      if (array.length === 0) {
                        setShowAddOwnItem(true)
                      }
                      if (array.length > 0 && showAddOwnItem === true) {
                        setShowAddOwnItem(false)
                      }
                      // console.log({ items })
                      return (
                        <div key={`source-${index}`} className="aa-Source">
                          {items.length > 0 && (
                            <ul
                              className="aa-List"
                              {...autoCompleteAttractions.getListProps()}
                            >
                              {items.map((item, index) => (
                                <li
                                  key={item.objectID}
                                  style={{
                                    background:
                                      autocompleteStateAttraction.activeItemId ===
                                      index
                                        ? "rgba(254, 198, 0, 1)"
                                        : "white",
                                  }}
                                  className="aa-Item"
                                  {...autoCompleteAttractions.getItemProps({
                                    item,
                                    source,
                                  })}
                                >
                                  <div
                                    style={{
                                      padding: "10px",
                                      minHeight: "40px",
                                      cursor: "pointer",
                                      fontSize: "14px",
                                    }}
                                    className="airport-code-items"
                                    onClick={() => {
                                      // var b = document.getElementById(
                                      //   "idAttractions"
                                      // )
                                      // console.log({ b })
                                      // if (b) {
                                      //   b.value = ` `
                                      // }

                                      if (onItemSelected) onItemSelected(item)
                                      clearSearchBar()
                                      autoCompleteAttractions.setQuery("")
                                      autoCompleteAttractions.setIsOpen(false)
                                      hideItem("attraction-panel")
                                      clearSearchBar()
                                      // clearResultsPanel()
                                      return
                                      //   var a = { ...currentFlightInfo }
                                      //   a["arriveAirport"] = item.iata_code
                                      //   a["arriveCity"] = item.city
                                      //   setCurrentFlightInfo({ ...a })
                                      //   var b = document.getElementById(
                                      //     "idAttractions"
                                      //   )
                                      //   if (b) {
                                      //     b.value = `${item.iata_code} - ${item.city}`
                                      //   }
                                    }}
                                  >
                                    <nav className="level is-mobile">
                                      <div className="level-left">
                                        <div className="level-item">
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <div>{item.name}</div>
                                            <div>
                                              <p
                                                className="help"
                                                style={{ marginTop: "-2px" }}
                                              >
                                                {item.city}, {item.country}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="level-right"
                                        style={{ display: "none" }}
                                      >
                                        <span
                                          className="tag is-warning"
                                          style={{
                                            textTransform: "uppercase",
                                            letterSpacing: "0.5px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {" "}
                                          {/* {item.iata_code} */}
                                        </span>
                                      </div>
                                    </nav>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )
                    }
                  )}
              </div>

              {showAddOwnItem && (
                <div
                  className="wl-add-new-item-panel"
                  id=""
                  // style={{ display: "none" }}
                >
                  <p
                    style={{
                      marginRight: "5px",
                      fontSize: "14px",
                      color: "rgba(74,74,74,0.8)",
                    }}
                  >
                    Can't find what you are looking for?{" "}
                  </p>
                  <a
                    style={{
                      textAlign: "center",
                      //   fontWeight: "600",
                      textDecorationColor: "#fec600",
                      color: "rgba(74, 74, 74, 0.8)",
                      //   textDecorationColor: "#fec600",
                      //   textDecoration: "underline",
                      fontSize: "14px",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      if (onAddNewItem) onAddNewItem(true)
                      //   setShowAddNewActivityEntry(true)
                      // setTimeout(() => {
                      setShowAddOwnItem(false)
                      hideItem("attraction-panel")
                      clearSearchBar()
                      // }, 200)
                    }}
                    className="button is-warning"
                  >
                    {/* <i
                            className="far fa-plus"
                            style={{ marginRight: "5px", color: "rgba(74,74,74,0.8)" }}
                          ></i>{" "} */}
                    Add an item
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="wl-search-bar-or-txt" style={{ display: "none" }}>
            <p style={{}}>OR</p>
          </div>

          {/* <div className="wl-search-bar-add-item" style={{ display: "none" }}>
              {canEdit ? (
                <div
                  style={{
                    // margin: "30px auto",
                    maxWidth: "400px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <a
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      textDecorationColor: "#fec600",
                      color: "rgba(74, 74, 74, 0.8)",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      setShowAddNewActivityEntry(true)
                    }}
                    className="button is-warning"
                  >
                    <i
                      className="far fa-plus"
                      style={{ marginRight: "5px", color: "rgba(74,74,74,0.8)" }}
                    ></i>{" "}
                    Add an item
                  </a>
                </div>
              ) : (
                ""
              )}
            </div> */}
        </div>
      )
    } catch (err) {}
  }
  return <div tabIndex="0">{showAttractionsSearchBar()}</div>
}

export default AttractionsSearchBar
