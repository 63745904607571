import React, { useState } from "react"
import MessageWindow from "./MessageWindow"

export default function ShowMessageWindow() {
  const [showMessageWindow, setShowMessageWindow] = useState(false)
  const onClose = e => {
    setShowMessageWindow(false)
  }
  return (
    <div>
      {showMessageWindow && <MessageWindow onClose={onClose} />}
      <div
        className=""
        onClick={() => {
          setShowMessageWindow(!showMessageWindow)
        }}
      >
        {showMessageWindow ? (
          <button
            style={{ height: "50px" }}
            className="button is-warning is-rounded show-messages"
          >
            <i
              className="fal fa-times"
              style={{ color: "#4a4a4a", marginTop: "" }}
            ></i>
          </button>
        ) : (
          <>
            <button
              className="button is-warning is-rounded show-messages"
              style={{ height: "50px" }}
            >
              <span className="icon" style={{ marginLeft: "5px" }}>
                <i
                  className="far fa-comment fa-lg"
                  style={{ transform: "scaleX(-1)", marginRight: "7px" }}
                ></i>
              </span>
              <span style={{ fontWeight: "600", fontSize: "14px" }}>
                Message Traveler
              </span>
            </button>
            {/* <p style={{ position: "absolute" }}>Message traveler</p> */}

            {/* <i
              className="fad fa-comments-alt fa-2x"
              style={{ color: "#fec600", marginTop: "12px" }}
            ></i> */}
          </>
        )}
      </div>
    </div>
  )
}
