import React, { useState, useEffect, useContext } from "react"
import EditableProfileImageField from "../../components/EditableFormComponents/EditableProfileImageField"
import defaultProfileImage from "./../../images/defaultuser.png"
import { useDashboardStateStore } from "../../State/dashboard-state/dashboard-state"
import { state_index } from "../../constants/data/geoData"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "./../../components/firebase"
import Select from "react-select"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import DatePicker from "react-date-picker"
import swal from "sweetalert"
import { ToastProvider, useToasts } from "react-toast-notifications"

import { Link, navigate } from "gatsby"
import {
  USAStates,
  countryListValueLabel,
  months,
  days,
  years,
} from "../../constants/data/general"
import {
  ProfileStateProvider,
  useProfileStateStore,
} from "../../State/profile-state/profile-state"
import { responsiveColumnWrap } from "../../utility/general"
import moment from "moment"

const AddGeneralInfoBase = () => {
  const userAuthContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [state, dispatch] = useProfileStateStore()
  const [loading, setLoading] = useState(true)
  const { addToast } = useToasts()
  const datePickerStyle = {
    fontSize: "55px",
    color: "red",
  }

  useEffect(() => {
    if (dbContext) {
      dbContext
        .firestore()
        .collection("users")
        .doc(`${userAuthContext.uid}`)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            console.log(doc.data())
            dispatch({ type: "updateUserState", data: doc.data() })
            setLoading(false)
          } else {
            console.log("No such document!")
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error)
        })
    }
  }, [dbContext])

  useEffect(() => {
    console.log("%c Profile-State", "color: #FC440F; font-size: 18px", {
      state,
    })
  }, [state])

  useEffect(() => {
    // window.addEventListener(
    //   "popstate",
    //   function(event) {
    //     // The popstate event is fired each time when the current history entry changes.
    //   },
    //   false
    // )
  }, [])

  const pushChangesToDB = () => {
    try {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("users")
          .doc(`${userAuthContext.uid}`)
          .update(state.tempPrivateProfile)
          .then(function() {
            dbContext
              .firestore()
              .collection("users")
              .doc(`${userAuthContext.uid}`)
              .collection("publicProfile")
              .doc(`${userAuthContext.uid}`)
              .update({
                firstName: state.tempPrivateProfile.firstName,
                lastName: state.tempPrivateProfile.lastName,
                location:
                  state.tempPrivateProfile.homeAddress.city +
                  ", " +
                  state.tempPrivateProfile.homeAddress.country,
                userProfileImage: state.tempPrivateProfile.userProfileImage,
              })
              .then(function() {
                //navigate("/app/designersignup")
                window.history.back()
              })
              .catch(error => {
                console.log("Error updating the document:", error)
                addToast("Error saving changes, please try again in sometime", {
                  appearance: "error",
                  autoDismiss: true,
                })
              })
          })
          .catch(error => {
            console.log("Error updating the document:", error)
            addToast("Error saving changes, please try again in sometime", {
              appearance: "error",
              autoDismiss: true,
            })
          })
      }
    } catch (err) {
      console.log(err)
      addToast("Error saving changes, please try again in sometime", {
        appearance: "error",
        autoDismiss: true,
      })
    }
  }

  const showIsRequired = () => {
    return (
      <p
        style={{
          display: "inline-block",
          fontSize: "14px",
          fontWeight: "400",
          float: "right",
        }}
      >
        required
      </p>
    )
  }
  const showIsOptional = () => {
    return (
      <p
        style={{
          display: "inline-block",
          fontSize: "14px",
          fontWeight: "400",
          float: "right",
        }}
      >
        optional
      </p>
    )
  }
  const getDateSafely = d => {
    try {
      return d.toDate()
    } catch (error) {
      return d
    }
  }

  return (
    <>
      {loading ? (
        <ShowLoading />
      ) : (
        <div>
          {/* <button className="button is-warning" onClick={onClose}>
        Go Back
      </button> */}
          {responsiveColumnWrap(
            <div>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  borderBottom: "2px solid #fec600",
                  marginTop: "25px",
                }}
              >
                General Information
              </p>
            </div>
          )}
          {responsiveColumnWrap()}

          {responsiveColumnWrap(
            <>
              <div className="columns is-multiline">
                <div className="column is-12">
                  <div
                    className="profile-image"
                    style={{ position: "relative", maxWidth: "200px" }}
                  >
                    <EditableProfileImageField
                      profileImageURL={
                        state.privateProfile
                          ? state.privateProfile.userProfileImage
                          : defaultProfileImage
                      }
                      doUpdateImage={imageUrl => {
                        dispatch({
                          type: "updateTempProfileImageUrl",
                          data: imageUrl,
                        })
                      }}
                    />
                  </div>
                  <div
                    className="profile-image"
                    style={{
                      position: "relative",
                      maxWidth: "500px",
                      marginBottom: "20px",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                    >
                      {" "}
                      <strong>Note: </strong>We recommend setting your profile
                      picture. Having a profile picture makes your profile more
                      personable and approachable to the traveler. →{" "}
                      <a
                        href="https://www.orbitmedia.com/blog/perfect-profile-pictures-9-tips-plus-some-research/"
                        target="_blank"
                      >
                        Link to a helpful article.
                      </a>
                    </p>
                  </div>
                </div>

                <div className="column is-6">
                  <div className="field">
                    <label className="label">First Name</label>

                    <div className="control">
                      <input
                        className="input"
                        value={state.tempPrivateProfile.firstName}
                        type="text"
                        placeholder=""
                        onChange={e => {
                          dispatch({
                            type: "updateTempPrivateProfileFirstName",
                            data: e.currentTarget.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Last Name </label>
                    <div className="control">
                      <input
                        value={state.tempPrivateProfile.lastName}
                        className="input"
                        type="text"
                        placeholder=""
                        onChange={e => {
                          dispatch({
                            type: "updateTempPrivateProfileLastName",
                            data: e.currentTarget.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="column is-12">
                  <div className="field">
                    <label className="label">
                      Address Line 1{" "}
                      <p
                        style={{
                          display: "inline",
                          fontSize: "14px",
                          fontWeight: "400",
                          float: "right",
                        }}
                      >
                        <i className="far fa-user-shield"></i> Will not be
                        displayed on your public profile
                      </p>
                    </label>
                    <div className="control">
                      <input
                        value={
                          state.tempPrivateProfile.homeAddress
                            ? state.tempPrivateProfile.homeAddress.line1
                            : ""
                        }
                        className="input"
                        type="text"
                        placeholder=""
                        onChange={e => {
                          dispatch({
                            type: "updateTempPrivateProfileAAddressLine1",
                            data: e.currentTarget.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-12">
                  <div className="field">
                    <label className="label">
                      Address Line 2 {showIsOptional()}
                    </label>
                    <div className="control">
                      <input
                        value={
                          state.tempPrivateProfile.homeAddress
                            ? state.tempPrivateProfile.homeAddress.line2
                            : ""
                        }
                        className="input"
                        type="text"
                        placeholder=""
                        onChange={e => {
                          dispatch({
                            type: "updateTempPrivateProfileAAddressLine2",
                            data: e.currentTarget.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-3-desktop is-6-tablet">
                  <div className="field">
                    <label className="label">City</label>
                    <div className="control">
                      <input
                        value={
                          state.tempPrivateProfile.homeAddress
                            ? state.tempPrivateProfile.homeAddress.city
                            : ""
                        }
                        className="input"
                        type="text"
                        placeholder=""
                        onChange={e => {
                          dispatch({
                            type: "updateTempPrivateProfileAAddressCity",
                            data: e.currentTarget.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-3-desktop is-6-tablet">
                  <div className="field">
                    <label className="label">State</label>
                    <div className="control">
                      <Select
                        className="basic-single"
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                        }}
                        defaultValue={{
                          value: `${state.tempPrivateProfile.homeAddress.state}`,
                          label: `${state.tempPrivateProfile.homeAddress.state}`,
                        }}
                        menuPortalTarget={document.body}
                        classNamePrefix="select"
                        isSearchable="true"
                        isClearable="true"
                        onChange={(value, actionMeta) => {
                          if (value)
                            dispatch({
                              type: "updateTempPrivateProfileAAddressState",
                              data: value.label,
                            })
                        }}
                        options={USAStates}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-3-desktop is-6-tablet">
                  <div className="field">
                    <label className="label">ZIP</label>
                    <div className="control">
                      <input
                        className="input"
                        value={
                          state.tempPrivateProfile.homeAddress
                            ? state.tempPrivateProfile.homeAddress.zip
                            : ""
                        }
                        type="text"
                        placeholder=""
                        onChange={e => {
                          dispatch({
                            type: "updateTempPrivateProfileAAddressZip",
                            data: e.currentTarget.value,
                          })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-3-desktop is-6-tablet">
                  <div className="field">
                    <label className="label">Country</label>
                    <div className="control">
                      <Select
                        className="basic-single"
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                        }}
                        defaultValue={
                          state.tempPrivateProfile.homeAddress
                            ? countryListValueLabel.find(
                                element =>
                                  element.value ===
                                  state.tempPrivateProfile.homeAddress.country
                              )
                            : [{ value: "USA", label: "USA" }]
                        }
                        menuPortalTarget={document.body}
                        classNamePrefix="select"
                        isSearchable="true"
                        isClearable="true"
                        onChange={(value, actionMeta) => {
                          if (value)
                            dispatch({
                              type: "updateTempPrivateProfileAAddressCountry",
                              data: value.label,
                            })
                        }}
                        options={countryListValueLabel}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-12">
                  <hr className="hr-branded"></hr>
                </div>
                <div className="column is-12-desktop is-12-tablet">
                  <div className="field">
                    <label className="label">
                      Birthday{" "}
                      <p
                        style={{
                          display: "inline",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginLeft: "15px",
                          // float: "right",
                        }}
                      >
                        <i className="far fa-user-shield"></i> Will not be
                        displayed on your public profile
                      </p>
                    </label>

                    <div className="control">
                      <DatePicker
                        // minDate={moment().subtract(18, "years")}
                        maxDate={moment().subtract(18, "years")._d}
                        style={datePickerStyle}
                        value={getDateSafely(state.tempPrivateProfile.dob)}
                        onChange={d => {
                          console.log(d)
                          dispatch({
                            type: "updateTempPrivateProfileBirthday",
                            data: d,
                          })
                        }}
                      ></DatePicker>
                    </div>
                  </div>
                </div>
                <div className="column is-8"></div>
              </div>
              <div className="columns is-mobile">
                <div className="column is-8-desktop "></div>
                <div className="column is-2-desktop ">
                  <button
                    style={{ width: "100%" }}
                    className="button is-pulled-right"
                    onClick={e => {
                      swal({
                        title: "Are you sure?",
                        text:
                          "You will lose all the information you have entered so far!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: false,
                        buttons: ["No, cancel it!", "Yes, I am sure!"],
                      }).then(willSubmit => {
                        if (willSubmit) navigate("/app/designersignup")
                      })
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="column is-2-desktop ">
                  <button
                    style={{ width: "100%" }}
                    className="button is-warning is-pulled-right"
                    onClick={pushChangesToDB}
                  >
                    Save
                  </button>
                </div>
              </div>

              <hr className="hr-branded"></hr>
            </>
          )}
          {responsiveColumnWrap()}
        </div>
      )}
    </>
  )
}

const AddGeneralInfo = () => {
  return (
    <ToastProvider>
      <ProfileStateProvider>
        <AddGeneralInfoBase />
      </ProfileStateProvider>
    </ToastProvider>
  )
}

export default AddGeneralInfo
