import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react"
import { SingleDatePicker } from "react-dates"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import "./AccommodationDetails.scss"
import Cleave from "cleave.js/react"
import is from "is_js"
import { v4 as uuidv4 } from "uuid"
import Geocode from "react-geocode"
// ...
import { ToastProvider, useToasts } from "react-toast-notifications"
import DatePicker from "react-date-picker"
import moment from "moment"
import { FirebaseContext } from "../../../components/firebase"
import { AuthUserContext } from "../../../components/session"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import { googleMapsURI } from "../../../utility/general"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"

const AccommodationDetails = ({ canEdit = false }) => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [isBusy, setIsBusy] = useState(false)
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const { addToast } = useToasts()
  const [currentAccommodationInfo, setCurrentAccommodationInfo] = useState({
    accommodationName: "",
    accommodationAddress: "",
    accommodationAddressLatLng: "",
    fromDate: "",
    toDate: "",
    fromDateFocus: false,
    toDateFocus: false,
    confirmationNumber: "",
    additionalNotes: "",
    accommodationUid: uuidv4(),
  })

  const [
    showAddNewAccommodationEntry,
    setShowAddNewAccommodationEntry,
  ] = useState(false)
  const [inEditMode, setInEditMode] = useState(9999)

  useEffect(() => {
    console.log({ currentAccommodationInfo: currentAccommodationInfo })
  }, [currentAccommodationInfo])

  useEffect(() => {
    try {
      if (state.currentJobInfo.accommodationDetailsSyncRequired) {
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${tripState.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({
              accommodationDetails: state.currentJobInfo.accommodationDetails,
            })
            .then(() => {
              console.log("document update successful")
            })
            .catch(e => {
              setIsBusy(false)
              console.log(e)
            })
        }
        dispatch({ type: "accommodationDetailsSyncRequired", data: false })
      }
    } catch (error) {}
  }, [state.currentJobInfo.accommodationDetailsSyncRequired])

  var getDaysArray = function(s, e) {
    console.log(s, e)
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d))
    }
    return a
  }

  //Do not use!!
  const addToItinerary = index => {
    try {
      if (
        state.currentJobInfo.accommodationDetails &&
        state.currentJobInfo.accommodationDetails.length > 0
      ) {
        let itemToAdd = state.currentJobInfo.accommodationDetails[index]
        let tripEndDate = Date.parse(state.currentJobInfo.travelDates.end)
        let tripStartDate = Date.parse(state.currentJobInfo.travelDates.start)
        let itemStartDate = Date.parse(itemToAdd.fromDate)
        let itemEndDate = Date.parse(itemToAdd.toDate)
        console.log(tripEndDate, tripStartDate, itemStartDate, itemEndDate)
        var totalTravelDays = Math.round(
          (tripEndDate - tripStartDate) / (24 * 60 * 60 * 1000) // starting from 0
        )
        // New item start date - trip start date is the start
        let startIndex = Math.round(
          (itemStartDate - tripStartDate) / (24 * 60 * 60 * 1000)
        )

        let endIndex = Math.round(
          (itemEndDate - tripStartDate) / (24 * 60 * 60 * 1000)
        )

        if (
          startIndex < 0 ||
          startIndex > totalTravelDays ||
          endIndex < 0 ||
          endIndex > totalTravelDays
        ) {
          addToast(
            "Invalid item date. Please make sure check-in and check-out dates are in the trip travel dates before adding to itinerary.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
        } else {
          let activityToMove = {
            byot: true,
            activityDurationHours: "1",
            activityDescription:
              itemToAdd.confirmationNumber + " " + itemToAdd.additionalNotes,
            activityType: "Accommodation",
            activityAddress: itemToAdd.accommodationAddress,
            activityUid: itemToAdd.accommodationUid,
            activityName: itemToAdd.accommodationName,
          }
          console.log({ activityToMove })
          for (let index = startIndex; index <= endIndex; index++) {
            dispatch({
              type: "addSearchedActivity",
              activeTab: index,
              selectedItem: activityToMove,
            })
          }

          dispatch({ type: "syncSchedule", data: true })
        }

        console.log(totalTravelDays, startIndex, endIndex)
        //
        // dispatch({
        //   type: "addNewActivity",
        //   activeTab: activeTab,
        // })
      } else {
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getFormattedAddressForIframe = (name, address) => {
    try {
      const gpsRegX = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
      var result = gpsRegX.exec(address)
      console.log({ result })
      // let a =
      //   result !== null
      //     ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
      //         address
      //       )}`
      //     : `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
      //         name
      //       )}+${encodeURI(address)}`
      let a =
        result !== null
          ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=12&q=${encodeURI(
              address
            )}`
          : `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=12&q=${encodeURI(
              address
            )}`

      console.log({ a })
      return a
    } catch (err) {
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=12&q=${encodeURI(
        name
      )}+${encodeURI(address)}`
    }
  }
  const showAccommodationDetails = params => {
    try {
      if (
        state.currentJobInfo.accommodationDetails &&
        state.currentJobInfo.accommodationDetails.length > 0
      ) {
        return (
          <>
            {canEdit && (
              <div
                style={{
                  margin: "30px auto",
                  maxWidth: "400px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  onClick={() => {
                    try {
                      setShowAddNewAccommodationEntry(true)
                    } catch (error) {}
                  }}
                  // className="a"
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    textDecorationColor: "#fec600",
                    color: "rgba(74, 74, 74, 0.8)",
                    textDecoration: "none",
                  }}
                >
                  <i className="far fa-plus" style={{ marginRight: "5px" }}></i>{" "}
                  Add another accommodation
                </a>
              </div>
            )}
            {state.currentJobInfo.accommodationDetails.map(
              (item, index, { length }) => {
                if (inEditMode === index) {
                  return <>{newAccommodationEntryForm(true, index)}</>
                } else
                  return (
                    <div key={index} style={{ margin: "10px auto" }}>
                      {canEdit && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "#fec600",
                            marginBottom: "5px",
                          }}
                        >
                          <div
                            style={{ marginRight: "20px", cursor: "pointer" }}
                            onClick={() => {
                              setCurrentAccommodationInfo({
                                ...state.currentJobInfo.accommodationDetails[
                                  index
                                ],
                              })
                              setInEditMode(index)
                            }}
                          >
                            <i className="far fa-pen"></i>
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              ConfirmationModal({
                                onConfirm: i => {
                                  console.log(i)
                                  dispatch({
                                    type: "deleteAccommodationDetailEntry",
                                    index: i,
                                  })
                                  dispatch({
                                    type: "accommodationDetailsSyncRequired",
                                    data: true,
                                  })
                                },
                                sendBackValue: index,
                                title: "Confirmation",
                                text: `Are you sure you want to permanently delete this entry?`,
                              })
                            }}
                          >
                            <i className="far fa-trash-alt"></i>
                          </div>
                        </div>
                      )}
                      <div className="columns">
                        <div className="column is-6-widescreen is-8-desktop is-12-mobile is-12-tablet">
                          <div className="accommodation-details">
                            <div className="accommodation-name">
                              {item.accommodationName}
                            </div>
                            <div className="accommodation-address">
                              <i
                                style={{ color: "#fec600", marginRight: "5px" }}
                                className="far fa-map-marker-alt"
                              ></i>
                              <a
                                href={googleMapsURI(
                                  item.accommodationName +
                                    item.accommodationAddress
                                )}
                                target="_blank"
                              >
                                {item.accommodationAddress}
                              </a>
                            </div>
                            <div className="accommodation-dates-container">
                              <div className="accommodation-dates">
                                <div
                                  style={{
                                    fontWeight: "300",
                                    textTransform: "uppercase",
                                    letterSpacing: "0.5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  check-in
                                </div>
                                {item.fromDate}
                              </div>
                              <i
                                style={{
                                  textAlign: "center",
                                  fontSize: "30px",
                                  color: "#fec600",
                                  margin: "0px 20px",
                                }}
                                className="fal fa-arrows-h"
                              ></i>
                              <div className="accommodation-dates">
                                <div
                                  style={{
                                    fontWeight: "300",
                                    textTransform: "uppercase",
                                    letterSpacing: "0.5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  check-out
                                </div>
                                {item.toDate}
                              </div>
                            </div>
                          </div>

                          <div style={{ display: "flex" }}>
                            <div
                              className="confirm-no"
                              style={{ marginRight: "20px", minWidth: "100px" }}
                            >
                              <span
                                style={{
                                  fontWeight: "300",
                                  textTransform: "uppercase",
                                  letterSpacing: "0.5px",
                                  fontSize: "10px",
                                }}
                              >
                                Confirmation #{" "}
                              </span>
                              <div
                                style={{ fontWeight: "400", fontSize: "14px" }}
                              >
                                {item.confirmationNumber === ""
                                  ? "--"
                                  : item.confirmationNumber}
                              </div>
                            </div>
                            <div className="additional-details">
                              {" "}
                              <span
                                style={{
                                  fontWeight: "300",
                                  textTransform: "uppercase",
                                  letterSpacing: "0.5px",
                                  fontSize: "10px",
                                }}
                              >
                                Notes{" "}
                              </span>
                              <div
                                style={{ fontWeight: "400", fontSize: "14px" }}
                              >
                                {item.additionalNotes === ""
                                  ? "--"
                                  : item.additionalNotes}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="column is-4-desktop is-hidden-mobile  is-6-widescreen">
                          <iframe
                            title="Paris"
                            // src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
                            //   item.name
                            // )}+${encodeURI(item.activityAddress)}`}

                            src={getFormattedAddressForIframe(
                              item.accommodationName,
                              item.accommodationAddress
                            )}
                            width="100%"
                            height="185px"
                            style={{ border: "solid 2px #fec600" }}
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <hr className="hr-branded"></hr>
                    </div>
                  )
              }
            )}
          </>
        )
      } else {
        return canEdit ? (
          <div
            style={{
              margin: "30px auto",
              maxWidth: "400px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontWeight: "600",
                textDecorationColor: "#fec600",
                color: "rgba(74, 74, 74, 0.8)",
                textDecoration: "none",
              }}
              onClick={() => {
                setShowAddNewAccommodationEntry(true)
              }}
              // className="button is-warning"
            >
              <i className="far fa-plus" style={{ marginRight: "5px" }}></i> Add
              an accommodation
            </a>
          </div>
        ) : (
          <p
            style={{
              // fontStyle: "italic",
              fontSize: "14px",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            - No accommodations added yet -
          </p>
        )
      }
    } catch (error) {}
  }

  const clearCurrentAccommodationInfo = () => {
    setCurrentAccommodationInfo({
      accommodationName: "",
      accommodationAddress: "",
      accommodationAddressLatLng: "",
      fromDate: "",
      toDate: "",
      fromDateFocus: false,
      toDateFocus: false,
      confirmationNumber: "",
      additionalNotes: "",
    })
  }

  const geoCodeItemAddress = useCallback(async itemAddress => {
    try {
      Geocode.setApiKey(`${process.env.GOOGLE_MAPS_API_KEY}`)
      // set response language. Defaults to english.
      Geocode.setLanguage("en")
      // set response region. Its optional.
      // A Geocoding request with region=es (Spain) will return the Spanish city.
      Geocode.setRegion("es")
      // set location_type filter . Its optional.
      // google geocoder returns more that one address for given lat/lng.
      // In some case we need one address as response for which google itself provides a location_type filter.
      // So we can easily parse the result for fetching address components
      // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
      // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
      Geocode.setLocationType("APPROXIMATE")
      // Enable or disable logs. Its optional.
      Geocode.enableDebug()
      if (itemAddress) {
        const response = await Geocode.fromAddress(itemAddress)

        if (response) {
          const { lat, lng } = response.results[0].geometry.location
          let latlng = { lat, lng }
          return latlng
        }
      }
    } catch (error) {
      console.log(error)
    }
  })

  const newAccommodationEntryForm = (inEditMode, editedItemIndex) => {
    try {
      return (
        <>
          <div className="field">
            <label className="label">Lodging Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                autoFocus
                tabIndex="1"
                placeholder="e.g. Paris Marriott Champs Elysees Hotel"
                value={currentAccommodationInfo.accommodationName}
                onChange={e => {
                  var a = { ...currentAccommodationInfo }
                  if (e.currentTarget.value) {
                    a["accommodationName"] = e.currentTarget.value
                  } else {
                    a["accommodationName"] = e.currentTarget.value
                  }
                  setCurrentAccommodationInfo({ ...a })
                }}
              ></input>
            </div>
          </div>
          <div className="field">
            <label className="label">Address</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder=""
                value={currentAccommodationInfo.accommodationAddress}
                onChange={e => {
                  var a = { ...currentAccommodationInfo }
                  if (e.currentTarget.value) {
                    a["accommodationAddress"] = e.currentTarget.value
                  } else {
                    a["accommodationAddress"] = e.currentTarget.value
                  }
                  setCurrentAccommodationInfo({ ...a })
                }}
              ></input>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              <label className="label">Check-in</label>
              <div className="control">
                <SingleDatePicker
                  date={
                    currentAccommodationInfo.fromDate != ""
                      ? moment(currentAccommodationInfo.fromDate)
                      : null
                  } // momentPropTypes.momentObj or null
                  numberOfMonths={1}
                  onDateChange={date => {
                    try {
                      console.log(date)
                      var a = currentAccommodationInfo
                      console.log(a)
                      if (moment.isMoment(date)) {
                        a["fromDate"] = moment(date).format("L")
                        setCurrentAccommodationInfo({ ...a })
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }} // PropTypes.func.isRequired
                  readOnly={is.mobile() ? true : false}
                  focused={currentAccommodationInfo.fromDateFocus} // PropTypes.bool
                  onFocusChange={({ focused }) => {
                    var a = { ...currentAccommodationInfo }
                    a["fromDateFocus"] = focused
                    setCurrentAccommodationInfo({ ...a })
                  }} // PropTypes.func.isRequired
                  id="your_unique_1" // PropTypes.string.isRequired,
                />
              </div>
            </div>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              <label className="label">Check-out</label>
              <div className="control">
                <div className="control">
                  <SingleDatePicker
                    date={
                      currentAccommodationInfo.toDate
                        ? moment(currentAccommodationInfo.toDate)
                        : null
                    } // momentPropTypes.momentObj or null
                    numberOfMonths={1}
                    onDateChange={date => {
                      try {
                        var a = currentAccommodationInfo
                        if (moment.isMoment(date)) {
                          a["toDate"] = moment(date).format("L")
                          setCurrentAccommodationInfo({ ...a })
                        }
                      } catch (err) {
                        console.log(err)
                      }
                    }} // PropTypes.func.isRequired
                    readOnly={is.mobile() ? true : false}
                    focused={currentAccommodationInfo.toDateFocus} // PropTypes.bool
                    onFocusChange={({ focused }) => {
                      var a = { ...currentAccommodationInfo }
                      a["toDateFocus"] = focused
                      setCurrentAccommodationInfo({ ...a })
                    }} // PropTypes.func.isRequired
                    id="your_unique_2" // PropTypes.string.isRequired,
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <label className="label">End</label> */}
          <div style={{ display: "flex", alignItems: "flex-start" }}></div>

          <div
            className="field"
            style={{ marginRight: "8px", marginTop: "5px" }}
          >
            <label className="label">Confirmation #</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder=""
                value={currentAccommodationInfo.confirmationNumber}
                onChange={e => {
                  var a = { ...currentAccommodationInfo }

                  a["confirmationNumber"] = e.currentTarget.value
                  setCurrentAccommodationInfo({ ...a })
                }}
              ></input>
            </div>
          </div>
          <div
            className="field"
            style={{ marginRight: "8px", marginTop: "5px" }}
          >
            <label className="label">Additional Notes</label>
            <div className="control">
              <textarea
                value={currentAccommodationInfo.additionalNotes}
                rows="2"
                className="textarea"
                type="text"
                placeholder=""
                maxLength="100"
                onChange={e => {
                  var a = { ...currentAccommodationInfo }
                  console.log(e.currentTarget.value)
                  a["additionalNotes"] = e.currentTarget.value
                  setCurrentAccommodationInfo({ ...a })
                }}
              ></textarea>
              <p className={`help is-pulled-right`}>
                {/* Characters:{" "} */}
                {currentAccommodationInfo.additionalNotes
                  ? currentAccommodationInfo.additionalNotes.length
                  : "0"}
                /{100}
              </p>
            </div>
          </div>
          <div
            className="field is-grouped is-grouped-right"
            style={{ marginTop: "40px" }}
          >
            <div className="control">
              <button
                onClick={() => {
                  clearCurrentAccommodationInfo()
                  setShowAddNewAccommodationEntry(false)
                  setInEditMode(9999)
                }}
                className="button is-warning is-light"
              >
                Cancel
              </button>
            </div>
            <div className="control">
              <button
                class={`${
                  isBusy ? "button is-warning is-loading" : "button is-warning"
                }`}
                onClick={async () => {
                  if (isBusy) return
                  setIsBusy(true)
                  //geocode
                  if (currentAccommodationInfo.accommodationAddress) {
                    let latlng = await geoCodeItemAddress(
                      currentAccommodationInfo.accommodationAddress
                    )
                    if (latlng) {
                      var a = currentAccommodationInfo
                      if (latlng) {
                        a["accommodationAddressLatLng"] = latlng
                        setCurrentAccommodationInfo({ ...a })
                      }
                    }
                    console.log(latlng)
                  }
                  if (inEditMode) {
                    dispatch({
                      type: "saveAccommodationEdits",
                      data: currentAccommodationInfo,
                      index: editedItemIndex,
                    })
                  } else {
                    var a = { ...currentAccommodationInfo }

                    a["accommodationUid"] = uuidv4()
                    setCurrentAccommodationInfo({ ...a })
                    dispatch({
                      type: "addNewAccommodationDetail",
                      data: currentAccommodationInfo,
                    })
                  }

                  clearCurrentAccommodationInfo()
                  setShowAddNewAccommodationEntry(false)

                  dispatch({
                    type: "accommodationDetailsSyncRequired",
                    data: true,
                  })
                  setIsBusy(false)
                  setInEditMode(9999)

                  // if (dbContext) {
                  //   dbContext
                  //     .firestore()
                  //     .collection("jobs")
                  //     .doc(`${authContext.uid}`)
                  //     .collection("active-jobs")
                  //     .doc(`${state.currentJobInfo.jobUid}`)
                  //     .update({
                  //       accommodationDetails: dbContext.firestore.FieldValue.arrayUnion(
                  //         currentAccommodationInfo
                  //       ),
                  //     })
                  //     .then(() => {
                  //       console.log("document update successful")
                  //       //update local state now

                  //     })
                  //     .catch(e => {
                  //       setIsBusy(false)
                  //       console.log(e)
                  //     })
                  // }
                }}
              >
                {inEditMode ? "Save Changes" : "Save"}
              </button>
            </div>
          </div>
          <hr className="hr-branded"></hr>
        </>
      )
    } catch (error) {}
  }

  return (
    <div>
      <div className="columns">
        <div className="column is-2-desktop is-0-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-12-tablet is-12-mobile">
          <p
            // style={{
            //   fontSize: "34px",
            //   fontWeight: "800",
            //   color: "rgba(74,74,74,0.8)",
            //   margin: "20px auto",
            //   lineHeight: "1.4",
            //   textAlign: "center",
            // }}
            className="tab-header"
          >
            Accommodation Details<span style={{ color: "#fec600" }}>.</span>
          </p>
          <p className="tab-tagline">
            Your accommodation during your trip.
            <span style={{ fontWeight: "500" }}> TIP:</span> Your check-in &
            check-out events would show up on your daily itinerary.
          </p>
          {!showAddNewAccommodationEntry && showAccommodationDetails()}
          {showAddNewAccommodationEntry && newAccommodationEntryForm(false, 0)}
        </div>
        <div className="column is-2-desktop is-0-tablet is-0-mobile"></div>
      </div>
    </div>
  )
}

export default AccommodationDetails
