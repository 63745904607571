import React, { useEffect } from "react"
import { daysRemaining, calculateProgress } from "../../utility/general"
import "./TripCardHorizontalClean.scss"

const TripCardHorizontalClean = ({
  tripId,
  tripName,
  tripDates,
  invokeTripDetails,
  invokeAcceptJob,
  invokeDeclineJob,
  travelerId,
  showAcceptDecline,
  jobUid,
  sample = true,
  activeOn,
  changesRequestedOn,
  status,
}) => {
  useEffect(() => {
    try {
      console.log(changesRequestedOn.toDate())
    } catch (err) {}
  }, [changesRequestedOn])
  const maxDesignDays = 7 // This needs to be made configurable later on.
  const maxReDesignDays = 3
  // const daysRemaining = (startDate, max) => {
  //   try {
  //     // if (activeOn) {
  //     var a = startDate.toDate()
  //     // var a = activeOn.toDate()
  //     var today = new Date()
  //     return Math.round(
  //       (a.setDate(a.getDate() + max) - today) / (24 * 60 * 60 * 1000)
  //     )
  //     // } else return 0
  //   } catch (err) {
  //     return 0
  //   }
  // }

  // const calculateProgress = (startDate, max) => {
  //   try {
  //     // if (activeOn) {
  //     var a = startDate.toDate()
  //     var today = new Date()
  //     var totalDays = Math.round(
  //       (a.setDate(a.getDate() + max) - today) / (24 * 60 * 60 * 1000)
  //     )
  //     // setElapsedDays(totalDays)
  //     var percent = Math.round((totalDays / max) * 100)
  //     return percent
  //     // } else return 0
  //   } catch (err) {
  //     return 0
  //   }
  // }

  const displayMessage = params => {
    switch (params) {
      case "review":
        return "Travel plan is in review"
      case "review-complete":
        return "Finalize travel plan"
      case "request-changes":
        return "Plan changes requested"
      case "active":
        return "Design in-progress"
      case "pending":
        return "Pending your approval"
      case "complete":
        return "Task completed"
        // case "payment-pending":
        //   return "Awaiting traveler payment"
        break

      default:
        break
    }
  }

  return (
    <React.Fragment>
      <div
        className="trip-card-clean"
        id={tripId}
        name={travelerId}
        value={jobUid}
        onClick={e => invokeTripDetails(e, travelerId, jobUid)}
      >
        {/* <div className="expiration-warning">
          <i className="fas fa-exclamation"></i>
        </div> */}

        {/* <img
              className="custom-card-image-h"
              src="https://www.fodors.com/wp-content/uploads/2018/10/HERO_UltimateRome_Hero_shutterstock789412159.jpg"
              alt=""
            /> */}
        {/* <img
            className="custom-card-image-h"
            src={`https://picsum.photos/id/${Math.round(
              Math.random() * 1000
            )}/117/86`}
            alt=""
          /> */}
        {sample && <div className="showSample">Sample Plan</div>}
        <div className="trip-card-clean-data">
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <p className="place">{tripName.place}</p>
            {/* <span style={{ color: "#fec600", fontWeight: "700" }}>-</span>
            <p className="country">{destination.country}</p> */}
          </div>

          {/* <p className="country">{tripName.country}</p> */}
          <p className="date" style={{ marginTop: "0px" }}>
            {tripDates}
          </p>
          {/* <p className="trip-card-clean-title">
            {tripName !== undefined
              ? tripName.length > 15
                ? tripName.substring(0, 14) + " ..."
                : tripName
              : ""}
          </p> */}
          {/* <p className="date">{tripDates}</p> */}
          {status === "active" ||
          // status === "review" ||
          status === "review-complete"
            ? activeOn && (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <p
                    className=""
                    style={{
                      fontSize: "11px",
                      textAlign: "right",
                      position: "absolute",
                      right: "5px",
                      bottom: "9px",
                    }}
                  >
                    {/* {maxDesignDays - (daysRemaining() < 0 ? 0 : daysRemaining())} /{" "}
                {maxDesignDays} Days */}
                    {daysRemaining(activeOn, maxDesignDays) < 0
                      ? "Late"
                      : daysRemaining(activeOn, maxDesignDays) === 0
                      ? `Due today`
                      : `Due in ${daysRemaining(activeOn, maxDesignDays)} days`}
                    {/* {100 - calculateProgress()} % */}
                  </p>
                  <progress
                    className="progress is-warning is-thin"
                    value={100 - calculateProgress(activeOn, maxDesignDays)}
                    // value={20}
                    style={{
                      marginTop: "15px",
                      borderRadius: "0px",
                      width: "98%",
                      border: "0.5px solid #fec600 !important",
                    }}
                    max="100"
                  >
                    15%
                  </progress>
                </div>
              )
            : ""}
          {status === "request-changes" && changesRequestedOn ? (
            <div
              style={{
                position: "relative",
              }}
            >
              <p
                className=""
                style={{
                  fontSize: "11px",
                  textAlign: "right",
                  position: "absolute",
                  right: "5px",
                  bottom: "9px",
                }}
              >
                {/* {maxDesignDays - (daysRemaining() < 0 ? 0 : daysRemaining())} /{" "}
                {maxDesignDays} Days */}
                {daysRemaining(changesRequestedOn, maxReDesignDays) < 0
                  ? "Late"
                  : daysRemaining(changesRequestedOn, maxReDesignDays) === 0
                  ? `Due today`
                  : `Due in ${daysRemaining(
                      changesRequestedOn,
                      maxReDesignDays
                    )} days`}
                {/* {100 - calculateProgress()} % */}
              </p>
              <progress
                className="progress is-warning is-thin"
                value={
                  100 - calculateProgress(changesRequestedOn, maxReDesignDays)
                }
                // value={20}
                style={{
                  marginTop: "15px",
                  borderRadius: "0px",
                  width: "98%",
                  border: "0.5px solid #fec600 !important",
                }}
                max="100"
              >
                15%
              </progress>
            </div>
          ) : (
            ""
          )}

          <p
            className=""
            style={{
              fontSize: "12px",
              marginTop: "5px",
              // textAlign: "right",
              // position: "absolute",
              // right: "7px",
              // bottom: "12px",
            }}
          >
            Status: {displayMessage(status)}
          </p>
        </div>

        {/* <div className="custom-card-data-h"> */}
        {/* <p className="custom-card-title-h">
              {tripName.place + ", " + tripName.country}
            </p> */}
        {/* <p className="custom-card-date-h">{tripDates}</p> */}
        {/* {showAcceptDecline && ( */}
        {/* <div */}
        {/* className="" */}
        {/* style={{ */}
        {/* borderTop: "1px solid #fec600", */}
        {/* paddingTop: "0px", */}
        {/* // textAlign: "center", */}
        {/* }} */}
        {/* > */}
        {/* {showAcceptDecline && (
                <a
                  className="hover-link"
                  id={tripId}
                  name={travelerId}
                  onClick={e => {
                    const a = e.currentTarget.id
                    swal({
                      title: "Confirmation",
                      text: "Are you sure you want to decline this job?",
                      //  icon: "question",
                      buttons: true,
                      dangerMode: true,
                      buttons: ["No, cancel it!", "Yes, I decline the job!"],
                    }).then(willDelete => {
                      if (willDelete) {
                        invokeDeclineJob(a)
                        console.log(a)
                        swal("Job removed from your queue!", {
                          icon: "success",
                        })
                      }
                    })
                  }}
                >
                  Decline
                </a>
              )} */}
        {/* {showAcceptDecline && (
                <a
                  className="hover-link"
                  id={tripId}
                  name={travelerId}
                  style={{ marginLeft: "30px" }}
                  onClick={async e => {
                    const a = e.currentTarget.id
                    const proceed = await swal({
                      title: "Confirmation",
                      text: "Are you sure you want to accept this job?",
                      // icon: "question",
                      buttons: true,
                      dangerMode: false,
                      buttons: ["No, cancel it!", "Yes, I accept the job!"],
                    })

                    if (proceed) {
                      invokeAcceptJob(a)
                      console.log(a)
                      swal("Great! traveler will be notified thank you!", {
                        icon: "success",
                      })
                    }
                  }}
                >
                  Accept
                </a>
              )} */}
        {/*
            <a
              className="hover-link"
              id={tripId}
              name={travelerId}
              onClick={e => invokeTripDetails(e)}
              style={{ marginLeft: "30px" }}
            >
              Details
            </a> */}
        {/* </div> */}
        {/* )} */}
        {/* </div> */}

        {/* <div className="avatar-container-h">
        <div className="avatars">
          <span className="avatar">
            <img
              src="https://www.fillmurray.com/35/35"
              width="35"
              height="35"
            />
          </span>
          <span className="avatar">
            <img
              src="https://www.fillmurray.com/100/100"
              width="35"
              height="35"
            />
          </span>
          <span className="avatar">
            <img
              src="https://www.fillmurray.com/200/200"
              width="35"
              height="35"
            />
          </span>
        </div>
      </div>

      <p className="traveler-count">4 People</p> */}
      </div>
    </React.Fragment>
  )
}

export default TripCardHorizontalClean
