import React, { useState, useEffect } from "react"
import "./AccountInfoContainer.scss"
import PersonalInfoContainer from "./PersonalInformation/PersonalInfoContainer"
import TravelPreferencesContainer from "./TravelPreferences/TravelPreferencesContainer"
import PaymentMethodsContainer from "./PaymentMethods/PaymentMethodsContainer"

const AccountInfoContainer = () => {
  const [PersonalInfoTab, setPersonalInfoTab] = useState(false)
  const [TravelPreferencesTab, setTravelPreferencesTab] = useState(false)
  const [PaymentMethodsTab, setPaymentMethods] = useState(false)
  const [sessionRole, setSessionRole] = useState("traveler")
  React.useEffect(() => {
    document.title = "My account | voyayge"
  }, [])

  useEffect(() => {
    //default active tab
    setPersonalInfoTab(true)
    setTravelPreferencesTab(false)
    setPaymentMethods(false)
    var role = JSON.parse(localStorage.getItem("sessionType"))
    console.log({ role })
    setSessionRole(role.sessionType)
  }, [])

  return (
    <>
      <div className="columns">
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-10-desktop is-10-tablet">
          <div>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "700",
                borderBottom: "2px solid #fec600",
              }}
            >
              Your Account
            </p>
          </div>
        </div>
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
      </div>
      <div className="columns">
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-10-desktop is-10-tablet">
          <div>
            <div className="tabs is-centered">
              <ul>
                <li className={`${PersonalInfoTab ? "is-active" : ""}`}>
                  <a
                    onClick={() => {
                      setPersonalInfoTab(true)
                      setTravelPreferencesTab(false)
                      setPaymentMethods(false)
                    }}
                  >
                    Personal Info
                  </a>
                </li>
                {sessionRole === "designer" && (
                  <>
                    <li className={`${PaymentMethodsTab ? "is-active" : ""}`}>
                      <a
                        onClick={() => {
                          setPersonalInfoTab(false)
                          setTravelPreferencesTab(false)
                          setPaymentMethods(true)
                        }}
                      >
                        Payment Info
                      </a>
                    </li>
                  </>
                )}

                {/* <li className={`${PaymentMethodsTab ? "is-active" : ""}`}>
            <a
              onClick={() => {
                setPersonalInfoTab(false)
                setTravelPreferencesTab(false)
                setPaymentMethods(true)
              }}
            >
              Payment Methods
            </a>
          </li> */}
              </ul>
            </div>
            {PersonalInfoTab ? <PersonalInfoContainer /> : ""}
            {TravelPreferencesTab ? <TravelPreferencesContainer /> : ""}
            {PaymentMethodsTab ? <PaymentMethodsContainer /> : ""}
          </div>
        </div>
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
      </div>
    </>
  )
}

export default AccountInfoContainer
