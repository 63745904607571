import React, { useState, useEffect } from "react"
import logo from "../../images/logo.png"
import thankq from "./../../images/thankq.png"
import errorImg from "./../../images/error.png"
import love from "./../../images/love-comment.jpg"
import wiggle from "./../../images/wiggle.svg"
import { navigate } from "gatsby"
import "./feedbackform.scss"
import { functions } from "../firebase/firebase"
import ScoreItem from "./ScoreItem"
import SEO from "../seo"
const FeedbackForm = ({ feedbackId }) => {
  const [whatWorkedText, setWhatWorkedText] = useState("")
  const [whatWorkedTextLen, setWhatWorkedTextLen] = useState(0)
  const [whatDidntWorkText, setWhatDidntWorkText] = useState("")
  const [whatDidntWorkTextLen, setWhatDidntWorkTextLen] = useState(0)
  const [npsScore, setNpsScore] = useState(0)
  const [screenLocationState, setScreenLocationState] = useState("score")
  const [submissionState, setSubmissionState] = useState("none")
  const [busy, setBusy] = useState(false)
  const [itineraryScore, setItineraryScore] = useState(-1)
  const [accommodationScore, setAccommodationScore] = useState(-1)
  const [reservationsScore, setReservationsScore] = useState(-1)
  const [destinationInfoScore, setDestinationInfoScore] = useState(-1)
  const [checklistScore, setChecklistScore] = useState(-1)
  const [offlinePlanScore, setOfflinePlanScore] = useState(-1)
  useEffect(() => {
    setWhatWorkedTextLen(whatWorkedText.length)
  }, [whatWorkedText])

  useEffect(() => {
    setWhatDidntWorkTextLen(whatDidntWorkText.length)
  }, [whatDidntWorkText])

  const pushFeedback = () => {
    try {
      setBusy(true)
      let data = {
        feedback: {
          score: npsScore,
          good: whatWorkedText,
          bad: whatDidntWorkText,
          itineraryScore: itineraryScore,
          accommodationScore: accommodationScore,
          reservationsScore: reservationsScore,
          destinationInfoScore: destinationInfoScore,
          checklistScore: checklistScore,
          offlinePlanScore: offlinePlanScore,
        },
        id: feedbackId,
        date: new Date(),
      }
      var saveFeedback = functions.httpsCallable("saveFeedback")
      saveFeedback(data)
        .then(function(result) {
          // Read result of the Cloud Function.
          setBusy(false)
          console.log({ result })
          if (result.data.success) {
            window.scrollTo(0, 0)
            setSubmissionState("success")
          } else {
            window.scrollTo(0, 0)
            setSubmissionState("error")
          }
          // ...
        })
        .catch(err => {
          console.log(err)
          setBusy(false)
          window.scrollTo(0, 0)
          setSubmissionState("error")
        })
    } catch (err) {
      console.log(err)
      setBusy(false)
      window.scrollTo(0, 0)
      setSubmissionState("error")
    }
  }
  const showFormState = () => {
    // window.scrollTo(0, 0)
    switch (submissionState) {
      case "none":
        return (
          <>
            <div>
              <div style={{ display: "" }}>
                <p className="feedback-header-primary">
                  Hello<span style={{ color: "#fec600" }}>.</span>
                </p>{" "}
                <p className="feedback-header-secondary">
                  Your Opinion Counts{" "}
                  <img
                    style={{
                      width: "34px",
                      marginLeft: "-10px",
                      marginBottom: "12px",
                    }}
                    src={love}
                  ></img>
                </p>{" "}
              </div>
              <p className="feedback-preamble">
                We want to be sure you are delighted with your experience with
                <strong
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  {" "}
                  vo<span style={{ color: "#fec600" }}>yay!</span>ge
                </strong>
                . To help provide you with the best experience possible, we'd
                like your help with this very brief survey:{" "}
              </p>
            </div>
            <hr className="hr-branded"></hr>
            <div
              style={{ marginTop: "35px", marginBottom: "35px" }}
              className="fade-in"
            >
              {displayRightScreen()}
            </div>
            <hr className="hr-branded"></hr>
            <div>
              <p className="feedback-text" style={{ marginBottom: "15px" }}>
                Your candid feedback is appreciated, and we read every single
                comment.
              </p>
              <p className="feedback-text">Cheers,</p>
              <p className="feedback-text">
                The{" "}
                <strong
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  {" "}
                  vo<span style={{ color: "#fec600" }}>yay!</span>ge
                </strong>{" "}
                team,
              </p>
            </div>
          </>
        )

      case "error":
        return (
          <>
            <div style={{ margin: "20px auto", maxWidth: "300px" }}>
              <img
                src={errorImg}
                alt="error!"
                style={{
                  padding: "10px",
                  // maxHeight: "300px",
                  width: "100%",
                }}
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                margin: "15px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Uh oh!, there was an error submitting your feedback. Please try
              again in sometime. Sorry! <br></br>{" "}
              {/* <button className="button">Retry</button> */}
              <small>
                If the issue persists please email to{" "}
                <a href="mailto: support@voyayge.com">support@voyayge.com</a>
              </small>
            </p>
          </>
        )
      case "success":
        return (
          <>
            <div style={{ maxWidth: "400px", margin: "0px auto" }}>
              <img src={thankq} alt="thank you" />
            </div>
            <p
              style={{
                fontSize: "22px",
                margin: "0px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              We received your feedback!
            </p>
          </>
        )

      default:
        break
    }
  }

  const displayRightScreen = () => {
    // window.scrollTo(0, 0)
    switch (screenLocationState) {
      case "score":
        return (
          <>
            <div>
              <p className="feedback-text fade-in">
                How likely is it that you would use{" "}
                <strong
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                  }}
                >
                  {" "}
                  vo<span style={{ color: "#fec600" }}>yay!</span>ge
                </strong>{" "}
                for your future travel plans?
              </p>
              <div className={"feedback-button-container"} style={{}}>
                <button
                  className="customButtonTextNotLikely button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(0)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                ></button>
                <button
                  className="button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(1)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                >
                  1
                </button>
                <button
                  className="button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(2)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                >
                  2
                </button>
                <button
                  className="button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(3)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                >
                  3
                </button>
                <button
                  className="button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(4)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                >
                  4
                </button>
                <button
                  className="button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(5)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                >
                  5
                </button>
                <button
                  className="button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(6)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                >
                  6
                </button>
                <button
                  className="button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(7)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                >
                  7
                </button>
                <button
                  className="button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(8)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                >
                  8
                </button>
                <button
                  className="button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(9)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                >
                  9
                </button>
                <button
                  className="customButtonTextLikely button feedback-score-button is-warning is-outlined"
                  onClick={() => {
                    setNpsScore(10)
                    setScreenLocationState("additional")
                    window.scrollTo(0, 0)
                  }}
                ></button>
              </div>
            </div>{" "}
          </>
        )
      case "features":
        return (
          <div>
            <p style={{ fontWeight: "600", marginBottom: "15px" }}>
              How valuable did you find the following features of your travel
              plan?
            </p>
            <ScoreItem
              question="Daily itinerary:"
              featureScored={s => {
                setItineraryScore(s)
              }}
            />
            {/* <ScoreItem
              question="Reservations:"
              featureScored={s => {
                setReservationsScore(s)
              }}
            /> */}
            <ScoreItem
              question="Accommodation recommendations:"
              featureScored={s => {
                setAccommodationScore(s)
              }}
            />
            <ScoreItem
              question="Destination Info:"
              featureScored={s => {
                setDestinationInfoScore(s)
              }}
            />
            <ScoreItem
              question="Travel checklist:"
              featureScored={s => {
                setChecklistScore(s)
              }}
            />
            <ScoreItem
              question="Having an offline travel plan:"
              featureScored={s => {
                setOfflinePlanScore(s)
              }}
            />
          </div>
        )
      case "additional":
        return (
          <>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="fade-in"
            >
              <p
                className="feedback-header"
                style={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  fontSize: "14px",
                }}
              >
                You gave a score of
              </p>
              <p
                className="feedback-header"
                style={{
                  textAlign: "center",
                  paddingBottom: "5px",
                  fontSize: "40px",
                  fontWeight: "600",
                }}
              >
                {npsScore}
              </p>
              <div
                style={{
                  margin: "0px auto",
                  width: "150px",
                  // marginBottom: "30px",
                }}
              >
                <a
                  className="button is-warning"
                  href=""
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    textTransform: "uppercase",
                    //   textDecoration: "underline",
                    letterSpacing: "1px",
                    width: "100%",
                  }}
                  onClick={e => {
                    setScreenLocationState("score")
                    e.preventDefault()
                  }}
                >
                  Change score
                </a>
              </div>
              <div
                style={{
                  margin: "10px auto",
                  width: "50px",
                  marginBottom: "30px",
                }}
              >
                <img src={wiggle} alt="" />
              </div>
              <div>
                <p
                  style={{
                    // fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  How valuable did you find the following features of your
                  travel plan?
                </p>
                <ScoreItem
                  question="Daily itinerary:"
                  featureScored={s => {
                    setItineraryScore(s)
                  }}
                />
                {/* <ScoreItem
                  question="Reservation:"
                  featureScored={s => {
                    setReservationsScore(s)
                  }}
                /> */}
                <ScoreItem
                  question="Accommodation recommendations:"
                  featureScored={s => {
                    setAccommodationScore(s)
                  }}
                />
                <ScoreItem
                  question="Destination Info:"
                  featureScored={s => {
                    setDestinationInfoScore(s)
                  }}
                />
                <ScoreItem
                  question="Travel checklist:"
                  featureScored={s => {
                    setChecklistScore(s)
                  }}
                />
                <ScoreItem
                  question="Having an offline travel plan:"
                  featureScored={s => {
                    setOfflinePlanScore(s)
                  }}
                />
              </div>
              <div style={{ marginTop: "35px" }}>
                <div className="field">
                  <label className="label">What worked for you?</label>
                  <div className="control is-expanded">
                    <textarea
                      onChange={e => {
                        if (e.currentTarget.value.length <= 500)
                          setWhatWorkedText(e.currentTarget.value)
                      }}
                      value={whatWorkedText}
                      className="textarea"
                      placeholder=""
                    ></textarea>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="help"></p>
                      <p
                        className={`help is-pulled-right ${
                          whatWorkedTextLen >= 500 ? "is-danger" : ""
                        }`}
                      >
                        Characters: {whatWorkedTextLen}/500
                      </p>
                    </div>
                    {/* {errors.accommodation ? (
                   <p className="help is-danger">
                     Please pick you accommodation preference
                   </p>
                 ) : (
                   <p className="help">pick all that apply</p>
                 )} */}
                  </div>
                </div>
                <div className="field">
                  <label className="label">How could we improve?</label>
                  <div className="control is-expanded">
                    <textarea
                      onChange={e => {
                        if (e.currentTarget.value.length <= 500)
                          setWhatDidntWorkText(e.currentTarget.value)
                      }}
                      value={whatDidntWorkText}
                      className="textarea"
                      placeholder=""
                    ></textarea>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="help"></p>
                      <p
                        className={`help is-pulled-right ${
                          whatDidntWorkTextLen >= 500 ? "is-danger" : ""
                        }`}
                      >
                        Characters: {whatDidntWorkTextLen}/500
                      </p>
                    </div>
                    {/* {errors.accommodation ? (
                   <p className="help is-danger">
                     Please pick you accommodation preference
                   </p>
                 ) : (
                   <p className="help">pick all that apply</p>
                 )} */}
                  </div>
                </div>
                <div
                  className="field  is-pulled-right"
                  style={{ marginTop: "15px" }}
                >
                  <button
                    className={`${
                      busy
                        ? "button is-warning is-pulled-right is-loading"
                        : "button is-warning is-pulled-right"
                    }`}
                    onClick={e => {
                      pushFeedback()
                      //   setScreenLocationState("thanks")
                      e.preventDefault()
                    }}
                  >
                    Complete Survey
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      case "thanks":
        return <></>

      default:
        break
    }
  }

  return (
    <>
      <SEO pageTitle="Feedback" title="voyayge.com" />
      <div className="columns" style={{ paddingTop: "25px" }}>
        <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
        <div className="column is-6-desktop is-8-tablet is-12-mobile">
          <div
            style={{
              marginBottom: "45px",
              maxWidth: "350px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {/* <img
            src={logo}
            style={{
              width: "100%",
            }}
            onClick={() => {
              navigate("/")
            }}
          ></img> */}
          </div>
          {showFormState()}
        </div>
        <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
      </div>
      <hr className="hr-branded"></hr>
    </>
  )
}

export default FeedbackForm
