import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import { useToasts } from "react-toast-notifications"
import { FirebaseContext } from "../../../components/firebase"
import { analytics } from "../../../components/firebase/firebase"
import AuthUserContext from "../../../components/session/context"
import {
  activityType,
  durationHours,
  durationMinutes,
} from "../../../constants/data/general"
import useRestrictCharacterLength from "../../../hooks/useRestrictCharacterLength"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import { titleCase } from "../../../utility/general"

// const ReactQuill = React.lazy(() => import("react-quill"))
export const BYOTActivityForm = ({
  onCancel,
  activeTab,
  onActivityCreated,
  inEditMode,
  addToItinerary,
  addToWishlist = false,
}) => {
  const [state, dispatch] = useDashboardStateStore()
  const dbContext = React.useContext(FirebaseContext)
  const authContext = React.useContext(AuthUserContext)
  const { addToast } = useToasts()
  const [placeValue, setPlaceValue] = useState({
    inputValue: "",
    value: [],
  })
  const {
    register,
    handleSubmit,
    triggerValidation,
    watch,
    errors,
    clearError,
  } = useForm()
  const maxTagsAllowed = 4
  const [activityTypeError, setActivityTypeError] = useState(false)
  const [activityDurationError, setActivityDurationError] = useState(false)
  const [
    reachedDescriptionMaxLength,
    maxDescriptionCharactersAllowed,
    checkDescription,
  ] = useRestrictCharacterLength(750)
  const [
    reachedInfoMaxLength,
    maxInfoCharactersAllowed,
    checkInfo,
  ] = useRestrictCharacterLength(750)
  const [maxTagsError, setMaxTagsError] = useState(false)
  const [showMaxTagsError, setShowMaxTagsError] = useState(false)
  // const [activityTypeValidation, setActivityTypeValidation] = useState({
  //   error: false,
  //   msg: "",
  // })

  useEffect(() => {
    if (!inEditMode) dispatch({ type: "createActivityUid" })
    else console.log("Launched in edit mode")

    dispatch({ type: "setActivityTypeBYOT" })
  }, [])
  useEffect(() => {
    if (placeValue.value) {
      console.log(placeValue.value.length)
      if (placeValue.value.length <= maxTagsAllowed) {
        setMaxTagsError(false)
        setShowMaxTagsError(false)
      } else setMaxTagsError(true)
    }
  }, [placeValue])

  useEffect(() => {
    try {
      console.log("%c Dashboard-State", "color: #FC440F; font-size: 18px", {
        state,
      })
      if (state.activityScratchPad.activityTags) {
        let a = []
        state.activityScratchPad.activityTags.map(value => {
          if (value !== undefined) a.push(createOption(value))
        })
        setPlaceValue({ inputValue: "", value: a })
      }
    } catch (err) {
      console.log(err)
    }
    // fetchUserProfileImage()
  }, [state])

  const isActivityTypeInvalid = () => {
    if (state.activityScratchPad.activityType === undefined) return true
    else return false
  }

  const getActivityTypeInfo = activityType => {
    try {
      if (activityType !== "") {
        if (dbContext) {
          dbContext
            .firestore()
            .collection("activityTypeDefaults")
            .doc(`${activityType}`)
            .get()
            .then(doc => {
              console.log(doc.data())
              if (doc && doc.data()) {
                console.log(doc.data())
                dispatch({ type: "updateActivityImage", data: doc.data() })
              } else {
              }
              // console.log("Document successfully updated!");
              // clearCurrentAttraction();
            })
            .catch(error => {
              console.log("Error getting document:", error)
            })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const isActivityDurationInvalid = () => {
    if (state.activityScratchPad.activityType === "Accommodation") return false
    if (state.activityScratchPad.activityType === "Waypoint") return false
    if (
      state.activityScratchPad.activityDurationHours === undefined &&
      state.activityScratchPad.activityDurationMinutes === undefined
    )
      return true
    else {
      if (
        state.activityScratchPad.activityDurationHours === "0" &&
        state.activityScratchPad.activityDurationMinutes === "0"
      )
        return true
      else return false
    }
  }

  const createOption = label => ({
    label: label.toLowerCase(),
    value: label.toLowerCase(),
  })

  const handleChange = (value, actionMeta) => {
    console.log(value, actionMeta)
    setPlaceValue({ ...placeValue, value: value })
  }

  const handleInputChange = inputValue => {
    console.log({ inputValue })
    setPlaceValue({ ...placeValue, inputValue: inputValue })
  }
  const handleKeyDown = event => {
    const { inputValue, value } = placeValue
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (!maxTagsError) {
          setPlaceValue({
            ...placeValue,
            inputValue: "",
            value: [...(value || []), createOption(inputValue)],
          })
        } else {
          setShowMaxTagsError(true)
        }
        event.preventDefault()
        return

      default:
        return
    }
  }

  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      // ["link", "image"],
      // ["clean"],
    ],
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    //  "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    // "link",
    // "image",
  ]

  return (
    <div style={{ width: "100%" }} draggable="false">
      {/* <h3
        align="center"
        style={{ marginTop: "26px" }}
        className="subtitle is-4"
      >
        {inEditMode ? "Edit Activity" : "New Activity"}
      </h3> */}
      <div className="columns">
        <div className="column is-8">
          {/* Activity Name */}
          <div className="field">
            <label className="label">
              Activity Name{" "}
              <p
                style={{
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: "400",
                  float: "right",
                }}
              >
                required
              </p>
            </label>
            <div className="control">
              <input
                ref={register({ required: true })}
                name="activity_name"
                className="input"
                type="text"
                autoFocus
                value={state.activityScratchPad.activityName}
                onChange={e =>
                  dispatch({
                    type: "updateActivityName",
                    data: e.currentTarget.value,
                  })
                }
                onFocus={() => clearError("activity_name")}
              />
              {errors.activity_name ? (
                <p className="help is-danger">
                  {" "}
                  <i
                    className="far fa-exclamation-triangle"
                    style={{ marginRight: "2px" }}
                  ></i>{" "}
                  This field is required
                </p>
              ) : (
                <></>
                // <p className="help">
                //   Note: Please do not use CITY or COUNTRY as a prefix to the
                //   Activity Name. This effects the visibility of this activity to the
                //   traveler
                // </p>
              )}
            </div>
          </div>
        </div>
        <div className="column is-4">
          {/* Activity Type */}
          <div className="field">
            <label className="label">
              Activity Type{" "}
              <p
                style={{
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: "400",
                  float: "right",
                }}
              >
                required
              </p>
            </label>{" "}
            <div className="control">
              <Select
                className="basic-single"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                value={{
                  value: `${state.activityScratchPad.activityType}`,
                  label: `${titleCase(state.activityScratchPad.activityType)}`,
                }}
                menuPortalTarget={document.body}
                classNamePrefix="select"
                isSearchable="true"
                // isClearable="true"
                onChange={(value, actionMeta) => {
                  if (value)
                    dispatch({ type: "updateActivityType", data: value.value })
                  else dispatch({ type: "updateActivityType", data: "" })

                  if (value.value) getActivityTypeInfo(value.value)
                }}
                options={activityType}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#fef0bf",
                    primary50: "#fee27f",
                    primary: "#fed43f",

                    //neutral0: "#424242",
                  },
                })}
                onFocus={() => setActivityTypeError(false)}
              />
              {activityTypeError && (
                <p className="help is-danger">
                  {" "}
                  <i
                    className="far fa-exclamation-triangle"
                    style={{ marginRight: "2px" }}
                  ></i>{" "}
                  This field is required
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-8">
          {/* Activity Address */}
          <div className="field">
            <label className="label">
              Address:{" "}
              <span style={{ fontSize: "10px", fontWeight: "400" }}>
                {" "}
                Physical Address, Plus Code or GPS coordinates
              </span>
              <p
                style={{
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: "400",
                  float: "right",
                }}
              >
                required
              </p>
            </label>
            <div className="control">
              <input
                ref={register({ required: true })}
                name="address"
                className="input"
                type="text"
                value={state.activityScratchPad.activityAddress}
                onChange={e =>
                  dispatch({
                    type: "updateActivityAddress",
                    data: e.currentTarget.value,
                  })
                }
                onFocus={() => clearError("address")}
              />
              {errors.address && (
                <p className="help is-danger">
                  {" "}
                  <i
                    className="far fa-exclamation-triangle"
                    style={{ marginRight: "2px" }}
                  ></i>{" "}
                  This field is required
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="column is-4">
          {/* Activity Phone */}
          {state.activityScratchPad.activityType !== "Waypoint" ? (
            <>
              <div className="field">
                <label className="label">
                  Phone Number{" "}
                  {/* <p
                style={{
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: "400",
                  float: "right",
                }}
              >
                required
              </p> */}
                </label>
                <div className="control">
                  <input
                    // ref={register({ required: true })}
                    name="phone"
                    className="input"
                    type="text"
                    value={state.activityScratchPad.activityPhone}
                    onChange={e =>
                      dispatch({
                        type: "updateActivityPhone",
                        data: e.currentTarget.value,
                      })
                    }
                    onFocus={() => clearError("phone")}
                  />
                  {errors.phone && (
                    <p className="help is-danger">
                      {" "}
                      <i
                        className="far fa-exclamation-triangle"
                        style={{ marginRight: "2px" }}
                      ></i>{" "}
                      This field is required
                    </p>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* <div className="columns">
        <div className="column is-6"></div>
        <div className="column is-6"></div>
      </div> */}

      {/* Activity Tags */}
      <div className="field" style={{ display: "none" }}>
        <label className="label">
          Tags{" "}
          <small>(you can search for the activity using these tags.)</small>
        </label>
        <div className="control is-expanded">
          <CreatableSelect
            components={{ DropdownIndicator: null }}
            inputValue={placeValue.inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onBlur={e => {
              console.log({ placeValue })
              setShowMaxTagsError(false)
              dispatch({ type: "updateActivityTags", data: placeValue })
            }}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Type a place and press enter..."
            value={placeValue.value}
          />
        </div>
        {showMaxTagsError ? (
          <p className="help is-danger">
            Maximum of 5 tags allowed per activity.
          </p>
        ) : (
          <p className="help">
            Type and press 'Enter' to add tags. Note: A maximum of 5 tags
            allowed per activity
          </p>
        )}
      </div>

      {/* Activity Description */}
      <div className="field" style={{ display: "none" }}>
        <label className="label">
          Description:{" "}
          {/* <p
            style={{
              display: "inline-block",
              fontSize: "12px",
              fontWeight: "400",
              float: "right",
            }}
          >
            required
          </p> */}
        </label>
        <textarea
          // ref={register({ required: true })}
          name="description"
          value={state.activityScratchPad.activityDescription}
          className="textarea"
          placeholder="few lines describing the activity"
          rows="3"
          onChange={e => {
            if (checkDescription(e.currentTarget.value.length)) return
            dispatch({
              type: "updateActivityDescription",
              data: e.currentTarget.value,
            })
          }}
          onFocus={() => clearError("description")}
        />
        {/* <div className="reco-quill" style={{ minHeight: "400px" }}>
          <ReactQuill
            theme="snow"
            ref={register({ required: true })}
            name="description"
            rows="5"
            placeholder="few lines describing the activity"
            value={state.activityScratchPad.activityDescription || ""}
            onChange={e => {
              if (checkDescription(e.length)) return
              dispatch({
                type: "updateActivityDescription",
                data: e,
              })
            }}
            modules={modules}
            formats={formats}
            onFocus={() => clearError("description")}
          />
        </div> */}
        {errors.description && (
          <p className="help is-danger">
            {" "}
            <i
              className="far fa-exclamation-triangle"
              style={{ marginRight: "2px" }}
            ></i>{" "}
            This field is required
          </p>
        )}
        <p className={`help ${reachedDescriptionMaxLength ? "is-danger" : ""}`}>
          Characters:{" "}
          {state.activityScratchPad.activityDescription
            ? state.activityScratchPad.activityDescription.length
            : 0}
          /{maxDescriptionCharactersAllowed}
        </p>
      </div>

      {/* Activity additional info */}
      {state.activityScratchPad.activityType === "restaurant" ? (
        <div className="field" style={{ display: "none" }}>
          <label className="label">
            Menu URL: (Please paste the URL below)
          </label>
          <div className="control">
            <input
              style={{ marginBottom: "10px" }}
              className="input"
              type="text"
              onChange={e =>
                dispatch({
                  type: "updateActivityMenuURL",
                  data: e.currentTarget.value,
                })
              }
              value={state.activityScratchPad.activityMenuURL}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {state.activityScratchPad.activityType === "sightseeing" ? (
        <div className="field" style={{ display: "none" }}>
          <label className="label">
            Entry Fee (per person in USD):{" "}
            {/* <p
              style={{
                display: "inline-block",
                fontSize: "12px",
                fontWeight: "400",
                float: "right",
              }}
            >
              required
            </p> */}
          </label>
          <div className="control">
            <input
              // ref={register({ required: true })}
              name="fee"
              style={{ marginBottom: "10px" }}
              className="input"
              type="text"
              onChange={e =>
                dispatch({
                  type: "updateActivityEntryFee",
                  data: e.currentTarget.value,
                })
              }
              value={state.activityScratchPad.activityEntryFee}
              onFocus={() => clearError("fee")}
            />
            {errors.fee && (
              <p className="help is-danger">
                {" "}
                <i
                  className="far fa-exclamation-triangle"
                  style={{ marginRight: "2px" }}
                ></i>{" "}
                This field is required
              </p>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <label className="label">Entry Fee</label>
        <div className="field has-addons">
          <p className="control">
            <span className="select">
              <select>
                <option>$ (USD)</option>
                <option>£ (UK Pound)</option>
                <option>€ (Euro)</option>
                <option>¥ (Japanese Yen)</option>
                <option>₹ (Indian Rupee)</option>
              </select>
            </span>
          </p>
          <p className="control">
            <input className="input" type="text" placeholder="Amount of money" />
          </p>
        </div> */}

      {/* <div className="field">
          <label className="label">Entry Fee</label>
          <div className="control">
            <input
              className="input"
              type="text"
              value={state.activityScratchPad.activityEntryFee}
              onChange={e =>
                dispatch({
                  type: "updateActivityEntryFree",
                  data: e.currentTarget.value,
                })
              }
            />
          </div>
        </div> */}
      {/*
      <div className="field">
        <label className="label">Address Line 1</label>
        <div className="control">
          <input className="input" type="text" />
        </div>
      </div>
      <div className="field">
        <label className="label">Address Line 2</label>
        <div className="control">
          <input className="input" type="text" />
        </div>
      </div>
      <div className="columns">
        <div className="column field">
          <label className="label">City</label>
          <div className="control">
            <input className="input" type="text" />
          </div>
        </div>
        <div className="column field">
          <label className="label">State</label>
          <div className="control">
            <input className="input" type="text" />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column field">
          <label className="label">Country</label>
          <div className="control">
            <input className="input" type="text" />
          </div>
        </div>
        <div className="column field">
          <label className="label">Zip</label>
          <div className="control">
            <input className="input" type="text" />
          </div>
        </div>
      </div> */}

      {/* <hr className="hr-branded"></hr> */}

      {/* Activity How long? */}
      {state.activityScratchPad.activityType === "Accommodation" ||
      state.activityScratchPad.activityType === "Waypoint" ? (
        ""
      ) : (
        <>
          <label className="label">
            How much time do you want to spend here?
            {activityDurationError && (
              <p
                className="help is-danger"
                style={{
                  display: "inline-block",
                  marginLeft: "5px",
                }}
              >
                {" "}
                <i
                  className="far fa-exclamation-triangle"
                  style={{ marginRight: "2px" }}
                ></i>{" "}
                This field is required
              </p>
            )}
            <p
              style={{
                display: "inline-block",
                fontSize: "12px",
                fontWeight: "400",
                float: "right",
              }}
            >
              required
            </p>
          </label>
          <div className="columns">
            <div className="column is-3">
              <>
                <label
                  className="label"
                  style={{ fontSize: "10px !important" }}
                >
                  Hours
                </label>
                <div className="control">
                  <Select
                    className="basic-single"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    value={{
                      value: `${
                        state.activityScratchPad.activityDurationHours
                          ? state.activityScratchPad.activityDurationHours
                          : "0"
                      }`,
                      label: `${
                        state.activityScratchPad.activityDurationHours
                          ? state.activityScratchPad.activityDurationHours
                          : "0"
                      }`,
                    }}
                    menuPortalTarget={document.body}
                    classNamePrefix="select"
                    isSearchable="true"
                    isClearable="true"
                    onChange={(value, actionMeta) => {
                      if (value)
                        dispatch({
                          type: "updateActivityDurationHours",
                          data: value.value,
                        })
                      setActivityDurationError(false)
                    }}
                    options={durationHours}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#fef0bf",
                        primary50: "#fee27f",
                        primary: "#fed43f",

                        //neutral0: "#424242",
                      },
                    })}
                    onFocus={() => setActivityDurationError(false)}
                  />
                </div>
              </>
            </div>
            <div className="column is-3">
              <>
                <label
                  className="label"
                  style={{ fontSize: "10px !important" }}
                >
                  Minutes
                </label>
                <div className="control">
                  <Select
                    className="basic-single"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    value={{
                      value: `${
                        state.activityScratchPad.activityDurationMinutes
                          ? state.activityScratchPad.activityDurationMinutes
                          : "0"
                      }`,
                      label: `${
                        state.activityScratchPad.activityDurationMinutes
                          ? state.activityScratchPad.activityDurationMinutes
                          : "0"
                      }`,
                    }}
                    menuPortalTarget={document.body}
                    classNamePrefix="select"
                    isSearchable="true"
                    isClearable="true"
                    onChange={(value, actionMeta) => {
                      if (value)
                        dispatch({
                          type: "updateActivityDurationMinutes",
                          data: value.value,
                        })
                    }}
                    options={durationMinutes}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#fef0bf",
                        primary50: "#fee27f",
                        primary: "#fed43f",

                        //neutral0: "#424242",
                      },
                    })}
                  />
                </div>
              </>
            </div>
            <div className="column is-6"></div>
          </div>
          {/* <div className="columns">
            <div className="column field is-3 is-6-tablet"></div>
            <div className="column field is-3 is-6-tablet"></div>
          </div> */}
        </>
      )}
      {/* Activity personal info */}
      <div className="field">
        <label className="label">
          Your Notes:
          {/* <p
            style={{
              display: "inline-block",
              fontSize: "12px",
              fontWeight: "400",
              float: "right",
            }}
          >
            required
          </p> */}
        </label>
        <textarea
          // ref={register({ required: true })}
          name="personal"
          value={state.activityScratchPad.personalPoints}
          className="textarea"
          placeholder=""
          rows="5"
          onChange={e => {
            if (checkInfo(e.currentTarget.value.length)) return
            dispatch({
              type: "updateActivityPersonalPoints",
              data: e.currentTarget.value,
            })
          }}
          onFocus={() => clearError("personal")}
        />
        {errors.personal && (
          <p className="help is-danger">
            {" "}
            <i
              className="far fa-exclamation-triangle"
              style={{ marginRight: "2px" }}
            ></i>{" "}
            This field is required
          </p>
        )}
        <p className={`help ${reachedInfoMaxLength ? "is-danger" : ""}`}>
          Characters:{" "}
          {state.activityScratchPad.personalPoints
            ? state.activityScratchPad.personalPoints.length
            : 0}
          /{maxInfoCharactersAllowed}
        </p>
      </div>

      {/* <div className="field" style={{ marginTop: "20px" }}>
        <label className="label">
          Would you like to add any personal tips to this attraction?
        </label>
        <div className="control">
          <button className="button is-warning">
            <span className="icon">
              <i className="fas fa-plus"></i>
            </span>
            <span>Add a travel tip</span>
          </button>
        </div>
        <div className="control"></div>
      </div> */}

      {/* Activity additional info */}
      <div className="field" style={{ marginTop: "20px" }}>
        <label className="label">
          External URLs for additional info: <br></br>{" "}
          <span style={{ fontSize: "16px" }}>
            {/* (Please paste the URLs below) */}
          </span>
          {/* <p
            style={{
              display: "inline-block",
              fontSize: "14px",
              fontWeight: "400",
              float: "right",
            }}
          >
            At least one URL required
          </p> */}
        </label>
        <div className="control">
          {errors.url_1 && (
            <p className="help is-danger">
              {" "}
              <i
                className="far fa-exclamation-triangle"
                style={{ marginRight: "2px" }}
              ></i>{" "}
              This field is required
            </p>
          )}
          <input
            // ref={register({ required: true })}
            name="url_1"
            style={{ marginBottom: "10px" }}
            className="input"
            type="text"
            onChange={e =>
              dispatch({
                type: "updateActivityURL1",
                data: e.currentTarget.value,
              })
            }
            value={state.activityScratchPad.activityURL1}
            onFocus={() => clearError("url_1")}
          />
          {/* {errors.url_2 && (
            <p className="help is-danger">
              {" "}
              <i
                className="far fa-exclamation-triangle"
                style={{ marginRight: "2px" }}
              ></i>{" "}
              This field is required
            </p>
          )} */}
          <input
            // ref={register({ required: true })}
            // name="url_2"
            style={{ marginBottom: "10px" }}
            className="input"
            type="text"
            onChange={e =>
              dispatch({
                type: "updateActivityURL2",
                data: e.currentTarget.value,
              })
            }
            value={state.activityScratchPad.activityURL2}
            // onFocus={() => clearError("url_2")}
          />

          <input
            style={{ marginBottom: "10px", display: "none" }}
            className="input"
            type="text"
            onChange={e =>
              dispatch({
                type: "updateActivityURL3",
                data: e.currentTarget.value,
              })
            }
            value={state.activityScratchPad.activityURL3}
          />
          <input
            style={{ marginBottom: "10px", display: "none" }}
            className="input"
            type="text"
            onChange={e =>
              dispatch({
                type: "updateActivityURL4",
                data: e.currentTarget.value,
              })
            }
            value={state.activityScratchPad.activityURL4}
          />
        </div>
      </div>

      {/* Activity buttons */}
      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <a
            className="button is-light"
            onClick={() => {
              onCancel()
            }}
          >
            Cancel
          </a>
        </p>
        <p className="control">
          <a
            className="button is-warning"
            onClick={async () => {
              const result = await triggerValidation()
              setActivityTypeError(isActivityTypeInvalid())

              setActivityDurationError(isActivityDurationInvalid())
              if (
                result == true &&
                isActivityDurationInvalid() === false &&
                isActivityTypeInvalid() === false
              ) {
                console.log("clean")
                if (inEditMode) {
                  console.log("Document successfully updated!")
                  if (addToItinerary) {
                    dispatch({
                      type: "updateActivity",
                      activeTab: activeTab,
                      activityUid: state.activityScratchPad.activityUid,
                    })
                  }
                  if (addToWishlist) {
                    dispatch({
                      type: "updateWishlistItem",
                      activityUid: state.activityScratchPad.activityUid,
                    })
                    dispatch({ type: "wishlistSyncRequired", data: true })
                  }

                  onActivityCreated()
                  onCancel()
                } else {
                  console.log("Document successfully updated!")
                  if (addToItinerary) {
                    dispatch({
                      type: "addNewActivity",
                      activeTab: activeTab,
                      addCommute: false,
                    })
                  }
                  if (addToWishlist) {
                    dispatch({
                      type: "addNewWishListItem",
                    })
                    dispatch({ type: "wishlistSyncRequired", data: true })
                  }
                  onActivityCreated()
                  onCancel()
                }
              } else {
                addToast(
                  "Please complete all the required fields, before creating the activity",
                  {
                    appearance: "error",
                    autoDismiss: true,
                  }
                )
              }
            }}
          >
            {inEditMode ? "Update activity" : "Add your activity"}
          </a>
        </p>
      </div>
      {/* <hr className="hr-branded"></hr> */}
    </div>
  )
}
