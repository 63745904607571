import React from "react"
import "./TravelDesigners.scss"
import Map from "../Map/Map"

import ShowcaseDesigners from "../../Container/ShowcaseDesigners/ShowcaseDesigners"
function TravelDesigners() {
  return (
    <div>
      {/* <div className="columns">
        <div className="column is-2"></div>
        <div className="column is-8">
          <div> */}

      <div className="columns">
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
        <div className="column is-10-desktop is-10-tablet is-12-mobile">
          <p
            style={{
              fontSize: "26px",
              fontWeight: "700",
              borderBottom: "2px solid #fec600",
              marginTop: "15px",
            }}
          >
            Travel Designers
          </p>
          {/* <div
            style={{ margin: "40px 0px", fontSize: "20px", fontWeight: "500" }}
          >
            <p>
              Travel designers on voyayge offer custom travel planning in 90+
              destinations around the world.
            </p>
          </div> */}
          <ShowcaseDesigners></ShowcaseDesigners>
        </div>
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
      </div>

      {/* </div>
        </div>
        <div className="column is-2"></div>
      </div> */}
    </div>
  )
}

export default TravelDesigners
