import loadable from "@loadable/component"
// import AlgoliaPlaces from "algolia-places-react"
import GoogleMapReact from "google-map-react"
import { navigate } from "gatsby"
import is from "is_js"
import { findIndex, isEqual, uniqWith, debounce } from "lodash"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

import { LazyLoadImage } from "react-lazy-load-image-component"
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"
import * as ROUTES from "../../constants/routes"
import { DateRangePicker } from "react-dates"
import { toast } from "react-toastify"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import lovingmap from "../../images/loving-map.png"
import destination1 from "../../images/destinations/1.jpg"
import "react-day-picker/lib/style.css"
// import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import Select from "react-select"
import { FirebaseContext } from "../../components/firebase"
import { analytics, functions } from "../../components/firebase/firebase"
import { AuthUserContext } from "../../components/session"
import {
  accommodationPreference,
  countryCode,
  vacationType,
} from "../../constants/data/general"
import useRestrictCharacterLength from "../../hooks/useRestrictCharacterLength"
import { useDashboardStateStore } from "../../State/dashboard-state/dashboard-state"
import { useGlobalStateStore } from "../../State/global-state/global-state"
import { dbGet } from "../../utility/dbCRUD"
import {
  TripStateProvider,
  useTripStateStore,
} from "../../State/trip-state/trip-state-store"
import { dottedText } from "../../utility/general"
import "./NewTripWizard.scss"
import ShowSwitchToDesktop from "../../components/ShowSwitchToDesktop/ShowSwitchToDesktop"
const AlgoliaPlaces = loadable(() => import("algolia-places-react"))

const ShowDestinationMap = ({ destinationState, seed }) => {
  const mapRef = useRef()
  const screenWidth = useWindowWidth()
  const MyMarker = () => (
    <div className="marker">
      <i
        className="fas fa-map-marker-alt fa-3x"
        style={{ color: "#ffc600" }}
      ></i>
    </div>
  )
  const createMapOptions = maps => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      // styles: [
      //   {
      //     stylers: [
      //       { saturation: -100 },
      //       { gamma: 0.8 },
      //       { lightness: 8 },
      //       { visibility: "on" },
      //     ],
      //   },
      // ],
      styles: [
        {
          featureType: "all",
          elementType: "geometry.fill",
          stylers: [
            {
              weight: "2.00",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#9c9c9c",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              color: "#f2f2f2",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 45,
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7b7b7b",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              color: "#46bcec",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c8d7d4",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#070707",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
      ],
      // [
      //   { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      //   { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      //   { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      //   {
      //     featureType: "administrative.locality",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "poi",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "poi.park",
      //     elementType: "geometry",
      //     stylers: [{ color: "#263c3f" }],
      //   },
      //   {
      //     featureType: "poi.park",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#6b9a76" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "geometry",
      //     stylers: [{ color: "#38414e" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "geometry.stroke",
      //     stylers: [{ color: "#212a37" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#9ca5b3" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "geometry",
      //     stylers: [{ color: "#746855" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "geometry.stroke",
      //     stylers: [{ color: "#1f2835" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#f3d19c" }],
      //   },
      //   {
      //     featureType: "transit",
      //     elementType: "geometry",
      //     stylers: [{ color: "#2f3948" }],
      //   },
      //   {
      //     featureType: "transit.station",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "geometry",
      //     stylers: [{ color: "#17263c" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#515c6d" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "labels.text.stroke",
      //     stylers: [{ color: "#17263c" }],
      //   },
      // ],
    }
  }

  var bounds = ""
  // if (state.tripDestinations) {
  //   if (state.tripDestinations.length === 0) return ""
  //   if (centerLat !== state.tripDestinations[0].latlng.lat)
  //     setCenterLat(state.tripDestinations[0].latlng.lat)
  //   if (centerLng !== state.tripDestinations[0].latlng.lng)
  //     setCenterLng(state.tripDestinations[0].latlng.lng)
  return (
    <div
      style={{
        height: screenWidth > 1024 ? "300px" : "250px",
        width: "100%",
        border: "2px solid #fec600",
        borderRadius: "6px",
        marginTop: "10px",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: `${process.env.GOOGLE_MAPS_API_KEY}`,
          language: "en",
          region: "US",
        }}
        // defaultCenter={{ lat: centerLat, lng: centerLng }}
        defaultCenter={{ lat: 36.1673, lng: -115.149 }}
        defaultZoom={10}
        // options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map
          console.log({ mapRef })
        }}
      >
        {destinationState.map((item, index) => {
          if (index === 0) {
            if (window.google) bounds = new window.google.maps.LatLngBounds()
            //   if (centerLat !== item.latlng.lat) setCenterLat(item.latlng.lat)
            //   if (centerLng !== item.latlng.lng) setCenterLng(item.latlng.lng)
            if (mapRef.current) {
              mapRef.current.setCenter({
                lat: item.latlng ? item.latlng.lat : 0,
                lng: item.latlng ? item.latlng.lng : 0,
              })
              mapRef.current.setZoom(10)
            }
          }

          console.log({ bounds })
          if (bounds !== "")
            bounds.extend({
              lat: item.latlng ? item.latlng.lat : 0,
              lng: item.latlng ? item.latlng.lng : 0,
            })
          if (index > 0 && mapRef.current) mapRef.current.fitBounds(bounds)

          return (
            <MyMarker
              key={index}
              lat={item.latlng ? item.latlng.lat : 0}
              lng={item.latlng ? item.latlng.lng : 0}
            />
          )
        })}
      </GoogleMapReact>
      {/* <Map
          google={props.google}
          zoom={8}
          //style={mapStyles}
          initialCenter={{ lat: centerLat, lng: centerLng }}
        /> */}
    </div>
  )
  // }
}

const BYOTWizardBase = () => {
  const [state, dispatch] = useTripStateStore()
  const maxDestinationsAllowed = 10
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [dashboardState, dispatchDashboard] = useDashboardStateStore()
  const [showSwitchToMobile, setShowSwitchToMobile] = useState(true)
  const [placeValue, setPlaceValue] = useState({
    inputValue: "",
    value: [""],
  })
  const [loading, setLoading] = useState(true)
  const [buildYourOwn, setBuildYourOwn] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [destinationError, setDestinationError] = useState(false)
  const [tripNameError, setTripNameError] = useState(false)
  const [tripTypeError, setTripTypeError] = useState(false)
  const [dateRangeError, setDateRangeError] = useState(false)
  const [centerLat, setCenterLat] = useState(47.444)
  const [centerLng, setCenterLng] = useState(-122.176)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showMainErrorMessage, setShowMainErrorMessage] = useState(false)
  const [tripDurationError, setTripDurationError] = useState(false)
  const datePicker = useRef()
  const [focusedInput, setFocusedInput] = useState(null)
  const [placeSearchValue, setPlaceSearchValue] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
  const [showPlaceLoading, setShowPlaceLoading] = useState(false)
  const [showNoResults, setShowNoResults] = useState(false)
  const handleDatesChange = ({ startDate, endDate }) => {
    setDateRangeError(false)
    setTripDurationError(false)
    console.log({ startDate, endDate })
    // if (startDate < moment() || endDate < moment()) return
    if (startDate) {
      let d0 = new Date(startDate)
      dispatch({
        type: "updateTravelStartDate",
        data: d0.toUTCString(),
      })
      setStartDate(startDate)
    }
    if (endDate) {
      let d1 = new Date(endDate)
      dispatch({
        type: "updateTravelEndDate",
        data: d1.toUTCString(),
      })

      setEndDate(endDate)
    }
  }
  const [pickedOther, setPickedOther] = useState(false)
  const [maxDestinationCountReached, setMaxDestinationCountReached] = useState(
    false
  )
  let error = false
  // const [reachedMaxLength, setReachedMaxLength] = useState(false)
  // const maxCharactersAllowed = 300
  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(500)
  const [
    reachedMaxLengthForTripName,
    maxCharactersAllowedForTripName,
    checkTripName,
  ] = useRestrictCharacterLength(30)
  const [
    reachedMaxLengthForTripType,
    maxCharactersAllowedForTripType,
    checkTripType,
  ] = useRestrictCharacterLength(30)
  const [value, setValue] = useState(null)
  var a = Math.random() * 1000

  useEffect(() => {
    let tUid = uuidv4()
    dispatch({ type: "updateTripUid", data: tUid })

    if (authContext && dbContext) {
      dbGet(dbContext, "users", authContext.uid)
        .then(doc => {
          if (doc.exists) {
            dispatch({
              type: "updatePrimaryTravelerDetails",
              data: doc.data(),
              uid: authContext.uid,
            })
            setLoading(false)
          }
        })
        .catch(error => {
          console.log("Error getting document:", error)
        })
    }
  }, [])

  useEffect(() => {
    console.log({ state })
  }, [state])
  // useEffect(() => {
  // }, [selectedItemIndex])
  useEffect(() => {
    try {
      let d0 = new Date()
      dispatch({
        type: "updateTravelStartDate",
        data: d0.toUTCString(),
      })
      setStartDate(startDate)

      let d1 = new Date()
      dispatch({
        type: "updateTravelEndDate",
        data: d1.toUTCString(),
      })

      setEndDate(endDate)
    } catch (error) {}
  }, [])
  useEffect(() => {
    console.log({ showNoResults })
  }, [showNoResults])

  useEffect(() => {
    console.log({ searchResults })
  }, [searchResults])
  const showDestinationTags = () => {
    if (state.tripDestinations) {
      if (state.tripDestinations.length === 0) return ""
      return state.tripDestinations.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className="tags are-small has-addons place-tags"
              style={{
                marginTop: "15px",
                marginRight: "10px",
                display: "inline-flex",
                position: "relative",

                //width: "50%",
              }}
            >
              {/* <p
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  left: "0px",
                  top: "-17px",
                }}
              >
                {moment.localeData().ordinal(index + 1)} Destination
              </p> */}
              <span className="tag is-warning">{item.place}</span>
              <span className="tag is-warning">
                {item.place === item.country ? "" : item.country}
              </span>
              <a
                id={index}
                className="tag is-delete"
                onClick={e => {
                  dispatch({
                    type: "removeDestination",
                    data: e.currentTarget.id,
                  })
                }}
              ></a>
              {/* <iframe
              title="Paris"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=7&q=${item.place}+${item.country}`}
              width="200px"
              height="200px"
              allowFullScreen
            ></iframe> */}
            </div>
          </>
        )
      })
    }
  }
  const validateDateRange = () => {
    if (
      state.tripLogistics.travelDates.start === "" ||
      state.tripLogistics.travelDates.end === ""
    ) {
      setDateRangeError(true)
      return false
    } else {
      let endDate = Date.parse(state.tripLogistics.travelDates.end)
      let startDate = Date.parse(state.tripLogistics.travelDates.start)
      var totalTravelDays =
        Math.round((endDate - startDate) / (24 * 60 * 60 * 1000)) + 1
      console.log("Total travel days: ", totalTravelDays)
      if (totalTravelDays < 1 || totalTravelDays > 60) {
        setTripDurationError(true)
        return false
      } else {
        setTripDurationError(false)
        return true
      }
    }
  }

  const addToNewTripsPool = async () => {
    try {
      console.log("addToNewTripsPool called")
      dbContext
        .firestore()
        .collection("newTripsPool")
        .doc(`${state.tripUid}`)
        .set({
          createdAt: new Date(),
          travelDates: state.tripLogistics.travelDates,
          tripName: state.tripName,
          tripPrimaryDestination: state.tripPrimaryDestination,
          tripUid: state.tripUid,
          travelerUid: state.uid,
          travelerEmailId: authContext.email,
          remindersSentCount: 0,
          jobCreated: false,
          travelerFirstName: globalState.currentUserData
            ? globalState.currentUserData.firstName
            : "",
        })
        .then(() => {
          console.log("New trip added to pool")
        })
    } catch (err) {
      console.log(err)
    }
  }
  const addNewManifestEntry = async type => {
    try {
      var manifestEntry = {
        createdAt: new Date(),
        travelDates: state.tripLogistics.travelDates,
        tripName: state.tripName,
        tripPrimaryDestination: state.tripPrimaryDestination,
        tripUid: state.tripUid,
        cardPictureURL: "",
        status: "work-in-progress",
        ...(type === "self" ? { selfPlanned: true } : ""),
      }
      dbContext
        .firestore()
        .collection("trips")
        .doc(`${state.uid}`)
        .update({
          travelManifest: dbContext.firestore.FieldValue.arrayUnion(
            manifestEntry
          ),
        })
        .then(() => {
          console.log("Travel manifest successfully updated!")
          // Todo : update local manifest array in dashboard state.
          dispatchDashboard({
            type: "addNewManifestEntry",
            data: manifestEntry,
          })
          addToNewTripsPool()
          // onNext()
        })
    } catch (err) {
      console.log(err)
    }
  }
  const formatStartDate = () => {
    try {
      if (state.tripLogistics.travelDates.start) {
        return [
          state.tripLogistics.travelDates.start === ""
            ? new Date()
            : state.tripLogistics.travelDates.start.toDate(),
        ]
      } else return [new Date()]
    } catch (error) {
      // console.log(state.tripLogistics.travelDates.start)
      // console.log(state.tripLogistics.travelDates.end)
      if (state.tripLogistics.travelDates.start) {
        return [
          state.tripLogistics.travelDates.start === ""
            ? new Date()
            : state.tripLogistics.travelDates.start,
        ]
      } else {
        return [new Date()]
      }
    }
  }
  const formatEndDate = () => {
    try {
      if (state.tripLogistics.travelDates.end) {
        return [
          state.tripLogistics.travelDates.end === ""
            ? new Date()
            : state.tripLogistics.travelDates.end.toDate(),
        ]
      } else return [new Date()]
    } catch (error) {
      // console.log(error)
      if (state.tripLogistics.travelDates.end) {
        return [
          state.tripLogistics.travelDates.end === ""
            ? new Date()
            : state.tripLogistics.travelDates.end,
        ]
      } else {
        return [new Date()]
      }
    }
  }

  const getPlaceName = qValue => {
    console.log("getPlaceName called")
    setShowPlaceLoading(true)
    let data = {
      qValue: qValue,
    }
    if (qValue) {
      var getPlaceName = functions.httpsCallable("getPlaceName")
      getPlaceName(data)
        .then(result => {
          // Read result of the Cloud Function.
          setShowPlaceLoading(false)
          console.log({ result })
          if (result && result.data) {
            let r = result.data.geonames.length > 0 ? result.data.geonames : []
            setSearchResults([...r])
            console.log(result.data.totalResultsCount)
            if (result.data.totalResultsCount === 0) setShowNoResults(true)
            else setShowNoResults(false)
          } else console.log("outside")
        })
        .catch(err => {
          console.log(err)
          setShowPlaceLoading(false)
        })
    }
  }

  const createNewBYOTrip = async () => {
    try {
      if (dbContext) {
        var jobId = uuidv4()
        var roomId = uuidv4()
        var travelerUid = state.travelerDetails[0].uid
        var designerUid = state.travelerDetails[0].uid
        var travelerName = state.travelerDetails[0].firstName
        const activeTripsRef = dbContext
          .firestore()
          .collection("trips")
          .doc(`${state.uid}`)
          .collection("active-trips")
          .doc(`${state.tripUid}`)

        const tripsRef = dbContext
          .firestore()
          .collection("trips")
          .doc(`${state.uid}`)

        const jobsRef = dbContext
          .firestore()
          .collection("jobs")
          .doc(`${designerUid}`)
          .collection("active-jobs")
          .doc(`${jobId}`)

        const chatRoomsRef = dbContext
          .firestore()
          .collection("chatRooms")
          .doc(`${roomId}`)

        const byotTripsDumpRef = dbContext
          .firestore()
          .collection("byot-trips-dump")
          .doc(`${state.tripUid}`)

        const byotStatsRef = dbContext
          .firestore()
          .collection("byot-trips-dump")
          .doc(`--stats--`)

        const increment = dbContext.firestore.FieldValue.increment(1)
        // const newTripPoolRef = dbContext
        //   .firestore()
        //   .collection("newTripsPool")
        //   .doc(`${state.tripUid}`)

        const batch = dbContext.firestore().batch()
        var b = { roomId: roomId }
        var a = {
          designerDetails: {
            ...b,
            ...{
              designerUid: designerUid,
              status: "active",
              type: "byot",
              jobId: jobId,
            },
          },
        }

        batch.set(activeTripsRef, { ...state, ...a })

        batch.update(tripsRef, {
          travelManifest: dbContext.firestore.FieldValue.arrayUnion({
            createdAt: new Date(),
            travelDates: state.tripLogistics.travelDates,
            tripName: state.tripName,
            tripPrimaryDestination: state.tripPrimaryDestination,
            tripUid: state.tripUid,
            cardPictureURL: "",
            status: "byot-work-in-progress",
            members: [{ UID: authContext.uid, owner: true }],
            byot: true,
          }),
        })

        // batch.delete(newTripPoolRef)

        let jobInfo = {
          createdOn: new Date(),
          tripUid: state.tripUid,
          tripReservations: [],
          tripPrimaryDestination: state.tripPrimaryDestination,
          tripChecklistStatus: "not-started",
          tripChecklistInProgress: "false",
          tripChecklist: [],
          travelerUid: travelerUid,
          travelDates: state.tripLogistics.travelDates,
          status: "active",
          roomId: roomId,
          reservationsStatus: "not-started",
          reservationsInProgress: "false",
          messagedInProgress: false,
          tripChecklist: [],
          jobUid: jobId,
          itineraryStatus: "not-started",
          reviewStatus: "not-started",
          recommendationsStatus: "not-started",
          byot: true,
          roles: {
            [travelerUid]: {
              role: `owner`,
              userName: travelerName,
            },
          },
        }
        batch.set(jobsRef, jobInfo)

        batch.set(chatRoomsRef, {
          createdOn: new Date(),
          owners: [travelerUid],
          roomId: roomId,
          usersInfo: {
            [travelerUid]: {
              name: travelerName,
              uid: travelerUid,
              profileUrl: globalState.currentUserData.userProfileImage,
            },
          },
          // senderInfo: {
          //   name: selectedDesigner.firstName,
          //   uid: designerUid,
          //   profileUrl: selectedDesigner.userProfileImage,
          // },
        })
        batch.set(
          byotTripsDumpRef,
          {
            createdOn: new Date(),
            owners: [travelerUid],
            tripPrimaryDestination: state.tripPrimaryDestination,
            travelerName: travelerName,
            travelerUid: travelerUid,
            tripDestinations: state.tripDestinations,
            travelerProfileUrl: globalState.currentUserData.userProfileImage,
          },
          { merge: true }
        )
        batch.set(byotStatsRef, { "byot-trips": increment }, { merge: true })
        batch
          .commit()
          .then(() => {
            dispatchDashboard({
              type: "updateCurrentJobInfo",
              data: jobInfo,
            })

            console.log("Hello")
            setIsBusy(false)
            navigate(`${ROUTES.BYOT_TRAVELER_DETAILS}/${state.tripUid}`, {
              state: { 118: state.travelManifest },
            })
            // dispatch({ type: "updateRoomId", data: roomId })
            // if (dispatchDashboard)
            //   dispatchDashboard({
            //     type: "setDesignerStateToPending",
            //     data: selectedDesigner,
            //   })
            // console.log("New chat room created successfully!")
            // console.log("Show success message now!")
            //Notify user
            // NotificationModal({
            //   title: "",
            //   text:
            //     "Great! Your design task has been created and the travel designer has been notified. Hang tight! You will receive a notification with the next steps once the designer has accepted the design job.",
            //   onClose: close,
            // })
          })
          .catch(error => {
            //console.log(error)
            console.log("Error creating new trip! :", error)
            toast.error(
              "Error starting your travel plan, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
              {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            )
          })
      } else {
        console.log("No DBContext")
      }
    } catch (err) {
      console.log({ err })
      toast.error(
        "Error creating your travel design task, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      )
    }
  }

  const debounceSearch = useCallback(debounce(getPlaceName, 500), [])

  const parsePlaceType = item => {
    try {
      let n = item.fclName
      const regex = /^[^,]*/g
      const str = n
      let m
      var ext = ""
      switch (item.fcode) {
        case "PCLI":
          return "country"
          break
        case "ADM1":
          return "state"
          break
        case "ADM2":
          return "county"
          break
        case "RGN":
        case "RGNE":
          return "region"
        case "ISL":
          return "Island"
          break
        default:
          break
      }
      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          // console.log(`Found match: ${match}`)
          ext = match
        })
      }
      // console.log({ ext })
      return ext === "parks" ? "National Park" : ext
    } catch (error) {
      return ""
    }
  }

  // To handle the UP, DOWN and ENTER key press results in the search bar.
  const keyChanged = e => {
    // console.log({ selectedItemIndex })
    // console.log(e.keyCode)
    try {
      switch (e.keyCode) {
        case 27: // ESC
          return
        case 38: // UP
          if (selectedItemIndex > 0) {
            // clearFocus()
            var a = selectedItemIndex - 1
            setSelectedItemIndex(a)
          }
          break
        case 40: // DOWN
          if (selectedItemIndex < searchResults.length - 1) {
            var a = selectedItemIndex + 1
            // if (selectedItemIndex != -1) clearFocus()
            setSelectedItemIndex(a)
          }
          break
        case 13: //ENTER
          if (selectedItemIndex != -1) {
            dispatch({
              type: "updateDestination",
              data: {
                country: searchResults[selectedItemIndex].countryName,
                place: searchResults[selectedItemIndex].name,
                latlng: {
                  lat: parseFloat(searchResults[selectedItemIndex].lat),
                  lng: parseFloat(searchResults[selectedItemIndex].lng),
                },
              },
              maxDestinationsCount: maxDestinationsAllowed,
            })
            setPlaceSearchValue("")
            setSearchResults([])
          }
          break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }

  const showSearchResults = () => {
    try {
      if (searchResults && searchResults.length > 0) {
        return (
          <ul>
            {searchResults.map((item, index) => {
              return (
                <li
                  key={item.geonameId}
                  className="aa-Item"
                  style={{
                    background:
                      selectedItemIndex === index
                        ? "rgba(254, 198, 0, 1)"
                        : "white",
                  }}
                  // style={{
                  //   background: "white",
                  // }}
                >
                  <div
                    style={{
                      padding: "10px",
                      minHeight: "40px",
                      cursor: "pointer",
                    }}
                    className="airport-code-items"
                    onClick={() => {
                      console.log("dispatch")
                      dispatch({
                        type: "updateDestination",
                        data: {
                          country: item.countryName,
                          place: item.name,
                          state: item.adminName1,
                          latlng: {
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng),
                          },
                        },
                        maxDestinationsCount: maxDestinationsAllowed,
                      })
                      setPlaceSearchValue("")
                      setSearchResults([])
                    }}
                  >
                    <nav className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ fontSize: "14px" }}>
                              {dottedText(item.name, 40)}
                            </div>
                            <div style={{ fontSize: "10px" }}>
                              {item.name === item.countryName
                                ? ""
                                : `${dottedText(item.adminName1, 40)}${
                                    item.adminName1 ? "," : ""
                                  }
                                ${dottedText(item.countryName, 20)}`}
                              {/* {dottedText(item.name, 40)},{" "}
                              {dottedText(item.countryName, 20)} */}
                            </div>
                            <div>
                              {/* <p
                                                  className="help"
                                                  style={{
                                                    marginTop: "-2px",
                                                  }}
                                                >
                                                  {item.fcodeName}
                                                </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="level-right">
                        <span
                          className="tag is-warning is-small"
                          style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.5px",
                            fontWeight: "500",
                            fontSize: "10px",
                            color: "rgba(74,74,74,0.8)",
                          }}
                        >
                          {" "}
                          {parsePlaceType(item)}
                        </span>
                      </div>
                    </nav>
                  </div>
                </li>
              )
            })}
          </ul>
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  const screenWidth = useWindowWidth()

  return (
    <>
      {is.mobile() && showSwitchToMobile ? (
        <ShowSwitchToDesktop
          onContinue={() => {
            setShowSwitchToMobile(false)
          }}
        />
      ) : (
        <div>
          <>
            <div className="columns fade-in">
              <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
              <div
                className="column is-6-desktop is-10-tablet is-12-mobile"
                // style={{ background: "#ebebeb" }}
              >
                <div className="columns is-multiline">
                  <div className="column is-12-desktop is-12-tablet is-12-mobile">
                    <div
                      style={{
                        textAlign: "left",
                        marginBottom: screenWidth > 1024 ? "40px" : "20px",
                        position: "relative",
                      }}
                    >
                      <a
                        onClick={() => {
                          navigate("/app/dashboard")
                        }}
                        style={{
                          color: "#fec600",
                          position: "absolute",
                          right: "0px",
                          top: "-30px",
                        }}
                      >
                        <i className="fal fa-times fa-lg"></i>
                      </a>

                      <p
                        style={{
                          fontSize: screenWidth > 1024 ? "34px" : "24px",
                          fontWeight: "800",
                          color: "rgba(74,74,74,0.8)",
                          marginTop: screenWidth > 1024 ? "60px" : "30px",
                          lineHeight: "1.4",
                        }}
                      >
                        Start planning your trip
                        <span style={{ color: "#fec600" }}>.</span>
                      </p>
                    </div>

                    <div
                      className="byot-wizard-reco-container"
                      style={{
                        display: "none",
                        // backgroundImage: `url(${lovingmap})`,
                      }}
                    >
                      <div
                        class="card"
                        style={{
                          width: "200px",
                          marginRight: "20px",
                          position: "relative",
                          border: "1px solid #ebebeb",
                          borderRadius: "14px",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          class="card-image"
                          style={{ borderRadius: "14px" }}
                        >
                          <figure
                            class="image is-4by5"
                            style={{ width: "200px", borderRadius: "14px" }}
                          >
                            <img
                              src={destination1}
                              alt="Placeholder image"
                              style={{ borderRadius: "14px" }}
                            ></img>
                          </figure>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: "15px",
                            left: "15px",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "32px",
                              fontWeight: "700",
                              color: "white",
                              lineHeight: "1",
                              letterSpacing: "0.5px",
                            }}
                          >
                            Florence
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              // color: "#fec600",
                              textTransform: "uppercase",
                              letterSpacing: "0.5px",
                              display: "none",
                            }}
                          >
                            Italy
                          </p>
                        </div>
                      </div>
                      <div
                        class="card"
                        style={{ width: "200px", marginRight: "20px" }}
                      >
                        <div class="card-image">
                          <figure
                            class="image is-4by5"
                            style={{ width: "200px" }}
                          >
                            <img
                              src="https://picsum.photos/id/1040/400/500"
                              alt="Placeholder image"
                              style={{ borderRadius: "14px" }}
                            ></img>
                          </figure>
                        </div>
                      </div>
                      <div
                        class="card"
                        style={{ width: "200px", marginRight: "20px" }}
                      >
                        <div class="card-image">
                          <figure
                            class="image is-4by5"
                            style={{ width: "200px" }}
                          >
                            <img
                              src="https://picsum.photos/id/1051/400/500"
                              alt="Placeholder image"
                              style={{ borderRadius: "14px" }}
                            ></img>
                          </figure>
                        </div>
                      </div>
                      <div
                        class="card"
                        style={{ width: "200px", marginRight: "20px" }}
                      >
                        <div class="card-image">
                          <figure
                            class="image is-4by5"
                            style={{ width: "200px" }}
                          >
                            <img
                              src="https://picsum.photos/id/1055/400/500"
                              alt="Placeholder image"
                              style={{ borderRadius: "14px" }}
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>

                    <div className="field" style={{ marginBottom: "10px" }}>
                      <label
                        className="label"
                        style={{ position: "relative", fontSize: "18px" }}
                      >
                        Where would you like to go?
                      </label>
                      <div className="">
                        <div style={{ position: "relative" }}>
                          <div
                            class={`${
                              showPlaceLoading
                                ? "control is-loading"
                                : "control"
                            }`}
                          >
                            <input
                              value={placeSearchValue}
                              type="text"
                              autoFocus
                              placeholder="e.g. Las Vegas, Paris, Japan"
                              disabled={maxDestinationCountReached}
                              className="input"
                              style={{ paddingRight: "10px" }}
                              onBlur={() => {
                                setTimeout(() => {
                                  let a = document.getElementById(
                                    "place-results-panel"
                                  )
                                  if (a) a.style.display = "none"
                                }, 300)
                              }}
                              onKeyDown={keyChanged}
                              onFocus={() => {
                                setDestinationError(false)
                                let a = document.getElementById(
                                  "place-results-panel"
                                )
                                if (a) a.style.display = "block"
                                // console.log("onFocus")
                              }}
                              onChange={e => {
                                setDestinationError(false)
                                let value = e.currentTarget.value
                                if (value) {
                                  setPlaceSearchValue(value)
                                  if (value.length > 2) debounceSearch(value)
                                } else {
                                  setPlaceSearchValue("")
                                  setSearchResults([])
                                  setShowNoResults(false)
                                }
                              }}
                            />
                            {searchResults.length > 0 && (
                              <div
                                className="aa-Panel"
                                style={{ marginTop: "3px" }}
                                id="place-results-panel"
                              >
                                {showSearchResults()}
                              </div>
                            )}
                            {showNoResults && (
                              <div
                                className="aa-Panel"
                                style={{ marginTop: "3px" }}
                                id="place-results-panel"
                              >
                                <p
                                  style={{
                                    padding: "5px 10px",
                                    fontSize: "14px",
                                  }}
                                >
                                  No Results
                                </p>
                              </div>
                            )}
                            {placeSearchValue.length > 0 &&
                            !showPlaceLoading ? (
                              <span
                                className="icon is-medium is-right"
                                style={{
                                  position: "absolute",
                                  right: "0px",
                                  top: "4px",
                                }}
                                onClick={() => {
                                  setPlaceSearchValue("")
                                  setSearchResults([])
                                  setShowNoResults(false)
                                }}
                              >
                                <i
                                  style={{
                                    color: "#fec600",
                                    cursor: "pointer",
                                    zIndex: "99",
                                  }}
                                  className="far fa-times"
                                ></i>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {maxDestinationCountReached ? (
                          <p className="help">
                            {" "}
                            <i className="fas fa-exclamation-circle"></i>{" "}
                            Reached maximum destinations allowed
                          </p>
                        ) : (
                          ""
                        )}
                        {destinationError ? (
                          <p className="help is-danger">
                            {" "}
                            <i className="fas fa-exclamation-circle"></i> Please
                            pick at least one destination
                          </p>
                        ) : (
                          <p className="help">
                            {/* Type your destination cities.
                        Type slowly to select a city from one of the options on
                        the list. Note: You can select multiple cities */}
                          </p>
                        )}

                        <div style={{ marginTop: "10px", marginBottom: "5px" }}>
                          {showDestinationTags()}
                        </div>
                      </div>

                      <div
                        className="control is-expanded"
                        style={{ display: "none" }}
                      >
                        <AlgoliaPlaces
                          placeholder="e.g. Las Vegas, Paris, Tokyo"
                          disabled={maxDestinationCountReached}
                          options={{
                            appId: `${process.env.ALGOLIA_APP_ID}`,
                            apiKey: `${process.env.ALGOLIA_API_KEY}`,
                            language: "en",
                            // placeholder: "Where do you want to go?",
                            // countries: ["se"],
                            useDeviceLocation: false,
                            aroundLatLngViaIP: false,
                            type: "city",
                            templates: {
                              value: function(suggestion) {
                                // return suggestion.name + ", " + suggestion.country
                                return " "
                              },
                              // suggestion: function(suggestion) {
                              //   return suggestion.name + ", " + suggestion.country
                              //   //return ""
                              // },
                            },
                            // Other options from https://community.algolia.com/places/documentation.html#options
                          }}
                          onFocus={() => {
                            setDestinationError(false)
                            console.log("onFocus")
                          }}
                          onBlur={() => {
                            if (state.tripDestinations === [])
                              setDestinationError(true)
                            console.log("onBlur")
                          }}
                          onChange={({
                            query,
                            rawAnswer,
                            suggestion,
                            suggestionIndex,
                          }) => {
                            console.log({
                              query,
                              rawAnswer,
                              suggestion,
                              suggestionIndex,
                            })

                            dispatch({
                              type: "updateDestination",
                              data: {
                                country: suggestion.country,
                                place: suggestion.name,
                                latlng: suggestion.latlng,
                              },
                              maxDestinationsCount: maxDestinationsAllowed,
                            })

                            setDestinationError(false)
                          }}
                          onLimit={({ message }) =>
                            console.log(
                              "Fired when you reached your current rate limit."
                            )
                          }
                          onError={({ message }) =>
                            console.log(
                              "Fired when we could not make the request to Algolia Places servers for any reason but reaching your rate limit."
                            )
                          }
                        />
                        {maxDestinationCountReached ? (
                          <p className="help">
                            {" "}
                            <i className="fas fa-exclamation-circle"></i>{" "}
                            Reached maximum destinations allowed
                          </p>
                        ) : (
                          ""
                        )}
                        {destinationError ? (
                          <p className="help is-danger">
                            {" "}
                            <i className="fas fa-exclamation-circle"></i> Please
                            pick at least one destination
                          </p>
                        ) : (
                          <p className="help">
                            {/* Type your destination cities.  */}
                            Type slowly to select a city from one of the options
                            on the list. Note: You can select multiple cities
                          </p>
                        )}
                        {/* <p style={{ marginTop: "20px", marginBottom: "5px" }}>
                    Selected destinations:
                  </p> */}

                        <div style={{ marginTop: "10px", marginBottom: "5px" }}>
                          {showDestinationTags()}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginBottom: screenWidth > 1024 ? "40px" : "0px",
                        display: "flex",
                        justifyContent: "center",
                        // minHeight: "300px",
                      }}
                      // className="is-hidden-mobile"
                    >
                      {/* <LazyLoadImage
                    src={lovingmap}
                    alt=""
                    effect="blur"
                    style={{ maxWidth: "600px" }}
                  /> */}
                      <ShowDestinationMap
                        destinationState={state.tripDestinations}
                        seed={a}
                      />
                    </div>
                  </div>

                  <div className="column is-12-desktop is-12-tablet is-12-mobile">
                    <label className="label">Travel dates</label>
                    {/* <div >
                  <span style={{ fontSize: "12px", marginLeft: "25px" }}>
                    pick start date
                  </span>
                  <span style={{ fontSize: "12px", marginLeft: "95px" }}>
                    pick end date
                  </span>
                </div> */}
                    <div className="control">
                      <DateRangePicker
                        startDate={moment(formatStartDate()[0])}
                        withPortal={is.mobile() ? true : false}
                        withFullScreenPortal={is.mobile() ? true : false}
                        orientation={
                          is.mobile() ? "verticalScrollable" : "horizontal"
                        }
                        startDateId="tata-start-date"
                        endDate={moment(formatEndDate()[0])}
                        endDateId="tata-end-date"
                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        onFocusChange={focusedInput => {
                          console.log({ focusedInput })
                          setFocusedInput(focusedInput)
                        }}
                        // minDate={moment().add(5, "d")}
                        readOnly={is.mobile() ? true : false}
                        numberOfMonths={is.mobile() ? 24 : 2}
                        disableScroll={false}
                        navPrev={is.mobile() ? <></> : ""}
                        navNext={is.mobile() ? <></> : ""}
                      />

                      {dateRangeError || tripDurationError ? (
                        <p
                          className="help is-danger"
                          // style={{ display: "inline-block" }}
                        >
                          <i className="fas fa-exclamation-circle"></i> Please
                          enter valid itinerary dates.
                        </p>
                      ) : (
                        <p className="help">
                          Note sure? You can change the travel dates later.
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        maxWidth: "200px",
                        marginLeft: "auto",
                        marginRight: "0px",
                        marginTop: "40px",
                        textAlign: "right",
                      }}
                    >
                      <a
                        style={{
                          textAlign: "right",
                          fontWeight: "600",
                          textDecorationColor: "#fec600",
                          color: "rgba(74, 74, 74, 0.8)",
                          textDecoration: "none",
                        }}
                        className="button is-warning"
                        onClick={e => {
                          if (isBusy === false) {
                            setIsBusy(true)
                            var a = true

                            var c = true

                            if (state.tripDestinations.length === 0) {
                              setDestinationError(true)
                              a = false
                            }

                            c = validateDateRange()

                            if (a && c) {
                              console.log("CLEAN!!")

                              dbContext
                                .firestore()
                                .collection("trips")
                                .doc(`${state.uid}`)
                                .collection("active-trips")
                                .doc(`${state.tripUid}`)
                                .set(state)
                                .then(async () => {
                                  // addNewManifestEntry("self")
                                  await createNewBYOTrip()
                                  analytics.logEvent("byot_create_trip", {
                                    userId: `${authContext.uid}`,
                                    tripId: state.tripUid,
                                  })
                                })
                                .catch(error => {
                                  setIsBusy(false)
                                  console.log("Error getting document:", error)
                                })
                            } else {
                              console.log("ERROR!")
                              setIsBusy(false)
                              setShowMainErrorMessage(true)
                            }
                          } else {
                            console.log("not allowed")
                          }
                        }}
                        // className="button is-warning"
                      >
                        <span
                          style={{
                            // textDecoration: "underline",
                            textDecorationColor: "#fec600",
                            fontSize: screenWidth > 1024 ? "16px" : "16px",
                            fontWeight: "400",
                          }}
                        >
                          Get Started
                        </span>{" "}
                        {/* <i
                      style={{ color: "#4a4a4a" }}
                      className="far fa-long-arrow-right"
                    ></i> */}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-3-desktop is-1-tablet is-hidden-mobile"></div>
            </div>
          </>
        </div>
      )}
    </>
  )
}

const BYOTWizard = () => {
  return (
    <TripStateProvider>
      <BYOTWizardBase />
    </TripStateProvider>
  )
}

export default BYOTWizard
