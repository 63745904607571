import React, { useState, useEffect, useContext } from "react"
import { ToastProvider, useToasts } from "react-toast-notifications"
// import { Container } from './styles';
import { fetchFromAPI } from "../../../utility/general"
import AuthUserContext from "../../../components/session/context"
import card from "./../../../images/card.png"

function PaymentMethodsBase() {
  const authContext = useContext(AuthUserContext)
  const [processing, setProcessing] = useState(false)
  const { addToast } = useToasts()

  const fetchDesignerDashboardURL = async () => {
    try {
      const token = await authContext.getIdToken()
      console.log({ token })
      const response = await fetchFromAPI(
        "POST",
        "api/getDesignerLoginLink",
        {
          body: {},
        },
        token
      )
      console.log({ response })
      if (response && response.url) {
        window.open(response.url)
      } else {
        console.log({ response })
        addToast(
          "Error getting your financial info, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
          {
            appearance: "error",
            autoDismiss: true,
          }
        )
      }
    } catch (err) {
      addToast(
        "Error getting your financial info, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
        {
          appearance: "error",
          autoDismiss: true,
        }
      )
      console.log(err)
    }
    setProcessing(false)
  }

  const fetchTravelerDashboardURL = async () => {
    try {
      const token = await authContext.getIdToken()
      console.log({ token })
      const response = await fetchFromAPI(
        "POST",
        "api/getTravelerLoginLink",
        {
          body: {
            returnURL: "https://localhost:8000/app/account",
          },
        },
        token
      )
      console.log({ response })
      if (response && response.url) {
        window.open(response.url)
      } else {
        console.log({ response })
        addToast(
          "Error getting your financial info, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
          {
            appearance: "error",
            autoDismiss: true,
          }
        )
      }
    } catch (err) {
      addToast(
        "Error getting your financial info, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
        {
          appearance: "error",
          autoDismiss: true,
        }
      )
      console.log(err)
    }
    setProcessing(false)
  }

  const displayAppropriateButton = () => {
    var role = JSON.parse(localStorage.getItem("sessionType"))
    if (role) {
      console.log(role.sessionType)

      if (role.sessionType === "designer") {
        return (
          <>
            <button
              className={`${
                processing
                  ? "button is-warning is-loading"
                  : "button is-warning"
              }`}
              onClick={() => {
                setProcessing(true)
                fetchDesignerDashboardURL()
              }}
            >
              View financial info
            </button>
            <p className="help">
              Note: If the window doesn't open, please make sure you allow pop
              ups from this site and try again
            </p>
          </>
        )
      } else {
        return (
          <button
            className={`${
              processing ? "button is-warning is-loading" : "button is-warning"
            }`}
            onClick={() => {
              setProcessing(true)
              fetchTravelerDashboardURL()
            }}
          >
            View transactions
          </button>
        )
      }
    }
  }
  return (
    <div className="columns is-multiline is-vcentered">
      <div className="column is-3-desktop is-2-tablet"></div>
      <div className="column is-6-desktop is-8-tablet">
        <div style={{ maxWidth: "300px", margin: "0 auto" }}>
          <img src={card} alt="card" style={{ width: "100%" }} />
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {displayAppropriateButton()}
        </div>
      </div>
      <div className="column is-3-desktop is-2-tablet"></div>
    </div>
  )
}

const PaymentMethods = () => {
  return (
    <ToastProvider>
      <PaymentMethodsBase />
    </ToastProvider>
  )
}

export default PaymentMethods
