import { forEach, forEachRight } from "lodash"
import React, { useContext, useState } from "react"
import Confetti from "react-confetti"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import swal from "sweetalert"
import Accordion from "../../../components/Accordion/Accordion"
import { FirebaseContext } from "../../../components/firebase"
import { analytics } from "../../../components/firebase/firebase"
import { NotificationModal } from "../../../components/NotificationModal/NotificationModal"
import AuthUserContext from "../../../components/session/context"
import cheers from "../../../images/cheers.png"
import emptyCommute from "../../../images/emptyCommute.png"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import { getSafeAddDays, titleCase } from "../../../utility/general"
import "./JobStatus.scss"

function JobStatusContainer() {
  const [state, dispatch] = useDashboardStateStore()
  const [party, setParty] = useState(false)
  const [jobState, setJobState] = useState("none")
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)

  // This process starts the review process. This is the get some feedback and minor changes before the plan is finalized.
  const initiateReview = () => {
    // set the designerDetails status to "review"
    // /trips/{TRAVELER_UID}/active-trips/{TRIP_UID}/ {designerDetails: {status: "review"}}
    console.log({ state })
    var travelerUid = state.tripDetails.uid
    var jobId = state.currentJobInfo.jobUid
    dbContext
      .firestore()
      .collection("jobs")
      .doc(`${authContext.uid}`)
      .collection("active-jobs")
      .doc(`${jobId}`)
      .update({
        status: "review",
        reviewStatus: "in-progress",
      })
      .then(function() {
        console.log("Jobs details status updated to review state")
        dispatch({ type: "reviewInProgress" })
        // swal("Travel plan successfully shared with user", {
        //   icon: "success",
        // })
        NotificationModal({
          onClose: () => {
            window.location.reload()
          }, // just refresh the page.},
          // title: "",
          text: `A copy of your travel plan was successfully sent to your client for review!`,
          // showImage: emptyCommute,
        })
      })
      .catch(error => {
        console.log("Error setting Jobs details status to review state:", error)
      })

    // copy travel plan details from /jobs/{DESIGNER_UID}/active-jobs/{JOB_ID} to /trips/{TRAVELER_UID}/active-trips/{TRIP_UID}/travel-plan-review/{JOB_ID}
    // traveler can now view the plan and reach out to the designer via a platform message for req  requesting ant changes
  }

  const syncDesignerWorkSpaceItineraryToDB = () => {
    if (dbContext) {
      dbContext
        .firestore()
        .collection("jobs")
        .doc(`${authContext.uid}`)
        .collection("active-jobs")
        .doc(`${state.currentJobInfo.jobUid}`)
        .update({
          designerWorkspace: {
            itinerary: state.currentJobInfo.designerWorkspace.itinerary,
          },
        })
        .then(() => {
          console.log("document update successful")
          //update local state now
          //
        })
        .catch(e => console.log(e))
    }
  }

  const enableCompleteJob = () => {
    if (
      state.currentJobInfo.itineraryStatus === "complete" &&
      state.currentJobInfo.tripChecklistStatus === "complete" &&
      // state.currentJobInfo.reservationsStatus === "complete" &&
      state.currentJobInfo.reviewStatus === "complete" &&
      state.currentJobInfo.status !== "complete"
    ) {
      return false
    } else return true
  }

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }
  const faqWrapper = qNa => {
    return <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>{qNa}</div>
  }
  const getSafeDate = d => {
    try {
      return new Intl.DateTimeFormat("en-US", options).format(d.toDate())
    } catch (error) {
      return new Intl.DateTimeFormat("en-US", options).format(d)
    }
  }
  // const getSafeAddDays = (d, days) => {
  //   try {
  //     var newDate = d.toDate().getTime() + days * 60 * 60 * 24 * 1000
  //     return new Intl.DateTimeFormat("en-US", options).format(new Date(newDate))
  //   } catch (error) {
  //     var newDate = d.getTime() + days * 60 * 60 * 24 * 1000
  //     return new Intl.DateTimeFormat("en-US", options).format(new Date(newDate))
  //   }
  // }

  const finalizeJob = () => {
    console.log({ state })
    var travelerUid = state.tripDetails.uid
    var jobId = state.currentJobInfo.jobUid
    dbContext
      .firestore()
      .collection("jobs")
      .doc(`${authContext.uid}`)
      .collection("active-jobs")
      .doc(`${jobId}`)
      .update({
        status: "complete",
        completedOn: new Date(),
      })
      .then(function() {
        console.log("Jobs status updated to complete state")
        //dispatch({ type: "reviewComplete" })
        // dispatch({ type: "reviewInProgress" })
        setParty(true)
        setJobState("complete")
        swal("Travel plan job successfully completed, Thank you!!", {
          icon: "success",
        })
        analytics.logEvent("job_completed")
      })
      .catch(error => {
        console.log(
          "Error setting Jobs details status to complete state:",
          error
        )
      })
  }
  const finalizeTravelRecommendations = params => {
    console.log({ state })
    var travelerUid = state.tripDetails.uid
    var jobId = state.currentJobInfo.jobUid
    dbContext
      .firestore()
      .collection("jobs")
      .doc(`${authContext.uid}`)
      .collection("active-jobs")
      .doc(`${jobId}`)
      .update({
        recommendationsStatus: "complete",
      })
      .then(function() {
        console.log("Recommendations status updated to complete")
        dispatch({ type: "recommendationsComplete" })
        swal("Recommendations successfully finalized!", {
          icon: "success",
        })
      })
      .catch(error => {
        console.log("Error setting Recommendations status to complete:", error)
      })
  }

  const timeOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }

  const changes = params => {
    try {
      if (state.currentJobInfo.requestedChanges) {
        return state.currentJobInfo.requestedChanges
          .map(item => {
            return (
              "<strong>" +
              Intl.DateTimeFormat("en-US", timeOptions).format(
                item.dateTime.toDate()
              ) +
              "</strong>" +
              item.changes +
              "<br>"
            )
          })
          .join(" ")
      }
    } catch (err) {
      if (state.currentJobInfo.requestedChanges) {
        return state.currentJobInfo.requestedChanges
          .map(item => {
            return (
              "<strong>" +
              Intl.DateTimeFormat("en-US", timeOptions).format(item.dateTime) +
              "</strong>" +
              item.changes +
              "<br>"
            )
          })
          .join(" ")
      }
    }
  }

  const showRequestedChanges = () => {
    if (state.currentJobInfo) {
      if (state.currentJobInfo.requestedChanges) {
        {
          return faqWrapper(
            <Accordion
              header={"View requested changes"}
              data={changes()}
              fontSize="14px"
            />
          )
        }
      }
    }
  }

  const onClose = params => {}

  const verifyTravelItinerary = () => {
    let itinerary = state.currentJobInfo.designerWorkspace.itinerary
    console.log({ itinerary })
    // let foundErrors = false
    // let errorType = ""

    let result = { foundErrors: false, errorType: "" }
    //Clean for trailing commute blocks
    itinerary.forEach(activities => {
      forEachRight(activities.activities, function(activity) {
        let aType = activity.activity.activityType
        if (aType === "commute") {
          console.log("Delete commute")
          dispatch({
            type: "deleteActivity",
            dayId: activity.dayId,
            itemId: activity.id,
          })
        } else return false
      })
    })

    //Clean for beginning commute blocks
    itinerary.forEach(activities => {
      forEach(activities.activities, function(activity) {
        let aType = activity.activity.activityType
        if (aType === "commute") {
          console.log("Delete commute")
          dispatch({
            type: "deleteActivity",
            dayId: activity.dayId,
            itemId: activity.id,
          })
        } else return false
      })
    })

    // Check for repeating "Add Commute" block in between activity blocks
    itinerary.forEach(activities => {
      let consecutiveCommuteActivityCounter = 0
      forEachRight(activities.activities, function(activity) {
        let aType = activity.activity.activityType
        if (aType === "commute") {
          if (consecutiveCommuteActivityCounter >= 1) {
            console.log("Found consecutive commutes")
            if (activity.activity.commuteMode === "") {
              dispatch({
                type: "deleteActivity",
                dayId: activity.dayId,
                itemId: activity.id,
              })
            }
          }
          consecutiveCommuteActivityCounter++
        } else consecutiveCommuteActivityCounter = 0
      })
    })

    // Check for blank commutes
    itinerary.forEach(activities => {
      forEachRight(activities.activities, function(activity) {
        let aType = activity.activity.activityType
        if (aType === "commute") {
          if (activity.activity.commuteMode === "") {
            console.log("Found blank commutes at -> ", activity.dayId)
            result.foundErrors = true
            result.errorType = "addCommute"
          }
        }
      })
    })

    console.log("done!")
    return result
  }

  const finalizeTravelItinerary = () => {
    console.log({ state })
    var travelerUid = state.tripDetails.uid
    var jobId = state.currentJobInfo.jobUid
    dbContext
      .firestore()
      .collection("jobs")
      .doc(`${authContext.uid}`)
      .collection("active-jobs")
      .doc(`${jobId}`)
      .update({
        itineraryStatus: "complete",
      })
      .then(function() {
        console.log("itinerary status updated to complete")
        dispatch({ type: "itineraryComplete" })
        swal("itinerary successfully finalized!", {
          icon: "success",
        })
      })
      .catch(error => {
        console.log("Error setting itinerary status to complete:", error)
      })
  }
  const finalizeReservations = () => {
    console.log({ state })
    var travelerUid = state.tripDetails.uid
    var jobId = state.currentJobInfo.jobUid
    dbContext
      .firestore()
      .collection("jobs")
      .doc(`${authContext.uid}`)
      .collection("active-jobs")
      .doc(`${jobId}`)
      .update({
        reservationsStatus: "complete",
      })
      .then(function() {
        console.log("Reservation status updated to complete")
        dispatch({ type: "reservationsComplete" })
        swal("Reservations successfully finalized!", {
          icon: "success",
        })
      })
      .catch(error => {
        console.log("Error setting Reservation status to complete:", error)
      })
  }
  const finalizeChecklist = () => {
    console.log({ state })
    var travelerUid = state.tripDetails.uid
    var jobId = state.currentJobInfo.jobUid
    dbContext
      .firestore()
      .collection("jobs")
      .doc(`${authContext.uid}`)
      .collection("active-jobs")
      .doc(`${jobId}`)
      .update({
        tripChecklistStatus: "complete",
      })
      .then(function() {
        console.log("Checklist status updated to complete")
        dispatch({ type: "tripChecklistComplete" })
        swal("Checklist successfully finalized!", {
          icon: "success",
        })
      })
      .catch(error => {
        console.log("Error setting Checklist status to complete:", error)
      })
  }
  const getFormattedStatus = item => {
    if (item) {
      switch (item) {
        case "not-started":
          return (
            <p
              style={{
                color: "rgba(78,78,78,0.8)",
                fontWeight: "600",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
                fontSize: "12px",
              }}
            >
              - Not Started -
            </p>
          )
          break
        case "in-progress":
          return (
            <p
              style={{
                color: "#FEC600",
                fontWeight: "600",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
                fontSize: "12px",
              }}
            >
              - In Progress -
            </p>
          )
          break
        case "complete":
          return (
            <p
              style={{
                color: "#00b8a9",
                fontWeight: "600",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
                fontSize: "12px",
              }}
            >
              - Done -
            </p>
          )
          break
        case "changes-required":
          return (
            <p
              style={{
                color: "#DA0000",
                fontWeight: "600",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
                fontSize: "12px",
              }}
            >
              - Changes Requested -
            </p>
          )
          break

        default:
          break
      }
    }
  }

  const formatStatusMessage = () => {
    switch (state.currentJobInfo.status) {
      case "request-changes":
        return "Changes Requested"
        break
      case "review":
        return "Travel plan is in review"
        break
      case "review-complete":
        return "Traveler Review Complete"
        break

      default:
        return titleCase(state.currentJobInfo.status)
    }
  }

  const getDueDate = () => {
    try {
      if (state.currentJobInfo.status === "review") return "--"
      if (state.currentJobInfo.status === "active")
        return state.currentJobInfo.paymentCompletedOn !== undefined
          ? getSafeAddDays(state.currentJobInfo.paymentCompletedOn, 7)
          : "--"
      if (state.currentJobInfo.status === "request-changes")
        return state.currentJobInfo.changesRequestedOn !== undefined
          ? getSafeAddDays(state.currentJobInfo.changesRequestedOn, 3)
          : "--"
    } catch (err) {
      return "--"
    }
  }

  return (
    <div>
      <Confetti
        style={{ pointerEvents: "none" }}
        numberOfPieces={party ? 500 : 0}
        recycle={false}
        onConfettiComplete={confetti => {
          setParty(false)
          confetti.reset()
        }}
      />
      <div style={{ width: "300px", marginRight: "auto", marginLeft: "auto" }}>
        {/* <img src={congrats} alt="" /> */}
        {/* <p style={{ textAlign: "center", fontSize: "24px" }}>
          Congratulation on accepting the job!
        </p> */}
      </div>
      <div className="columns">
        <div className="column is-3-desktop">
          <p
            style={{
              textAlign: "center",
              fontSize: "10px",
              fontWeight: "500",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            }}
          >
            Accepted on
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "600",
              letterSpacing: "0.5px",
            }}
          >
            <span style={{ textTransform: "", letterSpacing: "0.5px" }}>
              {state.currentJobInfo.acceptedOn !== undefined
                ? getSafeDate(state.currentJobInfo.acceptedOn)
                : "--"}
            </span>{" "}
          </p>
        </div>
        <div className="column is-3-desktop">
          <p
            style={{
              textAlign: "center",
              fontSize: "10px",
              fontWeight: "500",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            }}
          >
            Status
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "600",
              letterSpacing: "0.5px",
            }}
          >
            <span style={{ textTransform: "", letterSpacing: "0.5px" }}>
              {formatStatusMessage()}
            </span>{" "}
          </p>
        </div>
        <div className="column is-3-desktop">
          <p
            style={{
              textAlign: "center",
              fontSize: "10px",
              fontWeight: "500",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            }}
          >
            Active On
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "600",
              letterSpacing: "0.5px",
            }}
          >
            <span style={{ textTransform: "", letterSpacing: "0.5px" }}>
              {state.currentJobInfo.paymentCompletedOn !== undefined
                ? getSafeDate(state.currentJobInfo.paymentCompletedOn)
                : "--"}
            </span>
          </p>
        </div>
        <div className="column is-3-desktop">
          <p
            style={{
              textAlign: "center",
              fontSize: "10px",
              fontWeight: "500",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            }}
          >
            Due by
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "600",
              letterSpacing: "0.5px",
            }}
          >
            <span style={{ textTransform: "", letterSpacing: "0.5px" }}>
              {getDueDate()}
            </span>
          </p>
        </div>
      </div>
      <div
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      ></div>
      <div className="columns is-desktop is-tablet">
        {/* <div className="column is-1-desktop"></div> */}
        <div className="column is-12-desktop">
          <Table>
            <Thead>
              <Tr>
                <Th className="" style={{ paddingBottom: "10px" }}>
                  Next Steps
                </Th>
                <Th className="" style={{ paddingBottom: "10px" }}>
                  Links
                </Th>
                <Th
                  className=""
                  style={{ paddingBottom: "10px", minWidth: "115px" }}
                >
                  Current Status
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td className="">
                  1. Review travel details located under the "Trip Details" tab
                  to understand what the customer is looking for.
                </Td>
                <Td className="">
                  <a
                    className="link is-text"
                    onClick={params => {
                      dispatch({ type: "setActiveTab", data: "TripDetails" })
                    }}
                  >
                    Go to trip details
                  </a>
                </Td>
                <Td className="">{getFormattedStatus("in-progress")}</Td>
              </Tr>
              <Tr>
                <Td className="">
                  {state.currentJobInfo.itineraryStatus === "complete" ? (
                    <strike>
                      2. You can start working on traveler's itinerary located
                      at the "Itinerary Designer" tab.
                    </strike>
                  ) : (
                    '2. You can start working on traveler\'s itinerary located at the "Itinerary Designer" tab.'
                  )}
                </Td>
                <Td className="">
                  <a
                    className="link is-text"
                    onClick={params => {
                      if (state.currentJobInfo.itineraryStatus === "complete") {
                        return
                      }
                      if (
                        state.currentJobInfo.itineraryStatus === "not-started"
                      ) {
                        dispatch({ type: "setActiveTab", data: "Itinerary" })
                        return
                      }
                      if (state.currentJobInfo.reviewStatus !== "complete") {
                        NotificationModal({
                          onClose,
                          title: "Itinerary could not be finalized",
                          text: `Customer review (step 4) of the travel plan should be complete before the trip itinerary can be finalized.`,
                          // showImage: emptyCommute,
                        })
                        return
                      }
                      if (
                        state.currentJobInfo.itineraryStatus === "in-progress"
                      ) {
                        swal({
                          title: "Are you sure?",
                          text:
                            "Once you finalize the travel itinerary, you wont be able to make any changes to it!",
                          //  icon: "question",
                          buttons: true,
                          dangerMode: true,
                          buttons: [
                            "No, cancel it!",
                            "Yes, finalize itinerary!",
                          ],
                        }).then(doFinalize => {
                          if (doFinalize) {
                            if (!verifyTravelItinerary().foundErrors) {
                              syncDesignerWorkSpaceItineraryToDB()
                              finalizeTravelItinerary()
                            } else {
                              console.log("Verification failed")
                              if (
                                verifyTravelItinerary().errorType ===
                                "addCommute"
                              )
                                NotificationModal({
                                  onClose,
                                  title: "Itinerary could not be finalized",
                                  text: `Add commute between all activities on all travel days i.e. do not leave "Add Commute" blocks`,
                                  showImage: emptyCommute,
                                })
                            }
                          }
                        })
                      } else
                        dispatch({ type: "setActiveTab", data: "Itinerary" })
                    }}
                  >
                    {state.currentJobInfo.itineraryStatus === "complete" ? (
                      <strike>Finalize Itinerary</strike>
                    ) : state.currentJobInfo.itineraryStatus ===
                      "in-progress" ? (
                      "Finalize Itinerary"
                    ) : (
                      "Go to itinerary designer"
                    )}
                  </a>
                </Td>
                <Td className="">
                  {getFormattedStatus(state.currentJobInfo.itineraryStatus)}
                </Td>
              </Tr>
              <Tr>
                <Td className="">
                  {state.currentJobInfo.recommendationsStatus === "complete" ? (
                    <strike>
                      3. You can start working on the location specific
                      recommendations at the "Other Info" tab.
                    </strike>
                  ) : (
                    '3. You can start working on the location specific recommendations at the "Other Info" tab.'
                  )}
                </Td>
                <Td className="">
                  <a
                    className="link is-text"
                    onClick={params => {
                      if (
                        state.currentJobInfo.recommendationsStatus ===
                        "complete"
                      ) {
                        return
                      }
                      if (
                        state.currentJobInfo.recommendationsStatus ===
                        "not-started"
                      ) {
                        dispatch({
                          type: "setActiveTab",
                          data: "Recommendations",
                        })
                        return
                      }
                      if (state.currentJobInfo.reviewStatus !== "complete") {
                        NotificationModal({
                          onClose,
                          title: "Recommendations could not be finalized",
                          text: `Customer review (step 4) of the travel plan should be complete before the trip recommendations can be finalized.`,
                          // showImage: emptyCommute,
                        })
                        return
                      }
                      if (
                        state.currentJobInfo.recommendationsStatus ===
                        "in-progress"
                      ) {
                        swal({
                          title: "Are you sure?",
                          text:
                            "Once you finalize the travel recommendations, you wont be able to make any changes to it!",
                          //  icon: "question",
                          buttons: true,
                          dangerMode: true,
                          buttons: [
                            "No, cancel it!",
                            "Yes, finalize recommendations!",
                          ],
                        }).then(doFinalize => {
                          if (doFinalize) {
                            finalizeTravelRecommendations()
                          }
                        })
                      } else
                        dispatch({
                          type: "setActiveTab",
                          data: "Recommendations",
                        })
                    }}
                  >
                    {state.currentJobInfo.recommendationsStatus ===
                    "complete" ? (
                      <strike>Finalize Recommendations</strike>
                    ) : state.currentJobInfo.recommendationsStatus ===
                      "in-progress" ? (
                      "Finalize Recommendations"
                    ) : (
                      "Go to Trip Recommendations"
                    )}
                  </a>
                </Td>
                <Td className="">
                  {getFormattedStatus(
                    state.currentJobInfo.recommendationsStatus
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td className="">
                  {state.currentJobInfo.reviewStatus === "complete" ? (
                    <strike>
                      {" "}
                      4. Send the travel plan for customer review.
                    </strike>
                  ) : (
                    "4. Send the travel plan for customer review."
                  )}
                  {/* <br></br> <a></a> */}
                  {/* {ReactHtmlParser(showRequestedChanges())} */}
                  {showRequestedChanges()}
                </Td>
                <Td className="">
                  <a
                    className="link is-text"
                    onClick={() => {
                      if (state.currentJobInfo.reviewStatus === "complete") {
                        swal("Travel plan has already been reviewed", {
                          icon: "warning",
                        })
                        return
                      }
                      if (state.currentJobInfo.reviewStatus === "in-progress") {
                        swal(
                          "Travel plan has already been sent to user for review.",
                          {
                            icon: "warning",
                          }
                        )
                      } else {
                        swal({
                          title: "Confirmation",
                          text:
                            "Are you sure you want to share a copy of the current travel plan to the user?",
                          //  icon: "question",
                          buttons: true,
                          dangerMode: true,
                          buttons: [
                            "No, cancel it!",
                            "Yes, share plan for review!",
                          ],
                        }).then(doShare => {
                          if (doShare) {
                            if (!verifyTravelItinerary().foundErrors) {
                              syncDesignerWorkSpaceItineraryToDB()
                              initiateReview()
                            } else {
                              console.log("Verification failed")
                              if (
                                verifyTravelItinerary().errorType ===
                                "addCommute"
                              )
                                NotificationModal({
                                  onClose,
                                  title: "Review could not be sent",
                                  text: `Add commute between all activities on all travel days i.e. do not leave "Add Commute" blocks`,
                                  showImage: emptyCommute,
                                })
                            }
                          }
                        })
                      }
                    }}
                  >
                    {state.currentJobInfo.reviewStatus === "complete" ? (
                      <strike>Initiate review</strike>
                    ) : (
                      "Initiate review"
                    )}
                  </a>
                </Td>
                <Td className="">
                  {getFormattedStatus(state.currentJobInfo.reviewStatus)}
                </Td>
              </Tr>
              {/* <Tr>
                <Td className="">
                  {state.currentJobInfo.reservationsStatus === "complete" ? (
                    <strike>
                      5. Add details on any reservations made. E.g. dinner
                      reservations, show tickets etc.
                    </strike>
                  ) : (
                    "5. Add details on any reservations made. E.g. dinner reservations, show tickets etc."
                  )}
                </Td>
                <Td className="">
                  <a
                    className="link is-text"
                    onClick={params => {
                      if (
                        state.currentJobInfo.reservationsStatus === "complete"
                      ) {
                        return
                      }
                      if (
                        state.currentJobInfo.reservationsStatus ===
                        "not-started"
                      ) {
                        dispatch({ type: "setActiveTab", data: "Reservations" })
                        return
                      }
                      if (state.currentJobInfo.reviewStatus !== "complete") {
                        NotificationModal({
                          onClose,
                          title: "Reservations could not be finalized",
                          text: `Customer review (step 4) of the travel plan should be complete before the trip reservations can be finalized.`,
                          // showImage: emptyCommute,
                        })
                        return
                      }
                      if (
                        state.currentJobInfo.reservationsStatus ===
                        "in-progress"
                      ) {
                        swal({
                          title: "Are you sure?",
                          text:
                            "Once you finalize the reservations, you wont be able to make any changes to it!",
                          //  icon: "question",
                          buttons: true,
                          dangerMode: true,
                          buttons: [
                            "No, cancel it!",
                            "Yes, finalize reservations!",
                          ],
                        }).then(doFinalize => {
                          if (doFinalize) {
                            finalizeReservations()
                          }
                        })
                      } else
                        dispatch({ type: "setActiveTab", data: "Reservations" })
                    }}
                  >
                    {state.currentJobInfo.reservationsStatus === "complete" ? (
                      <strike>Finalize Reservations</strike>
                    ) : state.currentJobInfo.reservationsStatus ===
                      "in-progress" ? (
                      "Finalize Reservations"
                    ) : (
                      "Go to Reservations"
                    )}
                  </a>
                </Td>
                <Td className="">
                  {getFormattedStatus(state.currentJobInfo.reservationsStatus)}
                </Td>
              </Tr> */}
              <Tr>
                <Td className="">
                  {state.currentJobInfo.tripChecklistStatus === "complete" ? (
                    <strike>
                      6. Add any checklist items for the travelers.
                    </strike>
                  ) : (
                    "5. Add any checklist items for the travelers."
                  )}
                </Td>
                <Td className="">
                  <a
                    className="link is-text"
                    onClick={params => {
                      if (
                        state.currentJobInfo.tripChecklistStatus === "complete"
                      ) {
                        return
                      }
                      if (
                        state.currentJobInfo.tripChecklistStatus ===
                        "not-started"
                      ) {
                        dispatch({ type: "setActiveTab", data: "Checklist" })
                        return
                      }
                      if (state.currentJobInfo.reviewStatus !== "complete") {
                        NotificationModal({
                          onClose,
                          title: "Checklist could not be finalized",
                          text: `Customer review (step 4) of the travel plan should be complete before the trip checklist can be finalized.`,
                          // showImage: emptyCommute,
                        })
                        return
                      }
                      if (
                        state.currentJobInfo.tripChecklistStatus ===
                        "in-progress"
                      ) {
                        swal({
                          title: "Are you sure?",
                          text:
                            "Once you finalize the Checklist, you wont be able to make any changes to it!",
                          //  icon: "question",
                          buttons: true,
                          dangerMode: true,
                          buttons: [
                            "No, cancel it!",
                            "Yes, finalize Checklist!",
                          ],
                        }).then(doFinalize => {
                          if (doFinalize) {
                            finalizeChecklist()
                          }
                        })
                      } else
                        dispatch({ type: "setActiveTab", data: "Checklist" })
                    }}
                  >
                    {state.currentJobInfo.tripChecklistStatus === "complete" ? (
                      <strike>Finalize Checklist</strike>
                    ) : state.currentJobInfo.tripChecklistStatus ===
                      "in-progress" ? (
                      "Finalize Checklist"
                    ) : (
                      "Go to Checklist"
                    )}
                  </a>
                </Td>
                <Td className="">
                  {getFormattedStatus(state.currentJobInfo.tripChecklistStatus)}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
        {/* <div className="column is-1-desktop"></div> */}
      </div>
      {jobState === "none" && (
        <div
          style={{
            marginTop: "20px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "110px",
          }}
        >
          <div>
            <button
              className="button is-warning"
              disabled={enableCompleteJob()}
              style={{
                textTransform: "uppercase",
                fontSize: "14px",
                letterSpacing: "0.5px",
              }}
              onClick={params => {
                swal({
                  title: "Are you sure?",
                  text:
                    "Your final travel plan version will be submitted to the customer and you wont be able to make any changes to it!",
                  //  icon: "question",
                  buttons: true,
                  dangerMode: true,
                  buttons: ["No, cancel it!", "Yes, finalize Job!"],
                }).then(doFinalize => {
                  if (doFinalize) {
                    finalizeJob()
                  }
                })
              }}
            >
              {/* <i
              style={{ marginRight: "10px" }}
              className="fal fa-flag-checkered fa-lg"
            ></i> */}
              Complete Job!
              {/* <i
              style={{ marginLeft: "10px" }}
              className="fal fa-flag-checkered fa-lg"
            ></i> */}
            </button>
          </div>
          <div>
            <p style={{ fontSize: "14px", marginTop: "5px" }}>
              Note: "Complete Job!" will be enabled once all the above steps are
              complete.
            </p>
          </div>
        </div>
      )}
      {jobState === "complete" && (
        <div
          style={{
            marginTop: "10px",
            maxWidth: "350px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 auto",
            marginBottom: "80px",
          }}
        >
          <img src={cheers} style={{ width: "100%" }} alt="" />
          <p
            style={{
              textTransform: "uppercase",
              fontSize: "18px",
              fontWeight: "600",
              letterSpacing: "0.5px",
            }}
          >
            job completed successfully!
          </p>
          <hr className="hr-branded"></hr>
        </div>
      )}
      {/* Trip design in progress. Note: At this time amy changes made to the plan
      are not visible to the customer. */}
      <hr className="hr-branded"></hr>
    </div>
  )
}

export default JobStatusContainer
