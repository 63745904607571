import React, { useState, useEffect, useCallback } from "react"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import moment from "moment"
import { findIndex, isEqual, uniqWith, debounce } from "lodash"
import { DateRangePicker } from "react-dates"
import { ToastProvider, useToasts } from "react-toast-notifications"
import is from "is_js"
import join from "lodash"
import { functions } from "../../../components/firebase/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import { dottedText } from "../../../utility/general"
import ShowLoading from "../../../components/ShowLoading/ShowLoading"

const TripDetailsSettings = () => {
  const [state, dispatch] = useTripStateStore()
  const [dashboardState, dashboardDispatch] = useDashboardStateStore()
  const maxDestinationsAllowed = 10
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [newStartDate, setNewStartDate] = useState(null)
  const [newEndDate, setNewEndDate] = useState(null)
  const [dateRangeError, setDateRangeError] = useState(false)
  const [focusedInput, setFocusedInput] = useState(null)
  const [tripDurationError, setTripDurationError] = useState(false)
  const [travelDatesInEditMode, setTravelDatesInEditMode] = useState(false)
  const [datesUpdateInProgress, setDatesUpdateInProgress] = useState(false)
  const [placeSearchValue, setPlaceSearchValue] = useState("")
  const [searchResults, setSearchResults] = useState([])

  const [destinationError, setDestinationError] = useState(false)
  const [showNoResults, setShowNoResults] = useState(false)
  const [showPlaceLoading, setShowPlaceLoading] = useState(false)
  const [
    showUpdateDestinationsLoading,
    setShowUpdateDestinationsLoading,
  ] = useState(false)
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
  const [showNewDestinationSearch, setShowNewDestinationSearch] = useState(
    false
  )
  const [maxDestinationCountReached, setMaxDestinationCountReached] = useState(
    false
  )
  const [tempTripDestinations, setTempTripDestinations] = useState([])
  const { addToast } = useToasts()
  useEffect(() => {
    console.log("%Trip-State", "color: #972426; font-size: 18px", {
      state,
    })
  }, [state])
  useEffect(() => {
    console.log({ newStartDate })
  }, [newStartDate])
  useEffect(() => {
    console.log({ newEndDate })
  }, [newEndDate])
  useEffect(() => {
    console.log({ tempTripDestinations })
  }, [tempTripDestinations])

  const updateTripDestinations = newTripDestination => {
    console.log("updateTripDestinations called")
    console.log({ newTripDestination })
    setShowUpdateDestinationsLoading(true)
    let tripUID = state.tripUid
    let jobUID = state.designerDetails.jobId
    let data = {
      tripUID: tripUID,
      jobUID: jobUID,
      tripDestinations: [...newTripDestination],
      tripPrimaryDestinationChanged:
        state.tripDestinations[0] === newTripDestination[0] ? false : true,
    }
    if (newTripDestination) {
      var updateTripDestinations = functions.httpsCallable(
        "updateTripDestinations"
      )
      updateTripDestinations(data)
        .then(result => {
          // Read result of the Cloud Function.
          setShowUpdateDestinationsLoading(false)
          setShowNewDestinationSearch(false)
          console.log({ result })
          if (result && result.data) {
            if (result.data.success) {
              console.log("success!")
              console.log({ newTripDestination })
              dispatch({
                type: "updateTripDestinations",
                data: [...newTripDestination],
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
          setShowUpdateDestinationsLoading(false)

          addToast(
            "Error updating trip destinations, please try again in sometime",
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
        })
    }
  }

  const getPlaceName = qValue => {
    console.log("getPlaceName called")
    setShowPlaceLoading(true)
    let data = {
      qValue: qValue,
    }
    if (qValue) {
      var getPlaceName = functions.httpsCallable("getPlaceName")
      getPlaceName(data)
        .then(result => {
          // Read result of the Cloud Function.
          setShowPlaceLoading(false)
          console.log({ result })
          if (result && result.data) {
            let r = result.data.geonames.length > 0 ? result.data.geonames : []
            setSearchResults([...r])
            console.log(result.data.totalResultsCount)
            if (result.data.totalResultsCount === 0) setShowNoResults(true)
            else setShowNoResults(false)
          } else console.log("outside")
        })
        .catch(err => {
          console.log(err)
          setShowPlaceLoading(false)
        })
    }
  }
  const debounceSearch = useCallback(debounce(getPlaceName, 500), [])
  const getDates = data => {
    try {
      let a = `${data.tripLogistics.travelDates.start
        .toDate()
        .toLocaleDateString()} - ${data.tripLogistics.travelDates.end
        .toDate()
        .toLocaleDateString()}`
      console.log({ a })
      return a
    } catch (e) {
      console.log({ e })
      return `${new Date(
        data.tripLogistics.travelDates.start
      ).toLocaleDateString()} - ${new Date(
        data.tripLogistics.travelDates.end
      ).toLocaleDateString()}`
    }
  }

  useEffect(() => {
    try {
      setNewStartDate(new Date(state.tripLogistics.travelDates.start))
      setNewEndDate(new Date(state.tripLogistics.travelDates.end))

      setTempTripDestinations(state.tripDestinations)
    } catch (err) {
      console.log(err)
    }
  }, [state])

  const parsePlaceType = item => {
    try {
      let n = item.fclName
      const regex = /^[^,]*/g
      const str = n
      let m
      var ext = ""
      switch (item.fcode) {
        case "PCLI":
          return "country"
          break
        case "ADM1":
          return "state"
          break
        case "ADM2":
          return "county"
          break
        case "RGN":
        case "RGNE":
          return "region"
        case "ISL":
          return "Island"
          break
        default:
          break
      }
      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          // console.log(`Found match: ${match}`)
          ext = match
        })
      }
      // console.log({ ext })
      return ext === "parks" ? "National Park" : ext
    } catch (error) {
      return ""
    }
  }
  const showSearchResults = () => {
    try {
      if (searchResults && searchResults.length > 0) {
        return (
          <ul>
            {searchResults.map((item, index) => {
              return (
                <li
                  key={item.geonameId}
                  className="aa-Item"
                  style={{
                    background:
                      selectedItemIndex === index
                        ? "rgba(254, 198, 0, 1)"
                        : "white",
                  }}
                  // style={{
                  //   background: "white",
                  // }}
                >
                  <div
                    style={{
                      padding: "10px",
                      minHeight: "40px",
                      cursor: "pointer",
                    }}
                    className="airport-code-items"
                    onClick={() => {
                      console.log("dispatch")
                      let a = [...tempTripDestinations]
                      a.push({
                        country: item.countryName,
                        place: item.name,
                        latlng: {
                          lat: parseFloat(item.lat),
                          lng: parseFloat(item.lng),
                        },
                      })
                      updateTripDestinations(a)
                      // dispatch({
                      //   type: "updateDestination",
                      //   data: {
                      //     country: item.countryName,
                      //     place: item.name,
                      //     latlng: {
                      //       lat: parseFloat(item.lat),
                      //       lng: parseFloat(item.lng),
                      //     },
                      //   },
                      //   maxDestinationsCount: maxDestinationsAllowed,
                      // })
                      setPlaceSearchValue("")
                      setSearchResults([])
                    }}
                  >
                    <nav className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ fontSize: "14px" }}>
                              {dottedText(item.name, 40)}
                            </div>
                            <div style={{ fontSize: "10px" }}>
                              {item.name === item.countryName
                                ? ""
                                : `${dottedText(item.adminName1, 40)},
                                ${dottedText(item.countryName, 20)}`}
                              {/* {dottedText(item.name, 40)},{" "}
                              {dottedText(item.countryName, 20)} */}
                            </div>
                            <div>
                              {/* <p
                                                  className="help"
                                                  style={{
                                                    marginTop: "-2px",
                                                  }}
                                                >
                                                  {item.fcodeName}
                                                </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="level-right">
                        <span
                          className="tag is-warning is-small"
                          style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.5px",
                            fontWeight: "500",
                            fontSize: "10px",
                            color: "rgba(74,74,74,0.8)",
                          }}
                        >
                          {" "}
                          {parsePlaceType(item)}
                        </span>
                      </div>
                    </nav>
                  </div>
                </li>
              )
            })}
          </ul>
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const changeTripDates = data => {
    try {
      var updateTravelDates = functions.httpsCallable("updateTravelDates")
      updateTravelDates(data)
        .then(async result => {
          // Read result of the Cloud Function.
          //   setBusy(false)
          setDatesUpdateInProgress(false)
          console.log({ result })
          if (result.data.success != undefined) {
            if (result.data.success === "trip dates changed") {
              dispatch({
                type: "updateTravelStartDate",
                data: newStartDate,
              })
              dispatch({
                type: "updateTravelEndDate",
                data: newEndDate,
              })
              dashboardDispatch({
                type: "updateTravelDates",
                startDate: new Date(newStartDate).toUTCString(),
                endDate: new Date(newEndDate).toUTCString(),
              })
            }
            setTravelDatesInEditMode(false)
          }
          if (result.data.error != undefined) {
            console.log("error")
          }
          // await updateTripState()
          //   console.log({ state })

          //   if (result.data.success != undefined) {
          //     setInvitee("")
          //     console.log("success")
          //     if (result.data.success === "collaborator invite sent") {
          //       setCollaborationInviteState("success-upon-singup")
          //     } else {
          //       let a = result.data.roles
          //       if (a != [] && a != undefined)
          //         dispatch({
          //           type: "addNewRoleEntry",
          //           data: a,
          //         })
          //       setCollaborationInviteState("success")
          //     }
          //   }
          //   if (result.data.error != undefined) {
          //     console.log("error")
          //     setCollaborationInviteState("error")
          //   }
          // ...
        })
        .catch(err => {
          console.log(err)
          setDatesUpdateInProgress(false)
          setTravelDatesInEditMode(false)
          //   setBusy(false)
          addToast(
            "Error updating new trip dates, please try again in sometime",
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
          //   setCollaborationInviteState("error")
        })
    } catch (error) {}
  }
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const validateAndHandleTripDatesUpdateReq = (startDate, endDate) => {
    try {
      setDatesUpdateInProgress(true)
      console.log({ startDate, endDate })
      if (startDate && endDate) {
        console.log({ startDate, endDate })
        let startDateUTC = new Date(startDate).toUTCString()
        let endDateUTC = new Date(endDate).toUTCString()
        let tripUID = state.tripUid
        let jobUID = state.designerDetails.jobId

        let currentEndDate = new Date(state.tripLogistics.travelDates.end)
        let currentStartDate = new Date(state.tripLogistics.travelDates.start)
        var totalCurrentTravelDays =
          Math.round(
            (currentEndDate - currentStartDate) / (24 * 60 * 60 * 1000)
          ) + 1

        let newEndDate = new Date(endDate)
        let NewStartDate = new Date(startDate)
        var totalNewTravelDays =
          Math.round((newEndDate - NewStartDate) / (24 * 60 * 60 * 1000)) + 1

        if (totalNewTravelDays < 1 || totalNewTravelDays > 30) {
          setTripDurationError(true)
          setDatesUpdateInProgress(false)
          return false
        }
        // console.log({ data })
        if (totalNewTravelDays >= totalCurrentTravelDays) {
          if (
            currentStartDate.toUTCString() === startDateUTC &&
            currentEndDate.toUTCString() !== endDateUTC
          ) {
            // Add at end
            let data = {
              tripUID: tripUID,
              jobUID: jobUID,
              startDate: startDateUTC,
              endDate: endDateUTC,
              newDaysCount:
                totalNewTravelDays > totalCurrentTravelDays
                  ? totalNewTravelDays - totalCurrentTravelDays
                  : totalNewTravelDays,
              totalCurrentTravelDays: totalCurrentTravelDays,
              totalNewTravelDays: totalNewTravelDays,
              type: "add-at-end", //Note: Do not change the string. Cloud function uses this to match operation.
            }
            // we are good make the call
            changeTripDates(data)
          }
          if (
            currentStartDate.toUTCString() !== startDateUTC &&
            currentEndDate.toUTCString() === endDateUTC
          ) {
            // Add at beginning
            let data = {
              tripUID: tripUID,
              jobUID: jobUID,
              startDate: startDateUTC,
              endDate: endDateUTC,
              newDaysCount:
                totalNewTravelDays > totalCurrentTravelDays
                  ? totalNewTravelDays - totalCurrentTravelDays
                  : totalNewTravelDays,
              totalCurrentTravelDays: totalCurrentTravelDays,
              totalNewTravelDays: totalNewTravelDays,
              type: "add-at-start", //Note: Do not change the string. Cloud function uses this to match operation.
            }
            // we are good make the call
            changeTripDates(data)
          }

          if (
            currentStartDate.toUTCString() !== startDateUTC &&
            currentEndDate.toUTCString() !== endDateUTC
          ) {
            let sDate = new Date(startDate)
            let eDate = new Date(endDate)
            if (sDate < currentStartDate && eDate > currentEndDate) {
              // Add at start & beginning
              console.log("add-at-start-and-end")
              let daysToAddAtEnd = Math.round(
                (eDate - currentEndDate) / (24 * 60 * 60 * 1000)
              )

              let daysToAddAtBeginning = Math.round(
                (currentStartDate - sDate) / (24 * 60 * 60 * 1000)
              )
              let data = {
                tripUID: tripUID,
                jobUID: jobUID,
                startDate: startDateUTC,
                endDate: endDateUTC,
                newDaysCount:
                  totalNewTravelDays > totalCurrentTravelDays
                    ? totalNewTravelDays - totalCurrentTravelDays
                    : totalNewTravelDays,
                totalCurrentTravelDays: totalCurrentTravelDays,
                totalNewTravelDays: totalNewTravelDays,
                daysToAddStart: daysToAddAtEnd,
                daysToAddAtEnd: daysToAddAtBeginning,
                type: "add-at-start-and-end", //Note: Do not change the string. Cloud function uses this to match operation.
              }
              // we are good make the call
              changeTripDates(data)
            }

            if (sDate < currentStartDate && eDate < currentEndDate) {
              // Shift dates and add at start for new
              console.log("shift-and-add-at-start")
              let data = {
                tripUID: tripUID,
                jobUID: jobUID,
                startDate: startDateUTC,
                endDate: endDateUTC,
                newDaysCount:
                  totalNewTravelDays >= totalCurrentTravelDays
                    ? totalNewTravelDays - totalCurrentTravelDays
                    : totalNewTravelDays,
                totalCurrentTravelDays: totalCurrentTravelDays,
                totalNewTravelDays: totalNewTravelDays,
                type: "shift-and-add-at-start", //Note: Do not change the string. Cloud function uses this to match operation.
              }
              // we are good make the call
              changeTripDates(data)
            }
            if (sDate > currentStartDate && eDate > currentEndDate) {
              // Shift dates and add at end for new
              console.log("shift-and-add-at-end")
              let data = {
                tripUID: tripUID,
                jobUID: jobUID,
                startDate: startDateUTC,
                endDate: endDateUTC,
                newDaysCount:
                  totalNewTravelDays > totalCurrentTravelDays
                    ? totalNewTravelDays - totalCurrentTravelDays
                    : totalNewTravelDays,
                totalCurrentTravelDays: totalCurrentTravelDays,
                totalNewTravelDays: totalNewTravelDays,
                type: "shift-and-add-at-end", //Note: Do not change the string. Cloud function uses this to match operation.
              }
              // we are good make the call
              changeTripDates(data)
            }
          }

          //     let sDate = new Date(startDate)
          // let eDate = new Date(endDate)
          // if (
          //   sDate > currentStartDate &&
          //   sDate < currentEndDate &&
          //   eDate > currentStartDate &&
          //   eDate < currentEndDate
          // ) {

          // }

          //   }
        } else {
          let currentSD = state.tripLogistics.travelDates.start
          let currentED = state.tripLogistics.travelDates.end

          if (
            currentStartDate.toUTCString() === startDateUTC &&
            currentEndDate.toUTCString() !== endDateUTC
          ) {
            // dates have been trimmed at the end of the trip.
            let dataLoss = false
            let dataLossIndexes = []
            let dataLossDays = []
            console.log("Dates trimmed at the end")
            let daysToTrim = totalCurrentTravelDays - totalNewTravelDays
            console.log({ daysToTrim })
            let startIndex = totalNewTravelDays
            for (
              let index = startIndex;
              index <
              dashboardState.currentJobInfo.designerWorkspace.itinerary.length;
              index++
            ) {
              if (
                dashboardState.currentJobInfo.designerWorkspace.itinerary[index]
                  .activities.length > 0
              ) {
                dataLoss = true
                dataLossIndexes.push(index)
                let j = new Date(
                  dashboardState.currentJobInfo.designerWorkspace.itinerary[
                    index
                  ].date
                )

                dataLossDays.push(`${months[j.getMonth()]} ${j.getDate()}`)
              }
            }
            if (dataLoss) {
              ConfirmationModal({
                onConfirm: () => {
                  let data = {
                    tripUID: tripUID,
                    jobUID: jobUID,
                    startDate: startDateUTC,
                    endDate: endDateUTC,
                    newDaysCount:
                      totalNewTravelDays > totalCurrentTravelDays
                        ? totalNewTravelDays - totalCurrentTravelDays
                        : totalNewTravelDays,
                    totalCurrentTravelDays: totalCurrentTravelDays,
                    totalNewTravelDays: totalNewTravelDays,
                    daysToTrim: daysToTrim,
                    type: "trim-end", //Note: Do not change the string. Cloud function uses this to match operation.
                  }
                  // we are good make the call
                  changeTripDates(data)
                },
                onExit: () => {
                  setTravelDatesInEditMode(false)
                  setNewStartDate(
                    new Date(state.tripLogistics.travelDates.start)
                  )
                  setNewEndDate(new Date(state.tripLogistics.travelDates.end))
                },
                title: "Are you sure?",
                text: `Itinerary on ${dataLossDays.join(
                  ", "
                )} will be deleted. Please move the activities before changing the dates.`,
                // cancelText: "No, dont change",
                confirmationText: "Yes, proceed",
              })
              console.log(
                `Are you sure? We'll have to delete the itinerary on ${dataLossDays.join(
                  ", "
                )}. Please move the activities before changing the date.`
              )
            } else {
              let data = {
                tripUID: tripUID,
                jobUID: jobUID,
                startDate: startDateUTC,
                endDate: endDateUTC,
                newDaysCount:
                  totalNewTravelDays > totalCurrentTravelDays
                    ? totalNewTravelDays - totalCurrentTravelDays
                    : totalNewTravelDays,
                totalCurrentTravelDays: totalCurrentTravelDays,
                totalNewTravelDays: totalNewTravelDays,
                daysToTrim: daysToTrim,
                type: "trim-end", //Note: Do not change the string. Cloud function uses this to match operation.
              }
              // we are good make the call
              changeTripDates(data)
            }
            console.log({ dataLoss })
            console.log({ dataLossIndexes })
          }
          if (
            currentEndDate.toUTCString() === endDateUTC &&
            currentStartDate.toUTCString() !== startDateUTC
          ) {
            // dates have been trimmed at the beginning of the trip.
            let dataLoss = false
            let dataLossIndexes = []
            let dataLossDays = []
            console.log("Dates trimmed at the beginning")
            let daysToTrim = totalCurrentTravelDays - totalNewTravelDays
            console.log({ daysToTrim })
            let startIndex = 0
            for (let index = startIndex; index < daysToTrim; index++) {
              if (
                dashboardState.currentJobInfo.designerWorkspace.itinerary[index]
                  .activities.length > 0
              ) {
                dataLoss = true
                dataLossIndexes.push(index)
                let j = new Date(
                  dashboardState.currentJobInfo.designerWorkspace.itinerary[
                    index
                  ].date
                )

                dataLossDays.push(`${months[j.getMonth()]} ${j.getDate()}`)
              }
            }
            if (dataLoss) {
              ConfirmationModal({
                onConfirm: () => {
                  let data = {
                    tripUID: tripUID,
                    jobUID: jobUID,
                    startDate: startDateUTC,
                    endDate: endDateUTC,
                    newDaysCount:
                      totalNewTravelDays > totalCurrentTravelDays
                        ? totalNewTravelDays - totalCurrentTravelDays
                        : totalNewTravelDays,
                    totalCurrentTravelDays: totalCurrentTravelDays,
                    totalNewTravelDays: totalNewTravelDays,
                    daysToTrim: daysToTrim,
                    type: "trim-start", //Note: Do not change the string. Cloud function uses this to match operation.
                  }
                  // we are good make the call
                  changeTripDates(data)
                },
                onExit: () => {
                  setTravelDatesInEditMode(false)
                  setNewStartDate(
                    new Date(state.tripLogistics.travelDates.start)
                  )
                  setNewEndDate(new Date(state.tripLogistics.travelDates.end))
                },
                title: "Are you sure?",
                text: `Itinerary on ${dataLossDays.join(
                  ", "
                )} will be deleted. Please move the activities before changing the dates.`,
                // cancelText: "No, dont change",
                confirmationText: "Yes, proceed",
              })
              console.log(
                `Are you sure? We'll have to delete the itinerary on ${dataLossDays.join(
                  ", "
                )}. Please move the activities before changing the date.`
              )
            } else {
              let data = {
                tripUID: tripUID,
                jobUID: jobUID,
                startDate: startDateUTC,
                endDate: endDateUTC,
                newDaysCount:
                  totalNewTravelDays > totalCurrentTravelDays
                    ? totalNewTravelDays - totalCurrentTravelDays
                    : totalNewTravelDays,
                totalCurrentTravelDays: totalCurrentTravelDays,
                totalNewTravelDays: totalNewTravelDays,
                daysToTrim: daysToTrim,
                type: "trim-start", //Note: Do not change the string. Cloud function uses this to match operation.
              }
              // we are good make the call
              changeTripDates(data)
            }
            console.log({ dataLoss })
            console.log({ dataLossIndexes })
          }

          if (
            currentEndDate.toUTCString() !== endDateUTC &&
            currentStartDate.toUTCString() !== startDateUTC
          ) {
            // dates have been trimmed at both the beginning & end of the trip.
            let sDate = new Date(startDate)
            let eDate = new Date(endDate)

            if (
              (sDate < currentStartDate && eDate < currentEndDate) ||
              (sDate > currentStartDate && eDate > currentEndDate)
            ) {
              let dataLoss = false
              let dataLossIndexes = []
              let dataLossDays = []
              console.log("Shift and trim start")
              let daysToTrim = totalCurrentTravelDays - totalNewTravelDays
              let daysToTrimAtBeginning = Math.round(
                (sDate - currentStartDate) / (24 * 60 * 60 * 1000)
              )

              console.log({ daysToTrim })

              for (
                let index = totalNewTravelDays;
                index <
                dashboardState.currentJobInfo.designerWorkspace.itinerary
                  .length;
                index++
              ) {
                if (
                  dashboardState.currentJobInfo.designerWorkspace.itinerary[
                    index
                  ].activities.length > 0
                ) {
                  dataLoss = true
                  dataLossIndexes.push(index)
                  let j = new Date(
                    dashboardState.currentJobInfo.designerWorkspace.itinerary[
                      index
                    ].date
                  )

                  dataLossDays.push(`${months[j.getMonth()]} ${j.getDate()}`)
                }
              }
              if (dataLoss) {
                ConfirmationModal({
                  onConfirm: () => {
                    let data = {
                      tripUID: tripUID,
                      jobUID: jobUID,
                      startDate: startDateUTC,
                      endDate: endDateUTC,
                      newDaysCount:
                        totalNewTravelDays > totalCurrentTravelDays
                          ? totalNewTravelDays - totalCurrentTravelDays
                          : totalNewTravelDays,
                      totalCurrentTravelDays: totalCurrentTravelDays,
                      totalNewTravelDays: totalNewTravelDays,
                      type: "shift-and-trim-end", //Note: Do not change the string. Cloud function uses this to match operation.
                    }
                    // we are good make the call
                    changeTripDates(data)
                  },
                  onExit: () => {
                    setTravelDatesInEditMode(false)
                    setNewStartDate(
                      new Date(state.tripLogistics.travelDates.start)
                    )
                    setNewEndDate(new Date(state.tripLogistics.travelDates.end))
                  },
                  title: "Are you sure?",
                  text: `Itinerary on ${dataLossDays.join(
                    ", "
                  )} will be deleted. Please move the activities before changing the dates.`,
                  // cancelText: "No, dont change",
                  confirmationText: "Yes, proceed",
                })
                console.log(
                  `Are you sure? We'll have to delete the itinerary on ${dataLossDays.join(
                    ", "
                  )}. Please move the activities before changing the date.`
                )
              } else {
                let data = {
                  tripUID: tripUID,
                  jobUID: jobUID,
                  startDate: startDateUTC,
                  endDate: endDateUTC,
                  newDaysCount:
                    totalNewTravelDays > totalCurrentTravelDays
                      ? totalNewTravelDays - totalCurrentTravelDays
                      : totalNewTravelDays,
                  totalCurrentTravelDays: totalCurrentTravelDays,
                  totalNewTravelDays: totalNewTravelDays,
                  type: "shift-and-trim-end", //Note: Do not change the string. Cloud function uses this to match operation.
                }
                // we are good make the call
                changeTripDates(data)
              }
            }

            if (
              sDate > currentStartDate &&
              sDate < currentEndDate &&
              eDate > currentStartDate &&
              eDate < currentEndDate
            ) {
              //new dates are inside the existing date range
              let dataLoss = false
              let dataLossIndexes = []
              let dataLossDays = []
              console.log("Dates trimmed both at the beginning & end")
              let daysToTrimAtEnd = Math.round(
                (currentEndDate - eDate) / (24 * 60 * 60 * 1000)
              )

              let daysToTrimAtBeginning = Math.round(
                (sDate - currentStartDate) / (24 * 60 * 60 * 1000)
              )

              console.log({ daysToTrimAtEnd })
              console.log({ daysToTrimAtBeginning })

              for (let index = 0; index < daysToTrimAtBeginning; index++) {
                if (
                  dashboardState.currentJobInfo.designerWorkspace.itinerary[
                    index
                  ].activities.length > 0
                ) {
                  dataLoss = true
                  dataLossIndexes.push(index)
                  let j = new Date(
                    dashboardState.currentJobInfo.designerWorkspace.itinerary[
                      index
                    ].date
                  )

                  dataLossDays.push(`${months[j.getMonth()]} ${j.getDate()}`)
                }
              }

              for (
                let index = totalCurrentTravelDays - daysToTrimAtEnd;
                index <
                dashboardState.currentJobInfo.designerWorkspace.itinerary
                  .length;
                index++
              ) {
                if (
                  dashboardState.currentJobInfo.designerWorkspace.itinerary[
                    index
                  ].activities.length > 0
                ) {
                  dataLoss = true
                  dataLossIndexes.push(index)
                  let j = new Date(
                    dashboardState.currentJobInfo.designerWorkspace.itinerary[
                      index
                    ].date
                  )

                  dataLossDays.push(`${months[j.getMonth()]} ${j.getDate()}`)
                }
              }
              if (dataLoss) {
                ConfirmationModal({
                  onConfirm: () => {
                    let data = {
                      tripUID: tripUID,
                      jobUID: jobUID,
                      startDate: startDateUTC,
                      endDate: endDateUTC,
                      newDaysCount:
                        totalNewTravelDays > totalCurrentTravelDays
                          ? totalNewTravelDays - totalCurrentTravelDays
                          : totalNewTravelDays,
                      totalCurrentTravelDays: totalCurrentTravelDays,
                      totalNewTravelDays: totalNewTravelDays,
                      daysToTrimStart: daysToTrimAtBeginning,
                      daysToTrimAtEnd: daysToTrimAtEnd,
                      type: "trim-start-and-end", //Note: Do not change the string. Cloud function uses this to match operation.
                    }
                    // we are good make the call
                    changeTripDates(data)
                  },

                  title: "Are you sure?",
                  text: `Itinerary on ${dataLossDays.join(
                    ", "
                  )} will be deleted. Please move the activities before changing the dates.`,
                  // cancelText: "No, dont change",
                  confirmationText: "Yes, proceed",
                })
                console.log(
                  `Are you sure? We'll have to delete the itinerary on ${dataLossDays.join(
                    ", "
                  )}. Please move the activities before changing the date.`
                )
              } else {
                let data = {
                  tripUID: tripUID,
                  jobUID: jobUID,
                  startDate: startDateUTC,
                  endDate: endDateUTC,
                  newDaysCount:
                    totalNewTravelDays > totalCurrentTravelDays
                      ? totalNewTravelDays - totalCurrentTravelDays
                      : totalNewTravelDays,
                  totalCurrentTravelDays: totalCurrentTravelDays,
                  totalNewTravelDays: totalNewTravelDays,
                  daysToTrimAtBeginning: daysToTrimAtBeginning,
                  daysToTrimAtEnd: daysToTrimAtEnd,
                  type: "trim-start-and-end", //Note: Do not change the string. Cloud function uses this to match operation.
                }
                // we are good make the call
                changeTripDates(data)
              }
              console.log({ dataLoss })
              console.log({ dataLossIndexes })
            }
          }
          // state.currentJobInfo.designerWorkspace.itinerary[20].activities
          //Confirm with the user that there will be loss of current itinerary as the new number of days are shorter.
          //   onClick={() => {
          //     ConfirmationModal({
          //       onConfirm,
          //       title: "Delete Confirmation",
          //       text: `Would you like to permanently delete this trip?`,
          //     })
        }
      } else {
        setDatesUpdateInProgress(false)
      }
    } catch (err) {
      console.log(err)
      setDatesUpdateInProgress(false)
      setTravelDatesInEditMode(false)
    }

    //
  }

  const handleDatesChange = ({ startDate, endDate }) => {
    try {
      console.log("handleDatesChange")
      console.log({ startDate })
      console.log({ endDate })
      setDateRangeError(false)
      setTripDurationError(false)
      //   if (startDate < moment() || endDate < moment()) return
      if (startDate) setNewStartDate(startDate)
      if (endDate) setNewEndDate(endDate)
    } catch (err) {
      console.log(err)
    }

    //
  }
  const showDestinationTags = () => {
    if (tempTripDestinations) {
      if (tempTripDestinations.length === 0) return ""
      return tempTripDestinations.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className="tags are-small has-addons place-tags"
              style={{
                marginTop: "15px",
                marginRight: "10px",
                display: "inline-flex",
                position: "relative",

                //width: "50%",
              }}
            >
              {/* <p
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  left: "0px",
                  top: "-17px",
                }}
              >
                {moment.localeData().ordinal(index + 1)} Destination
              </p> */}
              <span className="tag is-warning">{item.place}</span>
              <span className="tag is-warning">
                {item.place === item.country ? "" : item.country}
              </span>
              <a
                id={index}
                className="tag is-delete is-loading"
                onClick={e => {
                  if (tempTripDestinations.length === 1) {
                    ConfirmationModal({
                      onConfirm: () => {},
                      onExit: () => {},
                      hideCancel: true,
                      title: "At least one destination required",
                      text: `Please add another destination before deleting this one.`,
                      // cancelText: "No, dont change",
                      confirmationText: "Ok",
                    })
                  } else
                    ConfirmationModal({
                      onConfirm: i => {
                        let a = [...tempTripDestinations]
                        a.splice(i, 1)
                        updateTripDestinations(a)
                      },
                      onExit: () => {},
                      sendBackValue: e.currentTarget.id,
                      title: "Confirmation",
                      text: `Are you sure you want to remove ${
                        tempTripDestinations[e.currentTarget.id].place
                      }?`,
                      // cancelText: "No, dont change",
                      confirmationText: "Yes, proceed",
                    })
                  // setTempTripDestinations(a)
                }}
              ></a>
              {showUpdateDestinationsLoading && (
                <span
                  className="button is-small is-loading"
                  style={{
                    width: " 24px",
                    height: " 24px",
                    marginBottom: " 8px",
                    border: "none",
                    background: "whitsmoke",
                    position: "abolute",
                    right: "0px",
                  }}
                ></span>
              )}
              {/* <iframe
                  title="Paris"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=7&q=${item.place}+${item.country}`}
                  width="200px"
                  height="200px"
                  allowFullScreen
                ></iframe> */}
            </div>
          </>
        )
      })
    }
  }
  const formatStartDate = () => {
    try {
      if (state.tripLogistics.travelDates.start) {
        return [
          state.tripLogistics.travelDates.start === ""
            ? new Date()
            : state.tripLogistics.travelDates.start.toDate(),
        ]
      } else return [new Date()]
    } catch (error) {
      // console.log(state.tripLogistics.travelDates.start)
      // console.log(state.tripLogistics.travelDates.end)
      if (state.tripLogistics.travelDates.start) {
        return [
          state.tripLogistics.travelDates.start === ""
            ? new Date()
            : state.tripLogistics.travelDates.start,
        ]
      } else {
        return [new Date()]
      }
    }
  }
  const formatEndDate = () => {
    try {
      if (state.tripLogistics.travelDates.end) {
        return [
          state.tripLogistics.travelDates.end === ""
            ? new Date()
            : state.tripLogistics.travelDates.end.toDate(),
        ]
      } else return [new Date()]
    } catch (error) {
      // console.log(error)
      if (state.tripLogistics.travelDates.end) {
        return [
          state.tripLogistics.travelDates.end === ""
            ? new Date()
            : state.tripLogistics.travelDates.end,
        ]
      } else {
        return [new Date()]
      }
    }
  }
  // To handle the UP, DOWN and ENTER key press results in the search bar.
  const keyChanged = e => {
    // console.log({ selectedItemIndex })
    // console.log(e.keyCode)
    try {
      switch (e.keyCode) {
        case 27: // ESC
          return
        case 38: // UP
          if (selectedItemIndex > 0) {
            // clearFocus()
            var a = selectedItemIndex - 1
            setSelectedItemIndex(a)
          }
          break
        case 40: // DOWN
          if (selectedItemIndex < searchResults.length - 1) {
            var a = selectedItemIndex + 1
            // if (selectedItemIndex != -1) clearFocus()
            setSelectedItemIndex(a)
          }
          break
        case 13: //ENTER
          if (selectedItemIndex != -1) {
            let a = [...tempTripDestinations]
            a.push({
              country: searchResults[selectedItemIndex].countryName,
              place: searchResults[selectedItemIndex].name,
              latlng: {
                lat: parseFloat(searchResults[selectedItemIndex].lat),
                lng: parseFloat(searchResults[selectedItemIndex].lng),
              },
            })
            updateTripDestinations(a)

            // dispatch({
            //   type: "updateDestination",
            //   data: {
            //     country: searchResults[selectedItemIndex].countryName,
            //     place: searchResults[selectedItemIndex].name,
            //     latlng: {
            //       lat: parseFloat(searchResults[selectedItemIndex].lat),
            //       lng: parseFloat(searchResults[selectedItemIndex].lng),
            //     },
            //   },
            //   maxDestinationsCount: maxDestinationsAllowed,
            // })
            setPlaceSearchValue("")
            setSearchResults([])
          }
          break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div>
      <div>
        <div style={{}}>
          <div style={{ marginBottom: "50px" }}>
            <p
              style={{
                marginBottom: "15px",
                fontWeight: "600",
                borderBottom: "solid 2px #fec600",
                fontSize: "18px",
              }}
            >
              Trip Destinations
            </p>
            {showUpdateDestinationsLoading ? (
              <div>
                <div className="spinner" style={{ zIndex: "20" }}>
                  <div className="rect1"></div>
                  <div className="rect2"></div>
                  <div className="rect3"></div>
                  <div className="rect4"></div>
                  <div className="rect5"></div>
                </div>
              </div>
            ) : (
              <div>
                {/* {!showNewDestinationSearch && (
                  <div
                    className=""
                    style={{ display: "flex", margin: "20px auto" }}
                  >
                    <button
                      onClick={() => {
                        setShowNewDestinationSearch(true)
                      }}
                      className="button"
                    >
                      Add a new destination
                    </button>
                  </div>
                )} */}
                {showNewDestinationSearch && (
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      margin: "20px auto",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        // marginRight: "10px",
                      }}
                    >
                      <div
                        class={`${
                          showPlaceLoading ? "control is-loading" : "control"
                        }`}
                      >
                        <input
                          value={placeSearchValue}
                          type="text"
                          autoFocus
                          placeholder="e.g. Las Vegas, Paris, Japan"
                          disabled={maxDestinationCountReached}
                          className="input"
                          style={{ paddingRight: "10px", fontSize: "14px" }}
                          onBlur={() => {
                            setTimeout(() => {
                              let a = document.getElementById(
                                "place-results-panel"
                              )
                              if (a) a.style.display = "none"
                            }, 300)
                          }}
                          onKeyDown={keyChanged}
                          onFocus={() => {
                            setDestinationError(false)
                            let a = document.getElementById(
                              "place-results-panel"
                            )
                            if (a) a.style.display = "block"
                            // console.log("onFocus")
                          }}
                          onChange={e => {
                            setDestinationError(false)
                            let value = e.currentTarget.value
                            if (value) {
                              setPlaceSearchValue(value)
                              if (value.length > 2) debounceSearch(value)
                            } else {
                              setPlaceSearchValue("")
                              setSearchResults([])
                              setShowNoResults(false)
                            }
                          }}
                        />
                        {searchResults.length > 0 && (
                          <div
                            className="aa-Panel"
                            style={{ marginTop: "3px" }}
                            id="place-results-panel"
                          >
                            {showSearchResults()}
                          </div>
                        )}
                        {showNoResults && (
                          <div
                            className="aa-Panel"
                            style={{ marginTop: "3px" }}
                            id="place-results-panel"
                          >
                            <p
                              style={{ padding: "5px 10px", fontSize: "14px" }}
                            >
                              No Results
                            </p>
                          </div>
                        )}
                        {placeSearchValue.length > 0 && !showPlaceLoading ? (
                          <span
                            className="icon is-medium is-right"
                            style={{
                              position: "absolute",
                              right: "0px",
                              top: "4px",
                            }}
                            onClick={() => {
                              setPlaceSearchValue("")
                              setSearchResults([])
                              setShowNoResults(false)
                            }}
                          >
                            <i
                              style={{
                                color: "#fec600",
                                cursor: "pointer",
                                zIndex: "99",
                              }}
                              className="far fa-times"
                            ></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div style={{ marginTop: "7px" }}>
                      <button
                        className="button"
                        onClick={() => {
                          setShowNewDestinationSearch(false)
                        }}
                        style={{ height: "35px", fontSize: "14px" }}
                      >
                        Cancel
                      </button>
                    </div>
                    {maxDestinationCountReached ? (
                      <p className="help">
                        {" "}
                        <i className="fas fa-exclamation-circle"></i> Reached
                        maximum destinations allowed
                      </p>
                    ) : (
                      ""
                    )}
                    {destinationError ? (
                      <p className="help is-danger">
                        {" "}
                        <i className="fas fa-exclamation-circle"></i> Please
                        pick at least one destination
                      </p>
                    ) : (
                      <p className="help">
                        {/* Type your destination cities.
               Type slowly to select a city from one of the options on
               the list. Note: You can select multiple cities */}
                      </p>
                    )}

                    {/* <div style={{ marginTop: "10px", marginBottom: "5px" }}>
                {showDestinationTags()}
              </div> */}
                  </div>
                )}
                <p
                  style={{
                    // borderBottom: "solid 1px #fec600",
                    marginBottom: "0px",
                    marginTop: "30px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Current Destinations:
                </p>
                <div>
                  {showDestinationTags()}{" "}
                  {!showNewDestinationSearch && (
                    <button
                      onClick={() => {
                        setShowNewDestinationSearch(true)
                      }}
                      style={{ height: "24px", marginTop: "15px" }}
                      className="button is-warning is-small is-light"
                    >
                      <i
                        className="far fa-plus"
                        style={{ marginRight: "5px" }}
                      ></i>{" "}
                      Add a new destination
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* {showRightCollaborationInviteScreen()} */}
        </div>
      </div>
      <p
        style={{
          marginBottom: "15px",
          fontWeight: "600",
          borderBottom: "solid 2px #fec600",
          fontSize: "18px",
        }}
      >
        Travel Dates
      </p>
      {travelDatesInEditMode ? (
        <div className="control">
          <div style={{ display: "" }}>
            <span style={{ marginRight: "0px", marginBottom: "15px" }}>
              <DateRangePicker
                startDate={moment(newStartDate)}
                withPortal={is.mobile() ? true : false}
                withFullScreenPortal={is.mobile() ? true : false}
                orientation={is.mobile() ? "verticalScrollable" : "horizontal"}
                startDateId="tata-start-date"
                endDate={moment(newEndDate)}
                endDateId="tata-end-date"
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => {
                  console.log({ focusedInput })
                  setFocusedInput(focusedInput)
                }}
                minDate={moment().add(5, "d")}
                readOnly={is.mobile() ? true : false}
                numberOfMonths={is.mobile() ? 24 : 2}
                disableScroll={false}
                navPrev={is.mobile() ? <></> : ""}
                navNext={is.mobile() ? <></> : ""}
              />
              {dateRangeError || tripDurationError ? (
                <p
                  className="help is-danger"
                  // style={{ display: "inline-block" }}
                >
                  <i className="fas fa-exclamation-circle"></i> Please enter
                  valid itinerary dates.
                </p>
              ) : (
                ""
                // <p className="help">pick all that apply</p>
              )}
            </span>
            <div style={{ marginTop: "15px" }}>
              <div class="buttons">
                <button
                  class="button is-small"
                  style={{ height: "40px" }}
                  onClick={() => {
                    setTravelDatesInEditMode(false)
                    setNewStartDate(
                      new Date(state.tripLogistics.travelDates.start)
                    )
                    setNewEndDate(new Date(state.tripLogistics.travelDates.end))
                  }}
                >
                  Cancel
                </button>
                <button
                  class={`${
                    datesUpdateInProgress
                      ? "button is-loading is-small is-warning"
                      : "button is-small is-warning"
                  }`}
                  style={{ height: "40px" }}
                  onClick={() => {
                    if (datesUpdateInProgress) return
                    validateAndHandleTripDatesUpdateReq(
                      newStartDate,
                      newEndDate
                    )
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div style={{}}>
            <div style={{ marginBottom: "50px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "10px", fontSize: "14px" }}>
                  {getDates(state)}
                </div>
                <button
                  style={{ fontSize: "12px" }}
                  className="button is-small is-warning"
                  onClick={() => {
                    setTravelDatesInEditMode(true)
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
            {/* {showRightCollaborationInviteScreen()} */}
          </div>
        </div>
      )}
    </div>
  )
}

export default TripDetailsSettings
