import React, { useState, useEffect, useContext, useMemo } from "react"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"
import DesignerCard from "../../components/TripCards/DesignerCard"
import { DebounceInput } from "react-debounce-input"
import {
  useProfileStateStore,
  ProfileStateProvider,
} from "../../State/profile-state/profile-state"
import { ToastProvider, useToasts } from "react-toast-notifications"
import "./ShowcaseDesigners.scss"
import algoliasearch from "algoliasearch/lite"
import Map from "../../components/Map/Map"
import Unsplash from "react-unsplash-wrapper"
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectHits,
  connectSearchBox,
  PoweredBy,
  RatingMenu,
  RefinementList,
  HitsPerPage,
  connectHitsPerPage,
  Pagination,
  connectPagination,
  connectRefinementList,
  Highlight,
  connectPoweredBy,
} from "react-instantsearch-dom"
import Select from "react-select"
import algoliaImg from "./../../images/search-by-algolia.png"
import { getCodes } from "../../utility/general"
import DesignerCardNew from "../../components/TripCards/DesignerCardNew"
const searchClient = algoliasearch(
  `${process.env.ALGOLIA_SEARCH_APP_ID}`,
  `${process.env.ALGOLIA_SEARCH_API_KEY}`
)
const ShowcaseDesignersBase = ({ onSelect, destination }) => {
  const [loading, setLoading] = useState(true)
  const { addToast } = useToasts()
  const [selectedDestination, setSelectedDestination] = useState("")
  useEffect(() => {
    console.log("reloading")
  }, [])

  const showDesigners = ({ hits }) => {
    return hits.map((item, index) => {
      if (item.doNotShow) return ""
      else
        return (
          <div className="" style={{}}>
            <DesignerCardNew key={index} hit={item} onSelect={onSelect} />
          </div>
        )
    })
  }
  // const showDesigners = ({ hits }) => {
  //   return hits.map((item, index) => {
  //     if (item.doNotShow) return ""
  //     else return <DesignerCard key={index} hit={item} onSelect={onSelect} />
  //   })
  // }

  function getPagingRange(current, { min = 1, total = 20, length = 5 } = {}) {
    if (length > total) length = total

    let start = current - Math.floor(length / 2)
    start = Math.max(start, min)
    start = Math.min(start, min + total - length)

    return Array.from({ length: length }, (el, i) => start + i)
  }

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => {
    // return the DOM output

    return nbPages > 1 ? (
      <nav
        className="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        <a
          className="pagination-previous"
          onClick={event => {
            event.preventDefault()
            if (currentRefinement > 1) refine(currentRefinement - 1)
          }}
        >
          Previous
        </a>
        <a
          className="pagination-next"
          onClick={event => {
            event.preventDefault()
            if (currentRefinement < nbPages) refine(currentRefinement + 1)
          }}
        >
          Next page
        </a>
        <ul className="pagination-list">
          {getPagingRange(currentRefinement, {
            min: 1,
            total: nbPages,
            length: 5,
          }).map((i, index) => {
            const page = i
            const style = {
              fontWeight: currentRefinement === page ? "bold" : "",
            }
            console.log({ i })
            console.log({ currentRefinement })
            return (
              <li key={i}>
                <a
                  className={`pagination-link ${
                    currentRefinement === page ? "is-current" : ""
                  }`}
                  href={createURL(page)}
                  style={style}
                  onClick={event => {
                    event.preventDefault()
                    refine(page)
                  }}
                >
                  {page}
                </a>
              </li>
            )
          })}
        </ul>
      </nav>
    ) : (
      ""
    )
  }

  const CustomPagination = connectPagination(Pagination)

  const PoweredBy = ({ url }) => (
    <a href={url} style={{ color: "#4a4a4a", fontSize: "14px" }}>
      <img src={algoliaImg} alt="algolia" style={{ maxHeight: "18px" }} />
    </a>
  )
  const CustomPoweredBy = connectPoweredBy(PoweredBy)

  const RefinementList = ({
    items,
    currentRefinement,
    refine,
    isFromSearch,
    searchForItems,
    createURL,
    showMore,
    limit,
    translations,
  }) => {
    const [extended, setExtended] = useState(false)
    return (
      <>
        {/* <div>
          Tags selected: {currentRefinement ? currentRefinement.join(", ") : ""}
        </div> */}
        <ul style={{ color: "#4a4a4a" }}>
          <li>
            <div className="field" style={{ marginBottom: "10px" }}>
              <div className="control has-icons-right">
                <input
                  className="input is-warning is-small"
                  type="search"
                  onChange={event => searchForItems(event.currentTarget.value)}
                  placeholder="search"
                />
                <span
                  className="icon is-small is-right"
                  // style={{
                  //   position: "absolute",
                  //   right: "10px",
                  //   top: "7px",
                  //   color: "#4a4a4a",
                  // }}
                >
                  <i className="far fa-search"></i>
                </span>
              </div>
            </div>
          </li>
          {items
            ? items.map(
                (item, i) =>
                  (i < limit || extended) && (
                    <li key={item.label}>
                      <a
                        href={createURL(item.value)}
                        style={{
                          fontWeight: item.isRefined ? "bold" : "",
                          color: "#4a4a4a",
                        }}
                        onClick={event => {
                          event.preventDefault()
                          refine(item.value)
                        }}
                      >
                        {/* <input
                type="checkbox"
                style={{ marginRight: "5px" }}
                checked={true}
              ></input> */}
                        {item.isRefined ? (
                          <i
                            className="far fa-check-square fa-lg"
                            style={{ color: "#fec600", marginRight: "5px" }}
                          ></i>
                        ) : (
                          <i
                            className="far fa-square fa-lg"
                            style={{ color: "#fec600", marginRight: "5px" }}
                          ></i>
                        )}
                        {isFromSearch ? (
                          <Highlight attribute="label" hit={item} />
                        ) : (
                          item.label
                        )}{" "}
                        ({item.count})
                      </a>
                    </li>
                  )
              )
            : ""}

          {/* <button onClick={toggleShowMore}>show more</button> */}
        </ul>
        {showMore && (
          <button
            onClick={() => {
              setExtended(!extended)
            }}
            className="button is-small is-warning"
            style={{ margin: "10px 0" }}
          >
            {translations["showMore"](extended)}
          </button>
        )}
      </>
    )
  }

  const CustomRefinementList = connectRefinementList(RefinementList)
  // const HitsPerPage = ({ items, currentRefinement, refine, createURL }) => (
  //   <div>
  //     {/* <p>The search display {currentRefinement} hits.</p> */}
  //     <Select
  //       // defaultValue={vacationPacing.find(
  //       //   element => element.label === state.tripPreferences.pacing
  //       // )}
  //       classNamePrefix="select"
  //       isSearchable="false"
  //       isClearable="false"
  //       onChange={(value, actionMeta) => {}}
  //       options={items}
  //       theme={theme => ({
  //         ...theme,
  //         colors: {
  //           ...theme.colors,
  //           primary25: "#fef0bf",
  //           primary: "#fed43f",
  //           primary50: "#fee27f",
  //           //neutral0: "#424242",
  //         },
  //       })}
  //     />
  //     <ul>
  //       {items.map(item => (
  //         <li key={item.value}>
  //           <a
  //             href={createURL(item.value)}
  //             style={{ fontWeight: item.isRefined ? "bold" : "" }}
  //             onClick={event => {
  //               event.preventDefault()
  //               refine(item.value)
  //             }}
  //           >
  //             {item.label}
  //           </a>
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // )
  // const CustomHitsPerPage = connectHitsPerPage(HitsPerPage)
  const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
    return (
      <div className="field has-addons" style={{ marginTop: "15px" }}>
        {/* <p className="control">
          <a className="button is-static force-brand-color">
            <i className="fas fa-search"></i>
          </a>
        </p> */}
        <div className="control is-expanded" style={{ position: "relative" }}>
          <input
            className="input"
            value={currentRefinement}
            type="text"
            // placeholder="Search..."
            style={{
              position: "relative",
              // paddingRight: "107px",
              // border: "none",
              border: "1px solid #fec600",
              borderRadius: "40px",
              boxShadow: "none",
              minWidth: "250px",
            }}
            onChange={e => refine(e.currentTarget.value)}
          />
          <p className="help" style={{ marginLeft: "20px" }}>
            Filter by destination country.
          </p>
          <span
            style={{
              position: "absolute",
              right: "20px",
              top: "-20px",
              color: "#4a4a4a",
              zIndex: "10",
            }}
          >
            <CustomPoweredBy />
          </span>
          <button
            className="button is-medium"
            onClick={() => refine("")}
            style={{
              position: "absolute",
              right: "5px",
              top: "-5px",
              border: "none",
              background: "transparent",
              // border: "none",
              // borderBottom: "1px solid #fec600",
              // borderRadius: "0px",
            }}
          >
            <span className="icon is-small">
              <i className="fal fa-times" style={{ color: "#fec600" }}></i>
            </span>
          </button>
        </div>

        {isSearchStalled ? "My search is stalled" : ""}
      </div>
    )
  }
  const CustomSearchBox = connectSearchBox(SearchBox)
  const CustomHits = connectHits(showDesigners)

  const HitsPerPage = ({ items, currentRefinement, refine, createURL }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "22px",
        marginLeft: "10px",
        marginTop: "-20px",
      }}
    >
      {/* <p>The search display {currentRefinement} hits.</p> */}
      <div
        style={{
          fontSize: "12px",
        }}
      >
        Results per page
      </div>
      <div
        style={{
          width: "80px",
          fontSize: "14px",
          marginLeft: "5px",
        }}
      >
        <Select
          defaultValue={items.find(element => element.isRefined === true)}
          classNamePrefix="select"
          isSearchable="false"
          isClearable={false}
          onChange={(value, actionMeta) => {
            if (value) refine(value.value)
          }}
          options={items}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#fef0bf",
              primary: "#fed43f",
              primary50: "#fee27f",
              //neutral0: "#424242",
            },
          })}
        />
      </div>
      {/* <ul>
        {items.map(item => (
          <li key={item.value}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={event => {
                event.preventDefault()
                refine(item.value)
              }}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul> */}
    </div>
  )
  const CustomHitsPerPage = connectHitsPerPage(HitsPerPage)
  const VirtualRefinementList = connectRefinementList(() => null)
  return (
    <div>
      {/* <button className="button" onClick={getData}>
        fetch
      </button> */}
      {/* <Map /> */}
      <div className="columns is-mobile">
        <div className="column is-hidden-desktop is-hidden-tablet is-hidden-mobile">
          {/* <div className="filter-container">
            <div className="header">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "Center",
                }}
              >
                Filters
              </p>
            </div>
            <div className="contents">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  textAlign: "Center",
                }}
              ></p>
            </div>
          </div> */}
        </div>
        <div
          className="column is-12-desktop is-12-tablet is-12-mobile"
          style={{ marginBottom: "40px" }}
        >
          <InstantSearch
            refresh={true}
            indexName="designers"
            searchClient={searchClient}
          >
            {/* <p
              style={{
                fontSize: "22px",
                // marginRight: "10px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              Featured Destinations
            </p> */}
            {/* <div
              style={{
                display: "flex",
                // justifyContent: "flex",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  width: "120px",
                  marginRight: "20px",
                  // border: "3px solid #fec600",
                  marginBottom: "10px",
                }}
              >
                <Unsplash
                  width="120"
                  height="120"
                  photoId="_UIN-pFfJ7c"
                  className="featured-destination-icons"
                  style={{ borderRadius: "10px", border: "3px solid #fec600" }}
                />
                <p style={{ textAlign: "center", marginTop: "5px" }}>Kyoto</p>
              </div>
              <div
                style={{
                  width: "120px",
                  marginRight: "20px",

                  marginBottom: "10px",
                }}
              >
                <Unsplash
                  width="120"
                  height="120"
                  photoId="PO7CGnoDFUI"
                  className="featured-destination-icons"
                  style={{ borderRadius: "10px", border: "3px solid #fec600" }}
                />
                <p style={{ textAlign: "center", marginTop: "5px" }}>Peru</p>
              </div>
              <div
                // className="featured-destination-icons"
                style={{
                  width: "120px",
                  marginRight: "20px",

                  marginBottom: "10px",
                }}
              >
                <Unsplash
                  width="120"
                  height="120"
                  photoId="9xjdQ8-zLKI"
                  className="featured-destination-icons"
                  style={{
                    borderRadius: "10px",
                    border: "3px solid #fec600",
                    // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                />
                <p style={{ textAlign: "center", marginTop: "5px" }}>
                  Las Vegas
                </p>
              </div>
              <div
                style={{
                  width: "120px",
                  marginRight: "20px",

                  marginBottom: "10px",
                }}
              >
                <Unsplash
                  width="120"
                  height="120"
                  photoId="s87bBFZviAU"
                  className="featured-destination-icons"
                  style={{ borderRadius: "10px", border: "3px solid #fec600" }}
                />
                <p style={{ textAlign: "center", marginTop: "5px" }}>Rome</p>
              </div>
              <div
                style={{
                  width: "120px",
                  marginRight: "20px",

                  marginBottom: "10px",
                }}
              >
                <Unsplash
                  width="120"
                  height="120"
                  photoId="einIuvtZen4"
                  className="featured-destination-icons"
                  style={{ borderRadius: "10px", border: "3px solid #fec600" }}
                />
                <p style={{ textAlign: "center", marginTop: "5px" }}>Sydney</p>
              </div>
              <div
                style={{
                  width: "120px",
                  marginRight: "20px",

                  marginBottom: "10px",
                }}
              >
                <Unsplash
                  width="120"
                  height="120"
                  photoId="2KPmrv0iyAI"
                  className="featured-destination-icons"
                  style={{ borderRadius: "10px", border: "3px solid #fec600" }}
                />
                <p style={{ textAlign: "center", marginTop: "5px" }}>
                  New York
                </p>
              </div>
            </div> */}
            {/* <button
              className="button"
              onClick={() => {
                setSelectedDestination("japan")
              }}
            >
              Kyoto
            </button>
            <button
              className="button"
              onClick={() => {
                setSelectedDestination("Peru")
              }}
            >
              Peru
            </button>
            <button
              className="button"
              onClick={() => {
                setSelectedDestination("USA")
              }}
            >
              Las Vegas
            </button>
            <button
              className="button"
              onClick={() => {
                setSelectedDestination("Italy")
              }}
            >
              Rome
            </button>
            <button
              className="button"
              onClick={() => {
                setSelectedDestination("Australia")
              }}
            >
              Sydney
            </button> */}
            <VirtualRefinementList
              attribute="countriesForItineraries.value"
              defaultRefinement={selectedDestination}
            />

            {/* <PoweredBy
              translations={{
                searchBy: "Powered by ",
              }}
            /> */}
            {/* <HitsPerPage
              defaultRefinement={5}
              items={[
                { value: 1, label: "Show 1" },
                { value: 5, label: "Show 5" },
                { value: 10, label: "Show 10" },
              ]}
            /> */}
            <div
              style={{
                // display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CustomSearchBox />
              <CustomHitsPerPage
                defaultRefinement={10}
                items={[
                  { value: 5, label: "5" },
                  { value: 10, label: "10" },
                  { value: 20, label: "20" },
                  { value: 30, label: "30" },
                ]}
              />
            </div>
            {/* <hr className="hr-branded"></hr> */}
            <div className="columns">
              {/* <div className="column is-3-desktop is-2-tablet "> */}
              {/* <p style={{ fontWeight: "500", fontSize: "18px" }}>
                  Travel Destination:
                </p> */}
              {/* <div
                  style={{
                    padding: "5px",
                    marginBottom: "20px",
                    padding: "10px",
                    // paddingTop: "20px",
                    borderTop: "1px solid #fec600",
                    // borderRadius: "5px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  <CustomRefinementList
                    limit={10}
                    showMore={true}
                    showMoreLimit={150}
                    searchable={true}
                    attribute="countriesForItineraries.value"
                    defaultRefinement={destination}
                    translations={{
                      showMore(expanded) {
                        return expanded ? "Show less" : "Show more"
                      },
                    }}
                  />
                </div> */}
              {/* <p style={{ fontWeight: "500", fontSize: "18px" }}>
                  Languages Spoken:
                </p>
                <div
                  style={{
                    padding: "5px",
                    marginBottom: "20px",
                    padding: "10px",
                    // paddingTop: "20px",
                    borderTop: "1px solid #fec600",
                    // borderRadius: "5px",
                  }}
                >
                  <CustomRefinementList
                    limit={5}
                    showMore={true}
                    showMoreLimit={25}
                    searchable={true}
                    attribute="languagesSpoken.value"
                    translations={{
                      showMore(expanded) {
                        return expanded ? "Show less" : "Show more"
                      },
                    }}
                  />
                </div>
                <p style={{ fontWeight: "500", fontSize: "18px" }}>Tags:</p>
                <div
                  style={{
                    padding: "5px",
                    marginBottom: "20px",
                    padding: "10px",
                    // paddingTop: "20px",
                    borderTop: "1px solid #fec600",
                    // borderRadius: "5px",
                  }}
                >
                  <CustomRefinementList
                    limit={5}
                    showMore={true}
                    showMoreLimit={25}
                    searchable={true}
                    attribute="tags"
                    translations={{
                      showMore(expanded) {
                        return expanded ? "Show less" : "Show more"
                      },
                    }}
                  />
                </div> */}
              {/* </div> */}
              {/* <RefinementList
              showMore={true}
              showMoreLimit={7}
              searchable={true}
              attribute="countriesVisited.label"
            /> */}
              {/* <RatingMenu
              attribute="reviewRating"
              // defaultRefinement={{ min: 2 }}
              max={5}
            /> */}
              {/* <div
                className="column is-1-desktop is-1-tablet "
                // style={{ marginBottom: "40px" }}
              ></div> */}
              <div
                className="column is-12-desktop is-9-tablet "
                // style={{ marginBottom: "40px" }}
              >
                <div
                  className="columns is-multiline is-narrow"
                  style={{ marginTop: "20px" }}
                >
                  <CustomHits />
                  {/* </div> */}
                </div>
                <CustomPagination />
              </div>
            </div>
          </InstantSearch>
        </div>
        <div className="column is-hidden-desktop is-hidden-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}

const ShowcaseDesigners = ({ onSelect, destination }) => {
  return (
    <ToastProvider>
      <ProfileStateProvider>
        <div style={{ margin: "20px 0px" }}>
          <ShowcaseDesignersBase
            onSelect={onSelect}
            destination={destination}
          />
        </div>
      </ProfileStateProvider>
    </ToastProvider>
  )
}

export default ShowcaseDesigners
