import React, { useState, useEffect, useRef, useContext } from "react"
import { SingleDatePicker } from "react-dates"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import "./RentalCarsDetails.scss"
import Cleave from "cleave.js/react"
import is from "is_js"
// ...

import DatePicker from "react-date-picker"
import moment from "moment"
import { FirebaseContext } from "../../../components/firebase"
import { AuthUserContext } from "../../../components/session"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import { googleMapsURI } from "../../../utility/general"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"

const RentalCarsDetails = ({ canEdit = false }) => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [isBusy, setIsBusy] = useState(false)
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [currentRentalCarsInfo, setCurrentRentalCarsInfo] = useState({
    agencyName: "",
    agencyAddress: "",
    pickupDate: "",
    dropOffDate: "",
    pickupTime: "",
    dropOffTime: "",
    pickupDateFocus: false,
    dropOffDateFocus: false,
    confirmationNumber: "",
    additionalNotes: "",
  })

  const [showAddNewRentalCarEntry, setShowAddNewRentalCarEntry] = useState(
    false
  )
  const [inEditMode, setInEditMode] = useState(9999)

  useEffect(() => {
    console.log({ currentRentalCarsInfo: currentRentalCarsInfo })
  }, [currentRentalCarsInfo])

  useEffect(() => {
    try {
      if (state.currentJobInfo.rentalCarDetailsSyncRequired) {
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${tripState.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({
              rentalCarDetails: state.currentJobInfo.rentalCarDetails,
            })
            .then(() => {
              console.log("document update successful")
            })
            .catch(e => {
              setIsBusy(false)
              console.log(e)
            })
        }
        dispatch({ type: "rentalCarDetailsSyncRequired", data: false })
      }
    } catch (error) {}
  }, [state.currentJobInfo.rentalCarDetailsSyncRequired])

  const showRentalCarDetails = params => {
    try {
      if (
        state.currentJobInfo.rentalCarDetails &&
        state.currentJobInfo.rentalCarDetails.length > 0
      ) {
        return (
          <>
            {canEdit && (
              <div
                style={{
                  margin: "30px auto",
                  maxWidth: "400px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  onClick={() => {
                    try {
                      setShowAddNewRentalCarEntry(true)
                    } catch (error) {}
                  }}
                  // className="a"
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    textDecorationColor: "#fec600",
                    color: "rgba(74, 74, 74, 0.8)",
                    textDecoration: "none",
                  }}
                >
                  <i className="far fa-plus" style={{ marginRight: "5px" }}></i>{" "}
                  Add another rental car
                </a>
              </div>
            )}
            {state.currentJobInfo.rentalCarDetails.map(
              (item, index, { length }) => {
                if (inEditMode === index) {
                  return <>{newRentalCarEntryForm(true, index)}</>
                } else
                  return (
                    <div key={index} style={{ margin: "10px auto" }}>
                      {canEdit && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "#fec600",
                          }}
                        >
                          <div
                            style={{ marginRight: "20px", cursor: "pointer" }}
                            onClick={() => {
                              setCurrentRentalCarsInfo({
                                ...state.currentJobInfo.rentalCarDetails[index],
                              })
                              setInEditMode(index)
                            }}
                          >
                            <i className="far fa-pen"></i>
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              ConfirmationModal({
                                onConfirm: i => {
                                  console.log(i)
                                  dispatch({
                                    type: "deleteRentalCarDetailEntry",
                                    index: i,
                                  })
                                  dispatch({
                                    type: "rentalCarDetailsSyncRequired",
                                    data: true,
                                  })
                                },
                                sendBackValue: index,
                                title: "Confirmation",
                                text: `Are you sure you want to permanently delete this entry?`,
                              })
                            }}
                          >
                            <i className="far fa-trash-alt"></i>
                          </div>
                        </div>
                      )}
                      <div className="rental-cars-details">
                        <div className="rental-cars-name">
                          {item.agencyName}
                        </div>
                        <div className="rental-cars-address">
                          <i
                            style={{ color: "#fec600", marginRight: "5px" }}
                            className="far fa-map-marker-alt"
                          ></i>
                          <a
                            href={googleMapsURI(
                              item.agencyName + item.agencyAddress
                            )}
                            target="_blank"
                          >
                            {item.agencyAddress}
                          </a>
                          {/* {item.agencyAddress} */}
                        </div>
                        <div className="rental-cars-dates-container">
                          <div className="rental-cars-dates">
                            <div
                              style={{
                                fontWeight: "300",
                                textTransform: "uppercase",
                                letterSpacing: "0.5px",
                                fontSize: "10px",
                              }}
                            >
                              pick-up
                            </div>
                            {item.pickupDate} - {item.pickupTime}
                          </div>
                          <i
                            style={{
                              textAlign: "center",
                              fontSize: "30px",
                              color: "#fec600",
                              margin: "0px 20px",
                            }}
                            className="fal fa-arrows-h"
                          ></i>
                          <div className="rental-cars-dates">
                            <div
                              style={{
                                fontWeight: "300",
                                textTransform: "uppercase",
                                letterSpacing: "0.5px",
                                fontSize: "10px",
                              }}
                            >
                              drop-off
                            </div>
                            {item.dropOffDate} - {item.dropOffTime}
                          </div>
                        </div>
                      </div>

                      <div style={{ display: "flex" }}>
                        <div
                          className="confirm-no"
                          style={{ marginRight: "20px", minWidth: "100px" }}
                        >
                          <span
                            style={{
                              fontWeight: "300",
                              textTransform: "uppercase",
                              letterSpacing: "0.5px",
                              fontSize: "10px",
                            }}
                          >
                            Confirmation #{" "}
                          </span>
                          <div style={{ fontWeight: "400", fontSize: "14px" }}>
                            {item.confirmationNumber === ""
                              ? "--"
                              : item.confirmationNumber}
                          </div>
                        </div>
                        <div className="additional-details">
                          {" "}
                          <span
                            style={{
                              fontWeight: "300",
                              textTransform: "uppercase",
                              letterSpacing: "0.5px",
                              fontSize: "10px",
                            }}
                          >
                            Notes{" "}
                          </span>
                          <div style={{ fontWeight: "400", fontSize: "14px" }}>
                            {item.additionalNotes === ""
                              ? "--"
                              : item.additionalNotes}
                          </div>
                        </div>
                      </div>
                      <hr className="hr-branded"></hr>
                    </div>
                  )
              }
            )}
          </>
        )
      } else {
        return canEdit ? (
          <div
            style={{
              margin: "30px auto",
              maxWidth: "400px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontWeight: "600",
                textDecorationColor: "#fec600",
                color: "rgba(74, 74, 74, 0.8)",
                textDecoration: "none",
              }}
              onClick={() => {
                setShowAddNewRentalCarEntry(true)
              }}
              // className="button is-warning"
            >
              <i className="far fa-plus" style={{ marginRight: "5px" }}></i> Add
              a rental car
            </a>
          </div>
        ) : (
          <p
            style={{
              // fontStyle: "italic",
              fontSize: "14px",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            - No rental cars added yet -
          </p>
        )
      }
    } catch (error) {}
  }

  const clearCurrentRentalCarsInfo = () => {
    setCurrentRentalCarsInfo({
      agencyName: "",
      agencyAddress: "",
      pickupDate: "",
      dropOffDate: "",
      pickupDateFocus: false,
      dropOffDateFocus: false,
      confirmationNumber: "",
      additionalNotes: "",
    })
  }

  const newRentalCarEntryForm = (inEditMode, editedItemIndex) => {
    try {
      return (
        <>
          <div className="field">
            <label className="label">Rental Company</label>
            <div className="control">
              <input
                className="input"
                type="text"
                tabIndex="1"
                autoFocus
                placeholder="e.g. Hertz"
                value={currentRentalCarsInfo.agencyName}
                onChange={e => {
                  var a = { ...currentRentalCarsInfo }
                  if (e.currentTarget.value) {
                    a["agencyName"] = e.currentTarget.value
                  } else {
                    a["agencyName"] = e.currentTarget.value
                  }
                  setCurrentRentalCarsInfo({ ...a })
                }}
              ></input>
            </div>
          </div>
          <div className="field">
            <label className="label">Address</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder=""
                value={currentRentalCarsInfo.agencyAddress}
                onChange={e => {
                  var a = { ...currentRentalCarsInfo }
                  if (e.currentTarget.value) {
                    a["agencyAddress"] = e.currentTarget.value
                  } else {
                    a["agencyAddress"] = e.currentTarget.value
                  }
                  setCurrentRentalCarsInfo({ ...a })
                }}
              ></input>
            </div>
          </div>
          <label className="label">Pick-up</label>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              <div className="control">
                <SingleDatePicker
                  date={
                    currentRentalCarsInfo.pickupDate != ""
                      ? moment(currentRentalCarsInfo.pickupDate)
                      : null
                  } // momentPropTypes.momentObj or null
                  numberOfMonths={1}
                  onDateChange={date => {
                    try {
                      console.log(date)
                      var a = currentRentalCarsInfo
                      console.log(a)
                      if (moment.isMoment(date)) {
                        a["pickupDate"] = moment(date).format("L")
                        setCurrentRentalCarsInfo({ ...a })
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }} // PropTypes.func.isRequired
                  readOnly={is.mobile() ? true : false}
                  focused={currentRentalCarsInfo.pickupDateFocus} // PropTypes.bool
                  onFocusChange={({ focused }) => {
                    var a = { ...currentRentalCarsInfo }
                    a["pickupDateFocus"] = focused
                    setCurrentRentalCarsInfo({ ...a })
                  }} // PropTypes.func.isRequired
                  id="your_unique_1" // PropTypes.string.isRequired,
                />
              </div>
            </div>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              <div className="control">
                <div className="control">
                  <Cleave
                    className="input"
                    value={currentRentalCarsInfo.pickupTime}
                    style={{ textAlign: "center" }}
                    placeholder="Time - 24hr"
                    options={{ time: true, timePattern: ["h", "m"] }}
                    onChange={e => {
                      var a = { ...currentRentalCarsInfo }
                      a["pickupTime"] = e.currentTarget.value
                      setCurrentRentalCarsInfo({ ...a })
                      console.log(e.currentTarget.value)
                      console.log(e.currentTarget.rawValue)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <label className="label">drop-off</label>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              <div className="control">
                <SingleDatePicker
                  date={
                    currentRentalCarsInfo.dropOffDate != ""
                      ? moment(currentRentalCarsInfo.dropOffDate)
                      : null
                  } // momentPropTypes.momentObj or null
                  numberOfMonths={1}
                  placeholder="Date"
                  onDateChange={date => {
                    try {
                      console.log(date)
                      var a = currentRentalCarsInfo
                      console.log(a)
                      if (moment.isMoment(date)) {
                        a["dropOffDate"] = moment(date).format("L")
                        setCurrentRentalCarsInfo({ ...a })
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }} // PropTypes.func.isRequired
                  readOnly={is.mobile() ? true : false}
                  focused={currentRentalCarsInfo.dropOffDateFocus} // PropTypes.bool
                  onFocusChange={({ focused }) => {
                    var a = { ...currentRentalCarsInfo }
                    a["dropOffDateFocus"] = focused
                    setCurrentRentalCarsInfo({ ...a })
                  }} // PropTypes.func.isRequired
                  id="your_unique_2" // PropTypes.string.isRequired,
                />
              </div>
            </div>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              <div className="control">
                <div className="control">
                  <Cleave
                    className="input"
                    value={currentRentalCarsInfo.dropOffTime}
                    style={{ textAlign: "center" }}
                    placeholder="Time - 24hr"
                    options={{ time: true, timePattern: ["h", "m"] }}
                    onChange={e => {
                      var a = { ...currentRentalCarsInfo }
                      a["dropOffTime"] = e.currentTarget.value
                      setCurrentRentalCarsInfo({ ...a })
                      console.log(e.currentTarget.value)
                      console.log(e.currentTarget.rawValue)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <label className="label">End</label> */}
          <div style={{ display: "flex", alignItems: "flex-start" }}></div>

          <div
            className="field"
            style={{ marginRight: "8px", marginTop: "5px" }}
          >
            <label className="label">Confirmation #</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder=""
                value={currentRentalCarsInfo.confirmationNumber}
                onChange={e => {
                  var a = { ...currentRentalCarsInfo }

                  a["confirmationNumber"] = e.currentTarget.value
                  setCurrentRentalCarsInfo({ ...a })
                }}
              ></input>
            </div>
          </div>
          <div
            className="field"
            style={{ marginRight: "8px", marginTop: "5px" }}
          >
            <label className="label">Additional Notes</label>
            <div className="control">
              <textarea
                value={currentRentalCarsInfo.additionalNotes}
                rows="2"
                className="textarea"
                type="text"
                placeholder=""
                maxLength="100"
                onChange={e => {
                  var a = { ...currentRentalCarsInfo }
                  console.log(e.currentTarget.value)
                  a["additionalNotes"] = e.currentTarget.value
                  setCurrentRentalCarsInfo({ ...a })
                }}
              ></textarea>
              <p className={`help is-pulled-right`}>
                {/* Characters:{" "} */}
                {currentRentalCarsInfo.additionalNotes
                  ? currentRentalCarsInfo.additionalNotes.length
                  : "0"}
                /{100}
              </p>
            </div>
          </div>
          <div
            className="field is-grouped is-grouped-right"
            style={{ marginTop: "40px" }}
          >
            <div className="control">
              <button
                onClick={() => {
                  clearCurrentRentalCarsInfo()
                  setShowAddNewRentalCarEntry(false)
                  setInEditMode(9999)
                }}
                className="button is-warning is-light"
              >
                Cancel
              </button>
            </div>
            <div className="control">
              <button
                class={`${
                  isBusy ? "button is-warning is-loading" : "button is-warning"
                }`}
                onClick={() => {
                  if (isBusy) return
                  setIsBusy(true)
                  if (inEditMode) {
                    dispatch({
                      type: "saveRentalCarEdits",
                      data: currentRentalCarsInfo,
                      index: editedItemIndex,
                    })
                  } else {
                    dispatch({
                      type: "addNewRentalCarDetail",
                      data: currentRentalCarsInfo,
                    })
                  }

                  clearCurrentRentalCarsInfo()
                  setShowAddNewRentalCarEntry(false)

                  dispatch({
                    type: "rentalCarDetailsSyncRequired",
                    data: true,
                  })
                  setIsBusy(false)
                  setInEditMode(9999)

                  // if (dbContext) {
                  //   dbContext
                  //     .firestore()
                  //     .collection("jobs")
                  //     .doc(`${authContext.uid}`)
                  //     .collection("active-jobs")
                  //     .doc(`${state.currentJobInfo.jobUid}`)
                  //     .update({
                  //       rentalCarDetails: dbContext.firestore.FieldValue.arrayUnion(
                  //         currentRentalCarsInfo
                  //       ),
                  //     })
                  //     .then(() => {
                  //       console.log("document update successful")
                  //       //update local state now

                  //     })
                  //     .catch(e => {
                  //       setIsBusy(false)
                  //       console.log(e)
                  //     })
                  // }
                }}
              >
                {inEditMode ? "Save Changes" : "Save"}
              </button>
            </div>
          </div>
          <hr className="hr-branded"></hr>
        </>
      )
    } catch (error) {}
  }

  return (
    <div>
      <div className="columns">
        <div className="column is-2-desktop is-0-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-12-tablet is-12-mobile">
          <p
            className="tab-header"
            // style={{
            //   fontSize: "34px",
            //   fontWeight: "800",
            //   color: "rgba(74,74,74,0.8)",
            //   margin: "20px auto",
            //   lineHeight: "1.4",
            //   textAlign: "center",
            // }}
          >
            Rental Car Details<span style={{ color: "#fec600" }}>.</span>
          </p>
          <p className="tab-tagline">
            Your rental car info during your trip.
            <span style={{ fontWeight: "500" }}> TIP:</span> The pick-up and
            drop-off events would show up on your daily itinerary.
          </p>
          {!showAddNewRentalCarEntry && showRentalCarDetails()}{" "}
          {showAddNewRentalCarEntry && newRentalCarEntryForm(false, 0)}
        </div>
        <div className="column is-2-desktop is-0-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}

export default RentalCarsDetails
