import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import PrivateRoute from "../components/PrivateRoute"
import AppHome from "../components/home/AppHome"
import ProfilePage from "../components/profilePage/ProfilePage"
import DashboardContainer from "../Container/Dashboard/DashboardContainer"
import NewTripWizard from "../Container/NewTripWizard/NewTripWizard"
import { TripDetails } from "../Container/Dashboard/TripDetailsTab/TripDetails"
import MessagesContainer from "../Container/Messages/MessagesContainer"
import AccountInfoContainer from "../Container/AccountInfo/AccountInfoContainer"
import UserProfiles from "../Container/UserProfiles/UserProfiles"
import { GlobalStateProvider } from "../State/global-state/global-state"
import MessageActivityComponent from "../Container/Dashboard/MessagesTab/MessageActivityComponent"
import DesignerSignUpContainer from "../Container/DesignerSignUp/DesignerSignUpContainer"
import AddGeneralInfo from "../Container/DesignerSignUp/AddGeneralInfo"
import AddAdditionalInfo from "../Container/DesignerSignUp/AddAdditionalInfo"
import UploadPhotos from "../Container/DesignerSignUp/UploadPhotos"
import ThankYou from "../components/ThankYou/ThankYou"
import BecomeADesigner from "../components/BecomeADesigner/BecomeADesigner"
import SearchDesigners from "../Container/SearchDesigners/SearchDesigners"
import NotificationsContainer from "../Container/Notifications/NotificationsContainer"
import DetailsForTraveler from "../Container/Dashboard/Traveler/DetailsForTraveler"
import SampleTravelPlan from "../Container/Dashboard/Traveler/SampleTravelPlan"
import { DashboardStateProvider } from "../State/dashboard-state/dashboard-state"
import DesignerOnboardingCallback from "../Container/DesignerOnboarding/DesignerOnboardingCallback"
import MessageActivityWrapper from "../Container/Dashboard/MessagesTab/MessageActivityWrapper"
import ActivityContainer from "../Container/Activities/ActivityContainer"
import RecommendationsForm from "../components/RecommendationsForm/RecommendationsForm"
import DetailsForDesigner from "../Container/Dashboard/Designer/DetailsForDesigner"
import TravelDesigners from "../components/TravelDesigners/TravelDesigners"

import { ToastContainer, toast } from "react-toastify"
import FeedbackForm from "../components/FeedbackForm/FeedbackForm"
import SEO from "../components/seo"
import SocialShareForm from "../components/SocialShareForm/SocialShareForm"
import SampleTravelPlanForm from "../Container/DesignerSignUp/SampleTravelPlanForm"
import NewsletterFeedback from "../components/NewsletterFeedback/NewsletterFeedback"
import NewsletterSubscription from "../components/NewsletterSubscription/NewsletterSubscription"
import NewsletterSubscriptionWrapper from "../components/NewsletterSubscription/NewsletterSubscriptionWrapper"
import ReferralProducer from "../components/Referrals/ReferralProducer"
import BYOTWizard from "../Container/NewTripWizard/BYOTWizard"
import BYOTDetailsForTraveler from "../Container/Dashboard/Traveler/BYOTDetailsForTraveler"
import FeedbackComponent from "../components/FeedbackComponent/FeedbackComponent"
import GeneralFeedBackForm from "../components/FeedbackForm/GeneralFeedBackForm"
import SupportRequestForm from "../components/Support/SupportRequestForm"

const AppBase = () => {
  if (process.env.NODE_ENV !== "development") console.log = () => {}
  return (
    <Router>
      <PrivateRoute path="/app/home" component={AppHome} />
      <PrivateRoute path="/app/profile" component={ProfilePage} />
      {/* <PrivateRoute
        path="/app/dashboard/:sessionType"
        component={DashboardContainer}
      /> */}
      <PrivateRoute path="/app/dashboard" component={DashboardContainer} />
      <PrivateRoute path="/app/activities" component={ActivityContainer} />
      <PrivateRoute path="/app/tripdetails" component={TripDetails} />
      <PrivateRoute path="/app/tripwizard" component={NewTripWizard} />
      {/* <PrivateRoute path="/app/planyourtrip" component={BYOTWizard} /> */}
      <PrivateRoute path="/app/tripwizard/:tripUid" component={NewTripWizard} />
      <PrivateRoute path="/app/messages" component={MessagesContainer} />
      <PrivateRoute
        path="/app/tripdetails/:tripUid"
        component={DetailsForTraveler}
      />
      <PrivateRoute
        path="/app/byottripdetails/:tripUid"
        component={BYOTDetailsForTraveler}
      />
      <PrivateRoute
        path="/app/byottripdetails/:tripUid/*"
        component={BYOTDetailsForTraveler}
      />
      <PrivateRoute
        path="/app/jobdetails/:tripUid/:uid/:jobUid"
        component={DetailsForDesigner}
      />

      <PrivateRoute
        path="/app/notifications"
        component={NotificationsContainer}
      />
      <PrivateRoute
        path="/app/designersignup"
        component={DesignerSignUpContainer}
      />
      <PrivateRoute
        path="/app/designersignup/generalinfo"
        component={AddGeneralInfo}
      />
      <PrivateRoute path="/app/searchdesigners" component={SearchDesigners} />
      <PrivateRoute
        path="/app/designersignup/additionalinfo"
        component={AddAdditionalInfo}
      />
      <PrivateRoute
        path="/app/designersignup/sampletravelplanentry"
        component={SampleTravelPlanForm}
      />
      <PrivateRoute path="/app/thankyou" component={ThankYou} />
      <PrivateRoute path="/app/uploadphotos" component={UploadPhotos} />

      <PrivateRoute
        path="/app/messageactivity/:roomId/:recipientUid"
        component={MessageActivityWrapper}
      />
      <PrivateRoute path="/app/account" component={AccountInfoContainer} />
      {/* <PrivateRoute
        path="/app/userprofiles/:profileId"
        component={UserProfiles}
      /> */}
      <PrivateRoute
        path="/app/onboarding"
        component={DesignerOnboardingCallback}
      />
      <PrivateRoute
        path="/app/become-a-trip-designer"
        component={BecomeADesigner}
      />
      <PrivateRoute path="/app/travel-designers" component={TravelDesigners} />
      <PrivateRoute path="/app/invite/:iType" component={ReferralProducer} />
      <PrivateRoute
        path="/app/product-feedback"
        component={GeneralFeedBackForm}
      />
      <PrivateRoute path="/app/support" component={SupportRequestForm} />

      <UserProfiles path="/app/userprofiles/:profileId" />
      <RecommendationsForm path="/app/recommendations/:recommendationId" />
      <SocialShareForm path="/app/socialshare/" />
      <FeedbackForm path="/app/feedback/:feedbackId" />
      <NewsletterFeedback path="/app/newsletterfeedback/:nId/:score" />

      <NewsletterSubscriptionWrapper path="/app/subscribenewsletter/:rId" />
      <NewsletterSubscriptionWrapper path="/app/sn/:rId" />
      <NewsletterSubscriptionWrapper path="/app/subscribenewsletter" />

      {/* <SampleTravelPlan path="/app/sampletravelplan/" /> */}
    </Router>
  )
}

const App = () => {
  return (
    <>
      {/* <SEO pageTitle="Platform" title="voyayge.com" /> */}
      <ToastContainer placement="top-center" />
      <DashboardStateProvider>
        <GlobalStateProvider>
          <Layout>
            <AppBase />
          </Layout>
        </GlobalStateProvider>
      </DashboardStateProvider>
    </>
  )
}

export default App
