import React from "react"
import NewsletterSubscription from "./NewsletterSubscription"

const NewsletterSubscriptionWrapper = ({ rId }) => {
  return (
    <div className="columns">
      <div className="column is-4-desktop"></div>
      <div
        className="column is-4-desktop newsletter-container"
        style={{ textAlign: "center", marginTop: "100px" }}
      >
        {/* {rId} */}
        <NewsletterSubscription rId={rId} />
      </div>

      <div className="column is-4-desktop"></div>
    </div>
  )
}

export default NewsletterSubscriptionWrapper
