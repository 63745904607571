import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import desktopImage from "../../images/desktop.png"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"

const ShowSwitchToDesktop = ({ onContinue }) => {
  const screenWidth = useWindowWidth()
  return (
    <div
      className="center"
      style={{
        maxWidth: "400px",
        margin: "0px auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div>
        <LazyLoadImage
          src={desktopImage}
          alt=""
          style={{ maxWidth: "200px", marginBottom: "20px" }}
          effect="blur"
        />
      </div>
      <p
        style={{ fontSize: "12px", marginBottom: "20px", textAlign: "center" }}
      >
        We recommend switching to your desktop computer for a better user
        experience while creating your trip.{" "}
      </p>
      <button
        style={{ fontSize: "14px", marginBottom: "0px" }}
        className="button is-warning"
        onClick={() => {
          if (onContinue) onContinue()
        }}
      >
        Continue on mobile
      </button>
    </div>
  )
}

export default ShowSwitchToDesktop
