import React, { useContext, useEffect } from "react"
import { useTripStateStore } from "../../State/trip-state/trip-state-store"
import swal from "sweetalert"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import "./TripCreation.scss"
import { FirebaseContext } from "../firebase"
import { getLabelValue } from "../../utility/general"
import { analytics } from "../firebase/firebase"
import { AuthUserContext } from "../session"

const DisplayField = ({ labelText, data, setBgColor, onEdit, section }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="columns">
      <div className="column is-0-desktop is-hidden-mobile"></div>
      <div className="column is-3 padding-bottom-0">
        {" "}
        <label className="label">{labelText}</label>
      </div>
      <div className="column is-7 padding-top-0">
        {" "}
        <p>{data}</p>
      </div>
      {/* <i
        style={{ color: "#fec600", cursor: "pointer" }}
        onClick={() => {
          onEdit(section)
        }}
        className="far fa-pen"
      ></i> */}
      <div className="column is-1-desktop is-0-mobile"></div>
    </div>
    //   <div
    //     className={`field is-horizontal ${setBgColor}
    // `}
    //   >
    //     <div className="my-custom-class">
    //       <div className="field-label">
    //         <label className="label" style={{ fontSize: "16px" }}>
    //           {labelText}
    //         </label>
    //       </div>
    //     </div>
    //     <div className="field-body">
    //       <div className="field">
    //         <div className="control is-expanded margin-right-20px">
    //           <p style={{ fontSize: "16px" }}>{data}</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
  )
}

const DisplayTravelerDetails = () => {
  const [state, dispatch] = useTripStateStore()

  const populateTable = () => {
    return state.travelerDetails.map(detail => (
      <>
        <Tr key={detail.index}>
          <Td className="" style={{ marginTop: "10px" }}>
            {detail.firstName + " " + detail.lastName}
          </Td>
          {/* <Td className="">{detail.gender || "--"}</Td>
        <Td className="">{detail.age || "--"}</Td> */}
          {/* <Td className="">{detail.diet || "--"}</Td>
        <Td className="">{detail.wheelchair || "--"}</Td>
        <Td id={detail.index} className="">
          <div
            id={detail.index}
            onClick={e =>
              swal({
                title: "Additional Details",
                text: `${detail.additionalTravelerDetails ||
                  "No additional details"}`,
                // icon: "info",
                button: "close",
              })
            }
          >
            click for details
          </div>
        </Td> */}
          <Td></Td>
        </Tr>
      </>
    ))
  }
  return (
    <>
      {/* <Table className="table-block">
        <Thead>
          <Tr>
            <Th className="table-header">Primary Traveler</Th>
            <Th className="table-header">Gender</Th>
          <Th className="table-header">Age</Th>
          <Th className="table-header">Diet</Th>
          <Th className="table-header">Wheelchair</Th>
          <Th className="table-header">Additional Details</Th>
          </Tr>
        </Thead>
        <Tbody>{populateTable()}</Tbody>
      </Table> */}
      <div style={{ marginLeft: "17px" }}>
        <p style={{ marginBottom: "20px" }}>
          <span
            style={{ fontSize: "16px", fontWeight: "600", marginRight: "7px" }}
          >
            Primary Traveler:
          </span>
          <span>{`${state.travelerDetails[0].firstName} ${state.travelerDetails[0].lastName}`}</span>
        </p>
        <p style={{ marginBottom: "20px" }}>
          <span
            style={{ fontSize: "16px", fontWeight: "600", marginRight: "7px" }}
          >
            Total adults traveling:
          </span>
          <span>{state.adultTravelerCount}</span>
        </p>
        <p style={{ marginBottom: "20px" }}>
          <span
            style={{ fontSize: "16px", fontWeight: "600", marginRight: "7px" }}
          >
            Kids traveling:
          </span>
          <span>{state.kidsTravelerCount}</span>
        </p>
        <p style={{ marginBottom: "20px" }}>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginRight: "7px",
            }}
          >
            Anything particular your designer should keep in mind about the
            travelers?
          </span>
          <span
            style={{
              display: "block",
              marginTop: "7px",
            }}
          >
            {state.travelerAdditionalInfo}
          </span>
        </p>
      </div>
    </>
  )
}

export const TripSummary = ({ onNext, onPrevious, gotoPage }) => {
  const [state, dispatch] = useTripStateStore()
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  let dbTime = dbContext.firestore.FieldValue.serverTimestamp()
  console.log({ dbTime })
  const getDates = data => {
    try {
      let a = `${data.tripLogistics.travelDates.start
        .toDate()
        .toLocaleDateString()} - ${data.tripLogistics.travelDates.end
        .toDate()
        .toLocaleDateString()}`
      console.log({ a })
      return a
    } catch (e) {
      console.log({ e })
      return `${new Date(
        data.tripLogistics.travelDates.start
      ).toLocaleDateString()} - ${new Date(
        data.tripLogistics.travelDates.end
      ).toLocaleDateString()}`
    }
  }

  const getLabelValues = data => {
    try {
      let a = []
      data.map(d => {
        if (d.label === "") {
        } else {
          a.push(d.label)
        }
      })
      console.log({ a })
      return a == [] ? "- Not Specified -" : a
    } catch (e) {
      console.log({ e })
      return "--"
    }
  }

  const getPlaces = data => {
    try {
      let a = []
      data.map(d => {
        if (d.label === "") {
        } else {
          a.push(d.label)
        }
      })
      console.log({ a })
      return a
    } catch (e) {
      console.log({ e })
      return "--"
    }
  }

  const showTripDestinations = () => {
    if (state.tripDestinations) {
      var destinationString = ""
      state.tripDestinations.map((item, index) => {
        console.log(state.tripDestinations.length, index)
        destinationString =
          destinationString +
          item.place +
          "-" +
          item.country +
          (state.tripDestinations.length === index + 1 ? "" : ", ")
      })
      return destinationString
    }
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })
  const displayAccommodationDetails = () => {
    try {
      if (state.tripLogistics.isAccommodationNotRequired) {
        return (
          <DisplayField
            labelText="Accommodation preference:"
            data={"Accommodation recommendation not required"}
          />
        )
      } else {
        return (
          <>
            {" "}
            <DisplayField
              labelText="Accommodation preference:"
              data={getLabelValues(
                state.tripLogistics.accommodationPreference
              ).join(", ")}
            />
            <DisplayField
              labelText="Accommodation details:"
              data={
                state.tripLogistics.accommodationDetails === "" ||
                state.tripLogistics.accommodationDetails === undefined
                  ? "- None -"
                  : state.tripLogistics.accommodationDetails
              }
            />
          </>
        )
      }
    } catch (err) {
      return "--"
    }
  }

  return (
    <>
      <div className="columns is-tablet is-multiline">
        <div className="column is-1-desktop is-12-tablet is-12-mobile"></div>
        <div className="column is-10-desktop is-12-tablet is-12-mobile">
          <div className="columns is-tablet is-multiline fade-in">
            {/* <div className="column is-12-desktop is-12-tablet is-12-mobile">
              {" "}
              <h3 align="center" className="subtitle is-3 align-center">

              </h3>
              <progress
            className="progress is-warning is-thin"
            value={state.wizardProgress}
            max="100"
          >
            25%
          </progress>
            </div> */}

            <div className="column is-12-desktop is-12-tablet is-12-mobile">
              <div
                style={{
                  textAlign: "left",

                  // marginBottom: "10px",
                }}
              >
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "800",
                    color: "#fec600",
                    lineHeight: "30px",
                  }}
                >
                  This is what we have got so far.
                </p>
              </div>{" "}
            </div>
            <div className="column is-12-desktop is-12-tablet is-12-mobile">
              <div style={{ border: "1px solid #ebebeb", borderRadius: "7px" }}>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {/* <DisplayField labelText="Trip name:" data={state.tripName} /> */}

                  <h3
                    style={{
                      margin: "10px auto",
                      textTransform: "uppercase",
                      fontWeight: "800",
                      fontSize: "18px",
                    }}
                    // className="subtitle is-5"
                    // style={{ borderLeft: "4px solid #fec600", padding: "10px" }}
                  >
                    {" "}
                    Trip Logistics<span style={{ color: "#fec600" }}>.</span>
                  </h3>

                  {/* <DisplayField
                    labelText="Trip name:"
                    data={state.tripName ? state.tripName : "--"}
                  /> */}
                  <DisplayField
                    labelText="Trip type:"
                    data={
                      state.tripLogistics
                        ? getLabelValue(state.tripLogistics.tripType)
                        : "--"
                    }
                    section={0}
                    onEdit={gotoPage}
                  />
                  <DisplayField
                    labelText="Destinations:"
                    //data={state.tripDestinations.join(", ")}
                    data={showTripDestinations()}
                    setBgColor="toggle-field-background"
                  />
                  {/* <DisplayField
                labelText="Purpose of the trip:"
                data={state.tripLogistics.tripsPurpose}
              /> */}
                  <DisplayField
                    labelText="Scheduled travel dates:"
                    data={getDates(state)}
                    setBgColor="toggle-field-background"
                  />
                  {/* <DisplayField
            labelText="Travel dates flexible:"
            data={state.tripLogistics.travelDatesFlexible ? "Yes" : "No"}
          /> */}
                  {/* <DisplayField
            labelText="Travel budget:"
            data={`${formatter.format(
              state.tripLogistics.totalTripBudgetRange.minBudget
            )} - ${formatter.format(
              state.tripLogistics.totalTripBudgetRange.maxBudget
            )}`}
            setBgColor="toggle-field-background"
          /> */}
                  {/* <DisplayField
                    labelText="Travel budget:"
                    data={`${formatter.format(
                      state.tripLogistics.totalTripBudgetRange.minBudget
                    )}`}
                    setBgColor="toggle-field-background"
                  /> */}

                  {displayAccommodationDetails()}
                  {/* <DisplayField
                    labelText="Accommodation preference:"
                    data={getLabelValues(
                      state.tripLogistics.accommodationPreference
                    ).join(", ")}
                  />
                  <DisplayField
                    labelText="Accommodation details:"
                    data={
                      state.tripLogistics.accommodationDetails === "" ||
                      state.tripLogistics.accommodationDetails === undefined
                        ? "- None -"
                        : state.tripLogistics.accommodationDetails
                    }
                  /> */}
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  <hr className="hr-branded"></hr>
                </div>
                {/* <div className="column is-12-desktop is-12-tablet is-12-mobile"> */}
                {/* </div> */}
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  <h3
                    // style={{ marginTop: "26px" }}
                    // className="subtitle is-5"
                    style={{
                      margin: "10px auto",
                      textTransform: "uppercase",
                      fontWeight: "800",
                      fontSize: "18px",
                    }}
                    // style={{ borderLeft: "4px solid #fec600", padding: "10px" }}
                  >
                    Traveler Details<span style={{ color: "#fec600" }}>.</span>
                    {/* <i
                      style={{ color: "#fec600", cursor: "pointer" }}
                      className="far fa-pen"
                    ></i> */}
                  </h3>{" "}
                  <DisplayTravelerDetails />
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {" "}
                  <hr className="hr-branded"></hr>
                </div>
                {/* <div className="column is-12-desktop is-12-tablet is-12-mobile"> */}{" "}
                {/* </div> */}
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  <h3
                    // style={{ marginTop: "26px" }}
                    // className="subtitle is-5"
                    style={{
                      margin: "10px auto",
                      textTransform: "uppercase",
                      fontWeight: "800",
                      fontSize: "18px",
                    }}
                  >
                    Trip Preferences<span style={{ color: "#fec600" }}>.</span>
                  </h3>
                  <DisplayField
                    labelText="Vacation pacing:"
                    data={state.tripPreferences.pacing}
                  />
                  {/* <DisplayField
                    labelText="Food preferences:"
                    data={getLabelValues(
                      state.tripPreferences.foodPreferences
                    ).join(", ")}
                    style={{ display: "none" }}
                  /> */}
                  {/* <DisplayField
                    labelText="Preferred cuisines:"
                    data={getLabelValues(
                      state.tripPreferences.preferredCuisines
                    ).join(", ")}
                    setBgColor="toggle-field-background"
                  /> */}
                  {/* <DisplayField
                    labelText="Drink preferences:"
                    data={getLabelValues(
                      state.tripPreferences.drinkPreferences
                    ).join(", ")}
                    style={{ display: "none" }}
                  /> */}
                  <DisplayField
                    labelText="Preferred vacation activities:"
                    data={getLabelValues(
                      state.tripPreferences.preferredActivities
                    ).join(", ")}
                    setBgColor="toggle-field-background"
                  />
                  <DisplayField
                    labelText="Additional details:"
                    data={
                      state.tripPreferences.otherTripPreferenceDetails === ""
                        ? "- None -"
                        : state.tripPreferences.otherTripPreferenceDetails
                    }
                  />
                </div>
              </div>
            </div>
            <div className="column is-12-desktop is-12-tablet is-12-mobile">
              {" "}
              <hr className="hr-branded"></hr>
            </div>
            <div
              className="column is-12-desktop is-12-tablet is-12-mobile"
              style={{ marginBottom: "60px" }}
            >
              <div className="columns is-mobile is-multiline">
                <div className="column is-6-desktop is-6-tablet is-6-mobile">
                  <button
                    className="button"
                    onClick={e => {
                      if (onPrevious) onPrevious()
                    }}
                  >
                    <i
                      className="fas fa-angle-left"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Traveler Info{" "}
                  </button>
                </div>
                <div className="column is-6-desktop is-6-tablet is-6-mobile">
                  <button
                    className="button is-warning"
                    style={{ float: "right" }}
                    onClick={e => {
                      if (onNext) {
                        onNext()
                        analytics.logEvent("wizard_pick_designer", {
                          userId: `${authContext.uid}`,
                          tripId: `${state.tripUid}`,
                        })
                      }
                    }}
                  >
                    Pick a designer{" "}
                    <i
                      className="fas fa-angle-right"
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-1-desktop is-12-tablet is-12-mobile"></div>
      </div>
    </>
  )
}
