import React, { useContext, useState, useEffect } from "react"
import algoliaImg from "../../images/search-by-algolia.png"
import {
  connectAutoComplete,
  InstantSearch,
  Highlight,
  connectPoweredBy,
  SearchBox,
  connectSearchBox,
  connectHitsPerPage,
  connectHits,
  connectPagination,
  connectRefinementList,
  RefinementList,
} from "react-instantsearch-dom"
import is from "is_js"
import algoliasearch from "algoliasearch/lite"
import AuthUserContext from "../../components/session/context"
import { dottedText } from "../../utility/general"
import { NewActivityForm } from "../Dashboard/ItineraryTab/NewActivityForm"
import { FirebaseContext } from "../../components/firebase"
import {
  useDashboardStateStore,
  DashboardStateProvider,
} from "../../State/dashboard-state/dashboard-state"
import { ToastProvider } from "react-toast-notifications"
import ActivityViewer from "./ActivityViewer"
import "./Activities.scss"
import Select from "react-select"
import { titleCase } from "../../utility/general"
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal"
import ReactTooltip from "react-tooltip"
const searchClient = algoliasearch(
  `${process.env.ALGOLIA_SEARCH_APP_ID}`,
  `${process.env.ALGOLIA_SEARCH_API_KEY}`
)

function ActivityContainerBase() {
  //const authContext = useContext(AuthUserContext)
  const [activeTab, setActiveTab] = useState(0)
  const [showHits, setShowHits] = useState(false)
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [showNewActivityEntry, setShowNewActivityEntry] = useState(false)
  const [state, dispatch] = useDashboardStateStore()
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [inActivityEditMode, setInActivityEditMode] = useState(false)
  const [showActivity, setShowActivity] = useState(false)
  const [forceRefresh, setForceRefresh] = useState(false)

  useEffect(() => {
    var x = document.getElementById("filterDIV")
    var y = document.getElementById("filterButton")
    x.style.display = "none"
    y.innerText = "Show filters"
  }, [])

  useEffect(() => {
    if (inActivityEditMode) setShowNewActivityEntry(true)
  }, [inActivityEditMode])

  useEffect(() => {
    console.log({ state })
  }, [state])
  useEffect(() => {
    console.log({ forceRefresh })
    if (forceRefresh) {
      setForceRefresh(false)
    }
  }, [forceRefresh])
  useEffect(() => {
    console.log({ showActivity })
    console.log(is.existy(state.activityScratchPad.activityUid))
  }, [showActivity])
  function getPagingRange(current, { min = 1, total = 20, length = 5 } = {}) {
    if (length > total) length = total

    let start = current - Math.floor(length / 2)
    start = Math.max(start, min)
    start = Math.min(start, min + total - length)

    return Array.from({ length: length }, (el, i) => start + i)
  }
  const PoweredBy = ({ url }) => (
    <a href={url} style={{ color: "#4a4a4a", fontSize: "14px" }}>
      <img src={algoliaImg} alt="algolia" style={{ maxHeight: "18px" }} />
    </a>
  )
  const CustomPoweredBy = connectPoweredBy(PoweredBy)

  const deleteActivityPermanently = activityUid => {
    try {
      if (dbContext)
        dbContext
          .firestore()
          .collection("activities")
          .doc(`${authContext.uid}`)
          .collection("activities")
          .doc(`${activityUid}`)
          .delete()
          .then(() => {
            setForceRefresh(true)
          })
          .catch(error => console.log({ error }))
    } catch (error) {
      ConfirmationModal({
        onConfirm,
        title: "Error",
        text: `There was an error deleting your activity. Please try again later.`,
      })
      console.log({ error })
    }
  }

  const Autocomplete = ({ currentRefinement, refine, hits }) => {
    //console.log({ hits, refine, currentRefinement })
    return (
      <div style={{ position: "relative" }}>
        <input
          id="search-bar"
          type="search"
          className="input"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
          onFocus={() => {
            setShowHits(true)
            // setTimeout(() => {
            //   document.getElementById("search-bar").focus()
            // }, 500)
          }}
          onBlur={() => {
            setTimeout(() => {
              console.log("blur")
              setShowHits(false)
            }, 100)
          }}
          placeholder="Search activities"
        />
        {/* <span style={{ position: "absolute", right: "10px", top: "8px" }}>
          <i className="far fa-search"></i>
        </span> */}
        <span
          style={{
            position: "absolute",
            right: "5px",
            top: "-20px",
            color: "#4a4a4a",
            zIndex: "10",
          }}
        >
          <CustomPoweredBy />
        </span>
        {showHits && (
          <ul style={{ zIndex: "99" }} className="results-container">
            {hits.map(hit => (
              // <li key={hit.objectID}>{hit.firstName}</li>
              <article
                key={hit.objectID}
                className="card result-cards"
                style={{ padding: "5px", cursor: "pointer" }}
                onClick={params => {
                  //console.log({ hit })
                  dispatch({
                    type: "updateActivityScratchPad",
                    data: hit,
                  })
                  setShowHits(false)
                  setShowActivity(true)
                  // dispatch({ type: "syncSchedule", data: true })
                }}
              >
                <div className="media-content">
                  <div className="content">
                    <p>
                      <strong>{hit.activityName}</strong>{" "}
                      <small style={{ fontWeight: "300" }}>
                        {hit.activityType}
                      </small>{" "}
                      <br></br> <small>{hit.activityAddress}</small>
                      {/* <br></br>
                      {dottedText(hit.activityDescription, 100)} */}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </ul>
        )}

        {/* <ul>
        {hits != undefined
          ? hits.map(index => (
              <li key={index.index}>
                <h2>{index.index}</h2>
                <ul>
                  {index.hits.map(hit => (
                    <li key={hit.objectID}>{hit.name}</li>
                  ))}
                </ul>
              </li>
            ))
          : ""}
      </ul> */}
      </div>
    )
  }
  const CustomAutocomplete = connectAutoComplete(Autocomplete)

  const onActivityCreated = () => {
    // console.log("onActivityCreated  called")
    // dispatch({ type: "syncSchedule", data: true })
  }
  const onEditActivity = e => {
    setShowActivity(false)
    console.log("on Edit")
    setInActivityEditMode(true)
    //setShowNewActivityEntry(true)
  }
  const onConfirm = activityUid => {
    deleteActivityPermanently(activityUid)
    setShowActivity(false)
    console.log({ activityUid })
  }
  const onRemove = activityUid => {
    ConfirmationModal({
      onConfirm,
      title: "Please Confirm",
      text: `Are you sure you want to permanently delete this activity?`,
      sendBackValue: activityUid,
    })
  }

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => {
    // return the DOM output
    return (
      <nav
        className="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        <a
          className="pagination-previous"
          onClick={event => {
            event.preventDefault()
            if (currentRefinement > 1) refine(currentRefinement - 1)
          }}
        >
          Previous
        </a>
        <a
          className="pagination-next"
          onClick={event => {
            event.preventDefault()
            if (currentRefinement < nbPages) refine(currentRefinement + 1)
          }}
        >
          Next page
        </a>
        <ul className="pagination-list">
          {getPagingRange(currentRefinement, {
            min: 1,
            total: nbPages,
            length: 5,
          }).map((i, index) => {
            const page = i
            const style = {
              fontWeight: currentRefinement === page ? "bold" : "",
            }
            console.log({ i })
            console.log({ currentRefinement })
            return (
              <li key={i}>
                <a
                  className={`pagination-link ${
                    currentRefinement === page ? "is-current" : ""
                  }`}
                  href={createURL(page)}
                  style={style}
                  onClick={event => {
                    event.preventDefault()
                    refine(page)
                  }}
                >
                  {page}
                </a>
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }

  const CustomPagination = connectPagination(Pagination)
  const displayHits = hits => {
    if (hits && hits.length > 0) {
      console.log({ hits })
      return hits.map((item, index) => {
        return (
          <tr>
            {is.desktop() ? (
              <>
                <td>{dottedText(item.activityName, 30)}</td>
                <td>{dottedText(titleCase(item.activityType), 30)}</td>
              </>
            ) : (
              <>
                <td>{dottedText(item.activityName, 7)}</td>
                <td>{dottedText(titleCase(item.activityType), 7)}</td>{" "}
              </>
            )}

            <td style={{ textAlign: "center" }}>
              <i
                style={{ color: "#4a4a4a", cursor: "pointer" }}
                className="far fa-eye"
                data-tip="View details"
                onClick={params => {
                  dispatch({
                    type: "updateActivityScratchPad",
                    data: item,
                  })
                  setShowActivity(true)
                }}
              ></i>
            </td>
            <td style={{ textAlign: "center" }}>
              <i
                style={{ color: "#4a4a4a", cursor: "pointer" }}
                className="far fa-copy"
                data-tip="Duplicate activity"
                onClick={params => {
                  ConfirmationModal({
                    onConfirm: () => {
                      dispatch({
                        type: "duplicateActivityAndUpdateScratchPad",
                        data: item,
                      })
                      setShowNewActivityEntry(true)
                      setShowActivity(false)
                    },
                    title: "Confirm",
                    text:
                      "Are you sure you want to make a copy of this activity?",
                  })
                }}
              ></i>
            </td>
            <td style={{ textAlign: "center" }}>
              <i
                className="far fa-trash-alt delete-icon-color"
                data-tip="Delete activity"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onRemove(item.objectID)
                }}
              ></i>
            </td>
          </tr>
        )
      })
    } else {
      return (
        <p style={{ marginTop: "20px", fontSize: "16px" }}>- No Activities -</p>
      )
    }
  }

  const showActivities = ({ hits }) => {
    return (
      <>
        <ReactTooltip />
        <p style={{ fontSize: "14px", marginBottom: "20px" }}>
          <i
            style={{ color: "#fec600", marginRight: "5px" }}
            className="far fa-info-circle"
          ></i>
          Tip: Don't see the activity you just created or edited? - Try
          refreshing the page.
        </p>
        <table className="table is-striped is-narrow is-hoverable is-fullwidth brand-border">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>{displayHits(hits)}</tbody>
        </table>
      </>
    )
  }
  const CustomHits = connectHits(showActivities)
  const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
    return (
      <>
        <div className="field has-addons">
          {/* <p className="control">
            <a className="button is-static force-brand-color">
              <i className="fas fa-search"></i>
            </a>
          </p> */}
          <div className="control is-expanded">
            <input
              className="input"
              value={currentRefinement}
              type="text"
              placeholder="Search activities"
              style={{
                position: "relative",
                paddingRight: "107px",
                border: "none",
                borderBottom: "1px solid #fec600",
                borderRadius: "0px",
                boxShadow: "none",
              }}
              onChange={e => refine(e.currentTarget.value)}
            />
            <span
              style={{
                position: "absolute",
                right: "-35px",
                top: "-20px",
                color: "#4a4a4a",
                zIndex: "10",
              }}
            >
              <CustomPoweredBy />
            </span>
          </div>
          <div className="control">
            <button
              className="button"
              onClick={() => refine("")}
              style={{
                border: "none",
                borderBottom: "1px solid #fec600",
                borderRadius: "0px",
              }}
            >
              <span className="icon is-small">
                <i className="fal fa-times" style={{ color: "#4a4a4a" }}></i>
              </span>
            </button>
          </div>
          {isSearchStalled ? "My search is stalled" : ""}
        </div>
        <div className="or">
          {" "}
          <p style={{ textAlign: "center", marginTop: "0px" }}>- or -</p>
        </div>
        <div className="new-activity" style={{ marginBottom: "40px" }}>
          <div style={{ maxWidth: "170px", margin: "10px auto" }}>
            <button
              className="button is-warning"
              style={{ width: "100%", marginTop: "0px" }}
              onClick={() => {
                setShowNewActivityEntry(true)
                setShowActivity(false)
                dispatch({ type: "clearActivityScratchPad" })
              }}
            >
              Create New Activity
            </button>
          </div>
        </div>
      </>
    )
  }

  const CustomSearchBox = connectSearchBox(SearchBox)

  const HitsPerPage = ({ items, currentRefinement, refine, createURL }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {/* <p>The search display {currentRefinement} hits.</p> */}
      <div
        style={{
          fontSize: "14px",
        }}
      >
        Results per page
      </div>
      <div
        style={{
          width: "80px",
          fontSize: "14px",
          marginLeft: "5px",
        }}
      >
        <Select
          defaultValue={items.find(element => element.isRefined === true)}
          classNamePrefix="select"
          isSearchable="false"
          isClearable={false}
          onChange={(value, actionMeta) => {
            if (value) refine(value.value)
          }}
          options={items}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#fef0bf",
              primary: "#fed43f",
              primary50: "#fee27f",
              //neutral0: "#424242",
            },
          })}
        />
      </div>
      {/* <ul>
        {items.map(item => (
          <li key={item.value}>
            <a
              href={createURL(item.value)}
              style={{ fontWeight: item.isRefined ? "bold" : "" }}
              onClick={event => {
                event.preventDefault()
                refine(item.value)
              }}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul> */}
    </div>
  )
  const CustomHitsPerPage = connectHitsPerPage(HitsPerPage)

  const RefinementList = ({
    items,
    currentRefinement,
    refine,
    isFromSearch,
    searchForItems,
    createURL,
    showMore,
    limit,
    translations,
  }) => {
    // console.log({ items })
    const [extended, setExtended] = useState(false)
    return (
      <>
        {/* <div>
          <p
            className=""
            style={{ fontWeight: "500", fontSize: "14px", padding: "5px" }}
          >
            {currentRefinement ? currentRefinement.join("  ") : ""}
          </p>
        </div> */}

        <ul style={{ color: "#4a4a4a" }}>
          <li>
            <div className="field" style={{ marginBottom: "10px" }}>
              <div className="control has-icons-right">
                <input
                  className="input is-warning is-small"
                  type="search"
                  onChange={event => searchForItems(event.currentTarget.value)}
                  placeholder="search..."
                  // style={{
                  //   position: "relative",
                  // }}
                />
                <span
                  className="icon is-small is-right"
                  // style={{
                  //   position: "absolute",
                  //   right: "10px",
                  //   top: "7px",
                  //   color: "#4a4a4a",
                  // }}
                >
                  <i className="far fa-search"></i>
                </span>
              </div>
            </div>
          </li>
          {items
            ? items.map(
                (item, i) =>
                  (i < limit || extended) && (
                    <li key={item.label}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        <a
                          href={createURL(item.value)}
                          style={{
                            fontWeight: item.isRefined ? "bold" : "",
                            color: "#4a4a4a",
                          }}
                          onClick={event => {
                            event.preventDefault()
                            refine(item.value)
                          }}
                        >
                          {/* <input
                type="checkbox"
                style={{ marginRight: "5px" }}
                checked={true}
              ></input> */}
                          {item.isRefined ? (
                            <i
                              className="far fa-check-square fa-lg"
                              style={{ color: "#fec600", marginRight: "5px" }}
                            ></i>
                          ) : (
                            <i
                              className="far fa-square fa-lg"
                              style={{ color: "#fec600", marginRight: "5px" }}
                            ></i>
                          )}
                          {isFromSearch ? (
                            <Highlight attribute="label" hit={item} />
                          ) : (
                            titleCase(item.label)
                          )}{" "}
                          ({item.count})
                        </a>
                      </span>
                    </li>
                  )
              )
            : ""}

          {/* <button onClick={toggleShowMore}>show more</button> */}
        </ul>
        {showMore && (
          <button
            onClick={() => {
              setExtended(!extended)
            }}
            className="button is-small is-warning"
            style={{ margin: "10px 0" }}
          >
            {translations["showMore"](extended)}
          </button>
        )}
      </>
    )
  }
  const CustomRefinementList = connectRefinementList(RefinementList)
  var toggleFilter = true
  return (
    <>
      {/* My Activities <Autocomplete /> */}
      {/* <ReactTooltip /> */}
      <div className="columns is-multiline ">
        <div className="column is-1-desktop is-1-tablet "></div>
        <div className="column is-10-desktop is-10-tablet ">
          <p
            style={{
              fontSize: "24px",
              fontWeight: "700",
              borderBottom: "2px solid #fec600",
            }}
          >
            Activities
          </p>

          <p
            style={{
              fontSize: "20px",
              fontWeight: "400",
              textAlign: "center",
              margin: "16px 0px",
              // borderBottom: "2px solid #fec600",
            }}
          >
            You can create new or edit existing activities here.
          </p>
        </div>
        <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
        {/* <hr className="hr-branded"></hr> */}
        {showNewActivityEntry ? (
          <>
            <div className="column is-4-desktop is-1-tablet is-hidden-mobile "></div>
            <div className="column is-4-desktop is-10-tablet ">
              <div className="new-activity-form-container">
                <NewActivityForm
                  onCancel={() => {
                    setShowNewActivityEntry(false)
                    setInActivityEditMode(false)
                    setShowActivity(true)
                    setForceRefresh(true)
                    //dispatch({ type: "clearActivityScratchPad" })
                  }}
                  addToItinerary={false}
                  activeTab={activeTab}
                  onActivityCreated={onActivityCreated}
                  inEditMode={inActivityEditMode}
                />
              </div>
            </div>
            <div className="column is-4-desktop is-1-tablet "></div>
          </>
        ) : showActivity && is.existy(state.activityScratchPad.activityType) ? (
          <>
            <div className="column is-2-desktop is-1-tablet "></div>
            <div className="column is-8-desktop is-10-tablet ">
              <div
                className="button is-warning"
                onClick={() => {
                  setShowActivity(false)
                }}
              >
                Go Back
              </div>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  borderBottom: "2px solid #fec600",
                  margin: "10px auto",
                  textAlign: "center",
                }}
              >
                Activity Details
              </p>
              <ActivityViewer
                value={{ activity: state.activityScratchPad }}
                canEdit={true}
                onEditActivity={onEditActivity}
                onRemove={onRemove}
              />
            </div>

            <div className="column is-2-desktop is-1-tablet "></div>
          </>
        ) : (
          <>
            <div className="column is-1-desktop is-1-tablet is-hidden-mobile "></div>
            <div className="column is-10-desktop is-10-tablet ">
              <div className="activities-search-bar">
                <div className="search-bar">
                  <InstantSearch
                    refresh={forceRefresh}
                    indexName={`activities-${authUser ? authUser.uid : ""}`}
                    searchClient={searchClient}
                  >
                    <CustomSearchBox />
                    {/* <CustomAutocomplete /> */}{" "}
                    <nav
                      className="level is-mobile"
                      style={{ marginTop: "5px" }}
                    >
                      <div className="level-left">
                        <div className="level-item">
                          <button
                            id="filterButton"
                            className="button is-small is-warning"
                            onClick={e => {
                              var x = document.getElementById("filterDIV")
                              var y = document.getElementById("filterButton")
                              // toggleFilter = !toggleFilter
                              if (x.style.display === "none") {
                                x.style.display = "block"
                                toggleFilter = false
                                y.innerText = "Hide filters"
                              } else {
                                x.style.display = "none"
                                toggleFilter = true
                                y.innerText = "Show filters"
                              }
                            }}

                            // style={{ textAlign: "right", fontSize: "16px" }}
                          >
                            Hide filters
                            {console.log(toggleFilter)
                            // toggleFilter ? (
                            //   <p>
                            //     Show Filters{" "}
                            //     <i style={{ marginLeft: "5px" }} className="far fa-chevron-down"></i>
                            //   </p>
                            // ) : (
                            //   <p>
                            //     Hide Filters{" "}
                            //     <i style={{ marginLeft: "5px" }} className="far fa-chevron-right"></i>
                            //   </p>
                            // )
                            }
                          </button>
                        </div>
                      </div>

                      <div className="level-right">
                        <div className="level-item">
                          <CustomHitsPerPage
                            defaultRefinement={10}
                            items={[
                              { value: 5, label: "5" },
                              { value: 10, label: "10" },
                              { value: 20, label: "20" },
                            ]}
                          />
                        </div>
                      </div>
                    </nav>
                    <div className="columns">
                      <div
                        className="column is-3-desktop is-2-tablet "
                        id="filterDIV"
                      >
                        <div style={{ marginBottom: "10px" }}>
                          <p style={{ fontWeight: "500", fontSize: "14px" }}>
                            Tags:
                          </p>
                          <CustomRefinementList
                            limit={5}
                            showMore={true}
                            showMoreLimit={25}
                            searchable={true}
                            attribute="activityTags"
                            translations={{
                              showMore(expanded) {
                                return expanded ? "Show less" : "Show more"
                              },
                            }}
                          />
                        </div>

                        <div>
                          <p style={{ fontWeight: "500", fontSize: "14px" }}>
                            Activity Type:
                          </p>
                          <CustomRefinementList
                            limit={5}
                            showMore={true}
                            showMoreLimit={25}
                            searchable={true}
                            attribute="activityType"
                            translations={{
                              showMore(expanded) {
                                return expanded ? "Show less" : "Show more"
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="column" style={{ minHeight: "200px" }}>
                        <CustomHits />
                      </div>
                    </div>
                    <CustomPagination />
                  </InstantSearch>
                </div>{" "}
              </div>
            </div>
            <div className="column is-1-desktop is-1-tablet is-hidden-mobile "></div>
            {/* <div className="column is-4-desktop is-1-tablet "></div>
              <div className="column is-4-desktop is-10-tablet "> */}
            {/* </div>
              <div className="column is-4-desktop is-1-tablet "></div> */}
          </>
        )}
        {/* <hr className="hr-branded"></hr> */}
      </div>
    </>
  )
}

const ActivityContainer = () => {
  return (
    <ToastProvider>
      <DashboardStateProvider>
        <ActivityContainerBase />
      </DashboardStateProvider>
    </ToastProvider>
  )
}

export default ActivityContainer
