import React, { useEffect } from "react"
import MessageActivityComponent from "./MessageActivityComponent"

export default function MessageActivityWrapper({ roomId, recipientUid }) {
  // useEffect(() => {
  //   console.log({ roomId, recipientUid })
  //   console.log("MessageActivityWrapper here")
  // }, [])
  return (
    <div className="columns">
      <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
      <div className="column is-8-desktop is-10-tablet is-12-mobile">
        <p
          style={{
            fontSize: "24px",
            fontWeight: "700",
            borderBottom: "2px solid #fec600",
          }}
        >
          Message Conversation
        </p>
        <div style={{ height: "30px" }}></div>
        <MessageActivityComponent roomId={roomId} recipientUid={recipientUid} />
      </div>
      <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
    </div>
  )
}
