import React, { useState, useEffect, useRef, useContext } from "react"
import { SingleDatePicker } from "react-dates"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import algoliasearch from "algoliasearch/lite"
import { createAutocomplete } from "@algolia/autocomplete-core"
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia"
import "./FlightDetails.scss"
import Cleave from "cleave.js/react"
import is from "is_js"

import moment from "moment"

import { FirebaseContext } from "../../../components/firebase"
import { AuthUserContext } from "../../../components/session"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
const searchClient = algoliasearch(
  `${process.env.ALGOLIA_SEARCH_APP_ID}`,
  `${process.env.ALGOLIA_SEARCH_API_KEY}`
)
// const searchClientDepart = algoliasearch(
//   `${process.env.ALGOLIA_SEARCH_APP_ID}`,
//   `${process.env.ALGOLIA_SEARCH_API_KEY}`
// )
const FlightDetails = ({ canEdit = false }) => {
  var originRef = useRef(null)
  var originRefResults = useRef(null)
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [isBusy, setIsBusy] = useState(false)
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [startDate, setStartDate] = useState()
  const [focussed, setFocussed] = useState(false)
  const [currentFlightInfo, setCurrentFlightInfo] = useState({
    flightNumber: "",
    departDate: "",
    departDateFocus: false,
    departTime: "",
    departAirport: "",
    departCity: "",
    arriveDate: "",
    arriveDateFocus: false,
    arriveTime: "",
    arriveAirport: "",
    arriveCity: "",
    confirmationNumber: "",
    additionalNotes: "",
  })
  const [autocompleteStateArrive, setAutocompleteStateArrive] = useState("")
  const [autocompleteStateDepart, setAutocompleteStateDepart] = useState("")
  const [showAddNewFlightEntry, setShowAddNewFlightEntry] = useState(false)
  const [inEditMode, setInEditMode] = useState(9999)
  const [selectedArrivalAirport, setSelectedArrivalAirport] = useState({})
  const [selectedDepartureAirport, setSelectedDepartureAirport] = useState({})

  useEffect(() => {
    console.log({ currentFlightInfo })
  }, [currentFlightInfo])

  useEffect(() => {
    console.log({ selectedArrivalAirport })
    if (selectedArrivalAirport) {
      var a = { ...currentFlightInfo }
      console.log({ a })
      a["arriveAirport"] = selectedArrivalAirport.iata_code
      a["arriveCity"] = selectedArrivalAirport.city
      setCurrentFlightInfo({ ...a })
      var b = document.getElementById("arrive-airport")
      if (b) {
        b.value = `${selectedArrivalAirport.iata_code} - ${selectedArrivalAirport.city}`
      }
    }
  }, [selectedArrivalAirport])
  useEffect(() => {
    console.log({ selectedDepartureAirport })
    if (selectedDepartureAirport) {
      var a = { ...currentFlightInfo }
      console.log({ a })
      a["departAirport"] = selectedDepartureAirport.iata_code
      a["departCity"] = selectedDepartureAirport.city
      setCurrentFlightInfo({ ...a })
      var b = document.getElementById("depart-airport")
      if (b) {
        b.value = `${selectedDepartureAirport.iata_code} - ${selectedDepartureAirport.city}`
      }
    }
  }, [selectedDepartureAirport])

  useEffect(() => {
    console.log(autocompleteStateDepart.activeItemId)
    try {
      if (!autocompleteStateDepart.isOpen) {
        hideItem("depart-panel")
      }
    } catch (error) {}
  }, [autocompleteStateDepart])
  useEffect(() => {
    console.log(autocompleteStateArrive.activeItemId)
    try {
      if (!autocompleteStateArrive.isOpen) {
        hideItem("arrive-panel")
      }
    } catch (error) {}
  }, [autocompleteStateArrive])

  const clearResultsPanel = () => {
    setTimeout(() => {
      if (!autocompleteStateDepart.isOpen) {
        hideItem("depart-panel")
      }
      if (!autocompleteStateArrive.isOpen) {
        hideItem("arrive-panel")
      }
    }, 100)
  }

  useEffect(() => {
    try {
      if (state.currentJobInfo.flightDetailsSyncRequired) {
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${tripState.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({
              flightDetails: state.currentJobInfo.flightDetails,
            })
            .then(() => {
              console.log("document update successful")
              // //update local state now
              // setIsBusy(false)
              // dispatch({
              //   type: "addNewFlightDetail",
              //   data: currentFlightInfo,
              // })
              // setCurrentFlightInfo({
              //   flightNumber: "",
              //   departDate: "",
              //   departDateFocus: "",
              //   departTime: "",
              //   departAirport: "",
              //   departCity: "",
              //   arriveDate: "",
              //   arriveDateFocus: "",
              //   arriveTime: "",
              //   arriveAirport: "",
              //   arriveCity: "",
              //   confirmationNumber: "",
              //   additionalNotes: "",
              // })
              // setShowAddNewFlightEntry(false)
            })
            .catch(e => {
              setIsBusy(false)
              console.log(e)
            })
        }
        dispatch({ type: "flightDetailsSyncRequired", data: false })
      }
    } catch (error) {}
  }, [state.currentJobInfo.flightDetailsSyncRequired])

  const showFlightDetails = params => {
    try {
      if (
        state.currentJobInfo.flightDetails &&
        state.currentJobInfo.flightDetails.length > 0
      ) {
        return (
          <>
            {canEdit && (
              <div
                style={{
                  margin: "30px auto",
                  maxWidth: "400px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  onClick={() => {
                    try {
                      setShowAddNewFlightEntry(true)
                      clearResultsPanel()
                    } catch (error) {}
                  }}
                  // className="a"
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    textDecorationColor: "#fec600",
                    color: "rgba(74, 74, 74, 0.8)",
                    textDecoration: "none",
                  }}
                >
                  <i className="far fa-plus" style={{ marginRight: "5px" }}></i>{" "}
                  Add another flight
                </a>
              </div>
            )}
            {state.currentJobInfo.flightDetails.map(
              (item, index, { length }) => {
                if (inEditMode === index) {
                  return <>{newFlightEntryForm(true, index)}</>
                } else
                  return (
                    <div
                      key={index}
                      style={{
                        margin: "10px auto",
                        padding: "15px",
                        // background: "#fafafa",
                        maxWidth: "500px",
                      }}
                    >
                      {canEdit && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "#fec600",
                          }}
                        >
                          <div
                            style={{ marginRight: "20px", cursor: "pointer" }}
                            onClick={() => {
                              setCurrentFlightInfo({
                                ...state.currentJobInfo.flightDetails[index],
                              })
                              setInEditMode(index)
                              clearResultsPanel()
                              setAirportValues(item)
                            }}
                          >
                            <i className="far fa-pen"></i>
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              ConfirmationModal({
                                onConfirm: i => {
                                  console.log(i)
                                  dispatch({
                                    type: "deleteFlightDetailEntry",
                                    index: i,
                                  })
                                  dispatch({
                                    type: "flightDetailsSyncRequired",
                                    data: true,
                                  })
                                },
                                sendBackValue: index,
                                title: "Confirmation",
                                text: `Are you sure you want to permanently delete this entry?`,
                              })
                            }}
                          >
                            <i className="far fa-trash-alt"></i>
                          </div>
                        </div>
                      )}
                      <div className="flight-details">
                        <div className="depart-details">
                          <div className="depart-airport">
                            {item.departAirport}
                          </div>
                          <div className="depart-city">{item.departCity}</div>
                          <div className="depart-date">
                            {`${moment(item.departDate).format("L")} - ${
                              item.departTime
                            }`}
                          </div>
                          {/* <div className="depart-time">{item.departTime}</div> */}
                        </div>
                        <div className="flight-no-details">
                          {/* <span style={{ borderTop: "dotted 2px #fec600" }}></span> */}
                          <i
                            style={{
                              textAlign: "center",
                              fontSize: "30px",
                              color: "#fec600",
                              marginBottom: "10px",
                            }}
                            className="fal fa-plane"
                          ></i>
                          <div
                            style={{
                              letterSpacing: "0.5px",
                              fontWeight: "500",
                            }}
                            className="flight-no"
                          >
                            {item.flightNumber}
                          </div>
                        </div>

                        <div className="arrive-details">
                          <div className="arrive-airport">
                            {item.arriveAirport}
                          </div>
                          <div className="arrive-city">{item.arriveCity}</div>
                          <div className="arrive-date">
                            {`${moment(item.arriveDate).format("L")} - ${
                              item.arriveTime
                            }`}
                          </div>
                          {/* <div className="arrive-time">{}</div> */}
                        </div>
                      </div>

                      <div style={{ display: "flex" }}>
                        <div
                          className="confirm-no"
                          style={{ marginRight: "20px", minWidth: "100px" }}
                        >
                          <span
                            style={{
                              fontWeight: "300",
                              textTransform: "uppercase",
                              letterSpacing: "0.5px",
                              fontSize: "10px",
                            }}
                          >
                            Confirmation #{" "}
                          </span>
                          <div style={{ fontWeight: "400", fontSize: "14px" }}>
                            {item.confirmationNumber === ""
                              ? "--"
                              : item.confirmationNumber}
                          </div>
                        </div>
                        <div className="additional-details">
                          {" "}
                          <span
                            style={{
                              fontWeight: "300",
                              textTransform: "uppercase",
                              letterSpacing: "0.5px",
                              fontSize: "10px",
                            }}
                          >
                            Notes{" "}
                          </span>
                          <div style={{ fontWeight: "400", fontSize: "14px" }}>
                            {item.additionalNotes === ""
                              ? "--"
                              : item.additionalNotes}
                          </div>
                        </div>
                      </div>
                      {/* <hr className="hr-branded"></hr> */}
                    </div>
                  )
              }
            )}
          </>
        )
      } else {
        return canEdit ? (
          <div
            style={{
              margin: "30px auto",
              maxWidth: "400px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontWeight: "600",
                textDecorationColor: "#fec600",
                color: "rgba(74, 74, 74, 0.8)",
                textDecoration: "none",
              }}
              onClick={() => {
                setShowAddNewFlightEntry(true)
                clearResultsPanel()
              }}
              // className="button is-warning"
            >
              <i className="far fa-plus" style={{ marginRight: "5px" }}></i> Add
              a flight
            </a>
          </div>
        ) : (
          <p
            style={{
              // fontStyle: "italic",
              fontSize: "14px",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            - No flights added yet -
          </p>
        )
      }
    } catch (error) {}
  }

  const autocompletearrive = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteStateArrive(state)
        },
        id: "autocompletearrive",
        navigator: {
          navigate({ itemUrl }) {
            console.log({ itemUrl })
          },
          navigateNewTab({ itemUrl }) {
            console.log({ itemUrl })
          },
        },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: "arrive",
              getItemInputValue({ item }) {
                return item.query
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "Airports",
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: "<mark>",
                        highlightPostTag: "</mark>",
                      },
                    },
                  ],
                })
              },
              getItemUrl({ item }) {
                return item.url
              },
              onSelect({ item }) {
                // console.log({ item })
                setSelectedArrivalAirport(item)
              },
            },
          ]
        },
      }),
    []
  )

  const autocompletedepart = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteStateDepart(state)
        },
        id: "autocompletedepart",
        navigator: {
          navigate({ itemUrl }) {
            console.log({ itemUrl })
          },
          navigateNewTab({ itemUrl }) {
            console.log({ itemUrl })
          },
        },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: "depart",
              getItemInputValue({ item }) {
                //    console.log({ item })
                return item.query
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "Airports",
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: "<mark>",
                        highlightPostTag: "</mark>",
                      },
                    },
                  ],
                })
              },
              getItemUrl({ item }) {
                return item.url
              },
              onActive({ item }) {
                //     console.log({ item })
              },
              onSelect({ item }) {
                setSelectedDepartureAirport(item)
              },
            },
          ]
        },
      }),
    []
  )

  const hideItem = id => {
    try {
      let a = document.getElementById(id)
      if (a) {
        a.style.display = "none"
      }
    } catch (error) {}
  }

  const showItem = id => {
    try {
      let a = document.getElementById(id)
      if (a) {
        a.style.display = "block"
      }
    } catch (error) {}
  }

  const clearCurrentFlightInfo = () => {
    setCurrentFlightInfo({
      flightNumber: "",
      departDate: "",
      departDateFocus: false,
      departTime: "",
      departAirport: "",
      departCity: "",
      arriveDate: "",
      arriveDateFocus: false,
      arriveTime: "",
      arriveAirport: "",
      arriveCity: "",
      confirmationNumber: "",
      additionalNotes: "",
    })
  }

  const setAirportValues = item => {
    try {
      setTimeout(() => {
        var a = document.getElementById("depart-airport")
        if (a) {
          a.value = `${item.departAirport} - ${item.departCity}`
        }
        var b = document.getElementById("arrive-airport")
        if (b) {
          b.value = `${item.arriveAirport} - ${item.arriveCity}`
        }
      }, 100)
    } catch (err) {}
  }

  const newFlightEntryForm = (inEditMode, editedItemIndex) => {
    try {
      return (
        <>
          <div className="field">
            <label className="label">Flight Number</label>
            <div className="control">
              <input
                className="input"
                type="text"
                tabIndex="1"
                autoFocus
                placeholder="E.g. AC1856"
                value={currentFlightInfo.flightNumber}
                onChange={e => {
                  var a = { ...currentFlightInfo }
                  if (e.currentTarget.value) {
                    a["flightNumber"] = e.currentTarget.value.toUpperCase()
                  } else {
                    a["flightNumber"] = e.currentTarget.value
                  }
                  setCurrentFlightInfo({ ...a })
                }}
              ></input>
            </div>
          </div>
          <label className="label">Depart</label>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              {/* <label className="label">Depart</label> */}
              <div className="control" style={{ width: "100%" }}>
                <SingleDatePicker
                  date={
                    currentFlightInfo.departDate != ""
                      ? moment(currentFlightInfo.departDate)
                      : null
                  } // momentPropTypes.momentObj or null
                  numberOfMonths={1}
                  tabIndex="2"
                  onDateChange={date => {
                    try {
                      console.log(date)
                      var a = currentFlightInfo
                      console.log(a)
                      if (moment.isMoment(date)) {
                        a["departDate"] = moment(date).format("L")
                        setCurrentFlightInfo({ ...a })
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }} // PropTypes.func.isRequired
                  readOnly={is.mobile() ? true : false}
                  focused={currentFlightInfo.departDateFocus} // PropTypes.bool
                  onFocusChange={({ focused }) => {
                    var a = { ...currentFlightInfo }
                    a["departDateFocus"] = focused
                    setCurrentFlightInfo({ ...a })
                  }} // PropTypes.func.isRequired
                  style={{ width: "100% !important" }}
                  id="your_unique_1" // PropTypes.string.isRequired,
                />
              </div>
            </div>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              {/* <label className="label">Depart</label> */}
              <div className="control">
                <div className="control">
                  {/* <input
               style={{ textAlign: "center" }}
               className="input"
               type="text"
               placeholder="Time 24hr"
             ></input> */}
                  <Cleave
                    className="input"
                    value={currentFlightInfo.departTime}
                    style={{ textAlign: "center" }}
                    placeholder="Time 24hr"
                    tabIndex="3"
                    options={{ time: true, timePattern: ["h", "m"] }}
                    onChange={e => {
                      var a = { ...currentFlightInfo }
                      a["departTime"] = e.currentTarget.value
                      setCurrentFlightInfo({ ...a })
                      console.log(e.currentTarget.value)
                      console.log(e.currentTarget.rawValue)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field" style={{ marginRight: "8px" }}>
            <div className="control">
              <div
                className="aa-Autocomplete"
                {...autocompletedepart.getRootProps({})}
              >
                <input
                  className="aa-Input input"
                  {...autocompletedepart.getInputProps({})}
                  id="depart-airport"
                  placeholder="Airport"
                  tabIndex="4"
                  // onBlur={e => {
                  //   let a = e.currentTarget.value
                  //   if (
                  //     a === "" &&
                  //     currentFlightInfo.departAirport != "" &&
                  //     currentFlightInfo.departCity != ""
                  //   ) {
                  //     var b = document.getElementById("depart-airport")
                  //     if (b) {
                  //       b.value = `${currentFlightInfo.departAirport} - ${currentFlightInfo.departCity}`
                  //     }
                  //   }
                  // }}
                />
                <div
                  className="aa-Panel"
                  id="depart-panel"
                  {...autocompletedepart.getPanelProps({})}
                >
                  {autocompleteStateDepart.isOpen &&
                    autocompleteStateDepart.collections.map(
                      (collection, index) => {
                        const { source, items } = collection
                        if (autocompleteStateDepart.isOpen) {
                          showItem("depart-panel")
                        } else {
                          hideItem("depart-panel")
                        }

                        return (
                          <div key={`source-${index}`} className="aa-Source">
                            {items.length > 0 && (
                              <ul
                                className="aa-List"
                                {...autocompletedepart.getListProps()}
                              >
                                {items.map((item, index) => (
                                  <li
                                    key={item.objectID}
                                    className="aa-Item"
                                    style={{
                                      background:
                                        autocompleteStateDepart.activeItemId ===
                                        index
                                          ? "rgba(254, 198, 0, 1)"
                                          : "white",
                                    }}
                                    {...autocompletedepart.getItemProps({
                                      item,
                                      source,
                                    })}
                                  >
                                    <div
                                      style={{
                                        padding: "10px",
                                        minHeight: "40px",
                                        cursor: "pointer",
                                      }}
                                      className="airport-code-items"
                                      onClick={() => {
                                        // var a = { ...currentFlightInfo }
                                        // a["departAirport"] = item.iata_code
                                        // a["departCity"] = item.city
                                        // setCurrentFlightInfo({ ...a })
                                        // var b = document.getElementById(
                                        //   "depart-airport"
                                        // )
                                        // if (b) {
                                        //   b.value = `${item.iata_code} - ${item.city}`
                                        // }
                                      }}
                                    >
                                      <nav className="level is-mobile">
                                        <div className="level-left">
                                          <div className="level-item">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <div>
                                                {item.city}, {item.country}
                                              </div>
                                              <div>
                                                <p
                                                  className="help"
                                                  style={{
                                                    marginTop: "-2px",
                                                  }}
                                                >
                                                  {item.name}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="level-right">
                                          <span
                                            className="tag is-warning"
                                            style={{
                                              textTransform: "uppercase",
                                              letterSpacing: "0.5px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {" "}
                                            {item.iata_code}
                                          </span>
                                        </div>
                                      </nav>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        )
                      }
                    )}
                </div>
              </div>
            </div>
          </div>
          <label className="label">Arrive</label>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              {/* <label className="label">Depart</label> */}
              <div className="control">
                <div className="control">
                  <SingleDatePicker
                    date={
                      currentFlightInfo.arriveDate
                        ? moment(currentFlightInfo.arriveDate)
                        : null
                    } // momentPropTypes.momentObj or null
                    numberOfMonths={1}
                    onDateChange={date => {
                      try {
                        var a = currentFlightInfo
                        if (moment.isMoment(date)) {
                          a["arriveDate"] = moment(date).format("L")
                          setCurrentFlightInfo({ ...a })
                        }
                      } catch (err) {
                        console.log(err)
                      }
                    }} // PropTypes.func.isRequired
                    tabIndex="5"
                    readOnly={is.mobile() ? true : false}
                    focused={currentFlightInfo.arriveDateFocus} // PropTypes.bool
                    onFocusChange={({ focused }) => {
                      var a = { ...currentFlightInfo }
                      a["arriveDateFocus"] = focused
                      setCurrentFlightInfo({ ...a })
                    }} // PropTypes.func.isRequired
                    id="your_unique_2" // PropTypes.string.isRequired,
                  />
                </div>
              </div>
            </div>
            <div className="field" style={{ marginRight: "8px", width: "50%" }}>
              {/* <label className="label">Depart</label> */}
              <div className="control">
                <div className="control">
                  {/* <input
               className="input"
               style={{ textAlign: "center" }}
               type="text"
               placeholder="Time"
             ></input> */}
                  <Cleave
                    className="input"
                    value={currentFlightInfo.arriveTime}
                    style={{ textAlign: "center" }}
                    placeholder="Time 24hr"
                    options={{ time: true, timePattern: ["h", "m"] }}
                    tabIndex="6"
                    onChange={e => {
                      var a = { ...currentFlightInfo }
                      a["arriveTime"] = e.currentTarget.value
                      setCurrentFlightInfo({ ...a })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field" style={{ marginRight: "8px" }}>
            <div className="control">
              {/* <input className="input" type="text" placeholder="Airport"></input> */}
              <div
                className="aa-Autocomplete"
                {...autocompletearrive.getRootProps({})}
              >
                <input
                  className="aa-Input input"
                  {...autocompletearrive.getInputProps({})}
                  id="arrive-airport"
                  placeholder="Airport"
                  tabIndex="7"
                  // onBlur={e => {
                  //   let a = e.currentTarget.value
                  //   if (
                  //     a === "" &&
                  //     currentFlightInfo.arriveAirport != "" &&
                  //     currentFlightInfo.arriveCity != ""
                  //   ) {
                  //     setAirportValues(currentFlightInfo)
                  //   }
                  //   console.log(e.currentTarget.value)
                  // }}
                  // onFocus={e => {
                  //   let a = e.currentTarget.value
                  //   if (
                  //     a === "" &&
                  //     currentFlightInfo.arriveAirport != "" &&
                  //     currentFlightInfo.arriveCity != ""
                  //   ) {
                  //     setAirportValues(currentFlightInfo)
                  //   }
                  //   console.log(e.currentTarget.value)
                  // }}
                />
                <div
                  className="aa-Panel"
                  id="arrive-panel"
                  {...autocompletearrive.getPanelProps({})}
                  //   placeholder="Airport"
                >
                  {autocompleteStateArrive.isOpen &&
                    autocompleteStateArrive.collections.map(
                      (collection, index) => {
                        const { source, items } = collection
                        if (autocompleteStateArrive.isOpen) {
                          showItem("arrive-panel")
                        } else {
                          hideItem("arrive-panel")
                        }

                        return (
                          <div key={`source-${index}`} className="aa-Source">
                            {items.length > 0 && (
                              <ul
                                className="aa-List"
                                {...autocompletearrive.getListProps()}
                              >
                                {items.map((item, index) => (
                                  <li
                                    key={item.objectID}
                                    style={{
                                      background:
                                        autocompleteStateArrive.activeItemId ===
                                        index
                                          ? "rgba(254, 198, 0, 1)"
                                          : "white",
                                    }}
                                    className="aa-Item"
                                    {...autocompletearrive.getItemProps({
                                      item,
                                      source,
                                    })}
                                  >
                                    <div
                                      style={{
                                        padding: "10px",
                                        minHeight: "40px",
                                        cursor: "pointer",
                                      }}
                                      className="airport-code-items"
                                      onClick={() => {
                                        var a = { ...currentFlightInfo }
                                        a["arriveAirport"] = item.iata_code
                                        a["arriveCity"] = item.city
                                        setCurrentFlightInfo({ ...a })
                                        var b = document.getElementById(
                                          "arrive-airport"
                                        )
                                        if (b) {
                                          b.value = `${item.iata_code} - ${item.city}`
                                        }
                                      }}
                                    >
                                      <nav className="level is-mobile">
                                        <div className="level-left">
                                          <div className="level-item">
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <div>
                                                {item.city}, {item.country}
                                              </div>
                                              <div>
                                                <p
                                                  className="help"
                                                  style={{ marginTop: "-2px" }}
                                                >
                                                  {item.name}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="level-right">
                                          <span
                                            className="tag is-warning"
                                            style={{
                                              textTransform: "uppercase",
                                              letterSpacing: "0.5px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {" "}
                                            {item.iata_code}
                                          </span>
                                        </div>
                                      </nav>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        )
                      }
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="field" style={{ marginRight: "8px" }}>
            <label className="label">Confirmation #</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder=""
                tabIndex="8"
                value={currentFlightInfo.confirmationNumber}
                onChange={e => {
                  var a = { ...currentFlightInfo }

                  a["confirmationNumber"] = e.currentTarget.value
                  setCurrentFlightInfo({ ...a })
                }}
              ></input>
            </div>
          </div>
          <div className="field" style={{ marginRight: "8px" }}>
            <label className="label">Additional Notes</label>
            <div className="control">
              <textarea
                value={currentFlightInfo.additionalNotes}
                rows="2"
                className="textarea"
                type="text"
                placeholder=""
                maxLength="100"
                tabIndex="9"
                onChange={e => {
                  var a = { ...currentFlightInfo }
                  console.log(e.currentTarget.value)
                  a["additionalNotes"] = e.currentTarget.value
                  setCurrentFlightInfo({ ...a })
                }}
              ></textarea>
              <p className={`help is-pulled-right`}>
                {/* Characters:{" "} */}
                {currentFlightInfo.additionalNotes
                  ? currentFlightInfo.additionalNotes.length
                  : "0"}
                /{100}
              </p>
            </div>
          </div>
          <div
            className="field is-grouped is-grouped-right"
            style={{ marginTop: "40px" }}
          >
            <div className="control">
              <button
                onClick={() => {
                  clearCurrentFlightInfo()
                  setShowAddNewFlightEntry(false)
                  setInEditMode(9999)
                }}
                tabIndex="11"
                className="button is-warning is-light"
              >
                Cancel
              </button>
            </div>
            <div className="control">
              <button
                class={`${
                  isBusy ? "button is-warning is-loading" : "button is-warning"
                }`}
                tabIndex="10"
                onClick={() => {
                  if (isBusy) return
                  setIsBusy(true)
                  if (inEditMode) {
                    dispatch({
                      type: "saveFlightEdits",
                      data: currentFlightInfo,
                      index: editedItemIndex,
                    })
                  } else {
                    dispatch({
                      type: "addNewFlightDetail",
                      data: currentFlightInfo,
                    })
                  }

                  clearCurrentFlightInfo()
                  setShowAddNewFlightEntry(false)

                  dispatch({ type: "flightDetailsSyncRequired", data: true })
                  setIsBusy(false)
                  setInEditMode(9999)

                  // if (dbContext) {
                  //   dbContext
                  //     .firestore()
                  //     .collection("jobs")
                  //     .doc(`${authContext.uid}`)
                  //     .collection("active-jobs")
                  //     .doc(`${state.currentJobInfo.jobUid}`)
                  //     .update({
                  //       flightDetails: dbContext.firestore.FieldValue.arrayUnion(
                  //         currentFlightInfo
                  //       ),
                  //     })
                  //     .then(() => {
                  //       console.log("document update successful")
                  //       //update local state now

                  //     })
                  //     .catch(e => {
                  //       setIsBusy(false)
                  //       console.log(e)
                  //     })
                  // }
                }}
              >
                {inEditMode ? "Save Changes" : "Save"}
              </button>
            </div>
          </div>
          <hr className="hr-branded"></hr>
        </>
      )
    } catch (error) {}
  }

  return (
    <div>
      <div className="columns">
        <div className="column is-3-desktop is-hidden-mobile"></div>
        <div className="column is-6-desktop">
          <p
            // style={{
            //   fontSize: "34px",
            //   fontWeight: "800",
            //   color: "rgba(74,74,74,0.8)",
            //   margin: "20px auto",
            //   lineHeight: "1.4",
            //   textAlign: "center",
            // }}
            className="tab-header"
          >
            Flight Details<span style={{ color: "#fec600" }}>.</span>
          </p>
          <p className="tab-tagline">
            Flights that you would be taking to, from or during your trip.
            <span style={{ fontWeight: "500" }}> TIP:</span> These would show up
            on your daily itinerary.
          </p>
          {!showAddNewFlightEntry && showFlightDetails()}{" "}
          {showAddNewFlightEntry && newFlightEntryForm(false, 0)}
        </div>
        <div className="column is-3-desktop is-hidden-mobile"></div>
      </div>
    </div>
  )
}

export default FlightDetails
