import React from "react"
import MessageActivityContainer from "./MessageActivityContainer"

export default function MessageWindow() {
  return (
    <div className="messages-card fade-in">
      <MessageActivityContainer />
    </div>
  )
}
