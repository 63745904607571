import React, { useState, useEffect, useContext, useRef } from "react"
import { useDashboardStateStore } from "../../State/dashboard-state/dashboard-state"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "./../../components/firebase"
import Select from "react-select"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import swal from "sweetalert"
import is from "is_js"
import { ToastProvider, useToasts } from "react-toast-notifications"
import { navigate } from "gatsby"

import {
  ProfileStateProvider,
  useProfileStateStore,
} from "../../State/profile-state/profile-state"
import { responsiveColumnWrap } from "../../utility/general"
import {
  countryListValueLabel,
  designerSkills,
  languages,
} from "../../constants/data/general"
import loadable from "@loadable/component"
const ReactQuill = loadable(() => import("react-quill"))
// var introductionHtmlLen = 0
const AddAdditionalInfoBase = () => {
  const userAuthContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [state, dispatch] = useProfileStateStore()
  const [loading, setLoading] = useState(true)
  const { addToast } = useToasts()
  const [introductionHtmlLen, setIntroductionHtmlLen] = useState(0)
  var introductionRef = useRef(null)
  const maxIntroductionHtmlLen = 701
  const [
    reachedAboutIntroductionLen,
    setReachedAboutIntroductionLen,
  ] = useState(false)
  const [skillsHtmlLen, setSkillsHtmlLen] = useState(0)
  var skillsRef = useRef(null)
  const maxSkillsHtmlLen = 701
  const [reachedSkillsLen, setReachedSkillsLen] = useState(false)
  const maxSkillsAllowed = 7
  const maxCountriesAllowed = 10
  useEffect(() => {
    //document.title = "My Profile | voyay!ge"

    if (dbContext) {
      dbContext
        .firestore()
        .collection("users")
        .doc(`${userAuthContext.uid}`)
        .collection("publicProfile")
        .doc(`${userAuthContext.uid}`)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            console.log(doc.data())
            dispatch({ type: "updateInitialState", data: doc.data() })
            setLoading(false)
          } else {
            console.log("No such document!")
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error)
        })
    }

    return () => {}
  }, [dbContext])

  useEffect(() => {
    console.log({ state })
    if (introductionRef) {
      try {
        const editor = introductionRef.getEditor()
        const quill = introductionRef.makeUnprivilegedEditor(editor)
        let len = quill.getLength()
        setIntroductionHtmlLen(len)
        if (len < maxIntroductionHtmlLen) setReachedAboutIntroductionLen(false)
      } catch (err) {}
    }
    if (skillsRef) {
      try {
        const editor = skillsRef.getEditor()
        const quill = skillsRef.makeUnprivilegedEditor(editor)
        let len = quill.getLength()
        setSkillsHtmlLen(len)
        if (len < maxSkillsHtmlLen) setReachedSkillsLen(false)
      } catch (err) {}
    }
  }, [state])

  const pushChangesToDB = params => {
    try {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("users")
          .doc(`${userAuthContext.uid}`)
          .collection("publicProfile")
          .doc(`${userAuthContext.uid}`)
          .update({
            countriesVisited: is.not.existy(
              state.tempPublicProfile.countriesVisited
            )
              ? ""
              : state.tempPublicProfile.countriesVisited,
            countriesForItineraries: is.not.existy(
              state.tempPublicProfile.countriesForItineraries
            )
              ? ""
              : state.tempPublicProfile.countriesForItineraries,
            aboutIntroduction: is.not.existy(
              state.tempPublicProfile.aboutIntroduction
            )
              ? ""
              : state.tempPublicProfile.aboutIntroduction,
            aboutSkills: is.not.existy(state.tempPublicProfile.aboutSkills)
              ? ""
              : state.tempPublicProfile.aboutSkills,
            countriesVisitedCount: is.not.existy(
              state.tempPublicProfile.countriesVisited
            )
              ? ""
              : state.tempPublicProfile.countriesVisited.length,

            languagesSpoken: is.not.existy(
              state.tempPublicProfile.languagesSpoken
            )
              ? ""
              : state.tempPublicProfile.languagesSpoken,
            skills: is.not.existy(state.tempPublicProfile.skills)
              ? ""
              : state.tempPublicProfile.skills,
          })
          .then(function() {
            //  navigate("/app/designersignup")
            window.history.back()
          })
          .catch(error => {
            console.log("Error updating the document:", error)
            addToast("Error saving changes, please try again in sometime", {
              appearance: "error",
              autoDismiss: true,
            })
          })
          .catch(error => {
            console.log("Error updating the document:", error)
            addToast("Error saving changes, please try again in sometime", {
              appearance: "error",
              autoDismiss: true,
            })
          })
      }
    } catch (err) {
      console.log(err)
      addToast("Error saving changes, please try again in sometime", {
        appearance: "error",
        autoDismiss: true,
      })
    }
  }

  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        // { list: "ordered" },
        //{ list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      // ["link", "image"],
      // ["clean"],
    ],
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    //  "strike",
    "blockquote",
    // "list",
    // "bullet",
    // "indent",
    // "link",
    // "image",
  ]

  const handleIntroductionHtml = async html => {
    try {
      if (introductionRef) {
        const editor = introductionRef.getEditor()
        const quill = introductionRef.makeUnprivilegedEditor(editor)
        console.log(quill.getLength())
        if (quill.getLength() > maxIntroductionHtmlLen) {
          setReachedAboutIntroductionLen(true)
          editor.deleteText(maxIntroductionHtmlLen, quill.getLength())
        } else {
          dispatch({
            type: "updateAboutIntroduction",
            data: html,
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const handleSkillsHtml = async html => {
    try {
      if (skillsRef) {
        const editor = skillsRef.getEditor()
        const quill = skillsRef.makeUnprivilegedEditor(editor)

        if (quill.getLength() > maxSkillsHtmlLen) {
          setReachedSkillsLen(true)
          editor.deleteText(maxSkillsHtmlLen, quill.getLength())
        } else {
          dispatch({
            type: "updateAboutSkills",
            data: html,
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {loading ? (
        <ShowLoading />
      ) : (
        <div>
          {responsiveColumnWrap(
            <>
              <div className="columns is-multiline">
                <div className="column is-12">
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      textAlign: "",
                      marginTop: "25px",
                      color: "#fec600",
                    }}
                  >
                    Build your public profile, tell the travellers about
                    yourself!
                  </p>
                </div>
                <div className="column is-12">
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      textAlign: "",
                    }}
                  >
                    <i className="fas fa-user-shield"></i>{" "}
                    <strong>Note:</strong> All this information is visible to
                    travelers, please do not mention any personal information in
                    the sections below.
                  </p>
                </div>
                <div className="column is-12">
                  <div className="field">
                    <label className="label">
                      Pick all the countries you have traveled to:
                    </label>
                    <div className="control">
                      <Select
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                        }}
                        defaultValue={state.tempPublicProfile.countriesVisited}
                        menuPortalTarget={document.body}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isSearchable="true"
                        isClearable="true"
                        isMulti
                        onChange={(value, actionMeta) => {
                          if (value)
                            dispatch({
                              type: "updateCountriesTraveled",
                              data: Object.values(value),
                            })
                        }}
                        options={countryListValueLabel}
                      />
                      <p className="help">Type to narrow countries</p>
                    </div>
                  </div>
                </div>
                <div className="column is-12"></div>
                <div className="column is-12">
                  <div className="field">
                    <label className="label">
                      Pick all the countries you are comfortable building travel
                      itineraries for:
                    </label>
                    <div className="control">
                      <Select
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                        }}
                        defaultValue={
                          state.tempPublicProfile.countriesForItineraries
                        }
                        menuPortalTarget={document.body}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isSearchable="true"
                        isMulti
                        isClearable="true"
                        onChange={(value, actionMeta) => {
                          if (value && value.length > maxCountriesAllowed) {
                            // Only upto maxSkillsAllowed are allowed
                            console.log("reached max")
                            return
                          }
                          if (value)
                            dispatch({
                              type: "updateCountriesForItineraries",
                              data: Object.values(value),
                            })
                        }}
                        options={countryListValueLabel}
                      />
                      <p className="help">
                        <strong>Note:</strong> A maximum of 10 countries allowed
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column is-12">
                  <div className="field">
                    <label className="label">
                      Pick your top skills to identify your strengths!
                    </label>
                    <div className="control is-expanded">
                      <Select
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                        }}
                        options={designerSkills}
                        menuPortalTarget={document.body}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isSearchable="true"
                        isClearable="true"
                        isMulti
                        onBlur={e => {}}
                        value={state.tempPublicProfile.skills}
                        // onKeyDown={handleKeyDownSkills}
                        onChange={(value, actionMeta) => {
                          if (value && value.length > maxSkillsAllowed) {
                            // Only upto maxSkillsAllowed are allowed
                            return
                          }
                          // if (value)
                          dispatch({
                            type: "updateDesignerSkills",
                            data: value ? Object.values(value) : null,
                          })
                        }}
                        // onInputChange={handleInputChange}
                        // onKeyDown={handleKeyDown}
                        // placeholder="Pick your top seven skills"
                        defaultValue={state.tempPublicProfile.skills}
                        // value={designerSkills.value}
                      />
                    </div>
                    <p className="help">
                      <strong>Note:</strong> A maximum of 7 skills allowed
                    </p>
                  </div>
                </div>
                <div className="column is-12"></div>
                <div className="column is-12">
                  <div className="field">
                    <label className="label">
                      Pick all the languages you can speak:
                    </label>
                    <div className="control">
                      <Select
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                        }}
                        defaultValue={state.tempPublicProfile.languagesSpoken}
                        menuPortalTarget={document.body}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isSearchable="true"
                        isClearable="true"
                        isMulti
                        onChange={(value, actionMeta) => {
                          if (value)
                            dispatch({
                              type: "updateLanguagesSpoken",
                              data: Object.values(value),
                            })
                        }}
                        options={languages}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-12"></div>
                <div className="column is-12">
                  {" "}
                  <div className="field">
                    <label className="label">
                      Introduce yourself - Why do you love traveling? What sets
                      you apart from the other designers? Why do you want to
                      help design travel itineraries?{" "}
                      <small
                        style={{ fontWeight: "400" }}
                      >{`(maximum ${maxIntroductionHtmlLen -
                        1} characters)`}</small>
                    </label>
                    <div className="reco-quill" style={{ minHeight: "400px" }}>
                      <ReactQuill
                        theme="snow"
                        ref={e => (introductionRef = e)}
                        value={state.tempPublicProfile.aboutIntroduction || ""}
                        onChange={handleIntroductionHtml}
                        onBlur={() => {}}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                    <p
                      className={`help is-pulled-right ${
                        reachedAboutIntroductionLen ? "is-danger" : ""
                      }`}
                    >
                      Characters:{" "}
                      {introductionHtmlLen === 0 ? 0 : introductionHtmlLen - 1}/
                      {maxIntroductionHtmlLen - 1}
                    </p>
                    {/* <textarea
                      value={state.tempPublicProfile.aboutIntroduction}
                      className="textarea"
                      placeholder=""
                      rows="5"
                      onChange={e =>
                        dispatch({
                          type: "updateAboutIntroduction",
                          data: e.currentTarget.value,
                        })
                      }
                    ></textarea> */}
                  </div>
                </div>
                <div className="column is-12">
                  {/* <hr className="hr-branded"></hr> */}
                </div>
                {/* <div className="column is-12">
                  {" "}
                  <div className="field">
                    <label className="label">
                      Tell us about yourself. Why do you love traveling and what
                      sets you apart from the other designers? e.g. I am foodie
                      and find the best restaurants wherever I go.{" "}
                    </label>
                    <textarea
                      //  value={state.activityScratchPad.activityDescription}
                      className="textarea"
                      placeholder=""
                      rows="5"
                      // onChange={e =>
                      //   dispatch({
                      //     type: "updateActivityDescription",
                      //     data: e.currentTarget.value,
                      //   })
                      // }
                    ></textarea>
                  </div>
                </div> */}
                {/* <div className="column is-12">
                  {" "}
                  <hr className="hr-branded"></hr>
                </div> */}
                <div className="column is-12">
                  <div className="field">
                    <label className="label">
                      Any specific activities that you are good at planning?
                      What unique skills have you acquired over your years of
                      traveling, that will help you design great travel plans?
                      e.g., "Being a mom of two myself, I am great for planning
                      trips for families with kids."{" "}
                      <small
                        style={{ fontWeight: "400" }}
                      >{`(maximum ${maxSkillsHtmlLen - 1} characters)`}</small>
                    </label>
                    <div className="reco-quill" style={{ minHeight: "400px" }}>
                      <ReactQuill
                        //id="my-text"
                        ref={e => (skillsRef = e)}
                        theme="snow"
                        onChange={handleSkillsHtml}
                        rows="15"
                        value={state.tempPublicProfile.aboutSkills || ""}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                    <p
                      className={`help is-pulled-right ${
                        reachedSkillsLen ? "is-danger" : ""
                      }`}
                    >
                      Characters: {skillsHtmlLen === 0 ? 0 : skillsHtmlLen - 1}/
                      {maxSkillsHtmlLen - 1}
                    </p>
                    {/* <textarea
                      value={state.tempPublicProfile.aboutSkills}
                      className="textarea"
                      placeholder=""
                      rows="5"
                      onChange={e =>
                        dispatch({
                          type: "updateAboutSkills",
                          data: e.currentTarget.value,
                        })
                      }
                    ></textarea> */}
                  </div>
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column is-8-desktop"></div>
                <div className="column is-2-desktop ">
                  <button
                    style={{ width: "100%" }}
                    className="button is-pulled-right"
                    onClick={e => {
                      swal({
                        title: "Are you sure?",
                        text:
                          "You will lose all the information you have entered so far!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: false,
                        buttons: ["No, cancel it!", "Yes, I am sure!"],
                      }).then(willSubmit => {
                        if (willSubmit) {
                          //navigate("/app/designersignup")
                          window.history.back()
                        }
                      })
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="column is-2-desktop">
                  <button
                    style={{ width: "100%", marginBottom: "40px" }}
                    className="button is-warning is-pulled-right"
                    onClick={pushChangesToDB}
                  >
                    Save
                  </button>
                </div>
              </div>
              <hr className="hr-branded"></hr>
            </>
          )}
        </div>
      )}
    </>
  )
}

const AddAdditionalInfo = () => {
  return (
    <ToastProvider>
      <ProfileStateProvider>
        <AddAdditionalInfoBase />
      </ProfileStateProvider>
    </ToastProvider>
  )
}
export default AddAdditionalInfo
