import React from "react"
import { responsiveColumnWrap } from "../../utility/general"
import thankq from "../../images/thankq.png"
const ThankYou = ({ message }) => {
  return (
    <>
      {responsiveColumnWrap(
        <div className="columns is-multiline">
          <div className="column is-12"></div>
          <div className="column is-12"></div>
          <div className="column is-12"></div>
          <div className="column is-12"></div>
          <div className="column is-12"></div>
          <div className="column is-12"></div>
          <div className="column is-12"></div>
          <div className="column is-12"></div>
          <div className="column is-1"></div>
          <div className="column is-10">
            <img src={thankq} alt="" />
          </div>
          <div className="column is-1"></div>
          <div className="column is-2"></div>
          <div className="column is-8">
            <p
              style={{
                fontSize: "20px",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              {message}
            </p>
          </div>
          <div className="column is-2"></div>
        </div>
      )}
    </>
  )
}

export default ThankYou
