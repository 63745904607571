import React, { useState, useEffect, useRef, useContext } from "react"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import is, { androidPhone } from "is_js"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"
import { FirebaseContext } from "../../../components/firebase"
import { AuthUserContext } from "../../../components/session"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import "./Wishlist.scss"
import { NewActivityForm } from "../ItineraryTab/NewActivityForm"
import { BYOTActivityForm } from "../ItineraryTab/BYOTActivityForm"
import ActivityViewer from "../../Activities/ActivityViewer"
import { dottedText, googleMapsURI, titleCase } from "../../../utility/general"
import { MoveActivityConfirmationModal } from "../ItineraryTab/MoveActivityConfirmationModal"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import algoliasearch from "algoliasearch/lite"
import { createAutocomplete } from "@algolia/autocomplete-core"
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia"
import { confirmAlert } from "react-confirm-alert"
import { countBy } from "lodash"
import noImageDefault from "../../../images/no-image-background.png"
import "react-confirm-alert/src/react-confirm-alert.css"
// import { analytics, functions } from "../../../components/firebase/firebase"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slider,
  Slide,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { functions } from "../../../components/firebase/firebase"
import AsyncFetchImage from "../../../components/AsyncFetchImage/AsyncFetchImage"
import ShowImage from "../../../components/ShowImage/ShowImage"
import TopAttractions from "../../../components/TopAttractions/TopAttractions"
const searchClient = algoliasearch(
  `6ISV547TER`,
  `7f1f9b4df5ef999caa0d7c6ba0ba8251`
)
// const searchClient = algoliasearch(
//   `${process.env.ALGOLIA_SEARCH_APP_ID}`,
//   `${process.env.ALGOLIA_SEARCH_API_KEY}`
// )
const Wishlist = ({ canEdit = false }) => {
  const [
    autocompleteStateAttraction,
    setAutocompleteStateAttraction,
  ] = useState("")
  const [showAddNewActivityEntry, setShowAddNewActivityEntry] = useState(false)
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [isBusy, setIsBusy] = useState(false)
  const [state, dispatch] = useDashboardStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [inEditMode, setInEditMode] = useState(9999)
  const [activityInEditMode, setActivityInEditMode] = useState(false)
  const [toggleMenu, setToggleMenu] = useState(9999)
  const [toggleExpandDetails, setToggleExpandDetails] = useState(9999)
  const [recommendationHits, setRecommendationHits] = useState()
  const [showAddOwnItem, setShowAddOwnItem] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [toggleSelectedItemsMenu, setToggleSelectedItemsMenu] = useState(false)
  const [
    toggleSelectedItemsMenuDropdown,
    setToggleSelectedItemsMenuDropdown,
  ] = useState(false)
  useEffect(() => {
    console.log({ toggleSelectedItemsMenuDropdown })
  }, [toggleSelectedItemsMenuDropdown])
  useEffect(() => {
    console.log({ showAddOwnItem })
  }, [showAddOwnItem])

  useEffect(() => {
    console.log({ autocompleteStateAttraction })
    try {
      if (
        autocompleteStateAttraction.collections &&
        autocompleteStateAttraction.collections[0].items.length === 0 &&
        autocompleteStateAttraction.query !== "" &&
        autocompleteStateAttraction.status === "idle"
      ) {
        setShowAddOwnItem(true)
      } else {
        if (showAddOwnItem === true) setShowAddOwnItem(false)
      }
    } catch (error) {}
  }, [autocompleteStateAttraction])

  useEffect(() => {
    if (!autocompleteStateAttraction.isOpen) {
      hideItem("attraction-panel")
    }
  }, [showAddNewActivityEntry])
  useEffect(() => {
    console.log({ toggleSelectedItemsMenu })
  }, [toggleSelectedItemsMenu])

  useEffect(() => {
    if (state && state.currentJobInfo) {
      if (state.currentJobInfo.itineraryStatus !== "in-progress") {
        beginWork()
      }
    }
  }, [state])

  // const getPictureFromId = pId => {
  //   console.log("getPictureFromId called")
  //   // setShowPlaceLoading(true)
  //   let data = {
  //     id: pId,
  //   }
  //   if (pId) {
  //     var getPictureFromId = functions.httpsCallable("getPictureFromId")
  //     getPictureFromId(data)
  //       .then(result => {
  //         // Read result of the Cloud Function.
  //         // setShowPlaceLoading(false)
  //         console.log({ result })

  //         if (result && result.data) {
  //           let attributionUrl = `${result.data.response.user.links.html}?utm_source=${app_name}&utm_medium=referral`

  //           console.log(result.data.response.urls.regular)
  //           console.log(attributionUrl)
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err)
  //         // setShowPlaceLoading(false)
  //       })
  //   }
  // }

  // Work has started on the itineraries. Update the flag in db and also initialize the itinerary workspace.
  const beginWork = e => {
    //Update the required state and db values
    try {
      var startDate = new Date(state.currentJobInfo.travelDates.start)
      var endDate = new Date(state.currentJobInfo.travelDates.end)
      console.log({ startDate })
      console.log({ endDate })
      //get all the date between the trip start and end dates.
      var a = []
      for (
        var d = new Date(startDate);
        d <= endDate;
        d.setDate(d.getDate() + 1)
      ) {
        a.push({
          date: new Date(d).toUTCString(),
          activities: [],
          dayScheduleStartTime: new Date(
            new Date(d).setUTCHours(8)
          ).toUTCString(), // This set the default time when all the scheduling begins for the day.
          daysStats: {
            totalActivitiesTime: 0,
            totalCommuteTime: 0,
            totalDiningTime: 0,
            totalSightseeingTime: 0,
          },
        })
      }

      if (dbContext) {
        dbContext
          .firestore()
          .collection("jobs")
          .doc(`${authContext.uid}`)
          .collection("active-jobs")
          .doc(`${state.currentJobInfo.jobUid}`)
          .update({
            itineraryStatus: "in-progress",
            designerWorkspace: { itinerary: a },
          })
          .then(() => {
            console.log("document update successful")
            //update local state now
            dispatch({ type: "itineraryInProgress" })
            dispatch({
              type: "initializeDesignerWorkspaceForItineraries",
              data: a,
            })

            //
          })
          .catch(e => console.log(e))
      }
    } catch (error) {
      console.log("beginWork: ", error)
    }
  }

  useEffect(() => {
    // console.log(autocompleteStateAttraction.activeItemId)
    try {
      if (!autocompleteStateAttraction.isOpen) {
        hideItem("attraction-panel")
      }
    } catch (error) {}
  }, [autocompleteStateAttraction])
  useEffect(() => {
    try {
      if (state.currentJobInfo.wishlistSyncRequired) {
        // clearSelection() //Make sure the wishlist selections are cleared before pushing in state to db
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${tripState.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({
              tripWishlist: state.currentJobInfo.tripWishlist,
            })
            .then(() => {
              console.log("document update successful")
              // //update local state now
              // setIsBusy(false)
              // dispatch({
              //   type: "addNewFlightDetail",
              //   data: currentFlightInfo,
              // })
              // setCurrentFlightInfo({
              //   flightNumber: "",
              //   departDate: "",
              //   departDateFocus: "",
              //   departTime: "",
              //   departAirport: "",
              //   departCity: "",
              //   arriveDate: "",
              //   arriveDateFocus: "",
              //   arriveTime: "",
              //   arriveAirport: "",
              //   arriveCity: "",
              //   confirmationNumber: "",
              //   additionalNotes: "",
              // })
              // setShowAddNewFlightEntry(false)
            })
            .catch(e => {
              setIsBusy(false)
              console.log(e)
            })
        }
        dispatch({ type: "wishlistSyncRequired", data: false })
      }
    } catch (error) {
      console.log(error)
    }
  }, [state.currentJobInfo.wishlistSyncRequired])
  useEffect(() => {
    try {
      if (state.currentJobInfo.wishlistItinerarySyncRequired) {
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${tripState.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({
              designerWorkspace: {
                itinerary: state.currentJobInfo.designerWorkspace.itinerary,
                updateCommute:
                  state.currentJobInfo.designerWorkspace.updateCommute,
              },
              tripWishlist: state.currentJobInfo.tripWishlist,
            })
            .then(() => {
              console.log("document update successful")
              //update local state now
              //

              // dispatch({
              //   type: "deleteWishListItem",
              //   index: index,
              // })
              // dispatch({
              //   type: "wishlistSyncRequired",
              //   data: true,
              // })
            })
            .catch(e => console.log(e))
        }
        dispatch({ type: "wishlistItinerarySyncRequired", data: false })
      }
    } catch (error) {
      console.log(error)
    }
  }, [state.currentJobInfo.wishlistItinerarySyncRequired])
  useEffect(() => {
    onCancel()
  }, [])
  const onCancel = () => {
    setShowAddNewActivityEntry(false)
    setActivityInEditMode(false)
    setShowAddOwnItem(false)
    dispatch({
      type: "clearActivityScratchPad",
    })
  }

  const clearResultsPanel = () => {
    setTimeout(() => {
      if (!autoCompleteAttractions.isOpen) {
        hideItem("attraction-panel")
      }
    }, 100)
  }

  const autoCompleteAttractions = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state
          setAutocompleteStateAttraction(state)
        },
        id: "autoCompleteAttractions",
        navigator: {
          navigate({ itemUrl }) {
            console.log({ itemUrl })
          },
          navigateNewTab({ itemUrl }) {
            console.log({ itemUrl })
          },
        },
        // shouldPanelOpen() {
        //   console.log("shouldPanelOpen called")
        // },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: "attractions",
              getItemInputValue({ item }) {
                return item.query
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: "attractions",
                      query,
                      params: {
                        hitsPerPage: 5,
                        highlightPreTag: "<mark>",
                        highlightPostTag: "</mark>",
                      },
                    },
                  ],
                })
              },
              templates: {
                // ...
                noResults() {
                  console.log("no-results")
                  return <p>No Results</p>
                },
              },
              getItemUrl({ item }) {
                return item.url
              },
              onSelect({ item }) {
                // console.log({ item })
                // setSelectedArrivalAirport(item)
              },
            },
          ]
        },
      }),
    []
  )

  const formatDuration = item => {
    try {
      var hours = item.activityDurationHours
      var minutes = item.activityDurationMinutes

      var hrs = hours === "1" ? "Hr" : "Hrs"
      if (hours === undefined && minutes === undefined) return ""
      if (
        hours === undefined ||
        hours === "" ||
        hours === " " ||
        hours === "0"
      ) {
        return ` ${minutes} Min`
      } else {
        if (
          minutes === undefined ||
          minutes === "" ||
          minutes === " " ||
          minutes === "0"
        )
          return ` ${hours} ${hrs}`
        else {
          return ` ${hours} ${hrs} & ${minutes} Min`
        }
      }
    } catch (error) {
      console.log(error)
      return ""
    }
  }
  const getFormattedAddressForIframe = (name, address) => {
    try {
      const gpsRegX = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
      var result = gpsRegX.exec(address)
      console.log({ result })
      // let a =
      //   result !== null
      //     ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
      //         address
      //       )}`
      //     : `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
      //         name
      //       )}+${encodeURI(address)}`
      let a =
        result !== null
          ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
              address
            )}`
          : `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
              address
            )}`

      console.log({ a })
      return a
    } catch (err) {
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
        name
      )}+${encodeURI(address)}`
    }
  }
  const showSelectedItemsCount = () => {
    try {
      var a = countBy(state.currentJobInfo.tripWishlist, val => {
        return val.selected === true
      })
      if (a.true > 0 && !toggleSelectedItemsMenu) {
        setToggleSelectedItemsMenu(true)
      } else {
        console.log({ toggleSelectedItemsMenu })
        if (toggleSelectedItemsMenu && !a.true)
          setToggleSelectedItemsMenu(false)
      }
      console.log({ a })
      return a.true > 0 ? (
        <p style={{ fontSize: "12px" }}>{a.true} activities selected</p>
      ) : (
        ""
      )
    } catch (error) {
      console.log(error)
    }
  }
  const clearSelection = () => {
    try {
      if (state.currentJobInfo.tripWishlist) {
        dispatch({
          type: "ResetWishlistSelections",
        })
      }
    } catch (error) {}
  }
  const handleSelectedItemsMenuDropdownBlur = () => {
    console.log("blur")
    if (toggleSelectedItemsMenuDropdown)
      setToggleSelectedItemsMenuDropdown(false)
  }

  const showActivities = params => {
    try {
      if (
        state.currentJobInfo.tripWishlist &&
        state.currentJobInfo.tripWishlist.length > 0
      ) {
        return (
          <>
            <p
              style={{
                fontWeight: "600",
                fontSize: "14px",
                textTransform: "uppercase",
                color: "rgba(74,74,74,0.8)",
                marginBottom: "20px",
              }}
            >
              Your wishlist
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                alignItems: "center",
              }}
              onBlur={handleSelectedItemsMenuDropdownBlur}
            >
              {showSelectedItemsCount()}
              {toggleSelectedItemsMenu && (
                <div
                  className={`${
                    toggleSelectedItemsMenuDropdown
                      ? "dropdown is-right is-active"
                      : "dropdown is-right"
                  }`}
                  tabindex="0"
                  style={{
                    outline: "white !important",
                  }}
                  onBlur={handleSelectedItemsMenuDropdownBlur}
                >
                  <div className="dropdown-trigger">
                    <a
                      className="is-text"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                      onClick={e => {
                        setToggleSelectedItemsMenuDropdown(
                          !toggleSelectedItemsMenuDropdown
                        )
                        // e.stopPropagation()
                      }}
                      onBlur={handleSelectedItemsMenuDropdownBlur}
                      style={{
                        background: "transparent",
                        backgroundColor: "transparent",
                        outline: "white !important",

                        // zIndex: "99",
                      }}
                    >
                      {/* <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          letterSpacing: "0.5px",
                          textTransform: "uppercase",
                          textDecoration: "underline",
                          color: "#4a4a4a",
                          textDecorationColor: "#fec600",
                        }}
                      >
                        {toggleSelectedItemsMenu ? "Close" : "Menu"}
                      </span> */}
                      {toggleSelectedItemsMenuDropdown ? (
                        <span
                          className="icon"
                          style={{
                            color: "#fec600",
                            outline: "none !important",
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </span>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            outline: "none !important",
                          }}
                        >
                          <span
                            className=""
                            style={{
                              color: "#4a4a4a",
                              outline: "none !important",
                            }}
                          >
                            {/* <i className="fas fa-ellipsis-h-alt"></i> */}

                            <p
                              style={{
                                // minWidth: "130px",
                                fontSize: "14px",
                                textDecoration: "underline",
                                cursor: "pointer",
                                textDecorationColor: "#fec600",
                                outline: "none !important",
                              }}
                            >
                              Selection Menu
                            </p>
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "14px",
                              color: "#fec600",
                            }}
                            class="far fa-chevron-down"
                          ></i>
                        </div>
                      )}
                    </a>
                  </div>
                  <div
                    class="dropdown-menu"
                    id="dropdown-menu"
                    role="menu"
                    style={{
                      background: "#fafafa",
                      backgroundColor: "#fafafa",
                      outline: "none !important",
                      border: "1px solid #eaeaea",
                      borderRadius: "4px",
                      fontSize: "14px",
                    }}
                    onBlur={() => {
                      console.log("blur")
                      setToggleSelectedItemsMenuDropdown(false)
                    }}
                  >
                    <div
                      class="dropdown-content"
                      id="dropdown-menu-item"
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <a
                        className="is-small dropdown-item"
                        onClick={e => {
                          setToggleMenu(false)
                          //   // onMoveActivity(e.currentTarget.id)
                          //   onMoveActivity(value.dayId, value.id, itemNo)
                          MoveActivityConfirmationModal({
                            onConfirm: dayIndex => {
                              dispatch({
                                type: "FromWishListToItinerarySelected",
                                activeTab: dayIndex,
                              })
                              clearSelection()
                              setToggleSelectedItemsMenuDropdown(false)
                              // dispatch({
                              //   type: "deleteWishListItem",
                              //   index: index,
                              // })

                              // dispatch({
                              //   type: "syncSchedule",
                              //   data: true,
                              // })
                              // dispatch({
                              //   type: "wishlistSyncRequired",
                              //   data: true,
                              // })
                            },
                            title: "Confirmation",
                            startDate:
                              typeof state.currentJobInfo.travelDates.start ===
                              "string"
                                ? new Date(
                                    state.currentJobInfo.travelDates.start
                                  )
                                : state.currentJobInfo.travelDates.start.toDate(),
                            endDate:
                              typeof state.currentJobInfo.travelDates.end ===
                              "string"
                                ? new Date(state.currentJobInfo.travelDates.end)
                                : state.currentJobInfo.travelDates.end.toDate(),
                            text:
                              "Pick a date to move the selected activities to?",
                            selectedIndex: 9999,
                          })
                        }}
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <i className="far fa-copy"> </i>
                        <p style={{ marginLeft: "7px" }}>
                          {" "}
                          Copy activities to Itinerary
                        </p>
                      </a>
                    </div>
                    <div
                      class="dropdown-content"
                      id="dropdown-menu-item"
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <a
                        className="is-small dropdown-item"
                        onClick={e => {
                          setToggleMenu(9999)

                          ConfirmationModal({
                            onConfirm: index => {
                              dispatch({
                                type: "deleteSelectedWishListItem",
                              })
                              setToggleSelectedItemsMenuDropdown(false)
                            },
                            title: "Confirmation",
                            text: `Are you sure you want to permanently delete the activity?`,
                          })
                        }}
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <i className="far fa-trash-alt delete-icon-color"> </i>

                        <p style={{ marginLeft: "7px" }}>Delete Activities</p>
                      </a>
                    </div>
                    <div
                      class="dropdown-content"
                      id="dropdown-menu-item"
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <a
                        className="is-small dropdown-item"
                        onClick={e => {
                          dispatch({
                            type: "ResetWishlistSelectionsWithDBSync",
                          })
                          setToggleSelectedItemsMenuDropdown(false)
                        }}
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <i className="far fa-minus"> </i>

                        <p style={{ marginLeft: "7px" }}>Clear Selection</p>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                alignItems: "flex-start",
              }}
            >
              {state.currentJobInfo.tripWishlist.map((item, index) => {
                return (
                  <>
                    <div
                      className="wl-card"
                      style={{
                        position: "relative",
                        border: item.selected
                          ? "3px solid #fec600"
                          : "2px solid #fec600",
                        backgroundImage: `url(${
                          item.activityImageData &&
                          toggleExpandDetails !== index
                            ? item.activityImageData.urls.small
                            : noImageDefault
                        })`,
                        backgroundPositionY: "center",
                        backgroundSize: "cover",

                        // marginRight: "40px",
                        // marginBottom: "40px",
                        cursor: "pointer",
                        // borderRadius: "4px",
                      }}
                      id={index}
                      onClick={e => {
                        setToggleMenu(false)
                        e.stopPropagation()
                        dispatch({
                          type: "ToggleWishlistItem",
                          selectedItem: e.currentTarget.id,
                        })
                      }}
                    >
                      {/* {item.selected && (
                        <div
                          style={{
                            marginRight: "5px",
                            color: "#4a4a4a",
                            position: "absolute",
                            left: "-25px",
                          }}
                        >
                          <i class="fas fa-check"></i>
                        </div>
                      )} */}
                      <div
                        style={{
                          background:
                            toggleExpandDetails !== index
                              ? item.selected
                                ? "rgba(254,198,0,0.3)"
                                : "rgba(5,5,5,0.5)"
                              : "#fafafa",
                          padding: "10px",
                          borderRadius: "4px",
                          // minHeight: "92px",
                          height: "100%",
                        }}
                      >
                        <div
                          className="activity-card-close"
                          tabindex="0"
                          onBlur={() => {
                            setToggleMenu(9999)
                          }}
                          style={{
                            background: "transparent",
                            backgroundColor: "transparent",
                            outline: "white !important",
                            float: "right",
                          }}
                        >
                          {canEdit ? (
                            <div
                              class={`${
                                toggleMenu === index
                                  ? "dropdown is-right is-active"
                                  : "dropdown is-right"
                              }`}
                              style={{
                                backgroundColor: "transparent",
                                outline: "white !important",
                              }}
                            >
                              <div className="dropdown-trigger">
                                <a
                                  className="is-text"
                                  aria-haspopup="true"
                                  aria-controls="dropdown-menu"
                                  onClick={e => {
                                    toggleMenu === 9999
                                      ? setToggleMenu(index)
                                      : setToggleMenu(9999)
                                    e.stopPropagation()
                                  }}
                                  onBlur={() => {
                                    setToggleMenu(9999)
                                  }}
                                  style={{
                                    background: "transparent",
                                    backgroundColor: "transparent",
                                    outline: "white !important",
                                    color: "transparent",
                                  }}
                                >
                                  {/* <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          letterSpacing: "0.5px",
                          textTransform: "uppercase",
                          textDecoration: "underline",
                          color: "#4a4a4a",
                          textDecorationColor: "#fec600",
                        }}
                      >
                        {toggleMenu ? "Close" : "Menu"}
                      </span> */}
                                  {toggleMenu === index ? (
                                    <span
                                      className="icon is-small"
                                      style={{
                                        color:
                                          toggleExpandDetails !== index
                                            ? "white"
                                            : "#fec600",
                                      }}
                                    >
                                      <i className="fas fa-times"></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="icon is-small"
                                      style={{ color: "#fec600" }}
                                    >
                                      <i className="fas fa-ellipsis-h-alt"></i>
                                    </span>
                                  )}
                                </a>
                              </div>
                              <div
                                className="dropdown-menu"
                                id="dropdown-menu"
                                role="menu"
                                onBlur={() => {
                                  setToggleMenu(9999)
                                }}
                                style={{
                                  background: "#fafafa",
                                  backgroundColor: "#fafafa",
                                  outline: "none !important",
                                  border: "1px solid #eaeaea",
                                  borderRadius: "4px",
                                  fontSize: "14px",
                                }}
                              >
                                <div
                                  className="dropdown-content"
                                  id="dropdown-menu-item"
                                  style={{
                                    backgroundColor: "#fafafa",
                                    display: "none",
                                  }}
                                >
                                  <a
                                    className="is-small dropdown-item"
                                    id={index}
                                    onClick={e => {
                                      setToggleMenu(9999)
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <i className="far fa-eye delete-icon-color">
                                      {" "}
                                    </i>
                                    <p style={{ marginLeft: "7px" }}>
                                      {" "}
                                      View Details
                                    </p>
                                  </a>
                                </div>
                                <div
                                  className="dropdown-content"
                                  id="dropdown-menu-item"
                                  style={{ backgroundColor: "#fafafa" }}
                                >
                                  <a
                                    className="is-small dropdown-item"
                                    id={index}
                                    onClick={e => {
                                      clearSelection()
                                      setToggleMenu(9999)
                                      dispatch({
                                        type: "prepareActivityScratchPad",
                                        data: item,
                                      })
                                      setActivityInEditMode(true)
                                      e.stopPropagation()
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <i className="far fa-edit delete-icon-color">
                                      {" "}
                                    </i>
                                    <p style={{ marginLeft: "7px" }}>
                                      {" "}
                                      Edit Item
                                    </p>
                                  </a>
                                </div>
                                <div
                                  className="dropdown-content"
                                  id="dropdown-menu-item"
                                  style={{
                                    backgroundColor: "#fafafa",
                                    display: "none",
                                  }}
                                >
                                  <a
                                    className="is-small dropdown-item"
                                    id={index}
                                    onClick={e => {
                                      clearSelection()
                                      setToggleMenu(false)
                                      //   // onMoveActivity(e.currentTarget.id)
                                      //   onMoveActivity(value.dayId, value.id, itemNo)
                                      MoveActivityConfirmationModal({
                                        onConfirm: dayIndex => {
                                          console.log(dayIndex)
                                          console.log(index)
                                          dispatch({
                                            type: "FromWishListToItinerary",
                                            activeTab: dayIndex,
                                            selectedItem: {
                                              ...item,
                                              activityUid: uuidv4(),
                                            },
                                            wishlistItemIndex: index,
                                            actionType: "move",
                                          })
                                        },
                                        title: "Confirmation",
                                        startDate:
                                          typeof state.currentJobInfo
                                            .travelDates.start === "string"
                                            ? new Date(
                                                state.currentJobInfo.travelDates.start
                                              )
                                            : state.currentJobInfo.travelDates.start.toDate(),
                                        endDate:
                                          typeof state.currentJobInfo
                                            .travelDates.end === "string"
                                            ? new Date(
                                                state.currentJobInfo.travelDates.end
                                              )
                                            : state.currentJobInfo.travelDates.end.toDate(),
                                        text:
                                          "Pick a date to move the activity to?",
                                        selectedIndex: 9999,
                                      })
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <i className="far fa-arrow-right"> </i>
                                    <p style={{ marginLeft: "7px" }}>
                                      {" "}
                                      Move to Itinerary
                                    </p>
                                  </a>
                                </div>
                                <div
                                  className="dropdown-content"
                                  id="dropdown-menu-item"
                                  style={{ backgroundColor: "#fafafa" }}
                                >
                                  {item.selected ? (
                                    <a
                                      className="is-small dropdown-item"
                                      id={index}
                                      onClick={e => {
                                        setToggleMenu(false)
                                        e.stopPropagation()
                                        dispatch({
                                          type: "ToggleWishlistItem",
                                          selectedItem: e.currentTarget.id,
                                        })

                                        //   onMoveActivity(value.dayId, value.id, itemNo)
                                      }}
                                      style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                      }}
                                    >
                                      <i class="far fa-minus"></i>
                                      <p style={{ marginLeft: "7px" }}>
                                        {" "}
                                        Unselect Item
                                      </p>
                                    </a>
                                  ) : (
                                    <a
                                      className="is-small dropdown-item"
                                      id={index}
                                      onClick={e => {
                                        setToggleMenu(false)
                                        e.stopPropagation()
                                        dispatch({
                                          type: "ToggleWishlistItem",
                                          selectedItem: e.currentTarget.id,
                                        })

                                        //   onMoveActivity(value.dayId, value.id, itemNo)
                                      }}
                                      style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                      }}
                                    >
                                      <i class="far fa-check"></i>
                                      <p style={{ marginLeft: "7px" }}>
                                        {" "}
                                        Select Item
                                      </p>
                                    </a>
                                  )}
                                </div>
                                <div
                                  className="dropdown-content"
                                  id="dropdown-menu-item"
                                  style={{ backgroundColor: "#fafafa" }}
                                >
                                  <a
                                    className="is-small dropdown-item"
                                    id={index}
                                    onClick={e => {
                                      clearSelection()
                                      setToggleMenu(false)
                                      //   // onMoveActivity(e.currentTarget.id)
                                      //   onMoveActivity(value.dayId, value.id, itemNo)
                                      MoveActivityConfirmationModal({
                                        onConfirm: dayIndex => {
                                          console.log(dayIndex)
                                          console.log(index)

                                          dispatch({
                                            type: "FromWishListToItinerary",
                                            activeTab: dayIndex,
                                            selectedItem: item,
                                          })
                                          // dispatch({
                                          //   type: "deleteWishListItem",
                                          //   index: index,
                                          // })

                                          // dispatch({
                                          //   type: "syncSchedule",
                                          //   data: true,
                                          // })
                                          // dispatch({
                                          //   type: "wishlistSyncRequired",
                                          //   data: true,
                                          // })
                                        },
                                        title: "Confirmation",
                                        startDate:
                                          typeof state.currentJobInfo
                                            .travelDates.start === "string"
                                            ? new Date(
                                                state.currentJobInfo.travelDates.start
                                              )
                                            : state.currentJobInfo.travelDates.start.toDate(),
                                        endDate:
                                          typeof state.currentJobInfo
                                            .travelDates.end === "string"
                                            ? new Date(
                                                state.currentJobInfo.travelDates.end
                                              )
                                            : state.currentJobInfo.travelDates.end.toDate(),
                                        text:
                                          "Pick a date to move the activity to?",
                                        selectedIndex: 9999,
                                        hideWishlist: true,
                                      })
                                      e.stopPropagation()
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <i className="far fa-copy"> </i>
                                    <p style={{ marginLeft: "7px" }}>
                                      {" "}
                                      Copy to Itinerary
                                    </p>
                                  </a>
                                </div>
                                <div
                                  className="dropdown-content"
                                  id="dropdown-menu-item"
                                  style={{ backgroundColor: "#fafafa" }}
                                >
                                  <a
                                    className="is-small dropdown-item"
                                    id={index}
                                    onClick={e => {
                                      clearSelection()
                                      setToggleMenu(9999)

                                      ConfirmationModal({
                                        onConfirm: index => {
                                          dispatch({
                                            type: "deleteWishListItem",
                                            index: index,
                                          })
                                        },
                                        sendBackValue: index,
                                        title: "Confirmation",
                                        text: `Are you sure you want to permanently delete the activity?`,
                                      })
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <i className="far fa-trash-alt delete-icon-color">
                                      {" "}
                                    </i>

                                    <p style={{ marginLeft: "7px" }}>
                                      {" "}
                                      Delete Activity
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          ) : (
                            // <a
                            //   className="is-small"
                            //   id={`Day:${value.dayId}-Item:${value.id}`}
                            //   onClick={onRemove}
                            // >
                            //   <i className="far fa-trash-alt delete-icon-color"></i>
                            // </a>
                            ""
                          )}
                        </div>

                        <div className="accommodation-name">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              style={{
                                marginRight: "10px",
                                fontSize: "16px",
                                color:
                                  toggleExpandDetails !== index
                                    ? "white"
                                    : "#4a4a4a",
                                // textTransform: "uppercase",
                                letterSpacing: "0.5px",
                                lineHeight: "1",
                              }}
                            >
                              {" "}
                              {dottedText(titleCase(item.activityName), 35)}
                            </p>
                          </div>
                        </div>
                        {/* <div className="wl-type">
                  <p>{item.activityType}</p>
                </div> */}
                        <div
                          className="accommodation-address"
                          style={{
                            fontSize: "12px",
                            marginTop: "5px",
                            display: "flex",
                          }}
                        >
                          <span
                            className="tag is-warning is-small wl-type"
                            style={{ marginRight: "7px" }}
                          >
                            {" "}
                            {titleCase(item.activityType)}
                          </span>
                          <span
                            style={{ fontSize: "10px", display: "none" }}
                            className="tag is-small wl-type"
                          >
                            <i
                              style={{ color: "#fec600", marginRight: "5px" }}
                              className="far fa-clock"
                            ></i>
                            <span>
                              {formatDuration(item)}
                              {/* {item.activityDurationHours}:
                              {item.activityDurationMinutes} */}
                            </span>
                          </span>
                          {item.selected && (
                            <div
                              style={{
                                marginRight: "5px",
                                color: "#fec600",
                                position: "absolute",
                                left: "0",
                                right: "0",
                                top: "0",
                                bottom: "0",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                <i
                                  style={{
                                    padding: "10px",
                                    background: "white",
                                    borderRadius: "50%",
                                    color: "#4a4a4a",
                                  }}
                                  class="fas fa-check"
                                ></i>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="wl-desc" style={{ display: "none" }}>
                          {/* <span
                          style={{
                            fontWeight: "300",
                            textTransform: "uppercase",
                            letterSpacing: "0.5px",
                            fontSize: "10px",
                          }}
                        >
                          {" "}
                        </span> */}
                          <div
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              marginTop: "0px",
                              lineHeight: "1.4",
                            }}
                          >
                            {item.activityDescription
                              ? dottedText(item.activityDescription, 120)
                              : "--"}
                          </div>
                        </div>
                        <div
                          className="wl-desc"
                          id={index}
                          style={{
                            fontSize: "14px",
                            margin: "0px 0px 0px auto",
                            maxWidth: "90px",
                            cursor: "pointer",
                            textAlign: "right",
                            marginTop: "10px",
                            zIndex: "99",
                          }}
                          onClick={e => {
                            if (toggleExpandDetails === index)
                              setToggleExpandDetails(9999)
                            else setToggleExpandDetails(index)
                            e.stopPropagation()
                          }}
                        >
                          {toggleExpandDetails === index ? (
                            <>
                              <span style={{ marginRight: "5px" }}>Close</span>
                              <i
                                style={{ color: "#fec600" }}
                                className="far fa-chevron-right"
                              ></i>
                            </>
                          ) : (
                            <>
                              <span
                                style={{ marginRight: "5px", color: "white" }}
                              >
                                View More
                              </span>
                              <i
                                style={{ color: "#fec600" }}
                                className="far fa-chevron-down"
                              ></i>
                            </>
                          )}
                        </div>
                        {toggleExpandDetails === index ? (
                          <>
                            <div className="wl-more-details">
                              <div
                                className="accommodation-address"
                                style={{ fontSize: "12px", marginTop: "13px" }}
                              >
                                <i
                                  style={{
                                    color: "#fec600",
                                    marginRight: "5px",
                                  }}
                                  className="far fa-map-marker-alt"
                                ></i>
                                <a
                                  href={googleMapsURI(
                                    item.name + item.activityAddress
                                  )}
                                  target="_blank"
                                >
                                  {dottedText(item.activityAddress, 55)}
                                </a>
                              </div>
                              {item.activityPhone !== "" && item.activityPhone && (
                                <div
                                  className="accommodation-address"
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "12px",
                                    // letterSpacing: "0.5px",
                                  }}
                                >
                                  {" "}
                                  <i
                                    className="fas fa-phone-alt"
                                    style={{
                                      color: "#fec600",
                                      marginRight: "5px",
                                    }}
                                  ></i>
                                  <a href={`tel:${item.activityPhone}`}>
                                    {item.activityPhone}
                                  </a>
                                </div>
                              )}
                              <div className="wl-desc">
                                {item.activityImageData ? (
                                  <ShowImage
                                    imageObj={item.activityImageData}
                                    isFullWidth={true}
                                    isRounded={false}
                                  />
                                ) : (
                                  // showImage(item.activityImageData, true, false)
                                  <figure class="image is-4x3">
                                    <AsyncFetchImage
                                      imageId={item.activityImageId}
                                      rounded={false}
                                      fullWidth={true}
                                    />
                                  </figure>
                                )}
                                {/* <iframe
                                title="Paris"
                                // src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
                                //   value.activity.activityName
                                // )}+${encodeURI(value.activity.activityAddress)}`}

                                src={getFormattedAddressForIframe(
                                  item.activityName,
                                  item.activityAddress
                                )}
                                width="100%"
                                height="200px"
                                allowFullScreen
                              ></iframe> */}
                              </div>
                              {item.activityDescription && (
                                <>
                                  {" "}
                                  <div className="wl-desc">
                                    <span
                                      style={{
                                        fontWeight: "700",
                                        textTransform: "uppercase",
                                        letterSpacing: "0.5px",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Description
                                    </span>
                                    <div
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        marginTop: "0px",
                                        lineHeight: "1.4",
                                      }}
                                    >
                                      {item.activityDescription
                                        ? item.activityDescription
                                        : "--"}
                                      <a
                                        href={
                                          item.activityDescriptionURL
                                            ? `https://${item.activityDescriptionURL}`
                                            : "https://en.wikipedia.org/wiki/Main_Page"
                                        }
                                        target="_blank"
                                        style={{
                                          marginLeft: "5px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Source Wikipedia
                                      </a>
                                    </div>
                                  </div>
                                </>
                              )}
                              {item.personalPoints && (
                                <div className="wl-desc">
                                  <span
                                    style={{
                                      fontWeight: "700",
                                      textTransform: "uppercase",
                                      letterSpacing: "0.5px",
                                      fontSize: "10px",
                                    }}
                                  >
                                    Your Notes
                                  </span>
                                  <div
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      marginTop: "0px",
                                      lineHeight: "1.4",
                                      // letterSpacing: "0.5px",
                                    }}
                                  >
                                    {item.personalPoints === ""
                                      ? "--"
                                      : item.personalPoints}
                                  </div>
                                </div>
                              )}

                              {item.activityURL1 && (
                                <div className="wl-desc">
                                  <span
                                    style={{
                                      fontWeight: "700",
                                      textTransform: "uppercase",
                                      letterSpacing: "0.5px",
                                      fontSize: "10px",
                                    }}
                                  >
                                    Useful URLs
                                  </span>
                                  <div style={{ marginBottom: "5px" }}>
                                    <i
                                      className="fas fa-external-link-alt"
                                      style={{ marginRight: "5px" }}
                                    ></i>
                                    <a href={item.activityURL1} target="_blank">
                                      {dottedText(item.activityURL1, 38)}
                                    </a>
                                  </div>
                                </div>
                              )}
                              {/* <div>
                                <i
                                  className="fas fa-external-link-alt"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                <a href={item.activityURL2} target="_blank">
                                  {dottedText(item.activityURL2, 28)}
                                </a>
                              </div> */}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </>
        )
      } else {
        return canEdit ? (
          <div
            style={{
              margin: "30px auto",
              maxWidth: "400px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                // fontStyle: "italic",
                fontSize: "14px",
                fontWeight: "300",
                textAlign: "center",
              }}
            >
              - No items added yet -
            </p>
            {/* <a
              style={{
                textAlign: "center",
                fontWeight: "600",
                textDecorationColor: "#fec600",
                color: "rgba(74, 74, 74, 0.8)",
                textDecoration: "none",
              }}
              onClick={() => {
                dispatch({
                  type: "clearActivityScratchPad",
                })
                setShowAddNewActivityEntry(true)
                clearResultsPanel()
              }}
              // className="button is-warning"
            >
              <i className="far fa-plus" style={{ marginRight: "5px" }}></i> Add
              an item
            </a> */}
          </div>
        ) : (
          <p
            style={{
              // fontStyle: "italic",
              fontSize: "14px",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            - No items added yet -
          </p>
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  const hideItem = id => {
    try {
      let a = document.getElementById(id)
      if (a) {
        a.style.display = "none"
      }
    } catch (error) {}
  }

  const showItem = id => {
    try {
      let a = document.getElementById(id)
      if (a) {
        a.style.display = "block"
      }
    } catch (error) {}
  }

  const onActivityCreated = () => {}
  const newActivityForm = inEditMode => {
    return (
      <div className="columns">
        <div className="column is-3-desktop is-hidden-mobile"></div>
        <div className="column is-6-desktop">
          <div className="new-activity-form-container">
            <BYOTActivityForm
              onCancel={onCancel}
              addToItinerary={false}
              addToWishlist={true}
              activeTab={0}
              onActivityCreated={onActivityCreated}
              inEditMode={inEditMode}
            />
          </div>
        </div>
        <div className="column is-3-desktop is-hidden-mobile"></div>
      </div>
    )
  }
  const getHours = val => {
    try {
      if (val) {
        return val === 0 ? "0" : parseInt(val / 60).toString()
      } else return "0"
    } catch (error) {
      return "0"
    }
  }
  const getMinutes = val => {
    try {
      if (val) {
        return val === 0 ? "0" : (val % 60).toString()
      } else return "0"
    } catch (error) {
      return "0"
    }
  }
  const updateWishlistWithAttraction = selectedAttraction => {
    try {
      console.log({ selectedAttraction })
      if (selectedAttraction) {
        //Check if the attraction already exists

        let index = -1
        if (state.currentJobInfo.tripWishlist) {
          index = state.currentJobInfo.tripWishlist.findIndex(
            item => item.activityName === selectedAttraction.name
          )
        } else index = -1

        if (index === -1) {
          let tempActivityScratchPad = {}
          tempActivityScratchPad["activityType"] = selectedAttraction["type"]
          tempActivityScratchPad["activityName"] = selectedAttraction["name"]
          tempActivityScratchPad["activityDescription"] =
            selectedAttraction["description"]
          if (selectedAttraction["descriptionURL"])
            tempActivityScratchPad["activityDescriptionURL"] =
              selectedAttraction["descriptionURL"]
          tempActivityScratchPad["activityAddress"] =
            selectedAttraction["address"]
          tempActivityScratchPad["activityEntryFee"] =
            selectedAttraction["entryFee"]
          tempActivityScratchPad["activityPhone"] = selectedAttraction["phone"]
          if (selectedAttraction["image"])
            tempActivityScratchPad["activityImageData"] =
              selectedAttraction["image"]
          if (selectedAttraction["imageId"])
            tempActivityScratchPad["activityImageId"] =
              selectedAttraction["imageId"]
          if (selectedAttraction["latlng"])
            tempActivityScratchPad["activityLatLng"] =
              selectedAttraction["latlng"]
          tempActivityScratchPad["activityURL1"] = selectedAttraction["url"]
          tempActivityScratchPad["activityUid"] = selectedAttraction["uid"]
          tempActivityScratchPad["activityHours"] = selectedAttraction["hours"]
          tempActivityScratchPad["activityViewTime"] =
            selectedAttraction["viewTime"]

          tempActivityScratchPad["activityDurationHours"] = getHours(
            selectedAttraction["viewTime"]
          )
          tempActivityScratchPad["activityDurationMinutes"] = getMinutes(
            selectedAttraction["viewTime"]
          )
          console.log({ tempActivityScratchPad })
          dispatch({
            type: "addNewWishListItemFromSearch",
            data: tempActivityScratchPad,
          })
          dispatch({ type: "wishlistSyncRequired", data: true })
        } else {
          console.log("Item already present in wishlist")
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const showDetailsModal = ({ item }) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        console.log({ item })
        if (item)
          return (
            <div
              className="box"
              style={{
                maxWidth: "600px",
                //height: "200px",
                padding: "25px",
                // textAlign: "center",
                boxShadow: "0 20px 75px rgba(0, 0, 0, 0.23)",
                position: "relative",
                border: "1px #ebebeb solid",
              }}
            >
              <div
                className="welcome-close"
                style={{ cursor: "pointer", right: "10px", top: "12px" }}
                onClick={() => {
                  onClose()
                }}
              >
                <i className="fas fa-times-circle fa-2x"></i>
              </div>
              {/* <div
                className="activity-card-data-show-compact"
                onClick={() => onClose()}
              >
                <i className="far fa-times"></i>
              </div> */}
              <div className="activity-card-data">
                <div
                  className="activity-card-name"
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "rgba(74,74,74,0.8)",
                  }}
                >
                  {titleCase(item.name)}
                </div>
                {/* <div className="activity-card-type" style={{ marginTop: "8px" }}>
          <i className="fas fa-map-marker-alt" style={{ marginRight: "3px" }}></i>5
          Rue Lucien Sampaix, 75010 Paris, France
        </div> */}
                <div
                  className="activity-card-type"
                  style={{
                    marginTop: "5px",
                    fontSize: "12px",
                    color: "#4a4a4a",
                    textDecorationColor: "#fec600",
                    textDecoration: "underline",
                  }}
                >
                  <i
                    className="fas fa-map-marker-alt"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <a
                    // onClick={e => {
                    //   e.preventDefault()
                    //   var a =
                    //   console.log("clicked")
                    //   customNavigateTo(a)
                    // }}
                    style={{
                      fontSize: "12px",
                      color: "#4a4a4a",

                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                    }}
                    href={googleMapsURI(item.address)}
                    target="_blank"
                  >
                    {item.address}
                  </a>
                </div>
                {item.phone ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    <i
                      className="fas fa-phone-alt"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a
                      style={{
                        fontSize: "12px",
                        color: "#4a4a4a",

                        textDecoration: "underline",
                        textDecorationColor: "#fec600",
                      }}
                      href={`tel:${item.activityPhone}`}
                    >
                      {item.phone}
                    </a>
                  </div>
                ) : (
                  ""
                )}
                {item.url ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    {" "}
                    <i
                      className="fas fa-link"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a
                      style={{
                        fontSize: "12px",
                        color: "#4a4a4a",

                        textDecoration: "underline",
                        textDecorationColor: "#fec600",
                      }}
                      href={item.url}
                      target="_blank"
                    >
                      {dottedText(item.url, 48)}
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {item.type === "restaurant" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i
                      className="fas fa-external-link-alt"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a href={item.menu} target="_blank">
                      Menu
                    </a>
                  </div>
                ) : (
                  " "
                )}
                {item.type === "sightseeing"
                  ? item.entryFee && (
                      <div
                        className="activity-card-type"
                        style={{ marginTop: "8px", fontSize: "12px" }}
                      >
                        Entry Fee (per person in USD): {item.entryFee}
                      </div>
                    )
                  : " "}
                {/* {formatDuration() !== "" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "14px", fontSize: "12px" }}
                  >
                    <i className="far fa-clock"></i>
                    {formatDuration()}
                  </div>
                ) : (
                  ""
                )} */}
                {/* {item.activityType === "restaurant" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i className="fas fa-dollar-sign"></i>
                    <i className="fas fa-dollar-sign"></i>
                    <i className="fas fa-dollar-sign"></i>
                  </div>
                ) : (
                  " "
                )} */}
                {/* <hr className="hr-branded"></hr> */}

                {/* <hr className="hr-branded"></hr> */}
                {/* <div className="activity-card-type" style={{ marginTop: "5px" }}>
          <i className="fas fa-phone" style={{ marginRight: "3px" }}></i>+33 1 82 28
          00 80
        </div> */}
                <div style={{ marginTop: "15px" }}>
                  {/* <AsyncFetchImage
                    imageId={item.imageId}
                    rounded={false}
                    fullWidth={true}
                  /> */}
                  <ShowImage
                    imageObj={item.image}
                    isFullWidth={true}
                    isRounded={false}
                  />
                  {/* {showImage(item.image, true, false)} */}
                </div>
                {item.description === "" ? (
                  ""
                ) : (
                  <div
                    className="activity-card-type"
                    style={{
                      marginTop: "16px",
                      marginBottom: "20px",
                      fontSize: "14px",
                    }}
                  >
                    {/* <label className="label">Description </label> */}
                    <p>{item.description}</p>
                    <a
                      href={
                        item.descriptionURL
                          ? `https://${item.descriptionURL}`
                          : "https://en.wikipedia.org/wiki/Main_Page"
                      }
                      target="_blank"
                    >
                      Source Wikipedia
                    </a>
                    {/* {ReactHtmlParser(item.activityDescription, {
                    decodeEntities: false,
                  })} */}
                  </div>
                )}

                {item.image === "" || item.image === undefined ? (
                  <iframe
                    title="Paris"
                    // src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
                    //   item.name
                    // )}+${encodeURI(item.activityAddress)}`}

                    src={getFormattedAddressForIframe(item.name, item.address)}
                    width="100%"
                    height="300px"
                    allowFullScreen
                  ></iframe>
                ) : (
                  ""
                )}
              </div>
            </div>
          )
      },
    })
  }

  const getResponsiveCount = sWidth => {
    if (sWidth < 640) return 1.3
    if (sWidth < 1024) return 2
    if (sWidth < 1200) return 1.5
    if (sWidth < 1600) return 2.0
    if (sWidth > 1600) return 2.75
  }
  const getResponsiveStepCount = sWidth => {
    if (sWidth < 640) return 1
    if (sWidth < 1024) return 1
    if (sWidth < 1254) return 2
    if (sWidth < 1600) return 2
    if (sWidth > 1600) return 2
  }

  const screenWidth = useWindowWidth()
  const displayRecommendations = () => {
    try {
      // get the current trip destinations

      //
      return
      // console.log({ recommendationHits })
    } catch (error) {
      console.log(error)
    }
  }

  const showAttractionsSearchBar = () => {
    return (
      <div className="wl-search-bar-container" style={{}}>
        <div className="wl-search-bar" style={{}}>
          {/* <input className="input" type="text" placeholder="Airport"></input> */}
          <div
            className="aa-Autocomplete"
            {...autoCompleteAttractions.getRootProps({})}
          >
            <input
              className="aa-Input input"
              tabindex="0"
              {...autoCompleteAttractions.getInputProps({})}
              id="idAttractions"
              placeholder="Search & add attractions"
              style={{ fontSize: "16px" }}
              onBlur={e => {
                // let a = e.currentTarget.value
                // if (
                //   a === "" &&
                //   currentFlightInfo.arriveAirport != "" &&
                //   currentFlightInfo.arriveCity != ""
                // ) {
                //   setAirportValues(currentFlightInfo)
                // }
                // console.log(e.currentTarget.value)
                setTimeout(() => {
                  setShowAddOwnItem(false)
                }, 200)
              }}
              // onFocus={e => {
              //   let a = e.currentTarget.value
              //   if (
              //     a === "" &&
              //     currentFlightInfo.arriveAirport != "" &&
              //     currentFlightInfo.arriveCity != ""
              //   ) {
              //     setAirportValues(currentFlightInfo)
              //   }
              //   console.log(e.currentTarget.value)
              // }}
            />
            <div
              className="aa-Panel"
              id="attraction-panel"
              {...autoCompleteAttractions.getPanelProps({})}
              //   placeholder="Airport"
            >
              {autocompleteStateAttraction.isOpen &&
                autocompleteStateAttraction.collections.map(
                  (collection, index, array) => {
                    const { source, items } = collection
                    if (autocompleteStateAttraction.isOpen) {
                      showItem("attraction-panel")
                    } else {
                      hideItem("attraction-panel")
                    }
                    if (array.length === 0) {
                      setShowAddOwnItem(true)
                    }
                    if (array.length > 0 && showAddOwnItem === true) {
                      setShowAddOwnItem(false)
                    }
                    // console.log({ items })
                    return (
                      <div key={`source-${index}`} className="aa-Source">
                        {items.length > 0 && (
                          <ul
                            className="aa-List"
                            {...autoCompleteAttractions.getListProps()}
                          >
                            {items.map((item, index) => (
                              <li
                                key={item.objectID}
                                style={{
                                  background:
                                    autocompleteStateAttraction.activeItemId ===
                                    index
                                      ? "rgba(254, 198, 0, 1)"
                                      : "white",
                                }}
                                className="aa-Item"
                                {...autoCompleteAttractions.getItemProps({
                                  item,
                                  source,
                                })}
                              >
                                <div
                                  style={{
                                    padding: "10px",
                                    minHeight: "40px",
                                    cursor: "pointer",
                                  }}
                                  className="airport-code-items"
                                  onClick={() => {
                                    // var b = document.getElementById(
                                    //   "idAttractions"
                                    // )
                                    // console.log({ b })
                                    // if (b) {
                                    //   b.value = ` `
                                    // }
                                    updateWishlistWithAttraction(item)
                                    autoCompleteAttractions.setQuery("")
                                    autoCompleteAttractions.setIsOpen(false)
                                    // clearResultsPanel()
                                    return
                                    //   var a = { ...currentFlightInfo }
                                    //   a["arriveAirport"] = item.iata_code
                                    //   a["arriveCity"] = item.city
                                    //   setCurrentFlightInfo({ ...a })
                                    //   var b = document.getElementById(
                                    //     "idAttractions"
                                    //   )
                                    //   if (b) {
                                    //     b.value = `${item.iata_code} - ${item.city}`
                                    //   }
                                  }}
                                >
                                  <nav className="level is-mobile">
                                    <div className="level-left">
                                      <div className="level-item">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <div>{item.name}</div>
                                          <div>
                                            <p
                                              className="help"
                                              style={{ marginTop: "-2px" }}
                                            >
                                              {item.city}, {item.country}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="level-right"
                                      style={{ display: "none" }}
                                    >
                                      <span
                                        className="tag is-warning"
                                        style={{
                                          textTransform: "uppercase",
                                          letterSpacing: "0.5px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {" "}
                                        {/* {item.iata_code} */}
                                      </span>
                                    </div>
                                  </nav>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    )
                  }
                )}
            </div>

            {showAddOwnItem && (
              <div
                className="wl-add-new-item-panel"
                id=""
                // style={{ display: "none" }}
              >
                <p style={{ marginRight: "5px", color: "rgba(74,74,74,0.8)" }}>
                  Can't find what you are looking for?{" "}
                </p>
                <a
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    textDecorationColor: "#fec600",
                    color: "rgba(74, 74, 74, 0.8)",
                    textDecorationColor: "#fec600",
                    textDecoration: "underline",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    setShowAddNewActivityEntry(true)
                    // setTimeout(() => {
                    //   setShowAddOwnItem(false)
                    // }, 200)
                  }}
                  // className="button is-text"
                >
                  {/* <i
                    className="far fa-plus"
                    style={{ marginRight: "5px", color: "rgba(74,74,74,0.8)" }}
                  ></i>{" "} */}
                  Add an item
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="wl-search-bar-or-txt" style={{ display: "none" }}>
          <p style={{}}>OR</p>
        </div>

        <div className="wl-search-bar-add-item" style={{ display: "none" }}>
          {canEdit ? (
            <div
              style={{
                // margin: "30px auto",
                maxWidth: "400px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  textDecorationColor: "#fec600",
                  color: "rgba(74, 74, 74, 0.8)",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
                onClick={() => {
                  setShowAddNewActivityEntry(true)
                }}
                className="button is-warning"
              >
                <i
                  className="far fa-plus"
                  style={{ marginRight: "5px", color: "rgba(74,74,74,0.8)" }}
                ></i>{" "}
                Add an item
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="columns">
        {/* <div className="column is-0-desktop is-hidden-mobile"></div> */}
        <div className="column is-12-desktop">
          <p
            // style={{
            //   fontSize: "34px",
            //   fontWeight: "800",
            //   color: "rgba(74,74,74,0.8)",
            //   margin: "20px auto",
            //   lineHeight: "1.4",
            //   textAlign: "center",
            // }}
            className="tab-header"
          >
            Trip Wishlist<span style={{ color: "#fec600" }}>.</span>
          </p>
          <p className="tab-tagline">
            Your wishlist of things to see, places to eat etc.{" "}
            <span style={{ fontWeight: "500" }}>TIP - </span> You can move items
            between your wishlist and your itinerary.
          </p>
          {!showAddNewActivityEntry &&
            !activityInEditMode &&
            canEdit &&
            showAttractionsSearchBar()}
          {/* <div className="wl-attractions-scroll"> */}
          {!showAddNewActivityEntry && canEdit && (
            <TopAttractions
              addAction={updateWishlistWithAttraction}
              showDetailAction={showDetailsModal}
            />
          )}
          {/* </div> */}
          <hr className="hr-branded"></hr>
          {!showAddNewActivityEntry &&
            !activityInEditMode &&
            showActivities()}{" "}
          {showAddNewActivityEntry && newActivityForm(false)}
          {activityInEditMode && newActivityForm(true)}
        </div>
        {/* <div className="column is-0-desktop is-hidden-mobile"></div> */}
      </div>
    </div>
  )
}

export default Wishlist
