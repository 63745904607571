import React, { useState, useEffect, useContext } from "react"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"
import {
  DashboardStateProvider,
  useDashboardStateStore,
} from "../../State/dashboard-state/dashboard-state"
import { Link } from "gatsby"
import {
  GlobalStateProvider,
  useGlobalStateStore,
} from "../../State/global-state/global-state"
import "./NotificationsContainer.scss"

import nomessage from "./../../images/nomessages.png"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import NotificationCard from "./NotificationCard"
const NotificationsContainerBase = () => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)

  const [state, dispatch] = useDashboardStateStore()
  const [loading, setLoading] = useState(true)
  const [lastVisible, setLastVisible] = useState(0)
  const [showExpandedView, setShowExpandedView] = useState(false)
  const pageSize = 5
  const [busy, setBusy] = useState(false)
  useEffect(() => {
    dispatch({ type: "initNotifications" })
  }, [])
  React.useEffect(() => {
    document.title = "Notifications | voyayge"
  }, [])

  useEffect(() => {
    console.log({ state })
  }, [state])

  useEffect(() => {
    console.log({ lastVisible })
  }, [lastVisible])

  useEffect(() => {
    try {
      const getNotificationsSnapshot = async () => {
        var notifications = []
        const querySnapshot = await dbContext
          .firestore()
          .collection("users")
          .doc(`${authContext.uid}`)
          .collection("notifications")
          .orderBy("sentAt", "desc")
          .limit(pageSize)
          .get()
          .then(function(querySnapshot) {
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
            querySnapshot.forEach(function(doc) {
              console.log(doc.id, " => ", doc.data())
              dispatch({
                type: "updateNotifications",
                data: doc.data(),
              })
            })
            setLoading(false)
          })
      }

      if (dbContext) getNotificationsSnapshot()
    } catch (error) {
      console.log(error)
    }
    return () => {}
  }, [dbContext])

  const loadNext = params => {
    try {
      setBusy(true)
      const getNotificationsSnapshot = async () => {
        var notifications = []
        const querySnapshot = await dbContext
          .firestore()
          .collection("users")
          .doc(`${authContext.uid}`)
          .collection("notifications")
          .orderBy("sentAt", "desc")
          .startAfter(lastVisible)
          .limit(pageSize)
          .get()
          .then(function(querySnapshot) {
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
            querySnapshot.forEach(function(doc) {
              dispatch({
                type: "updateNotifications",
                data: doc.data(),
              })
            })
            setBusy(false)
          })
      }

      if (dbContext) getNotificationsSnapshot()
    } catch (error) {
      console.log(error)
    }
  }

  const onDelete = messageId => {
    console.log({ messageId })
    try {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("users")
          .doc(`${authContext.uid}`)
          .collection("notifications")
          .doc(`${messageId}`)
          .delete()
          .then(function() {
            console.log("Notification successfully deleted!")
            dispatch({ type: "deleteNotification", data: messageId })
          })
          .catch(function(error) {
            console.error("Error removing Notification: ", error)
          })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onStarred = (messageId, value) => {
    console.log({ messageId })
    console.log({ value })
    try {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("users")
          .doc(`${authContext.uid}`)
          .collection("notifications")
          .doc(`${messageId}`)
          .update({ starred: value })
          .then(function() {
            console.log("Notification successfully starred!")
          })
          .catch(function(error) {
            console.error("Error starring Notification: ", error)
          })
      }
    } catch (err) {
      console.log(err)
    }
  }
  const populateNotifications = () => {
    if (state.notifications != undefined) {
      if (state.notifications.length === 0) {
        return (
          <div className="columns is-vcentered">
            <div className="column is-1"></div>
            <div className="column is-5">
              <p style={{ textAlign: "center", fontSize: "18px" }}>- Empty -</p>
            </div>
            <div className="column is-5">
              <img
                style={{ width: "100%", maxWidth: "250px" }}
                src={nomessage}
                alt=""
              />
            </div>
            <div className="column is-1"></div>
          </div>
        )
      } else {
        return state.notifications.map((item, index) => {
          return (
            <NotificationCard
              key={index}
              data={item}
              onDelete={onDelete}
              onStarred={onStarred}
            />
          )
        })
      }
    }
  }

  return (
    <div>
      {/* <hr className="hr-branded"></hr> */}

      {loading ? (
        <ShowLoading />
      ) : (
        <div className="columns is-multiline">
          <div className="column is-2-desktop is-1-tablet"></div>
          <div className="column is-8-desktop is-10-tablet ">
            {" "}
            <p
              style={{
                fontSize: "24px",
                fontWeight: "700",
                borderBottom: "2px solid #fec600",
              }}
            >
              Notifications
            </p>
          </div>

          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>

          <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
          <div className="column is-8-desktop is-10-tablet ">
            {populateNotifications()}
            <button
              disabled={lastVisible ? false : true}
              className={`button is-warning ${busy ? `is-loading` : ``}`}
              onClick={() => {
                loadNext()
              }}
              style={{ width: "100%" }}
            >
              Load more
            </button>
          </div>

          <div className="column is-2-desktop is-1-tablet "></div>
        </div>
      )}
    </div>
  )
}

const NotificationsContainer = () => {
  return (
    <DashboardStateProvider>
      <NotificationsContainerBase />
    </DashboardStateProvider>
  )
}
export default NotificationsContainer
