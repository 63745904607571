import React, { useState, useEffect, useRef, useContext } from "react"
import { SingleDatePicker } from "react-dates"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import Cleave from "cleave.js/react"
import is from "is_js"
import { v4 as uuidv4 } from "uuid"
import moment from "moment"
import { FirebaseContext } from "../../../components/firebase"
import { AuthUserContext } from "../../../components/session"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import "./TaskList.scss"
import ReactTooltip from "react-tooltip"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import { navigate } from "gatsby"
import { useGlobalStateStore } from "../../../State/global-state/global-state"
import { functions } from "../../../components/firebase/firebase"
const TaskList = ({ canEdit = false }) => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [isBusy, setIsBusy] = useState(false)
  const [state, dispatch] = useDashboardStateStore()
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [tripState, tripStateDispatch] = useTripStateStore()

  const [currentTaskListItem, setCurrentTaskListItem] = useState({
    taskTitle: "",
    taskNote: "",
    taskDueDate: "",
    taskDueTime: "",
    taskAssignee: "",
    taskStarred: false,
    dueDateDateFocus: false,
    completed: false,
    subTasks: [],
    taskId: uuidv4(),
  })
  const [showTaskEntryForm, setShowTaskEntryForm] = useState(false)
  const [inEditMode, setInEditMode] = useState(9999)
  const [toggleCompletedTaskPanel, setToggleCompletedTaskPanel] = useState(true)
  var noteRef = useRef(null)

  const sendNewTaskNotificationEmail = () => {
    try {
      if (currentTaskListItem.taskAssignee.uid === authContext.uid) return // dont sent notification to task assigned to self
      if (currentTaskListItem.taskAssignee === "") return // No assignee
      let data = {
        tripUid: state.tripDetails.tripUid,
        taskCreatorUserName: globalState.currentUserData.username
          ? globalState.currentUserData.username
          : globalState.currentUserData.firstName +
            " " +
            globalState.currentUserData.lastName,
        taskText: currentTaskListItem.taskTitle,
        receiverUid: currentTaskListItem.taskAssignee.uid,
      }
      console.log({ data })
      var sendNewTaskNotification = functions.httpsCallable(
        "sendNewTaskNotification"
      )
      sendNewTaskNotification(data)
        .then(async result => {
          // Read result of the Cloud Function.

          console.log({ result })
          // await updateTripState()

          if (result.data.success != undefined) {
          }
          if (result.data.error != undefined) {
            console.log("error")
          }
          // ...
        })
        .catch(err => {
          console.log(err)

          // addToast(
          //   "Error adding trip mate, please try again in sometime. If the issue persists please reach out to support@voyayge.com",
          //   {
          //     appearance: "error",
          //     autoDismiss: true,
          //   }
          // )
        })
    } catch (error) {}
  }

  useEffect(() => {
    try {
      if (state.currentJobInfo.tasksSyncRequired) {
        console.log(state.currentJobInfo.tasksSyncRequired)
        console.log({ dbContext })
        console.log(tripState.uid)
        console.log(state.currentJobInfo.jobUid)
        console.log(state.currentJobInfo.tasks)
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${tripState.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({
              tasks: [...state.currentJobInfo.tasks],
            })
            .then(() => {
              console.log("document update successful")
            })
            .catch(e => {
              setIsBusy(false)
              console.log(e)
            })
        }
        dispatch({ type: "tasksSyncRequired", data: false })
      }
    } catch (error) {}
  }, [state.currentJobInfo.tasksSyncRequired])

  useEffect(() => {
    console.log({ currentTaskListItem })
  }, [currentTaskListItem])
  useEffect(() => {
    console.log({ inEditMode })
  }, [inEditMode])
  useEffect(() => {
    console.log({ globalState })
  }, [globalState])
  // useEffect(() => {
  //   setInEditMode(9999)
  // }, [])

  useEffect(() => {
    console.log(state.currentJobInfo.tasksSyncRequired)
  }, [state.currentJobInfo.tasksSyncRequired])

  // const getSortedTasks = () => {
  //   try {
  //     if (state.currentJobInfo.tasks && state.currentJobInfo.tasks.length > 0) {
  //       var sArray = sortBy(state.currentJobInfo.tasks, [
  //         function(o) {
  //           return o.taskStarred
  //         },
  //       ])
  //       return sArray
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     return state.currentJobInfo.tasks
  //   }
  // }

  const showCompletedTasks = () => {
    try {
      if (state.currentJobInfo.tasks && state.currentJobInfo.tasks.length > 0) {
        let showEmptyText = true
        return (
          <>
            <div
              style={{
                display: "flex",
                marginBottom: "0.5em",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setToggleCompletedTaskPanel(!toggleCompletedTaskPanel)
              }}
            >
              <label
                style={{ marginRight: "7px", marginBottom: "0px" }}
                className="label"
              >
                Completed Tasks
              </label>
              {toggleCompletedTaskPanel ? (
                <i
                  style={{ color: "#fec600", fontSize: "14px" }}
                  className="far fa-chevron-down"
                ></i>
              ) : (
                <i
                  style={{ color: "#fec600", fontSize: "14px" }}
                  className="far fa-chevron-right"
                ></i>
              )}
            </div>
            {toggleCompletedTaskPanel &&
              state.currentJobInfo.tasks.map((item, index, { length }) => {
                if (inEditMode === index && item.completed) {
                  return <>{newTaskEntryForm(true, index)}</>
                } else {
                  if (item.completed) {
                    showEmptyText = false
                    return (
                      <div key={index} style={{ margin: "10px auto" }}>
                        {true && (
                          <nav
                            className="level is-mobile"
                            style={{
                              background: "rgba(250,250,250,1)",
                              borderRadius: "6px",
                              padding: "10px",
                            }}
                          >
                            <div
                              className="level-left"
                              style={{ flexShrink: "1" }}
                            >
                              <div className="level-item">
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "#fec600",
                                  }}
                                  onClick={() => {
                                    if (!canEdit) return
                                    var a = { ...item }
                                    if (item.completed) a["completed"] = false
                                    else a["completed"] = true

                                    dispatch({
                                      type: "saveTaskEdits",
                                      data: { ...a },
                                      index: index,
                                    })
                                    dispatch({
                                      type: "tasksSyncRequired",
                                      data: true,
                                    })
                                  }}
                                >
                                  {item.completed ? (
                                    <i className="far fa-check-square fa-lg"></i>
                                  ) : (
                                    <i className="far fa-square fa-lg"></i>
                                  )}
                                </div>
                              </div>
                              <div
                                className="level-item"
                                style={{ flexShrink: "1" }}
                              >
                                <div
                                  style={{
                                    textAlign: "left",
                                    marginRight: "20px",
                                    color: "rgba(74,74,74,1)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (canEdit) {
                                      setCurrentTaskListItem({
                                        ...state.currentJobInfo.tasks[index],
                                      })
                                      setInEditMode(index)
                                    }
                                  }}
                                >
                                  {" "}
                                  <p
                                    className="tasklist-title"
                                    style={{
                                      textDecoration: item.completed
                                        ? "line-through"
                                        : "none",
                                      textDecorationColor: item.completed
                                        ? "#fec600"
                                        : "transparent",
                                    }}
                                  >
                                    {item.taskTitle}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="level-right">
                              <div className="level-item">
                                <span
                                  style={{
                                    fontSize: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {/* Assigned To:{" "} */}
                                  {/* <span
                                  className="tag is-small is-warning"
                                  style={{ marginLeft: "3px" }}
                                > */}
                                  {showTaskAssignee(item)}
                                  {/* </span> */}
                                </span>
                              </div>
                              <div className="level-item">
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "#fec600",
                                    marginRight: "10px",
                                  }}
                                  onClick={() => {
                                    if (!canEdit) return
                                    var a = { ...item }
                                    if (item.taskStarred)
                                      a["taskStarred"] = false
                                    else a["taskStarred"] = true

                                    dispatch({
                                      type: "saveTaskEdits",
                                      data: { ...a },
                                      index: index,
                                    })
                                    dispatch({
                                      type: "tasksSyncRequired",
                                      data: true,
                                    })
                                  }}
                                >
                                  {item.taskStarred ? (
                                    <i className="fas fa-star"></i>
                                  ) : (
                                    <i className="fal fa-star"></i>
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  marginRight: "20px",
                                  cursor: "pointer",
                                  display: "none",
                                }}
                                onClick={() => {
                                  setCurrentTaskListItem({
                                    ...state.currentJobInfo.tasks[index],
                                  })
                                  setInEditMode(index)
                                }}
                              >
                                <i className="far fa-pen"></i>
                              </div>
                              {canEdit && (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "rgba(74,74,74,0.8)",
                                  }}
                                  onClick={() => {
                                    ConfirmationModal({
                                      onConfirm: i => {
                                        console.log(i)
                                        dispatch({
                                          type: "deleteTask",
                                          index: i,
                                        })
                                        dispatch({
                                          type: "tasksSyncRequired",
                                          data: true,
                                        })
                                      },
                                      sendBackValue: index,
                                      title: "Confirmation",
                                      text: `Are you sure you want to permanently delete this entry?`,
                                    })
                                  }}
                                >
                                  <i
                                    style={{
                                      cursor: "pointer",
                                      color: "rgba(74,74,74,0.8)",
                                    }}
                                    className="far fa-trash-alt"
                                  ></i>
                                </div>
                              )}
                            </div>
                          </nav>
                        )}

                        {/* <hr className="hr-branded"></hr> */}
                      </div>
                    )
                  }
                }
              })}
            {toggleCompletedTaskPanel && showEmptyText ? (
              <p
                style={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  letterSpacing: "0.5px",
                  color: "rgba(74,74,74,0.6)",
                }}
              >
                - none -
              </p>
            ) : (
              ""
            )}
          </>
        )
      } else {
        return canEdit ? (
          <div
            style={{
              margin: "30px auto",
              maxWidth: "400px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontWeight: "600",
                textDecorationColor: "#fec600",
                color: "rgba(74, 74, 74, 0.8)",
                textDecoration: "none",
              }}
              onClick={() => {
                setShowTaskEntryForm(true)
              }}
              // className="button is-warning"
            >
              <i className="far fa-plus" style={{ marginRight: "5px" }}></i> Add
              a Task
            </a>
          </div>
        ) : (
          <p
            style={{
              // fontStyle: "italic",
              fontSize: "14px",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            --
          </p>
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  const showOpenTasks = params => {}
  const showTaskAssignee = taskObj => {
    try {
      if (taskObj.taskAssignee.uid === authContext.uid)
        return (
          <img
            style={{ width: "25px", borderRadius: "50%" }}
            src={globalState.currentUserData.userProfileImage}
            alt="profile-image"
          />
        )
      else {
        if (state.collaborators) {
          let itemIndex = state.collaborators.findIndex(
            item => item.uid === taskObj.taskAssignee.uid
          )
          if (itemIndex !== -1) {
            return (
              <img
                style={{ width: "25px", borderRadius: "50%" }}
                src={state.collaborators[itemIndex].profileImage}
                alt="profile-image"
              />
            )
          }
        }
      }

      // if (taskObj.taskAssignee.uid === authContext.uid) return "Me"
      // else return taskObj.taskAssignee.userName
    } catch (error) {}
  }
  const showTasks = () => {
    try {
      if (state.currentJobInfo.tasks && state.currentJobInfo.tasks.length > 0) {
        let showEmptyText = true
        return (
          <>
            {canEdit && (
              <div
                style={{
                  margin: "30px auto",
                  maxWidth: "400px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  onClick={() => {
                    try {
                      setShowTaskEntryForm(true)
                    } catch (error) {}
                  }}
                  // className="a"
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    textDecorationColor: "#fec600",
                    color: "rgba(74, 74, 74, 0.8)",
                    textDecoration: "none",
                  }}
                >
                  <i className="far fa-plus" style={{ marginRight: "5px" }}></i>{" "}
                  Add a Task
                </a>
              </div>
            )}
            <label className="label">Open Tasks</label>
            {state.currentJobInfo.tasks.map((item, index, { length }) => {
              if (inEditMode === index && !item.completed) {
                return <>{newTaskEntryForm(true, index)}</>
              } else {
                if (!item.completed) {
                  showEmptyText = false
                  return (
                    <div key={index} style={{ margin: "10px auto" }}>
                      {true && (
                        <nav
                          className="level is-mobile"
                          style={{
                            background: "rgba(250,250,250,1)",
                            borderRadius: "6px",
                            padding: "10px",
                          }}
                        >
                          <ReactTooltip
                            offset={{ left: 5 }}
                            type="light"
                            padding="5px 5px"
                            backgroundColor="#fec600"
                            textColor="rgba(74,74,74,0.8)"
                          />
                          <div
                            className="level-left"
                            style={{ flexShrink: "1" }}
                          >
                            <div className="level-item">
                              <div
                                style={{ cursor: "pointer", color: "#fec600" }}
                                onClick={() => {
                                  if (!canEdit) return
                                  var a = { ...item }
                                  if (item.completed) a["completed"] = false
                                  else a["completed"] = true

                                  dispatch({
                                    type: "saveTaskEdits",
                                    data: { ...a },
                                    index: index,
                                  })
                                  dispatch({
                                    type: "tasksSyncRequired",
                                    data: true,
                                  })
                                }}
                              >
                                {item.completed ? (
                                  <i className="far fa-check-square fa-lg"></i>
                                ) : (
                                  <i className="far fa-square fa-lg"></i>
                                )}
                              </div>
                            </div>
                            <div
                              className="level-item"
                              style={{ flexShrink: "1" }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  marginRight: "20px",
                                  color: "rgba(74,74,74,1)",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  ReactTooltip.hide(noteRef)
                                  setCurrentTaskListItem({
                                    ...state.currentJobInfo.tasks[index],
                                  })
                                  setInEditMode(index)
                                }}
                              >
                                {" "}
                                <p
                                  className="tasklist-title"
                                  style={{
                                    textDecoration: item.completed
                                      ? "line-through"
                                      : "none",
                                    textDecorationColor: item.completed
                                      ? "#fec600"
                                      : "transparent",
                                  }}
                                >
                                  {item.taskTitle}
                                </p>
                                {item.taskNote && item.taskNote.length > 0 ? (
                                  <div

                                  // style={{ marginLeft: "-5px" }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "300",
                                        marginTop: "5px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontWeight: "500",
                                        }}
                                      >
                                        Note:
                                      </span>{" "}
                                      {item.taskNote}
                                    </p>
                                    {/* <i
                                      data-tip={`Note`}
                                      ref={ref => (noteRef = ref)}
                                      style={{
                                        fontSize: "14px",
                                        // color: "#fec600",
                                        color: "rgba(74,74,74,.8)",
                                      }}
                                      className="far fa-sticky-note"
                                    ></i> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="level-right">
                            <div className="level-item">
                              <span
                                style={{
                                  fontSize: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                {/* Assigned To:{" "} */}
                                {/* <span
                                  className="tag is-small is-warning"
                                  style={{ marginLeft: "3px" }}
                                > */}
                                {showTaskAssignee(item)}
                                {/* </span> */}
                              </span>
                            </div>
                            <div className="level-item">
                              <div
                                style={{
                                  cursor: "pointer",
                                  color: "#fec600",
                                  marginRight: "10px",
                                }}
                                onClick={() => {
                                  if (!canEdit) return
                                  var a = { ...item }
                                  if (item.taskStarred) a["taskStarred"] = false
                                  else a["taskStarred"] = true

                                  dispatch({
                                    type: "saveTaskEdits",
                                    data: { ...a },
                                    index: index,
                                  })
                                  dispatch({
                                    type: "tasksSortRequired",
                                  })
                                  dispatch({
                                    type: "tasksSyncRequired",
                                    data: true,
                                  })
                                }}
                              >
                                {item.taskStarred ? (
                                  <i className="fas fa-star"></i>
                                ) : (
                                  <i className="fal fa-star"></i>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                marginRight: "20px",
                                cursor: "pointer",
                                display: "none",
                              }}
                              onClick={() => {
                                setCurrentTaskListItem({
                                  ...state.currentJobInfo.tasks[index],
                                })
                                setInEditMode(index)
                              }}
                            >
                              <i className="far fa-pen"></i>
                            </div>
                            {canEdit && (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  ConfirmationModal({
                                    onConfirm: i => {
                                      console.log(i)
                                      dispatch({
                                        type: "deleteTask",
                                        index: i,
                                      })
                                      dispatch({
                                        type: "tasksSyncRequired",
                                        data: true,
                                      })
                                    },
                                    sendBackValue: index,
                                    title: "Confirmation",
                                    text: `Are you sure you want to permanently delete this entry?`,
                                  })
                                }}
                              >
                                <i className="far fa-trash-alt"></i>
                              </div>
                            )}
                          </div>
                        </nav>
                      )}
                    </div>
                  )
                }
              }
            })}
            {showEmptyText ? (
              <p
                style={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  letterSpacing: "0.5px",
                  color: "rgba(74,74,74,0.6)",
                }}
              >
                - none -
              </p>
            ) : (
              ""
            )}
            <hr className="hr-branded"></hr>
            {showCompletedTasks()}
          </>
        )
      } else {
        return canEdit ? (
          <div
            style={{
              margin: "30px auto",
              maxWidth: "400px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              style={{
                textAlign: "center",
                fontWeight: "600",
                textDecorationColor: "#fec600",
                color: "rgba(74, 74, 74, 0.8)",
                textDecoration: "none",
              }}
              onClick={() => {
                setShowTaskEntryForm(true)
              }}
              // className="button is-warning"
            >
              <i className="far fa-plus" style={{ marginRight: "5px" }}></i> Add
              a Task
            </a>
          </div>
        ) : (
          <p
            style={{
              // fontStyle: "italic",
              fontSize: "14px",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            - No tasks cars added yet -
          </p>
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  const clearCurrentTaskInfo = () => {
    setCurrentTaskListItem({
      taskTitle: "",
      taskNote: "",
      taskDueDate: "",
      taskDueTime: "",
      taskAssignee: "",
      taskStarred: false,
      dueDateDateFocus: false,
      completed: false,
      subTasks: [],
      taskId: uuidv4(),
    })
  }
  const showCollaborators = () => {
    try {
      let roles = state.currentJobInfo.roles
      let tList = Object.keys(state.currentJobInfo.roles)

      // if (tList.length === 1)
      //   return (
      //     <a
      //       style={{
      //         fontSize: "14px",
      //         color: "rgba(74,74,74,0.8)",
      //         fontWeight: "500",
      //       }}
      //       onClick={() => {
      //         navigate(
      //           `/app/byottripdetails/${state.currentJobInfo.tripUid}?tab=settings`
      //         )
      //       }}
      //     >
      //       <span className="icon is-small" style={{ marginRight: "5px" }}>
      //         {" "}
      //         <i className="far fa-user-plus"></i>
      //       </span>
      //       <span
      //         style={{
      //           textDecoration: "underline",
      //           textDecorationColor: "#fec600",
      //         }}
      //       >
      //         Invite trip mates.
      //       </span>
      //     </a>
      //   )
      // console.log({ tList })
      return tList.map((item, index) => {
        // console.log(tList[item])
        if (item === authContext.uid) {
          return (
            <button
              style={{ marginRight: "10px" }}
              onClick={e => {
                if (canEdit) {
                  var a = { ...currentTaskListItem }
                  a["taskAssignee"] = { ...roles[item], uid: item }
                  setCurrentTaskListItem({ ...a })
                }
              }}
              className={`${
                item === currentTaskListItem.taskAssignee.uid
                  ? "button is-small is-warning"
                  : "button is-small"
              } `}
            >
              Me
            </button>
          )
        } else if (roles[item].userName === undefined) {
          let itemIndex = state.collaborators.findIndex(i => i.uid === item)
          if (itemIndex !== -1) {
            return (
              <button
                style={{ marginRight: "10px" }}
                className={`${
                  item === currentTaskListItem.taskAssignee.uid
                    ? "button is-small is-warning"
                    : "button is-small"
                } `}
                onClick={e => {
                  if (canEdit) {
                    var a = { ...currentTaskListItem }
                    a["taskAssignee"] = {
                      ...roles[item],
                      uid: item,
                      userName: state.collaborators[itemIndex].userName,
                      // receiverEmail: state.collaborators[itemIndex].receiverEmail,
                    }
                    setCurrentTaskListItem({ ...a })
                  }
                }}
              >
                {state.collaborators[itemIndex].userName}
              </button>
            )
          }
        } else
          return (
            <button
              style={{ marginRight: "10px" }}
              className={`${
                item === currentTaskListItem.taskAssignee.uid
                  ? "button is-small is-warning"
                  : "button is-small"
              } `}
              onClick={e => {
                var a = { ...currentTaskListItem }
                a["taskAssignee"] = { ...roles[item], uid: item }
                setCurrentTaskListItem({ ...a })
              }}
            >
              {roles[item].userName}
            </button>
          )
      })
    } catch (error) {
      console.log(error)
    }
  }
  const newTaskEntryForm = (edit, editedItemIndex) => {
    try {
      return (
        <>
          <hr className="hr-branded"></hr>
          <div className="field">
            <label className="label">Task</label>
            <div className="control">
              <input
                style={
                  {
                    // background: inEditMode ? "#fafafa" : "white",
                    // border: inEditMode ? "none" : "default",
                    // // background: inEditMode ? "#fafafa" : "white",
                    // border: inEditMode ? "none" : "default",
                  }
                }
                disabled={canEdit ? false : true}
                className="input"
                type="text"
                autoFocus
                tabIndex="1"
                placeholder="Add a task"
                value={currentTaskListItem.taskTitle}
                onChange={e => {
                  var a = { ...currentTaskListItem }
                  if (e.currentTarget.value) {
                    a["taskTitle"] = e.currentTarget.value
                  } else {
                    a["taskTitle"] = e.currentTarget.value
                  }
                  setCurrentTaskListItem({ ...a })
                }}
              ></input>
            </div>
          </div>
          <div className="field">
            <label className="label">Additional Note</label>
            <div className="control">
              <textarea
                // style={{
                //   background: inEditMode ? "#fafafa" : "white",
                //   border: inEditMode ? "none" : "default",
                // }}
                disabled={canEdit ? false : true}
                className="textarea"
                type="text"
                tabIndex="2"
                rows="2"
                maxLength="100"
                placeholder="Add a note"
                value={currentTaskListItem.taskNote}
                onChange={e => {
                  var a = { ...currentTaskListItem }
                  if (e.currentTarget.value) {
                    a["taskNote"] = e.currentTarget.value
                  } else {
                    a["taskNote"] = e.currentTarget.value
                  }
                  setCurrentTaskListItem({ ...a })
                }}
              ></textarea>
            </div>
          </div>
          <div className="field">
            <label className="label">Assign To</label>
            <div className="control">
              <div className="tags are-small">{showCollaborators()}</div>
            </div>
          </div>
          <div
            className="field is-grouped is-grouped-right"
            style={{ marginTop: "40px" }}
          >
            <div className="control">
              <button
                tabIndex="4"
                style={{ border: "1px solid #fec600", fontSize: "14px" }}
                onClick={() => {
                  clearCurrentTaskInfo()
                  setShowTaskEntryForm(false)
                  setInEditMode(9999)
                }}
                className="button is-warning is-light"
              >
                {edit ? "Close" : "Cancel"}
              </button>
            </div>
            {canEdit && (
              <div className="control">
                <button
                  style={{ fontSize: "14px" }}
                  tabIndex="3"
                  class={`${
                    isBusy
                      ? "button is-warning is-loading"
                      : "button is-warning"
                  }`}
                  onClick={() => {
                    if (isBusy) return
                    setIsBusy(true)
                    console.log({ edit })
                    if (edit) {
                      dispatch({
                        type: "saveTaskEdits",
                        data: currentTaskListItem,
                        index: editedItemIndex,
                      })
                    } else {
                      dispatch({
                        type: "addNewTask",
                        data: currentTaskListItem,
                      })
                      sendNewTaskNotificationEmail()
                    }

                    dispatch({
                      type: "tasksSyncRequired",
                      data: true,
                    })
                    setIsBusy(false)
                    setInEditMode(9999)
                    clearCurrentTaskInfo()
                    setShowTaskEntryForm(false)
                    // if (dbContext) {
                    //   dbContext
                    //     .firestore()
                    //     .collection("jobs")
                    //     .doc(`${authContext.uid}`)
                    //     .collection("active-jobs")
                    //     .doc(`${state.currentJobInfo.jobUid}`)
                    //     .update({
                    //       rentalCarDetails: dbContext.firestore.FieldValue.arrayUnion(
                    //         currentRentalCarsInfo
                    //       ),
                    //     })
                    //     .then(() => {
                    //       console.log("document update successful")
                    //       //update local state now

                    //     })
                    //     .catch(e => {
                    //       setIsBusy(false)
                    //       console.log(e)
                    //     })
                    // }
                  }}
                >
                  {edit ? "Save Changes" : "Add"}
                </button>
              </div>
            )}
          </div>
          <hr className="hr-branded"></hr>
        </>
      )
    } catch (error) {}
  }

  return (
    <div>
      <div className="columns">
        <div className="column is-3-desktop is-0-tablet is-hidden-mobile"></div>
        <div className="column is-6-desktop is-12-tablet is-12-mobile">
          <p
            className="tab-header"
            // style={{
            //   fontSize: "34px",
            //   fontWeight: "800",
            //   color: "rgba(74,74,74,0.8)",
            //   margin: "20px auto",
            //   lineHeight: "1.4",
            //   textAlign: "center",
            // }}
          >
            Trip Tasks<span style={{ color: "#fec600" }}>.</span>
          </p>
          {!showTaskEntryForm && showTasks()}
          {/* {showCompletedTasks()} */}
          {showTaskEntryForm && newTaskEntryForm(false, 0)}
        </div>
        <div className="column is-3-desktop is-0-tablet is-hidden-mobile"></div>
      </div>
    </div>
  )
}

export default TaskList
