import React from "react"
import CollaboratorDetails from "../Traveler/CollaboratorDetails"
import TripDetailsSettings from "../Traveler/TripDetailsSettings"
import "./TripSettings.scss"
const TripSettings = ({ isOwner }) => {
  return (
    <div>
      {" "}
      <p
        // style={{
        //   fontSize: "34px",
        //   fontWeight: "800",
        //   color: "rgba(74,74,74,0.8)",
        //   margin: "20px auto",
        //   lineHeight: "1.4",
        //   textAlign: "center",
        // }}
        className="tab-header"
      >
        Settings<span style={{ color: "#fec600" }}>.</span>
      </p>
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column is-8">
          {/* <div class="tabs">
            <ul>
              {isOwner && (
                <li class="is-active">
                  <a>
                    <span class="icon is-small">
                      {" "}
                      <i class="far fa-users"></i>
                    </span>
                  </a>
                </li>
              )}
              {isOwner && (
                <li>
                  <a>
                    <span class="icon is-small">
                      {" "}
                      <i class="far fa-map-marker-alt"></i>
                    </span>
                  </a>
                </li>
              )}
            </ul>
          </div> */}
          <div class="tile is-ancestor" style={{ marginTop: "30px" }}>
            <div class="tile is-parent">
              <article class="tile is-child" style={{ padding: "10px" }}>
                {isOwner && <CollaboratorDetails />}
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child" style={{ padding: "10px" }}>
                {isOwner && <TripDetailsSettings />}
              </article>
            </div>
          </div>

          <hr className="hr-branded"></hr>
        </div>
        <div className="column is-2"></div>
      </div>
    </div>
  )
}

export default TripSettings
