import React, { useState, useEffect, useContext } from "react"
import is from "is_js"
import { analytics, functions } from "../../components/firebase/firebase"
import thankq from "./../../images/thankq.png"
import error from "./../../images/404.png"
import yay from "./../../images/yay.png"
import { v4 as uuidv4 } from "uuid"
import { uniq } from "lodash"
import { motion, useMotionValue, useTransform } from "framer-motion"
import { LazyLoadImage } from "react-lazy-load-image-component"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"
import { AuthUserContext } from "../session"
import { FirebaseContext } from "../firebase"
import { useGlobalStateStore } from "../../State/global-state/global-state"
const TripPlannerReferral = ({ onClose }) => {
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [selectionState, setSelectionState] = useState("none")
  const [emailCount, setEmailCount] = useState(3)
  const [emailAddress, setEmailAddress] = useState([])
  const [invalidEmailIndex, setInvalidEmailIndex] = useState(99)
  const [repeatingEmailAddress, setRepeatingEmailAddress] = useState(false)
  const [showInvitesSending, setShowInvitesSending] = useState(false)
  const [whatDidntWorkText, setWhatDidntWorkText] = useState("")
  const [showFeedbackSending, setShowFeedbackSending] = useState(false)
  const [whatDidntWorkTextLen, setWhatDidntWorkTextLen] = useState(0)
  const [isOpen, setIsOpen] = useState(true)
  const screenWidth = useWindowWidth()
  useEffect(() => {
    console.log(emailAddress)
  }, [emailAddress])
  useEffect(() => {
    setWhatDidntWorkTextLen(whatDidntWorkText.length)
  }, [whatDidntWorkText])
  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  }
  const updateState = sVal => {
    try {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("users")
          .doc(`${authContext.uid}`)
          .update({
            npsComplete: true,
            npsCompleteDate: new Date(),
            npsType: "thumbsUpDown",
            npsValue: sVal,
          })
          .then(() => {
            console.log("nps state updated successfully!")
          })
          .catch(err => {
            console.log(err)
          })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const sendInvitesToEmails = () => {
    console.log("sendInvitesToEmails called")
    setShowInvitesSending(true)
    let eId = uuidv4()
    let data = {
      emails: [...emailAddress],
      user: authContext.uid,
      email: authContext.email,
      id: eId,
      date: new Date(),
    }
    if (emailAddress) {
      var sendInvitesToEmails = functions.httpsCallable("inviteFriendsToByot")
      sendInvitesToEmails(data)
        .then(result => {
          // Read result of the Cloud Function.
          setEmailAddress([])
          setShowInvitesSending(false)
          setSelectionState("success")
          setTimeout(() => {
            if (onClose) onClose()
            setIsOpen(false)
          }, 1000)
          console.log({ result })
          if (dbContext) {
            dbContext
              .firestore()
              .collection("trip-planner-referrals")
              .doc(`${eId}`)
              .set(data)
              .then(() => {
                console.log("referrals updated successfully!")
              })
              .catch(err => {
                console.log(err)
              })
          }
          updateState("thumbsUp")
          globalDispatch({
            type: "updateNPSState",
            data: true,
          })
          if (result && result.data) {
          } else console.log("outside")
        })
        .catch(err => {
          console.log(err)
          setShowInvitesSending(false)
          updateState("thumbsUp")
          globalDispatch({
            type: "updateNPSState",
            data: true,
          })
        })
    }
  }
  const pushFeedback = () => {
    try {
      setShowFeedbackSending(true)
      let data = {
        feedback: whatDidntWorkText,
        id: uuidv4(),
        user: authContext.uid,
        email: authContext.email,
      }
      var saveFeedback = functions.httpsCallable("saveGeneralFeedback")
      saveFeedback(data)
        .then(function(result) {
          // Read result of the Cloud Function.
          setShowFeedbackSending(false)
          console.log({ result })
          updateState("thumbsDown")
          globalDispatch({
            type: "updateNPSState",
            data: true,
          })
          if (result.data.success) {
            setSelectionState("success")
            if (onClose) onClose()
            setIsOpen(false)
          } else {
            setSelectionState("error")
          }
          // ...
        })
        .catch(err => {
          console.log(err)
          setShowFeedbackSending(false)
          setSelectionState("error")
          updateState("thumbsDown")
          globalDispatch({
            type: "updateNPSState",
            data: true,
          })
        })
    } catch (err) {
      console.log(err)
      setShowFeedbackSending(false)
      setSelectionState("error")
      updateState("thumbsDown")
      globalDispatch({
        type: "updateNPSState",
        data: true,
      })
    }
  }
  const validateEmailsAndSend = () => {
    try {
      if (emailAddress.length > 0) {
        let inValidEmailFound = false
        let invalidEmailIndex = 0
        let uArray = uniq(emailAddress)
        console.log({ uArray })
        console.log({ emailAddress })
        let cnt = emailAddress.filter(x => x == "").length
        console.log({ cnt })
        if (cnt === 3) {
          setInvalidEmailIndex(0)
          setInvalidEmailIndex(1)
          setInvalidEmailIndex(3)
          console.log(`invalid email found at ${invalidEmailIndex}`)

          return
        }
        if (emailAddress.length != uArray.length) {
          if (cnt !== 2) {
            setRepeatingEmailAddress(true)
            console.log(`Email address have to be unique.`)
            return
          }
        }
        emailAddress.map((item, index) => {
          if (item.length > 0) {
            if (is.email(item)) {
              console.log("valid email")
            } else {
              inValidEmailFound = true
              invalidEmailIndex = index
            }
          }
        })
        if (!inValidEmailFound) {
          //send email list
          console.log("all valid emails")
          sendInvitesToEmails()
        } else {
          setInvalidEmailIndex(invalidEmailIndex)
          console.log(`invalid email found at ${invalidEmailIndex}`)
        }
      }
    } catch (error) {}
  }

  const showRightScreen = () => {
    console.log({ selectionState })
    switch (selectionState) {
      case "none":
        return (
          <>
            <p style={{ marginBottom: "15px", textAlign: "center" }}>
              Like using the trip planner?
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="button"
                onClick={() => {
                  setSelectionState("thumbsDown")
                  // updateState("thumbsDown")
                  // globalDispatch({
                  //   type: "updateNPSState",
                  //   data: true,
                  // })
                }}
                style={{ marginRight: "20px", outline: "none" }}
              >
                <i
                  style={{
                    color:
                      selectionState !== "thumbsDown"
                        ? "rgba(74,74,74,.4)"
                        : "#fec600",
                  }}
                  class="fas fa-thumbs-down"
                ></i>
              </div>
              <div
                className="button"
                onClick={() => {
                  setSelectionState("thumbsUp")
                  // updateState("thumbsUp")
                  // globalDispatch({
                  //   type: "updateNPSState",
                  //   data: true,
                  // })
                }}
              >
                <i
                  style={{
                    color:
                      selectionState !== "thumbsUp"
                        ? "rgba(74,74,74,.4)"
                        : "#fec600",
                  }}
                  class="fas fa-thumbs-up"
                ></i>
              </div>
            </div>
          </>
        )
        break
      case "thumbsUp":
        return (
          <>
            {/* <p style={{ marginBottom: "15px", textAlign: "center" }}>
              Awesome! 🎉
            </p> */}
            <div
              className="welcome-close"
              style={{
                cursor: "pointer",
                right: "10px",
                top: "10px",
                zIndex: "99",
              }}
              onClick={() => {
                console.log("closed")
                if (onClose) onClose()
                setIsOpen(false)
              }}
            >
              <i
                style={{ cursor: "pointer" }}
                className="fas fa-times-circle fa-2x"
                onClick={() => {
                  console.log("closed")
                  if (onClose) onClose()
                  setIsOpen(false)
                }}
              ></i>
            </div>
            <div style={{ maxWidth: "300px", margin: "0px auto" }}>
              <LazyLoadImage src={yay} alt="thank you" effect="blur" />
            </div>
            {/* <p style={{ marginBottom: "15px", textAlign: "center" }}>
              Are your friends using voyay!ge?
            </p> */}

            <p
              style={{
                marginBottom: "5px",
                marginTop: "25px",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              We need your help!
            </p>
            <p
              style={{
                marginBottom: "10px",
                marginTop: "0px",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              Tell your friends about voyay!ge
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {[...Array(emailCount).keys()].map(item => {
                return (
                  <div
                    key={item}
                    style={{
                      marginBottom: "10px",
                      fontSize: "14px",
                      width: "100%",
                    }}
                  >
                    <input
                      id={item}
                      class="input"
                      type="email"
                      autocomplete="off"
                      placeholder={`email address ${item + 1}`}
                      style={{ marginBottom: "2px", fontSize: "14px" }}
                      onChange={e => {
                        setRepeatingEmailAddress(false)
                        setInvalidEmailIndex(99)
                        let i = e.currentTarget.id
                        let em = emailAddress
                        console.log(em.length)
                        if (i > em.length) {
                          em.push(e.currentTarget.value)
                        } else {
                          em[i] = e.currentTarget.value
                        }
                        setEmailAddress([...em])
                      }}
                    ></input>
                    {invalidEmailIndex === item ? (
                      <p class="help is-danger">
                        Please enter valid email address
                      </p>
                    ) : (
                      ""
                    )}
                    {repeatingEmailAddress === true && item === 2 ? (
                      <p class="help is-danger">
                        emails address have to be unique
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )
              })}

              <button
                className={`button is-warning ${
                  showInvitesSending ? "is-loading" : ""
                }`}
                style={{ fontSize: "14px", width: "100%" }}
                onClick={() => {
                  validateEmailsAndSend()
                }}
              >
                Spread the word!
              </button>
            </div>
          </>
        )
        break
      case "thumbsDown":
        return (
          <>
            {/* <p style={{ marginBottom: "15px", textAlign: "center" }}>
              Awesome! 🎉
            </p> */}
            <div
              className="welcome-close"
              style={{
                cursor: "pointer",
                right: "10px",
                top: "10px",
                zIndex: "99",
              }}
              onClick={() => {
                console.log("closed")
                if (onClose) onClose()
                setIsOpen(false)
              }}
            >
              <i
                style={{ cursor: "pointer" }}
                className="fas fa-times-circle fa-2x"
                onClick={() => {
                  console.log("closed")
                  if (onClose) onClose()
                  setIsOpen(false)
                }}
              ></i>
            </div>
            <div style={{ maxWidth: "300px", margin: "0px auto" }}>
              <LazyLoadImage src={error} alt="thank you" effect="blur" />
            </div>
            {/* <p style={{ marginBottom: "15px", textAlign: "center" }}>
              Are your friends using voyay!ge?
            </p> */}

            <p
              style={{
                marginBottom: "10px",
                marginTop: "25px",
                textAlign: "left",
              }}
            >
              How can we improve?
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                className="control is-expanded"
                style={{ width: "100%", marginBottom: "10px" }}
              >
                <textarea
                  onChange={e => {
                    if (e.currentTarget.value.length <= 500)
                      setWhatDidntWorkText(e.currentTarget.value)
                  }}
                  value={whatDidntWorkText}
                  className="textarea"
                  placeholder=""
                ></textarea>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="help"></p>
                  <p
                    className={`help is-pulled-right ${
                      whatDidntWorkTextLen >= 500 ? "is-danger" : ""
                    }`}
                  >
                    Characters: {whatDidntWorkTextLen}/500
                  </p>
                </div>
              </div>
              <button
                className={`button is-warning ${
                  showFeedbackSending ? "is-loading" : ""
                }`}
                style={{ fontSize: "14px", width: "100%" }}
                onClick={() => {
                  //   validateEmailsAndSend()
                  pushFeedback()
                }}
              >
                Send Feedback
              </button>
            </div>
          </>
        )
        break
      case "success":
        return (
          <div style={{ maxWidth: "300px", margin: "0px auto" }}>
            <LazyLoadImage src={thankq} alt="thank you" effect="blur" />
          </div>
        )

      default:
        break
    }
  }

  return (
    <motion.div
      style={{
        padding: "25px",
        background: "white",
        borderRadius: "6px",
        // display: "flex",
        // flexDirection:'column',
        // alignItems: "center",
        border: "1px solid #ebebeb",
        position: "absolute",
        zIndex: "2342342423423",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: screenWidth > 768 ? "auto" : "80%",
      }}
      animate={isOpen ? "open" : "closed"}
      className="brand-shadow"
      transition={{ delay: 1 }}
      variants={variants}
    >
      {showRightScreen()}
    </motion.div>
  )
}

export default TripPlannerReferral
