import React, { useState, useEffect, useMemo } from "react"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc"
import ReactHtmlParser from "react-html-parser"
import { NewActivityForm } from "./NewActivityForm"
import ReactTooltip from "react-tooltip"
import ReactDOMServer from "react-dom/server"

import {
  titleCase,
  getMinutes,
  getHours,
  addZeroBefore,
  dottedText,
  googleMapsURI,
  parseDayAndItemFromString,
  googleDirectionsURI,
} from "../../../utility/general"
import { NotificationModal } from "../../../components/NotificationModal/NotificationModal"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import { MoveActivityConfirmationModal } from "./MoveActivityConfirmationModal"
import AsyncFetchImage from "../../../components/AsyncFetchImage/AsyncFetchImage"
import { LazyLoadImage } from "react-lazy-load-image-component"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"

const DragHandle = SortableHandle(() => (
  <div>
    <i className="far fa-bars"></i>
  </div>
))
export const BYOTActivityCard = SortableElement(
  ({
    length,
    index,
    itemNo,
    value,
    onRemove,
    onEditActivity,
    onMoveActivity,
    activeTab,
    isSetAsBackup,
    onEditCommute,
    onAddCommute,
    onRemoveCommute,
    canEdit,
    type,
    onMoveToBackup,
    onMoveToActive,
    onRemoveBackup,
    onSelect,
    disableDrag,
    onMoveUp,
    onMoveDown,
  }) => {
    console.log({ length })
    const [state, dispatch] = useDashboardStateStore()
    const [showMoreInfo, setShowMoreInfo] = useState(false)
    const [launchEditForm, setLaunchEditForm] = useState(false)
    const [toggleMenu, setToggleMenu] = useState(false)
    const [mouseDown, setMouseDown] = useState(false)
    const screenWidth = useWindowWidth()
    //var a = value.activity.activityType
    useEffect(() => {
      // console.log({ showMoreInfo })
    }, [showMoreInfo])
    useEffect(() => {
      console.log({ disableDrag })
    }, [disableDrag])
    useEffect(() => {
      console.log({ mouseDown })
    }, [mouseDown])

    const onClose = () => {
      setShowMoreInfo(!showMoreInfo)
    }

    const pickRightImage = activityType => {
      // switch (activityType) {
      //   case "breakfast":
      //     return (
      //       <img
      //         className="activity-card-image"
      //         src={breakfast}
      //         alt="breakfast"
      //       />
      //     )
      //   case "lunch":
      //     return <img className="activity-card-image" src={lunch} alt="lunch" />
      //   case "sightseeing":
      //     return (
      //       <img
      //         className="activity-card-image"
      //         src={sightseeing}
      //         alt="sightseeing"
      //       />
      //     )
      //   default:
      //     break
      // }
    }

    const customNavigateTo = url => {
      window.open(url, "_blank")
    }

    function returnFormattedTime() {
      try {
        return (
          <>
            {" "}
            <div className="top-timeline" style={{ display: "none" }}>
              {value.activity.activityStartTime === undefined
                ? 0
                : `${addZeroBefore(
                    getHours(value.activity.activityStartTime)
                  )}:${addZeroBefore(
                    getMinutes(value.activity.activityStartTime)
                  )}`}
            </div>
            <div className="bottom-timeline" style={{ display: "none" }}>
              {value.activity.activityEndTime === undefined
                ? 0
                : `${addZeroBefore(
                    getHours(value.activity.activityEndTime)
                  )}:${addZeroBefore(
                    getMinutes(value.activity.activityEndTime)
                  )}`}
            </div>
          </>
        )
      } catch (error) {
        console.log(error)
        return ""
      }
    }

    const moveUp = () => {
      try {
        onMoveUp({ oldIndex: itemNo, newIndex: itemNo === 0 ? 0 : itemNo - 1 })
      } catch (error) {}
    }
    const moveDown = () => {
      try {
        onMoveDown({
          oldIndex: itemNo,
          newIndex: itemNo + 1 === length ? itemNo : itemNo + 1,
        })
      } catch (error) {}
    }

    const showCompact = (
      length,
      itemNo,
      value,
      onClose,
      onRemove,
      onMoveToBackup,
      onEditActivity,
      onSelect
    ) => {
      console.log({ index })
      return (
        <div
          className="activity-card-type2"
          style={{
            marginBottom: "15px",
            border: value.activity.selected
              ? "1px solid #7a7a7a"
              : "1px solid #fec600",
            borderLeft: value.activity.selected
              ? "5px solid #7a7a7a"
              : "5px solid #fec600",
            backgroundImage: `url(${
              value.activity.activityImageData
                ? value.activity.activityImageData.urls.small
                : "none"
            })`,
            backgroundPositionY: value.activity.activityImageData
              ? "center"
              : `${Math.random() * 100}%`,
            backgroundSize: "cover",
            height: "92px",
            cursor: disableDrag ? "" : mouseDown ? "grabbing" : "grab",
            marginLeft: screenWidth < 640 ? "0px" : "0px",
            width: screenWidth < 1250 ? "100%" : "",
            borderRadius: "0px 4px 4px 0px",
          }}
          id="activity-card-type2"
          // style={ {isSetAsBackup ? { cursor: "pointer" } : ""}`}

          // onClick={() => {
          //   onSelect(itemNo, value.dayId, value.id)
          // }}
          onMouseOver={() => {
            var marker = document.getElementById(`map-marker-${value.id}`)
            if (marker) {
              marker.classList.add("active-marker")
            }
          }}
          onMouseLeave={() => {
            var marker = document.getElementById(`map-marker-${value.id}`)
            if (marker) {
              marker.classList.remove("active-marker")
            }
            setMouseDown(false)
          }}
          onMouseOut={() => {
            var marker = document.getElementById(`map-marker-${value.id}`)
            if (marker) {
              marker.classList.remove("active-marker")
            }
            setMouseDown(false)
          }}
          onMouseDown={() => {
            console.log("mouse down")
            if (!mouseDown) setMouseDown(true)
            // document.body.style.cursor = disableDrag ? "default" : "grabbing"
          }}
          onMouseUp={() => {
            console.log("mouse up")
            setMouseDown(false)
            // document.body.style.cursor = disableDrag ? "default" : "grabbing"
          }}
          onDragOver={() => {
            console.log("drag done")
            setMouseDown(false)
            // document.body.style.cursor = disableDrag ? "default" : "grabbing"
          }}
        >
          <div
            style={{
              background: value.activity.activityImageData
                ? "rgba(5,5,5,0.5)"
                : "rgba(74,74,74,0.8)",
              // padding: "10px",
              borderRadius: "0px 2px 2px 0px",
              // minHeight: "92px",
              height: "100%",
            }}
          >
            {/* <div className="vertical-line"></div> */}
            {value.activity.selected && (
              <div
                style={{
                  marginRight: "5px",
                  color: "#fec600",
                  position: "absolute",
                  right: "-30px",
                }}
              >
                <i class="fas fa-check"></i>
              </div>
            )}
            <div className="activity-card-data">
              {isSetAsBackup ? "" : returnFormattedTime()}
              <div
                className="activity-card-close"
                tabindex="0"
                onBlur={() => {
                  setToggleMenu(false)
                }}
                style={{
                  // background: "white",
                  // backgroundColor: "white",
                  outline: "none !important",
                }}
              >
                {canEdit && screenWidth < 1024 ? (
                  <div
                    class={`${
                      toggleMenu
                        ? "dropdown is-right is-active"
                        : "dropdown is-right"
                    }`}
                    style={{
                      // background: "white",
                      // backgroundColor: "white",
                      outline: "none !important",
                    }}
                  >
                    <div className="dropdown-trigger">
                      <a
                        className="is-text"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        onClick={() => {
                          setToggleMenu(!toggleMenu)
                        }}
                        onBlur={() => {
                          setToggleMenu(false)
                        }}
                        style={{
                          // background: "white",
                          // backgroundColor: "white",
                          outline: "none !important",
                        }}
                      >
                        {/* <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          letterSpacing: "0.5px",
                          textTransform: "uppercase",
                          textDecoration: "underline",
                          color: "#4a4a4a",
                          textDecorationColor: "#fec600",
                        }}
                      >
                        {toggleMenu ? "Close" : "Menu"}
                      </span> */}
                        {toggleMenu ? (
                          <span
                            className="icon is-small"
                            style={{ color: "#fec600" }}
                          >
                            <i className="fas fa-times"></i>
                          </span>
                        ) : (
                          <span
                            className="icon is-small"
                            style={{ color: "#fec600" }}
                          >
                            <i className="fas fa-ellipsis-h-alt"></i>
                          </span>
                        )}
                      </a>
                    </div>
                    <div
                      className="dropdown-menu"
                      id="dropdown-menu"
                      role="menu"
                      onBlur={() => {
                        setToggleMenu(false)
                      }}
                      style={{
                        background: "white",
                        backgroundColor: "white",
                        outline: "none !important",
                        border: "1px solid #eaeaea",
                        borderRadius: "4px",
                      }}
                    >
                      <div className="dropdown-content" id="dropdown-menu-item">
                        <a
                          className="is-small dropdown-item"
                          id={`Day:${value.dayId}-Item:${value.id}`}
                          onClick={e => {
                            setToggleMenu(false)
                            dispatch({
                              type: "prepareActivityScratchPad",
                              data: value.activity,
                            })
                            onEditActivity()
                          }}
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <i className="far fa-pencil delete-icon-color"> </i>
                          <p style={{ marginLeft: "7px" }}> Edit Activity</p>
                        </a>
                      </div>
                      <div className="dropdown-content" id="dropdown-menu-item">
                        <a
                          className="is-small dropdown-item"
                          id={`Day:${value.dayId}-Item:${value.id}`}
                          onClick={e => {
                            setToggleMenu(false)
                            // onMoveActivity(e.currentTarget.id)
                            onMoveActivity(value.dayId, value.id, itemNo)
                          }}
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <i className="far fa-arrow-right"> </i>
                          <p style={{ marginLeft: "7px" }}> Move Activity</p>
                        </a>
                      </div>
                      <div className="dropdown-content" id="dropdown-menu-item">
                        <a
                          className="is-small dropdown-item"
                          id={`Day:${value.dayId}-Item:${value.id}`}
                          onClick={e => {
                            setToggleMenu(false)

                            ConfirmationModal({
                              onConfirm: e => {
                                console.log(e, itemNo, value.dayId)

                                isSetAsBackup
                                  ? onRemoveBackup(itemNo, value.dayId)
                                  : onRemove(e)
                              },
                              sendBackValue: e.currentTarget.id,
                              title: "Confirmation",
                              text: value.activity.byot
                                ? `Are you sure you want to permanently delete the activity?`
                                : `Are you sure you want to remove the activity?`,
                            })
                          }}
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <i className="far fa-trash-alt delete-icon-color">
                            {" "}
                          </i>
                          {value.activity.byot ? (
                            <p style={{ marginLeft: "7px" }}>
                              {" "}
                              Delete Activity
                            </p>
                          ) : (
                            <p style={{ marginLeft: "7px" }}>
                              {" "}
                              Remove Activity
                            </p>
                          )}
                        </a>
                      </div>
                      {/* {isSetAsBackup ? (
                      <div className="dropdown-content" id="dropdown-menu-item">
                        <a
                          className="is-small dropdown-item"
                          // id={`Day:${value.dayId}-Item:${value.id}`}
                          id={itemNo}
                          onClick={e => {
                            setToggleMenu(false)
                            onMoveToActive(itemNo, value.dayId)
                          }}
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <i className="far fa-arrow-up"> </i>
                          <p style={{ marginLeft: "7px" }}>
                            {" "}
                            Set as Active Activity
                          </p>
                        </a>
                      </div>
                    ) : value.activity.byot ? (
                      ""
                    ) : (
                      <div className="dropdown-content" id="dropdown-menu-item">
                        <a
                          className="is-small dropdown-item"
                          id={`Day:${value.dayId}-Item:${value.id}`}
                          onClick={e => {
                            setToggleMenu(false)
                            onMoveToBackup(e)
                          }}
                          style={{ display: "flex", alignItems: "baseline" }}
                        >
                          <i className="far fa-arrow-down"> </i>
                          <p style={{ marginLeft: "7px" }}>
                            {" "}
                            Set as Backup Activity
                          </p>
                        </a>
                      </div>
                    )} */}
                    </div>
                  </div>
                ) : (
                  // <a
                  //   className="is-small"
                  //   id={`Day:${value.dayId}-Item:${value.id}`}
                  //   onClick={onRemove}
                  // >
                  //   <i className="far fa-trash-alt delete-icon-color"></i>
                  // </a>
                  ""
                )}
              </div>

              {canEdit ? (
                <>
                  <ReactTooltip
                    backgroundColor="#fafafa"
                    textColor="#4a4a4ad9"
                    borderColor="#fec600"
                    delayShow="500"
                    place="right"
                    id="card-menu"
                    effect="solid"
                  />
                  <div className="activity-card-movement-container">
                    <div
                      className="activity-card-move-up"
                      data-tip="Move Up"
                      data-for="card-menu"
                      style={{ color: itemNo === 0 ? "white" : "#fec600" }}
                      onClick={e => {
                        moveUp()
                        // setToggleMenu(false)
                        // dispatch({
                        //   type: "prepareActivityScratchPad",
                        //   data: value.activity,
                        // })
                        // onEditActivity()
                      }}
                    >
                      <i className="far fa-angle-up fa-lg"> </i>
                    </div>
                    <div
                      className="activity-card-move-down"
                      style={{
                        color: itemNo + 1 === length ? "white" : "#fec600",
                      }}
                      data-tip="Move Down"
                      data-for="card-menu"
                      onClick={e => {
                        moveDown()
                        // setToggleMenu(false)
                        // // onMoveActivity(e.currentTarget.id)
                        // onMoveActivity(value.dayId, value.id, itemNo)
                      }}
                    >
                      <i className="far fa-angle-down fa-lg"> </i>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {canEdit && screenWidth > 1024 ? (
                <>
                  <ReactTooltip
                    backgroundColor="#fafafa"
                    textColor="#4a4a4ad9"
                    borderColor="#fec600"
                    delayShow="500"
                    place="right"
                    id="card-menu"
                    effect="solid"
                  />
                  <div className="activity-card-menu-container">
                    <div
                      className="activity-card-menu-item"
                      data-tip="Edit Item"
                      data-for="card-menu"
                      onClick={e => {
                        setToggleMenu(false)
                        dispatch({
                          type: "prepareActivityScratchPad",
                          data: value.activity,
                        })
                        onEditActivity()
                      }}
                    >
                      <i className="fas fa-pencil"> </i>
                    </div>
                    <div
                      className="activity-card-menu-item"
                      style={{ top: "50px" }}
                      data-tip="Move Item"
                      data-for="card-menu"
                      onClick={e => {
                        setToggleMenu(false)
                        // onMoveActivity(e.currentTarget.id)
                        onMoveActivity(value.dayId, value.id, itemNo)
                      }}
                    >
                      <i className="fas fa-arrow-right"> </i>
                    </div>
                    <div
                      className="activity-card-menu-item"
                      id={`Day:${value.dayId}-Item:${value.id}`}
                      data-tip="Delete Item"
                      data-for="card-menu"
                      onClick={e => {
                        setToggleMenu(false)

                        ConfirmationModal({
                          onConfirm: e => {
                            console.log(e, itemNo, value.dayId)

                            isSetAsBackup
                              ? onRemoveBackup(itemNo, value.dayId)
                              : onRemove(e)
                          },
                          sendBackValue: e.currentTarget.id,
                          title: "Confirmation",
                          text: value.activity.byot
                            ? `Are you sure you want to permanently delete the activity?`
                            : `Are you sure you want to remove the activity?`,
                        })
                      }}
                    >
                      <i className="fas fa-trash-alt"> </i>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {/* <div className="activity-card-warnings">
              <a className="is-small">
                <i className="fas fa-exclamation-triangle warning-icon-color"></i>
              </a>
            </div> */}
              <div
                className="activity-card-name"
                style={{ color: "#fafafa", fontSize: "14px" }}
              >
                {dottedText(titleCase(value.activity.activityName), 33)}
              </div>
              {/* <div className="activity-card-type" style={{ fontSize: "14px" }}>
              <i
                className="fas fa-map-marker-alt"
                style={{ marginRight: "5px" }}
              ></i>
              <a
                // onClick={e => {
                //   e.preventDefault()
                //   var a =
                //   console.log("clicked")
                //   customNavigateTo(a)
                // }}
                href={googleMapsURI(value.activity.activityAddress)}
                target="_blank"
              >
                {value.activity.activityAddress}
              </a>
            </div>   */}
              <div
                className="activity-card-type tag is-warning"
                style={{
                  // marginTop: "-2px",
                  fontSize: "10px",
                  textTransform: "uppercase",
                  letterSpacing: "0.5px",
                }}
              >
                {titleCase(value.activity.activityType)}
              </div>
              {formatDuration() !== "" ? (
                <div
                  className="activity-card-type"
                  style={{ marginTop: "14px", fontSize: "12px" }}
                >
                  <i
                    style={{ color: "#fec600", fontSize: "12px" }}
                    className="fas fa-stopwatch"
                  ></i>
                  <span style={{ color: "#ffffff", fontSize: "12px" }}>
                    {formatDuration()}
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="activity-card-details">
                <a
                  style={{
                    paddingRight: "0px",
                    marginRight: "5px",
                    fontSize: "14px",
                    fontWeight: "500",
                    // letterSpacing: "0.5px",
                    // textTransform: "uppercase",
                    textDecoration: "underline",
                    color: "#fafafa",
                    textDecorationColor: "#fec600",
                  }}
                  // className="hover-link"
                  onClick={() => {
                    onClose()
                  }}
                >
                  View Details
                </a>
              </div>
            </div>
          </div>
        </div>
      )
    }

    const onCancel = () => {
      setLaunchEditForm(false)
    }
    const prepareAndLaunchEditForm = () => {
      dispatch({ type: "prepareActivityScratchPad", data: value.activity })
      return (
        <NewActivityForm
          onCancel={onCancel}
          inEditMode={true}
          activeTab={activeTab}
          addToItinerary={true}
        />
      )
    }
    const getFormattedAddressForIframe = () => {
      try {
        const gpsRegX = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
        var result = gpsRegX.exec(value.activity.activityAddress)
        console.log({ result })
        let a =
          result !== null
            ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
                value.activity.activityAddress
              )}`
            : `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
                value.activity.activityName
              )}+${encodeURI(value.activity.activityAddress)}`

        console.log({ a })
        return a
      } catch (err) {
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
          value.activity.activityName
        )}+${encodeURI(value.activity.activityAddress)}`
      }
    }

    const formatDuration = () => {
      try {
        var hours = value.activity.activityDurationHours
        var minutes = value.activity.activityDurationMinutes

        var hrs = hours === "1" ? "Hr" : "Hrs"
        if (hours === undefined && minutes === undefined) return ""
        if (
          hours === undefined ||
          hours === "" ||
          hours === " " ||
          hours === "0"
        ) {
          return ` ${minutes} Min`
        } else {
          if (
            minutes === undefined ||
            minutes === "" ||
            minutes === " " ||
            minutes === "0"
          )
            return ` ${hours} ${hrs}`
          else {
            return ` ${hours} ${hrs} & ${minutes} Min`
          }
        }
      } catch (error) {
        console.log(error)
        return ""
      }
    }
    const app_name = "travelPlanner"
    const showImage = (image, fullWidth, rounded) => {
      if (image && image.urls) {
        return (
          <figure class="image is-4x3">
            <div style={{ position: "relative" }}>
              <LazyLoadImage
                src={image.urls.regular}
                effect="blur"
                style={{
                  // height: fullWidth ? "auto" : "154px",
                  height: fullWidth ? "auto" : "148px",
                  width: fullWidth ? "" : "224px",
                  maxHeight: fullWidth ? "300px" : "148px",
                  objectFit: "cover",
                  borderRadius: rounded ? "4px" : "0px 0px 0px 0px",
                }}
                wrapperClassName="lazy-load-image-wrapper"
              ></LazyLoadImage>
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "15px",
                  fontSize: "8px",
                  color: "#4a4a4a",
                  backgroundColor: "rgba(256,256,256,0.8)",
                  padding: "2px 7px",
                  borderRadius: "2px",
                }}
              >
                <span>Photo by </span>
                <a
                  // href={`${data.response.user.links.html}?utm_source=${app_name}&utm_medium=referral`}
                  style={{ color: "#4a4a4a", textDecoration: "underline" }}
                  onClick={e => {
                    window.open(
                      `${image.user.profileURL}?utm_source=${app_name}&utm_medium=referral`,
                      "_blank"
                    )
                    e.stopPropagation()
                  }}
                >
                  {image.user.fName}
                </a>
              </div>
            </div>
          </figure>
        )
      } else {
        return (
          <figure class="image is-4x3">
            {/* <AsyncFetchImage
              imageId={image.imageId}
              rounded={rounded}
              fullWidth={fullWidth}
            /> */}
          </figure>
        )
      }
    }

    const showExpanded = (value, onClose, onEditActivity) => {
      return (
        <>
          {launchEditForm ? (
            prepareAndLaunchEditForm()
          ) : (
            <div
              tabindex="0"
              className="activity-card-type2-expanded"
              onBlur={() => {
                var marker = document.getElementById(`map-marker-${value.id}`)
                if (marker) {
                  marker.classList.remove("active-marker")
                }

                onClose()
              }}
            >
              <div
                className="welcome-close"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  var marker = document.getElementById(`map-marker-${value.id}`)
                  if (marker) {
                    marker.classList.remove("active-marker")
                  }
                  onClose()
                }}
              >
                <i className="fas fa-times-circle fa-2x"></i>
              </div>
              {/* <div
                className="activity-card-data-show-compact"
                onClick={() => onClose()}
              >
                <i className="far fa-times"></i>
              </div> */}
              <div className="activity-card-data">
                <div
                  className="activity-card-name"
                  style={{ fontSize: "22px" }}
                >
                  {titleCase(value.activity.activityName)}
                </div>
                {/* <div className="activity-card-type" style={{ marginTop: "8px" }}>
          <i className="fas fa-map-marker-alt" style={{ marginRight: "3px" }}></i>5
          Rue Lucien Sampaix, 75010 Paris, France
        </div> */}
                <div
                  className="activity-card-type"
                  style={{
                    marginTop: "5px",
                    fontSize: "12px",
                    fontWeight: "300",
                  }}
                >
                  <i
                    className="fas fa-map-marker-alt"
                    style={{ marginRight: "3px", color: "#fec600" }}
                  ></i>
                  <a
                    // onClick={e => {
                    //   e.preventDefault()
                    //   var a =
                    //   console.log("clicked")
                    //   customNavigateTo(a)
                    // }}
                    style={{
                      color: "#4a4a4a",
                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                    }}
                    href={googleMapsURI(value.activity.activityAddress)}
                    target="_blank"
                  >
                    {value.activity.activityAddress}
                  </a>
                </div>
                {value.activity.activityPhone !== undefined ? (
                  <div
                    className="activity-card-type"
                    style={{
                      marginTop: "0px",
                      fontSize: "12px",
                      fontWeight: "300",
                    }}
                  >
                    <i
                      className="fas fa-phone"
                      style={{ marginRight: "3px", color: "#fec600" }}
                    ></i>
                    <a
                      href={`tel:${value.activity.activityPhone}`}
                      style={{
                        color: "#4a4a4a",
                        textDecoration: "underline",
                        textDecorationColor: "#fec600",
                      }}
                    >
                      {value.activity.activityPhone}
                    </a>
                  </div>
                ) : (
                  ""
                )}
                {value.activity.activityType === "restaurant" &&
                value.activity.activityMenuURL ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i
                      className="fas fa-external-link-alt"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a href={value.activity.activityMenuURL} target="_blank">
                      Menu
                    </a>
                  </div>
                ) : (
                  " "
                )}
                {value.activity.activityType === "sightseeing"
                  ? value.activity.activityEntryFee && (
                      <div
                        className="activity-card-type"
                        style={{ marginTop: "8px" }}
                      >
                        Entry Fee (per person in USD):{" "}
                        {value.activity.activityEntryFee}
                      </div>
                    )
                  : " "}
                {formatDuration() !== "" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    <i
                      style={{
                        color: "#fec600",
                      }}
                      className="far fa-clock"
                    ></i>
                    {formatDuration()}
                  </div>
                ) : (
                  ""
                )}
                {/* {value.activity.activityType === "restaurant" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i className="fas fa-dollar-sign"></i>
                    <i className="fas fa-dollar-sign"></i>
                    <i className="fas fa-dollar-sign"></i>
                  </div>
                ) : (
                  " "
                )} */}
                <hr className="hr-branded"></hr>
                {value.activity.activityImageData ? (
                  showImage(value.activity.activityImageData, true, false)
                ) : (
                  <iframe
                    title="Paris"
                    // src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
                    //   value.activity.activityName
                    // )}+${encodeURI(value.activity.activityAddress)}`}

                    src={getFormattedAddressForIframe()}
                    width="100%"
                    height="200px"
                    allowFullScreen
                  ></iframe>
                )}

                <hr className="hr-branded"></hr>
                {/* <div className="activity-card-type" style={{ marginTop: "5px" }}>
          <i className="fas fa-phone" style={{ marginRight: "3px" }}></i>+33 1 82 28
          00 80
        </div> */}
                {value.activity.activityDescription && (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "16px", fontSize: "14px" }}
                  >
                    <strong>Description: </strong>
                    <p>{value.activity.activityDescription}</p>
                    {/* {ReactHtmlParser(value.activity.activityDescription, {
                    decodeEntities: false,
                  })} */}
                  </div>
                )}

                {value.activity.byot && value.activity.personalPoints && (
                  <>
                    {" "}
                    <div
                      className="activity-card-type"
                      style={{ marginTop: "16px", fontSize: "14px" }}
                    >
                      <strong>
                        <p>Your Notes:</p>
                      </strong>
                      <p>{value.activity.personalPoints}</p>
                    </div>
                    <hr className="hr-branded"></hr>
                  </>
                )}
                {!value.activity.byot && value.activity.personalPoints && (
                  <>
                    <div
                      className="activity-card-type"
                      style={{ marginTop: "16px", fontSize: "14px" }}
                    >
                      <strong>Personal info from your designer:</strong>
                      <p>{value.activity.personalPoints}</p>
                    </div>
                    <hr className="hr-branded"></hr>
                  </>
                )}
                {value.activity.activityURL1 && (
                  <>
                    {" "}
                    <div
                      className="activity-card-type"
                      style={{ marginTop: "16px", fontSize: "14px" }}
                    >
                      <strong>
                        <p>Additional Info:</p>
                      </strong>
                    </div>
                    <div
                      className="activity-card-type"
                      style={{ marginTop: "4px", fontSize: "14px" }}
                    >
                      <i
                        className="fas fa-external-link-alt"
                        style={{ marginRight: "5px" }}
                      ></i>
                      <a href={value.activity.activityURL1} target="_blank">
                        {dottedText(value.activity.activityURL1, 28)}
                      </a>
                    </div>
                    {value.activity.activityURL2 && (
                      <div
                        className="activity-card-type"
                        style={{ marginTop: "4px", fontSize: "14px" }}
                      >
                        <i
                          className="fas fa-external-link-alt"
                          style={{ marginRight: "5px" }}
                        ></i>
                        <a href={value.activity.activityURL2} target="_blank">
                          {dottedText(value.activity.activityURL2, 30)}
                        </a>
                      </div>
                    )}
                    {value.activity.activityURL3 && (
                      <div
                        className="activity-card-type"
                        style={{ marginTop: "4px", fontSize: "14px" }}
                      >
                        <i
                          className="fas fa-external-link-alt"
                          style={{ marginRight: "5px" }}
                        ></i>
                        <a href={value.activity.activityURL3} target="_blank">
                          {dottedText(value.activity.activityURL3, 30)}
                        </a>
                      </div>
                    )}
                    <hr className="hr-branded"></hr>
                  </>
                )}
                <div className="field is-grouped is-grouped-centered">
                  <p className="control">
                    {canEdit ? (
                      <button
                        className="button is-light is-small"
                        style={{
                          marginTop: "0px",
                          marginBottom: "20px",
                          marginRight: "10px",
                          width: "100px",
                        }}
                        onClick={() => {
                          //setLaunchEditForm(true)
                          dispatch({
                            type: "prepareActivityScratchPad",
                            data: value.activity,
                          })
                          onEditActivity()
                        }}
                      >
                        Edit
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      className="button is-warning is-small"
                      style={{
                        marginTop: "0px",
                        marginBottom: "20px",
                        marginLeft: "10px",
                        width: "100px",
                      }}
                      onClick={() => {
                        var marker = document.getElementById(
                          `map-marker-${value.id}`
                        )
                        if (marker) {
                          marker.classList.remove("active-marker")
                        }

                        onClose()
                      }}
                    >
                      Close
                    </button>
                  </p>
                </div>
              </div>
            </div>
          )}{" "}
        </>
      )
    }

    const LaunchGoogleDirections = e => {
      try {
        var results = parseDayAndItemFromString(e.currentTarget.id)
        if (results !== []) {
          var dayId = results[1]
          var itemId = results[2]
          var activitiesLen =
            state.currentJobInfo.designerWorkspace.itinerary[dayId].activities
              .length
          var selectedItemIndex = state.currentJobInfo.designerWorkspace.itinerary[
            dayId
          ].activities.findIndex(item => item.id === itemId)
          if (
            //filter out element not found, first element and last element cases
            selectedItemIndex !== -1 ||
            selectedItemIndex !== 0 ||
            selectedItemIndex !== activitiesLen - 1
          ) {
            console.log({ selectedItemIndex })
            var previousActivity =
              state.currentJobInfo.designerWorkspace.itinerary[dayId]
                .activities[selectedItemIndex - 1].activity
            var nextActivity =
              state.currentJobInfo.designerWorkspace.itinerary[dayId]
                .activities[selectedItemIndex + 1].activity

            console.log(
              previousActivity.activityName + previousActivity.activityAddress
            )
            console.log(
              nextActivity.activityName + nextActivity.activityAddress
            )

            const gpsRegX = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
            const gpsRegX2 = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
            var previousActivityResult = gpsRegX.exec(
              previousActivity.activityAddress
            )
            var nextActivityResult = gpsRegX2.exec(nextActivity.activityAddress)
            console.log({ previousActivityResult })
            console.log({ nextActivityResult })
            var directionUrl = googleDirectionsURI(
              previousActivityResult !== null
                ? ""
                : previousActivity.activityName,
              previousActivity.activityAddress,
              nextActivityResult !== null ? "" : nextActivity.activityName,
              nextActivity.activityAddress,
              value.activity.commuteMode
            )
            if (directionUrl !== "") return directionUrl
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    const displayRightIcons = value => {
      switch (value.activity.commuteMode) {
        case "walk":
        case "walking":
          return (
            <i
              className="fas fa-walking fa-lg"
              style={{
                marginRight: "33px",
                color: "rgba(51, 51, 51, 0.8)",
                float: "left",
                marginTop: "5px",
                marginLeft: "18px",
              }}
              id={`Day:${value.dayId}-Item:${value.id}`}
              onClick={e => {
                customNavigateTo(LaunchGoogleDirections(e))
              }}
            ></i>
          )
          break
        case "car":
        case "driving":
          return (
            <i
              className="fas fa-car fa-lg"
              style={{
                marginRight: "33px",
                color: "rgba(51, 51, 51, 0.8)",
                float: "left",
                marginTop: "5px",
                marginLeft: "15px",
              }}
              id={`Day:${value.dayId}-Item:${value.id}`}
              onClick={e => {
                customNavigateTo(LaunchGoogleDirections(e))
              }}
            ></i>
          )
          break
        case "train":
        case "transit":
          return (
            <i
              className="fas fa-subway fa-lg"
              style={{
                marginRight: "33px",
                color: "rgba(51, 51, 51, 0.8)",
                float: "left",
                marginTop: "5px",
                marginLeft: "10px",
              }}
              id={`Day:${value.dayId}-Item:${value.id}`}
              onClick={e => {
                customNavigateTo(LaunchGoogleDirections(e))
              }}
            ></i>
          )
          break

        case "bicycling":
          return (
            <i
              className="fas fa-biking fa-lg"
              style={{
                marginRight: "33px",
                color: "rgba(51, 51, 51, 0.8)",
                float: "left",
                marginTop: "5px",
                marginLeft: "10px",
              }}
              id={`Day:${value.dayId}-Item:${value.id}`}
              onClick={e => {
                customNavigateTo(LaunchGoogleDirections(e))
              }}
            ></i>
          )
          break

        default:
          break
      }
    }

    const checkCardType = value => {
      if (value.activity.activityType === "commute") {
        // if (
        //   value.activity.commuteMode === undefined ||
        //   value.activity.commuteMode === ""
        // ) {
        //   if (canEdit)
        //     return (
        //       <div className="activity-card-type-commute">
        //         {/* <div style={{ position: "absolute", left: "-25px" }}>
        //         <i
        //           data-tip="Commute needs to added between activities. If the activities are at the same property please set a minimal time."
        //           className="fal fa-info-square"
        //           style={{
        //             color: "rgba(78, 78, 78, 0.8)",
        //           }}
        //         ></i>
        //       </div> */}
        //         <div className="top-connector"></div>
        //         <button
        //           id={value.activity.activityUid}
        //           className="button is-warning"
        //           style={{ fontSize: "14px" }}
        //           onClick={e => {
        //             console.log("clicked")
        //             //onEditCommute()
        //             dispatch({
        //               type: "prepareActivityScratchPad",
        //               data: value.activity,
        //             })
        //             onAddCommute()
        //           }}
        //         >
        //           Add Commute
        //         </button>
        //         <div className="remove-add-commute-button">
        //           {canEdit ? (
        //             <a
        //               className="is-small"
        //               id={`Day:${value.dayId}-Item:${value.id}`}
        //               onClick={e => {
        //                 onRemove(e.currentTarget.id)
        //               }}
        //             >
        //               <i
        //                 className="far fa-trash-alt"
        //                 style={{
        //                   color: "rgba(78,78,78, 0.8)",
        //                 }}
        //                 data-tip={`Delete Commute. Note: You can only delete \"Add Commute\" blocks when there is more than one commute block between two activities`}
        //               ></i>
        //             </a>
        //           ) : (
        //             ""
        //           )}
        //         </div>
        //         <div className="bottom-connector"></div>
        //       </div>
        //     )
        //   else return ""
        // } else {
        return (
          <div className="activity-card-type-commute-active">
            <div className="vertical-line"></div>
            {/* <div className="top-connector"></div> */}
            <div className="commute-card-data">
              {formatDuration() !== "" ? (
                <div
                  className="activity-card-type"
                  style={{ marginTop: "5px" }}
                >
                  {displayRightIcons(value)}
                  <div
                    className="commute-time"
                    style={{
                      marginLeft: "70px",
                      marginRight: "10px",
                      marginTop: "8px",
                      // float: "",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="far fa-clock"
                      style={{ marginRight: "4px" }}
                    ></i>
                    <span style={{ fontSize: "14px", lineHeight: "25px" }}>
                      {formatDuration()}
                    </span>
                  </div>
                  <div
                    className="commute-card-close"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "8px",
                      color: "rgba(51, 51, 51, 0.8)",
                    }}
                  >
                    {canEdit ? (
                      <a
                        className="is-small"
                        id={`Day:${value.dayId}-Item:${value.id}`}
                        onClick={e => {
                          onRemove(e.currentTarget.id)
                        }}
                      >
                        <i
                          className="far fa-trash-alt"
                          style={{
                            color: "rgba(51, 51, 51, 0.8)",
                          }}
                        ></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="commute-card-close"
                    style={{
                      position: "absolute",
                      right: "35px",
                      top: "8px",
                      color: "rgba(51, 51, 51, 0.8)",
                    }}
                  >
                    {canEdit ? (
                      <a
                        className="is-small"
                        id={`Day:${value.dayId}-Item:${value.id}`}
                        onClick={() => {
                          dispatch({
                            type: "prepareActivityScratchPad",
                            data: value.activity,
                          })
                          onAddCommute()
                        }}
                      >
                        {" "}
                        <span
                          style={{
                            letterSpacing: "0.5px",
                            fontSize: "12px",
                            textTransform: "uppercase",
                          }}
                        >
                          Edit
                        </span>
                        {/* <i
                            className="fas fa-edit delete-icon-color"
                            style={{
                              color: "rgba(51, 51, 51, 0.8)",
                            }}
                          ></i> */}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  {canEdit ? (
                    <div
                      className="commute-card-close"
                      style={{
                        position: "absolute",
                        right: "73px",
                        top: "8px",
                        color: "rgba(51, 51, 51, 0.8)",
                      }}
                    >
                      <a
                        className="is-small"
                        id={`Day:${value.dayId}-Item:${value.id}`}
                        onClick={e => {
                          customNavigateTo(LaunchGoogleDirections(e))
                        }}
                      >
                        <span
                          style={{
                            letterSpacing: "0.5px",
                            fontSize: "12px",
                            textTransform: "uppercase",
                            textDecoration: "underline",
                            color: "#4a4a4a",
                            textDecorationColor: "#fec600",
                          }}
                        >
                          Map
                        </span>
                        {/* <i
                            className="fal fa-directions delete-icon-color fa-lg"
                            style={{
                              color: "rgba(51, 51, 51, 0.8)",
                            }}
                          ></i> */}
                      </a>
                    </div>
                  ) : (
                    <div
                      className="commute-card-close"
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "8px",
                        color: "rgba(78, 78, 78, 0.8)",
                      }}
                    >
                      <a
                        className="is-small"
                        style={{ color: "rgba(78, 78, 78,1)" }}
                        id={`Day:${value.dayId}-Item:${value.id}`}
                        onClick={e => {
                          customNavigateTo(LaunchGoogleDirections(e))
                        }}
                      >
                        <span
                          style={{
                            letterSpacing: "0.5px",
                            fontSize: "12px",
                            textTransform: "uppercase",
                            textDecoration: "underline",
                            color: "#4a4a4a",
                            textDecorationColor: "#fec600",
                            fontWeight: "500",
                          }}
                        >
                          Map
                        </span>
                        {/* <i
                            className="fal fa-directions delete-icon-color fa-lg"
                            style={{
                              color: "rgba(51, 51, 51, 0.8)",
                            }}
                          ></i> */}
                      </a>
                    </div>
                  )}
                  {canEdit
                    ? value.activity.commuteAdditionalInfo && (
                        <div
                          className="commute-card-close"
                          style={{
                            position: "absolute",
                            right: "110px",
                            top: "8px",
                            color: "rgba(51, 51, 51, 0.8)",
                          }}
                        >
                          <a
                            className="is-small"
                            id={`Day:${value.dayId}-Item:${value.id}`}
                            onClick={e => {
                              console.log({ value })
                              NotificationModal({
                                title: "",
                                text: `${value.activity.commuteAdditionalInfo}`,
                                onClose: () => {},
                              })
                              // customNavigateTo(LaunchGoogleDirections(e))
                            }}
                          >
                            <span
                              style={{
                                letterSpacing: "0.5px",
                                fontSize: "12px",
                                textTransform: "uppercase",
                              }}
                            >
                              Info
                            </span>
                            {/* <i
                            className="fal fa-directions delete-icon-color fa-lg"
                            style={{
                              color: "rgba(51, 51, 51, 0.8)",
                            }}
                          ></i> */}
                          </a>
                        </div>
                      )
                    : value.activity.commuteAdditionalInfo && (
                        <div
                          className="commute-card-close"
                          style={{
                            position: "absolute",
                            right: "55px",
                            top: "8px",
                            color: "rgba(51, 51, 51, 0.8)",
                          }}
                        >
                          <a
                            className="is-small"
                            id={`Day:${value.dayId}-Item:${value.id}`}
                            onClick={e => {
                              console.log({ value })
                              NotificationModal({
                                title: "",
                                text: `${value.activity.commuteAdditionalInfo}`,
                                onClose: () => {},
                              })
                              // customNavigateTo(LaunchGoogleDirections(e))
                            }}
                          >
                            <span
                              style={{
                                letterSpacing: "0.5px",
                                fontSize: "12px",
                                textTransform: "uppercase",
                              }}
                            >
                              Info
                            </span>
                            {/* <i
                            className="fal fa-directions delete-icon-color fa-lg"
                            style={{
                              color: "rgba(51, 51, 51, 0.8)",
                            }}
                          ></i> */}
                          </a>
                        </div>
                      )}
                </div>
              ) : (
                ""
              )}
            </div>

            {/* <div className="bottom-connector"></div> */}
          </div>
        )
        // }
      }
    }

    return (
      <div
        id={`card-${value.id}`}
        // style={
        //   isSetAsBackup
        //     ? { margin: "10px 0px !important" }
        //     : { margin: "0px 0px" }
        // }
      >
        {/* <DragHandle /> */}
        {/* <ReactTooltip backgroundColor="#fec600" textColor="#4a4a4a" /> */}
        {type === "commute" ? (
          checkCardType(value)
        ) : (
          <div>
            {showMoreInfo
              ? showExpanded(value, onClose, onEditActivity)
              : showCompact(
                  length,
                  itemNo,
                  value,
                  onClose,
                  onRemove,
                  onMoveToBackup,
                  onEditActivity,
                  onSelect
                )}
          </div>
        )}
      </div>
    )
  }
)
