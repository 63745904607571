import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react"
import { useAsync } from "react-async"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { functions } from "../firebase/firebase"
import { ShimmerThumbnail } from "react-shimmer-effects"

const getPictureFromId = async ({ ctx }) => {
  try {
    console.log("getPictureFromId called")
    // setShowPlaceLoading(true)
    let data = {
      id: ctx,
    }
    if (ctx) {
      var getPictureFromId = functions.httpsCallable("getPictureFromId")
      const result = await getPictureFromId(data)
      if (result && result.data) {
        return result.data
        // let attributionUrl = `${result.data.response.user.links.html}?utm_source=${app_name}&utm_medium=referral`

        // console.log(result.data.response.urls.regular)
        // console.log(attributionUrl)
      }
    }
  } catch (err) {
    console.log(err)
  }
}

const AsyncFetchImage = React.memo(({ imageId, rounded, fullWidth }) => {
  let ctx = imageId
  const app_name = "travelPlanner"
  const { data, error } = useAsync({ promiseFn: getPictureFromId, ctx: ctx })

  if (error) return "Error"

  if (data) {
    console.log({ data })
    return (
      <div style={{ position: "relative" }}>
        <LazyLoadImage
          src={data.response.urls.regular}
          effect="blur"
          style={{
            height: fullWidth ? "auto" : "154px",
            // width: "315px",
            objectFit: "cover",
            borderRadius: rounded ? "14px 14px 0px 0px" : "0px 0px 0px 0px",
          }}
        ></LazyLoadImage>
        <div
          style={{
            position: "absolute",
            right: "10px",
            bottom: "15px",
            fontSize: "10px",
            color: "#4a4a4a",
            backgroundColor: "rgba(256,256,256,0.8)",
            padding: "2px 7px",
            borderRadius: "2px",
          }}
        >
          <span>Photo by </span>
          <a
            // href={`${data.response.user.links.html}?utm_source=${app_name}&utm_medium=referral`}
            style={{ color: "#4a4a4a", textDecoration: "underline" }}
            onClick={e => {
              window.open(
                `${data.response.user.links.html}?utm_source=${app_name}&utm_medium=referral`,
                "_blank"
              )
              e.stopPropagation()
            }}
          >
            {data.response.user.first_name}
          </a>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        {" "}
        {!fullWidth && (
          <ShimmerThumbnail
            height={154}
            width={205}
            rounded
            className="remove-bottom-margin"
            // style={{ marginBottom: "0px" }}
          />
        )}
      </div>
    )
  }
})
export default AsyncFetchImage
