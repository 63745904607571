import { dbUpdate } from "../../utility/dbCRUD"
import React, { useState, useEffect, useContext } from "react"
import "./EditableItems.scss"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"
import { titleCase } from "../../utility/general"

const EditableNameField = nameObject => {
  const [inEditMode, setEditMode] = useState({
    fullName: false,
    address: false,
  })
  const [userName, setUserName] = useState({ firstName: "", lastName: "" })

  const [fullName, setFullName] = useState("")

  const [userNameTemp, setUserNameTemp] = useState({
    firstName: "",
    lastName: "",
  })

  const authUser = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)

  useEffect(() => {
    console.log({ userName })
    const { firstName, lastName } = userName
    if (firstName + lastName === "") setFullName("(not set)")
    else setFullName(titleCase(firstName) + " " + titleCase(lastName))
  }, [userName])

  useEffect(() => {
    console.log({ nameObject })
    const _uName = {
      firstName: nameObject.nameObject.firstName,
      lastName: nameObject.nameObject.lastName,
    }
    setUserName(_uName)
    setUserNameTemp(_uName)
  }, [nameObject])

  const handleSave = e => {
    switch (e.target.name) {
      case "fullNameSave":
        setUserName(userNameTemp)
        setUserNameTemp(userNameTemp)
        dbUpdate(dbContext, "users", authUser.uid, userNameTemp)
        dbContext
          .firestore()
          .collection("users")
          .doc(`${authUser.uid}`)
          .collection("publicProfile")
          .doc(`${authUser.uid}`)
          .update(userNameTemp)
          .then(function() {
            console.log("Document successfully updated!")
          })
          .catch(error => {
            console.log("Error getting document:", error)
          })
        // Push to firestore.
        setEditMode({ fullName: false })
        break
      default:
        break
    }
  }
  return (
    <>
      {!inEditMode.fullName ? (
        <>
          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">Full Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">{fullName}</p>
              </div>

              <div className="control">
                <a
                  //className="is-pulled-right"
                  onClick={() => setEditMode({ fullName: true })}
                >
                  Edit
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="field">
            <label className="label">First Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder=""
                value={userNameTemp.firstName}
                onChange={e => {
                  setUserNameTemp({
                    ...userNameTemp,
                    firstName: e.target.value,
                  })
                }}
                name="firstName"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Last Name</label>
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder=""
                value={userNameTemp.lastName}
                onChange={e => {
                  setUserNameTemp({ ...userNameTemp, lastName: e.target.value })
                }}
                name="lastName"
              />
            </div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                name="fullNameSave"
                className="button is-warning"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
            <div className="control">
              <button
                className="button is-text"
                onClick={() => setEditMode({ fullName: false })}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default EditableNameField
