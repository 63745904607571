import React, { useContext, useEffect } from "react"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import swal from "sweetalert"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { FirebaseContext } from "../../../components/firebase"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import { getLabelValue } from "../../../utility/general"

const DisplayField = ({ labelText, data, setBgColor }) => {
  return (
    <div className="columns">
      <div className="column is-0 is-hidden-mobile"></div>
      <div className="column is-3 padding-bottom-0">
        {" "}
        <label className="label">{labelText}</label>
      </div>
      <div className="column is-7 padding-top-0">
        {" "}
        <p>{data}</p>
      </div>
      <div className="column is-1 is-0-mobile is-hidden-mobile"></div>
    </div>
    //   <div
    //     className={`field is-horizontal ${setBgColor}
    // `}
    //   >
    //     <div className="my-custom-class">
    //       <div className="field-label">

    //       </div>
    //     </div>
    //     <div className="field-body">
    //       <div className="field">
    //         <div className="control is-expanded margin-right-20px">

    //         </div>
    //       </div>
    //     </div>
    //   </div>
  )
}

const DisplayTravelerDetails = () => {
  const [state, dispatch] = useDashboardStateStore()

  useEffect(() => {
    console.log({ state })
  }, [state])
  const populateTable = () => {
    return state.tripDetails.travelerDetails.map(detail => (
      <Tr key={detail.index}>
        <Td className="" style={{ marginTop: "10px" }}>
          {detail.firstName + " " + detail.lastName}
        </Td>
        <Td className="">{detail.gender || "--"}</Td>
        <Td className="">{detail.age || "--"}</Td>
        <Td className="">{detail.diet || "--"}</Td>
        <Td className="">{detail.wheelchair || "--"}</Td>
        <Td id={detail.index} className="">
          <div
            id={detail.index}
            onClick={e =>
              swal({
                title: "Additional Details",
                text: `${detail.additionalTravelerDetails ||
                  "No additional details"}`,
                // icon: "info",
                button: "close",
              })
            }
          >
            click for details
          </div>
        </Td>
        <Td></Td>
      </Tr>
    ))
  }
  return (
    <Table>
      <Thead>
        <Tr>
          <Th className="table-header">Name</Th>
          <Th className="table-header">Gender</Th>
          <Th className="table-header">Age</Th>
          <Th className="table-header">Diet</Th>
          <Th className="table-header">Wheelchair</Th>
          <Th className="table-header">Additional Details</Th>
        </Tr>
      </Thead>
      <Tbody>{populateTable()}</Tbody>
    </Table>
  )
}

export const TripDetails = ({ onClose }) => {
  const [state, dispatch] = useDashboardStateStore()
  const dbContext = useContext(FirebaseContext)

  const getDates = data => {
    try {
      return `${data.tripLogistics.travelDates.start
        .toDate()
        .toLocaleDateString()} - ${data.tripLogistics.travelDates.end
        .toDate()
        .toLocaleDateString()}`
    } catch (e) {
      console.log({ e })
      return `${data.tripLogistics.travelDates.start.toLocaleDateString()} - ${data.tripLogistics.travelDates.end.toLocaleDateString()}`
    }
  }

  const getLabelValues = data => {
    try {
      let a = []
      data.map(d => {
        if (d.label === "") {
        } else {
          a.push(d.label)
        }
      })
      console.log({ a })
      return a == [] ? "- Not Specified -" : a
    } catch (e) {
      console.log({ e })
      return "--"
    }
  }
  const getPlaces = data => {
    try {
      let a = []
      data.map(d => {
        if (d.label === "") {
        } else {
          a.push(d.label)
        }
      })
      console.log({ a })
      return a
    } catch (e) {
      console.log({ e })
      return "--"
    }
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })

  const showTripDestinations = () => {
    if (state.tripDetails.tripDestinations) {
      var destinationString = ""
      state.tripDetails.tripDestinations.map((item, index) => {
        console.log(state.tripDetails.tripDestinations.length, index)
        destinationString =
          destinationString +
          item.place +
          "-" +
          item.country +
          (state.tripDetails.tripDestinations.length === index + 1 ? "" : ", ")
      })
      return destinationString
    }
  }

  return (
    <>
      <h3
        style={{ marginTop: "26px" }}
        className="subtitle is-4"
        style={{ borderLeft: "4px solid #fec600", padding: "10px" }}
      >
        {" "}
        Trip Logistics
      </h3>
      {/* <hr className="hr-branded"></hr> */}
      <progress
        className="progress is-warning is-thin"
        value={state.wizardProgress}
        max="100"
      >
        25%
      </progress>
      <div className="columns is-centered branded-background">
        {/* <div className="column is-three-quarters"> */}
        <div className="column">
          {/* <DisplayField
            labelText="Trip name:"
            data={state.tripDetails.tripName}
          /> */}
          <DisplayField
            labelText="Trip type:"
            data={
              state.tripLogistics
                ? getLabelValue(state.tripLogistics.tripType)
                : "--"
            }
          />
          <DisplayField
            labelText="Destination:"
            data={showTripDestinations()}
            setBgColor="toggle-field-background"
          />
          {/* <DisplayField
            labelText="Purpose of the trip:"
            data={
              state.tripDetails.tripLogistics !== undefined
                ? state.tripDetails.tripLogistics.tripsPurpose
                : ""
            }
          /> */}
          <DisplayField
            labelText="Scheduled travel dates:"
            data={getDates()}
            setBgColor="toggle-field-background"
          />
          {/* <DisplayField
            labelText="Travel dates flexible:"
            data={
              state.tripDetails.tripLogistics !== undefined
                ? state.tripDetails.tripLogistics.travelDatesFlexible
                  ? "Yes"
                  : "No"
                : ""
            }
          /> */}
          <DisplayField
            labelText="Total budget:"
            data={`${formatter.format(
              state.tripDetails.tripLogistics.totalTripBudgetRange.minBudget
            )} - ${formatter.format(
              state.tripDetails.tripLogistics.totalTripBudgetRange.maxBudget
            )}`}
            setBgColor="toggle-field-background"
          />
          <DisplayField
            labelText="Accommodation preference:"
            data={getLabelValues(
              state.tripDetails.tripLogistics.accommodationPreference
            ).join(", ")}
          />
        </div>
      </div>

      <h3
        style={{ marginTop: "26px" }}
        className="subtitle is-4"
        style={{ borderLeft: "4px solid #fec600", padding: "10px" }}
      >
        Traveler Details
      </h3>
      <hr className="hr-branded"></hr>
      <div className="columns is-centered branded-background">
        {/* <div className="column is-three-quarters"> */}
        <div className="column">
          <DisplayTravelerDetails />
        </div>
      </div>

      <h3
        style={{ marginTop: "26px" }}
        className="subtitle is-4"
        style={{ borderLeft: "4px solid #fec600", padding: "10px" }}
      >
        Traveler Preferences
      </h3>
      <hr className="hr-branded"></hr>
      <div className="columns is-centered branded-background">
        {/* <div className="column is-three-quarters"> */}
        <div className="column">
          <DisplayField
            labelText="Vacation pacing:"
            data={state.tripDetails.tripPreferences.pacing}
          />
          {/* <DisplayField
            labelText="Food preferences:"
            data={getLabelValues(
              state.tripDetails.tripPreferences.foodPreferences
            ).join(", ")}
          />
          <DisplayField
            labelText="Preferred cuisines:"
            data={getLabelValues(
              state.tripDetails.tripPreferences.preferredCuisines
            ).join(", ")}
            setBgColor="toggle-field-background"
          />
          <DisplayField
            labelText="Drink preferences:"
            data={getLabelValues(
              state.tripDetails.tripPreferences.drinkPreferences
            ).join(", ")}
          /> */}
          <DisplayField
            labelText="Preferred vacation activities:"
            data={getLabelValues(
              state.tripDetails.tripPreferences.preferredActivities
            ).join(", ")}
            setBgColor="toggle-field-background"
          />
          <DisplayField
            labelText="Additional details:"
            data={
              state.tripDetails.tripPreferences.otherTripPreferenceDetails ===
              ""
                ? "- None -"
                : state.tripDetails.tripPreferences.otherTripPreferenceDetails
            }
          />
        </div>
      </div>
      <hr className="hr-branded"></hr>
      {/* <h3
        style={{ marginTop: "26px" }}
        className="subtitle is-4"
        style={{ borderLeft: "4px solid #fec600", padding: "10px" }}
      >
        Friends & Family Recommendations
      </h3>
      <hr className="hr-branded"></hr>
      <div className="columns is-centered branded-background"></div> */}
    </>
  )
}
