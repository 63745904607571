import loadable from "@loadable/component"
import { navigate } from "gatsby"
import { constant, countBy, range, times } from "lodash"
import moment from "moment"
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Dot,
  Slide,
  Slider,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
// import Map from "../../components/Map/Map"
import Calendar from "react-calendar"
import ReactHtmlParser from "react-html-parser"
import Carousel, { Modal, ModalGateway } from "react-images"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-quill/dist/quill.snow.css"
import Select from "react-select"
import { ToastProvider } from "react-toast-notifications"
import {
  countryCode,
  countryListValueLabel,
  designerSkills,
  languages,
} from "../../constants/data/general"
import {
  ProfileStateProvider,
  useProfileStateStore,
} from "../../State/profile-state/profile-state"
import { titleCase } from "../../utility/general"
import BlogPostCard from "../BlogPostCards/BlogPostCard"
import EditableProfileImageField from "../EditableFormComponents/EditableProfileImageField"
import ReviewCards from "../ReviewCards/ReviewCards"
import AuthUserContext from "../session/context"
import ShowLoading from "../ShowLoading/ShowLoading"
import SkillBadge from "../SkillBadge/SkillBadge"
import StarRating from "../StarRating/StarRating"
import * as ROUTES from "./../../constants/routes"
import empty from "./../../images/empty.svg"
import { FirebaseContext } from "./../firebase"
import "./ProfilePage.scss"

const LazyMap = loadable(() => import("../../components/Map/Map"))
const ReactQuill = loadable(() => import("react-quill"))
const ProfilePageBase = () => {
  const userAuthContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [state, dispatch] = useProfileStateStore()
  const [loading, setLoading] = useState(true)
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [sessionRole, setSessionRole] = useState("traveler")
  const [placeValue, setPlaceValue] = useState({
    inputValue: "",
    value: [""],
  })
  const maxSkillsAllowed = 7
  const [maxSkillsError, setMaxSkillsError] = useState(false)
  const [introductionHtmlLen, setIntroductionHtmlLen] = useState(0)
  var introductionRef = useRef(null)
  const maxIntroductionHtmlLen = 701
  const [
    reachedAboutIntroductionLen,
    setReachedAboutIntroductionLen,
  ] = useState(false)
  const [skillsHtmlLen, setSkillsHtmlLen] = useState(0)
  var skillsRef = useRef(null)
  const maxSkillsHtmlLen = 701
  const [reachedSkillsLen, setReachedSkillsLen] = useState(false)
  const MAXPOSTSALLOWED = 5
  let values = times(MAXPOSTSALLOWED, constant(false))
  const maxCountriesAllowed = 10
  const [blogPostError, setBlogPostError] = useState(values)
  useEffect(() => {
    document.title = "My Profile | voyayge"

    if (dbContext) {
      dbContext
        .firestore()
        .collection("users")
        .doc(`${userAuthContext.uid}`)
        .collection("publicProfile")
        .doc(`${userAuthContext.uid}`)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            console.log(doc.data())
            dispatch({ type: "updateInitialState", data: doc.data() })
            dbContext
              .firestore()
              .collection("users")
              .doc(`${userAuthContext.uid}`)
              .collection("publicProfile")
              .doc(`${userAuthContext.uid}`)
              .collection("reviews")
              .orderBy("reviewDate", "desc")
              .limit(5)
              .get()
              .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, " => ", doc.data())
                  dispatch({ type: "updateReviewList", data: doc.data() })
                })
                setLoading(false)
              })
              .catch(function(error) {
                console.log("Error getting documents: ", error)
              })
          } else {
            console.log("No such document!")
            setLoading(false)
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error)
        })
    }

    return () => {}
  }, [dbContext])

  useEffect(() => {
    console.log({ blogPostError })
  }, [blogPostError])

  const updateDBWithAvailability = newVal => {
    if (dbContext) {
      dbContext
        .firestore()
        .collection("users")
        .doc(`${userAuthContext.uid}`)
        .collection("publicProfile")
        .doc(`${userAuthContext.uid}`)
        .update({
          available: newVal,
          backInDays: "unspecified",
        })
        .then(() => {
          console.log("availability updated")
        })
        .catch(function(error) {
          console.log("Error getting document:", error)
        })
    }
  }
  const updateDBWithBackInDays = newVal => {
    if (dbContext) {
      dbContext
        .firestore()
        .collection("users")
        .doc(`${userAuthContext.uid}`)
        .collection("publicProfile")
        .doc(`${userAuthContext.uid}`)
        .update({
          backInDays: newVal,
        })
        .then(() => {
          console.log("backInDays updated")
        })
        .catch(function(error) {
          console.log("Error getting document:", error)
        })
    }
  }

  useEffect(() => {
    console.log("%c Profile-State", "color: #FC440F; font-size: 18px", {
      state,
    })
    if (state.tempPublicProfile.tags) {
      let a = []
      state.tempPublicProfile.tags.map(value => {
        if (value !== undefined) a.push(createOption(value))
      })
      setPlaceValue({ inputValue: "", value: a })
    }
    if (introductionRef) {
      try {
        const editor = introductionRef.getEditor()
        const quill = introductionRef.makeUnprivilegedEditor(editor)
        let len = quill.getLength()
        setIntroductionHtmlLen(len)
        if (len < maxIntroductionHtmlLen) setReachedAboutIntroductionLen(false)
      } catch (err) {}
    }
    if (skillsRef) {
      try {
        const editor = skillsRef.getEditor()
        const quill = skillsRef.makeUnprivilegedEditor(editor)
        let len = quill.getLength()
        setSkillsHtmlLen(len)
        if (len < maxSkillsHtmlLen) setReachedSkillsLen(false)
      } catch (err) {}
    }
    // fetchUserProfileImage()
  }, [state])

  useEffect(() => {
    var role = JSON.parse(localStorage.getItem("sessionType"))
    // console.log({ role })
    setSessionRole(role.sessionType)
    // fetchUserProfileImage()

    return () => {}
  }, [])

  // useEffect(() => {
  //   var a = state.publicProfile.available
  //   console.log("%c", "color: #FC440F; font-size: 18px", {
  //     a,
  //   })
  //   updateDBWithAvailability()
  // }, [state.publicProfile.available, dbContext])
  const handleIntroductionHtml = async html => {
    try {
      if (introductionRef) {
        const editor = introductionRef.getEditor()
        const quill = introductionRef.makeUnprivilegedEditor(editor)
        console.log(quill.getLength())
        if (quill.getLength() > maxIntroductionHtmlLen) {
          setReachedAboutIntroductionLen(true)
          editor.deleteText(maxIntroductionHtmlLen, quill.getLength())
        } else {
          dispatch({
            type: "updateAboutIntroduction",
            data: html,
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const handleSkillsHtml = async html => {
    try {
      if (skillsRef) {
        const editor = skillsRef.getEditor()
        const quill = skillsRef.makeUnprivilegedEditor(editor)

        if (quill.getLength() > maxSkillsHtmlLen) {
          setReachedSkillsLen(true)
          editor.deleteText(maxSkillsHtmlLen, quill.getLength())
        } else {
          dispatch({
            type: "updateAboutSkills",
            data: html,
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getCodes = data => {
    try {
      let codes = []
      if (data) {
        const a = data
        a.map((item, index) => {
          console.log(item)
          const _foundItem = countryCode.find(
            element => element.Name === item.label.trim()
          )
          console.log(_foundItem)
          if (_foundItem !== -1)
            codes = { ...codes, ...{ [_foundItem.Code]: 0 } }
        })
        console.log({ codes })
        return codes
      } else return
    } catch (err) {
      console.log(err)
    }
  }

  const createOption = label => ({
    label,
    value: label,
  })

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const handleChange = (value, actionMeta) => {
    console.log(value, actionMeta)
    setPlaceValue({ ...placeValue, value: value })
  }

  const handleInputChange = inputValue => {
    setPlaceValue({ ...placeValue, inputValue: inputValue })
  }
  const handleKeyDown = event => {
    const { inputValue, value } = placeValue
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        setPlaceValue({
          ...placeValue,
          inputValue: "",
          value: [...(value || []), createOption(inputValue)],
        })
        event.preventDefault()
        return

      default:
        return
    }
  }
  const renderNameField = () => {
    if (state.editMode) {
    } else {
      return (
        <>
          {titleCase(state.publicProfile.firstName) +
            " " +
            titleCase(state.publicProfile.lastName)}
        </>
      )
    }
  }

  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        // { list: "ordered" },
        //{ list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      // ["link", "image"],
      // ["clean"],
    ],
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    //  "strike",
    "blockquote",
    // "list",
    // "bullet",
    // "indent",
    // "link",
    // "image",
  ]

  const getBlogPostURLValue = index => {
    try {
      let v = state.tempPublicProfile.blogPostURL[index]
      return v
    } catch (error) {
      return ""
    }
  }

  const notErrorsFound = () => {
    //Check blog posts errors
    let result = blogPostError.every(v => v === false)
    return result
  }

  const renderAboutField = () => {
    if (state.editMode) {
      return (
        <>
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">First name</label>
                <div className="control">
                  <input
                    onChange={e =>
                      dispatch({
                        type: "updateFirstName",
                        data: e.currentTarget.value,
                      })
                    }
                    className="input"
                    type="text"
                    placeholder="Text input"
                    value={
                      state.tempPublicProfile.firstName
                        ? state.tempPublicProfile.firstName
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">Last name</label>
                <div className="control">
                  <input
                    onChange={e =>
                      dispatch({
                        type: "updateLastName",
                        data: e.currentTarget.value,
                      })
                    }
                    className="input"
                    type="text"
                    placeholder="Text input"
                    value={
                      state.tempPublicProfile.lastName
                        ? state.tempPublicProfile.lastName
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">Location</label>
                <input
                    onChange={e =>
                      dispatch({
                        type: "updateFirstName",
                        data: e.currentTarget.value,
                      })
                    }
                    className="input"
                    type="text"
                    placeholder="Text input"
                    value={
                      state.tempPublicProfile.firstName
                        ? state.tempPublicProfile.firstName
                        : ""
                    }
                  />
              </div>
            </div>
          </div> */}
          {/* // this will be computed rather than user entering it. */}
          {sessionRole === "designer" && (
            <div className="field">
              <label className="label">Skills</label>
              <div className="control is-expanded">
                <Select
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                  options={designerSkills}
                  menuPortalTarget={document.body}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isSearchable="true"
                  isClearable="true"
                  isMulti
                  onBlur={e => {}}
                  value={state.tempPublicProfile.skills}
                  // onKeyDown={handleKeyDownSkills}
                  onChange={(value, actionMeta) => {
                    if (value && value.length > maxSkillsAllowed) {
                      // Only upto maxSkillsAllowed are allowed
                      return
                    }
                    // if (value)
                    dispatch({
                      type: "updateDesignerSkills",
                      data: value ? Object.values(value) : null,
                    })
                  }}
                  // onInputChange={handleInputChange}
                  // onKeyDown={handleKeyDown}
                  placeholder="Pick you top five skills"
                  defaultValue={state.tempPublicProfile.skills}
                  // value={designerSkills.value}
                />
              </div>
              <p className="help">
                Pick your top skills to identify your strengths! ({" "}
                <strong>Note:</strong> A maximum of 7 skills allowed )
              </p>
            </div>
          )}

          <hr className="hr-branded"></hr>
          <div className="field">
            <label className="label">
              Pick all the countries you have traveled to:
            </label>
            <div className="control">
              <Select
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                defaultValue={state.tempPublicProfile.countriesVisited}
                menuPortalTarget={document.body}
                className="basic-multi-select"
                classNamePrefix="select"
                isSearchable="true"
                isClearable="true"
                isMulti
                onChange={(value, actionMeta) => {
                  if (value)
                    dispatch({
                      type: "updateCountriesTraveled",
                      data: Object.values(value),
                    })
                }}
                options={countryListValueLabel}
              />
            </div>
          </div>

          <hr className="hr-branded"></hr>
          <div className="field">
            <label className="label">
              Pick all the languages you can speak:
            </label>
            <div className="control">
              <Select
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                defaultValue={state.tempPublicProfile.languagesSpoken}
                menuPortalTarget={document.body}
                className="basic-multi-select"
                classNamePrefix="select"
                isSearchable="true"
                isClearable="true"
                isMulti
                onChange={(value, actionMeta) => {
                  if (value)
                    dispatch({
                      type: "updateLanguagesSpoken",
                      data: Object.values(value),
                    })
                }}
                options={languages}
              />
            </div>
          </div>
          {sessionRole === "designer" && (
            <>
              <hr className="hr-branded"></hr>
              <div className="field">
                <label className="label">
                  Pick all the countries you are comfortable building travel
                  itineraries for:
                </label>
                <div className="control">
                  <Select
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    defaultValue={
                      state.tempPublicProfile.countriesForItineraries
                    }
                    menuPortalTarget={document.body}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable="true"
                    isClearable="true"
                    isMulti
                    onChange={(value, actionMeta) => {
                      if (value)
                        dispatch({
                          type: "updateCountriesForItineraries",
                          data: Object.values(value),
                        })
                    }}
                    options={countryListValueLabel}
                  />
                </div>
              </div>
              <hr className="hr-branded"></hr>
            </>
          )}

          <div className="field" style={{ marginBottom: "30px" }}>
            <label className="label">
              About:{" "}
              <small
                style={{ fontWeight: "400" }}
              >{`(maximum ${maxIntroductionHtmlLen - 1} characters)`}</small>
            </label>
            <ReactQuill
              theme="snow"
              ref={e => (introductionRef = e)}
              value={state.tempPublicProfile.aboutIntroduction || ""}
              onChange={handleIntroductionHtml}
              modules={modules}
              formats={formats}
            />
            <p
              className={`help is-pulled-right ${
                reachedAboutIntroductionLen ? "is-danger" : ""
              }`}
            >
              Characters:{" "}
              {introductionHtmlLen === 0 ? 0 : introductionHtmlLen - 1}/
              {maxIntroductionHtmlLen - 1}
            </p>
            {/* <textarea
              id="my-text"
              onChange={e => {
                console.log(e.target)
                if (e.currentTarget.value)
                  dispatch({
                    type: "updateAboutIntroduction",
                    data: e.currentTarget.value,
                  })
                else
                  dispatch({
                    type: "updateAboutIntroduction",
                    data: "",
                  })
              }}
              rows="5"
              value={state.tempPublicProfile.aboutIntroduction}
              className="textarea"
              placeholder=""
            ></textarea> */}
          </div>
          {/* <hr className="hr-branded"></hr> */}
          <div className="field" style={{ marginBottom: "50px" }}>
            <label className="label">
              Additional Info:{" "}
              <small
                style={{ fontWeight: "400" }}
              >{`(maximum ${maxSkillsHtmlLen - 1} characters)`}</small>
            </label>
            <div style={{ minHeight: "100px" }}>
              <ReactQuill
                //id="my-text"
                ref={e => (skillsRef = e)}
                theme="snow"
                onChange={handleSkillsHtml}
                rows="5"
                value={state.tempPublicProfile.aboutSkills || ""}
                modules={modules}
                formats={formats}
              />
            </div>
            <p
              className={`help is-pulled-right ${
                reachedSkillsLen ? "is-danger" : ""
              }`}
            >
              Characters: {skillsHtmlLen === 0 ? 0 : skillsHtmlLen - 1}/
              {maxSkillsHtmlLen - 1}
            </p>
            {/* <textarea
              id="my-text"
              onChange={e => {
                console.log(e.target)
                if (e.currentTarget.value)
                  dispatch({
                    type: "updateAboutSkills",
                    data: e.currentTarget.value,
                  })
                else
                  dispatch({
                    type: "updateAboutSkills",
                    data: "",
                  })
              }}
              rows="5"
              value={state.tempPublicProfile.aboutSkills}
              className="textarea"
              placeholder=""
            ></textarea> */}
          </div>

          {sessionRole === "designer" && (
            <>
              <label className="label">
                Do you want to display your voyayge blog posts on your profile?
              </label>
              <div className="field">
                <input
                  id="switchColorWarning"
                  type="checkbox"
                  name="switchColorWarning"
                  className="switch is-warning"
                  checked={
                    state.tempPublicProfile.displayBlogPostsOnProfilePage
                      ? state.tempPublicProfile.displayBlogPostsOnProfilePage
                      : false
                  }
                  onChange={e => {
                    console.log(e.currentTarget.value)
                    let newVal = state.tempPublicProfile
                      .displayBlogPostsOnProfilePage
                      ? !state.tempPublicProfile.displayBlogPostsOnProfilePage
                      : true
                    dispatch({
                      type: "updateDisplayBlogPostOnProfile",
                      data: newVal,
                    })
                  }}
                ></input>
                <label for="switchColorWarning" style={{ userSelect: "none" }}>
                  {state.tempPublicProfile.displayBlogPostsOnProfilePage
                    ? state.tempPublicProfile.displayBlogPostsOnProfilePage
                      ? "Yes"
                      : "No"
                    : "No"}
                </label>
                {/* <label className="help">Note: </label> */}
                {state.tempPublicProfile.displayBlogPostsOnProfilePage && (
                  <div style={{ marginTop: "30px" }}>
                    <p>Enter your blog post urls below</p>
                    {range(MAXPOSTSALLOWED).map((item, index) => {
                      return (
                        <div className="field" key={index}>
                          <div className="control">
                            <input
                              class={`${
                                blogPostError[item] === false
                                  ? "input is-warning"
                                  : "input is-danger"
                              }`}
                              type="text"
                              placeholder="URL"
                              value={getBlogPostURLValue(item)}
                              onBlur={e => {
                                if (e.currentTarget.value) {
                                  let text = e.currentTarget.value
                                  if (
                                    !text.includes("https://blog.voyayge.com/")
                                  ) {
                                    let currentValue = blogPostError
                                    currentValue[item] = true
                                    setBlogPostError([...currentValue])
                                  } else {
                                    let currentValue = blogPostError
                                    currentValue[item] = false
                                    setBlogPostError([...currentValue])
                                  }
                                } else {
                                  let currentValue = blogPostError
                                  currentValue[item] = false
                                  setBlogPostError([...currentValue])
                                }
                              }}
                              onChange={e => {
                                let currentValue = blogPostError
                                currentValue[item] = false
                                setBlogPostError([...currentValue])
                                dispatch({
                                  type: "updateBlogPostURLValue",
                                  index: item,
                                  data: e.currentTarget.value,
                                })
                              }}
                            ></input>
                            {blogPostError[item] === true ? (
                              <p className="help is-danger">
                                Only voyayge blog posts allowed
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </>
          )}

          <div
            style={{ marginTop: "20px" }}
            className="field is-grouped is-grouped-right"
          >
            <p className="control">
              <button
                onClick={e => {
                  dispatch({ type: "cancelEditMode" })
                  window.scrollTo(0, 0)
                }}
                className="button"
              >
                Cancel
              </button>
            </p>
            <p className="control">
              <button
                onClick={e => {
                  if (notErrorsFound()) {
                    dispatch({
                      type: "saveEditedChanges",
                      context: dbContext,
                      uid: `${userAuthContext.uid}`,
                    })
                  }
                }}
                className="button is-warning"
              >
                Save changes
              </button>
            </p>
          </div>
          <hr className="hr-branded"></hr>
        </>
      )
    } else {
      return (
        <>
          <div>
            {state.publicProfile.aboutIntroduction
              ? ReactHtmlParser(state.publicProfile.aboutIntroduction)
              : "--"}
          </div>
          <div>
            {state.publicProfile.aboutSkills
              ? ReactHtmlParser(state.publicProfile.aboutSkills)
              : "--"}
          </div>
          {/* <div>{ReactHtmlParser(state.publicProfile.aboutIntroduction)} </div> */}
          {/* <p>
            {state.publicProfile.aboutIntroduction
              ? state.publicProfile.aboutIntroduction
              : "--"}
          </p> */}
          {/* <div>{ReactHtmlParser(state.publicProfile.aboutSkills)} </div> */}
          {/* <p>
            {state.publicProfile.aboutSkills
              ? state.publicProfile.aboutSkills
              : "--"}
          </p> */}
        </>
      )
    }
  }

  const editProfileRef = React.useRef(null)

  const showImageThumbnails = params => {
    try {
      if (
        state.publicProfile.profilePhotos &&
        state.publicProfile.profilePhotos.length > 0
      ) {
        // console.log(state.publicProfile.profilePhotos)
        // let _p = []
        // state.publicProfile.profilePhotos.map((item, index) => {
        //   let v = {
        //     src: item.thumb_src,
        //     width: item.width,
        //     height: item.height,
        //   }
        //   _p.push(v)
        // })
        // return (
        //   <Gallery
        //     photos={_p}
        //     style={{ cursor: "pointer" }}
        //     onClick={e => {
        //       // openLightbox(index)
        //       setCurrentImage(Number(e.currentTarget.id))
        //       setViewerIsOpen(true)
        //     }}
        //   />
        // )
        return state.publicProfile.profilePhotos.map((item, index) => {
          return (
            <div key={item.name} id={index} className="image-item">
              <LazyLoadImage
                id={index}
                src={item.thumb_src}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={e => {
                  // openLightbox(index)
                  setCurrentImage(Number(e.currentTarget.id))
                  setViewerIsOpen(true)
                }}
                effect="blur"
              />

              {/* <div className="image-hover-menu">
                <div className="image-show">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    id={index}
                  >
                    Show Photo
                  </a>
                </div>
              </div> */}
            </div>
          )
        })
      }
      {
        return (
          <div className="profile-photo-container">
            <p>- No Photos -</p>
            <img src={empty} alt="" />
          </div>
        )
      }
    } catch (err) {
      return ""
    }
  }

  const memoizedGetCodes = useMemo(() => state.publicProfile.countriesVisited, [
    state.publicProfile.countriesVisited,
  ])

  const pickRightDateFormat = () => {
    try {
      return state.publicProfile.backInDays === "unspecified"
        ? new Date()
        : state.publicProfile.backInDays === ""
        ? new Date()
        : typeof state.publicProfile.backInDays === "string"
        ? new Date(state.publicProfile.backInDays)
        : state.publicProfile.backInDays.toDate()
    } catch (error) {
      console.log(error)
      return state.publicProfile.backInDays === "unspecified"
        ? new Date()
        : state.publicProfile.backInDays === ""
        ? new Date()
        : state.publicProfile.backInDays
    }
  }

  const linkPreview = url => {
    fetch(
      "https://blog.voyayge.com/follow-the-underground-railroad-and-learn-about-the-road-to-freedom/",
      { method: "GET" }
    ).then(html => {
      html.text().then(text => {
        var parser = new DOMParser()
        var doc = parser.parseFromString(text, "text/html")
        if (doc) console.log(doc.title)
        return (
          <div>
            <p>doc.title</p>
          </div>
        )
      })
    })
  }

  const getValidPostsCount = () => {
    try {
      return countBy(state.publicProfile.blogPostURL, o => o != "").true
    } catch (err) {
      return 0
    }
  }

  const displayCarousel = () => {
    if (
      state.publicProfile.blogPostURL &&
      state.publicProfile.blogPostURL.length > 0
    ) {
      return (
        <CarouselProvider
          // naturalSlideWidth={100}
          // naturalSlideHeight={125}
          totalSlides={getValidPostsCount()}
          isPlaying={true}
          interval={10000}
          infinite={true}
          visibleSlides={1}
          isIntrinsicHeight={true}
          currentSlide={0}
          naturalSlideWidth={282}
          className="blog-post-carousel"
        >
          <Slider>
            {state.publicProfile.blogPostURL.map((item, index) => {
              if (item)
                return (
                  <Slide index={index}>
                    <BlogPostCard postUrl={item} />
                  </Slide>
                )
            })}
          </Slider>
          <ButtonBack className="button is-warning blog-post-back-button">
            <i className="far fa-angle-left fa-lg"></i>
          </ButtonBack>
          <ButtonNext className="button is-warning blog-post-next-button">
            <i className="far fa-angle-right fa-lg"></i>
          </ButtonNext>
          {/* <Button.Group size={3}>
        {[...Array(slides).keys()].map(slide => (
          <button as={Dot} key={slide} icon="circle" slide={slide} />
        ))}
      </Button.Group> */}
          <div
            style={{ maxWidth: "116px", margin: "0px auto" }}
            className="blog-post-dots"
          >
            {state.publicProfile.blogPostURL.map((item, index) => {
              if (item)
                return (
                  <Dot className="dots" slide={index}>
                    .
                  </Dot>
                )
            })}
          </div>
        </CarouselProvider>
      )
    }
  }

  return (
    <>
      {loading ? (
        <ShowLoading />
      ) : (
        <>
          {/* <button className="button" onClick={getCodes}>
            Press Me!
          </button> */}
          {/* <GoToTop scrollStepInPx="100" delayInMs="30" /> */}
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={state.publicProfile.profilePhotos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        {x.caption}
                      </p>
                    ),
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
          {/* <Link to="/app/userprofiles/DBDLzjsQHIVREixJeGSqQGH7nFE3">
            This is a link
          </Link> */}

          <div className="columns">
            <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
            <div className="column is-10-desktop is-10-tablet">
              <div>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "700",
                    borderBottom: "2px solid #fec600",
                  }}
                >
                  Your Profile
                </p>
              </div>
            </div>
            <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
          </div>

          <div className="columns is-desktop">
            <div className="column is-1-desktop "></div>
            <div className="column is-3-desktop  ">
              <EditableProfileImageField
                profileImageURL={state.publicProfile.userProfileImage}
                doUpdateImage={imageUrl => {
                  dispatch({
                    type: "updateProfileImageUrl",
                    data: imageUrl,
                    context: dbContext,
                    uid: `${userAuthContext.uid}`,
                  })
                }}
              />
              {/* <hr className="hr-branded"></hr> */}
              <h3
                style={{
                  marginBottom: "0px",
                  marginLeft: "0px",
                  fontSize: "28px",
                  fontWeight: "600",
                }}
                align="center"
                // className="subtitle is-3 font-lato-700"
              >
                {renderNameField()}
              </h3>
              <div
                style={{ fontSize: "14px", color: "rgba(74,74,74,0.6)" }}
                align="center"
                // className="content"
              >
                <i
                  style={{ margin: "8px" }}
                  className="fas fa-map-marker-alt"
                ></i>
                {state.publicProfile.location === ""
                  ? "--"
                  : state.publicProfile.location}
              </div>
              <div className="align-center">
                <StarRating
                  score={state.publicProfile.reviewRating}
                  reviewCount={state.publicProfile.reviewCount}
                />
              </div>
              {!state.editMode && (
                <>
                  <hr className="hr-branded"></hr>
                  <div
                    style={{ fontSize: "16px" }}
                    className="content is-medium"
                  >
                    <p
                      style={{
                        color: "rgba(74,74,74,0.6)",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      Languages spoken:
                    </p>
                    <div className="tags" style={{ justifyContent: "center" }}>
                      {state.publicProfile.languagesSpoken
                        ? state.publicProfile.languagesSpoken.map(item => {
                            if (item)
                              return (
                                <span
                                  key={item.label}
                                  className="tag is-warning"
                                >
                                  {item.label}
                                </span>
                              )
                          })
                        : "--"}
                    </div>
                  </div>
                  {sessionRole === "designer" && ( // Only show for designers
                    <>
                      <hr className="hr-branded"></hr>
                      <p style={{ marginBottom: "8px" }}>
                        Are you available for design tasks?
                      </p>
                      <div className="field">
                        <input
                          id="switchColorWarning"
                          type="checkbox"
                          name="switchColorWarning"
                          className="switch is-warning"
                          checked={
                            state.publicProfile.available
                              ? state.publicProfile.available
                              : false
                          }
                          onChange={e => {
                            console.log(e.currentTarget.value)
                            let newVal = state.publicProfile.available
                              ? !state.publicProfile.available
                              : true
                            dispatch({
                              type: "updateAvailability",
                              data: newVal,
                            })
                            dispatch({
                              type: "updateBackInDays",
                              data: 0,
                            })
                            updateDBWithAvailability(newVal)
                          }}
                        ></input>
                        <label
                          for="switchColorWarning"
                          style={{ userSelect: "none" }}
                        >
                          {state.publicProfile.available
                            ? state.publicProfile.available
                              ? "Yes"
                              : "No"
                            : "No"}
                        </label>
                        {/* <label className="help">Note: </label> */}
                      </div>
                      {state.publicProfile.available === false && (
                        <div className="field">
                          <label style={{ marginBottom: "10px" }}>
                            I will be available back again on:
                          </label>
                          <div style={{ marginTop: "5px" }}>
                            <Calendar
                              minDate={new Date()}
                              // setting max date to 30
                              maxDate={moment(
                                moment().format("DD-MM-YYYY"),
                                "DD-MM-YYYY"
                              )
                                .add(30, "days")
                                .toDate()}
                              onChange={value => {
                                console.log(value)
                                // dispatch({
                                //   type: "updateReservationDateTime",
                                //   // data: formattedDate(value).toUTCString(),
                                //   data: value,
                                // })
                                if (value) {
                                  dispatch({
                                    type: "updateBackInDays",
                                    data: value,
                                  })
                                  updateDBWithBackInDays(value)
                                }
                              }}
                              value={pickRightDateFormat()}
                            />
                            {/* <label className="help">
                          Selected Date:{" "}
                          {pickRightDateFormat().toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })}
                        </label> */}
                          </div>
                          {/* <Select
                        options={days}
                        value={
                          state.publicProfile.backInDays === "unspecified"
                            ? {
                                value: `${0}`,
                                label: `${0}`,
                              }
                            : {
                                value: `${state.publicProfile.backInDays}`,
                                label: `${state.publicProfile.backInDays}`,
                              }
                        }
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        onChange={e => {
                          console.log(e)
                          if (e) {
                            dispatch({
                              type: "updateBackInDays",
                              data: e.value,
                            })
                            updateDBWithBackInDays(e.value)
                          }
                        }}
                      ></Select> */}
                        </div>
                      )}
                      <hr className="hr-branded"></hr>
                    </>
                  )}
                </>
              )}

              {(state.publicProfile.countriesVisitedCount === 0) |
              (state.publicProfile.countriesVisitedCount === undefined)
                ? ""
                : ""
              // <div
              //   className="align-center map-bg"
              //   style={{
              //     marginTop: "25px",
              //     width: "200px",
              //     position: "relative",
              //     display: "block",
              //   }}
              // >
              //   <p
              //     style={{
              //       fontSize: "30px",
              //       fontWeight: "600",
              //     }}
              //   >
              //     {state.publicProfile.countriesVisitedCount}
              //   </p>
              //   <p
              //     style={{
              //       fontSize: "18px",
              //     }}
              //   >
              //     Countries Traveled
              //   </p>
              // </div>
              }
              {state.publicProfile.tripsDesigned === 0 ||
              state.publicProfile.tripsDesigned === undefined ? (
                ""
              ) : (
                <div
                  className="align-center"
                  style={{
                    marginTop: "25px",
                    width: "200px",
                    position: "relative",
                    display: "block",
                  }}
                >
                  <p
                    style={{
                      fontSize: "30px",
                      fontWeight: "600",
                    }}
                  >
                    {state.publicProfile.tripsDesigned}
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Trips Designed
                  </p>
                </div>
              )}
            </div>
            {/* <div className="column is-1-desktop "></div> */}
            <div className="column is-7-desktop ">
              {!state.editMode && (
                <button
                  className="button is-warning is-pulled-right"
                  style={{
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                  onClick={e => {
                    dispatch({ type: "toggleEditMode" })
                    window.scrollTo(0, editProfileRef.current.offsetTop)
                  }}
                >
                  Edit Profile
                </button>
              )}
              <h3
                align=""
                style={{ fontSize: "24px" }}
                className="subtitle is-2 font-lato-600"
              >
                About
              </h3>

              <hr ref={editProfileRef} className="hr-branded"></hr>
              <div
                style={{ fontSize: "16px", lineHeight: "1.5rem" }}
                className="content is-medium"
              >
                {renderAboutField()}
              </div>
              {/* <h3
                align=""
                style={{ fontSize: "20px" }}
                className="subtitle is-3 font-lato-500"
              >
                Tags
              </h3>
              <div style={{ fontSize: "16px" }} className="content is-medium">
                <div className="tags">
                  {state.publicProfile.tags
                    ? state.publicProfile.tags.map(item => {
                        if (item)
                          return (
                            <span key={item} className="tag is-warning">
                              {item}
                            </span>
                          )
                      })
                    : "--"}
                </div>
              </div> */}
              {!state.editMode && (
                <>
                  {sessionRole === "designer" && (
                    <>
                      <h3
                        align=""
                        style={{ fontSize: "20px" }}
                        className="subtitle is-3"
                      >
                        Specialties
                      </h3>
                      {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "90px", margin: "5px" }}>
                      <img src={camping} alt="" />
                    </div>
                    <div style={{ width: "90px", margin: "5px" }}>
                      <img src={camping} alt="" />
                    </div>
                    <div style={{ width: "90px", margin: "5px" }}>
                      <img src={camping} alt="" />
                    </div>
                    <div style={{ width: "90px", margin: "5px" }}>
                      <img src={camping} alt="" />
                    </div>
                  </div> */}
                      <div
                        style={{ fontSize: "16px" }}
                        className="content is-medium"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {state.publicProfile.skills
                            ? state.publicProfile.skills.map(item => {
                                // console.log(item.src)
                                if (item)
                                  return (
                                    <>
                                      <SkillBadge item={item} />
                                      {/* <div
                                    className=""
                                    style={{ marginRight: "10px" }}
                                  >

                                    <div
                                      style={{
                                        // height: "120px",
                                        width: "120px",
                                        margin: "5px",
                                      }}
                                    >
                                      <img src={item.src} alt="" />
                                    </div>

                                  </div> */}
                                    </>
                                  )
                              })
                            : "--"}
                        </div>
                      </div>
                    </>
                  )}
                  {!state.editMode && (
                    <>
                      <hr className="hr-branded"></hr>
                      <h3
                        align=""
                        style={{ fontSize: "24px" }}
                        className="subtitle is-3 font-lato-600"
                      >
                        Countries Traveled
                      </h3>
                      <div style={{ position: "relative" }}>
                        <Suspense fallback={<h1>Still Loading…</h1>}>
                          <LazyMap mapData={memoizedGetCodes} />
                          {/* <Map mapData={memoizedGetCodes} /> */}
                        </Suspense>
                        <p
                          style={{
                            fontSize: "40px",
                            fontWeight: "600",
                            position: "absolute",
                            top: "20px",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          {/* <i
                        className="fas fa-globe-americas fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                          {state.publicProfile.countriesVisitedCount}
                        </p>
                      </div>
                    </>
                  )}
                  {sessionRole === "designer" && (
                    <>
                      <hr className="hr-branded"></hr>
                      <h3
                        align=""
                        style={{ fontSize: "24px" }}
                        className="subtitle is-3 font-lato-600"
                      >
                        Countries I can design travel plans for:
                      </h3>
                      <div
                        style={{ fontSize: "16px" }}
                        className="content is-medium"
                      >
                        <div className="tags">
                          {state.publicProfile.countriesForItineraries
                            ? state.publicProfile.countriesForItineraries.map(
                                item => {
                                  if (item)
                                    return (
                                      <span
                                        key={item.value}
                                        className="tag is-warning"
                                      >
                                        {item.value}
                                      </span>
                                    )
                                }
                              )
                            : "--"}
                        </div>
                      </div>
                      <hr className="hr-branded"></hr>
                    </>
                  )}

                  {sessionRole === "designer" &&
                    state.publicProfile.displayBlogPostsOnProfilePage && (
                      <>
                        <h3
                          align=""
                          style={{ fontSize: "24px", marginTop: "36px" }}
                          className="subtitle is-3 font-lato-600"
                        >
                          My Travel Diaries & Tips
                        </h3>
                        {displayCarousel()}
                      </>
                    )}
                  <h3
                    align=""
                    style={{ fontSize: "24px", marginTop: "36px" }}
                    className="subtitle is-3 font-lato-600"
                  >
                    Photos
                  </h3>
                  <button
                    className="button is-warning is-small is-pulled-right"
                    onClick={e => {
                      navigate(ROUTES.EDIT_PHOTOS)
                    }}
                  >
                    Edit Photos
                  </button>
                  <hr className="hr-branded"></hr>
                  <div className="image-show-container">
                    {showImageThumbnails()}
                  </div>
                  {sessionRole === "designer" && (
                    <>
                      {" "}
                      <hr className="hr-branded"></hr>
                      <div
                        className="reviews-section"
                        style={{ marginBottom: "50px" }}
                      >
                        {/* //Todo - Reviews must be moved to a sub-collection. Will have
                  an issue as the number of reviews grow the entire reviews have
                  to be downloaded. */}
                        <div className="columns is-vcentered is-mobile is-tablet">
                          <div className="column is-5-mobile   is-4-tablet is-3-desktop">
                            <p
                              style={{
                                fontSize: "24px",
                              }}
                            >
                              Reviews
                            </p>
                          </div>
                          {/* <div className="column is-1">
                            <span className="tag is-warning">
                              <p
                                align=""
                                style={{
                                  fontSize: "20px",
                                  // float: "left",
                                  //    marginTop: "12px",
                                  verticalAlign: "-webkit-baseline-middle",
                                  padding: "5px",
                                }}
                                className="subtitle is-4 font-lato-400 item"
                              >
                                {state.publicProfile.reviewCount
                                  ? state.publicProfile.reviewCount
                                  : "0"}
                              </p>
                            </span>
                          </div> */}
                          <div className="column is-6"></div>
                        </div>
                        {state.reviews !== undefined
                          ? state.reviews.map((review, index) => (
                              <ReviewCards
                                key={review.reviewId}
                                review={review}
                                index={index}
                                canReply={true}
                              />
                            ))
                          : ""}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="column is-1-desktop"></div>
          </div>
        </>
      )}
    </>
  )
}

const ProfilePage = () => {
  return (
    <ToastProvider>
      <ProfileStateProvider>
        <ProfilePageBase />
      </ProfileStateProvider>
    </ToastProvider>
  )
}

export default ProfilePage
