import React, { useState, useEffect, useContext, useCallback } from "react"
import { state_index, countryList } from "./../../constants/data/geoData"
import "./EditableItems.scss"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"
import { dbUpdate } from "../../utility/dbCRUD"

const EditableAddressItem = addressObject => {
  const authUser = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [inEditMode, setEditMode] = useState({
    fullName: false,
    address: false,
  })
  const [userAddress, setUserAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  })

  const [userTempAddress, setUserTempAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  })
  const [combinedAddress, setCombinedAddress] = useState()
  const [enableStateField, setEnableStateField] = useState(true)
  //  user address data.
  useEffect(() => {
    console.log(addressObject)
    setUserAddress(addressObject.addressObject)
    setUserTempAddress(addressObject.addressObject)
  }, [addressObject])

  useEffect(() => {}, [inEditMode])

  useEffect(() => {
    console.log({ userAddress })
    setCombinedAddress(mergeAddress())
  }, [userAddress])

  useEffect(() => {
    console.log({ userTempAddress })
    if (userTempAddress.country === "USA") {
      setEnableStateField(true)
    } else {
      setEnableStateField(false)
    }
  }, [userTempAddress])

  const mergeAddress = useCallback(() => {
    const homeAddress = userAddress
    var mergedAddress = ""
    if (homeAddress.line1 !== "")
      mergedAddress = mergedAddress + homeAddress.line1 + ", "
    if (homeAddress.line2 !== "")
      mergedAddress = mergedAddress + homeAddress.line2 + ", "
    if (homeAddress.city !== "")
      mergedAddress = mergedAddress + homeAddress.city + ", "
    if (homeAddress.state !== "")
      mergedAddress = mergedAddress + homeAddress.state + ", "
    if (homeAddress.zip !== "")
      mergedAddress = mergedAddress + homeAddress.zip + ", "
    if (homeAddress.country !== "")
      mergedAddress = mergedAddress + homeAddress.country
    if (mergedAddress === "") mergedAddress = "(not set)"
    return mergedAddress
  }, [userAddress])

  const handleSave = e => {
    switch (e.target.name) {
      case "addressSave":
        try {
          setUserAddress(userTempAddress)
          const updateData = { homeAddress: userTempAddress }
          dbUpdate(dbContext, "users", authUser.uid, updateData)
          if (dbContext) {
            dbContext
              .firestore()
              .collection("users")
              .doc(`${authUser.uid}`)
              .collection("publicProfile")
              .doc(`${authUser.uid}`)
              .update({
                location: `${userTempAddress.city}, ${userTempAddress.country}`,
              })
              .then(result => {
                console.log("Public profile location updated successfully")
              })
          }
          setEditMode({ address: false })
        } catch (err) {
          console.log(err)
        }
        break
      default:
        break
    }
  }
  return (
    <>
      {!inEditMode.address ? (
        <>
          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">Home Address</label>
            </div>

            <div className="field-body">
              <div className="field">
                <p className="control">{combinedAddress}</p>
              </div>
              <div className="control">
                <a
                  // className="is-pulled-right"
                  onClick={() => setEditMode({ address: true })}
                >
                  Edit
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="field">
            <div className="control">
              <label className="label">Address Line 1</label>
              <input
                className="input"
                type="text"
                placeholder=""
                value={userTempAddress.line1}
                onChange={e => {
                  setUserTempAddress({
                    ...userTempAddress,
                    line1: e.target.value,
                  })
                }}
                name="addressLine1"
              />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">Address Line 2</label>
              <input
                className="input"
                type="text"
                placeholder=""
                value={userTempAddress.line2}
                onChange={e => {
                  setUserTempAddress({
                    ...userTempAddress,
                    line2: e.target.value,
                  })
                }}
                name="addressLine2"
              />
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="label">City</div>
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={userTempAddress.city}
                    onChange={e => {
                      setUserTempAddress({
                        ...userTempAddress,
                        city: e.target.value,
                      })
                    }}
                    name="addressCity"
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <div className="label">State</div>
                  <div className="select is-fullwidth">
                    <select
                      disabled={!enableStateField}
                      value={userTempAddress.state}
                      onChange={e => {
                        setUserTempAddress({
                          ...userTempAddress,
                          state: e.target.value,
                        })
                      }}
                      name="addressState"
                    >
                      {Object.keys(state_index).map(x => (
                        <option>{x}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <div className="label">Zip</div>
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={userTempAddress.zip}
                    onChange={e => {
                      setUserTempAddress({
                        ...userTempAddress,
                        zip: e.target.value,
                      })
                    }}
                    name="addressZip"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="label">Country</div>
                  <div className="select is-fullwidth">
                    <select
                      value={userTempAddress.country}
                      onChange={e => {
                        setUserTempAddress({
                          ...userTempAddress,
                          country: e.target.value,
                        })
                      }}
                      name="addressCountry"
                    >
                      {countryList.map(x => (
                        <option>{x}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field is-grouped">
                <div className="control">
                  <button
                    className="button is-warning"
                    name="addressSave"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
                <div className="control">
                  <button
                    className="button is-text"
                    onClick={() => setEditMode({ address: false })}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default EditableAddressItem
