import React, { useContext, useRef, useMemo, useCallback } from "react"
import { useState, useEffect } from "react"
import { useTripStateStore } from "../../../State/trip-state/trip-state-store"
import { DebounceInput } from "react-debounce-input"
import TimePicker from "react-time-picker"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { ReactSortable } from "react-sortablejs"
import { useDashboardStateStore } from "../../../State/dashboard-state/dashboard-state"
import noevents from "./../../../images//activities.svg"
import algoliaImg from "./../../../images/search-by-algolia.png"
import wizard from "./../../../images/wizard.png"
import swal from "sweetalert"
import GoogleMapReact from "google-map-react"
import AuthUserContext from "../../../components/session/context"
import { FirebaseContext } from "../../../components/firebase"
import { NewActivityForm } from "./NewActivityForm"
import { ActivityCard } from "./ActivityCard"
import { element } from "prop-types"
import { navigate } from "gatsby"
import { CommuteCard } from "./CommuteCard"
import { NewCommuteForm } from "./NewCommuteForm"
import ReactTooltip from "react-tooltip"
import ReactQuill from "react-quill"
import { add, countBy, minBy } from "lodash"
import { functions } from "../../../components/firebase/firebase"
import ReactHtmlParser from "react-html-parser"
import CreatableSelect from "react-select/creatable"
import { ToastProvider, useToasts } from "react-toast-notifications"
import {
  addZeroBefore,
  dottedText,
  getHours,
  getMinutes,
  googleDirectionsURI,
  googleMapsURI,
  parseDayAndItemFromString,
  titleCase,
} from "../../../utility/general"
import Geocode from "react-geocode"
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size"
import {
  connectAutoComplete,
  InstantSearch,
  Highlight,
  connectPoweredBy,
} from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import moment from "moment"
import Select from "react-select"
import { BYOTActivityForm } from "./BYOTActivityForm"
import map from "../../../images/map.jpg"
import {
  ConfirmationActivityOverwriteModal,
  MoveActivityConfirmationModal,
} from "./MoveActivityConfirmationModal"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal"
import { BYOTActivityCard } from "./BYOTActivityCard"
import DisplayCommute from "./DisplayCommute"
import TopAttractions from "../../../components/TopAttractions/TopAttractions"
import ShowImage from "../../../components/ShowImage/ShowImage"
import AttractionsSearchBar from "../../../components/AttractionsSearchBar/AttractionsSearchBar"
import { useGlobalStateStore } from "../../../State/global-state/global-state"
import { TripPreferencesFormElements } from "../../../components/TripCreationComponents/TripPreferences"
import TripPlannerReferral from "../../../components/TripPlannerReferral/TripPlannerReferral"
const searchClient = algoliasearch(
  `${process.env.ALGOLIA_SEARCH_APP_ID}`,
  `${process.env.ALGOLIA_SEARCH_API_KEY}`
)

function ItineraryDateTabs({ onTabChange, location }) {
  const [currentItem, setCurrentItem] = useState(0)
  const [dateButtons, setDateButtons] = useState()
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [state, dispatch] = useDashboardStateStore()
  const [buttonData, setButtonData] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [incrementEnabled, setIncrementEnabled] = useState(true)
  const [decrementEnabled, setDecrementEnabled] = useState(true)

  useEffect(() => {
    try {
      // var currentItem =
      //   state.activeDay !== undefined
      //     ? state.activeDay
      //     : 0
      // onTabChange(currentItem)
      // buttonList[currentItem] = "selected"

      //Generate the date tab buttons for all the travel days.
      var startDate =
        typeof state.currentJobInfo.travelDates.start === "string"
          ? new Date(state.currentJobInfo.travelDates.start)
          : state.currentJobInfo.travelDates.start.toDate()
      var endDate =
        typeof state.currentJobInfo.travelDates.end === "string"
          ? new Date(state.currentJobInfo.travelDates.end)
          : state.currentJobInfo.travelDates.end.toDate()
      generateDateButtonsData(startDate, endDate)
      // setDateButtons(generateDateButtons(startDate, endDate))
    } catch (error) {
      console.log(error)
    }
  }, [state])
  let SHOWCOUNT = 3
  try {
    SHOWCOUNT = window.innerWidth > 1024 ? 13 : 3
  } catch (err) {}

  useEffect(() => {
    console.log({ buttonData })
  }, [buttonData])

  useEffect(() => {
    console.log({ currentIndex })
  }, [currentIndex])

  var buttonList = []
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  // const months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ]

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  var getDaysArray = function(s, e) {
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d))
      buttonList.push("")
    }
    return a
  }

  const formatDestinations = activeTab => {
    try {
      var destinationString = ""
      console.log({ activeTab })
      state.currentJobInfo.designerWorkspace.itinerary[
        activeTab
      ].daysDestination.map((item, index) => {
        destinationString =
          destinationString +
          item.label +
          (state.currentJobInfo.designerWorkspace.itinerary[activeTab]
            .daysDestination.length ===
          index + 1
            ? ""
            : ", ")
      })
      console.log(destinationString)
      return destinationString
    } catch (err) {
      console.log(err)
      return ""
    }
  }
  // This is for the date selection buttons on the itinerary tab.
  const generateDateButtonsData = (startDate, endData) => {
    let bData = []
    getDaysArray(startDate, endData).map((item, index) => {
      var month = months[item.getMonth()]
      var date = item.getDate()
      var day = weekdays[item.getDay()]
      let a = { month: month, day: day, date: date }
      bData.push(a)
    })
    if (bData.length <= SHOWCOUNT + 1) {
      setDecrementEnabled(false)
      setIncrementEnabled(false)
    }
    setButtonData([...bData])
  }

  const generateDateButtons = (startDate, endData) => {
    return (
      <div className="day-box-container">
        {getDaysArray(startDate, endData).map((item, index) => {
          var month = months[item.getMonth()]
          var date = item.getDate()
          var day = weekdays[item.getDay()]
          // console.log({ index })
          return (
            <>
              <div
                key={month + date}
                id={index}
                className={`day-box ${buttonList[index]}`}
                onClick={e => {
                  if (location) {
                    console.log("same place - skipping navigate")
                    if (
                      location.search ===
                      `?tab=itinerary&day=${e.currentTarget.id}`
                    )
                      return
                  }
                  // console.log(e.currentTarget.id)
                  // dispatch({ type: "setActiveDay", data: e.currentTarget.id })
                  navigate(
                    `/app/byottripdetails/${state.tripDetails.tripUid}?tab=itinerary&day=${e.currentTarget.id}`
                  )

                  // setCurrentItem(e.currentTarget.id)
                }}
                style={{
                  marginTop: "25px",
                }}
              >
                <p
                  style={{
                    paddingTop: "1px",
                    fontSize: "10px",
                    marginTop: "-17px",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                    color: "rgba(78,78,78,0.8)",
                  }}
                  align="center"
                >
                  {month}
                </p>
                <p
                  style={{
                    paddingTop: "1px",
                    fontSize: "14px",
                    marginTop: "4px",
                  }}
                  align="center"
                >
                  {day}
                </p>
                <p
                  style={{
                    fontSize: "26px",
                    fontWeight: "700",
                    color: "rgba(78,78,78,0.7)",
                    marginTop: "-6px",
                  }}
                  align="center"
                >
                  {date}
                </p>
                {/* <p
                style={{
                  paddingTop: "1px",
                  fontSize: "10px",
                  marginTop: "7px",
                  letterSpacing: "0.5px",
                  textTransform: "uppercase",
                  color: "rgba(78,78,78,0.8)",
                }}
                align="center"
              >
                {formatDestinations(index)}
              </p> */}
              </div>
            </>
          )
        })}
      </div>
    )
  }

  const showButtonsResponsive = params => {
    if (buttonData) {
      return (
        <>
          {decrementEnabled && (
            <div
              className="day-box"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "center",
                border: "none",
                background: "white",
                color: "rgba(78,78,78,0.8)",
                // background: "#fbe290",
              }}
              onClick={e => {
                if (currentIndex === 0) return
                // setIncrementEnabled(true)
                console.log(currentIndex)
                setCurrentIndex(currentIndex - 1)
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </div>
          )}
          {buttonData.map((item, index) => {
            if (index < currentIndex) return
            if (index > currentIndex + SHOWCOUNT) return
            return (
              <div
                key={item.month + item.date}
                id={index}
                className={`day-box ${index} ${
                  state.activeDay === index ? `` : ``
                }`}
                onClick={e => {
                  console.log({ location })
                  if (location) {
                    if (
                      location.search ===
                      `?tab=itinerary&day=${e.currentTarget.id}`
                    ) {
                      console.log("same place - skipping navigate")
                      return
                    }
                  }
                  console.log(e.currentTarget.id)
                  // dispatch({ type: "setActiveDay", data: e.currentTarget.id })
                  // dispatch({ type: "setActiveTab", data: e.currentTarget.id })
                  navigate(
                    `/app/byottripdetails/${state.tripDetails.tripUid}?tab=itinerary&day=${e.currentTarget.id}`
                  )

                  // setCurrentItem(e.currentTarget.id)
                }}
                style={{
                  marginTop: "25px",
                  backgroundColor:
                    state.activeDay === index ? "#fec600" : "#fbe290",
                }}
              >
                <p
                  style={{
                    paddingTop: "1px",
                    fontSize: "10px",
                    marginTop: "-17px",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                    color: "rgba(78,78,78,0.8)",
                  }}
                  align="center"
                >
                  {item.month}
                </p>
                <p
                  style={{
                    paddingTop: "1px",
                    fontSize: "10px",
                    marginTop: "2px",
                  }}
                  align="center"
                >
                  {item.day}
                </p>
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "700",
                    color: "rgba(78,78,78,0.7)",
                    marginTop: "-4px",
                  }}
                  align="center"
                >
                  {item.date}
                </p>
                {/* <p
              style={{
                paddingTop: "1px",
                fontSize: "10px",
                marginTop: "7px",
                letterSpacing: "0.5px",
                textTransform: "uppercase",
                color: "rgba(78,78,78,0.8)",
              }}
              align="center"
            >
              {formatDestinations(index)}
            </p> */}
              </div>
            )
          })}
          {incrementEnabled && (
            <div
              className="day-box"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "center",
                // background: "#fbe290",
                border: "none",
                background: "white",
                color: "rgba(78,78,78,0.8)",
              }}
              onClick={e => {
                if (currentIndex === buttonData.length - (SHOWCOUNT + 1)) {
                  // setIncrementEnabled(false)
                  return
                }
                // setDecrementEnabled(true)
                console.log(buttonData.length, currentIndex)
                setCurrentIndex(currentIndex + 1)
              }}
            >
              <i className="fas fa-chevron-right"></i>
            </div>
          )}
        </>
      )
    } else {
      return <>--</>
    }
  }
  const screenWidth = useWindowWidth()

  const showButtonsResponsiveDesktop = params => {
    if (buttonData) {
      return (
        <>
          {decrementEnabled && (
            <div
              className="day-box"
              style={{
                // marginTop: "25px",
                display: "flex",
                justifyContent: "center",
                border: "none",
                background: "white",
                color: "rgba(78,78,78,0.8)",
                margin: "2px",

                // background: "#fbe290",
              }}
              onClick={e => {
                if (currentIndex === 0) return
                // setIncrementEnabled(true)
                console.log(currentIndex)
                setCurrentIndex(currentIndex - 1)
              }}
            >
              <i className="fas fa-chevron-up"></i>
            </div>
          )}
          {buttonData.map((item, index) => {
            if (index < currentIndex) return
            if (index > currentIndex + SHOWCOUNT) return
            return (
              <div
                key={item.month + item.date}
                id={index}
                className={`day-box ${index} ${
                  state.activeDay === index ? `` : ``
                }`}
                onClick={e => {
                  console.log({ location })
                  if (location) {
                    if (
                      location.search ===
                      `?tab=itinerary&day=${e.currentTarget.id}`
                    ) {
                      console.log("same place - skipping navigate")
                      return
                    }
                  }
                  console.log(e.currentTarget.id)
                  // dispatch({ type: "setActiveDay", data: e.currentTarget.id })
                  // dispatch({ type: "setActiveTab", data: e.currentTarget.id })
                  navigate(
                    `/app/byottripdetails/${state.tripDetails.tripUid}?tab=itinerary&day=${e.currentTarget.id}`
                  )

                  // setCurrentItem(e.currentTarget.id)
                }}
                style={{
                  // marginTop: "25px",
                  backgroundColor:
                    state.activeDay === index ? "#fec600" : "#fbe290",
                  margin: "2px",
                }}
              >
                <p
                  style={{
                    paddingTop: "1px",
                    fontSize: "10px",
                    marginTop: "-17px",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                    color: "rgba(78,78,78,0.8)",
                    display: index === 0 ? "block" : "none",
                  }}
                  align="center"
                >
                  {item.month}
                </p>
                <p
                  style={{
                    paddingTop: "1px",
                    fontSize: "10px",
                    marginTop: "2px",
                  }}
                  align="center"
                >
                  {item.day}
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "rgba(78,78,78,0.7)",
                    marginTop: "-4px",
                  }}
                  align="center"
                >
                  {item.date}
                </p>
                {/* <p
              style={{
                paddingTop: "1px",
                fontSize: "10px",
                marginTop: "7px",
                letterSpacing: "0.5px",
                textTransform: "uppercase",
                color: "rgba(78,78,78,0.8)",
              }}
              align="center"
            >
              {formatDestinations(index)}
            </p> */}
              </div>
            )
          })}
          {incrementEnabled && (
            <div
              className="day-box"
              style={{
                // marginTop: "25px",
                display: "flex",
                justifyContent: "center",
                // background: "#fbe290",
                border: "none",
                background: "white",
                color: "rgba(78,78,78,0.8)",
                margin: "2px",
              }}
              onClick={e => {
                if (currentIndex === buttonData.length - (SHOWCOUNT + 1)) {
                  // setIncrementEnabled(false)
                  return
                }
                // setDecrementEnabled(true)
                console.log(buttonData.length, currentIndex)
                setCurrentIndex(currentIndex + 1)
              }}
            >
              <i className="fas fa-chevron-down"></i>
            </div>
          )}
        </>
      )
    } else {
      return <>--</>
    }
  }

  return (
    <>
      <div
        className="day-box-container"
        style={{
          marginRight: screenWidth > 1024 ? "25px" : "0px",
          paddingRight: screenWidth > 1024 ? "3px" : "0px",
          justifyContent: screenWidth > 1024 ? "flex-start" : "center",
          borderRight: screenWidth > 1024 ? "1px solid #ebebeb" : "none",
        }}
      >
        {screenWidth > 1024
          ? showButtonsResponsiveDesktop()
          : showButtonsResponsive()}
      </div>
    </>
  )
}

const checkIfCommute = value => {
  try {
    return value.activity.activityType === "commute" ? "commute" : ""
  } catch (err) {
    return ""
  }
}

// Using the HOC to create a draggable list container.
const SortableList = SortableContainer(
  ({
    items,
    onRemove,
    activeTab,
    onEditActivity,
    onMoveActivity,
    onEditCommute,
    onAddCommute,
    onRemoveCommute,
    onRemoveBackup,
    disableDrag,
    type,
    onMoveToBackup,
    isSetAsBackup,
    onMoveToActive,
    canEdit,
    onSelect,
    isItineraryBusy,
    useDragHandle,
    onMoveUp,
    onMoveDown,
  }) => {
    console.log({ items })
    const screenWidth = useWindowWidth()
    const [state, dispatch] = useDashboardStateStore()
    const LaunchGoogleDirections = cObj => {
      try {
        const gpsRegX = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
        const gpsRegX2 = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
        var previousActivityResult = gpsRegX.exec(cObj.from)
        var nextActivityResult = gpsRegX2.exec(cObj.to)
        console.log({ previousActivityResult })
        console.log({ nextActivityResult })
        var encodedSource = encodeURI(
          previousActivityResult ? previousActivityResult : cObj.from
        )
        var encodedDestination = encodeURI(
          nextActivityResult ? nextActivityResult : cObj.to
        )
        var encodedTravelMode = encodeURI(cObj.commuteMode)
        let finalURI = `https://www.google.com/maps/dir/?api=1&origin=${encodedSource}&destination=${encodedDestination}&travelmode=${encodedTravelMode}`
        console.log({ finalURI })

        if (finalURI !== "") return finalURI
      } catch (error) {
        console.log(error)
      }
    }
    const customNavigateTo = url => {
      window.open(url, "_blank")
    }
    const showCommuteItem = activityItem => {
      try {
        if (activityItem + 1 === items.length) return ""
        if (state.currentJobInfo.designerWorkspace.itinerary === undefined)
          return ""
        let commuteObj =
          state.currentJobInfo.designerWorkspace.itinerary[activeTab].commute[
            activityItem
          ]
        return (
          <div
            style={{
              marginBottom: "2px",
              maxWidth: "396px",
              textAlign: "center",
              // background: "rgba(74, 74, 74, 0.1)",
              // borderRadius: "4px 4px 4px 4px",
              padding: "5px",
              marginTop: "-13px",
              height: "55px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",

              // borderLeft: "5px solid rgba(74,74,74,0.2)",
            }}
          >
            {/* {
                state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                  .activities.length
              }{" "} */}
            <span
              style={{
                marginRight: "10px",
                minWidth: "120px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  marginRight: "5px",
                  color: "rgba(74,74,74,0.8)",
                  // display: "none",
                }}
              >
                <i class="fas fa-car"></i>
              </span>
              <span
                style={{
                  fontSize: "12px",
                  // marginRight: "10px",
                  color: "rgba(74,74,74,0.8)",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                  cursor: "pointer",
                }}
                onClick={() => {
                  customNavigateTo(LaunchGoogleDirections(commuteObj))
                }}
              >
                {commuteObj.formattedDurationText}
              </span>
            </span>
            {/* <span style={{ marginRight: "5px", color: "rgba(74,74,74,0.8)" }}>
              <i class="fas fa-clock"></i>
            </span> */}

            <span
              style={{
                fontSize: "12px",
                marginRight: "20px",
                color: "rgba(74,74,74,0.8)",
                textDecoration: "underline",
                textDecorationColor: "#fec600",
                cursor: "pointer",
              }}
              onClick={() => {
                customNavigateTo(LaunchGoogleDirections(commuteObj))
              }}
            >
              {" "}
              {commuteObj.formattedDistanceToMiles} mi
            </span>
            <span
              style={{
                // height: "100%",
                marginRight: "10px",
                color: "#fec600",
                fontSize: "30px",
                // borderLeft: "3px dotted #fec600",
              }}
            >
              <i class="fal fa-long-arrow-down"></i>
            </span>
            <span
              style={{
                fontSize: "12px",
                marginRight: "10px",
                color: "rgba(74,74,74,0.8)",
                // textTransform: "uppercase",
                cursor: "pointer",
                textDecoration: "underline",
                textDecorationColor: "#fec600",
                display: "none",
              }}
              onClick={() => {
                customNavigateTo(LaunchGoogleDirections(commuteObj))
              }}
            >
              view
            </span>
            <span
              style={{
                fontSize: "12px",
                marginRight: "5px",
                color: "rgba(74,74,74,0.8)",
                textTransform: "uppercase",
                display: "none",
              }}
            >
              edit
            </span>
          </div>
        )
      } catch (error) {
        return ""
      }
    }
    return (
      <>
        <div
          className="list-container"
          style={{
            maxWidth: screenWidth > 1024 ? "400px" : "none",
            marginRight: screenWidth > 1024 ? "auto" : "none",
          }}
        >
          {/* {isItineraryBusy && (
            <div className="list-container-busy">
              {" "}
              <div>
                <div className="spinner" style={{ zIndex: "20" }}>
                  <div className="rect1"></div>
                  <div className="rect2"></div>
                  <div className="rect3"></div>
                  <div className="rect4"></div>
                  <div className="rect5"></div>
                </div>
              </div>
            </div>
          )} */}
          {items.map((value, i, array) => {
            // console.log({ i })
            return (
              <React.Fragment key={`card-${i}`}>
                <BYOTActivityCard
                  key={`card-${i}`}
                  // key={`card-${value.activity.activityUid}`}
                  // type={type}
                  length={array.length}
                  itemNo={i}
                  index={i}
                  value={value}
                  onRemove={onRemove}
                  activeTab={activeTab}
                  onEditActivity={onEditActivity}
                  type={checkIfCommute(value)}
                  onEditCommute={onEditCommute}
                  onAddCommute={onAddCommute}
                  onRemoveCommute={onRemoveCommute}
                  canEdit={canEdit}
                  disabled={disableDrag}
                  onMoveToBackup={onMoveToBackup}
                  isSetAsBackup={isSetAsBackup}
                  onMoveToActive={onMoveToActive}
                  onRemoveBackup={onRemoveBackup}
                  onMoveActivity={onMoveActivity}
                  onSelect={onSelect}
                  disableDrag={disableDrag}
                  onMoveUp={onMoveUp}
                  onMoveDown={onMoveDown}
                  // disabled={
                  //   value.activity.activityType === "commute" ? "disabled" : ""
                  // }
                />
                {showCommuteItem(i, activeTab)}
              </React.Fragment>
            )
          })}
        </div>
      </>
    )
  }
)

function BYOTItineraryCreator({ defaultDayId, canEdit = false, location }) {
  const [activeTab, setActiveTab] = useState(0)
  const [searchResults, setSearchResults] = useState()
  const [ItineraryBusy, setItineraryBusy] = useState(false)
  //This state variable stores the text that the user is typing to search an activity.
  const [searchText, setSearchText] = useState("")

  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const { addToast } = useToasts()
  const [isSearching, setIsSearching] = useState(false)

  const [state, dispatch] = useDashboardStateStore()

  const [state1, setState1] = useState([])

  const [showNewActivityEntry, setShowNewActivityEntry] = useState(false)

  const [inActivityEditMode, setInActivityEditMode] = useState(false)
  const [inCommuteEditMode, setInCommuteEditMode] = useState(false)
  const [showNewCommuteEntry, setShowNewCommuteEntry] = useState(false)
  const [tripState, tripStateDispatch] = useTripStateStore()
  const [designerWorkSpaceModified, setDesignerWorkSpaceModified] = useState(
    false
  )
  const [startTime, setStartTime] = useState("")
  const [daysStartTimeInEditMode, setDaysStartTimeInEditMode] = useState(false)

  //This state variable stores the <li> search results when the user uses UP or DOWN arrow to navigate the search results.
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
  const [disableDrag, setDisableDrag] = useState(true)
  const [showHits, setShowHits] = useState(false)
  const [activeSubTab, setActiveSubTab] = useState("map")
  const [markerCoordinates, setMarkerCoordinates] = useState([])
  const [auxMarkerCoordinates, setAuxMarkerCoordinates] = useState([])
  const [editDayDestination, setEditDayDestination] = useState(false)
  const [showMobileMap, setShowMobileMap] = useState(false)
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [isBusy, setIsBusy] = useState(false)
  const [tripDayNotes, setTripDayNotes] = useState("")
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [toggleSelectedItemsMenu, setToggleSelectedItemsMenu] = useState(true)
  const [
    toggleSelectedItemsMenuDropdown,
    setToggleSelectedItemsMenuDropdown,
  ] = useState(false)
  const maxNotesHtmlLen = 1024
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  useEffect(() => {
    try {
      if (state.currentJobInfo.wishlistSyncRequired) {
        if (dbContext) {
          dbContext
            .firestore()
            .collection("jobs")
            .doc(`${authContext.uid}`)
            .collection("active-jobs")
            .doc(`${state.currentJobInfo.jobUid}`)
            .update({
              tripWishlist: state.currentJobInfo.tripWishlist,
            })
            .then(() => {
              console.log("document update successful")
            })
            .catch(e => {
              setIsBusy(false)
              console.log(e)
            })
        }
        dispatch({ type: "wishlistSyncRequired", data: false })
      }
    } catch (error) {
      console.log(error)
    }
  }, [state.currentJobInfo.wishlistSyncRequired])

  useEffect(() => {
    if (inActivityEditMode) setShowNewActivityEntry(true)
  }, [inActivityEditMode])

  useEffect(() => {
    if (inCommuteEditMode) setShowNewCommuteEntry(true)
  }, [inCommuteEditMode])
  useEffect(() => {
    console.log({ tripState })
  }, [tripState])
  useEffect(() => {
    console.log({ canEdit })
  }, [canEdit])
  useEffect(() => {
    try {
      if (activeSubTab === "note") {
        setTripDayNotes(
          state.currentJobInfo.designerWorkspace.itinerary[activeTab].dayNotes
            ? state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                .dayNotes
            : ""
        )
      }
    } catch (err) {
      console.log(err)
    }
  }, [activeSubTab])
  useEffect(() => {
    if (defaultDayId) {
      if (dispatch) dispatch({ type: "setActiveDay", data: defaultDayId })
      setActiveTab(defaultDayId)
      console.log({ defaultDayId })
    } else {
      dispatch({ type: "setActiveDay", data: 0 })
      setActiveTab(0)
    }
  }, [defaultDayId])

  useEffect(() => {
    console.log("@#$%@#$%@#$%@#$%@#$%#%#$5")
    console.log(window.google)
    if (window.google) {
      fetchAndUpdateCommute(true)
    }
  }, [window.google, activeTab])

  useEffect(() => {
    //  recalculateSchedule()
    // geoCodeActivityAddress()

    if (state.currentJobInfo.designerWorkspace !== undefined)
      // dispatch({ type: "setActiveDay", data: 0 })
      // Initialize the selection index and the search results.
      dispatch({ type: "updateSearchResults", data: [] })

    //This clears the flag the triggers a db sync.
    dispatch({ type: "syncSchedule", data: false })
    setSelectedItemIndex(-1)

    try {
      setTripDayNotes(
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].dayNotes
          ? state.currentJobInfo.designerWorkspace.itinerary[activeTab].dayNotes
          : ""
      )
    } catch (err) {
      console.log(err)
    }
  }, [activeTab])

  useEffect(() => {
    console.log("designerWorkSpaceModified: ", designerWorkSpaceModified)
    // Initialize the selection index and the search results.
  }, [designerWorkSpaceModified])

  useEffect(() => {
    console.log("markerCoordinates: ", markerCoordinates)
    // Initialize the selection index and the search results.
  }, [markerCoordinates])
  useEffect(() => {
    console.log("auxMarkerCoordinates: ", auxMarkerCoordinates)
    // Initialize the selection index and the search results.
  }, [auxMarkerCoordinates])

  useEffect(() => {
    try {
      let dayIndex = state.activeDay
      geoCodeActivityAddress(dayIndex)
      // setAuxMarkerCoordinates([])
    } catch (err) {}
    // Initialize the selection index and the search results.
  }, [state.activeDay])

  const handleItineraryUpdate = async () => {
    if (state.currentJobInfo.designerWorkspace.updateCommute) {
      //syncDesignerWorkSpaceDB()
      //update itinerary times

      // refreshCommute()
      // cleanupCommuteBlocks()
      dispatch({ type: "syncCommute", data: false })

      await fetchAndUpdateCommute()
      dispatch({ type: "syncSchedule", data: true })
    }
    if (state.currentJobInfo.designerWorkspace.updateRequired) {
      geoCodeActivityAddress(state.activeDay)
      //syncDesignerWorkSpaceDB()
      //update itinerary times
      console.log("Updating Itinerary Schedule")
      // recalculateSchedule(state.activeDay ? state.activeDay : activeTab)
      dispatch({ type: "syncSchedule", data: false })
      dispatch({ type: "syncStats", data: true })
    }

    if (state.currentJobInfo.designerWorkspace.updateStats) {
      //syncDesignerWorkSpaceDB()
      //update itinerary times
      console.log("Updating Days Stats")
      // refreshDaysStats()
      dispatch({ type: "syncStats", data: false })
      dispatch({ type: "syncDB", data: true })
    }
    if (state.currentJobInfo.designerWorkspace.updateDB) {
      console.log("Updating DB")
      syncDesignerWorkSpaceDB()
      dispatch({ type: "syncDB", data: false })
    }
  }

  useEffect(() => {
    try {
      if (state.activeDay !== undefined) setActiveTab(state.activeDay)
      handleItineraryUpdate()
      //
    } catch (err) {
      console.log(err)
    }
  }, [state.currentJobInfo.designerWorkspace])

  useEffect(() => {
    try {
      if (state.currentJobInfo.designerWorkspace.fetchAndUpdateCommute) {
        fetchAndUpdateCommute(true)
        dispatch({ type: "setFetchAndUpdateCommute", value: false })
      }
    } catch (err) {
      console.log(err)
    }
  }, [state.currentJobInfo.designerWorkspace.fetchAndUpdateCommute])

  useEffect(() => {
    try {
      if (selectedItemIndex != -1) {
        // Highlight the search results item when using UP and DOWN key.
        document.getElementById(
          state.searchResults[selectedItemIndex].activityUid
        ).className = "focus"

        // Update the search bar with the item the user has the focus on using the arrow key.
        document.getElementById("search-field").value =
          state.searchResults[selectedItemIndex].activityName
      }
    } catch (err) {
      console.log(err)
    }
  }, [selectedItemIndex])

  useEffect(() => {
    try {
      if (searchText.length > 0) {
        // When the search text is ready after debounce get the filtered results from firestore.
        if (dbContext) {
          dbContext
            .firestore()
            .collection("activities")
            .doc(`${authContext.uid}`)
            .collection("activities")
            .where("activityNameSplitString", "array-contains", searchText)
            .limit(10)
            .get()
            .then(function(querySnapshot) {
              var a = []
              querySnapshot.forEach(function(doc) {
                a.push(doc.data())
              })
              // Clear previous search results and update the state.activities.searchResults with new results.
              dispatch({ type: "updateSearchResults", data: a })
            })
            .catch(function(error) {
              console.log("Error getting documents: ", error)
            })
        }
      } else {
        // reset when the search input box is cleared.
        dispatch({ type: "updateSearchResults", data: [] })
        setSelectedItemIndex(-1)
      }
    } catch (error) {}
  }, [searchText])

  const onTabChange = tab => {
    console.log("tab changed")
    // Switch tabs for highlighting the tab class.
    dispatch({ type: "setActiveDay", data: tab })

    // geoCodeActivityAddress()
    //setActiveTab(tab)
  }

  useEffect(() => {
    try {
      if (
        state.currentJobInfo.designerWorkspace.syncScheduleForTab === activeTab
      ) {
        console.log("setSyncScheduleForTab")
        dispatch({ type: "syncCommute", data: true })
        dispatch({
          type: "setSyncScheduleForTab",
          data: "",
        })
      }
    } catch (err) {
      console.log(err)
    }
  }, [activeTab])

  const showDaysPlan = () => {
    try {
      if (state.currentJobInfo.designerWorkspace.itinerary != undefined) {
        return state.currentJobInfo.designerWorkspace.itinerary[
          activeTab
        ].date.toDateString()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const showActivitiesType2 = () => {
    try {
      return (
        <div>
          <ReactSortable
            animation="200"
            ghostclassName="sort-ghost-card"
            list={state1}
            setList={setState1}
            group="shared"
            // easing="cubic-bezier(.2, 0, 0, 1)"
          >
            {state1.map(item => (
              <div className="sort-card" key={item.id}>
                <div className="sort-item-data">{item.name}</div>
              </div>
            ))}
          </ReactSortable>
        </div>
        // <ReactSortable
        //   animation="150"
        //   ghostclassName="sort-ghost-card"
        //   list={tripState.itinerary[activeTab].activities}
        //   setList={""}
        //   //group="shared"
        //   // easing="cubic-bezier(.2, 0, 0, 1)"
        // >
        //   {tripState.itinerary[activeTab].activities.map((item, index) => (
        //     <div className="sort-card" key={index}>
        //       <div className="sort-item-data">{item.activity}</div>
        //     </div>
        //   ))}
        // </ReactSortable>
      )
    } catch (error) {
      console.log(error)
    }
  }

  const onMoveActivity = (dayId, itemId, itemIndex) => {
    // var results = parseDayAndItemFromString(e)
    try {
      MoveActivityConfirmationModal({
        onConfirm: dayIndex => {
          console.log({ dayIndex })
          console.log(dayId, itemId, itemIndex)

          if (dayIndex === "9999") {
            //9999 reserved for wishlist
            console.log("I should be here")
            let activityToMove =
              state.currentJobInfo.designerWorkspace.itinerary[dayId]
                .activities[itemIndex].activity

            let findIndex = state.currentJobInfo.tripWishlist.findIndex(
              i => i.activityUid === activityToMove.activityUid
            )
            if (findIndex === -1) {
              // Item not found

              console.log({ activityToMove })
              dispatch({
                type: "moveToWishList",
                overwrite: false,
                item: activityToMove,
              })
              dispatch({ type: "wishlistSyncRequired", data: true })
              dispatch({
                type: "deleteActivity",
                dayId: dayId,
                itemId: itemId,
              })

              dispatch({ type: "syncSchedule", data: true })
            } else {
              //Item already exists prompt to overwrite or save as new
              console.log("Item already present")
              setTimeout(() => {
                ConfirmationActivityOverwriteModal({
                  onConfirm: () => {},
                  onOverwrite: dayIndex => {
                    dispatch({
                      type: "moveToWishList",
                      overwrite: true,
                      item: activityToMove,
                      existingIndex: findIndex,
                    })
                    dispatch({ type: "wishlistSyncRequired", data: true })
                    dispatch({
                      type: "deleteActivity",
                      dayId: dayId,
                      itemId: itemId,
                    })

                    dispatch({ type: "syncCommute", data: true })
                  },
                  onDuplicate: dayIndex => {
                    dispatch({
                      type: "moveToWishList",
                      overwrite: false,
                      item: activityToMove,
                    })
                    dispatch({ type: "wishlistSyncRequired", data: true })
                    dispatch({
                      type: "deleteActivity",
                      dayId: dayId,
                      itemId: itemId,
                    })

                    dispatch({ type: "syncCommute", data: true })
                  },
                  title: "Confirmation",
                  text: "This activity is already in your wishlist",
                  selectedIndex: dayId,
                })
              }, 500)
            }
          } else {
            let activityToMove =
              state.currentJobInfo.designerWorkspace.itinerary[dayId]
                .activities[itemIndex].activity

            dispatch({
              type: "addSearchedActivity",
              activeTab: dayIndex,
              selectedItem: activityToMove,
            })
            console.log(`setSyncScheduleForTab ${dayIndex}`)
            dispatch({
              type: "setSyncScheduleForTab",
              data: dayIndex,
            })

            // dispatch({
            //   type: "moveActivity",
            //   fromDayId: results[1],
            //   toDayId: e,
            //   itemId: results[2],
            // })
            dispatch({
              type: "deleteActivity",
              dayId: dayId,
              itemId: itemId,
            })

            dispatch({ type: "syncCommute", data: true })
          }
        },
        title: "Confirmation",
        startDate:
          typeof state.currentJobInfo.travelDates.start === "string"
            ? new Date(state.currentJobInfo.travelDates.start)
            : state.currentJobInfo.travelDates.start.toDate(),
        endDate:
          typeof state.currentJobInfo.travelDates.end === "string"
            ? new Date(state.currentJobInfo.travelDates.end)
            : state.currentJobInfo.travelDates.end.toDate(),
        text: "Select a destination",
        selectedIndex: dayId,
      })
    } catch (err) {
      console.log(err)
    }
  }
  const onEditActivity = e => {
    console.log("on oEdit")
    setInActivityEditMode(true)
  }

  const onEditCommute = e => {
    console.log("on edit commute")
    setInCommuteEditMode(true)
  }

  const onAddCommute = e => {
    console.log("on add commute")
    setShowNewCommuteEntry(true)
  }

  const onRemoveCommute = e => {}
  const onRemove = e => {
    try {
      var results = parseDayAndItemFromString(e)
      console.log({ results })
      if (results !== []) {
        dispatch({
          type: "deleteActivity",
          dayId: results[1],
          itemId: results[2],
        })
        dispatch({ type: "syncCommute", data: true })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onMoveToBackup = e => {
    try {
      var results = parseDayAndItemFromString(e.currentTarget.id)
      if (results !== []) {
        dispatch({
          type: "moveActivityToBackup",
          dayId: results[1],
          itemId: results[2],
        })
        dispatch({ type: "syncCommute", data: true })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onSelect = (index, dayId, itemId) => {
    try {
      console.log({ index }, { dayId }, { itemId })
      if (index != undefined && dayId != undefined && itemId != undefined) {
        dispatch({
          type: "toggleSelectionForItineraryItem",
          dayId: dayId,
          itemIndex: index,
          itemId: itemId,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onRemoveBackup = (index, dayId) => {
    try {
      if (index != undefined && dayId != undefined) {
        dispatch({
          type: "deleteBackupActivity",
          dayId: dayId,
          itemIndex: index,
        })
        dispatch({ type: "syncCommute", data: true })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const onMoveToActive = (index, dayId) => {
    try {
      console.log(index, dayId)
      if (index != undefined && dayId != undefined) {
        let backupActivity =
          state.currentJobInfo.designerWorkspace.itinerary[dayId]
            .backupActivities[index].activity

        dispatch({
          type: "addSearchedActivity",
          activeTab: dayId,
          selectedItem: backupActivity,
        })
        dispatch({
          type: "deleteBackupActivity",
          dayId: dayId,
          itemIndex: index,
        })
        dispatch({ type: "syncSchedule", data: true })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const retrieveDate = date => {
    try {
      return typeof date === "string"
        ? `${months[new Date(date).getMonth()]} ${new Date(date).getDate()}`
        : `${months[date.toDate().getMonth()]} ${date.toDate().getDate()}`
    } catch (error) {
      console.log(error)
      return `${months[date.getMonth()]} ${date.getDate()}`
    }
  }

  const options = {
    hour: "numeric",
    minute: "numeric",
  }

  const getSafeStartTimeFormatted = () => {
    try {
      return typeof state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime === "string"
        ? moment
            .utc(
              // new Date(
              state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                .dayScheduleStartTime
              // )
            )
            .format("hh:mm")
        : state.currentJobInfo.designerWorkspace.itinerary[
            activeTab
          ].dayScheduleStartTime.toDate()
    } catch (err) {
      console.log(err)
      return state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime
    }
  }

  const getSafeStartTime = () => {
    try {
      return typeof state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime === "string"
        ? new Date(
            state.currentJobInfo.designerWorkspace.itinerary[
              activeTab
            ].dayScheduleStartTime
          )
        : state.currentJobInfo.designerWorkspace.itinerary[
            activeTab
          ].dayScheduleStartTime.toDate()
    } catch (err) {
      console.log(err)
      return state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime
    }
  }

  const getSafeDaysStartTime = () => {
    try {
      return typeof state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .dayScheduleStartTime === "string"
        ? moment
            .utc(
              new Date(
                state.currentJobInfo.designerWorkspace.itinerary[
                  activeTab
                ].dayScheduleStartTime
              )
            )
            .format("hh:mm")
        : Intl.DateTimeFormat("en-US", options).format(
            state.currentJobInfo.designerWorkspace.itinerary[
              activeTab
            ].dayScheduleStartTime.toDate()
          )
    } catch (err) {
      console.log(err)
      return Intl.DateTimeFormat("en-US", options).format(
        state.currentJobInfo.designerWorkspace.itinerary[activeTab]
          .dayScheduleStartTime
      )
    }
  }
  const showDayNotes = () => {
    try {
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].dayNotes
      ) {
        return (
          <div
            style={{ fontSize: "14px", display: "flex", alignItems: "center" }}
          >
            {" "}
            <i
              style={{ color: "#fec600", marginRight: "5px" }}
              className="far fa-clipboard"
            ></i>
            <p style={{ fontSize: "14px", marginRight: "7px" }}>
              {/* This day has{" "} */}
              <span
                onClick={() => {
                  setActiveSubTab("note")
                }}
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
              >
                View day notes.
              </span>
            </p>
            {/* <span>View Notes</span>{" "} */}
          </div>
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  const showFlights = () => {
    try {
      if (
        state.currentJobInfo.flightDetails &&
        state.currentJobInfo.flightDetails.length > 0
      ) {
        let selectedDate = new Date(
          Date.parse(
            state.currentJobInfo.designerWorkspace.itinerary[activeTab].date
          )
        ).toLocaleDateString()
        let fDetails = state.currentJobInfo.flightDetails
        return fDetails.map((fDetail, index) => {
          let itemArriveDate = new Date(
            Date.parse(fDetail.arriveDate)
          ).toLocaleDateString()
          let itemDepartDate = new Date(
            Date.parse(fDetail.departDate)
          ).toLocaleDateString()
          // console.log(selectedDate, itemArriveDate, itemDepartDate)
          let departures,
            arrivals = ""
          if (selectedDate === itemDepartDate) {
            departures = (
              <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex" }}>
                  <i
                    style={{
                      color: "#fec600",
                      marginRight: "7px",
                      fontSize: "14px",
                    }}
                    className="far fa-plane-departure"
                  ></i>{" "}
                  <p
                    style={{
                      color: "rgba(74,74,74,0.8)",
                      textTransform: "uppercase",
                      fontSize: "10px",
                      letterSpacing: "0.5px",
                      fontWeight: "300",
                    }}
                  >
                    Flight Departure
                  </p>
                </div>
                <div
                // className="flight-details"
                // style={{ marginTop: "7px" }}
                >
                  <div>
                    {/* <span style={{ borderTop: "dotted 2px #fec600" }}></span> */}

                    <div
                      style={{
                        // letterSpacing: "0.5px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "rgba(74,74,74,0.8)",
                        // color: "#fec600",
                      }}
                      className="flight-no"
                    >
                      {fDetail.flightNumber} - {fDetail.departCity} [
                      {fDetail.departAirport}]{" "}
                      <span
                        style={{
                          // letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "rgba(74,74,74,0.8)",
                          // color: "#fec600",
                        }}
                      >
                        at {`${fDetail.departTime}`}{" "}
                      </span>
                      <span
                        style={{
                          letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "10px",
                          color: "rgba(74,74,74,0.8)",
                          marginLeft: "5px",
                          textDecoration: "underline",
                          textDecorationColor: "#fec600",
                          cursor: "pointer",
                          textTransform: "uppercase",
                          // color: "#fec600",
                        }}
                        onClick={() => {
                          navigate(
                            `/app/byottripdetails/${state.tripDetails.tripUid}?tab=flights`
                          )
                        }}
                      >
                        view details
                      </span>
                    </div>
                  </div>
                  {/* <div style={{ marginLeft: "0px" }}>
                    <div
                      className="depart-airport"
                      style={{
                        // textAlign: "center",
                        fontSize: "14px",
                        // marginBottom: "0px",
                        fontWeight: "500",
                      }}
                    ></div>
                  </div> */}
                </div>
              </div>
            )
          }
          if (selectedDate === itemArriveDate) {
            arrivals = (
              <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex" }}>
                  <i
                    style={{
                      color: "#fec600",
                      marginRight: "7px",
                      fontSize: "14px",
                    }}
                    className="far fa-plane-arrival"
                  ></i>{" "}
                  <p
                    style={{
                      color: "rgba(74,74,74,0.8)",
                      textTransform: "uppercase",
                      fontSize: "10px",
                      letterSpacing: "0.5px",
                      fontWeight: "300",
                    }}
                  >
                    Flight Arrival
                  </p>
                </div>
                <div
                // className="flight-details"
                // style={{ marginTop: "15px" }}
                >
                  <div>
                    {/* <span style={{ borderTop: "dotted 2px #fec600" }}></span> */}

                    <div
                      style={{
                        // letterSpacing: "0.5px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "rgba(74,74,74,0.8)",
                        // color: "#fec600",
                      }}
                      className="flight-no"
                    >
                      {fDetail.flightNumber} - {fDetail.arriveCity} [
                      {fDetail.arriveAirport}]{" "}
                      <span
                        style={{
                          // letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "rgba(74,74,74,0.8)",
                          // color: "#fec600",
                        }}
                      >
                        at {`${fDetail.arriveTime}`}{" "}
                      </span>
                      <span
                        style={{
                          letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "10px",
                          color: "rgba(74,74,74,0.8)",
                          marginLeft: "5px",
                          textDecoration: "underline",
                          textDecorationColor: "#fec600",
                          cursor: "pointer",
                          textTransform: "uppercase",
                          // color: "#fec600",
                        }}
                        onClick={() => {
                          navigate(
                            `/app/byottripdetails/${state.tripDetails.tripUid}?tab=flights`
                          )
                        }}
                      >
                        view details
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          return (
            <>
              {" "}
              {departures}
              {arrivals}
            </>
          )
          if (
            selectedDate === itemArriveDate ||
            selectedDate === itemDepartDate
          ) {
            return (
              <>
                <div style={{ display: "flex" }}>
                  <i
                    style={{
                      color: "#fec600",
                      marginRight: "7px",
                      fontSize: "20px",
                    }}
                    className="far fa-plane"
                  ></i>{" "}
                  <p
                    style={{
                      color: "rgba(74,74,74,0.8)",
                      textTransform: "uppercase",
                      fontSize: "12px",
                      letterSpacing: "0.5px",
                      fontWeight: "500",
                    }}
                  >
                    Today's Flight
                  </p>
                </div>
                <div className="flight-details">
                  <div className="depart-details">
                    <div
                      className="depart-airport"
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        marginBottom: "0px",
                      }}
                    >
                      {fDetail.departAirport}
                    </div>
                    <div
                      className="depart-city"
                      style={{
                        textAlign: "center",
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "300",
                        letterSpacing: "0.5px",
                      }}
                    >
                      {fDetail.departCity}
                    </div>
                    <div
                      className="arrive-date"
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        marginTop: "7px",
                        weight: "500",
                      }}
                    >{`${fDetail.departTime}`}</div>
                  </div>
                  <div className="flight-no-details">
                    {/* <span style={{ borderTop: "dotted 2px #fec600" }}></span> */}
                    <i
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        color: "#fec600",
                        marginBottom: "5px",
                      }}
                      className="fal fa-plane"
                    ></i>
                    {/* <div
                    style={{
                      letterSpacing: "0.5px",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                    className="flight-no"
                  >
                    {fDetail.flightNumber}
                  </div> */}
                  </div>

                  <div className="arrive-details">
                    <div
                      className="arrive-airport"
                      style={{
                        textAlign: "center",
                        fontSize: "16px",

                        marginBottom: "0px",
                      }}
                    >
                      {fDetail.arriveAirport}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "10px",
                        marginBottom: "0px",
                        fontWeight: "300",
                        letterSpacing: "0.5px",
                      }}
                      className="arrive-city"
                    >
                      {fDetail.arriveCity}
                    </div>
                    <div
                      className="arrive-date"
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        marginTop: "7px",
                        weight: "500",
                      }}
                    >{`${fDetail.arriveTime}`}</div>
                  </div>
                </div>
              </>
            )
          }
        })
      } else
        return (
          <>
            {canEdit ? (
              <p
                onClick={() => {
                  navigate(
                    `/app/byottripdetails/${state.tripDetails.tripUid}?tab=flights`
                  )
                }}
                style={{
                  letterSpacing: "0.5px",
                  fontWeight: "400",
                  fontSize: "10px",
                  color: "rgba(74,74,74,0.8)",
                  marginLeft: "5px",
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                  cursor: "pointer",
                  textTransform: "uppercase",
                  marginBottom: "5px",
                  // color: "#fec600",
                }}
              >
                <i
                  class="fas fa-plane-departure"
                  style={{
                    textDecoration: "none",
                    color: "#fec600",
                    marginRight: "3px",
                  }}
                ></i>{" "}
                Add flight info
              </p>
            ) : (
              ""
            )}
          </>
        )
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const showBookings = () => {
    try {
      if (
        state.currentJobInfo.tripReservations &&
        state.currentJobInfo.tripReservations.length > 0
      ) {
        let selectedDate = new Date(
          Date.parse(
            state.currentJobInfo.designerWorkspace.itinerary[activeTab].date
          )
        ).toLocaleDateString()
        let trDetails = state.currentJobInfo.tripReservations
        return trDetails.map((trDetail, index) => {
          let itemDate = new Date(
            Date.parse(trDetail.reservationDate)
          ).toLocaleDateString()
          // console.log({ itemDate })
          if (selectedDate === itemDate) {
            return (
              <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex" }}>
                  <i
                    style={{
                      color: "#fec600",
                      marginRight: "7px",
                      fontSize: "14px",
                    }}
                    className="far fa-ticket"
                  ></i>{" "}
                  <p
                    style={{
                      color: "rgba(74,74,74,0.8)",
                      textTransform: "uppercase",
                      fontSize: "10px",
                      letterSpacing: "0.5px",
                      fontWeight: "300",
                    }}
                  >
                    Reservation:
                  </p>
                </div>
                <div
                // className="flight-details"
                // style={{ marginTop: "7px" }}
                >
                  <div>
                    {/* <span style={{ borderTop: "dotted 2px #fec600" }}></span> */}

                    <div
                      style={{
                        // letterSpacing: "0.5px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "rgba(74,74,74,0.8)",
                        // color: "#fec600",
                      }}
                      className="flight-no"
                    >
                      {trDetail.name}{" "}
                      <span
                        style={{
                          // letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "rgba(74,74,74,0.8)",
                          // color: "#fec600",
                        }}
                      >
                        at {`${trDetail.reservationTime}`}{" "}
                      </span>
                      <span
                        style={{
                          letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "10px",
                          color: "rgba(74,74,74,0.8)",
                          marginLeft: "5px",
                          textDecoration: "underline",
                          textDecorationColor: "#fec600",
                          cursor: "pointer",
                          textTransform: "uppercase",
                          // color: "#fec600",
                        }}
                        onClick={() => {
                          navigate(
                            `/app/byottripdetails/${state.tripDetails.tripUid}?tab=bookings`
                          )
                        }}
                      >
                        view details
                      </span>
                    </div>
                  </div>
                  {/* <div style={{ marginLeft: "0px" }}>
                  <div
                    className="depart-airport"
                    style={{
                      // textAlign: "center",
                      fontSize: "14px",
                      // marginBottom: "0px",
                      fontWeight: "500",
                    }}
                  ></div>
                </div> */}
                </div>
              </div>
            )
          }
        })
      } else return ""
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const showRentalCars = () => {
    try {
      if (
        state.currentJobInfo.rentalCarDetails &&
        state.currentJobInfo.rentalCarDetails.length > 0
      ) {
        let selectedDate = new Date(
          Date.parse(
            state.currentJobInfo.designerWorkspace.itinerary[activeTab].date
          )
        ).toLocaleDateString()
        let rcDetails = state.currentJobInfo.rentalCarDetails
        return rcDetails.map((rcDetail, index) => {
          let itemPickupDate = new Date(
            Date.parse(rcDetail.pickupDate)
          ).toLocaleDateString()
          let itemDropOffDate = new Date(
            Date.parse(rcDetail.dropOffDate)
          ).toLocaleDateString()
          // console.log(selectedDate, itemPickupDate, itemDropOffDate)
          let dropOffs,
            pickups = ""
          if (selectedDate === itemPickupDate) {
            pickups = (
              <div style={{ marginBottom: "0px" }}>
                <div style={{ display: "flex" }}>
                  <i
                    style={{
                      color: "#fec600",
                      marginRight: "7px",
                      fontSize: "14px",
                    }}
                    className="far fa-car"
                  ></i>{" "}
                  <p
                    style={{
                      color: "rgba(74,74,74,0.8)",
                      textTransform: "uppercase",
                      fontSize: "10px",
                      letterSpacing: "0.5px",
                      fontWeight: "300",
                    }}
                  >
                    Rental Cap Pick-up
                  </p>
                </div>
                <div
                // className="flight-details"
                // style={{ marginTop: "7px" }}
                >
                  <div>
                    {/* <span style={{ borderTop: "dotted 2px #fec600" }}></span> */}

                    <div
                      style={{
                        // letterSpacing: "0.5px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "rgba(74,74,74,0.8)",
                        // color: "#fec600",
                      }}
                      className="flight-no"
                    >
                      {rcDetail.agencyName}{" "}
                      <span
                        style={{
                          // letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "rgba(74,74,74,0.8)",
                          // color: "#fec600",
                        }}
                      >
                        at {`${rcDetail.pickupTime}`}{" "}
                      </span>
                      <span
                        style={{
                          letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "10px",
                          color: "rgba(74,74,74,0.8)",
                          marginLeft: "5px",
                          textDecoration: "underline",
                          textDecorationColor: "#fec600",
                          cursor: "pointer",
                          textTransform: "uppercase",
                          // color: "#fec600",
                        }}
                        onClick={() => {
                          navigate(
                            `/app/byottripdetails/${state.tripDetails.tripUid}?tab=cars`
                          )
                        }}
                      >
                        view details
                      </span>
                    </div>
                  </div>
                  {/* <div style={{ marginLeft: "0px" }}>
                  <div
                    className="depart-airport"
                    style={{
                      // textAlign: "center",
                      fontSize: "14px",
                      // marginBottom: "0px",
                      fontWeight: "500",
                    }}
                  ></div>
                </div> */}
                </div>
              </div>
            )
          }
          if (selectedDate === itemDropOffDate) {
            dropOffs = (
              <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex" }}>
                  <i
                    style={{
                      color: "#fec600",
                      marginRight: "7px",
                      fontSize: "14px",
                    }}
                    className="far fa-car"
                  ></i>{" "}
                  <p
                    style={{
                      color: "rgba(74,74,74,0.8)",
                      textTransform: "uppercase",
                      fontSize: "10px",
                      letterSpacing: "0.5px",
                      fontWeight: "300",
                    }}
                  >
                    Rental Car Drop-off
                  </p>
                </div>
                <div
                // className="flight-details"
                // style={{ marginTop: "15px" }}
                >
                  <div>
                    {/* <span style={{ borderTop: "dotted 2px #fec600" }}></span> */}

                    <div
                      style={{
                        // letterSpacing: "0.5px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "rgba(74,74,74,0.8)",
                        // color: "#fec600",
                      }}
                      className="flight-no"
                    >
                      {rcDetail.agencyName}{" "}
                      <span
                        style={{
                          // letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "rgba(74,74,74,0.8)",
                          // color: "#fec600",
                        }}
                      >
                        at {`${rcDetail.dropOffTime}`}{" "}
                      </span>
                      <span
                        style={{
                          letterSpacing: "0.5px",
                          fontWeight: "400",
                          fontSize: "10px",
                          color: "rgba(74,74,74,0.8)",
                          marginLeft: "5px",
                          textDecoration: "underline",
                          textDecorationColor: "#fec600",
                          cursor: "pointer",
                          textTransform: "uppercase",
                          // color: "#fec600",
                        }}
                        onClick={() => {
                          navigate(
                            `/app/byottripdetails/${state.tripDetails.tripUid}?tab=cars`
                          )
                        }}
                      >
                        view details
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          return (
            <>
              {" "}
              {pickups}
              {dropOffs}
            </>
          )
        })
      } else
        return (
          <>
            {canEdit ? (
              <p
                onClick={() => {
                  navigate(
                    `/app/byottripdetails/${state.tripDetails.tripUid}?tab=cars`
                  )
                }}
                style={{
                  letterSpacing: "0.5px",
                  fontWeight: "400",
                  fontSize: "10px",
                  color: "rgba(74,74,74,0.8)",
                  marginLeft: "5px",
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                  cursor: "pointer",
                  textTransform: "uppercase",
                  marginBottom: "5px",
                  // color: "#fec600",
                }}
              >
                <i
                  class="fas fa-car-side"
                  style={{
                    textDecoration: "none",
                    color: "#fec600",
                    marginRight: "3px",
                  }}
                ></i>{" "}
                Add rental car info
              </p>
            ) : (
              ""
            )}
          </>
        )
    } catch (error) {
      console.log(error)
      return ""
    }
  }
  const customNavigateTo = url => {
    window.open(url, "_blank")
  }
  const LaunchGoogleDirections = cObj => {
    try {
      const gpsRegX = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
      const gpsRegX2 = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
      var previousActivityResult = gpsRegX.exec(cObj.from)
      var nextActivityResult = gpsRegX2.exec(cObj.to)
      console.log({ previousActivityResult })
      console.log({ nextActivityResult })
      var encodedSource = encodeURI(
        previousActivityResult ? previousActivityResult : cObj.from
      )
      var encodedDestination = encodeURI(
        nextActivityResult ? nextActivityResult : cObj.to
      )
      var encodedTravelMode = encodeURI(cObj.commuteMode)
      let finalURI = `https://www.google.com/maps/dir/?api=1&origin=${encodedSource}&destination=${encodedDestination}&travelmode=${encodedTravelMode}`
      console.log({ finalURI })

      if (finalURI !== "") return finalURI
    } catch (error) {
      console.log(error)
    }
  }
  const accommodationAndAttractionCommute = (type, aDetail) => {
    try {
      let source = ""
      let destination = ""
      let sourceName = ""
      let destinationName = ""
      let searchString = location.search
      var ext = ""
      const regex = /day=(\d*)/g

      while ((m = regex.exec(searchString)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
          // console.log(`Found match: ${match}`)
          ext = match
        })
      }
      console.log({ ext })
      const index = ext === "" ? 0 : parseInt(ext)

      let activitiesArray = ""

      activitiesArray =
        state.currentJobInfo.designerWorkspace.itinerary[index].activities
      if (type === "accommodationToFirstAttractionCommute") {
        source = aDetail.accommodationAddress
        sourceName = aDetail.accommodationName
        destination = activitiesArray[0].activity.activityAddress
        destinationName = activitiesArray[0].activity.activityName
      }

      if (type === "LastAttractionToAccommodationCommute") {
        source =
          activitiesArray[activitiesArray.length - 1].activity.activityAddress
        sourceName =
          activitiesArray[activitiesArray.length - 1].activity.activityName
        destination = aDetail.accommodationAddress
        destinationName = aDetail.accommodationName
      }

      console.log({ source, destination })
      if (source && destination) {
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "7px",
                padding: "5px 10px",
                // background: "#f7f7f7",
                borderRadius: "6px",
                cursor: "pointer",
              }}
              onClick={() => {
                let commuteObj = {
                  from: source,
                  to: destination,
                  commuteMode: "driving",
                }
                customNavigateTo(LaunchGoogleDirections(commuteObj))
              }}
            >
              <div>
                <span
                  style={{
                    fontWeight: "400",
                    // textAlign: "center",
                    fontSize: "12px",
                    // color: "#fec600",
                    marginRight: "10px",
                    // maxWidth: "100px",
                  }}
                >
                  {dottedText(sourceName, 35)}
                </span>
                <span>
                  <i
                    style={{
                      fontWeight: "400",
                      // textAlign: "center",
                      fontSize: "14px",
                      color: "#fec600",
                      marginRight: "10px",
                    }}
                    class="fas fa-long-arrow-right"
                  ></i>
                </span>
                <span
                  style={{
                    fontWeight: "400",
                    // textAlign: "center",
                    fontSize: "12px",
                    // color: "#fec600",
                    marginRight: "10px",
                    // maxWidth: "100px",
                  }}
                >
                  {dottedText(destinationName, 35)}
                </span>
              </div>
              <div
                style={{
                  fontWeight: "400",
                  // textAlign: "center",
                  fontSize: "12px",
                  // color: "#fec600",
                  marginRight: "7px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    letterSpacing: "0.5px",
                    fontWeight: "400",
                    fontSize: "14px",
                    // color: "rgba(74,74,74,0.8)",
                    color: "#fec600",
                    marginRight: "5px",
                    textDecorationColor: "#fec600",
                    textDecoration: "underline",
                    textDecorationColor: "#fec600",

                    textTransform: "uppercase",
                  }}
                  className="branded-text-decoration"
                >
                  <i class="fas fa-directions"></i>
                </span>
                <DisplayCommute
                  sourceAddress={source}
                  destinationAddress={destination}
                />
              </div>
            </div>
          </>
        )
      } else return ""
    } catch (error) {
      console.log(error)
      return ""
    }
  }
  // const flightsAndAccommodationCommute = (type, aDetail) => {
  //   try {
  //     let source = ""
  //     let destination = ""
  //     let sourceName = ""
  //     let destinationName = ""
  //     let searchString = location.search
  //     var ext = ""
  //     const regex = /day=(\d*)/g

  //     while ((m = regex.exec(searchString)) !== null) {
  //       // This is necessary to avoid infinite loops with zero-width matches
  //       if (m.index === regex.lastIndex) {
  //         regex.lastIndex++
  //       }

  //       // The result can be accessed through the `m`-variable.
  //       m.forEach((match, groupIndex) => {
  //         // console.log(`Found match: ${match}`)
  //         ext = match
  //       })
  //     }
  //     console.log({ ext })
  //     const index = ext === "" ? 0 : parseInt(ext)

  //     let activitiesArray = ""

  //     activitiesArray =
  //       state.currentJobInfo.designerWorkspace.itinerary[index].activities
  //     if (type === "accommodationToFirstAttractionCommute") {
  //       source = aDetail.accommodationAddress
  //       sourceName = aDetail.accommodationName
  //       destination = activitiesArray[0].activity.activityAddress
  //       destinationName = activitiesArray[0].activity.activityName
  //     }

  //     if (type === "LastAttractionToAccommodationCommute") {
  //       source =
  //         activitiesArray[activitiesArray.length - 1].activity.activityAddress
  //       sourceName =
  //         activitiesArray[activitiesArray.length - 1].activity.activityName
  //       destination = aDetail.accommodationAddress
  //       destinationName = aDetail.accommodationName
  //     }

  //     console.log({ source, destination })
  //     if (source && destination) {
  //       return (
  //         <>
  //           <div style={{ display: "flex" }}>
  //             <span
  //               style={{
  //                 fontWeight: "400",
  //                 // textAlign: "center",
  //                 fontSize: "12px",
  //                 // color: "#fec600",
  //                 margin: "0px 10px",
  //               }}
  //             >
  //               {sourceName}
  //             </span>
  //             <span>
  //               <i
  //                 style={{
  //                   fontWeight: "400",
  //                   // textAlign: "center",
  //                   fontSize: "14px",
  //                   color: "#fec600",
  //                   marginRight: "10px",
  //                 }}
  //                 class="fas fa-long-arrow-right"
  //               ></i>
  //             </span>
  //             <span
  //               style={{
  //                 fontWeight: "400",
  //                 // textAlign: "center",
  //                 fontSize: "12px",
  //                 // color: "#fec600",
  //                 marginRight: "10px",
  //               }}
  //             >
  //               {destinationName}
  //             </span>
  //             <span
  //               style={{
  //                 fontWeight: "400",
  //                 // textAlign: "center",
  //                 fontSize: "12px",
  //                 // color: "#fec600",
  //                 marginRight: "10px",
  //               }}
  //             >
  //               <DisplayCommute
  //                 sourceAddress={source}
  //                 destinationAddress={destination}
  //               />
  //             </span>
  //           </div>
  //         </>
  //       )
  //     } else return ""
  //   } catch (error) {
  //     console.log(error)
  //     return ""
  //   }
  // }

  const showAccommodations = () => {
    try {
      if (
        state.currentJobInfo.accommodationDetails &&
        state.currentJobInfo.accommodationDetails.length > 0
      ) {
        let selectedDate = new Date(
          Date.parse(
            state.currentJobInfo.designerWorkspace.itinerary[activeTab].date
          )
        ).toLocaleDateString()
        let aDetails = state.currentJobInfo.accommodationDetails
        return aDetails.map((aDetail, index) => {
          let itemStartDate = new Date(
            Date.parse(aDetail.fromDate)
          ).toLocaleDateString()
          let itemEndDate = new Date(
            Date.parse(aDetail.toDate)
          ).toLocaleDateString()
          // console.log(selectedDate, itemStartDate, itemEndDate)
          // if (
          //   Date.parse(selectedDate) >= Date.parse(itemStartDate) &&
          //   Date.parse(selectedDate) <= Date.parse(itemEndDate)
          // ) {
          //   // add hotel markers'
          //   console.log("found")
          //   console.log({ aDetail })
          //   if (
          //     aDetail.accommodationAddress &&
          //     aDetail.accommodationAddressLatLng &&
          //     aDetail.accommodationUid
          //   ) {
          //     if (
          //       markerCoordinates.some(
          //         item => item.uid === aDetail.accommodationUid
          //       )
          //     ) {
          //       console.log("aux marker entry already present.")
          //       console.log({ markerCoordinates })
          //     } else {
          //       let a = {
          //         ...aDetail.accommodationAddressLatLng,
          //         name: aDetail.accommodationName,
          //         uid: aDetail.accommodationUid,
          //         type: "accommodation",
          //         index: aDetail.accommodationName,
          //         aId: "accommodation",
          //       }
          //       setMarkerCoordinates([...markerCoordinates, a])
          //     }
          //   }
          // }

          if (selectedDate === itemStartDate) {
            return (
              <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex" }}>
                  <i
                    style={{ color: "#fec600", marginRight: "7px" }}
                    className="far fa-bed"
                  ></i>{" "}
                  <p
                    style={{
                      color: "rgba(74,74,74,0.8)",
                      textTransform: "uppercase",
                      fontSize: "10px",
                      letterSpacing: "0.5px",
                      fontWeight: "300",
                    }}
                  >
                    Check-in
                  </p>
                </div>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {aDetail.accommodationName}{" "}
                  <span
                    style={{
                      letterSpacing: "0.5px",
                      fontWeight: "400",
                      fontSize: "10px",
                      color: "rgba(74,74,74,0.8)",
                      marginLeft: "5px",
                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                      cursor: "pointer",
                      textTransform: "uppercase",
                      // color: "#fec600",
                    }}
                    onClick={() => {
                      navigate(
                        `/app/byottripdetails/${state.tripDetails.tripUid}?tab=lodging`
                      )
                    }}
                  >
                    view details
                  </span>
                  {/* <span
                    style={{
                      letterSpacing: "0.5px",
                      fontWeight: "400",
                      fontSize: "10px",
                      color: "rgba(74,74,74,0.8)",
                      marginLeft: "5px",
                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                      cursor: "pointer",
                      textTransform: "uppercase",
                      // color: "#fec600",
                    }}
                    onClick={() => {
                      computeAccommodationToFirstAttractionCommute(
                        aDetail.accommodationAddress
                      )
                    }}
                  >
                    view details
                  </span> */}
                  {accommodationAndAttractionCommute(
                    "accommodationToFirstAttractionCommute",
                    aDetail
                  )}
                  {accommodationAndAttractionCommute(
                    "LastAttractionToAccommodationCommute",
                    aDetail
                  )}
                  {/* <span
                    style={{
                      letterSpacing: "0.5px",
                      fontWeight: "400",
                      fontSize: "10px",
                      color: "rgba(74,74,74,0.8)",
                      marginLeft: "5px",
                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                      cursor: "pointer",
                      textTransform: "uppercase",
                      // color: "#fec600",
                    }}
                    onClick={() => {
                      addAccommodationToItinerary(aDetail, "end")
                    }}
                  >
                    Add to itinerary
                  </span> */}
                </p>
              </div>
            )
          }
          if (
            Date.parse(selectedDate) > Date.parse(itemStartDate) &&
            Date.parse(selectedDate) < Date.parse(itemEndDate)
          ) {
            return (
              <div style={{ marginBottom: "30px" }}>
                <div style={{ display: "flex" }}>
                  <i
                    style={{ color: "#fec600", marginRight: "7px" }}
                    className="far fa-bed"
                  ></i>{" "}
                  <p
                    style={{
                      color: "rgba(74,74,74,0.8)",
                      textTransform: "uppercase",
                      fontSize: "10px",
                      letterSpacing: "0.5px",
                      fontWeight: "300",
                    }}
                  >
                    Staying at
                  </p>
                </div>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {aDetail.accommodationName}{" "}
                  <span
                    style={{
                      letterSpacing: "0.5px",
                      fontWeight: "400",
                      fontSize: "10px",
                      color: "rgba(74,74,74,0.8)",
                      marginLeft: "5px",
                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                      cursor: "pointer",
                      textTransform: "uppercase",
                      // color: "#fec600",
                    }}
                    onClick={() => {
                      navigate(
                        `/app/byottripdetails/${state.tripDetails.tripUid}?tab=lodging`
                      )
                    }}
                  >
                    view details
                  </span>
                  {accommodationAndAttractionCommute(
                    "accommodationToFirstAttractionCommute",
                    aDetail
                  )}
                  {accommodationAndAttractionCommute(
                    "LastAttractionToAccommodationCommute",
                    aDetail
                  )}
                </p>
              </div>
            )
          }
          if (selectedDate === itemEndDate) {
            return (
              <div style={{ marginBottom: "30px" }}>
                <div style={{ display: "flex" }}>
                  <i
                    style={{ color: "#fec600", marginRight: "7px" }}
                    className="far fa-bed"
                  ></i>{" "}
                  <p
                    style={{
                      color: "rgba(74,74,74,0.8)",
                      textTransform: "uppercase",
                      fontSize: "10px",
                      letterSpacing: "0.5px",
                      fontWeight: "300",
                    }}
                  >
                    Check-out
                  </p>
                </div>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  {aDetail.accommodationName}{" "}
                  <span
                    style={{
                      letterSpacing: "0.5px",
                      fontWeight: "400",
                      fontSize: "10px",
                      color: "rgba(74,74,74,0.8)",
                      marginLeft: "5px",
                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                      cursor: "pointer",
                      textTransform: "uppercase",
                      // color: "#fec600",
                    }}
                    onClick={() => {
                      navigate(
                        `/app/byottripdetails/${state.tripDetails.tripUid}?tab=lodging`
                      )
                    }}
                  >
                    view details
                  </span>
                  {accommodationAndAttractionCommute(
                    "LastAttractionToAccommodationCommute",
                    aDetail
                  )}
                </p>
              </div>
            )
          }
        })
      } else
        return (
          <>
            {canEdit ? (
              <p
                onClick={() => {
                  navigate(
                    `/app/byottripdetails/${state.tripDetails.tripUid}?tab=lodging`
                  )
                }}
                style={{
                  letterSpacing: "0.5px",
                  fontWeight: "400",
                  fontSize: "10px",
                  color: "rgba(74,74,74,0.8)",
                  marginLeft: "5px",
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                  cursor: "pointer",
                  textTransform: "uppercase",
                  marginBottom: "5px",
                  // color: "#fec600",
                }}
              >
                <i
                  class="fas fa-bed"
                  style={{
                    textDecoration: "none",
                    color: "#fec600",
                    marginRight: "3px",
                  }}
                ></i>{" "}
                Add accommodation info
              </p>
            ) : (
              " "
            )}
          </>
        )
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const showActivities = () => {
    try {
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab] !==
        undefined
      ) {
        var itemCount =
          state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
            .length
        if (itemCount === 0) {
          //If no activities on a selected day
          return (
            // <div className="no-activities-image" style={{}}>
            <div className="" style={{ minHeight: "150px", marginTop: "40px" }}>
              {/* <img style={{}} src={noevents} alt="noevents" /> */}
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  // textTransform: "uppercase",
                  letterSpacing: "0.5px",
                }}
                align="center"
              >
                {`No activities for
                ${retrieveDate(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .date
                )}`}
              </p>
              {/* {canEdit && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "50px",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{
                      textAlign: "center",
                      fontWeight: "600",

                      color: "rgba(74, 74, 74, 0.8)",

                      textDecorationColor: "#fec600",
                      textDecoration: "underline",
                      margin: "20px",
                    }}
                    onClick={() => {
                      setShowNewActivityEntry(true)
                    }}
                  >
                    <i
                      className="far fa-plus"
                      style={{ color: "#fec600", marginRight: "5px" }}
                    ></i>
                    <span
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        color: "rgba(74, 74, 74, 0.8)",
                      }}
                    >
                      Add new activity
                    </span>
                  </a>
                  <p
                    style={{
                      margin: "10px",
                      fontWeight: "300",
                      fontSize: "12px",
                    }}
                  >
                    OR
                  </p>
                  <a
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      textDecorationColor: "#fec600",
                      color: "rgba(74, 74, 74, 0.8)",
                      textDecoration: "underline",
                      textDecorationColor: "#fec600 !important",
                      margin: "20px",
                    }}
                    onClick={() => {
                      // setShowNewActivityEntry(true)
                      navigate(
                        `/app/byottripdetails/${state.tripDetails.tripUid}?tab=wishlist`
                      )
                    }}
                    className="a "
                  >
                    <i
                      className="far fa-arrow-right"
                      style={{ color: "#fec600", marginRight: "5px" }}
                    ></i>
                    <span
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        // textDecorationColor: "#fec600",
                        color: "rgba(74, 74, 74, 0.8)",
                        textDecoration: "underline",
                        textDecorationColor: "#fec600 !important",
                      }}
                    >
                      Add from wishlist
                    </span>
                  </a>
                </div>
              )} */}
            </div>
          )
        } else {
          //Show activities for a selected day

          return (
            <>
              <p
                className="align-center"
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  marginTop: "20px",
                }}
              >
                {/* {`Itinerary for
                 ${retrieveDate(
                   state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                     .date
                 )}`} */}
              </p>
              {daysStartTimeInEditMode ? (
                <div style={{ maxWidth: "100%", marginLeft: "0px" }}>
                  <p>Pick activities start time:</p>
                  <TimePicker
                    value={
                      startTime === "" || startTime === null
                        ? getSafeStartTimeFormatted()
                        : startTime
                    }
                    onChange={time => {
                      console.log({ time })
                      // var res = time.split(":")
                      setStartTime(time)
                      // console.log({ res })
                      // dispatch({ type: "setSunriseTime", data: time })
                    }}
                  />
                  <div style={{ margin: "15px 0" }}>
                    <button
                      className="button"
                      onClick={() => setDaysStartTimeInEditMode(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="button is-warning"
                      style={{ marginLeft: "10px", float: "right" }}
                      onClick={() => {
                        if (startTime !== "" && startTime !== null) {
                          console.log(startTime)
                          var res = startTime.split(":")
                          var newTime = getSafeStartTime()
                          console.log(newTime)
                          newTime.setUTCHours(Number(res[0]), Number(res[1]))
                          console.log(newTime)
                          dispatch({
                            type: "updateDaysActivityStatTime",
                            index: activeTab,
                            data: newTime.toUTCString(),
                          })
                          dispatch({ type: "syncSchedule", data: true })
                          setDaysStartTimeInEditMode(false)
                        }
                      }}
                    >
                      Save time
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "40px",
                    lineHeight: "40px",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "left",
                    display: "none",
                    //marginLeft: "-40px",
                  }}
                >
                  <p>
                    {getSafeDaysStartTime()}{" "}
                    {canEdit && (
                      <a
                        className=""
                        onClick={() => setDaysStartTimeInEditMode(true)}
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          textTransform: "uppercase",
                          marginLeft: "5px",
                          color: "#fec600",
                        }}
                      >
                        <i className="far fa-edit"></i>
                      </a>
                    )}
                  </p>
                </div>
              )}

              <SortableList
                items={
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .activities
                }
                backupItems={
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .backupActivities
                }
                onSortEnd={onSortEnd}
                onMoveUp={onSortEnd}
                onMoveDown={onSortEnd}
                onSortStart={onSortStart}
                shouldCancelStart={shouldCancelStart}
                onRemove={onRemove}
                onMoveToBackup={onMoveToBackup}
                activeTab={activeTab}
                onEditActivity={onEditActivity}
                onMoveActivity={onMoveActivity}
                onEditCommute={onEditCommute}
                onAddCommute={onAddCommute}
                onRemoveCommute={onRemoveCommute}
                disableDrag={disableDrag}
                canEdit={canEdit}
                lockAxis="y"
                distance={10}
                onSelect={onSelect}
                isItineraryBusy={ItineraryBusy}
                // useDragHandle={true}
                // hideSortableGhost={true}
              />
              {/* {canEdit && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "50px",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      textDecorationColor: "#fec600",
                      color: "rgba(74, 74, 74, 0.8)",
                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                      margin: "20px",
                    }}
                    onClick={() => {
                      setShowNewActivityEntry(true)
                    }}
                    className="a "
                  >
                    <i
                      className="far fa-plus"
                      style={{ color: "#fec600", marginRight: "5px" }}
                    ></i>
                    <span
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        textDecorationColor: "#fec600",
                        color: "rgba(74, 74, 74, 0.8)",
                        textDecoration: "underline",
                        textDecorationColor: "#fec600 !important",
                      }}
                    >
                      Add a new activity
                    </span>
                  </a>
                  <p
                    style={{
                      margin: "10px",
                      fontWeight: "300",
                      fontSize: "12px",
                    }}
                  >
                    OR
                  </p>
                  <a
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      textDecorationColor: "#fec600",
                      color: "rgba(74, 74, 74, 0.8)",
                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                      margin: "20px",
                    }}
                    onClick={() => {
                      // setShowNewActivityEntry(true)
                      navigate(
                        `/app/byottripdetails/${state.tripDetails.tripUid}?tab=wishlist`
                      )
                    }}
                    className="a "
                  >
                    <i
                      className="far fa-arrow-right"
                      style={{ color: "#fec600", marginRight: "5px" }}
                    ></i>
                    <span
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        textDecorationColor: "#fec600",
                        color: "rgba(74, 74, 74, 0.8)",
                        textDecoration: "underline",
                        textDecorationColor: "#fec600 !important",
                      }}
                    >
                      Add from wishlist
                    </span>
                  </a>
                </div>
              )} */}
            </>
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const showBackupActivities = () => {
    try {
      // if (
      //   state.currentJobInfo.designerWorkspace.itinerary[activeTab] !==
      //   undefined
      // ) {

      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab] ===
          undefined ||
        state.currentJobInfo.designerWorkspace.itinerary[activeTab]
          .backupActivities.length === 0
      ) {
        //If no activities on a selected day
        return (
          <div className="" style={{}}>
            {/* <img style={{}} src={noevents} alt="noevents" /> */}
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                // textTransform: "uppercase",
                letterSpacing: "0.5px",
              }}
              align="center"
            >
              {`- No backup activities for
                ${retrieveDate(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .date
                )} -`}
            </p>
          </div>
        )
      } else {
        //Show activities for a selected day

        return (
          <>
            <SortableList
              items={
                state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                  .backupActivities
              }
              isSetAsBackup={true}
              onSortEnd={onSortEnd}
              onMoveUp={onSortEnd}
              onMoveDown={onSortEnd}
              shouldCancelStart={shouldCancelStart}
              onRemove={onRemove}
              onMoveToBackup={onMoveToBackup}
              onMoveToActive={onMoveToActive}
              activeTab={activeTab}
              onEditActivity={onEditActivity}
              onEditCommute={onEditCommute}
              onAddCommute={onAddCommute}
              onRemoveCommute={onRemoveCommute}
              disableDrag={disableDrag}
              onRemoveBackup={onRemoveBackup}
              onSelect={onSelect}
            />
          </>
        )
      }
      // }
    } catch (error) {
      // console.log(error)
    }
  }

  // Note: This is the important for the <a> tag navigation to work inside the sortable list.
  const shouldCancelStart = e => {
    // Cancel sorting if the event target is an anchor tag (`a`)
    if (e.target.tagName.toLowerCase() === "a") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "button") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "input") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "textarea") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "select") {
      return true // Return true to cancel sorting
    }
    if (e.target.tagName.toLowerCase() === "i") {
      return true // Return true to cancel sorting
    }
  }
  // update the newly created activity
  const onActivityCreated = () => {
    console.log("onActivityCreated  called")
    dispatch({ type: "syncSchedule", data: true })
    dispatch({ type: "setFetchAndUpdateCommute", value: true })
  }
  // update the newly created commute
  const onCommuteCreated = () => {
    console.log("onCommuteCreated  called")
    dispatch({ type: "syncSchedule", data: true })
  }

  const onSortStart = () => {
    console.log("sorting started")
    document.body.style.cursor = "grabbing"
  }
  // swap on drag end.
  const onSortEnd = ({ oldIndex, newIndex }) => {
    console.log({ oldIndex })
    console.log({ newIndex })
    document.body.style.cursor = "initial"
    if (oldIndex === newIndex) {
      console.log("no change in index")
      return
    }
    let activityCard = document.getElementById("activity-card-type2")
    if (activityCard) activityCard.style.cursor = "default"
    dispatch({
      type: "moveActivities",
      oldIndex: oldIndex,
      newIndex: newIndex,
      activeTab: activeTab,
    })
    // dispatch({ type: "syncCommute", data: true })
    //dispatch({ type: "syncSchedule", data: true })
  }

  // clears the focus for the search results selection item.
  const clearFocus = () => {
    document.getElementById(
      state.searchResults[selectedItemIndex].activityUid
    ).className = ""
  }

  const syncDesignerWorkSpaceDB = params => {
    if (dbContext) {
      console.log(state.currentJobInfo.designerWorkspace.itinerary)
      dbContext
        .firestore()
        .collection("jobs")
        .doc(`${tripState.uid}`)
        .collection("active-jobs")
        .doc(`${state.currentJobInfo.jobUid}`)
        .update({
          designerWorkspace: {
            itinerary: state.currentJobInfo.designerWorkspace.itinerary,
          },
        })
        .then(() => {
          console.log("document update successful")
          //update local state now
          //
        })
        .catch(e => console.log(e))
    }
  }

  // const syncCommuteDataDB = params => {
  //   if (dbContext) {
  //     dbContext
  //       .firestore()
  //       .collection("jobs")
  //       .doc(`${tripState.uid}`)
  //       .collection("active-jobs")
  //       .doc(`${state.currentJobInfo.jobUid}`)
  //       .set({
  //         "designerWorkspace.itinerary":
  //           state.currentJobInfo.designerWorkspace.itinerary,
  //       })
  //       .then(() => {
  //         console.log("document update successful")
  //         //update local state now
  //         //
  //       })
  //       .catch(e => console.log(e))
  //   }
  // }

  // To handle the UP, DOWN and ENTER key press results in the search bar.
  const keyChanged = e => {
    console.log({ selectedItemIndex })
    console.log(e.keyCode)
    try {
      switch (e.keyCode) {
        case 27: // ESC
          setShowHits(false)
          return
        // case 38: // UP
        //   if (selectedItemIndex > 0) {
        //     clearFocus()
        //     var a = selectedItemIndex - 1
        //     setSelectedItemIndex(a)
        //   }
        //   break
        // case 40: // DOWN
        //   if (selectedItemIndex < state.searchResults.length - 1) {
        //     var a = selectedItemIndex + 1
        //     if (selectedItemIndex != -1) clearFocus()
        //     setSelectedItemIndex(a)
        //   }
        //   break
        // case 13: //ENTER
        //   if (selectedItemIndex != -1) {
        //     clearFocus()
        //     // Add to days activities list.
        //     dispatch({
        //       type: "addSearchedActivity",
        //       activeTab: activeTab,
        //       selectedItem: selectedItemIndex,
        //     })
        //     dispatch({ type: "updateSearchResults", data: [] })
        //     setSelectedItemIndex(-1)
        //     setSearchText("")
        //     //update cloud
        //     dispatch({ type: "syncSchedule", data: true })
        //     //setDesignerWorkSpaceModified(true)
        //   }
        //   break
        default:
          break
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Handles displaying search results and its formatting.
  const showSearchResults = () => {
    try {
      if (state.searchResults !== undefined)
        return state.searchResults.map(item => {
          return (
            <li
              key={item.activityUid}
              id={item.activityUid}
              onClick={e => {
                console.log(e.currentTarget.id)
                if (e.currentTarget.id != undefined) {
                  var i = state.searchResults.findIndex(
                    element => element.activityUid === e.currentTarget.id
                  )
                  console.log({ i })
                  if (i != -1) {
                    //clearFocus()
                    // Add to days activities list.
                    dispatch({
                      type: "addSearchedActivity",
                      activeTab: activeTab,
                      selectedItemIndex: i,
                    })
                    dispatch({ type: "updateSearchResults", data: [] })
                    setSelectedItemIndex(-1)
                    setSearchText("")
                    dispatch({ type: "syncSchedule", data: true })
                  }

                  //syncDesignerWorkSpaceDB()
                }
              }}
            >
              <p>{item.activityName}</p>
            </li>
          )
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getStartDateTime = params => {
    try {
      return state.currentJobInfo.designerWorkspace.itinerary[
        activeTab
      ].dayScheduleStartTime.toDate()
    } catch (error) {
      return new Date(
        state.currentJobInfo.designerWorkspace.itinerary[
          activeTab
        ].dayScheduleStartTime
      )
    }
  }
  const recalculateSchedule = dayId => {
    //fetch the day's schedule start time

    console.log(dayId)
    try {
      var tempPreviousActivityTime = 0 // this stores the previous activity end time. This is picked form a temp variable as the state is not updated yet
      var startDateTime = getStartDateTime()
      for (
        let i = 0;
        i <
        state.currentJobInfo.designerWorkspace.itinerary[dayId].activities
          .length;
        i++
      ) {
        // Now fetch each activities duration
        var currentActivityDurationHrs =
          state.currentJobInfo.designerWorkspace.itinerary[dayId].activities[i]
            .activity.activityDurationHours === undefined
            ? 0
            : Number(
                state.currentJobInfo.designerWorkspace.itinerary[dayId]
                  .activities[i].activity.activityDurationHours
              )

        var currentActivityDurationMin =
          state.currentJobInfo.designerWorkspace.itinerary[dayId].activities[i]
            .activity.activityDurationMinutes === undefined
            ? 0
            : Number(
                state.currentJobInfo.designerWorkspace.itinerary[dayId]
                  .activities[i].activity.activityDurationMinutes
              )
        // console.log({ currentActivityDurationHrs })
        // console.log({ currentActivityDurationMin })
        var totalMinutes =
          currentActivityDurationMin + currentActivityDurationHrs * 60

        //get the start time of this activity. It is going to be the end time of the previous activity.
        var activityStartTime =
          i === 0 ? startDateTime : tempPreviousActivityTime

        var activityEndTime = new Date(
          activityStartTime.getTime() + totalMinutes * 60000
        )

        // console.log({ startDateTime })
        // console.log({ totalMinutes })
        // console.log({ activityStartTime })
        // console.log({ activityEndTime })

        // // Dispatch a state update here.
        // console.log({ activityEndTime })
        dispatch({
          type: "updateActivityScheduleTimes",
          activeTab: dayId,
          index: i,
          activityStartTime: activityStartTime.toUTCString(),
          activityEndTime: activityEndTime.toUTCString(),
        })
        tempPreviousActivityTime = activityEndTime
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDistanceMatrix = (service, data) =>
    new Promise((resolve, reject) => {
      service.getDistanceMatrix(data, (response, status) => {
        if (status === "OK") {
          resolve(response)
        } else {
          reject(response)
        }
      })
    })
  const getOptimizedRoute = (source, destination, distanceMatrix) => {
    try {
      if (distanceMatrix) {
        console.log({ distanceMatrix })
        let paths = []
        let totalPaths = distanceMatrix.length
        let iterations = 0
        for (let jj = 0; jj < totalPaths - 1; jj++) {
          let newSrc = jj
          let optimizedRoute = []
          optimizedRoute.push(newSrc)
          let totalWeight = 0
          let totalWaypoints = distanceMatrix.length

          for (let ii = 1; ii < totalWaypoints; ii++) {
            iterations++
            let rowItems = distanceMatrix[newSrc].elements
            console.log({ optimizedRoute, rowItems, newSrc })
            let tempNewSrc = -1
            let lowestWeight = -1
            rowItems.map((item, index) => {
              iterations++
              console.log({ tempNewSrc, lowestWeight })
              if (index === newSrc || optimizedRoute.includes(index)) {
                console.log("skipping")
              } else {
                // if (item.distance) {
                let wt = item.distance
                  ? item.distance.value
                  : Number.MAX_SAFE_INTEGER
                if (tempNewSrc === -1) {
                  tempNewSrc = index
                  lowestWeight = wt
                } else {
                  if (wt < lowestWeight) {
                    lowestWeight = wt
                    tempNewSrc = index
                  }
                }
                // }
              }
            })
            newSrc = tempNewSrc
            optimizedRoute.push(tempNewSrc)
            totalWeight = totalWeight + lowestWeight
            console.log({ optimizedRoute, totalWeight })
          }
          let pObj = {
            optimizedRoute: optimizedRoute,
            totalWeight: totalWeight,
          }
          paths.push(pObj)
        }
        console.log({ iterations })
        console.log({ paths })
        console.log(paths[0])
        let shortestPath = minBy(paths, item => {
          return item.totalWeight
        })
        console.log({ shortestPath })
        return shortestPath ? shortestPath : []
      }
    } catch (err) {
      console.log(err)
      setItineraryBusy(false)
      return []
    }
  }

  const applyOptimizedRoute = rDetails => {
    try {
      let currentActivities =
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
      console.log({ currentActivities, rDetails })
      if (currentActivities.length === rDetails.optimizedRoute.length) {
        let newActivities = []
        rDetails.optimizedRoute.map((item, index) => {
          newActivities.push(currentActivities[item])
        })
        console.log({ newActivities })
        if (newActivities !== []) {
          dispatch({
            type: "applyOptimizedRoute",
            activities: [...newActivities],
            dayIndex: activeTab,
          })
          setItineraryBusy(false)
        }
      }
    } catch (error) {
      setItineraryBusy(false)
    }
  }

  const generateDaysDistanceMatrix = async dayIndex => {
    try {
      let apiKey = process.env.GOOGLE_MAPS_API_KEY
      var service = new window.google.maps.DistanceMatrixService()
      let daysOrigins = []
      let daysDestinations = []
      if (
        state.currentJobInfo.designerWorkspace.itinerary[dayIndex].activities
      ) {
        if (
          state.currentJobInfo.designerWorkspace.itinerary[dayIndex].activities
            .length > 10
        ) {
          setItineraryBusy(false)
          addToast(`Maximum of 10 activities supported.`, {
            appearance: "error",
            autoDismiss: true,
          })
          return
          //google distance matrix API request limit to a max of 10 source and 10 destination per call.
        }
        if (
          state.currentJobInfo.designerWorkspace.itinerary[dayIndex].activities
            .length < 3
        ) {
          setItineraryBusy(false)
          addToast(`Add at least 3 activities to compute an optimized plan.`, {
            appearance: "error",
            autoDismiss: true,
          })
          return
          //google distance matrix API request limit to a max of 10 source and 10 destination per call.
        }
        state.currentJobInfo.designerWorkspace.itinerary[
          dayIndex
        ].activities.map((item, index) => {
          daysOrigins.push(item.activity.activityAddress)
          daysDestinations.push(item.activity.activityAddress)
        })
      }
      // if (state.currentJobInfo.designerWorkspace.itinerary[dayIndex].commute) {
      //   state.currentJobInfo.designerWorkspace.itinerary[dayIndex].commute.map(
      //     (item, index) => {
      //       daysOrigins.push(item.from)
      //       daysDestinations.push(item.to)
      //     }
      //   )
      // }
      console.log({ daysOrigins, daysDestinations })
      let daysDistanceMatrix = await getDistanceMatrix(
        new window.google.maps.DistanceMatrixService(),
        {
          origins: [...daysOrigins],
          destinations: [...daysDestinations],
          travelMode: window.google.maps.TravelMode.DRIVING,
          unitSystem: window.google.maps.UnitSystem.IMPERIAL,
          avoidHighways: false,
          avoidTolls: false,
        }
      )
      console.log({ daysDistanceMatrix })
      if (daysDistanceMatrix.rows.length > 1) {
        let optimizedRoute = getOptimizedRoute(0, 1, daysDistanceMatrix.rows)
        console.log({ optimizedRoute })
        if (optimizedRoute) {
          applyOptimizedRoute(optimizedRoute)
        }
      }
    } catch (error) {
      console.log(error)
      setItineraryBusy(false)
    }
  }

  const fetchAndUpdateCommute = async updateDBifRequired => {
    try {
      console.log({ activeTab })
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
          .length > 1
      ) {
        let jobs = []
        let commuteDBSyncRequired = false
        let updateCount = 0
        await Promise.all(
          state.currentJobInfo.designerWorkspace.itinerary[
            activeTab
          ].activities?.map(async (activity, index, elements) => {
            let currentActivity = activity.activity
            let nextActivity = elements[index + 1]
              ? elements[index + 1].activity
              : null
            console.log("1")
            if (currentActivity && nextActivity) {
              if (
                currentActivity.activityAddress &&
                nextActivity.activityAddress
              ) {
                if (
                  state.currentJobInfo.designerWorkspace.itinerary &&
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab] &&
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .commute
                ) {
                  if (
                    state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                      .commute[index] &&
                    state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                      .commute[index].from ===
                      currentActivity.activityAddress &&
                    state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                      .commute[index].to === nextActivity.activityAddress
                  ) {
                    console.log(
                      state.currentJobInfo.designerWorkspace.itinerary[
                        activeTab
                      ].commute[index].from,
                      currentActivity.activityAddress,
                      state.currentJobInfo.designerWorkspace.itinerary[
                        activeTab
                      ].commute[index].to,
                      nextActivity.activityAddress
                    )
                    return
                  }
                }
                updateCount++
                let apiKey = process.env.GOOGLE_MAPS_API_KEY
                // var service = new window.google.maps.DistanceMatrixService()
                console.log(currentActivity.activityAddress)
                console.log(nextActivity.activityAddress)
                if (window.google) {
                  let response = await getDistanceMatrix(
                    new window.google.maps.DistanceMatrixService(),
                    {
                      origins: [currentActivity.activityAddress],
                      destinations: [nextActivity.activityAddress],
                      travelMode: window.google.maps.TravelMode.DRIVING,
                      unitSystem: window.google.maps.UnitSystem.IMPERIAL,
                      // avoidHighways: false,
                      // avoidTolls: false,
                    }
                  )
                  console.log({ index, response, updateCount })
                  if (response.rows[0].elements[0].status !== "ZERO_RESULTS") {
                    let duration = response.rows[0].elements[0].duration.value
                    let distance = response.rows[0].elements[0].distance.value
                    let durationInText = ""
                    if (duration < 60) durationInText = "less than a min"
                    let hours = 0
                    let minutes = 0
                    if (duration > 60) {
                      hours = Math.floor(duration / 3600)
                      minutes = Math.round((duration % 3600) / 60)
                      if (hours > 0) {
                        if (hours === 1)
                          durationInText = `${hours} hr & ${minutes} min`
                        else durationInText = `${hours} hrs & ${minutes} min`
                      } else {
                        durationInText = `${minutes} min`
                      }
                    }
                    let distanceInMiles =
                      distance === 0 ? 0 : (distance * 0.000621).toFixed(1)
                    console.log(`${distanceInMiles} mi ${durationInText}`)
                    commuteDBSyncRequired = true
                    dispatch({
                      type: "updateComputedCommuteDetails",
                      dayIndex: activeTab,
                      activityIndex: index,
                      commuteData: {
                        from: currentActivity.activityAddress,
                        to: nextActivity.activityAddress,
                        commuteMode: "driving",
                        duration: duration,
                        distance: distance,
                        formattedDistanceToMiles: distanceInMiles,
                        formattedDurationText: durationInText,
                      },
                    })
                  } else {
                    dispatch({
                      type: "updateComputedCommuteDetails",
                      dayIndex: activeTab,
                      activityIndex: index,
                      commuteData: {
                        from: currentActivity.activityAddress,
                        to: nextActivity.activityAddress,
                        commuteMode: "driving",
                        duration: 0,
                        distance: 0,
                        formattedDistanceToMiles: 0,
                        formattedDurationText: "check address",
                      },
                    })
                  }
                }
              }
            }
          })
        )
        console.log("complete~~~~")
        if (commuteDBSyncRequired) {
          if (updateDBifRequired) dispatch({ type: "syncDB", data: true })
          // console.log("update db")
          // syncDesignerWorkSpaceDB()
        } else {
          console.log("update not required")
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const fetchAndUpdateCommute = async () => {
  //   try {
  //     if (
  //       state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
  //         .length > 1
  //     ) {
  //       let jobs = []

  //       state.currentJobInfo.designerWorkspace.itinerary[
  //         activeTab
  //       ].activities.map(async (activity, index, elements) => {
  //         if (
  //           index !== elements.length &&
  //           activity.activity.activityType !== "commute"
  //         ) {
  //           // check if last item
  //           let currentActivity = activity.activity
  //           let nextActivity = elements[index + 2]
  //             ? elements[index + 2].activity
  //             : null

  //           if (currentActivity && nextActivity) {
  //             if (
  //               currentActivity.activityAddress &&
  //               nextActivity.activityAddress
  //             ) {
  //               if (
  //                 elements[index + 1].activity.origin ===
  //                   currentActivity.activityAddress &&
  //                 elements[index + 1].activity.destination ===
  //                   nextActivity.activityAddress
  //               )
  //                 return // We dont have to recheck the travel time if address didnt change.
  //               let apiKey = process.env.GOOGLE_MAPS_API_KEY
  //               var service = new window.google.maps.DistanceMatrixService()
  //               let response = await getDistanceMatrix(
  //                 new window.google.maps.DistanceMatrixService(),
  //                 {
  //                   origins: [currentActivity.activityAddress],
  //                   destinations: [nextActivity.activityAddress],
  //                   travelMode: window.google.maps.TravelMode.DRIVING,
  //                   unitSystem: window.google.maps.UnitSystem.IMPERIAL,
  //                   avoidHighways: false,
  //                   avoidTolls: false,
  //                 }
  //               )
  //               console.log({ response })
  //               let duration = response.rows[0].elements[0].duration.value
  //               let distance = response.rows[0].elements[0].distance.value
  //               let durationInText = ""
  //               if (duration < 60) durationInText = "less than a minute"
  //               let hours = 0
  //               let minutes = 0
  //               if (duration > 60) {
  //                 hours = Math.floor(duration / 3600)
  //                 minutes = Math.round((duration % 3600) / 60)
  //                 if (hours > 0) {
  //                   if (hours === 1)
  //                     durationInText = `${hours} hr & ${minutes} mins`
  //                   else durationInText = `${hours} hrs & ${minutes} mins`
  //                 } else {
  //                   durationInText = `${minutes} mins`
  //                 }
  //               }
  //               let distanceInMiles =
  //                 distance === 0 ? 0 : (distance * 0.000621).toFixed(1)
  //               console.log(`${distanceInMiles} mi ${durationInText}`)

  //               if (index + 3 === elements.length) {
  //                 // After the last commute block is updated perform a db update. This is done because the distance calculation API is asynchronous.
  //                 dispatch({
  //                   type: "updateCommute",
  //                   activeTab: activeTab,
  //                   itemIndex: index + 1,
  //                   commuteDurationHours: hours,
  //                   commuteDurationMinutes: minutes,
  //                   commuteMode: "driving",
  //                   commuteDistanceMeters: distance,
  //                   updateRequired: true,
  //                   origin: currentActivity.activityAddress,
  //                   destination: nextActivity.activityAddress,
  //                 })
  //               } else {
  //                 dispatch({
  //                   type: "updateCommute",
  //                   activeTab: activeTab,
  //                   itemIndex: index + 1,
  //                   commuteDurationHours: hours,
  //                   commuteDurationMinutes: minutes,
  //                   commuteMode: "driving",
  //                   commuteDistanceMeters: distance,
  //                   updateRequired: false,
  //                   origin: currentActivity.activityAddress,
  //                   destination: nextActivity.activityAddress,
  //                 })
  //               }
  //             }
  //           }
  //         }
  //       })
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   for (
  //     let i = 0;
  //     i <
  //     state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
  //       .length -
  //       1;
  //     i++
  //   ) {
  //     // if ( // for a case if two "add commute" card end up next to each other.
  //     //   state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
  //     //     i
  //     //   ].activity.activityType === "commute" &&
  //     //   state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
  //     //     i + 1
  //     //   ].activity.activityType === "commute"
  //     // ) {
  //     //   dispatch({
  //     //     type: "deleteAddCommute",
  //     //     activeTab: activeTab,
  //     //     index: i + 1,
  //     //   })
  //     // }
  //     if (
  //       state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
  //         i
  //       ].activity.activityType !== "commute" &&
  //       state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
  //         i + 1
  //       ].activity.activityType !== "commute"
  //     ) {
  //       dispatch({
  //         type: "insertNewCommute",
  //         activeTab: activeTab,
  //         index: i + 1,
  //       })
  //     }
  //   }
  // }

  const cleanupCommuteBlocks = () => {
    for (
      let i = 0;
      i <
      state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
        .length -
        1;
      i++
    ) {
      // if ( // for a case if two "add commute" card end up next to each other.
      //   state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
      //     i
      //   ].activity.activityType === "commute" &&
      //   state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
      //     i + 1
      //   ].activity.activityType === "commute"
      // ) {
      //   dispatch({
      //     type: "deleteAddCommute",
      //     activeTab: activeTab,
      //     index: i + 1,
      //   })
      // }
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.activityType === "commute" &&
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.commuteMode === ""
      ) {
        console.log("deleting block")
        console.log(i)
        dispatch({
          type: "deleteBlock",
          activeTab: activeTab,
          index: i,
        })
      }
    }
  }

  const refreshCommute = () => {
    for (
      let i = 0;
      i <
      state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
        .length -
        1;
      i++
    ) {
      // if ( // for a case if two "add commute" card end up next to each other.
      //   state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
      //     i
      //   ].activity.activityType === "commute" &&
      //   state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
      //     i + 1
      //   ].activity.activityType === "commute"
      // ) {
      //   dispatch({
      //     type: "deleteAddCommute",
      //     activeTab: activeTab,
      //     index: i + 1,
      //   })
      // }
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.activityType !== "commute" &&
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i + 1
        ].activity.activityType !== "commute"
      ) {
        dispatch({
          type: "insertNewCommute",
          activeTab: activeTab,
          index: i + 1,
        })
      }
    }
  }

  const refreshDaysStats = () => {
    var totalSightseeingTime = 0
    var totalCommuteTime = 0
    var totalDiningTime = 0

    for (
      let i = 0;
      i <
      state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
        .length;
      i++
    ) {
      // console.log({ i })
      var currentActivityDurationHrs =
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.activityDurationHours === undefined
          ? 0
          : Number(
              state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                .activities[i].activity.activityDurationHours
            )

      var currentActivityDurationMin =
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.activityDurationMinutes === undefined
          ? 0
          : Number(
              state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                .activities[i].activity.activityDurationMinutes
            )
      var totalMinutes =
        currentActivityDurationMin + currentActivityDurationHrs * 60
      switch (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities[
          i
        ].activity.activityType
      ) {
        case "sightseeing":
        case "Sightseeing":
        case "souvenir":
        case "Souvenir":
        case "shopping":
        case "Shopping":
        case "distillery":
        case "Distillery":
        case "brewery":
        case "Brewery":
        case "Photo Shoot":
        case "Culture Walk":
        case "Cooking Class":
        case "Local Experience":
        case "River Cruise":
        case "Photo Shoot":
        case "Outdoor Activity":
        case "Guided Tour":
        case "Foodie Tour":
          totalSightseeingTime = totalSightseeingTime + totalMinutes
          break
        case "commute":
        case "Commute":
          totalCommuteTime = totalCommuteTime + totalMinutes
          break
        case "restaurant":
        case "Restaurant":
        case "coffee":
        case "Coffee":
        case "bar":
        case "Bar":
        case "Bar Crawl":
          totalDiningTime = totalDiningTime + totalMinutes
          break
        default:
          break
      }
    }

    dispatch({
      type: "updateDayStats",
      totalSightseeingTime: totalSightseeingTime,
      totalCommuteTime: totalCommuteTime,
      totalDiningTime: totalDiningTime,
      activeTab: activeTab,
    })
  }

  const launchActivityFormDesktop = () => {
    return (
      <div className="columns">
        <div className="column is-hidden-desktop is-1-tablet"></div>
        <div className="column is-12-desktop is-10-tablet">
          <div
            className="new-activity-form-container"
            style={{
              background: "#f7f7f7",
              padding: "40px 30px",
              borderRadius: "4px",
              border: "1px solid #ebebeb",
            }}
          >
            <BYOTActivityForm
              onCancel={() => {
                setShowNewActivityEntry(false)
                setInActivityEditMode(false)
                dispatch({ type: "clearActivityScratchPad" })
              }}
              addToItinerary={true}
              activeTab={activeTab}
              onActivityCreated={onActivityCreated}
              inEditMode={inActivityEditMode}
            />
          </div>
        </div>
        <div className="column is-hidden-desktop is-1-tablet"></div>
      </div>
    )
  }
  const launchActivityFormMobile = () => {
    return (
      <div className="columns">
        <div className="column is-4-desktop is-1-tablet"></div>
        <div className="column is-8-desktop is-10-tablet">
          <div className="new-activity-form-container">
            <BYOTActivityForm
              onCancel={() => {
                setShowNewActivityEntry(false)
                setInActivityEditMode(false)
                dispatch({ type: "clearActivityScratchPad" })
              }}
              addToItinerary={true}
              activeTab={activeTab}
              onActivityCreated={onActivityCreated}
              inEditMode={inActivityEditMode}
            />
          </div>
        </div>
        <div className="column is-4-desktop is-1-tablet"></div>
      </div>
    )
  }

  const launchCommuteForm = () => {
    return (
      <div className="new-activity-form-container">
        <NewCommuteForm
          onCancel={() => {
            setInCommuteEditMode(false)
            setShowNewCommuteEntry(false)
            dispatch({ type: "clearActivityScratchPad" })
          }}
          activeTab={activeTab}
          onCommuteCreated={onCommuteCreated}
          //inCommuteEditMode={inCommuteEditMode}
          //    onAddCommute={onAddCommute}
        />
      </div>
    )
  }

  const timeInStringFromMinutes = minutes => {
    try {
      var t = Number(minutes)

      var h = Math.floor(t / 60)
      var m = Math.round(t % 60)
      if (t === 0 && h === 0) return "--"
      if (t < 60) {
        return `${m} Minutes`
      } else {
        var hrs = h === "1" ? "Hr" : "Hrs"
        if (m === 0) return `${h} ${hrs}`
        else return `${h} ${hrs} & ${m} Minutes`
      }
    } catch (err) {
      console.log(err)
      return "--"
    }
  }
  const formatDuration = (dHours, dMinutes) => {
    try {
      var hours = dHours
      var minutes = dMinutes

      var hrs = hours === "1" ? "Hr" : "Hrs"

      if (
        hours === undefined ||
        hours === "" ||
        hours === " " ||
        hours === "0"
      ) {
        return ` ${minutes} Min`
      } else {
        if (
          minutes === undefined ||
          minutes === "" ||
          minutes === " " ||
          minutes === "0"
        )
          return ` ${hours} ${hrs}`
        else {
          return ` ${hours} ${hrs} & ${minutes} Min`
        }
      }
    } catch (error) {
      console.log(error)
      return ""
    }
  }
  function returnFormattedTime(startTime, endTime) {
    try {
      return (
        <>
          {" "}
          <div className="from-timeline">
            {startTime === undefined
              ? 0
              : `${addZeroBefore(getHours(startTime))}:${addZeroBefore(
                  getMinutes(startTime)
                )}`}
          </div>
          <div className="to-timeline">
            {" - "}
            {endTime === undefined
              ? 0
              : `${addZeroBefore(getHours(endTime))}:${addZeroBefore(
                  getMinutes(endTime)
                )}`}
          </div>
        </>
      )
    } catch (error) {
      console.log(error)
      return ""
    }
  }
  const mapRef = useRef()
  const MyAuxMarker = ({ item }) => (
    <div className="" id={`${item.uid}`}>
      <ReactTooltip
        id="aux-map-marker"
        // type="light"
        // backgroundColor="transparent"
        // getContent={dataTip => {
        //   // console.log({ dataTip })
        //   if (!dataTip) return
        //   else {
        //     return <div>{dataTip.name}</div>
        //   }
        // }}
        // <p>{`This little buddy is ${`}</p>}
      />
      <i
        className="fas fa-bed fa-2x"
        style={{ color: "#4a4a4a", cursor: "pointer" }}
        onMouseOver={params => {}}
        id={`aux-map-marker-${item.uid}`}
        data-for="aux-map-marker"
        data-tip={item.name}
        // data-tip={"test"}
      ></i>
    </div>
  )
  const MyMarker = ({ id, activityId }) => (
    <div className="marker" id={`${id}`}>
      <ReactTooltip
        id="map-marker"
        type="light"
        backgroundColor="transparent"
        getContent={dataTip => {
          // console.log({ dataTip })
          // return
          if (!dataTip) return
          else {
            const [
              activityName,
              activityType,
              activityDurationHours,
              activityDurationMinutes,
              activityStartTime,
              activityEndTime,
            ] = dataTip.split("|")
            return (
              <div
                className="activity-card-type2"
                style={{
                  margin: "10px",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: activityType === "accommodation" ? "38px" : "35px",
                  width: "auto",
                  borderLeft: "1px solid #fec600",
                  borderRadius: "4px",
                  // padding: "5px",
                }}
              >
                {/* <div className="vertical-line"></div> */}
                <div className="activity-card-data">
                  {/* {activityType === "accommodation"
                    ? ""
                    : returnFormattedTime(activityStartTime, activityEndTime)} */}

                  {/* <div className="activity-card-warnings">
              <a className="is-small">
                <i className="fas fa-exclamation-triangle warning-icon-color"></i>
              </a>
            </div> */}
                  <div
                    className="activity-card-name"
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      marginTop: "2px",
                      fontWeight: "500",
                    }}
                  >
                    {activityType === "accommodation"
                      ? activityName
                      : dottedText(activityName ?? "", 27)}
                  </div>
                  {/* {activityType === "accommodation" ? (
                    ""
                  ) : (
                    <div
                      className="activity-card-type"
                      style={{
                        marginTop: "0px",
                        fontSize: "10px",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                      }}
                    >
                      {titleCase(activityType)}
                    </div>
                  )} */}
                  {/* {formatDuration(
                    activityDurationHours ?? 0,
                    activityDurationMinutes
                  ) !== "" ? (
                    <div
                      className="activity-card-type"
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        fontSize: "14px",
                      }}
                    >
                      <i className="far fa-clock"></i>
                      {formatDuration(
                        activityDurationHours ?? 0,
                        activityDurationMinutes
                      )}
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            )
          }
        }}
        // <p>{`This little buddy is ${`}</p>}
      />

      {activityId === "accommodation" ? (
        <i
          className="fas fa-bed fa-3x"
          style={{ color: "#4a4a4a", cursor: "pointer" }}
          onMouseOver={params => {}}
          id={`map-marker-${activityId}`}
          data-for="map-marker"
          data-tip={`${id}|${activityId}`}
          // data-tip={"test"}
        ></i>
      ) : (
        <i
          className="fas fa-map-marker-alt fa-3x"
          style={{ color: "#4a4a4a", cursor: "pointer" }}
          onMouseOver={params => {}}
          id={`map-marker-${activityId}`}
          data-for="map-marker"
          data-tip={getActivity(activeTab, id)}
          // data-tip={"test"}
        ></i>
      )}
    </div>
  )
  const getActivity = (dayIndex, activityIndex) => {
    try {
      if (state) {
        // console.log({ dayIndex })
        if (
          state.currentJobInfo.designerWorkspace.itinerary[dayIndex] !==
          undefined
        ) {
          let dayPlan =
            state.currentJobInfo.designerWorkspace.itinerary[dayIndex]
          // console.log(dayPlan, dayIndex, activityIndex)
          if (dayPlan) {
            let activities = dayPlan.activities
            // console.log({ activities })
            // return "yay!"
            if (activities[activityIndex]) {
              let a = activities[activityIndex].activity
              let stringyResult = `${a.activityName}|${
                a.activityType
              }|${a.activityDurationHours ?? 0}|${a.activityDurationMinutes ??
                0}|${a.activityStartTime}|${a.activityEndTime}`
              // console.log({ stringyResult })
              return stringyResult
            } else return ""
          }
        }
      } else {
        return ""
      }
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  // const geoCodeItemAddress = useCallback(async (itemAddress, type, name) => {
  //   try {
  //     Geocode.setApiKey(`${process.env.GOOGLE_MAPS_API_KEY}`)
  //     // set response language. Defaults to english.
  //     Geocode.setLanguage("en")
  //     // set response region. Its optional.
  //     // A Geocoding request with region=es (Spain) will return the Spanish city.
  //     Geocode.setRegion("es")
  //     // set location_type filter . Its optional.
  //     // google geocoder returns more that one address for given lat/lng.
  //     // In some case we need one address as response for which google itself provides a location_type filter.
  //     // So we can easily parse the result for fetching address components
  //     // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
  //     // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
  //     Geocode.setLocationType("APPROXIMATE")
  //     // Enable or disable logs. Its optional.
  //     Geocode.enableDebug()
  //     var bounds = ""
  //     if (itemAddress && name) {
  //       let a = auxMarkerCoordinates.some(item => item.name === name )
  //       if(a)
  //       {
  //       if(a.state === "pending" || a.state==="complete" )
  //       {
  //         //either the request is in progress or already have the latlong
  //         return
  //       }
  //     }
  //     else
  //     {
  //       let latlng = {lat:0,long:0, type, name, state:"pending" }
  //       setAuxMarkerCoordinates([...auxMarkerCoordinates, latlng])
  //       // not find send geocode request.
  //       const response = await Geocode.fromAddress(itemAddress)

  //         if (response) {
  //           const { lat, lng } = response.results[0].geometry.location
  //           // console.log(item.activity.activityAddress, lat, lng)
  //           let a = [...auxMarkerCoordinates]
  //           a.findIndex(item => item.)
  //           let latlng = { lat, lng, type, name }
  //           setAuxMarkerCoordinates([...auxMarkerCoordinates, latlng])
  //           return
  //         }
  //     }
  //     console.log({ itemAddress })
  //     if (auxMarkerCoordinates.some(item => item.name === name)) {
  //       console.log("Aux item already geocoded")
  //       return
  //     } else {

  //         console.log("inside")
  //         console.log({ auxMarkerCoordinates })
  //         const response = await Geocode.fromAddress(itemAddress)

  //         if (response) {
  //           const { lat, lng } = response.results[0].geometry.location
  //           // console.log(item.activity.activityAddress, lat, lng)
  //           let latlng = { lat, lng, type, name }
  //           setAuxMarkerCoordinates([...auxMarkerCoordinates, latlng])
  //           return
  //         }

  //     }
  //   }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // })

  const addAccommodationToItinerary = (accommodationInfo, additionType) => {
    try {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("activityTypeDefaults")
          .doc(`Accommodation`)
          .get()
          .then(doc => {
            console.log(doc.data())
            if (doc && doc.data()) {
              console.log({ accommodationInfo, additionType })

              if (additionType === "end") {
                dispatch({
                  type: "addAccommodationToItinerary",
                  data: accommodationInfo,
                  actionType: "end",
                  activeTab: activeTab,
                  imageInfo: doc.data().image,
                })
              }
              // switch (additionType) {
              //   case "start":
              //     dispatch({
              //       type: "addAccommodationToItinerary",
              //       data: accommodationInfo,
              //       type: "start",
              //       activeTab: activeTab,
              //       imageInfo: doc.data(),
              //     })
              //     break

              //   case "end":

              //     break

              //   case "both":
              //     dispatch({
              //       type: "addAccommodationToItinerary",
              //       data: accommodationInfo,
              //       type: "both",
              //       activeTab: activeTab,
              //       imageInfo: doc.data(),
              //     })
              //     break

              //   default:
              //     break
              // }
            } else {
            }

            // console.log("Document successfully updated!");
            // clearCurrentAttraction();
          })
          .catch(error => {
            console.log("Error getting document:", error)
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const geoCodeActivityAddress = useCallback(async dayIndex => {
    try {
      Geocode.setApiKey(`${process.env.GOOGLE_MAPS_API_KEY}`)
      // set response language. Defaults to english.
      Geocode.setLanguage("en")
      // set response region. Its optional.
      // A Geocoding request with region=es (Spain) will return the Spanish city.
      Geocode.setRegion("es")
      // set location_type filter . Its optional.
      // google geocoder returns more that one address for given lat/lng.
      // In some case we need one address as response for which google itself provides a location_type filter.
      // So we can easily parse the result for fetching address components
      // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
      // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
      Geocode.setLocationType("APPROXIMATE")
      // Enable or disable logs. Its optional.
      // Geocode.enableDebug()
      var bounds = ""
      if (state) {
        console.log({ dayIndex })
        if (
          state.currentJobInfo.designerWorkspace.itinerary[dayIndex] !==
          undefined
        ) {
          let dayPlan =
            state.currentJobInfo.designerWorkspace.itinerary[dayIndex]
          if (dayPlan) {
            let activities = dayPlan.activities
            // console.log(activities)
            let coordinates = []
            const promises = activities.map(async (item, index) => {
              if (item) {
                if (item.activity.latlng) {
                  coordinates.push(item.activity.latlng)
                } else {
                  if (item.activity.activityAddress) {
                    // console.log(item.activity.activityAddress)
                    const response = await Geocode.fromAddress(
                      item.activity.activityAddress
                    )

                    if (response) {
                      const { lat, lng } = response.results[0].geometry.location
                      // console.log(item.activity.activityAddress, lat, lng)
                      let latlng = { lat, lng, index, aId: item.id }
                      coordinates.push(latlng)
                      return
                    }
                  }
                }
              }
            })
            const result = await Promise.all(promises)
            // console.log(result)
            setMarkerCoordinates(coordinates)

            /* eslint-disable no-undef */
            // for (let ii = 0; ii < coordinates.length; ii++) {
            //   const destination = coordinates[ii]
            //   const origin = coordinates[ii + 1]
            //   const DirectionsService = new google.maps.DirectionsService()
            //   const directionsRenderer = new google.maps.DirectionsRenderer()
            //   var request = {
            //     origin: origin,
            //     destination: destination,
            //     /* eslint-disable no-undef */
            //     travelMode: google.maps.TravelMode.DRIVING,
            //   }
            //   DirectionsService.route(request, function(response, status) {
            //     /* eslint-disable no-undef */
            //     if (status == google.maps.DirectionsStatus.OK) {
            //       directionsRenderer.setDirections(response)
            //       /* eslint-disable no-undef */
            //       // var map = new google.maps.Map(
            //       //   document.getElementById("map")
            //       // )
            //       directionsRenderer.setMap(mapRef.current)
            //       var myRoute = response.routes[0].legs[0]
            //       var lineSymbol = {
            //         path: "M 0,-1 0,1",
            //         strokeOpacity: 1,
            //         scale: 4,
            //       }
            //       /* eslint-disable no-undef */
            //       // var line = new google.maps.Polyline({
            //       //   path: [
            //       //     myRoute.steps[myRoute.steps.length - 1].end_point,
            //       //     to.geometry.location,
            //       //   ],
            //       //   strokeOpacity: 0,
            //       //   strokeColor: "#7d7d7d",
            //       //   icons: [
            //       //     {
            //       //       icon: lineSymbol,
            //       //       offset: "0",
            //       //       repeat: "20px",
            //       //     },
            //       //   ],
            //       //   map: map,
            //       // })
            //     }
            //   })
            // }
          }
        } else {
          setMarkerCoordinates([])
        }
      }
    } catch (error) {
      console.log(error)
    }
  })

  const getZoomLevel = () => {
    try {
      if (
        state.tripDetails.tripPrimaryDestination.country ===
        state.tripDetails.tripPrimaryDestination.place
      )
        // Its a country
        return 7
      else return 10
    } catch (error) {
      console.log(error)
      return 10
    }
  }

  const createMapOptions = maps => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      // styles: [
      //   {
      //     stylers: [
      //       { saturation: -100 },
      //       { gamma: 0.8 },
      //       { lightness: 8 },
      //       { visibility: "on" },
      //     ],
      //   },
      // ],
      styles: [
        {
          featureType: "all",
          elementType: "geometry.fill",
          stylers: [
            {
              weight: "2.00",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#9c9c9c",
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              color: "#f2f2f2",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 45,
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7b7b7b",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              color: "#46bcec",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c8d7d4",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#070707",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
      ],
      // [
      //   { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      //   { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      //   { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      //   {
      //     featureType: "administrative.locality",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "poi",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "poi.park",
      //     elementType: "geometry",
      //     stylers: [{ color: "#263c3f" }],
      //   },
      //   {
      //     featureType: "poi.park",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#6b9a76" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "geometry",
      //     stylers: [{ color: "#38414e" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "geometry.stroke",
      //     stylers: [{ color: "#212a37" }],
      //   },
      //   {
      //     featureType: "road",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#9ca5b3" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "geometry",
      //     stylers: [{ color: "#746855" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "geometry.stroke",
      //     stylers: [{ color: "#1f2835" }],
      //   },
      //   {
      //     featureType: "road.highway",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#f3d19c" }],
      //   },
      //   {
      //     featureType: "transit",
      //     elementType: "geometry",
      //     stylers: [{ color: "#2f3948" }],
      //   },
      //   {
      //     featureType: "transit.station",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#d59563" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "geometry",
      //     stylers: [{ color: "#17263c" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "labels.text.fill",
      //     stylers: [{ color: "#515c6d" }],
      //   },
      //   {
      //     featureType: "water",
      //     elementType: "labels.text.stroke",
      //     stylers: [{ color: "#17263c" }],
      //   },
      // ],
    }
  }

  var bounds = ""
  var m = ""
  const displayMap = fullScreen => {
    return (
      <div
        style={{
          height: fullScreen ? "100%" : "600px",
          width: "100%",
          border: fullScreen ? "none" : "1px solid #fec600",
          // borderRadius: "6px",
          marginTop: fullScreen ? "0px" : "10px",
          left: "0px",
          top: "0px",
          zIndex: fullScreen ? "9999" : "0",
          position: fullScreen ? "fixed" : "sticky",
          // top: "100px",
        }}
        className="map-container"
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${process.env.GOOGLE_MAPS_API_KEY}`,
            language: "en",
            region: "US",
          }}
          // defaultCenter={{ lat: centerLat, lng: centerLng }}
          defaultCenter={{ lat: 36.1673, lng: -115.149 }}
          defaultZoom={getZoomLevel()}
          // options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            mapRef.current = map
            m = maps
            console.log({ mapRef })
            console.log({ m })
            mapRef.current.setCenter(
              state.tripDetails.tripPrimaryDestination.latlng
            )
            if (markerCoordinates)
              markerCoordinates.map((item, index) => {
                console.log("markerCoordinates")
                if (index === 0) {
                  if (window.google)
                    bounds = new window.google.maps.LatLngBounds()
                  if (mapRef.current) {
                    mapRef.current.setCenter({
                      lat: item ? item.lat : 0,
                      lng: item ? item.lng : 0,
                    })
                    mapRef.current.setZoom(12)
                  }
                }
                if (bounds !== "")
                  bounds.extend({
                    lat: item ? item.lat : 0,
                    lng: item ? item.lng : 0,
                  })
                if (index > 0 && mapRef.current)
                  mapRef.current.fitBounds(bounds)

                // return (
                //   <MyMarker
                //     id={item.index}
                //     key={index}
                //     lat={item ? item.lat : 0}
                //     lng={item ? item.lng : 0}
                //     activityId={item.aId}
                //   />
                // )
              })
            /* eslint-disable no-undef */

            // console.log({ DirectionsService })
          }}
        >
          {markerCoordinates
            ? markerCoordinates.map((item, index) => {
                if (index === 0) {
                  if (window.google)
                    bounds = new window.google.maps.LatLngBounds()
                  if (mapRef.current) {
                    mapRef.current.setCenter({
                      lat: item ? item.lat : 0,
                      lng: item ? item.lng : 0,
                    })
                    mapRef.current.setZoom(12)
                  }
                }
                if (bounds !== "")
                  bounds.extend({
                    lat: item ? item.lat : 0,
                    lng: item ? item.lng : 0,
                  })
                if (index > 0 && mapRef.current)
                  mapRef.current.fitBounds(bounds)

                return (
                  <MyMarker
                    id={item.index}
                    key={index}
                    lat={item ? item.lat : 0}
                    lng={item ? item.lng : 0}
                    activityId={item.aId}
                  />
                )
              })
            : ""}
        </GoogleMapReact>
        {/* <Map
          google={props.google}
          zoom={8}
          //style={mapStyles}
          initialCenter={{ lat: centerLat, lng: centerLng }}
        /> */}
      </div>
    )
  }
  const displayBackupActivities = () => {
    try {
      return (
        <>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "700",
              borderBottom: "1px solid #fec600",
              marginBottom: "20px",
              color: "rgba(74,74,74,0.8)",
            }}
          >
            Backup Activities
          </p>
          <p style={{ marginBottom: "15px", fontSize: "14px" }}>
            Things don't always go as planned, add your backup activities and
            places to eat for this day.
          </p>
          {showBackupActivities()}
        </>
      )
    } catch (err) {
      console.log(err)
      return ""
    }
  }

  const displayDaysStats = () => {
    try {
      return (
        <>
          <p
            style={{
              fontSize: "24px",
              fontWeight: "700",
              borderBottom: "1px solid #fec600",
              marginBottom: "20px",
              color: "rgba(74,74,74,0.8)",
            }}
          >
            Days Statistics
          </p>
          <div className="stats-container" style={{ textAlign: "center" }}>
            {/* <div className="stats-header">Statistics</div> */}

            <div className="stats-header-2">
              Sightseeing/Experiences
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                {timeInStringFromMinutes(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .daysStats.totalSightseeingTime
                )}
              </p>
            </div>
            <div className="stats-header-2">
              Commute
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                {timeInStringFromMinutes(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .daysStats.totalCommuteTime
                )}
              </p>
            </div>

            <div className="stats-header-2">
              Wine & Dine
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                {timeInStringFromMinutes(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .daysStats.totalDiningTime
                )}
              </p>
            </div>
            <div className="stats-header-2">
              Total
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                {timeInStringFromMinutes(
                  state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                    .daysStats.totalActivitiesTime
                )}
              </p>
            </div>
          </div>
        </>
      )
    } catch (err) {
      console.log(err)
      return ""
    }
  }

  const showEnableEnableDisableDrag = () => {
    try {
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
          .length != 0
      ) {
        return (
          <div
            style={{
              maxWidth: "94px",
              marginLeft: "auto",
              marginTop: "20px",
              fontSize: "10px",
            }}
          >
            <button
              className="button is-warning is-small"
              onClick={() => {
                setDisableDrag(!disableDrag)
              }}
              style={{
                width: "100%",
              }}
            >
              {disableDrag ? "Enable Drag" : "Disable Drag"}
            </button>
          </div>
        )
      } else return ""
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  const PoweredBy = ({ url }) => (
    <a href={url} style={{ color: "#4a4a4a", fontSize: "14px" }}>
      <img src={algoliaImg} alt="algolia" style={{ maxHeight: "18px" }} />
    </a>
  )
  const CustomPoweredBy = connectPoweredBy(PoweredBy)
  const Autocomplete = React.memo(({ currentRefinement, refine, hits }) => {
    console.log({ hits, refine, currentRefinement })
    //dispatch({ type: "updateSearchResults", data: hits })
    return (
      <div style={{ position: "relative" }}>
        <input
          // autoFocus
          id="search-bar"
          type="search"
          className="input"
          value={currentRefinement}
          onChange={event => refine(event.currentTarget.value)}
          onFocus={() => {
            try {
              setShowHits(true)
              setTimeout(() => {
                let element = document.getElementById("search-bar")
                if (element) element.focus()
              }, 500)
            } catch (err) {
              console.log(err)
            }
          }}
          //id="search-field"
          onBlur={() => {
            setTimeout(() => {
              console.log("blur")
              setShowHits(false)
            }, 500)
          }}
          onKeyDown={keyChanged}
        />
        <span
          style={{
            position: "absolute",
            right: "5px",
            top: "-20px",
            color: "#4a4a4a",
            zIndex: "10",
          }}
        >
          <CustomPoweredBy />
        </span>
        {showHits && (
          <ul style={{ zIndex: "99" }} className="results-container">
            {hits.map(hit => (
              // <li key={hit.objectID}>{hit.firstName}</li>
              <article
                key={hit.objectID}
                className="card result-cards"
                style={{ padding: "5px", cursor: "pointer" }}
                onClick={params => {
                  console.log({ hit })
                  dispatch({
                    type: "addSearchedActivity",
                    activeTab: activeTab,
                    selectedItem: hit,
                  })
                  setShowHits(false)
                  dispatch({ type: "syncSchedule", data: true })

                  // geoCodeActivityAddress()
                }}
              >
                <div className="media-content">
                  <div className="content">
                    <p>
                      <strong>{hit.activityName}</strong>{" "}
                      <small style={{ fontWeight: "300" }}>
                        {hit.activityType}
                      </small>{" "}
                      <br></br> <small>{hit.activityAddress}</small>
                      {/* <br></br>
                      {dottedText(hit.activityDescription, 100)} */}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </ul>
        )}

        {/* <ul>
        {hits != undefined
          ? hits.map(index => (
              <li key={index.index}>
                <h2>{index.index}</h2>
                <ul>
                  {index.hits.map(hit => (
                    <li key={hit.objectID}>{hit.name}</li>
                  ))}
                </ul>
              </li>
            ))
          : ""}
      </ul> */}
      </div>
    )
  })
  const getTripDestinations = () => {
    try {
      if (state.tripDetails.tripDestinations) {
        let d = state.tripDetails.tripDestinations.map((item, index) => {
          return { value: `${item.place}`, label: `${item.place}` }
        })
        return d
      }
    } catch (err) {
      return []
    }
  }
  const getTripDestination = () => {
    try {
      return state.currentJobInfo.designerWorkspace.itinerary[activeTab]
        .daysDestination
    } catch (err) {
      return []
    }
  }

  const formatDestinations = () => {
    try {
      var destinationString = ""
      state.currentJobInfo.designerWorkspace.itinerary[
        activeTab
      ].daysDestination.map((item, index) => {
        destinationString =
          destinationString +
          item.label +
          (state.currentJobInfo.designerWorkspace.itinerary[activeTab]
            .daysDestination.length ===
          index + 1
            ? ""
            : ", ")
      })
      console.log(destinationString)
      return destinationString
    } catch (err) {
      console.log(err)
      return ""
    }
  }

  const removeIsNew = data => {
    try {
      if (data) {
        let res = []
        data.map((val, index) => {
          res.push({ label: titleCase(val.label), value: val.value })
        })
        return res
      }
      return []
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const showDayDestination = () => {
    try {
      if (
        state.currentJobInfo.designerWorkspace.itinerary[activeTab] !==
        undefined
      ) {
        return state.currentJobInfo.designerWorkspace.itinerary[activeTab]
          .daysDestination === "" ||
          state.currentJobInfo.designerWorkspace.itinerary[activeTab]
            .daysDestination === undefined ||
          editDayDestination ? (
          canEdit && (
            <div style={{ margin: "15px 0px" }}>
              <p style={{ marginBottom: "5px", fontWeight: "600" }}>
                Which destination is this day being planned for?
              </p>
              <div className="field">
                <div
                  className="control is-expanded"
                  style={{ display: "flex" }}
                >
                  <div style={{ flexGrow: "2", marginRight: "10px" }}>
                    <CreatableSelect
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      isMulti
                      className="basic-multi-select"
                      // isMulti
                      defaultValue={getTripDestination()}
                      onFocus={() => {
                        //  clearError("accommodation")
                        // setTripTypeError(false)
                      }}
                      // defaultValue={state.tripLogistics.tripType}
                      // defaultValue={getTripDestinations()}
                      // className="basic-select"
                      classNamePrefix="select"
                      onChange={(value, actionMeta) => {
                        setEditDayDestination(true)
                        console.log({ value })
                        if (value) {
                          let a = removeIsNew(value)
                          console.log({ a })
                          dispatch({
                            type: "updateDayDestination",
                            data: a,
                            activeTab: activeTab,
                          })
                          // dispatch({ type: "syncDB", data: true })
                        }
                      }}
                      isSearchable="true"
                      isClearable="false"
                      options={getTripDestinations()}
                      theme={theme => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#fef0bf",
                          primary50: "#fee27f",
                          primary: "#fed43f",
                          //neutral0: "#424242",
                        },
                      })}
                    />
                    <p className="help">
                      pick all destination that apply to this day's itinerary
                    </p>
                  </div>
                  <button
                    className="button is-warning"
                    onClick={() => {
                      dispatch({ type: "syncDB", data: true })
                      setEditDayDestination(false)
                    }}
                  >
                    Done
                  </button>
                  {/* {tripTypeError ? (
                    <p className="help is-danger">Please pick a trip type</p>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "15px 0px",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginRight: "10px",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              <span
                style={{
                  marginRight: "7px",
                  fontWeight: "300",
                  fontSize: "10px",
                  textTransform: "uppercase",
                  letterSpacing: "0.5px",
                }}
              >
                This day's itinerary covers
              </span>
              <p>
                {// state.currentJobInfo.designerWorkspace.itinerary[activeTab]
                //   .daysDestination
                formatDestinations()}
              </p>
            </p>
            {canEdit && (
              <a
                style={{
                  textTransform: "uppercase",
                  fontSize: "12px",
                  lineHeight: "27px",
                  color: "#fec600",
                  letterSpacing: "0.5px",
                }}
                className=""
                onClick={() => {
                  setEditDayDestination(true)
                  // dispatch({
                  //   type: "updateDayDestination",
                  //   data: "",
                  //   activeTab: activeTab,
                  // })
                }}
              >
                {/* <i className="far fa-edit"></i> */} change
              </a>
            )}
          </div>
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const CustomAutocomplete = connectAutoComplete(Autocomplete)

  const itineraryTabItemsList = [
    { name: "View Map", id: "map", icon: "fa-map" },
    { name: "Day's Notes", id: "note", icon: "fa-clipboard" },
  ]
  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      //   ["link", "image"],
      ["link"],
    ],
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
  ]
  const showViewer = () => {
    return (
      <div
        style={{
          //   border: "1px solid #fec600",
          //   minHeight: "400px",
          width: "100%",
        }}
      >
        {tripDayNotes ? (
          <>
            {" "}
            <label className="label">Trip Notes</label>
            <div className="ql-editor">{ReactHtmlParser(tripDayNotes)}</div>
          </>
        ) : (
          <p
            style={{
              // fontStyle: "italic",
              fontSize: "14px",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            - No notes added yet -
          </p>
        )}
      </div>
    )
  }

  const showEditor = () => {
    return (
      <div
        style={{
          //   border: "1px solid #fec600",
          //   minHeight: "400px",
          width: "100%",
        }}
      >
        {/* <label className="label">Day Notes</label> */}
        {/* <ReactQuill
          value={state.currentJobInfo.tripNotes || ""}
          ref={e => (notesRef = e)}
          theme="bubble"
          onChange={handleNotesInHtml}
          onBlur={() => {
            console.log("blur")
          }}
          placeholder="Start typing your notes here..."
          modules={modules}
          formats={formats}
        >
          <div className="my-editing-area" />
        </ReactQuill> */}
        <ReactQuill
          theme="bubble"
          value={tripDayNotes}
          onChange={val => {
            setTripDayNotes(val)
            setShowSaveButton(true)
          }}
          modules={modules}
          formats={formats}
          placeholder="Type or paste your notes for this day here..."
        />
        {showSaveButton && (
          <div
            className="field"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
          >
            <div className="control">
              <button
                class={`button is-warning ${isBusy ? "is-loading" : ""}`}
                onClick={() => {
                  if (isBusy) return
                  setIsBusy(true)
                  dispatch({
                    type: "updateTripDayNotes",
                    data: tripDayNotes,
                    activeTab: activeTab,
                  })
                  dispatch({ type: "syncDB", data: true })
                  setIsBusy(false)
                }}
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  textDecorationColor: "#fec600",
                  color: "rgba(74, 74, 74, 0.8)",
                  textDecoration: "none",
                  minWidth: "100px",
                  fontSize: "14px",
                  //   borderRadius: "10px",
                }}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
  const displayNotes = (canEdit = false) => {
    try {
      return (
        <div style={{ marginTop: "20px" }}>
          {canEdit ? showEditor() : showViewer()}
        </div>
      )
    } catch (error) {
      console.log(error)
    }
  }
  const showRightTab = () => {
    try {
      switch (activeSubTab) {
        case "map":
          return <>{displayMap(false)}</>
          break

        case "note":
          return <>{displayNotes(canEdit)}</>
        default:
          break
      }
    } catch (error) {}
  }
  const showSelectedItemsCount = () => {
    try {
      var a = countBy(
        state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities,
        val => {
          return val.activity.selected === true
        }
      )
      if (a.true > 0 && !toggleSelectedItemsMenu) {
        setToggleSelectedItemsMenu(true)
      } else {
        console.log({ toggleSelectedItemsMenu })
        if (toggleSelectedItemsMenu && !a.true)
          setToggleSelectedItemsMenu(false)
      }
      console.log({ a })
      return a.true > 0 ? (
        <p style={{ fontSize: "12px" }}>{a.true} activities selected</p>
      ) : (
        <p style={{ fontSize: "12px" }}> No activities selected</p>
      )
    } catch (error) {
      console.log(error)
    }
  }
  const handleSelectedItemsMenuDropdownBlur = () => {
    console.log("blur")
    if (toggleSelectedItemsMenuDropdown)
      setToggleSelectedItemsMenuDropdown(false)
  }

  const selectionMenuIcons = () => {
    try {
      return (
        <>
          <>
            {" "}
            <div style={{ marginRight: "20px" }}>
              <span
                onClick={e => {
                  navigate(
                    `/app/byottripdetails/${state.tripDetails.tripUid}?tab=wishlist`
                  )
                  setToggleSelectedItemsMenuDropdown(false)
                }}
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <i className="far fa-heart"> </i>

                {/* <p style={{ marginLeft: "7px" }}>Copy a wishlist activity</p> */}
              </span>
            </div>
            <div style={{ marginRight: "20px" }}>
              <span
                onClick={e => {
                  setShowNewActivityEntry(true)
                  setToggleSelectedItemsMenuDropdown(false)
                }}
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <i className="far fa-plus"> </i>

                {/* <p style={{ marginLeft: "7px" }}>Add a new activity</p> */}
              </span>
            </div>
            <div style={{ marginRight: "20px" }}>
              <span
                onClick={e => {
                  setItineraryBusy(true)
                  generateDaysDistanceMatrix(activeTab)
                  setToggleSelectedItemsMenuDropdown(false)
                }}
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <i class="fad fa-magic"></i>

                {/* <p style={{ marginLeft: "7px" }}>Optimize Days Plan</p> */}
              </span>
            </div>
            <div style={{ marginRight: "0px" }}>
              <span
                onClick={e => {
                  setDisableDrag(!disableDrag)
                  setToggleSelectedItemsMenuDropdown(false)
                }}
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <i class="fas fa-hand-rock"></i>

                {/* <p style={{ marginLeft: "7px" }}>
                  {" "}
                  {disableDrag ? "Enable Drag" : "Disable Drag"}
                </p> */}
              </span>
            </div>
          </>
        </>
      )
    } catch (error) {}
  }

  const selectionMenu = () => {
    try {
      return (
        <>
          {toggleSelectedItemsMenu && (
            <div
              className={`${
                toggleSelectedItemsMenuDropdown
                  ? "dropdown is-right is-active"
                  : "dropdown is-right"
              }`}
              tabindex="0"
              style={{
                background: "#fafafa",
                background: "transparent",
                backgroundColor: "transparent",
                outline: "none !important",
              }}
              onBlur={handleSelectedItemsMenuDropdownBlur}
            >
              <div className="dropdown-trigger">
                <a
                  className="is-text"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={e => {
                    setToggleSelectedItemsMenuDropdown(
                      !toggleSelectedItemsMenuDropdown
                    )
                    // e.stopPropagation()
                  }}
                  onBlur={handleSelectedItemsMenuDropdownBlur}
                  style={{
                    background: "transparent",
                    backgroundColor: "transparent",
                    outline: "none !important",
                    // zIndex: "99",
                  }}
                >
                  {/* <span
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  letterSpacing: "0.5px",
                  textTransform: "uppercase",
                  textDecoration: "underline",
                  color: "#4a4a4a",
                  textDecorationColor: "#fec600",
                }}
              >
                {toggleSelectedItemsMenu ? "Close" : "Menu"}
              </span> */}
                  {toggleSelectedItemsMenuDropdown ? (
                    <span className="icon" style={{ color: "#fec600" }}>
                      <i className="fas fa-times"></i>
                    </span>
                  ) : (
                    <span className="icon" style={{ color: "#fec600" }}>
                      <i className="fas fa-ellipsis-h-alt"></i>
                    </span>
                  )}
                </a>
              </div>
              <div
                class="dropdown-menu"
                id="dropdown-menu"
                role="menu"
                style={{
                  background: "#fafafa",
                  backgroundColor: "#fafafa",
                  outline: "none !important",
                  border: "1px solid #eaeaea",
                  borderRadius: "4px",
                  fontSize: "14px",
                }}
                onBlur={() => {
                  console.log("blur")
                  setToggleSelectedItemsMenuDropdown(false)
                }}
              >
                {/* <div
                  class="dropdown-content"
                  id="dropdown-menu-item"
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                >
                  <a
                    className="is-small dropdown-item"
                    onClick={e => {
                      setToggleMenu(false)
                      //   // onMoveActivity(e.currentTarget.id)
                      //   onMoveActivity(value.dayId, value.id, itemNo)
                      MoveActivityConfirmationModal({
                        onConfirm: dayIndex => {
                          dispatch({
                            type: "FromWishListToItinerarySelected",
                            activeTab: dayIndex,
                          })
                          clearSelection()
                          setToggleSelectedItemsMenuDropdown(false)
                          // dispatch({
                          //   type: "deleteWishListItem",
                          //   index: index,
                          // })

                          // dispatch({
                          //   type: "syncSchedule",
                          //   data: true,
                          // })
                          // dispatch({
                          //   type: "wishlistSyncRequired",
                          //   data: true,
                          // })
                        },
                        title: "Confirmation",
                        startDate:
                          typeof state.currentJobInfo.travelDates.start ===
                          "string"
                            ? new Date(state.currentJobInfo.travelDates.start)
                            : state.currentJobInfo.travelDates.start.toDate(),
                        endDate:
                          typeof state.currentJobInfo.travelDates.end ===
                          "string"
                            ? new Date(state.currentJobInfo.travelDates.end)
                            : state.currentJobInfo.travelDates.end.toDate(),
                        text: "Pick a date to move the selected activities to?",
                        selectedIndex: 9999,
                      })
                    }}
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <i className="far fa-copy"> </i>
                    <p style={{ marginLeft: "7px" }}>
                      {" "}
                      Copy activities to Itinerary
                    </p>
                  </a>
                </div> */}
                {/* <div
                  class="dropdown-content"
                  id="dropdown-menu-item"
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                >
                  <a
                    className="is-small dropdown-item"
                    onClick={e => {
                      setToggleMenu(9999)

                      ConfirmationModal({
                        onConfirm: index => {
                          dispatch({
                            type: "deleteSelectedWishListItem",
                          })
                          setToggleSelectedItemsMenuDropdown(false)
                        },
                        title: "Confirmation",
                        text: `Are you sure you want to permanently delete the activity?`,
                      })
                    }}
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <i className="far fa-trash-alt delete-icon-color"> </i>

                    <p style={{ marginLeft: "7px" }}>Delete Activities</p>
                  </a>
                </div> */}
                {canEdit && (
                  <>
                    {" "}
                    <div
                      class="dropdown-content"
                      id="dropdown-menu-item"
                      style={{
                        backgroundColor: "#fafafa",
                        display: "none",
                      }}
                    >
                      <a
                        className="is-small dropdown-item"
                        onClick={e => {
                          navigate(
                            `/app/byottripdetails/${state.tripDetails.tripUid}?tab=wishlist`
                          )
                          setToggleSelectedItemsMenuDropdown(false)
                        }}
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <i className="far fa-heart"> </i>

                        <p style={{ marginLeft: "7px" }}>
                          Copy a wishlist activity
                        </p>
                      </a>
                    </div>
                    <div
                      class="dropdown-content"
                      id="dropdown-menu-item"
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <a
                        className="is-small dropdown-item"
                        onClick={e => {
                          setShowNewActivityEntry(true)
                          setToggleSelectedItemsMenuDropdown(false)
                        }}
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <i className="far fa-plus"> </i>

                        <p style={{ marginLeft: "7px" }}>Add a new activity</p>
                      </a>
                    </div>
                    <div
                      class="dropdown-content"
                      id="dropdown-menu-item"
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <a
                        className="is-small dropdown-item"
                        onClick={e => {
                          setItineraryBusy(true)
                          generateDaysDistanceMatrix(activeTab)
                          setToggleSelectedItemsMenuDropdown(false)
                        }}
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <i class="fas fa-magic"></i>

                        <p style={{ marginLeft: "7px" }}>Optimize Days Plan</p>
                      </a>
                    </div>
                    <div
                      class="dropdown-content"
                      id="dropdown-menu-item"
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <a
                        className="is-small dropdown-item"
                        onClick={e => {
                          setDisableDrag(!disableDrag)
                          setToggleSelectedItemsMenuDropdown(false)
                        }}
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <i class="fas fa-hand-rock"></i>

                        <p style={{ marginLeft: "7px" }}>
                          {" "}
                          {disableDrag ? "Enable Drag" : "Disable Drag"}
                        </p>
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )
    } catch (error) {}
  }

  // const showMenu = () => {
  //   try {
  //     return  <div
  //                 className={`${
  //                   toggleSelectedItemsMenuDropdown
  //                     ? "dropdown is-right is-active"
  //                     : "dropdown is-right"
  //                 }`}
  //                 tabindex="0"
  //                 style={{
  //                   background: "#fafafa",
  //                   background: "transparent",
  //                   backgroundColor: "transparent",
  //                   outline: "none !important",
  //                 }}
  //                 onBlur={handleSelectedItemsMenuDropdownBlur}
  //               >
  //                 <div className="dropdown-trigger">
  //                   <a
  //                     className="is-text"
  //                     aria-haspopup="true"
  //                     aria-controls="dropdown-menu"
  //                     onClick={e => {
  //                       setToggleSelectedItemsMenuDropdown(
  //                         !toggleSelectedItemsMenuDropdown
  //                       )
  //                       // e.stopPropagation()
  //                     }}
  //                     onBlur={handleSelectedItemsMenuDropdownBlur}
  //                     style={{
  //                       background: "transparent",
  //                       backgroundColor: "transparent",
  //                       outline: "none !important",
  //                       // zIndex: "99",
  //                     }}
  //                   >
  //                     {/* <span
  //                       style={{
  //                         fontSize: "12px",
  //                         fontWeight: "500",
  //                         letterSpacing: "0.5px",
  //                         textTransform: "uppercase",
  //                         textDecoration: "underline",
  //                         color: "#4a4a4a",
  //                         textDecorationColor: "#fec600",
  //                       }}
  //                     >
  //                       {toggleSelectedItemsMenu ? "Close" : "Menu"}
  //                     </span> */}
  //                     {toggleSelectedItemsMenuDropdown ? (
  //                       <span className="icon" style={{ color: "#fec600" }}>
  //                         <i className="fas fa-times"></i>
  //                       </span>
  //                     ) : (
  //                       <span className="icon" style={{ color: "#fec600" }}>
  //                         <i className="fas fa-ellipsis-h-alt"></i>
  //                       </span>
  //                     )}
  //                   </a>
  //                 </div>
  //                 <div
  //                   class="dropdown-menu"
  //                   id="dropdown-menu"
  //                   role="menu"
  //                   style={{
  //                     background: "#fafafa",
  //                     backgroundColor: "#fafafa",
  //                     outline: "none !important",
  //                     border: "1px solid #eaeaea",
  //                     borderRadius: "4px",
  //                     fontSize: "14px",
  //                   }}
  //                   onBlur={() => {
  //                     console.log("blur")
  //                     setToggleSelectedItemsMenuDropdown(false)
  //                   }}
  //                 >
  //                   <div
  //                     class="dropdown-content"
  //                     id="dropdown-menu-item"
  //                     style={{
  //                       backgroundColor: "#fafafa",
  //                     }}
  //                   >
  //                     <a
  //                       className="is-small dropdown-item"
  //                       onClick={e => {
  //                         setToggleMenu(false)
  //                         //   // onMoveActivity(e.currentTarget.id)
  //                         //   onMoveActivity(value.dayId, value.id, itemNo)
  //                         MoveActivityConfirmationModal({
  //                           onConfirm: dayIndex => {
  //                             dispatch({
  //                               type: "FromWishListToItinerarySelected",
  //                               activeTab: dayIndex,
  //                             })
  //                             clearSelection()
  //                             setToggleSelectedItemsMenuDropdown(false)
  //                             // dispatch({
  //                             //   type: "deleteWishListItem",
  //                             //   index: index,
  //                             // })

  //                             // dispatch({
  //                             //   type: "syncSchedule",
  //                             //   data: true,
  //                             // })
  //                             // dispatch({
  //                             //   type: "wishlistSyncRequired",
  //                             //   data: true,
  //                             // })
  //                           },
  //                           title: "Confirmation",
  //                           startDate:
  //                             typeof state.currentJobInfo.travelDates.start ===
  //                             "string"
  //                               ? new Date(
  //                                   state.currentJobInfo.travelDates.start
  //                                 )
  //                               : state.currentJobInfo.travelDates.start.toDate(),
  //                           endDate:
  //                             typeof state.currentJobInfo.travelDates.end ===
  //                             "string"
  //                               ? new Date(state.currentJobInfo.travelDates.end)
  //                               : state.currentJobInfo.travelDates.end.toDate(),
  //                           text:
  //                             "Pick a date to move the selected activities to?",
  //                           selectedIndex: 9999,
  //                         })
  //                       }}
  //                       style={{
  //                         display: "flex",
  //                         alignItems: "baseline",
  //                       }}
  //                     >
  //                       <i className="far fa-copy"> </i>
  //                       <p style={{ marginLeft: "7px" }}>
  //                         {" "}
  //                         Copy activities to Itinerary
  //                       </p>
  //                     </a>
  //                   </div>
  //                   <div
  //                     class="dropdown-content"
  //                     id="dropdown-menu-item"
  //                     style={{
  //                       backgroundColor: "#fafafa",
  //                     }}
  //                   >
  //                     <a
  //                       className="is-small dropdown-item"
  //                       onClick={e => {
  //                         setToggleMenu(9999)

  //                         ConfirmationModal({
  //                           onConfirm: index => {
  //                             dispatch({
  //                               type: "deleteSelectedWishListItem",
  //                             })
  //                             setToggleSelectedItemsMenuDropdown(false)
  //                           },
  //                           title: "Confirmation",
  //                           text: `Are you sure you want to permanently delete the activity?`,
  //                         })
  //                       }}
  //                       style={{
  //                         display: "flex",
  //                         alignItems: "baseline",
  //                       }}
  //                     >
  //                       <i className="far fa-trash-alt delete-icon-color"> </i>

  //                       <p style={{ marginLeft: "7px" }}>Delete Activities</p>
  //                     </a>
  //                   </div>
  //                   <div
  //                     class="dropdown-content"
  //                     id="dropdown-menu-item"
  //                     style={{
  //                       backgroundColor: "#fafafa",
  //                     }}
  //                   >
  //                     <a
  //                       className="is-small dropdown-item"
  //                       onClick={e => {
  //                         dispatch({
  //                           type: "ResetWishlistSelectionsWithDBSync",
  //                         })
  //                         setToggleSelectedItemsMenuDropdown(false)
  //                       }}
  //                       style={{
  //                         display: "flex",
  //                         alignItems: "baseline",
  //                       }}
  //                     >
  //                       <i className="far fa-minus"> </i>

  //                       <p style={{ marginLeft: "7px" }}>Clear Selection</p>
  //                     </a>
  //                   </div>
  //                 </div>
  //               </div>
  //   } catch (error) {

  //   }
  //  }

  var getDArray = function() {
    var s =
      typeof state.currentJobInfo.travelDates.start === "string"
        ? new Date(state.currentJobInfo.travelDates.start)
        : state.currentJobInfo.travelDates.start.toDate()
    var e =
      typeof state.currentJobInfo.travelDates.end === "string"
        ? new Date(state.currentJobInfo.travelDates.end)
        : state.currentJobInfo.travelDates.end.toDate()
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d))
    }
    console.log({ a })
    return a
  }

  const showAllItineraryItems = () => {
    try {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]
      // const months = [
      //   "January",
      //   "February",
      //   "March",
      //   "April",
      //   "May",
      //   "June",
      //   "July",
      //   "August",
      //   "September",
      //   "October",
      //   "November",
      //   "December",
      // ]

      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
      return (
        <>
          {getDArray().map((item, index) => {
            var month = months[item.getMonth()]
            var date = item.getDate()
            var day = weekdays[item.getDay()]
            let a = { month: month, day: day, date: date }
            return (
              <div
                style={{
                  marginRight: "30px",
                  minWidth: "250px",
                  borderRight: "1px solid #ebebeb",
                  textAlign: "center",
                }}
              >
                <p style={{ fontSize: "14px", color: "rgba(74,74,74,0.6)" }}>
                  {item.toLocaleDateString()}
                </p>
                <SortableList
                  items={
                    state.currentJobInfo.designerWorkspace.itinerary[index]
                      .activities
                  }
                  backupItems={
                    state.currentJobInfo.designerWorkspace.itinerary[index]
                      .backupActivities
                  }
                  onSortEnd={onSortEnd}
                  onSortStart={onSortStart}
                  shouldCancelStart={shouldCancelStart}
                  onRemove={onRemove}
                  onMoveToBackup={onMoveToBackup}
                  activeTab={activeTab}
                  onEditActivity={onEditActivity}
                  onMoveActivity={onMoveActivity}
                  onEditCommute={onEditCommute}
                  onAddCommute={onAddCommute}
                  onRemoveCommute={onRemoveCommute}
                  disableDrag={disableDrag}
                  canEdit={canEdit}
                  lockAxis="y"
                  distance={10}
                  onSelect={onSelect}
                  isItineraryBusy={ItineraryBusy}
                  // useDragHandle={true}
                  // hideSortableGhost={true}
                />
              </div>
            )
          })}
        </>
      )
    } catch (error) {
      console.log(error)
      return ""
    }
  }
  const showTopAttractions = () => {
    try {
      return
    } catch (error) {
      return ""
    }
  }
  const screenWidth = useWindowWidth()
  const getHours = val => {
    try {
      if (val) {
        return val === 0 ? "0" : parseInt(val / 60).toString()
      } else return "0"
    } catch (error) {
      return "0"
    }
  }
  const getMinutes = val => {
    try {
      if (val) {
        return val === 0 ? "0" : (val % 60).toString()
      } else return "0"
    } catch (error) {
      return "0"
    }
  }
  const showDetailsModal = ({ item }) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        console.log({ item })
        if (item)
          return (
            <div
              className="box"
              style={{
                maxWidth: "600px",
                //height: "200px",
                padding: "25px",
                // textAlign: "center",
                boxShadow: "0 20px 75px rgba(0, 0, 0, 0.23)",
                position: "relative",
                border: "1px #ebebeb solid",
              }}
            >
              <div
                className="welcome-close"
                style={{ cursor: "pointer", right: "10px", top: "12px" }}
                onClick={() => {
                  onClose()
                }}
              >
                <i className="fas fa-times-circle fa-2x"></i>
              </div>
              {/* <div
                className="activity-card-data-show-compact"
                onClick={() => onClose()}
              >
                <i className="far fa-times"></i>
              </div> */}
              <div className="activity-card-data">
                <div
                  className="activity-card-name"
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "rgba(74,74,74,0.8)",
                  }}
                >
                  {titleCase(item.name)}
                </div>
                {/* <div className="activity-card-type" style={{ marginTop: "8px" }}>
          <i className="fas fa-map-marker-alt" style={{ marginRight: "3px" }}></i>5
          Rue Lucien Sampaix, 75010 Paris, France
        </div> */}
                <div
                  className="activity-card-type"
                  style={{
                    marginTop: "5px",
                    fontSize: "12px",
                    color: "#4a4a4a",
                    textDecorationColor: "#fec600",
                    textDecoration: "underline",
                  }}
                >
                  <i
                    className="fas fa-map-marker-alt"
                    style={{ marginRight: "5px" }}
                  ></i>
                  <a
                    // onClick={e => {
                    //   e.preventDefault()
                    //   var a =
                    //   console.log("clicked")
                    //   customNavigateTo(a)
                    // }}
                    style={{
                      fontSize: "12px",
                      color: "#4a4a4a",

                      textDecoration: "underline",
                      textDecorationColor: "#fec600",
                    }}
                    href={googleMapsURI(item.address)}
                    target="_blank"
                  >
                    {item.address}
                  </a>
                </div>
                {item.phone ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    <i
                      className="fas fa-phone-alt"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a
                      style={{
                        fontSize: "12px",
                        color: "#4a4a4a",

                        textDecoration: "underline",
                        textDecorationColor: "#fec600",
                      }}
                      href={`tel:${item.activityPhone}`}
                    >
                      {item.phone}
                    </a>
                  </div>
                ) : (
                  ""
                )}
                {item.url ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "5px", fontSize: "12px" }}
                  >
                    {" "}
                    <i
                      className="fas fa-link"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a
                      style={{
                        fontSize: "12px",
                        color: "#4a4a4a",

                        textDecoration: "underline",
                        textDecorationColor: "#fec600",
                      }}
                      href={item.url}
                      target="_blank"
                    >
                      {dottedText(item.url, 48)}
                    </a>
                  </div>
                ) : (
                  ""
                )}

                {item.type === "restaurant" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i
                      className="fas fa-external-link-alt"
                      style={{ marginRight: "5px" }}
                    ></i>
                    <a href={item.menu} target="_blank">
                      Menu
                    </a>
                  </div>
                ) : (
                  " "
                )}
                {item.type === "sightseeing"
                  ? item.entryFee && (
                      <div
                        className="activity-card-type"
                        style={{ marginTop: "8px", fontSize: "12px" }}
                      >
                        Entry Fee (per person in USD): {item.entryFee}
                      </div>
                    )
                  : " "}
                {/* {formatDuration() !== "" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "14px", fontSize: "12px" }}
                  >
                    <i className="far fa-clock"></i>
                    {formatDuration()}
                  </div>
                ) : (
                  ""
                )} */}
                {/* {item.activityType === "restaurant" ? (
                  <div
                    className="activity-card-type"
                    style={{ marginTop: "8px" }}
                  >
                    <i className="fas fa-dollar-sign"></i>
                    <i className="fas fa-dollar-sign"></i>
                    <i className="fas fa-dollar-sign"></i>
                  </div>
                ) : (
                  " "
                )} */}
                {/* <hr className="hr-branded"></hr> */}

                {/* <hr className="hr-branded"></hr> */}
                {/* <div className="activity-card-type" style={{ marginTop: "5px" }}>
          <i className="fas fa-phone" style={{ marginRight: "3px" }}></i>+33 1 82 28
          00 80
        </div> */}
                <div style={{ marginTop: "15px" }}>
                  {/* <AsyncFetchImage
                    imageId={item.imageId}
                    rounded={false}
                    fullWidth={true}
                  /> */}
                  <ShowImage
                    imageObj={item.image}
                    isFullWidth={true}
                    isRounded={false}
                  />
                  {/* {showImage(item.image, true, false)} */}
                </div>
                {item.description === "" ? (
                  ""
                ) : (
                  <div
                    className="activity-card-type"
                    style={{
                      marginTop: "16px",
                      marginBottom: "20px",
                      fontSize: "14px",
                    }}
                  >
                    {/* <label className="label">Description </label> */}
                    <p>{item.description}</p>
                    <a
                      href={
                        item.descriptionURL
                          ? `https://${item.descriptionURL}`
                          : "https://en.wikipedia.org/wiki/Main_Page"
                      }
                      target="_blank"
                    >
                      Source Wikipedia
                    </a>
                    {/* {ReactHtmlParser(item.activityDescription, {
                    decodeEntities: false,
                  })} */}
                  </div>
                )}

                {(item.image && item.image === "") ||
                item.image === undefined ? (
                  <iframe
                    title="Paris"
                    // src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
                    //   item.name
                    // )}+${encodeURI(item.activityAddress)}`}

                    src={getFormattedAddressForIframe(item.name, item.address)}
                    width="100%"
                    height="300px"
                    allowFullScreen
                  ></iframe>
                ) : (
                  ""
                )}

                <div
                  style={{
                    width: "115px",
                    marginLeft: "auto",
                    marginRight: "0px",
                  }}
                >
                  <button
                    className="button is-warning"
                    style={{ width: "100%", fontSize: "14px" }}
                    onClick={() => {
                      updateWishlistWithAttraction(item)
                    }}
                  >
                    Add to plan
                  </button>
                </div>
              </div>
            </div>
          )
      },
    })
  }

  const getFormattedAddressForIframe = (name, address) => {
    try {
      const gpsRegX = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
      var result = gpsRegX.exec(address)
      console.log({ result })
      let a =
        result !== null
          ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
              address
            )}`
          : `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
              name
            )}+${encodeURI(address)}`

      console.log({ a })
      return a
    } catch (err) {
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyDm3CrjOnhItsIxs5KwVqUdTXzG7sHN88A&zoom=17&q=${encodeURI(
        name
      )}+${encodeURI(address)}`
    }
  }
  const updateWishlistWithAttraction = selectedAttraction => {
    try {
      if (selectedAttraction) {
        //Check if the attraction already exists
        console.log({ selectedAttraction })
        let index = -1
        if (
          state.currentJobInfo.designerWorkspace.itinerary[activeTab].activities
        ) {
          index = state.currentJobInfo.designerWorkspace.itinerary[
            activeTab
          ].activities.findIndex(
            item => item.activity.activityName === selectedAttraction.name
          )
        } else index = -1
        console.log(index)
        if (index === -1) {
          let tempActivityScratchPad = {}
          tempActivityScratchPad["activityType"] = selectedAttraction["type"]
          tempActivityScratchPad["activityName"] = selectedAttraction["name"]
          tempActivityScratchPad["activityDescription"] =
            selectedAttraction["description"]
          if (selectedAttraction["descriptionURL"])
            tempActivityScratchPad["activityDescriptionURL"] =
              selectedAttraction["descriptionURL"]
          tempActivityScratchPad["activityAddress"] =
            selectedAttraction["address"]
          tempActivityScratchPad["activityEntryFee"] =
            selectedAttraction["entryFee"]
          tempActivityScratchPad["activityPhone"] = selectedAttraction["phone"]
          if (selectedAttraction["image"])
            tempActivityScratchPad["activityImageData"] =
              selectedAttraction["image"]
          if (selectedAttraction["imageId"])
            tempActivityScratchPad["activityImageId"] =
              selectedAttraction["imageId"]
          if (selectedAttraction["latlng"])
            tempActivityScratchPad["activityLatLng"] =
              selectedAttraction["latlng"]
          tempActivityScratchPad["activityURL1"] = selectedAttraction["url"]
          tempActivityScratchPad["activityUid"] = selectedAttraction["uid"]
          tempActivityScratchPad["activityHours"] = selectedAttraction["hours"]
          tempActivityScratchPad["activityViewTime"] =
            selectedAttraction["viewTime"]

          tempActivityScratchPad["activityDurationHours"] = getHours(
            selectedAttraction["viewTime"]
          )
          tempActivityScratchPad["activityDurationMinutes"] = getMinutes(
            selectedAttraction["viewTime"]
          )
          console.log({ tempActivityScratchPad })
          dispatch({
            type: "addNewActivityWithData",
            activeTab: activeTab,
            addCommute: false,
            data: tempActivityScratchPad,
          })
          addToast(`Attraction added to your plan`, {
            appearance: "info",
            autoDismiss: true,
            placement: "top-center",
          })
        } else {
          console.log("Item already present in wishlist")
          addToast(`Attraction is already in your day's plan`, {
            appearance: "info",
            autoDismiss: true,
            placement: "top-center",
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const showRouteWizard = () => {
    try {
      return (
        <>
          {state.currentJobInfo.designerWorkspace.itinerary[activeTab]
            .activities.length < 3 ? (
            ""
          ) : (
            <div
              style={{ height: "132px" }}
              className="itinerary-wizard-button brand-shadow"
              onClick={e => {
                setItineraryBusy(true)
                generateDaysDistanceMatrix(activeTab)
              }}
            >
              {ItineraryBusy ? (
                <div style={{}}>
                  <div
                    className="spinner"
                    style={{
                      zIndex: "20",
                      marginTop: "18px",
                      marginBottom: "30px",
                    }}
                  >
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                  </div>
                </div>
              ) : (
                <img className="itinerary-wizard-button-image" src={wizard} />
              )}

              <p className="itinerary-wizard-button-text">Optimize Itinerary</p>
            </div>
          )}
        </>
      )
    } catch (error) {}
  }
  const showRightComponent = () => {
    return (
      <>
        {showNewActivityEntry && screenWidth < 1024 ? (
          <>{launchActivityFormMobile()} </>
        ) : (
          <>
            {" "}
            <ReactTooltip />
            <p className="tab-header">
              Trip Itinerary<span style={{ color: "#fec600" }}>.</span>
            </p>
            {canEdit && (
              <div
                style={{
                  marginTop: screenWidth > 1024 ? "10px" : "0px",
                  marginBottom: screenWidth > 1024 ? "30px" : "0px",
                  padding: "0px 0px",
                  // background: "#f7f7f7",
                  borderRadius: "6px",
                }}
              >
                <TopAttractions
                  addAction={updateWishlistWithAttraction}
                  showDetailAction={showDetailsModal}
                />
              </div>
            )}
            <div style={{ display: screenWidth > 1024 ? "flex" : "block" }}>
              <ItineraryDateTabs
                onTabChange={onTabChange}
                location={location}
              />

              {/* <hr
                        className="hr-branded"
                        style={{ backgroundColor: "white" }}
                      ></hr> */}

              <div
                className="columns"
                style={{
                  width: screenWidth > 1024 ? "100%" : "block",
                  marginTop: "0px",
                }}
              >
                {/* <div className="column is-0-desktop"></div> */}
                <div
                  className="column is-5-desktop is-7-tablet"
                  style={{ paddingTop: screenWidth > 1024 ? "0px" : "20px" }}
                >
                  <div className="activity-list-container">
                    <>
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          borderBottom: "1px solid #fec600",
                          marginBottom: "20px",
                          color: "rgba(74,74,74,0.8)",
                          display: screenWidth > 1024 ? "none" : "block",
                        }}
                      >
                        Days Itinerary
                      </p>

                      {/* {showDayDestination()} */}
                      <div className="details-container">
                        {showFlights()}
                        {showAccommodations()}
                        {showBookings()}
                        {showRentalCars()}
                      </div>
                      {/* <hr className="hr-branded"></hr> */}
                      {/* {showDayNotes()} */}
                      {/* <hr
                                className="hr-branded"
                                // style={{ backgroundColor: "white" }}
                              ></hr> */}

                      {canEdit && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "20px",
                            alignItems: "center",
                            display: "none",
                          }}
                        >
                          {/* {showSelectedItemsCount()}  */}
                          {selectionMenu()}
                        </div>
                      )}
                      {/* {canEdit && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: "20px",
                                    alignItems: "center",
                                  }}
                                >

                                  {selectionMenuIcons()}
                                </div>
                              )} */}
                      {canEdit && (
                        <div
                          className="details-container"
                          style={{ marginTop: "20px", height: "80px" }}
                        >
                          {" "}
                          <AttractionsSearchBar
                            onItemSelected={updateWishlistWithAttraction}
                            onAddNewItem={setShowNewActivityEntry}
                          />
                        </div>
                      )}
                      {showActivities()}
                      {canEdit && <> {showRouteWizard()}</>}
                    </>
                  </div>
                </div>
                {/* <div className="column is-0-desktop is-0-tablet"></div> */}
                <div
                  className="column is-7-desktop is-5-tablet is-hidden-mobile"
                  style={{ paddingTop: screenWidth > 1024 ? "0px" : "12px" }}
                >
                  {/* {displayDaysStats()} */}
                  <>
                    {showNewActivityEntry && screenWidth > 1024 ? (
                      <>{launchActivityFormDesktop()} </>
                    ) : (
                      <>
                        <div
                          // className="tabs is-centered"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                            // paddingBottom: "10px",
                            borderBottom: "1px solid #fec600",
                            // minWidth: "512px",
                            marginTop: "-30px",
                          }}
                        >
                          {itineraryTabItemsList.map(item => (
                            <>
                              <a
                                id={item.id}
                                style={{
                                  letterSpacing: "0.5px",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  padding: "10px",
                                }}
                                onClick={e => {
                                  setActiveSubTab(e.currentTarget.id)
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {activeSubTab === item.id && (
                                    <span
                                      style={{
                                        width: "8px",
                                        height: "8px",
                                        background: "#fec600",
                                        borderRadius: "50%",
                                        marginBottom: "5px",
                                      }}
                                    ></span>
                                  )}
                                  <span
                                    className=""
                                    style={{
                                      display: "flex",
                                      // alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    <span class="icon-text">
                                      {/* <span class="icon">
                                            <i
                                              className={`far ${item.icon}`}
                                              style={{
                                                color:
                                                  activeSubTab === item.id
                                                    ? "#fec600"
                                                    : "rgba(74,74,74,0.8)",
                                              }}
                                            ></i>
                                          </span> */}
                                      <span
                                        style={{
                                          color: "rgba(74,74,74,0.8)",
                                          fontFamily: "poppins",
                                          fontSize: "12px",
                                          color:
                                            activeSubTab === item.id
                                              ? "#fec600"
                                              : "rgba(74,74,74,0.8)",
                                        }}
                                      >
                                        {item.name}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                {/* {item.name} */}
                              </a>
                            </>
                            // </li>
                          ))}
                        </div>
                        {/* <p
                           style={{
                             fontSize: "18px",
                             fontWeight: "700",
                             borderBottom: "1px solid #fec600",
                             marginBottom: "20px",
                             color: "rgba(74,74,74,0.8)",
                           }}
                         >
                           Map View
                         </p> */}
                        {showRightTab()}
                      </>
                    )}
                  </>
                </div>
                {showMobileMap && displayMap(true)}
                {showMobileMap && (
                  <div
                    style={{
                      position: "fixed",
                      left: "10px",
                      top: "10px",
                      zIndex: "99999",
                    }}
                  >
                    <button
                      className="button is-warning"
                      style={{
                        minHeight: "40px",
                        fontSize: "14px",
                        boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
                      }}
                      onClick={() => {
                        setShowMobileMap(false)
                      }}
                    >
                      Exit Map
                    </button>
                  </div>
                )}

                {/* <div className="column is-0-desktop"></div> */}
              </div>
              <div
                className="is-hidden-desktop is-hidden-tablet"
                style={{
                  width: " 50px",
                  height: " 50px",
                  position: " fixed",
                  borderRadius: "50%",
                  zIndex: " 99",
                  right: " 50px",
                  bottom: " 50px",
                  cursor: " pointer",
                  color: " rgba(74,74,74,0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #ebebeb",
                  background: "#fec600",
                  boxShadow:
                    "0 1px 2px rgba(0, 0, 15, 0.06), 0 2px 4px rgba(0, 0, 15, 0.06),0 4px 8px rgba(0, 0, 15, 0.06), 0 8px 16px rgba(0, 0, 15, 0.06),0 16px 32px rgba(0, 0, 15, 0.06), 0 32px 64px rgba(0, 0, 15, 0.06)",
                }}
                onClick={() => {
                  {
                    setShowMobileMap(true)
                  }
                }}
              >
                <i className="far fa-map-marker-alt fa-lg"></i>
                {/* <img src={map} alt="" /> */}
                {/* <i className="far fa-globe-americas fa-3x"></i>{" "} */}
                {/* <p
                       style={{
                         fontSize: "14px",
                         textTransform: "uppercase",
                         letterSpacing: "0.5px",
                         marginTop: "-20px",
                         color: "rgba(74,74,74,0.8)",
                         fontWeight: "600",
                       }}
                     >
                       View Map
                     </p> */}
              </div>
              {/* {showActivities()} */}
              <hr className="hr-branded"></hr>

              {/* {showActivitiesType2()} */}
              {/* <NewActivityForm /> */}
              {/* <ActivityCardTest />
             <ActivityCardTestInfo /> */}
            </div>
            {/* <div style={{ display: "none" }}>{showAllItineraryItems()}</div> */}
          </>
        )}
      </>
    )
  }

  return <>{showRightComponent()}</>
}

export default BYOTItineraryCreator
