import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  Suspense,
} from "react"
import { FirebaseContext } from "../../components/firebase"
import {
  ProfileStateProvider,
  useProfileStateStore,
} from "../../State/profile-state/profile-state"
import GoToTop from "../../components/GoToTop/GoToTop"
import ReviewCards from "../../components/ReviewCards/ReviewCards"
import StarRating from "../../components/StarRating/StarRating"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import Carousel, { Modal, ModalGateway } from "react-images"
import empty from "./../../images/empty.svg"
import { ToastProvider } from "react-toast-notifications"
import ShowError from "../../components/Error/ShowError"
import ReactHtmlParser from "react-html-parser"
// import Map from "../../components/Map/Map"
import { countryCode } from "../../constants/data/general"
import { getPresence } from "../../utility/general"
import { navigate } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import useRestrictCharacterLength from "../../hooks/useRestrictCharacterLength"
import { useGlobalStateStore } from "../../State/global-state/global-state"
import { toast } from "react-toastify"
import { AuthUserContext } from "../../components/session"

import SkillBadge from "../../components/SkillBadge/SkillBadge"
import { functions } from "../../components/firebase/firebase"
import loadable from "@loadable/component"
const LazyMap = loadable(() => import("../../components/Map/Map"))
const UserProfilesBase = ({ profileId, firebase }) => {
  const authContext = useContext(AuthUserContext)
  const dbContext = React.useContext(FirebaseContext)
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [state, dispatch] = useProfileStateStore()
  const [loading, setLoading] = useState(true)
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [presence, setPresence] = useState("")
  const [showContactMessageBox, setShowContactMessageBox] = useState(false)
  const [messageText, setMessageText] = useState("")
  const [busySendingMessage, setBusySendingMessage] = useState(false)
  useEffect(() => {
    console.log("profile State: ", state)
  }, [state])

  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(300)

  useEffect(() => {
    console.log({ dbContext })
    console.log({ profileId })
    //waitForDBContext()
    if (dbContext) {
      dbContext
        .firestore()
        .collection("users")
        .doc(`${profileId}`)
        .collection("publicProfile")
        .doc(`${profileId}`)
        .get()
        .then(doc => {
          if (doc.data()) {
            console.log(doc.data())
            dispatch({ type: "updateUserProfile", data: doc.data() })
            dbContext
              .firestore()
              .collection("users")
              .doc(`${profileId}`)
              .collection("publicProfile")
              .doc(`${profileId}`)
              .collection("reviews")
              .orderBy("reviewDate", "desc")
              .limit(5)
              .get()
              .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, " => ", doc.data())
                  dispatch({ type: "updateReviewList", data: doc.data() })
                })

                dbContext
                  .database()
                  .ref("/status/" + profileId)
                  .on("value", snapshot => {
                    console.log(snapshot.val())
                    if (snapshot.val()) {
                      var a = snapshot.val().state || "offline"
                      setPresence(a)
                      console.log({ a })
                    } else setPresence("")
                  })

                setLoading(false)
              })
              .catch(function(error) {
                console.log("Error getting documents: ", error)
              })
          }
        })
        .catch(e => console.log(e))
    }

    return () => {
      if (dbContext)
        dbContext
          .database()
          .ref("/status/" + profileId)
          .off()
    }
  }, [dbContext])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const sendMessage = () => {
    try {
      var sendMessageToDesigner = functions.httpsCallable(
        "sendMessageToDesigner"
      )
      sendMessageToDesigner({
        message: messageText,
        recipientName: state.userProfile.firstName,
        recipientUid: profileId,
        recipientProfileUrl: state.userProfile.userProfileImage,
      })
        .then(function(result) {
          // Read result of the Cloud Function.
          setBusySendingMessage(false)
          console.log({ result })
          if (result.data.success) {
            setShowContactMessageBox(false)
            setMessageText("")
          } else {
            toast.error(
              "Error sending message, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
              {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            )
          }
          // ...
        })
        .catch(err => {
          setBusySendingMessage(false)
          console.log(err)
          toast.error(
            "Error sending message, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
            {
              position: "top-right",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          )
        })
    } catch (error) {
      toast.error(
        "Error sending message, please try again later in sometime. If the issue persists send an email to support@voyayge.com",
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      )
    }
  }

  const pickRightDateFormat = () => {
    try {
      return state.userProfile.backInDays === "unspecified"
        ? new Date()
        : state.userProfile.backInDays === ""
        ? new Date()
        : typeof state.userProfile.backInDays === "string"
        ? new Date(state.userProfile.backInDays)
        : state.userProfile.backInDays.toDate()
    } catch (error) {
      console.log(error)
      return state.userProfile.backInDays === "unspecified"
        ? new Date()
        : state.userProfile.backInDays === ""
        ? new Date()
        : state.userProfile.backInDays
    }
  }

  const showImageThumbnails = params => {
    try {
      if (state.userProfile.profilePhotos.length > 0) {
        return state.userProfile.profilePhotos.map((item, index) => {
          return (
            <div key={item.name} id={index} className="image-item">
              <img
                id={index}
                src={item.thumb_src}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={e => {
                  // openLightbox(index)
                  setCurrentImage(Number(e.currentTarget.id))
                  setViewerIsOpen(true)
                }}
              />
              {/* <div className="image-hover-menu">
                <div className="image-show">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    id={index}
                  >
                    Show Photo
                  </a>
                </div>
              </div> */}
            </div>
          )
        })
      }
      {
        return (
          <div className="profile-photo-container">
            <p>- No Photos Uploaded -</p>
            <img src={empty} alt="" />
          </div>
        )
      }
    } catch (err) {
      return ""
    }
  }

  const getCodes = data => {
    try {
      let codes = []
      if (data) {
        const a = data
        a.map((item, index) => {
          console.log(item)
          const _foundItem = countryCode.find(
            element => element.Name === item.label.trim()
          )
          console.log(_foundItem)
          if (_foundItem) codes = { ...codes, ...{ [_foundItem.Code]: 0 } }
        })
        console.log({ codes })
        return codes
      }
    } catch (err) {
      console.log(err)
    }
  }
  const memoizedGetCodes = useMemo(() => state.userProfile.countriesVisited, [
    state.userProfile.countriesVisited,
  ])

  const showPresence = () => {
    switch (presence) {
      case "online":
        return (
          <span
            className="tag is-success"
            style={{ marginLeft: "auto", float: "right" }}
          >
            Online
          </span>
        )
      case "offline":
        return (
          <span
            className="tag is-light"
            style={{ marginLeft: "auto", float: "right" }}
          >
            Offline
          </span>
        )
      case "away":
        return (
          <span
            className="tag is-warning is-light"
            style={{ marginLeft: "auto", float: "right" }}
          >
            Away
          </span>
        )

      default:
        break
    }
  }

  return (
    <>
      {loading ? (
        <ShowLoading />
      ) : (
        <>
          {/* <GoToTop scrollStepInPx="100" delayInMs="30" /> */}

          <div className={`modal ${showContactMessageBox ? `is-active` : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-card">
              {/* <header className="modal-card-head">
                <p className="modal-card-title">Modal title</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => {
                    setShowContactMessageBox(false)
                  }}
                ></button>
              </header> */}
              <section className="modal-card-body">
                <div className="field">
                  <label className="label">Message:</label>
                  <div className="control is-expanded">
                    <textarea
                      onChange={e => {
                        // if (check(e.currentTarget.value.length)) return
                        console.log(e.currentTarget.value)
                        if (e.currentTarget.value) {
                          setMessageText(e.currentTarget.value)
                        } else {
                          setMessageText("")
                        }
                      }}
                      value={messageText}
                      className="textarea"
                      placeholder="Type your message here..."
                      maxlength={maxCharactersAllowed}
                    ></textarea>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        className={`help is-pulled-right ${
                          reachedMaxLength ? "is-danger" : ""
                        }`}
                      >
                        Characters: {messageText ? messageText.length : "0"}/
                        {maxCharactersAllowed}
                      </p>
                    </div>
                    {/* {errors.accommodation ? (
                    <p className="help is-danger">
                      Please pick you accommodation preference
                    </p>
                  ) : (
                    <p className="help">pick all that apply</p>
                  )} */}
                  </div>
                </div>
              </section>
              <footer className="modal-card-foot">
                <div className="" style={{ marginLeft: "auto" }}>
                  <button
                    className="button"
                    onClick={() => {
                      setShowContactMessageBox(false)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={`${
                      busySendingMessage
                        ? "button is-warning is-loading"
                        : "button is-warning"
                    }`}
                    onClick={() => {
                      setBusySendingMessage(true)
                      sendMessage()
                    }}
                  >
                    Send
                  </button>
                </div>
              </footer>
            </div>
          </div>
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={state.userProfile.profilePhotos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        {x.caption}
                      </p>
                    ),
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
          {state.userProfile === undefined ? (
            <ShowError />
          ) : (
            <>
              <div className="columns">
                <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
                <div className="column is-10-desktop is-10-tablet">
                  <div>
                    <p
                      style={{
                        fontSize: "34px",
                        fontWeight: "700",
                        borderBottom: "2px solid #fec600",
                      }}
                    >
                      Profile
                    </p>
                  </div>
                </div>
                <div className="column is-1-desktop is-1-tablet is-hidden-mobile"></div>
              </div>
              <div className="columns is-desktop">
                <div className="column is-1-desktop"></div>
                <div className="column is-3-desktop ">
                  <div
                    className="profile-image"
                    style={{
                      position: "relative",
                    }}
                  >
                    {" "}
                    <img
                      id="voyage-avatar"
                      style={{
                        marginBottom: "10px",
                        borderRadius: "7px",
                        width: "100%",
                        // height: "150px",
                      }}
                      src={state.userProfile.userProfileImage}
                    ></img>
                    <div
                      style={{
                        position: "absolute",
                        right: "5px",
                        bottom: "22px",
                      }}
                    >
                      {showPresence()}
                    </div>
                  </div>

                  {/* <hr className="hr-branded"></hr> */}
                  <h3
                    style={{
                      marginBottom: "5px",
                      marginLeft: "0px",
                      color: "rgba(74,74,74,0.8)",
                    }}
                    align="center"
                    className="subtitle is-3"
                  >
                    {state.userProfile.firstName +
                      " " +
                      state.userProfile.lastName}
                  </h3>

                  <div
                    style={{ fontSize: "14px", color: "rgba(74,74,74,0.6)" }}
                    align="center"
                    // className="content"
                  >
                    <i
                      style={{ margin: "8px" }}
                      className="fas fa-map-marker-alt"
                    ></i>
                    {state.userProfile.location === ""
                      ? "--"
                      : state.userProfile.location}
                  </div>
                  <div className="align-center">
                    <StarRating
                      score={state.userProfile.reviewRating}
                      reviewCount={state.userProfile.reviewCount}
                    />
                  </div>
                  <div className="align-center">
                    {" "}
                    <button
                      className="button is-warning"
                      style={{ marginTop: "35px", width: "auto" }}
                      onClick={() => {
                        // Check if user is signed in
                        const authUser = localStorage.getItem("authUser")
                        if (!authUser) {
                          // If we’re not logged in, redirect to the home page.
                          navigate(`/signup`)
                          return null
                        } else setShowContactMessageBox(true)
                      }}
                    >
                      <span>Contact {state.userProfile.firstName}</span>

                      {/* Send a message */}
                    </button>
                  </div>

                  <hr className="hr-branded"></hr>
                  <div
                    style={{ fontSize: "16px" }}
                    className="content is-medium"
                  >
                    <p
                      style={{
                        color: "rgba(74,74,74,0.6)",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      Languages spoken
                    </p>
                    <div className="tags" style={{ justifyContent: "center" }}>
                      {state.userProfile.languagesSpoken
                        ? state.userProfile.languagesSpoken.map(item => {
                            if (item)
                              return (
                                <span
                                  key={item.label}
                                  className="tag is-warning"
                                >
                                  {item.label}
                                </span>
                              )
                          })
                        : "--"}
                    </div>
                  </div>
                  {state.userProfile.available === false && (
                    <>
                      <hr className="hr-branded"></hr>
                      <div
                        style={{ fontSize: "16px" }}
                        className="content is-medium"
                      >
                        <p style={{ textAlign: "left" }}>
                          <i
                            className="fas fa-exclamation-square fa-1x"
                            style={{ marginRight: "5px", color: "#fec600" }}
                          ></i>
                          <strong>Note:</strong> I am on vacation and not
                          currently accepting new jobs.
                          {state.userProfile.backInDays === "unspecified"
                            ? ""
                            : ` I will be back on ${pickRightDateFormat().toLocaleDateString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}`}
                        </p>
                      </div>
                    </>
                  )}

                  {(state.userProfile.countriesVisitedCount === 0) |
                  (state.userProfile.countriesVisitedCount === undefined)
                    ? ""
                    : ""
                  // <div
                  //   className="align-center map-bg"
                  //   style={{
                  //     marginTop: "25px",
                  //     width: "200px",
                  //     position: "relative",
                  //     display: "block",
                  //   }}
                  // >
                  //   <p
                  //     style={{
                  //       fontSize: "30px",
                  //       fontWeight: "600",
                  //     }}
                  //   >
                  //     <i
                  //       className="fas fa-globe-americas fa-lg"
                  //       style={{ color: "#fec600" }}
                  //     ></i>{" "}
                  //     {state.userProfile.countriesVisitedCount}
                  //   </p>
                  //   <p
                  //     style={{
                  //       fontSize: "18px",
                  //     }}
                  //   >
                  //     Countries Traveled
                  //   </p>
                  // </div>
                  }
                  {state.userProfile.tripsDesigned === 0 ||
                  state.userProfile.tripsDesigned === undefined ? (
                    ""
                  ) : (
                    <div
                      className="align-center"
                      style={{
                        marginTop: "25px",
                        width: "200px",
                        position: "relative",
                        display: "block",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "30px",
                          fontWeight: "600",
                        }}
                      >
                        {state.userProfile.tripsDesigned}
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        Trips Designed
                      </p>
                    </div>
                  )}
                </div>
                {/* <div className="column is-1-desktop is-hidden-mobile"></div> */}
                <div className="column is-7-desktop ">
                  {" "}
                  {/* <hr className="hr-branded"></hr> */}
                  <h3
                    align=""
                    style={{ fontSize: "30px" }}
                    className="subtitle is-3 font-lato-700"
                  >
                    About
                  </h3>
                  <div
                    style={{ fontSize: "16px", lineHeight: "1.8" }}
                    className="content is-medium"
                  >
                    <div>
                      {state.userProfile.aboutIntroduction
                        ? ReactHtmlParser(state.userProfile.aboutIntroduction)
                        : "--"}
                    </div>
                    <div>
                      {state.userProfile.aboutSkills
                        ? ReactHtmlParser(state.userProfile.aboutSkills)
                        : "--"}{" "}
                    </div>
                    {/* <p>
                      {state.userProfile.aboutIntroduction
                        ? state.userProfile.aboutIntroduction
                        : "--"}
                    </p>
                    <p>
                      {state.userProfile.aboutSkills
                        ? state.userProfile.aboutSkills
                        : "--"}
                    </p> */}
                    {/* {state.userProfile.about} */}
                  </div>
                  <h3
                    align=""
                    style={{ fontSize: "26px" }}
                    className="subtitle is-3"
                  >
                    Specialties
                  </h3>
                  <div
                    style={{ fontSize: "16px" }}
                    className="content is-medium"
                  >
                    {/* <div>
                      <i className="fal fa-cocktail fa-3x"></i>
                      <p>Clubbing Enthusiast</p>
                    </div> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {state.userProfile.skills
                        ? state.userProfile.skills.map(item => {
                            if (item)
                              return (
                                <>
                                  <SkillBadge item={item} />
                                  {/* <div
                                    className=""
                                    style={{ marginRight: "10px" }}
                                  >
                                    {item.icon ? (
                                      <i
                                        class={`tag is-light is-warning ${item.icon}`}
                                      ></i>
                                    ) : (
                                      ""
                                    )}

                                    <span
                                      key={item}
                                      style={{
                                        letterSpacing: "0.5px",
                                        fontWeight: "500",
                                        textTransform: "uppercase",
                                      }}
                                      className="tag  is-warning"
                                    >
                                      {item.label}
                                    </span>
                                  </div> */}
                                </>
                              )
                          })
                        : "--"}
                    </div>
                  </div>
                  {/* <div
                    style={{ fontSize: "16px" }}
                    className="content is-medium"
                  >
                    <div className="tags">
                      {state.userProfile.tags
                        ? state.userProfile.tags.map(item => {
                            if (item)
                              return (
                                <span key={item} className="tag is-warning">
                                  {item}
                                </span>
                              )
                          })
                        : "--"}
                    </div>
                  </div> */}
                  {/* <hr className="hr-branded"></hr> */}
                  <h3
                    align=""
                    style={{ fontSize: "26px" }}
                    className="subtitle is-3 font-lato-600"
                  >
                    Destination Expert
                  </h3>
                  <div
                    style={{ fontSize: "16px" }}
                    className="content is-large"
                  >
                    <div className="tags">
                      {state.userProfile.countriesForItineraries
                        ? state.userProfile.countriesForItineraries.map(
                            item => {
                              if (item)
                                return (
                                  <span
                                    key={item.value}
                                    className="tag is-medium"
                                  >
                                    {item.value}{" "}
                                    <span
                                      style={{
                                        color: "#fec600",
                                        // marginLeft: "20px",
                                      }}
                                    >
                                      .
                                    </span>
                                  </span>
                                )
                            }
                          )
                        : "--"}
                    </div>
                  </div>
                  <hr className="hr-branded"></hr>
                  <h3
                    align=""
                    style={{ fontSize: "30px" }}
                    className="subtitle is-3 font-lato-700"
                  >
                    Countries Traveled
                  </h3>
                  <div style={{ position: "relative" }}>
                    <Suspense fallback={<h1>Still Loading…</h1>}>
                      <LazyMap
                        mapData={state.userProfile ? memoizedGetCodes : ""}
                      />
                    </Suspense>
                    <p
                      style={{
                        fontSize: "40px",
                        fontWeight: "600",
                        position: "absolute",
                        top: "20px",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {/* <i
                        className="fas fa-globe-americas fa-lg"
                        style={{ color: "#fec600" }}
                      ></i>{" "} */}
                      {state.userProfile.countriesVisitedCount}
                    </p>
                  </div>
                  <hr className="hr-branded"></hr>
                  {/* <h3
                    align=""
                    style={{ fontSize: "24px" }}
                    className="subtitle is-3 font-lato-600"
                  >
                    Countries I can design travel plans for:
                  </h3>
                  <div
                    style={{ fontSize: "16px" }}
                    className="content is-medium"
                  >
                    <div className="tags">
                      {state.userProfile.countriesForItineraries
                        ? state.userProfile.countriesForItineraries.map(
                            item => {
                              if (item)
                                return (
                                  <span
                                    key={item.value}
                                    className="tag is-warning"
                                  >
                                    {item.value}
                                  </span>
                                )
                            }
                          )
                        : "--"}
                    </div>
                  </div> */}
                  {/* <hr className="hr-branded"></hr> */}
                  <h3
                    align=""
                    style={{ fontSize: "30px" }}
                    className="subtitle is-3 font-lato-700"
                  >
                    Photos
                  </h3>
                  <div className="image-show-container">
                    {showImageThumbnails()}
                  </div>
                  <hr className="hr-branded"></hr>
                  <div
                    className="reviews-section"
                    style={{ marginBottom: "50px" }}
                  >
                    <div className="columns is-vcentered is-mobile is-tablet">
                      <div className="column is-6-mobile   is-4-tablet is-3-desktop">
                        <h3
                          align=""
                          style={{ fontSize: "30px" }}
                          className="subtitle is-3 font-lato-700"
                        >
                          Reviews
                        </h3>
                      </div>
                      {/* <div className="column is-1">
                        <span className="">
                          <p
                            align=""
                            style={{
                              fontSize: "14px",
                              // float: "left",
                              //    marginTop: "12px",
                              verticalAlign: "-webkit-baseline-middle",
                              padding: "5px",
                            }}
                            className="subtitle is-4 font-lato-400 item"
                          >
                            {state.userProfile.reviewCount
                              ? state.userProfile.reviewCount
                              : "0"}
                          </p>
                        </span>
                      </div> */}
                      <div className="column is-6"></div>
                    </div>
                    {/* <button
              style={{ float: "right", marginTop: "5px" }}
              className="button is-warning item"
            >
              <span className="icon">
                <i className="fas fa-pencil-alt"></i>
              </span>
              <span>Add a review</span>
            </button> */}
                    {state.reviews !== undefined
                      ? state.reviews.map((review, index) => (
                          <ReviewCards
                            key={index}
                            review={review}
                            index={index}
                            canReply={false}
                          />
                        ))
                      : " - No Reviews Yet - "}
                  </div>
                </div>
                <div className="column is-1-desktop "></div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

const UserProfiles = ({ profileId }) => {
  return (
    <ToastProvider>
      <ProfileStateProvider>
        <UserProfilesBase profileId={profileId} />
      </ProfileStateProvider>
    </ToastProvider>
  )
}
export default UserProfiles
