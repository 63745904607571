import React from "react"
import { responsiveColumnWrap } from "../../utility/general"
import pending from "../../images/workingHard.png"
const Pending = ({ heading, message }) => {
  return (
    <>
      {responsiveColumnWrap(
        <div className="columns is-multiline is-vcentered">
          {/* <div className="column is-12-desktop is-12-tablet is-hidden-mobile"></div>
          <div className="column is-12-desktop is-12-tablet is-hidden-mobile"></div>
          <div className="column is-12-desktop is-12-tablet is-hidden-mobile"></div>
          <div className="column is-12-desktop is-12-tablet is-hidden-mobile"></div>
          <div className="column is-12-desktop is-12-tablet is-hidden-mobile"></div>
          <div className="column is-12-desktop is-12-tablet is-hidden-mobile"></div> */}
          <div className="column is-12-desktop is-12-tablet is-0-mobile"></div>
          <div className="column is-12-desktop is-12-tablet is-0-mobile"></div>
          {/* <div className="column is-2"></div> */}
          <div className="column is-8">
            <img src={pending} alt="" />
          </div>
          {/* <div className="column is-2"></div>
          <div className="column is-2"></div> */}
          <div className="column is-4">
            <p
              style={{
                fontSize: "40px",
                textAlign: "center",
                fontWeight: "700",
                color: "#ffc600",
                // fontFamily: "Luckiest Guy",
              }}
            >
              {heading}
            </p>
            <p
              style={{
                marginTop: "24px",
                fontSize: "20px",
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              {message}
            </p>
          </div>
          {/* <div className="column is-2"></div> */}
        </div>
      )}
    </>
  )
}

export default Pending
