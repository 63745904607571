import { navigate } from "gatsby"
import React, { useState, useContext, useRef, useEffect } from "react"
import { useToasts, ToastProvider } from "react-toast-notifications"
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal"
import { FirebaseContext } from "../../components/firebase"
import { AuthUserContext } from "../../components/session"
import loadable from "@loadable/component"
const ReactQuill = loadable(() => import("react-quill"))

const SampleTravelPlanFormBase = () => {
  const userAuthContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const { addToast } = useToasts()
  const [generalInfo, setGeneralInfo] = useState("")
  const [
    accommodationRecommendation,
    setAccommodationRecommendation,
  ] = useState("")
  const [day1, setDay1] = useState("")
  const [day2, setDay2] = useState("")
  const [destination, setDestination] = useState("")
  const [generalInfoMaxLenReached, setGeneralInfoMaxLenReached] = useState(
    false
  )
  const [
    accommodationInfoMaxLenReached,
    setAccommodationInfoMaxLenReached,
  ] = useState(false)
  const [day1MaxLenReached, setDay1MaxLenReached] = useState(false)
  const [day2MaxLenReached, setDay2MaxLenReached] = useState(false)
  const [generalInfoLen, setGeneralInfoLen] = useState(0)
  const [
    accommodationRecommendationLen,
    setAccommodationRecommendationLen,
  ] = useState(0)
  const [day1Len, setDay1Len] = useState(0)
  const [day2Len, setDay2Len] = useState(0)
  var generalInfoRef = useRef(null)
  var accommodationRef = useRef(null)
  var day1Ref = useRef(null)
  var day2Ref = useRef(null)
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)
  const modules = {
    toolbar: [
      // [{ header: [false, 2, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      // ["link", "image"],
      ["link"],
      // ["clean"],
    ],
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    //  "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
  ]

  // useEffect(() => {
  //   // window.history.pushState(null, null, window.location.pathname)
  //   // window.addEventListener("popstate", onBackButtonEvent)

  //   // //logic for showing popup warning on page refresh
  //   // window.onbeforeunload = function() {
  //   //   return "Data will be lost if you leave the page, are you sure?"
  //   // }
  //   // return () => {
  //   //   window.removeEventListener("popstate", onBackButtonEvent)
  //   // }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  // const onBackButtonEvent = e => {
  //   // e.preventDefault()
  //   // if (!isBackButtonClicked) {
  //   //   if (window.confirm("Are you sure? Unsaved changes will be lost.")) {
  //   //     setBackbuttonPress(true)
  //   //     navigate("/app/designersignup")
  //   //   } else {
  //   //     window.history.pushState(null, null, window.location.pathname)
  //   //     setBackbuttonPress(false)
  //   //   }
  //   // }
  // }
  useEffect(() => {
    try {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("users")
          .doc(`${userAuthContext.uid}`)
          .collection("sampleTravelPlan")
          .doc(`${userAuthContext.uid}`)
          .get()
          .then(doc => {
            if (doc.data()) {
              setDay1(doc.data().day1Itinerary)
              setDay2(doc.data().day2Itinerary)
              setDestination(doc.data().destination)
              setGeneralInfo(doc.data().generalInfo)
              setAccommodationRecommendation(
                doc.data().accommodationRecommendation
              )
            }
          })
          .catch(error => {
            console.log("Error updating the document:", error)
            // addToast("Error saving changes, please try again in sometime", {
            //   appearance: "error",
            //   autoDismiss: true,
            // })
          })
      }
    } catch (err) {
      console.log(err)
      // addToast("Error saving changes, please try again in sometime", {
      //   appearance: "error",
      //   autoDismiss: true,
      // })
    }
  }, [dbContext])
  const pushChangesToDB = params => {
    try {
      if (dbContext) {
        dbContext
          .firestore()
          .collection("users")
          .doc(`${userAuthContext.uid}`)
          .collection("sampleTravelPlan")
          .doc(`${userAuthContext.uid}`)
          .set({
            lastSaved: new Date(),
            generalInfo: generalInfo,
            accommodationRecommendation: accommodationRecommendation,
            day1Itinerary: day1,
            day2Itinerary: day2,
            destination: destination,
          })
          .then(function() {
            addToast("Changes successfully saved", {
              appearance: "success",
              autoDismiss: true,
            })
            navigate("/app/designersignup")
          })
          .catch(error => {
            console.log("Error updating the document:", error)
            addToast("Error saving changes, please try again in sometime", {
              appearance: "error",
              autoDismiss: true,
            })
          })
      }
    } catch (err) {
      console.log(err)
      addToast("Error saving changes, please try again in sometime", {
        appearance: "error",
        autoDismiss: true,
      })
    }
  }

  const onConfirm = activityUid => {
    navigate("/app/designersignup")
  }

  const handleGeneralInfoHtml = html => {
    try {
      if (generalInfoRef) {
        const editor = generalInfoRef.getEditor()
        const quill = generalInfoRef.makeUnprivilegedEditor(editor)

        setGeneralInfoLen(quill.getLength())
        if (quill.getLength() > 1000) {
          setGeneralInfoMaxLenReached(true)
          editor.deleteText(1000, quill.getLength())
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const handleAccommodationRecommendationHtml = html => {
    try {
      if (accommodationRef) {
        const editor = accommodationRef.getEditor()
        const quill = accommodationRef.makeUnprivilegedEditor(editor)

        setAccommodationRecommendationLen(quill.getLength())
        if (quill.getLength() > 1000) {
          setAccommodationInfoMaxLenReached(true)
          editor.deleteText(1000, quill.getLength())
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleDay1DataHTML = html => {
    try {
      if (day1Ref) {
        const editor = day1Ref.getEditor()
        const quill = day1Ref.makeUnprivilegedEditor(editor)

        setDay1Len(quill.getLength())
        if (quill.getLength() > 1000) {
          setDay1MaxLenReached(true)
          editor.deleteText(1000, quill.getLength())
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  const handleDay2DataHTML = html => {
    try {
      if (day2Ref) {
        const editor = day2Ref.getEditor()
        const quill = day2Ref.makeUnprivilegedEditor(editor)

        setDay2Len(quill.getLength())
        if (quill.getLength() > 1000) {
          setDay2MaxLenReached(true)
          editor.deleteText(1000, quill.getLength())
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="columns">
      <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
      <div className="column is-6-desktop is-8-tablet is-12-mobile">
        <div>
          <p
            style={{
              fontSize: "36px",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            Sample travel plan form
          </p>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "400",
              textAlign: "left",
              marginBottom: "40px",
            }}
          >
            Using the below traveler requirements please create a sample travel
            itinerary. <strong>Note:</strong> You can pick a destination city of
            your choice and enter it below.
          </p>
          <div className="columns">
            <div
              className="column is-4-desktop is-4-tablet"
              style={{ fontWeight: "600" }}
            >
              Trip Destination:
            </div>
            {/* <div className="column is-8-desktop is-8-tablet">
              Note: Pick a destination of your choice.
            </div> */}
            <div className="field is-expanded" style={{ width: "100%" }}>
              <div className="control ">
                <input
                  value={destination}
                  className="input is-warning "
                  type="text"
                  placeholder="City, Country"
                  onChange={e => {
                    setDestination(e.currentTarget.value)
                  }}
                ></input>
                <p className="help">Enter a city of your choice</p>
              </div>
            </div>
          </div>
          <div className="columns">
            <div
              className="column is-4-desktop is-4-tablet"
              style={{ fontWeight: "600" }}
            >
              Trip Duration:
            </div>
            <div className="column is-8-desktop is-8-tablet">2 Days</div>
          </div>
          <div className="columns">
            <div
              className="column is-4-desktop is-4-tablet"
              style={{ fontWeight: "600" }}
            >
              Vacation Pacing:
            </div>
            <div className="column is-8-desktop is-8-tablet">Balanced</div>
          </div>
          <div className="columns">
            <div
              className="column is-4-desktop is-4-tablet"
              style={{ fontWeight: "600" }}
            >
              Preferred Activities:
            </div>
            <div className="column is-8-desktop is-8-tablet">
              Must see tourist spots, Museums, Shopping, Local Tours, Historic
            </div>
          </div>
          <div className="columns">
            <div
              className="column is-4-desktop is-4-tablet"
              style={{ fontWeight: "600" }}
            >
              Additional Details:
            </div>
            <div className="column is-8-desktop is-8-tablet">
              This is our first trip to this destination and would like to cover
              all the major attractions. Do recommend a few good places to eat.
              We have a toddler (5 year old) with us and would like to schedule
              in some attractions that are fun for kids.
            </div>
          </div>

          <hr className="hr-branded"></hr>
          <div style={{ marginTop: "30px" }}>
            <div>
              <p style={{ fontSize: "24px", fontWeight: "bold" }}>Your Plan</p>
              <div className="general-info" style={{ marginTop: "20px" }}>
                <p>
                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Destination General Info
                  </span>{" "}
                  (Note: Please provide any information that you would like the
                  traveler to be aware of e.g. local weather during travel
                  dates, general transportation recommendations, key local
                  language phrases that would be helpful etc.)
                </p>
                <div className="field" style={{ marginTop: "7px" }}>
                  <div className="control">
                    <div
                      className="reco-quill-250"
                      style={{ minHeight: "200px" }}
                    >
                      <ReactQuill
                        theme="snow"
                        ref={e => (generalInfoRef = e)}
                        value={generalInfo ?? ""}
                        onChange={setGeneralInfo}
                        onBlur={() => {}}
                        modules={modules}
                        formats={formats}
                        onKeyDown={handleGeneralInfoHtml}
                      />
                    </div>
                    {/* <textarea
                      className="textarea"
                      rows="5"
                      maxlength="1000"
                      value={generalInfo}
                      onChange={e => setGeneralInfo(e.currentTarget.value)}
                      //placeholder="E.g. In January, winter is at the peak in Paris, and the presence of the cold winds result in low temperatures during the day and lower temperatures at night. With average temperatures between 3°C (37.4°F) and 7°C (44.6°F), the night temperatures seldom drop to 0°C (32°F) making the winter nights icy and chilly. Rainfall in January is moderately low, with an average of fourteen rainy days. Falling mostly in the afternoons, rains are accompanied by light floods and thunderstorms."
                      placeholder=""
                    ></textarea> */}
                    <p
                      className={`help is-pulled-right ${
                        generalInfoLen >= 1000 ? "is-danger" : ""
                      }`}
                    >
                      {/* Characters:{" "} */}
                      {generalInfoLen}/{1000}
                    </p>
                  </div>
                </div>
              </div>
              <div className="accommodation-reco" style={{ marginTop: "40px" }}>
                <p>
                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Accommodation Recommendations
                  </span>{" "}
                  (Note: Based on travelers’ requirements please copy URLs of
                  Airbnb or Hotels that you would like to recommend)
                </p>
                <div className="field" style={{ marginTop: "7px" }}>
                  <div className="control">
                    <div
                      className="reco-quill-250"
                      style={{ minHeight: "200px" }}
                    >
                      <ReactQuill
                        theme="snow"
                        ref={e => (accommodationRef = e)}
                        value={accommodationRecommendation ?? ""}
                        onChange={setAccommodationRecommendation}
                        onBlur={() => {}}
                        modules={modules}
                        formats={formats}
                        onKeyDown={handleAccommodationRecommendationHtml}
                      />
                    </div>
                    {/* <textarea
                      className="textarea"
                      rows="5"
                      maxlength="1000"
                      value={accommodationRecommendation}
                      onChange={e =>
                        setAccommodationRecommendation(e.currentTarget.value)
                      }
                      //placeholder="E.g. In January, winter is at the peak in Paris, and the presence of the cold winds result in low temperatures during the day and lower temperatures at night. With average temperatures between 3°C (37.4°F) and 7°C (44.6°F), the night temperatures seldom drop to 0°C (32°F) making the winter nights icy and chilly. Rainfall in January is moderately low, with an average of fourteen rainy days. Falling mostly in the afternoons, rains are accompanied by light floods and thunderstorms."
                      placeholder=""
                    ></textarea> */}
                    <p
                      className={`help is-pulled-right ${
                        accommodationRecommendationLen >= 1000
                          ? "is-danger"
                          : ""
                      }`}
                    >
                      {/* Characters:{" "} */}
                      {accommodationRecommendationLen}/{1000}
                    </p>
                  </div>
                </div>
              </div>
              <div className="accommodation-reco" style={{ marginTop: "40px" }}>
                <p>
                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Itinerary
                  </span>{" "}
                  (Please create a personalized travel itinerary based on
                  travelers’ requirements provided above. Note: personal tips
                  and recommendations go a long way!)
                </p>
                <div className="field" style={{ marginTop: "10px" }}>
                  <label className="label">Day 1 Plan</label>
                  <div className="control">
                    <div
                      className="reco-quill-250"
                      style={{ minHeight: "200px" }}
                    >
                      <ReactQuill
                        theme="snow"
                        ref={e => (day1Ref = e)}
                        value={day1 ?? ""}
                        onChange={setDay1}
                        onBlur={() => {}}
                        modules={modules}
                        formats={formats}
                        onKeyDown={handleDay1DataHTML}
                      />
                    </div>

                    {/* <textarea
                      className="textarea"
                      rows="10"
                      maxlength="1000"
                      value={day1}
                      onChange={e => setDay1(e.currentTarget.value)}
                      //placeholder="E.g. In January, winter is at the peak in Paris, and the presence of the cold winds result in low temperatures during the day and lower temperatures at night. With average temperatures between 3°C (37.4°F) and 7°C (44.6°F), the night temperatures seldom drop to 0°C (32°F) making the winter nights icy and chilly. Rainfall in January is moderately low, with an average of fourteen rainy days. Falling mostly in the afternoons, rains are accompanied by light floods and thunderstorms."
                      placeholder=""
                    ></textarea> */}
                    <p
                      className={`help is-pulled-right ${
                        day1Len >= 1000 ? "is-danger" : ""
                      }`}
                    >
                      {/* Characters:{" "} */}
                      {day1Len}/{1000}
                    </p>
                  </div>
                </div>
              </div>
              <div className="accommodation-reco" style={{ marginTop: "40px" }}>
                <div className="field">
                  <label className="label">Day 2 Plan</label>
                  <div className="control">
                    <div
                      className="reco-quill-250"
                      style={{ minHeight: "200px" }}
                    >
                      <ReactQuill
                        theme="snow"
                        ref={e => (day2Ref = e)}
                        value={day2 ?? ""}
                        onChange={setDay2}
                        onBlur={() => {}}
                        modules={modules}
                        formats={formats}
                        onKeyDown={handleDay1DataHTML}
                      />
                    </div>
                    {/* <textarea
                      className="textarea"
                      rows="10"
                      maxlength="1000"
                      value={day2}
                      onChange={e => setDay2(e.currentTarget.value)}
                      //placeholder="E.g. In January, winter is at the peak in Paris, and the presence of the cold winds result in low temperatures during the day and lower temperatures at night. With average temperatures between 3°C (37.4°F) and 7°C (44.6°F), the night temperatures seldom drop to 0°C (32°F) making the winter nights icy and chilly. Rainfall in January is moderately low, with an average of fourteen rainy days. Falling mostly in the afternoons, rains are accompanied by light floods and thunderstorms."
                      placeholder=""
                    ></textarea> */}
                    <p
                      className={`help is-pulled-right ${
                        day2Len >= 1000 ? "is-danger" : ""
                      }`}
                    >
                      {/* Characters:{" "} */}
                      {day2Len}/{1000}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  margin: "60px 0px",
                  marginRight: "0px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div className="field is-grouped">
                  <p className="control">
                    <button
                      className="button"
                      onClick={e => {
                        ConfirmationModal({
                          onConfirm,
                          title: "Confirmation",
                          text: `Are you sure you want to exit? All unsaved data will be lost!`,
                        })
                      }}
                    >
                      Go Back
                    </button>
                  </p>
                  <p className="control">
                    <button
                      className="button is-warning"
                      onClick={e => pushChangesToDB()}
                    >
                      Save & Exit
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-3-desktop is-2-tablet is-hidden-mobile"></div>
    </div>
  )
}

const SampleTravelPlanForm = () => {
  return (
    <ToastProvider>
      <SampleTravelPlanFormBase />
    </ToastProvider>
  )
}
export default SampleTravelPlanForm
