import React, { useState, useEffect, useContext } from "react"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"
import { ToastProvider, useToasts } from "react-toast-notifications"
import {
  DashboardStateProvider,
  useDashboardStateStore,
} from "../../State/dashboard-state/dashboard-state"

import {
  GlobalStateProvider,
  useGlobalStateStore,
} from "../../State/global-state/global-state"

import "./Dashboard.scss"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
import DesignerDashboard from "./Designer/DesignerDashboard"
import TravelerDashboard from "./Traveler/TravelerDashboard"
import { navigate } from "gatsby"
import ThankYou from "../../components/ThankYou/ThankYou"
import Pending from "../../components/Pending/Pending"
import DesignerOnboarding from "../designerOnboarding/DesignerOnboarding"
import ShowError from "../../components/Error/ShowError"
import SEO from "../../components/seo"

const DashboardContainerBase = ({ location }) => {
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [globalState, globalDispatch] = useGlobalStateStore()
  const [loading, setLoading] = useState(true)

  const fetchAdminData = params => {
    dbContext
      .firestore()
      .collection("users")
      .doc(`${authContext.uid}`)
      .collection("adminData")
      .doc(`${authContext.uid}`)
      .get()
      .then(doc => {
        if (doc) {
          console.log("updateAdminData: ", doc.data())
          globalDispatch({
            type: "updateAdminData",
            data: doc.data(),
          })
          setLoading(false)
        }
      })
  }

  const doNext = () => {
    if (
      globalState.currentSessionRole !== "traveler" &&
      globalState.currentSessionRole !== "designer"
    ) {
      console.log("fetching session role from cloud")
      dbContext
        .firestore()
        .collection("users")
        .doc(`${authContext.uid}`)
        .get()
        .then(doc => {
          if (doc) {
            var role = doc.data().currentSessionRole
            if (role === "traveler" || role === "designer") {
              globalDispatch({
                type: "updateSessionType",
                data: role,
              })
              fetchAdminData()
            }
          }
        })
        .catch(e => console.log(e))
    } else {
      fetchAdminData()
      setLoading(false)
    }
  }

  useEffect(() => {
    try {
      if (dbContext) {
        setLoading(true)
        if (location.state) {
          if (
            location.state.sessionType === "traveler" ||
            location.state.sessionType === "designer"
          ) {
            globalDispatch({
              type: "updateSessionType",
              data: location.state.sessionType,
            })
            // Update this state from the sign up form immediately and get any other admin details.
            globalDispatch({
              type: "updateDesignerApprovalStatus",
              data: location.state.designerApprovalStatus,
            })
            //Fetch admin data created by a cloud function
            fetchAdminData()
          } else {
            // Added this case to cover page reload scenario.
            doNext()
          }
        } else doNext()
        console.log(globalState.currentSessionRole)
        if (dbContext === null) {
          setLoading(true)
        }

        let activeTrips = []
      }
    } catch (error) {
      console.log(error)
    }
  }, [dbContext])

  useEffect(() => {
    // console.log(location.state)
    // globalDispatch({
    //   type: "updateSessionType",
    //   data: location.state.sessionType,
    // })
    // // Update this state from the sign up form immediately and get any other admin details.
    // globalDispatch({
    //   type: "updateDesignerApprovalStatus",
    //   data: location.state.designerApprovalStatus,
    // })
  }, [])

  const checkIfOnboardingIsRequired = () => {
    // check if a account number is already present
    if (dbContext) {
      dbContext
        .firestore()
        .collection("users")
        .doc(`${authContext.uid}`)
        .get()
        .then(doc => {
          if (doc) {
            console.log(doc.data())
            console.log(doc.data().stripeInfo)
            if (
              doc.data().stripeInfo !== undefined &&
              doc.data().stripeInfo.accountId !== undefined
            ) {
              return <DesignerDashboard />
            } else {
              return <DesignerOnboarding />
            }
          }
        })
        .catch(error => {
          return <ShowError />
        })
    }
  }

  const showAppropriateDashboard = () => {
    switch (globalState.currentSessionRole) {
      case "traveler":
        return <TravelerDashboard />
      case "designer":
        if (globalState.adminData !== undefined) {
          switch (globalState.adminData.designerApprovalStatus) {
            case "none":
            case "sentBack":
              navigate("/app/designersignup") //Redirect to approval form
              // return (
              //
              // )
              break
            case "pending":
              return (
                <Pending
                  heading="We are on it!"
                  message="Reviewing your application, we'll get back to you in 24-48 hours."
                />
              )
              return
            case "pending-onboarding":
              return <DesignerOnboarding />
              // var res = ""
              // if (dbContext) {
              //   dbContext
              //     .firestore()
              //     .collection("users")
              //     .doc(`${authContext.uid}`)
              //     .get()
              //     .then(doc => {
              //       if (doc) {
              //         console.log(doc.data())
              //         console.log(doc.data().stripeInfo.accountId)
              //         if (doc.data().stripeInfo.accountId) {
              //           console.log("I am =")
              //           res = <DesignerDashboard />
              //           return res
              //         } else {
              //           console.log("I am 4")
              //           res = <DesignerOnboarding />
              //           return res
              //         }
              //       }
              //     })
              //     .catch(error => {
              //       return <ShowError />
              //     })
              // } else {
              //   console.log("I am here")
              // }
              break
            case "approved":
              return <DesignerDashboard />
            default:
              return <ShowError />
              break
          }
        } else {
          // TODO: To cover the issue where the use only singned up as a traveler but logins' as a designer. Need to find a better way to fix this.
          navigate("/app/designersignup")
        }

      default:
        return ""
    }
  }
  return (
    <>
      {loading ? (
        <ShowLoading />
      ) : (
        <>
          {/* <button
            className="button is-warning"
            onClick={() => {
              navigate("/app/designersignup")
            }}
          >
            Show Onboarding
          </button> */}
          <SEO pageTitle="Dashboard" description="My dashboard" />
          {showAppropriateDashboard()}
        </>
      )}
    </>
  )
}

const DashboardContainer = ({ location }) => {
  return (
    //<GlobalStateProvider>
    <ToastProvider>
      <DashboardStateProvider>
        <DashboardContainerBase location={location} />
      </DashboardStateProvider>
    </ToastProvider>

    //</GlobalStateProvider>
  )
}
export default DashboardContainer
