import React, { useState, useContext, useEffect } from "react"
import "./ReviewCards.scss"
import { responsiveColumnWrap } from "../../utility/general"
import AuthUserContext from "../session/context"
import { FirebaseContext } from "./../firebase"
import { useProfileStateStore } from "../../State/profile-state/profile-state"

import { ToastProvider, useToasts } from "react-toast-notifications"

const ReviewCards = React.memo(({ review, canReply, index }) => {
  const [showReplyInput, setShowReplyInput] = useState(false)
  const userAuthContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [state, dispatch] = useProfileStateStore()
  const [replyText, setReplyText] = useState("")
  const [replyTextValidationMsg, setReplyTextValidationMsg] = useState("")
  const { addToast } = useToasts()
  console.log({ review })

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }

  useEffect(() => {
    dispatch({ type: "replyDBUpdate", data: false })
  }, [])

  useEffect(() => {
    try {
      if (state.requiresReplyDBUpdate) {
        if (dbContext) {
          dbContext
            .firestore()
            .collection("users")
            .doc(`${userAuthContext.uid}`)
            .collection("publicProfile")
            .doc(`${userAuthContext.uid}`)
            .update(state.publicProfile)
            .then(() => console.log("update successful!"))
            .catch(error => {
              addToast("Error saving changes, please try again in sometime", {
                appearance: "error",
                autoDismiss: true,
              })
            })
        }
        dispatch({ type: "replyDBUpdate", data: false })
      }
    } catch (err) {
      console.log(err)
      addToast("Error saving changes, please try again in sometime", {
        appearance: "error",
        autoDismiss: true,
      })
    }
  }, [state.requiresReplyDBUpdate])

  const submitReply = () => {
    var response = {
      firstName:
        state.publicProfile.firstName + " " + state.publicProfile.lastName,
      userProfileImage: state.publicProfile.userProfileImage,
      uid: userAuthContext.uid,
      responseDate: new Date(),
      response: replyText,
    }
    var updatedReview = { ...review, response: response }
    dbContext
      .firestore()
      .collection("users")
      .doc(`${userAuthContext.uid}`)
      .collection("publicProfile")
      .doc(`${userAuthContext.uid}`)
      .collection("reviews")
      .doc(`${review.reviewId}`)
      .update(updatedReview)
      .then(() => {
        console.log("reply update successful!")
        dispatch({ type: "updateReply", data: updatedReview })
      })
      .catch(error => {
        addToast("Error saving response, please try again in sometime", {
          appearance: "error",
          autoDismiss: true,
        })
      })
  }
  const validateAndSubmit = () => {
    if (replyText === "")
      setReplyTextValidationMsg("Your response cannot be empty")
    else {
      submitReply()
    }
  }

  const getSafeDate = d => {
    try {
      return new Intl.DateTimeFormat("en-US", options).format(d.toDate())
    } catch (error) {
      return new Intl.DateTimeFormat("en-US", options).format(d)
    }
  }
  return (
    <>
      <div
        className=""
        style={{
          marginBottom: "15px",
          borderBottom: "1px solid #fec600",
          padding: "10px",
        }}
      >
        <article className="media">
          <div
            className="media-left"
            style={{ cursor: "pointer" }}
            onClick={params => {
              window.open(`/app/userprofiles/${review.uid}`, "_blank")
            }}
          >
            <figure className="image is-32x32">
              <img src={review.reviewerProfileImage} alt="Image"></img>
            </figure>
          </div>
          <div className="media-content">
            <div className="content">
              <p>
                <strong>
                  {review.firstName ? review.firstName : review.userName}
                </strong>{" "}
                <small
                  style={{ fontSize: "12px", color: "rgba(74,74,74,0.6)" }}
                >
                  {" "}
                  {getSafeDate(review.reviewDate)}
                </small>{" "}
                <br></br>
                <small>{review.review}</small>
              </p>
            </div>
            <nav className="level is-mobile">
              <div className="level-left">
                <div className="level-item">
                  {review.response !== undefined ? (
                    <article className="media">
                      <div className="media-left">
                        <figure className="image is-32x32">
                          <img
                            src={review.response.userProfileImage}
                            alt="Image"
                          ></img>
                        </figure>
                      </div>
                      <div className="media-content">
                        <div className="content">
                          <p>
                            <strong>{review.response.firstName}</strong>{" "}
                            <small
                              style={{
                                fontSize: "12px",
                                color: "rgba(74,74,74,0.6)",
                              }}
                            >
                              {" "}
                              {getSafeDate(review.response.responseDate)}
                            </small>{" "}
                            <br></br>
                            <small>{review.response.response} </small>
                          </p>
                        </div>
                      </div>
                    </article>
                  ) : (
                    <>
                      <div
                        className=""
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        {showReplyInput ? (
                          <>
                            <div className="field">
                              <div className="control">
                                <p className="help is-danger">
                                  {replyTextValidationMsg}
                                </p>
                                <textarea
                                  value={replyText}
                                  className="textarea is-warning"
                                  // placeholder="Type your reply here..."
                                  // value= state.tempPubulicProfile
                                  onChange={params => {
                                    setReplyText(params.currentTarget.value)
                                    setReplyTextValidationMsg("")
                                    // dispatch({
                                    //   type: "updateReply",
                                    //   index: index,
                                    //   data: params.currentTarget.value,
                                    // })
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="field is-grouped is-grouped-right">
                              <p className="control">
                                <a
                                  className="button is-light"
                                  onClick={() => {
                                    setShowReplyInput(false)
                                    setReplyText("")
                                    setReplyTextValidationMsg("")
                                  }}
                                >
                                  Cancel
                                </a>
                              </p>
                              <p className="control">
                                <a
                                  className="button is-warning"
                                  onClick={() => {
                                    validateAndSubmit()
                                    // dispatch({
                                    //   type: "saveReply",
                                    //   index: index,
                                    //   firstName: state.publicProfile.firstName,
                                    //   userProfileImage:
                                    //     state.publicProfile.userProfileImage,
                                    //   uid: userAuthContext.uid,
                                    // })
                                  }}
                                >
                                  Save Reply
                                </a>
                              </p>
                            </div>
                          </>
                        ) : canReply ? (
                          <a onClick={() => setShowReplyInput(true)}>
                            Reply <i className="fas fa-reply"></i>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </article>
      </div>
      {/* <div className="columns is-vcentered is-multiline is-mobile is-tablet">
        {review.response !== undefined ? (
          <></>
        ) : (
          <>
            <div
              className=""
              style={{
                fontSize: "13px",
              }}
            >
              {showReplyInput ? (
                <>
                  <div className="field">
                    <div className="control">
                      <p className="help is-danger">{replyTextValidationMsg}</p>
                      <textarea
                        value={replyText}
                        className="textarea is-warning"
                        placeholder="Tye your reply here..."
                        // value= state.tempPubulicProfile
                        onChange={params => {
                          setReplyText(params.currentTarget.value)
                          setReplyTextValidationMsg("")
                          // dispatch({
                          //   type: "updateReply",
                          //   index: index,
                          //   data: params.currentTarget.value,
                          // })
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="field is-grouped is-grouped-right">
                    <p className="control">
                      <a
                        className="button is-light"
                        onClick={() => {
                          setShowReplyInput(false)
                          setReplyText("")
                          setReplyTextValidationMsg("")
                        }}
                      >
                        Cancel
                      </a>
                    </p>
                    <p className="control">
                      <a
                        className="button is-warning"
                        onClick={() => {
                          validateAndSubmit()
                          // dispatch({
                          //   type: "saveReply",
                          //   index: index,
                          //   firstName: state.publicProfile.firstName,
                          //   userProfileImage:
                          //     state.publicProfile.userProfileImage,
                          //   uid: userAuthContext.uid,
                          // })
                        }}
                      >
                        Save Reply
                      </a>
                    </p>
                  </div>
                </>
              ) : canReply ? (
                <a onClick={() => setShowReplyInput(true)}>
                  Reply <i className="fas fa-reply"></i>
                </a>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div> */}

      {/* <hr className="hr-branded"></hr> */}
    </>
  )
})

export default ReviewCards
