import React, { useState, useEffect, useContext } from "react"
import { Link, navigate } from "gatsby"
import * as ROUTES from "./../../constants/routes"
import { v4 as uuidv4 } from "uuid"
import AuthUserContext from "../../components/session/context"
import { FirebaseContext } from "../../components/firebase"
import congrats from "./../../images/congratulations.png"
import cheers from "./../../images/cheers.png"
import ShowError from "../../components/Error/ShowError"
import ShowLoading from "../../components/ShowLoading/ShowLoading"
const DesignerOnboarding = () => {
  const dbContext = useContext(FirebaseContext)
  const authContext = useContext(AuthUserContext)
  const [loading, setLoading] = useState(true)

  const getStripeOnboardingURL = () => {
    try {
      const STATE_VALUE = uuidv4()
      var stripe_onboarding_API = `https://connect.stripe.com/express/oauth/authorize?client_id=${process.env.STRIPE_CLIENT_ID}&state=${STATE_VALUE}&stripe_user[email]=${authContext.email}`
      console.log(stripe_onboarding_API)
      return stripe_onboarding_API
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (dbContext) {
      dbContext
        .firestore()
        .collection("users")
        .doc(`${authContext.uid}`)
        .get()
        .then(doc => {
          if (doc) {
            console.log(doc.data())
            //console.log(doc.data().stripeInfo.accountId)
            var userData = doc.data()
            if (userData.stripeInfo) {
              if (userData.stripeInfo.accountId) {
                console.log("I am =")
                // There is a valid strip account id. So we change the status in DB
                dbContext
                  .firestore()
                  .collection("users")
                  .doc(`${authContext.uid}`)
                  .collection("adminData")
                  .doc(`${authContext.uid}`)
                  .update({ designerApprovalStatus: "approved" })
                  .then(() => {
                    setLoading(false)
                    // navigate back to dashboard -
                    navigate(ROUTES.DASHBOARD)
                  })
              } else {
                setLoading(false)
              }
            } else {
              setLoading(false)
            }
          }
        })
        .catch(error => {
          console.log(error)
          return <ShowError />
        })
    } else {
      console.log("asdfasasdf")
    }
  }, [dbContext])

  return (
    <div className="">
      {/* <div className="center"> */}
      <div className="columns is-multiline is-vcentered">
        <div className="column is-3-desktop is-2-tablet"></div>
        <div className="column is-6-desktop is-8-tablet">
           
          {loading ? (
            <ShowLoading />
          ) : (
            <>
              <div className="columns is-multiline is-vcentered">
                <div className="column is-12-desktop is-12-tablet">
                  <div style={{ maxWidth: "300px", margin: "0 auto" }}>
                    <img
                      src={cheers}
                      alt="congratulations"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="column is-12-desktop is-12-tablet">
                  <img
                    src={congrats}
                    alt="congratulations"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="column is-12-desktop is-12-tablet">
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "26px",
                    }}
                  >
                    Let's get your bank account set up for payments.
                  </p>
                </div>
                <div className="column is-12-desktop is-12-tablet">
                  <div
                    className="centered"
                    style={{
                      textAlign: "center",
                      width: "140px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <a
                      href={getStripeOnboardingURL()}
                      className="button is-warning centered"
                    >
                      Setup payment
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="column is-3-desktop is-2-tablet"></div>
      </div>
    </div>
  )
}

export default DesignerOnboarding
